// Core
import { Injectable } from '@angular/core';

// RxJS
import { Observable, of, merge, timer } from 'rxjs';
import { tap, catchError, map, take, delayWhen } from 'rxjs/operators';

// Services
import { ToastrService } from 'ngx-toastr';
import { BackgroundJobsService } from '@modules/common/services/background-jobs.service';

// Types
import { ActiveToast } from 'ngx-toastr';
import { AsyncTask } from '@modules/common/types/background-job';
import { AsyncTaskToastSettings } from '../types/async-task-toast-settings';
import { AsyncTaskProgressToastSettings } from '../types/async-task-progress-toast-settings';
import { Action } from '../types/action';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(
    private backgroundJobsService: BackgroundJobsService,
    private toastrService: ToastrService
  ) { }

  private getToastSettings(status: string, settings: AsyncTaskProgressToastSettings): AsyncTaskToastSettings {
    return {
      text: settings.status[status].text,
      icon: settings.status[status].icon,
      actions: (settings.status[status].actions || []).reduce(
        (actions: Action[], actionName: string) => {
          if (settings.actions && settings.actions[actionName]) {
            actions.push(settings.actions[actionName]);
          }
          return actions;
        },
        [] as Action[]
      )
    };
  }

  show(settings: AsyncTaskToastSettings): ActiveToast<boolean> {
    const duration = settings.duration || 10000

    const toast = this.toastrService.show(
      settings.text,
      null,
      {
        timeOut: duration,
        extendedTimeOut: 0
      }
    );

    toast.toastRef.componentInstance.setActions(settings.actions);
    toast.toastRef.componentInstance.setIcon(settings.icon);
    toast.toastRef.componentInstance.countdown = settings.countdown;
    toast.toastRef.componentInstance.duration = duration;

    toast.toastRef.componentInstance.afterTimeout
      .pipe(take(1))
      .subscribe(() => {
        settings.afterTimeout?.();
      });

    return toast;
  }

  // The method is probably not in use anymore when you read this, but we may still need it at some point. However, you will have to revisit 'getJobById' inside the backend APIGateway service, which is from the very old version of Matrix.
  showAwaitProgress(asyncTask: AsyncTask, settings: AsyncTaskProgressToastSettings): Observable<boolean> {
    if (!asyncTask || asyncTask.status === 'completed') {
      this.show(this.getToastSettings('completed', settings));
      return of(true);
    }

    if (asyncTask.status === 'error') {
      this.show(this.getToastSettings('error', settings));
      return of(false);
    }

    const toast = this.show(this.getToastSettings('processing', settings));
    const delayUntil = new Date().getTime() + 1000;

    return merge(
      toast.onAction.pipe(map(() => false)),
      this.backgroundJobsService
        .await(asyncTask)
        .pipe(
          delayWhen(() => timer(delayUntil - new Date().getTime())),
          catchError(() => of(false)),
          tap(success => {
            this.toastrService.remove(toast.toastId);
            this.show(this.getToastSettings(success ? 'completed' : 'error', settings));
          })
        )
    )
      .pipe(take(1));
  }
}
