// Common
import { Injectable, Optional} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';

// RX
import { Observable, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';
import { BaseStitchChildService } from '@modules/common/services/base-stitch-child.service';
import { StitchService } from '@modules/common/services/stitch.service';

// Types
import { Like } from '@modules/common/types/like';
import { Contact } from '../types/contact';
import { ContactsFilters } from '../types/contacts-filters';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { StitchType } from '@modules/common/types/stitch-type';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { Participant } from '@modules/messages/types/participant';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { BunchUpdateOptions } from '@modules/common/types/bunch-update-options';

// Decorators
import { warmUpObservable } from '@decorators';

const formatParticipantTitle = participant =>
  `${participant.name ?? ''}${participant.name ? ' <' : ''}${participant.address}${participant.name ? '>' : ''}`;

@Injectable()
export class ContactsService extends BaseStitchChildService<Contact, ContactsFilters> implements CalendarEventsService {
  protected url = environment.baseUrl + '/api/contacts/contacts/';
  protected stitchType = StitchType.contact;
  protected filtersConstructor = ContactsFilters;

  constructor (
    protected http: HttpClient,
    @Optional() linkedInfoService: LinkedInfoService,
    protected toasterService: ToasterService,
    @Optional() knotsService: KnotsService,
    @Optional() tagsService: TagsService,
    @Optional() stitchService: StitchService,
  ) {
    super(http, toasterService, stitchService, tagsService, knotsService, linkedInfoService);
  }

  /**
   * Methods
   */

  public bunchUpdate(
    filters: Partial<ContactsFilters>,
    changes: BunchUpdateOptions & Partial<Pick<Contact, 'groupId'>>,
    config: FeedbackConfig
  ): Observable<void> {
    return super.bunchUpdate(filters, changes, config);
  }

  create(
    contactInstance: Contact,
    { emit, toast, message }: FeedbackConfig = { emit: true }
  ): Observable<Contact> {
    return this.http.post<{ contact: Contact, success: boolean }>(
      `${environment.baseUrl}/api/contacts/contacts`,
      contactInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }
          emit && this.forceRefresh();
          toast && this.toasterService.show({ text: message || `Contact created.` });
        }),
        map(({ contact }) => new Contact(contact)),
        switchMap(contact => this.processKnowledgeItems(contact)),
        switchMap(contact => this.processUploads(contactInstance.uploads, contact, { other: true, parentFolderName: 'Contact App' })),
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  update(
    contactInstance: Contact,
    { emitUpdate, displayToast, toastMessage }: FeedbackConfig = { emitUpdate: true }
  ): Observable<Contact> {
    return this.http.put<{ contact: Contact, success: boolean }>(
      environment.baseUrl + '/api/contacts/contacts/' + contactInstance.id, contactInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ contact, success }) => {
          if (success) {
            emitUpdate && this.forceRefresh();
            displayToast && this.toasterService.show({ text: toastMessage || 'Contact updated' });
          }
        }),
        map(({ contact }) => new Contact(contact)),
        catchError(error => this.handleObserverError(error))
      );
  }

  getCalendarEvents(fromTime: Date, toTime: Date): Observable<AngularCalendarEvent[]> {
    return this.search({ fromTime, toTime })
      .pipe(
        map(({ items }) => items.map(contact => contact.asAngularCalendarEvent()))
      );
  }

  getAutocompleteSuggestions(inputFilters: Like<ContactsFilters> = {}): AutocompleteFactory<Contact> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      const filters: Like<ContactsFilters> = {
        limit: config?.limit || 5,
        archived: false,
        deleted: false,
        ...inputFilters
      };

      if (values?.length) {
        filters.ids = values;
      } else if (title?.trim()) {
        filters.query = title.trim();
        filters.esAnalyzer = 'ngrams_2_12';
        filters.esPriority = 'title';
        filters.esMultiMatchType = 'best_fields';
      }

      return this.search(filters)
        .pipe(
          map((response: BaseSearchResponse<Contact>) => response.items.map(contact => ({
            title: contact.title,
            value: contact.id,
            source: contact
          }))),
        );
    };
  }

  getEmailAutocompleteSuggestions(): AutocompleteFactory<Participant> {
    return (
      title?: string,
      values?: Participant[],
      { limit, except }: { limit?: number, except?: Participant[] } = { limit: 5, except: []}
    ) => {
      const filters = { limit: limit || 5, query: null, except: [] };

      if (values?.length) {
        return of(values.map(participant => ({
          title: formatParticipantTitle(participant),
          value: participant,
          source: participant,
          type: 'contact'
        })));
      } else if (title?.trim()) {
        filters.query = title.trim();
        if (except?.length) {
          filters['except[]'] = except.map(participant => participant.address);
        }
      } else {
        return of([]);
      }

      return this.http.get<{ items: Participant[] }>(
        environment.baseUrl + '/api/contacts/contacts/email-autocomplete',
        { params: filters })
        .pipe(
          catchError(error => this.handleObserverError(error)),
          map((response: BaseSearchResponse<Participant>) => (
            response.items.map(participant => ({
              title: formatParticipantTitle(participant),
              value: participant,
              source: participant,
              type: 'contact'
            }))
          ))
        );
    };
  }

  createInstance(item): Contact {
    return new Contact(item);
  }
}
