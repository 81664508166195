// Common
import { Component, Injector } from '@angular/core';

// Types
import { File } from '@modules/files/types/file';
import { StitchType } from '@modules/common/types/stitch-type';

// Components
import { SharedBaseComponent } from '@modules/common/components/shared-base/shared-base.component';

// Services
import { FilesService } from '@modules/files/services/files.service';

// RX
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'stch-file-shared',
  templateUrl: './file-shared.component.html',
  styleUrls: ['./file-shared.component.less'],
})
export class FileSharedComponent extends SharedBaseComponent<File> {

  protected stitchType = StitchType.file;

  constructor (
    private filesService: FilesService,
    injector: Injector,
  ) {
    super(injector);
  }

  get file() {
    return this.stitchItem;
  }

  download() {
    this.stitchItem.download();
  }

  print() {
    this.filesService.print(this.file)
      .pipe(takeUntil(this.alive))
      .subscribe();
  }
}
