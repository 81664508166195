// Common
import { Component, EventEmitter, Injector, Output } from '@angular/core';

// Services
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { MessagesService } from '@modules/messages/services/messages.service';

// Types
import { MessageFolder } from '@modules/messages/types/message-folder';
import { Message } from '@modules/messages/types/message';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

@Component({
  selector: 'app-message-folder',
  templateUrl: './message-folder.component.html',
  styleUrls: ['./message-folder.component.less'],
})
export class MessageFolderComponent extends BaseStitchComponent<MessageFolder> {
  @Output() openMessage = new EventEmitter<Message>();

  moveDragDataTypes = [DragDataTypes.message, DragDataTypes.messageFolder];
  dragDataType = DragDataTypes.messageFolder;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(
      dragData.type === DragDataTypes.messageFolder &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.item.id
    ) &&
    dragDataTypeAllowed(dragData.type)

  constructor (
    injector: Injector,
    protected foldersService: MessageFoldersService,
    private messagesService: MessagesService,
  ) { super(injector, foldersService); }

  handleMove(dragData: DragData) {
    const message = 'Successfully moved to ' + this.item.title;

    if (dragData.type === DragDataTypes.message) {
      this.messagesService.bunchUpdate(
        { ids: this.getIds(dragData.data) },
        { folderId: this.item.id },
        { message }
      );
    } else if (dragData.type === DragDataTypes.messageFolder) {
      this.foldersService.bunchUpdate(
        { ids: this.getIds(dragData.data) },
        { parentId: this.item.id },
        { message }
      );
    }
    this.popoverClose.next();
  }

  get folder() {
    return this.item;
  }
}
