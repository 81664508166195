<div
  *ngFor="let participant of (participants | slice : 0 : (page + 1) * perPage)"
  class="item"
>
  {{ participant.value }}

  <mat-icon
    class="item-delete-icon"
    svgIcon="close-thin"
    (click)="handleDelete(participant)"
  ></mat-icon>
</div>

<div
  *ngIf="page !== 0"
  class="more-less"
  (click)="showLess()"
>
  &lt; Less
</div>

<div
  *ngIf="page < pagesCount - 1"
  class="more-less"
  (click)="showMore()"
>
  More >
</div>
