// Common
import { AfterViewInit, Directive, ElementRef, Output, EventEmitter, NgZone, OnDestroy, Input } from '@angular/core';
import { SplitViewService } from '@modules/split-view/services/split-view.service';

// RX
import { fromEvent, of, merge, Subject, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[resize]'
})
export class ResizeDirective implements AfterViewInit, OnDestroy {

  @Input() resizeDelayType: 'throttle' | 'debounce' = 'debounce';

  @Output() resize = new EventEmitter<[number, number]>();

  private alive = new Subject<void>();

  constructor(
    private element: ElementRef,
    private ngZone: NgZone,
    private splitService: SplitViewService
  ) { }

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      timer(600)
        .pipe(
          switchMap(() => merge(
            fromEvent(window, 'resize'),
            this.splitService.getSizeChanges(),
            of(null)
          )),
          this.resizeDelayType === 'throttle' ? throttleTime(400) : debounceTime(400),
          map(() => {
            const { width, height } = this.element.nativeElement.getBoundingClientRect();
            return { width, height };
          }),
          distinctUntilChanged((prev, curr) => prev.width === curr.width && prev.height === curr.height),
          takeUntil(this.alive)
        )
        .subscribe(({ width, height }) => {
          this.ngZone.run(() => {
            this.resize.emit([width, height]);
          });
        });
    });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }
}
