export enum Application {
  mail = 'mail',
  calendar = 'calendar',
  tasking = 'tasking',
  notes = 'notes',
  contacts = 'contacts',
  files = 'files',
  kp = 'kp',
  settings = 'settings',
  admin = 'admin'
}
