// Common
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

// RX
import { Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

// Services
import { PushNotificationsService } from '@modules/notification/services/push-notifications.service';
import { MailAppSettingsService } from '@modules/messages/services/settings.service';
import { NotificationsSettingsService } from '@modules/notification/services/settings.service';
import { CalendarAppSettingsService } from '@modules/calendar-app/services/settings.service';
import { TaskingAppSettingsService } from '@modules/tasks/services/settings.service';
import { NotesAppSettingsService } from '@modules/notes/services/settings.service';
import { ContactsAppSettingsService } from '@modules/contacts/services/settings.service';
import { FilesAppSettingsService } from '@modules/files/services/settings.service';

// Types
import { Settings as MailAppSettings } from '@modules/messages/types/settings';
import { Settings as NotificationsSettings } from '@modules/notification/types/settings';
import { Settings as CalendarAppSettings } from '@modules/calendar-app/types/settings';
import { Settings as TaskingAppSettings } from '@modules/tasks/types/settings';
import { Settings as NotesAppSettings } from '@modules/notes/types/settings';
import { Settings as ContactsAppSettings } from '@modules/contacts/types/settings';
import { Settings as FilesAppSettings } from '@modules/files/types/settings';

@Component({
  selector: 'app-settings-general-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.less']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  public mailForm = new MailAppSettings().asFormGroup();
  public calendarForm = new CalendarAppSettings().asFormGroup();
  public taskingForm = new TaskingAppSettings().asFormGroup();
  public notesForm = new NotesAppSettings().asFormGroup();
  public filesForm = new FilesAppSettings().asFormGroup();
  public contactsForm = new ContactsAppSettings().asFormGroup();
  public notificationsForm = new NotificationsSettings().asFormGroup();
  public pushNotificationsEnabled = true;
  public toggleAllNotifications = new FormControl(true);
  public toggleAllReminders = new FormControl(true);
  public toggleAllPostpone = new FormControl(true);
  public spaceVolumeToggleControl = new FormControl(null);

  private alive = new Subject<void>();

  constructor(
    private mailSettingsService: MailAppSettingsService,
    private pushNotificationsService: PushNotificationsService,
    private calendarSettingsService: CalendarAppSettingsService,
    private taskingSettingsService: TaskingAppSettingsService,
    private notesSettingsService: NotesAppSettingsService,
    private contactsSettingsService: ContactsAppSettingsService,
    private filesSettingsService: FilesAppSettingsService,
    private notificationsSettingsService: NotificationsSettingsService,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.mailSettingsService.listAll(true)
      .pipe(
        tap(settings => {
          this.mailForm = settings.asFormGroup();
          this.updateGlobalControls();
        }),
        switchMap(() => this.mailForm.valueChanges),
        debounceTime(500),
        switchMap(() =>
          this.mailSettingsService.update(MailAppSettings.fromFormGroup(this.mailForm))
        ),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.updateGlobalControls();
      });

    this.calendarSettingsService.listAll(true)
      .pipe(
        tap(settings => {
          this.calendarForm = settings.asFormGroup();
          this.updateGlobalControls();
        }),
        switchMap(() => this.calendarForm.valueChanges),
        debounceTime(500),
        switchMap(() =>
          this.calendarSettingsService.update(CalendarAppSettings.fromFormGroup(this.calendarForm))
        ),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        if (
          this.calendarForm.controls.remindersTimeBeforeMinutes.value !==
          this.taskingForm.controls.remindersTimeBeforeMinutes.value
        ) {
          this.taskingForm.controls.remindersTimeBeforeMinutes.setValue(
            this.calendarForm.controls.remindersTimeBeforeMinutes.value
          );
        }

        this.updateGlobalControls();
      });

    this.taskingSettingsService.listAll(true)
      .pipe(
        tap(settings => {
          this.taskingForm = settings.asFormGroup();
          this.updateGlobalControls();
        }),
        switchMap(() => this.taskingForm.valueChanges),
        debounceTime(500),
        switchMap(() =>
          this.taskingSettingsService.update(TaskingAppSettings.fromFormGroup(this.taskingForm))
        ),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.updateGlobalControls();
      });

    this.notesSettingsService.listAll(true)
      .pipe(
        tap(settings => {
          this.notesForm = settings.asFormGroup();
          this.updateGlobalControls();
        }),
        switchMap(() => this.notesForm.valueChanges),
        debounceTime(500),
        switchMap(() =>
          this.notesSettingsService.update(NotesAppSettings.fromFormGroup(this.notesForm))
        ),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.updateGlobalControls();
      });

    this.contactsSettingsService.listAll(true)
      .pipe(
        tap(settings => {
          this.contactsForm = settings.asFormGroup();
          this.updateGlobalControls();
        }),
        switchMap(() => this.contactsForm.valueChanges),
        debounceTime(500),
        switchMap(() =>
          this.contactsSettingsService.update(ContactsAppSettings.fromFormGroup(this.contactsForm))
        ),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.updateGlobalControls();
      });

    this.filesSettingsService.listAll(true)
      .pipe(
        tap(settings => {
          this.filesForm = settings.asFormGroup();
          this.updateGlobalControls();
        }),
        switchMap(() => this.filesForm.valueChanges),
        debounceTime(500),
        switchMap(() =>
          this.filesSettingsService.update(FilesAppSettings.fromFormGroup(this.filesForm))
        ),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.updateGlobalControls();
      });

    this.notificationsSettingsService.listAll(true)
      .pipe(
        tap(settings => {
          this.notificationsForm = settings.asFormGroup();
        }),
        switchMap(() => this.notificationsForm.valueChanges),
        debounceTime(500),
        switchMap(() =>
          this.notificationsSettingsService.update(NotificationsSettings.fromFormGroup(this.notificationsForm))
        ),
        takeUntil(this.alive)
      )
      .subscribe();

    this.pushNotificationsService.isEnabled()
      .pipe(takeUntil(this.alive))
      .subscribe((enabled) => this.pushNotificationsEnabled = enabled);

    this.toggleAllNotifications.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(value => {
        const controls = [
          this.mailForm.controls.notifications,
          this.calendarForm.controls.notifications,
          this.taskingForm.controls.notifications,
          this.notesForm.controls.notifications,
          this.filesForm.controls.notifications,
          this.contactsForm.controls.notifications,
        ];

        controls.forEach(control => {
          if (control.value !== value) {
            control.setValue(value);
          }
        });
      });

    this.toggleAllReminders.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(value => {
        const controls = [
          this.taskingForm.controls.reminders,
          this.calendarForm.controls.reminders,
        ];

        controls.forEach(control => {
          if (control.value !== value) {
            control.setValue(value);
          }
        });
      });

    this.toggleAllPostpone.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(value => {
        const controls = [
          this.mailForm.controls.messagesPostponeNotificationsEnabled,
          this.mailForm.controls.messageFoldersPostponeNotificationsEnabled,
          this.calendarForm.controls.calendarsPostponeNotificationsEnabled,
          this.calendarForm.controls.eventsPostponeNotificationsEnabled,
          this.filesForm.controls.filesPostponeNotificationsEnabled,
          this.filesForm.controls.foldersPostponeNotificationsEnabled,
          this.taskingForm.controls.tasksPostponeNotificationsEnabled,
          this.taskingForm.controls.projectsPostponeNotificationsEnabled,
          this.notesForm.controls.notesPostponeNotificationsEnabled,
          this.notesForm.controls.notebooksPostponeNotificationsEnabled,
          this.contactsForm.controls.contactsPostponeNotificationsEnabled,
          this.contactsForm.controls.groupsPostponeNotificationsEnabled
        ];

        controls.forEach(control => {
          if (control.value !== value) {
            control.setValue(value);
          }
        });
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  requestPermission() {
    this.pushNotificationsService.requestGrantPermission();
  }

  updateGlobalControls() {
    this.toggleAllNotifications.setValue(
      [
        this.mailForm.controls.notifications.value,
        this.calendarForm.controls.notifications.value,
        this.taskingForm.controls.notifications.value,
        this.notesForm.controls.notifications.value,
        this.filesForm.controls.notifications.value,
        this.contactsForm.controls.notifications.value,
      ].every(value => value),
      { emitEvent: false }
    );

    this.toggleAllReminders.setValue(
      [
        this.taskingForm.controls.reminders.value,
        this.calendarForm.controls.reminders.value,
      ].every(value => value),
      { emitEvent: false }
    );

    this.toggleAllPostpone.setValue(
      [
        this.mailForm.controls.messagesPostponeNotificationsEnabled.value,
        this.mailForm.controls.messageFoldersPostponeNotificationsEnabled.value,
        this.calendarForm.controls.calendarsPostponeNotificationsEnabled.value,
        this.calendarForm.controls.eventsPostponeNotificationsEnabled.value,
        this.filesForm.controls.filesPostponeNotificationsEnabled.value,
        this.filesForm.controls.foldersPostponeNotificationsEnabled.value,
        this.taskingForm.controls.tasksPostponeNotificationsEnabled.value,
        this.taskingForm.controls.projectsPostponeNotificationsEnabled.value,
        this.notesForm.controls.notesPostponeNotificationsEnabled.value,
        this.notesForm.controls.notebooksPostponeNotificationsEnabled.value,
        this.contactsForm.controls.contactsPostponeNotificationsEnabled.value,
        this.contactsForm.controls.groupsPostponeNotificationsEnabled.value
      ].every(value => value),
      { emitEvent: false }
    );
  }
}
