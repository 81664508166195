<span class="placeholder" *ngIf="items.length === 0">No Groups found</span>

<div appSelectableList>
  <app-sidebar-item
    *nestedFor="
      let group of items;
      let index = index;
      let depth = depth;
      let expandable = expandable;
      let itemExpanded = expanded;
      stateKey: treeStateKey;
    "
    [title]="group.title"
    [actions]="[{
      icon: 'sapphire-plus',
      template: groupQuickFormTemplate,
      context: { parentId: group.id },
      until: quickFormPopoverClose
    }]"
    [depth]="depth"
    [badge]="{ template: badgeTemplate, context: { flagged: group.flagged, pinned: group.pinned } }"
    [iconPadding]="false"
    [expandable]="expandable"
    [expanded]="itemExpanded"
    [active]="selectedItemsIds | includesBy : group?.id"
    (appClick)="handleClick(group)"
    (appDblClick)="handleDblClick(group)"
    [appGroupContextMenu]="group"
    [appSelectable]="group.id"
    [appSelectablePosition]="index"
    [appSelectableIdentifier]="group.id"

    appDroppable
    appDroppableHoverClass="drag-over"
    [appDroppablePredicate]="dndPredicate(group)"
    (appDroppableDrop)="dndDrop($event, group)"

    appDraggable
    [appDraggableData]="items | selectedItemsDragData: selectedItemsIds: group"
    [appDraggablePlaceholder]="placeholder"
    [appDraggablePlaceholderContext]="{ item: group }"
  />
</div>

<ng-template #badgeTemplate let-flagged=flagged let-pinned=pinned>
  <div class="badge-template">
    <mat-icon
      class="badge-template-icon pinned"
      *ngIf="pinned"
      svgIcon="pin-direct"
    />

    <mat-icon
      *ngIf="flagged"
      class="badge-template-icon flagged"
      svgIcon="starred"
    />
  </div>
</ng-template>
