<div class="form">
  <div class="form-date">{{ notebook?.createdAt | date : 'MMM d' }}</div>

  <h2 class="form-title">
    <mat-icon
      class="form-heading-title-icon"
      svgIcon="note"
      [style.color]="notebook?.color"
    />
    {{ notebook?.title }}
  </h2>

  <div class="form-details">
    <div class="form-details-row">
      <div class="form-details-row-title">
        Parent Notebook
      </div>
      <div class="form-details-row-input">{{ notebook?.parentId }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">
        Description
      </div>
      <div class="form-details-row-input">{{ notebook?.description }}</div>
    </div>
  </div>
</div>
