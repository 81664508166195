import { Inject, Injectable, Optional } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { SPACE_ID } from '../injection-tokens/space-id.injection-token';

@Injectable()
export class SpacesHttpInterceptor implements HttpInterceptor {
  constructor(
    @Optional() @Inject(SPACE_ID) private spaceId: BehaviorSubject<string>,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const newReq = request.clone({
      params: (request.params ? request.params : new HttpParams()).set('space_id', this.spaceId?.value)
    });

    return next.handle(newReq);
  }
}
