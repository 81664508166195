// Types
import { PaymentMethodType } from "@modules/settings/types/payment-method-type";
import { TransactionType } from "@modules/settings/types/transaction-type";

export class Charge {
  id: number;
  amount: number;
  currency: string;
  debug: object;
  createdAt: Date;
  paymentMethod: {
    type: PaymentMethodType,
    cardName: string,
    cardBrand: string,
    cardLast4: string,
  }
  error: string;
  type: TransactionType;

  constructor(raw: any = {}) {
    this.id = raw.id;
    this.amount = raw.amount;
    this.currency = raw.currency;
    this.debug = raw.debug;
    this.paymentMethod = {
      type: raw.paymentMethod.type,
      cardName: raw.paymentMethod.cardName,
      cardBrand: raw.paymentMethod.cardBrand,
      cardLast4: raw.paymentMethod.cardLast4,
    }
    this.createdAt = raw?.createdAt && new Date(raw.createdAt);
    this.error = raw?.error;
    this.type = raw?.type;
  }
}
