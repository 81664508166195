
<div class="block">
  <div
    *ngIf="user?.avatar"
    class="block-col"
  >
    <img class="avatar" [src]="user?.avatar">
  </div>

  <div class="block-grow">
    <stch-table
      class="items"
      [items]="items"
      [columns]="columns"
    ></stch-table>
  </div>
</div>

<ng-template #birthDateTemplate let-user=item>
  {{ user.birthDate | date : 'mediumDate' }}
</ng-template>

<ng-template #dateTemplate let-user=item>
  {{ user.createdAt | date : 'mediumDate' }}
</ng-template>

<ng-template #rowTemplate let-item=item>
  <div [ngSwitch]="item.key">
    <div *ngSwitchCase="'birthDate'">{{ item.value | date : 'mediumDate' }}</div>
    <div *ngSwitchCase="'createdAt'">{{ item.value | date : 'mediumDate' }}</div>
    <div *ngSwitchCase="'userName'">{{ item.value }}&#64;stage-stitch.com</div>
    <div *ngSwitchCase="'subscriptionPlan'">
      <div *ngIf="item.value?.title">
        {{ item.value.title }}
        {{ item.value.suspended ? '(Suspended)' : '' }}.
        Price: ${{ item.value.monthlyPrice }}/${{ item.value.annuallyPrice }}
      </div>
    </div>
    <div *ngSwitchDefault>{{ item.value }}</div>
  </div>
</ng-template>
