<div
  *ngIf="file"
  class="file"
  [class.selected]="selected && !isDragging"
  [class.pinned]="file.pinned"
  [class.hovered]="contextMenuOpened"
  [class.snoozed]="file.snoozed"
  [class.followed]="file.followed"

  [appFileContextMenu]="file"
  (appFileContextMenuOpened)="contextMenuOpened = $event"
  (appFileContextMenuOpenFile)="open.emit()"

  appDraggable
  [appDraggableDisabled]="!dragEnabled"
  [appDraggableData]="dragData"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"

  (appClick)="onClick.emit(file)"
  (appDblClick)="onDoubleClick.emit(file)"
>
  <div class="file-selection"></div>

  <div
    class="file-type-icon"
    [style.backgroundColor]="file.attachmentType | fileColorByType"
  >
    {{ file.attachmentType | fileMimeToExt }}
  </div>

  <div class="file-body">
    <div class="file-body-title">
      <div
        class="file-body-title-text"
        [class.blank]="!file.title"
      >
        {{ file.title || 'Untitled File' }}
      </div>
    </div>

    <div class="file-body-date">
      <div
        *ngIf="!withTags && !withKnots"
        class="file-body-date-item"
        [class.blank]="!file.createdAt"
      >
        {{ file.createdAt ? 'Upload Date: ' + (file.createdAt | date : 'MMM d') : 'No Created' }};
        {{ file.attachmentSize ? 'Size: ' + (file.attachmentSize | fileSize) : 'No Size' }}
      </div>

      <app-list-item-tags
        *ngIf="withTags"
        class="file-body-tags"
        [tags]="file.tags"
      ></app-list-item-tags>

      <app-list-item-knots
        *ngIf="withKnots"
        class="file-body-tags"
        [knots]="file.knots"
      ></app-list-item-knots>
    </div>

    <app-link-info-bar [stitch]="file"></app-link-info-bar>
  </div>

  <mat-icon
    class="file-pin-icon"
    [class.active]="file.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  ></mat-icon>

  <mat-icon
    class="file-flag-icon"
    [class.active]="file.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  ></mat-icon>

  <mat-icon
    class="file-marker"
    [svgIcon]="file.archived ? 'folder-archive' : 'file'"
    [style.color]="file.color"
  ></mat-icon>

  <div
    class="file-hover-icons"
    *ngIf="dragEnabled && actionsButtonEnabled"
    (click)="$event.stopPropagation()"
  >
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming Soon"
    ></mat-icon>
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="snooze"
      [matTooltip]="file?.snoozed ? 'Unsnooze' : 'Snooze'"
      [class.active]="!!file?.snoozed"
      (click)="snooze()"
    ></mat-icon>
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="follow-up"
      [matTooltip]="file?.followed ? 'Unfollow' : 'Follow up'"
      [class.active]="!!file?.followed"
      (click)="followUp()"
    ></mat-icon>
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="download-large"
      (click)="download()"
    ></mat-icon>
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="file"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    ></mat-icon>
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="archive"
      (click)="archive()"
      [matTooltip]="file?.archived ? 'Restore from Archive' : 'Archive'"
    ></mat-icon>
    <mat-icon
      class="file-hover-icons-item"
      svgIcon="trash"
      (click)="delete()"
      [matTooltip]="file?.deleted ? 'Restore from Trash' : 'Trash'"
    ></mat-icon>
  </div>

  <div class="drop-zone"></div>

  <div *ngIf="debug" class="file-debug">{{ file.score }}</div>
</div>

<div class="placeholder" *ngIf="!file">
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
  <div class="placeholder-11"></div>
  <div class="placeholder-12"></div>
</div>

<ng-template #dragPlaceholder>
  <app-file [item]="file" [dragEnabled]="false"></app-file>
</ng-template>

