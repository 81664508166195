<cdk-virtual-scroll-viewport
  [itemSize]="itemHeight"
  minBufferPx="880"
  maxBufferPx="1320"
  class="connections-list-wrapper"
  (mouseover)="isHover = true"
  (mouseleave)="isHover = false"
  appSelectableList
  [appSelectableListReset]="resetSelected"
>
  <ng-container *cdkVirtualFor="let contact of itemsStreamObservable; let index = index">
    <app-contact
      [item]="contact"
      [position]="index"
      [withTags]="withTags"
      [withKnots]="withKnots"
      (open)="openConnection.emit(contact)"
      (appClick)="handleClickItem(contact)"
      (appDblClick)="handleDoubleClickItem(contact)"

      [appSelectable]="contact"
      [appSelectablePosition]="index"
    ></app-contact>
  </ng-container>

  <!-- Placeholders -->
  <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  <div *ngIf="loadingError" class="placeholder">Error occured while loading. <span (click)="items?.length ? refreshCurrentItems() : resetItems()" class="error">Click here to try again.</span></div>
</cdk-virtual-scroll-viewport>

<div class="connections-stub" *ngIf="items?.length === 0 && !loading && !loadingError">
  <img class="connections-stub-image" src="assets/placeholders/placeholder-tasks.svg"/>
  <p class="connections-stub-text">{{ placeholderText }}</p>
</div>
