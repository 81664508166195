// Common
import { Component, Injector, Input, OnInit } from '@angular/core';

// Services
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';

// Types
import { PostponeType } from '@modules/common/types/postpone-type';
import { Application } from '@modules/common/types/application';
import { Calendar } from '@modules/calendar-app/types/calendar';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';

// RX
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Components
import { StitchContextMenuComponent } from '../stitch-context-menu/stitch-context-menu.component';

@Component({
  selector: 'app-calendar-context-menu',
  templateUrl: './calendar-context-menu.component.html',
  styleUrls: ['./calendar-context-menu.component.less']
})
export class CalendarContextMenuComponent extends StitchContextMenuComponent<Calendar> implements OnInit {

  @Input() calendar: Calendar;

  applicationName = Application.calendar;
  gaPrefix = 'calendar-context-menu';

  constructor (
    private calendarsService: CalendarsService,
    private toasterService: ToasterService,
    private eventsStateService: CalendarAppStateService,
    injector: Injector,
  ) {
    super(injector, calendarsService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.item = this.calendar;
    super.ngOnInit();
  }

  /**
   * Helpers
   */

  private includedInSelected(): boolean {
    return this.selectedItems.map(calendar => calendar.id).includes(this.calendar.id);
  }

  duplicate() {
    if (!this.calendar) { return; }

    const calendarsToDuplicate = this.multiple ? this.selectedItems : [this.calendar];

    combineLatest(
      calendarsToDuplicate.map(calendar => this.calendarsService.create(calendar, { emitUpdate: false, displayToast: false }))
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.toasterService
          .show({
            text: `Calendar${ this.multiple ? 's have' : ' has' } been duplicated`
          });
        this.calendarsService.forceRefresh();
        this.close();
      });
  }

  postpone(date: Date, postponeType: PostponeType) {
    this.calendarsService[postponeType](
      {
        ids: this.includedInSelected()
          ? this.selectedItems.filter(({ readOnly }) => !readOnly).map(({ id }) => id)
          : [this.calendar.id]
      },
      date
    );
  }

  deletePermanently() {
    super.deletePermanently(
      `When deleting, all events in ${ this.multiple ? 'these' : 'this' } calendar${ this.multiple && 's' } will be removed also`
    );
  }

  public handleMore(entity: CalendarEvent | Calendar) {
    this.eventsStateService.setMainView(entity);
    this.close();
  }
}
