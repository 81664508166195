<div
  class="container"
  [class.focused]="focused"
  [class.no-border]="noBorder"
>
  <input
    #input
    class="input"
    [formControl]="innerControl"
    [placeholder]="placeholder"
    (focus)="handleFocus()"
    (blur)="handleBlur()"
    (keyup.enter)="handleEnter()"
    autocomplete="off"

    stchPopover
    stchPopoverPlacement="bottomLeft"
    [stchPopoverContent]="optionsPopover"
    [stchPopoverCustomTrigger]="popoverCustomTrigger"
    [stchPopoverShowUntil]="popoverHide"

    (appOutsideClick)="popoverHide.next()"
    outsideClickExceptSelectors=".options"
  />

  <div
    *ngIf="sideOptions"
    class="container-side"

    stchPopover
    stchPopoverPlacement="bottomLeft"
    stchPopoverTrigger="click"
    [stchPopoverContent]="sideOptionsPopover"
    [stchPopoverShowUntil]="sidePopoverHide"
  >
    <div class="container-side-separator"></div>
    <div class="container-side-input">{{ selectedSideOption?.title }}</div>
    <mat-icon class="container-side-icon" svgIcon="dropdown-down"></mat-icon>
  </div>

  <mat-icon
    *ngIf="withClear && innerControl.value"
    class="container-clear-icon"
    svgIcon="close-thin"
    (click)="handleClear()"
  ></mat-icon>
</div>

<ng-template #optionsPopover>
  <div
    *ngIf="suggestions || staticOptions"
    class="simple-input-options options"
    [style.width.px]="popoverWidth"
  >
    <div
      *ngFor="let option of options"
      [class.options-item]="!itemTemplate"
      [class.options-template-item]="itemTemplate"
      [class.active]="option.value === inputFormControl?.value"
      (click)="handleSelect(option)"
    >
      <ng-container *ngIf="!itemTemplate">
        <mat-icon class="options-item-icon" *ngIf="option.icon" [svgIcon]="option.icon"></mat-icon>
        {{ option.title }}
      </ng-container>
      <ng-container *ngIf="itemTemplate">
        <ng-container *ngTemplateOutlet="itemTemplate; context: { item: option.source, type: option.type }"></ng-container>
      </ng-container>
    </div>

    <div
      *ngFor="let option of staticOptions"
      [class.options-item]="!itemTemplate"
      [class.options-template-item]="itemTemplate"
      [class.active]="option.value === inputFormControl?.value"
      (click)="handleSelect(option)"
    >
      <ng-container *ngIf="!itemTemplate">
        <mat-icon class="options-item-icon" *ngIf="option.icon" [svgIcon]="option.icon"></mat-icon>
        {{ option.title }}
      </ng-container>
      <ng-container *ngIf="itemTemplate">
        <ng-container *ngTemplateOutlet="itemTemplate; context: { item: option.source }"></ng-container>
      </ng-container>
    </div>

    <div class="options-item-placeholder" *ngIf="!options?.length && !staticOptions?.length">
      No items matched your search for<br />“{{ innerControl.value }}…”.
    </div>
  </div>
</ng-template>

<ng-template #sideOptionsPopover>
  <div
    class="simple-input-options options no-scroll"
    [style.minWidth.px]="130"
  >
    <div
      *ngFor="let option of sideOptions"
      class="options-item"
      [class.active]="option.value === selectedSideOption?.value"
      (click)="handleSideSelect(option)"
    >
      <mat-icon class="options-item-icon" *ngIf="option.icon" [svgIcon]="option.icon"></mat-icon>
      {{ option.title }}
    </div>
  </div>
</ng-template>
