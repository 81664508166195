<div class="block">
  <div class="heading2">Stitch Plans</div>
  <div class="plans">
    Get the planning and features you need to work more efficiently.
  </div>

  <br/>
  <br/>
  <div>Bonus Balance: ${{ account?.bonusBalance }}</div>
  <div>Subscription Status: {{ account?.subscriptionStatus }}</div>
  <div>Next Subscription Plan: {{ nextPlanTitle }}</div>
  <div>Next Charge Date: {{ account?.nextChargeDate | date : 'mediumDate' }}</div>
  <div>Next Charge Type: {{ account?.nextChargeType }}</div>

  <div class="stitch-plans">
    <app-plan-card
      *ngFor="let plan of plans"
      class="stitch-plans-item"
      [plan]="plan"
      [account]="account"
      [currentPlan]="currentPlan"
    ></app-plan-card>
  </div>
</div>
