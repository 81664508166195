// Types
import { Filters } from '@modules/common/types/filters';
import { Connection } from '@modules/connections/types/connection';

// Utils
import { isNil } from '@modules/common/utils/base';
import { Knot } from '@modules/knots/types/knot';

export class TagsNeighboursFilters extends Filters {
  // Filters
  connections?: Connection[];
  createdFrom?: Date;
  createdTo?: Date;
  ids?: string[];
  knots?: Knot[];

  // Sort Options
  sortBy?: 'name' | 'date' | 'recency' | 'frequency';

  constructor(filters: Partial<TagsNeighboursFilters> = {}) {
    super(filters);
    this.connections = filters.connections;
    this.createdFrom = filters.createdFrom;
    this.createdTo = filters.createdTo;

    this.sortBy = filters.sortBy;
    this.knots = filters.knots;
  }

  format(): { [param: string]: string | string[]; } {
    const result = {};

    if (this.knots?.length) {
      result['knots[]'] = this.knots.map(({ name }) => name);
    }

    if (this.connections?.length) {
      result['connections[]'] = this.connections.map(({ name }) => name);
    }

    if (this.ids && this.ids.length) {
      // 'undefined' possible when new item in FF and new not existing knot
      result['ids[]'] = this.ids.filter(i => !isNil(i));
    }

    if (this.createdFrom) { result['created_from_time'] = this.createdFrom.toISOString(); }
    if (this.createdTo) { result['created_to_time'] = this.createdTo.toISOString(); }

    if (this.sortBy) { result['sort_by'] = this.sortBy; }
    if (this.sortOrder) { result['sort_order'] = this.sortOrder; }

    if (!isNil(this.limit)) { result['limit'] = this.limit + ''; }
    if (!isNil(this.offset)) { result['offset'] = this.offset + ''; }

    return result;
  }
}
