<div class="form">
  <div
    *ngIf="owner || manager"
    class="label"
  >
    <app-button
      icon="plus-medium"
      appearance="link-blue"
      label="ADD PARTICIPANT"
      (click)="openParticipantForm()"
    />
  </div>

  <div class="participants">
    <div
      *ngFor="let user of space.participants"
      class="row"
    >
      <div class="cell">
        <stch-avatar [user]="user" />
      </div>
      <div class="cell">{{ user.firstName }} {{ user.lastName}} ({{ user.userName }})</div>
      <div class="cell">{{ user.status }}</div>
      <div class="cell">{{ user.joinedAt | timeAgo }}</div>
      <div class="cell">
        <app-button
          *ngIf="user.status === 'pending' && account?.id === user.id"
          label="Accept"
          appearance="link-blue"
          (click)="accept(true)"
        />
        <app-button
          *ngIf="user.status === 'pending' && account?.id === user.id"
          label="Reject"
          appearance="link-blue"
          (click)="accept(false)"
        />
      </div>
      <div class="cell">
        <app-dropdown-label
          stchPopover
          stchPopoverTrigger="click"
          stchPopoverPlacement="bottomLeft"
          [stchPopoverContent]="rolesPopover"
          [stchPopoverContext]="{ user: user }"
        >
          {{ user.role }}
        </app-dropdown-label>
      </div>
      <div class="cell">
        <mat-icon
          class="icon"
          svgIcon="trash"
          (click)="removeParticipantPrompt($event, user)"
        ></mat-icon>
      </div>
    </div>
  </div>
</div>

<ng-template #rolesPopover let-user="user">
  <app-context-menu-item
    *ngFor="let role of roles"
    [withCheckOption]="true"
    [close]="true"
    [title]="role | titlecase"
    [active]="user.role === role"
    (execute)="changeRole(user, role)"
  ></app-context-menu-item>
</ng-template>
