// Common
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from '@environment';

// Types
// @ts-ignore
import { Editor, Settings } from 'tinymce';
import { Upload } from '@modules/common/types/upload';

// RX
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { UploadsService } from '@modules/common/services/uploads.service';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.less'],
})
export class RichTextEditorComponent implements OnInit, OnChanges, OnDestroy {

  @Input() control: FormControl<string>;
  // TODO implement commandName enum for editor if needed from docs of tinymc editor
  @Input() editorCommandExecutor: Observable<{ commandName: string, ui: boolean, content: string }>;
  @Input() disableTableMenu = false;
  @Input() withErrors = false;
  @Input() validationMessages: { [key: string]: string} = {};

  @Output() imageUploaded = new EventEmitter<Upload>();

  public settingsPrepared = false;
  public settings: Settings = {
    plugins: 'lists link image table code',
    resize: false,
    height: '100%',
    elementpath: false,
    statusbar: false,
    // Workaround to hide not needed menu items
    menu: {
      file: { title: 'File', items: '' },
      tools: { title: 'Tools', items: '' },
    },
    images_upload_handler: this.handleUploadImage.bind(this),
    promotion: false,
  };

  private editor: Editor;
  private alive = new Subject<void>();
  private editorCommandExecutorChanged = new ReplaySubject<void>();
  private allTempAttachmentsIds: string[] = []

  constructor(
    private uploadsService: UploadsService,
  ) { }

  /**
  * Lifecycle
  */

  ngOnInit() {
    if (this.disableTableMenu) {
      this.settings.menu['table'] = { title: 'Table', items: '' };
    }

    this.settingsPrepared = true;

    this.editorCommandExecutorChanged
      .pipe(
        filter(() => !!this.editorCommandExecutor),
        switchMap(() => this.editorCommandExecutor),
        takeUntil(this.alive)
      )
      .subscribe((command) => {
        this.editor.execCommand(command.commandName, command.ui, command.content);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('editorCommandExecutor' in changes) {
      this.editorCommandExecutorChanged.next();
    }
  }

  ngOnDestroy() {
    const tempAttachments = this.getInlineTempAttachmentsIds(this.control.value);
    const attachmentsToRemove = this.allTempAttachmentsIds.filter(id => !tempAttachments.includes(id));

    if (attachmentsToRemove.length > 0) {
      this.uploadsService.delete(attachmentsToRemove);
    }

    // if form was closed without submiting, temp attachemnts will remain on the disk

    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  editorInitialized({ editor }: { editor: Editor }) {
    if (!editor) { return; }

    this.editor = editor;

    // Work around to make undo remove last word and not entire line
    editor.on('keyup', (event: KeyboardEvent) => {
      if (event && event.code === 'Space') {
        editor.undoManager.add();
      }
    });
  }

  private handleUploadImage(blob, progress): Promise<string> {
    return new Promise((resolve, reject) => {
      const file = blob.blob();
      const upload = new Upload({
        nativeFile: file,
        name: file.name,
        size: file.size,
        type: file.type,
        inline: true,
        temp: true
      });

      this.uploadsService.upload(upload)
        .pipe(takeUntil(this.alive))
        .subscribe(
          ({ id }) => {
            this.allTempAttachmentsIds.push(id);
            upload.id = id;
            this.imageUploaded.emit(upload);
            resolve(`${environment.baseUrl}/api/attachments/temp/${id}`);
          },
          (error) => reject(error)
        );
    });
  }

  private getInlineTempAttachmentsIds(html) {
    if (!html) { return []; }

    const regExp = new RegExp(`src=["']${environment.baseUrl}/api/attachments/temp/([^"']+)["']`, 'g');
    const ids = (html.match(regExp) || []).map(src => src.replace(regExp, '$1'));

    return ids;
  }
}
