// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, Injector } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Types
import { Task } from '@modules/tasks/types/task';
import { PostponeType } from '@modules/common/types/postpone-type';
import { Application } from '@modules/common/types/application';

// RX
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Components
import { StitchContextMenuComponent } from '../stitch-context-menu/stitch-context-menu.component';

@Component({
  selector: 'app-task-context-menu',
  templateUrl: './task-context-menu.component.html',
  styleUrls: ['./task-context-menu.component.less']
})
export class TaskContextMenuComponent extends StitchContextMenuComponent<Task> implements OnInit, OnChanges {

  @Input() task: Task;

  @Output() openSubtask = new EventEmitter<Task>();

  applicationName = Application.tasking;
  gaPrefix = 'task-context-menu';
  public form: UntypedFormGroup;
  public quickTask: Task;

  constructor (
    private tasksService: TasksService,
    private toasterService: ToasterService,
    injector: Injector,
  ) {
    super(injector, tasksService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.item = this.task;
    this.quickTask = new Task({ parentId: this.task?.id });
    super.ngOnInit();
    this.resetForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('task' in changes) {
      this.resetForm();
      this.item = this.task;
      this.quickTask = new Task({ parentId: this.task.id });
    }
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.tasksService.update(Task.fromFormGroup(this.form))
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => this.close());
  }

  resetForm() {
    this.form = (this.task || new Task()).asFormGroup();
  }

  handleSubtaskFormMore(task: Task) {
    this.openSubtask.emit(task);
  }

  duplicate() {
    if (!this.task) { return; }

    const tasksToDuplicate = this.multiple ? this.selectedItems : [this.task];

    combineLatest(
      tasksToDuplicate.map((task) => this.tasksService.create(task, { emitUpdate: false, displayToast: false }))
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.toasterService
          .show({
            text: `Task${ this.multiple ? 's have' : ' has' } been duplicated`
          });
        this.tasksService.forceRefresh();
        this.close();
      });
  }

  postpone(date: Date, postponeType: PostponeType) {
    this.tasksService[postponeType]({ ids: this.getIds() }, date);
  }

  deletePermanently() {
    super.deletePermanently(`Are you sure you want to remove ${ this.multiple ? 'these' : 'this' } task${ this.multiple && 's'}?`);
  }
}
