import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const authReq = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`)
    });

    return next.handle(authReq);
  }
}
