<div class="header">
  <div class="header-title">{{ title }}</div>
  <div class="header-separator"></div>
  <app-dropdown-label
    [appKPStitchTypeContextMenu]="stateKey"
    (appKPStitchTypeContextMenuOpened)="menuOpened = $event"
    (appKPStitchTypeContextMenuChange)="handleTypeChange($event)"
    [collapsed]="!menuOpened"
  >
    {{ type | stitchListTitleByType }}
  </app-dropdown-label>

  <app-context-menu-state-indicator
    *ngIf="type === KPListType.messageFolder"
    class="header-filters-icon"
    svgIcon="filters"
    appMessagesFoldersListContextMenu
    [appMessagesFoldersListContextMenuWithScore]="true"
    (appMessagesFoldersListContextMenuChange)="handleFilter($event)"
  ></app-context-menu-state-indicator>

  <app-context-menu-state-indicator
    *ngIf="type === KPListType.message"
    class="header-filters-icon"
    svgIcon="filters"
    appMessagesListContextMenu
    [appMessagesListContextMenuWithScore]="true"
    (appMessagesListContextMenuChange)="handleFilter($event)"
  ></app-context-menu-state-indicator>

  <app-context-menu-state-indicator
    *ngIf="type === KPListType.event"
    class="header-filters-icon"
    svgIcon="filters"
    appEventsListContextMenu
    [appEventsListContextMenuWithScore]="true"
    (appEventsListContextMenuChange)="handleFilter($event)"
  ></app-context-menu-state-indicator>

  <app-context-menu-state-indicator
    *ngIf="type === KPListType.project"
    class="header-filters-icon"
    svgIcon="filters"
    appProjectsListContextMenu
    [appProjectsListContextMenuWithScore]="true"
    (appProjectsListContextMenuChange)="handleFilter($event)"
  ></app-context-menu-state-indicator>

  <app-context-menu-state-indicator
    *ngIf="type === KPListType.task"
    class="header-filters-icon"
    svgIcon="filters"
    appTasksListContextMenu
    [appTasksListContextMenuWithScore]="true"
    (appTasksListContextMenuChange)="handleFilter($event)"
  ></app-context-menu-state-indicator>

  <app-context-menu-state-indicator
    *ngIf="type === KPListType.notebook"
    class="header-filters-icon"
    svgIcon="filters"
    appNotebooksListContextMenu
    [appNotebooksListContextMenuWithScore]="true"
    (appNotebooksListContextMenuChange)="handleFilter($event)"
  ></app-context-menu-state-indicator>

  <app-context-menu-state-indicator
    *ngIf="type === KPListType.note"
    class="header-filters-icon"
    svgIcon="filters"
    appNotesListContextMenu
    [appNotesListContextMenuWithScore]="true"
    (appNotesListContextMenuChange)="handleFilter($event)"
  ></app-context-menu-state-indicator>

  <app-context-menu-state-indicator
    *ngIf="type === KPListType.group"
    class="header-filters-icon"
    svgIcon="filters"
    appGroupsListContextMenu
    [appGroupsListContextMenuWithScore]="true"
    (appGroupsListContextMenuChange)="handleFilter($event)"
  ></app-context-menu-state-indicator>

  <app-context-menu-state-indicator
    *ngIf="type === KPListType.contact"
    class="header-filters-icon"
    svgIcon="filters"
    appContactsListContextMenu
    [appContactsListContextMenuWithScore]="true"
    (appContactsListContextMenuChange)="handleFilter($event)"
  ></app-context-menu-state-indicator>

  <app-context-menu-state-indicator
    *ngIf="type === KPListType.folder"
    class="header-filters-icon"
    svgIcon="filters"
    appFoldersListContextMenu
    [appFoldersListContextMenuWithScore]="true"
    (appFoldersListContextMenuChange)="handleFilter($event)"
  ></app-context-menu-state-indicator>

  <app-context-menu-state-indicator
    *ngIf="type === KPListType.file"
    class="header-filters-icon"
    svgIcon="filters"
    appFilesListContextMenu
    [appFilesListContextMenuWithScore]="true"
    (appFilesListContextMenuChange)="handleFilter($event)"
  ></app-context-menu-state-indicator>

  <app-context-menu-state-indicator
    *ngIf="type === KPListType.connection"
    class="header-filters-icon"
    svgIcon="filters"
    appConnectionsManageContextMenu
    [appConnectionsManageContextMenuStateKey]="'kp-connections-section'"
    (appConnectionsManageContextMenuStateChange)="handleFilter($event)"
  ></app-context-menu-state-indicator>
</div>

<div class="list">
  <app-message-folders-list
    *ngIf="type === KPListType.messageFolder"
    [filters]="state[type]"
    [withTags]="tags.length > 0"
    [withKnots]="knots.length > 0"
    [knotsSource]="knotsSource"
    [withConnections]="connections.length > 0"
    (openItem)="handleClickItem($event)"
    (clickItem)="handleClickItem($event)"
    (doubleClickItem)="handleDoubleClickItem($event)"
  ></app-message-folders-list>

  <app-messages2-list
    *ngIf="type === KPListType.message"
    [filters]="state[type]"
    [withTags]="tags.length > 0"
    [withKnots]="knots.length > 0"
    [withConnections]="connections.length > 0"
    [knotsSource]="knotsSource"
    (openItem)="handleClickItem($event)"
    (clickItem)="handleClickItem($event)"
    (doubleClickItem)="handleDoubleClickItem($event)"
  ></app-messages2-list>

  <app-events-list
    *ngIf="type === KPListType.event"
    [filters]="state[type]"
    [withTags]="tags.length > 0"
    [withKnots]="knots.length > 0"
    [knotsSource]="knotsSource"
    (openEvent)="handleClickItem($event)"
    (clickEvent)="handleClickItem($event)"
    (doubleClickEvent)="handleDoubleClickItem($event)"
  ></app-events-list>

  <app-projects-list
    *ngIf="type === KPListType.project"
    [filters]="state[type]"
    [withTags]="tags.length > 0"
    [withKnots]="knots.length > 0"
    [knotsSource]="knotsSource"
    (openProject)="handleClickItem($event)"
    (clickProject)="handleClickItem($event)"
    (doubleClickProject)="handleDoubleClickItem($event)"
  ></app-projects-list>

  <app-tasks-list
    *ngIf="type === KPListType.task"
    [filters]="state[type]"
    [withTags]="tags.length > 0"
    [withKnots]="knots.length > 0"
    [knotsSource]="knotsSource"
    (openTask)="handleClickItem($event)"
    (clickTask)="handleClickItem($event)"
    (doubleClickTask)="handleDoubleClickItem($event)"
  ></app-tasks-list>

  <app-notebooks-list
    *ngIf="type === KPListType.notebook"
    [filters]="state[type]"
    [withTags]="tags.length > 0"
    [withKnots]="knots.length > 0"
    [knotsSource]="knotsSource"
    (openNotebook)="handleClickItem($event)"
    (clickNotebook)="handleClickItem($event)"
    (doubleClickNotebook)="handleDoubleClickItem($event)"
  ></app-notebooks-list>

  <app-notes-list
    *ngIf="type === KPListType.note"
    [filters]="state[type]"
    [withTags]="tags.length > 0"
    [withKnots]="knots.length > 0"
    [knotsSource]="knotsSource"
    (openNote)="handleClickItem($event)"
    (clickNote)="handleClickItem($event)"
    (doubleClickNote)="handleDoubleClickItem($event)"
  ></app-notes-list>

  <app-groups-list
    *ngIf="type === KPListType.group"
    [filters]="state[type]"
    [withTags]="tags.length > 0"
    [withKnots]="knots.length > 0"
    [knotsSource]="knotsSource"
    (openGroup)="handleClickItem($event)"
    (clickGroup)="handleClickItem($event)"
    (doubleClickGroup)="handleDoubleClickItem($event)"
  ></app-groups-list>

  <app-contacts-list
    *ngIf="type === KPListType.contact"
    [filters]="state[type]"
    [withTags]="tags.length > 0"
    [withKnots]="knots.length > 0"
    [knotsSource]="knotsSource"
    (openContact)="handleClickItem($event)"
    (clickContact)="handleClickItem($event)"
    (doubleClickContact)="handleDoubleClickItem($event)"
  ></app-contacts-list>

  <app-folders-list
    *ngIf="type === KPListType.folder"
    [filters]="state[type]"
    [withTags]="tags.length > 0"
    [withKnots]="knots.length > 0"
    [knotsSource]="knotsSource"
    (openFolder)="handleClickItem($event)"
    (clickFolder)="handleClickItem($event)"
    (doubleClickFolder)="handleDoubleClickItem($event)"
  ></app-folders-list>

  <app-connections-list
    *ngIf="type === KPListType.connection"
    [filters]="state[type]"
    (openConnection)="handleClickItem($event)"
    (clickConnection)="handleClickItem($event)"
    (doubleClickConnection)="handleDoubleClickItem($event)"
  ></app-connections-list>
</div>
