import { countries, CountryType } from '../../../../assets/countries/countries';

export class Country {
  name: CountryType['name'];
  native: CountryType['native'];
  phoneCode: CountryType['phoneCode'];
  iso2: CountryType['iso2'];
  iso3: CountryType['iso3'];

  constructor(country) {
    this.name = country?.name;
    this.native = country?.native;
    this.phoneCode = country?.phoneCode;
    this.iso2 = country?.iso2;
    this.iso3 = country?.iso3;
  }

  public static fromIso2(iso2Name) {
    const country = countries.find(({ iso2 }) => iso2 === iso2Name);

    return new Country(country);
  }

  public static fromIso3(iso3Name) {
    const country = countries.find(({ iso3 }) => iso3 === iso3Name);

    return new Country(country);
  }

  public static fromPhoneCode(value) {
    const country = countries.find(({ phoneCode }) => phoneCode === value);

    return new Country(country);
  }

  public asIso3() {
    return this.iso3;
  }
}
