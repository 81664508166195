// Common
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Types
import { Import } from '@modules/messages/types/import';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Injection Tokens
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// Services
import { ImportsService } from '@modules/messages/services/imports.service';

@Component({
  selector: 'app-settings-messages-import-form',
  templateUrl: './import-form.component.html',
  styleUrls: ['./import-form.component.less']
})
export class ImportFormComponent implements OnInit, OnDestroy {
  public form: FormGroup;

  // Private
  private alive = new Subject<void>();
  public submitted = false;

  constructor (
    @Inject(CloseToken) private closeToken,
    private importsService: ImportsService
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.form = new Import().asFormGroup();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleCancel() {
    this.closeToken.next();
  }

  handleSubmit() {
    this.submitted = true;

    if (!this.form.valid) { return; }

    const item = Import.fromFormGroup(this.form);

    this.importsService.create(item)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
          this.closeToken.next();
      });
  }
}
