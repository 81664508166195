<div>
  <span class="label-item">
    {{ label }}
  </span>

  <span
    *ngFor="let contact of (contacts | slice : 0 : (collapsed ? minLength : contacts.length)); let last = last"
    class="item"
    [class.selected]="selectedConnections | includesBy : contact.address : 'name'"
    (click)="selectContact(contact, $event)"
    [appParticipantContextMenu]="contact"
  >
    {{ contact | formatParticipant }}{{ last ? '' : ', ' }}

    <mat-icon
      *ngIf="contact?.vip"
      class="item-vip"
      svgIcon="crown"
    ></mat-icon>
  </span>

  <span
    *ngIf="collapsed && contacts?.length > minLength"
    class="link"
    (click)="toggleContacts()"
  >
    + {{ contacts.length - minLength }} more
  </span>

  <span
    *ngIf="!collapsed"
    class="link"
    (click)="toggleContacts()"
  >
    Show less
  </span>
</div>
