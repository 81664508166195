<div
  #droppableArea
  class="file-input"
  [class.dragover]="dragOver > 0"
  [class.error]="withErrors && !inputFormControl?.valid"
  (drop)="handleDrop($event)"
  (dragenter)="dragOver = dragOver + 1;"
  (dragleave)="dragOver = dragOver - 1;"
>
  Drop files to attach, or&nbsp;
  <div
    class="file-input-link"
    (click)="fileUploadInput.click()"
  >
    browse.
  </div>

  <input
    #fileUploadInput
    type="file"
    multiple
    id="files-input"
    (change)="handleChange($event)"
  >
</div>

<div class="uploads">
  <div
    *ngFor="let upload of uploads | filterBy : 'inline' : false; let index = index"
    class="uploads-item upload"
    [class.multiple]="multiple"
  >
    <div
      class="upload-type-icon"
      [style.backgroundColor]="upload.type | fileColorByType"
    >
      {{ upload.type | fileMimeToExt | uppercase }}
    </div>

    <div class="upload-name">
      {{ upload.name }}
    </div>

    <div class="upload-size">
      {{ upload.size | fileSize }}
    </div>

    <div
      *ngIf="(upload.progress | async) > 0"
      class="upload-progress"
      [class.error]="upload.error"
    >
      <div
        class="upload-progress-done"
        [class.error]="upload.error"
        [style.width.%]="upload.progress | async"
      ></div>
    </div>

    <mat-icon
      *ngIf="upload.temp && upload.error"
      class="upload-icon"
      [class.inactive]="uploadsInProgress > 0"
      svgIcon="retry"
      (click)="retry(index)"
    ></mat-icon>

    <mat-icon
      class="upload-icon"
      [class.inactive]="uploadsInProgress > 0"
      svgIcon="close-thin"
      (click)="delete(index)"
    ></mat-icon>
  </div>

  <div class="uploads-filler"></div>
  <div class="uploads-filler"></div>
  <div class="uploads-filler"></div>
  <div class="uploads-filler"></div>
  <div class="uploads-filler"></div>
</div>

<app-input-errors
  *ngIf="withErrors"
  [inputFormControl]="inputFormControl"
  [validationMessages]="validationMessages"
></app-input-errors>
