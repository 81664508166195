// Decorators
import { OnChange } from 'src/app/decorators/on-change';

// Types
import { BaseStitchListState } from '@modules/common/types/base-stitch-list-state';
import { Like } from '@modules/common/types/like';
import { StateKey } from '@modules/settings/types/state-key';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';

export class MessagesListState extends BaseStitchListState {
  @OnChange('onPropertyChange') sortBy?: 'title' | 'date' | 'score';
  @OnChange('onPropertyChange') today?: boolean;

  constructor(
    { searchService, stateService, stateKey, withAdvanced }:
    {
      searchService?: AdvancedSearchService,
      stateService?: StateService,
      stateKey?: StateKey,
      withAdvanced?: boolean
    }
  ) {
    super({ searchService, stateService, stateKey, withAdvanced });
  }

  protected applyState(state?: Like<MessagesListState>) {
    super.applyState(state);

    this.sortBy = state?.sortBy || 'date';
    this.sortOrder = state?.sortOrder || 'desc';
    this.today = !!state?.today;
  }

  onPropertyChange(attribute) {
    if (
      (attribute === 'from' && this.from) ||
      (attribute === 'to' && this.to)
    ) {
      this.today = false;
    }

    if (attribute === 'today' && this.today) {
      this.from = null;
      this.to = null;
    }

    super.onPropertyChange(attribute);
  }

  syncState() {
    super.syncState({
      sortBy: this.sortBy,
      today: this.today
    });
  }

  isDefault(): boolean {
    return (
      super.isDefault() &&
      this.sortBy === 'date' &&
      this.sortOrder === 'desc' &&
      !this.today
    );
  }

  syncFromAS({ mail: state }: AdvancedSearchState) {
    this.stitchedWith = state.stitched;
    this.from = state.dateRangeFrom;
    this.to = state.dateRangeTo;
  }

  syncToAS(attribute) {
    if (!['stitchedWith', 'flagged', 'from', 'to'].includes(attribute)) {
      return;
    }

    const searchState = this.searchService.getStateSync();
    const state = searchState.mail;

    switch (attribute) {
      case 'flagged':
        break;
      case 'stitchedWith':
        state.stitched = this.stitchedWith;
        break;
      case 'from':
        state.dateRangeFrom = this.from;
        break;
      case 'to':
        state.dateRangeTo = this.to;
        break;
    }

    this.searchService.setState(searchState);
  }
}
