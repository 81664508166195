// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { CommonModule as AppCommonModule } from '../common/common.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UtilsModule } from '@modules/utils/utils.module';

@NgModule({
  imports: [
    AppCommonModule,
    CommonModule,
    FormControlsModule,
    MatIconModule,
    MatTooltipModule,
    UtilsModule,
  ],
  declarations: [],
  exports: [],
})
export class LinkedInfoModule { }
