// Common
import { Component, Injector, Input, OnInit } from '@angular/core';

// RX
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, switchMap, startWith } from 'rxjs/operators';

// Types
import { Task } from '@modules/tasks/types/task';
import { DragDataTypes, DragData } from '@modules/drag-n-drop/types/drag-data';
import { Tab } from '@modules/common/types/tab';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { Column } from '@modules/tasks/types/column';
import { Row } from '@modules/tasks/types/row';
import { Section } from '@modules/common/types/section';
import { Project } from '@modules/tasks/types/project';
import { SpaceParticipant } from '@modules/settings/types/space-participant';
import { TasksListState } from '@modules/tasks/types/tasks-list-state';
import { TasksFilters } from '@modules/tasks/types/tasks-filters';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';
import { ColumnsService } from '@modules/tasks/services/columns.service';
import { SectionsService } from '@modules/tasks/services/sections.service';
import { RowsService } from '@modules/tasks/services/rows.service';
import { AlertService } from '@modules/alert/services/alert.service';
import { TaskingAppStateService } from '@modules/tasks/services/state.service';
import { TaskingAppSettingsService } from '@modules/tasks/services/settings.service';

// Components
import { FullFormBaseComponent } from '@modules/common/components/full-form-base/full-form-base.component';

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.less'],
  providers: [
    { provide: CalendarEventsService, useClass: TasksService }
  ]
})
export class TaskFormComponent extends FullFormBaseComponent<Task> implements OnInit {

  public changesKey = 'task';
  tabs: Tab[] = [
    { title: 'Task', value: 'task'},
    { title: 'Details', value: 'details'},
    { title: 'Attachments', value: 'attachments'},
    { title: 'Stitch', value: 'stitch'},
    { title: 'Lab', value: 'lab'},
    { title: 'Comments', value: 'comments'},
  ];
  tabsStateKey = 'ffTask';
  public suggestions: AutocompleteFactory<Project>;
  public columnsSuggestions: AutocompleteFactory<Column>;
  public sectionsSuggestions: AutocompleteFactory<Section>;
  public rowsSuggestions: AutocompleteFactory<Row>;
  public assigneeSuggestions: AutocompleteFactory<SpaceParticipant>;
  public triggerRefreshSubtasks = new Subject<void>();
  public showIssueKey = false;
  public subTasksListState = new BehaviorSubject<TasksListState>(null);
  public tasksFilters: TasksFilters;

  protected dragDataType = DragDataTypes.task;

  @Input() task = new Task();
 
  constructor(
    private tasksService: TasksService,
    private projectsService: ProjectsService,
    private columnsService: ColumnsService,
    private sectionsService: SectionsService,
    private rowsService: RowsService,
    injector: Injector,
    private alertService: AlertService,
    tasksAppStateService: TaskingAppStateService,
    private settingsService: TaskingAppSettingsService,
  ) {
    super(injector, tasksService, tasksAppStateService);
    this.suggestions = this.projectsService.getAutocompleteSuggestions();
    this.assigneeSuggestions = this.tasksService.getAssigneeAutocompleteSuggestions();
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();
    this.stitchItemChanged
      .pipe(
        switchMap(() => this.form.controls.projectId.valueChanges
          .pipe(startWith(<string> this.form.controls.projectId.value))
        ),
        takeUntil(this.alive)
      )
      .subscribe(projectId => {
        this.columnsSuggestions = this.columnsService.getAutocompleteSuggestions({ projectsIds: [projectId]});
        this.sectionsSuggestions = this.sectionsService.getAutocompleteSuggestions({ containersIds: [projectId]});
        this.rowsSuggestions = this.rowsService.getAutocompleteSuggestions({ projectsIds: [projectId]});

        this.form.patchValue({ sectionId: null, rowId: null, columnId: null }, { emitEvent: false });
      });

    this.stitchItemChanged
      .pipe(
        switchMap(() => this.subTasksListState),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.tasksFilters = new TasksFilters({ parentsIds: [this.stitchItem.id] });
        this.tasksFilters.applyListState(this.subTasksListState.getValue());
      });

    this.settingsService.listAll()
      .pipe(takeUntil(this.alive))
      .subscribe(({ issueKeyEnabled }) => this.showIssueKey = issueKeyEnabled);
  }

  /**
   * Actions
   */

  public handleCreateSubTaskOnDrop(dragData: DragData): void {
    if (!this.stitchItem || !this.stitchItem.id) { return; }

    const subtask = Task.fromDragData(dragData);
    subtask.parentId = this.stitchItem.id;

    this.tasksService.create(subtask, { emitUpdate: false })
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.triggerRefreshSubtasks.next());
  }

  public handleLinkOnDrop(dragData: DragData): void {
    this.stitchService.linkDragData(this.stitchItem, dragData);
  }

  dndDrop(dragData: DragData) {
    if (!this.stitchItem || !this.stitchItem.id) { return; }

    if (
      dragData.type === DragDataTypes.knot ||
      dragData.type === DragDataTypes.tag ||
      dragData.type === DragDataTypes.connection
    ) {
      this.stitchService.linkDragData(this.stitchItem, dragData);
    } else {
      this.alertService.show({
        title: 'Stitch or Subtask?',
        body: 'What do we do with the dropped item?',
        rightButtons: [
          {
            title: 'CANCEL',
            close: true
          },
          {
            title: 'Create new Subtask',
            click: () => { this.handleCreateSubTaskOnDrop(dragData); }
          },
          {
            title: 'Stitch an Item',
            click: () => { this.handleLinkOnDrop(dragData); },
            close: true
          }
        ]
      });
    }
  }

  handleComplete() {
    this.stitchItem.completed = !this.stitchItem.completed;
    this.tasksService.update(this.stitchItem);
  }

  /**
   * Helpers
   */

  get stitchItem(): Task {
    return this.task;
  }

  protected shouldRefreshList(prev, current) {
    return Task.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): Task {
    return Task.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return this.task.asFormGroup();
  }
}
