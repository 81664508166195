import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sameTime'
})
export class SameTimePipe implements PipeTransform {

  transform(time1: Date, time2: Date): boolean {
    return time1 &&
      time2 &&
      time1.getHours() === time2.getHours() &&
      time1.getMinutes() === time2.getMinutes();
  }
}
