// Types
import { Like } from '@modules/common/types/like';
import { Filters } from '@modules/common/types/filters';
import { PermissionParticipant } from './permission-participant';

export class PermissionParticipantsFilters extends Filters {
  type?: PermissionParticipant['type'];
  sortBy?: 'createdAt';
  values?: string[];

  constructor (filters?: Like<PermissionParticipantsFilters>) {
    super(filters);
    this.sortBy = filters?.sortBy;
    this.type = filters?.type;
    this.values = Array.isArray(filters?.values) ? filters.values : [];
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.type) { result['type'] = this.type; }
    if (this.values) { result['values[]'] = this.values; }

    result['sort_by'] = this.sortBy || 'createdAt';

    return result;
  }
}
