<app-context-menu-item
  *ngIf="!stitch?.shareUid"
  [close]="true"
  title="Share via Link"
  (execute)="shareViaLink()"
/>

<ng-container *ngIf="stitch?.shareUid">
  <app-context-menu-heading>Anyone on the internet with the link can view</app-context-menu-heading>

  <app-context-menu-item
    [close]="true"
    title="Copy Link"
    (execute)="copyLink()"
  />

  <app-context-menu-item
    [close]="true"
    title="Revoke Access via Link"
    (execute)="revokeAccessViaLink()"
  />
</ng-container>
