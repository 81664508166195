<div class="form">
  <div class="form-date">{{ message?.date | date : 'MMM d' }}</div>

  <h2 class="form-title">
    {{ message?.subject }}
  </h2>

  <app-caja
    *ngIf="message?.bodyHtml"
    [body]="message?.bodyHtml"
  />

  <app-attachments [stitchItem]="message"/>

  <div class="form-details">
    <div class="form-details-row">
      <div class="form-details-row-title">Folder</div>
      <div class="form-details-row-input">{{ message?.folderId }}</div>
    </div>
  </div>
</div>
