// Common
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// Types
import { UserValidator } from '@modules/auth/validators/user.validator';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';

// RxJS
import { of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.less']
})
export class SignupComponent implements OnInit, OnDestroy {
  // Private
  private industries = [
    'Accounting',
    'Administration & Office Support',
    'Advertising, Arts & Media',
    'Banking & Financial Services',
    'Call Centre & Customer Service',
    'Community Services & Development',
    'Construction',
    'Consulting & Strategy',
    'Design & Architechture',
    'Education & Training',
    'Engineering',
    'Farming, Animals & Conservation',
    'Government & Defence',
    'Healthcare & Medical',
    'Hospitality & Tourism',
    'Human Resources & Recruitment',
    'Information & Communication Technology',
    'Insurance & Superannuation',
    'Legal',
    'Manufacturing, Transport & Logistics',
    'Marketing & Communications',
    'Mining, Resources & Energy',
    'Real Estate & Property',
    'Retail & Consumer Products',
    'Sales',
    'Science & Technology',
    'Sport & Recreation',
    'Trades & Services'
  ];
  private alive = new Subject<void>();

  // Public
  public sources = [
    'Recommendations',
    'Search Engine (Google, Yahoo…)',
    'Blog or Publication',
    'Social Media',
    'Reading an Article',
    'Promotional Videos',
    'Podcast',
    'Type your option',
  ];
  public form1 = new UntypedFormGroup(
    {
      userName: new UntypedFormControl('', {
        validators: [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9.]+$')
        ],
        asyncValidators: [UserValidator.unique(this.authService)],
        updateOn: 'blur'
      }),
      recoveryEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl(''),
      password: new UntypedFormControl('', [Validators.required, UserValidator.weekPassword]),
      passwordConfirmation: new UntypedFormControl(''),
    },
    [UserValidator.passwordConfirmation('password', 'passwordConfirmation')]
  );
  public form2 = new UntypedFormGroup({
    birthDate: new UntypedFormControl(),
    industry: new UntypedFormControl(),
    from: new UntypedFormControl(),
    newsletter: new UntypedFormControl(false),
    termsAccepted: new UntypedFormControl(false, [Validators.requiredTrue])
  });
  public submitted = false;
  public step = 1;
  public loading = false;
  public suggestions: AutocompleteFactory<string> = (title?: string, values?: string[]) => of(
    this.industries
      .filter(item => values
        ? item.toLowerCase().includes(values[0]?.trim()?.toLowerCase())
        : item.toLowerCase().includes(title?.trim()?.toLowerCase())
      )
      .map(item => ({ title: item, value: item }))
  )

  /**
   * Constructor
   */

  constructor(
    private authService: AuthService,
    private router: Router
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.form1.statusChanges
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(status => {
        switch (status) {
          case 'VALID':
            if (this.submitted && this.loading) {
              this.step = 2;
            }
            this.loading = false;
            break;
          case 'INVALID':
            this.loading = false;
            break;
          case 'PENDING':
            this.loading = true;
            break;
          default:
            this.loading = false;
        }
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  goToStep2() {
    if (this.form1.valid) {
      this.submitted = false;
      this.step = 2;
    } else {
      this.submitted = true;
    }
  }

  handleSubmit() {
    this.submitted = true;

    if (!this.form2.valid) { return; }

    this.authService.signUp({ ...this.form1.value, ...this.form2.value })
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(({ success }) => {
        if (success) {
          this.router.navigate(['']);
        }
      });
  }
}
