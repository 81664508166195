<div class="panel" *ngIf="!minimized">
  <div class="panel-heading">
    <div class="panel-heading-top">
      <div class="panel-heading-top-title">
        {{ sidebarFilter | titleByEventsFilter }}
      </div>

      <div class="panel-heading-top-separator"></div>

      <div
        class="debug"
        [class.active]="debug"
        (click)="doDebug()"
      >
        {{ debug || 'Debug' }}
      </div>

      <div class="panel-heading-top-filters">
        <app-context-menu-state-indicator
          *ngIf="selectedTab === 'events'"
          svgIcon="filters"
          appEventsListContextMenu
          [appEventsListContextMenuWithAdvancedSearch]="true"
          [appEventsListContextMenuStateKey]="eventsListStateKey"
          (appEventsListContextMenuChange)="handleEventsListStateChange($event)"
        />

        <app-context-menu-state-indicator
          *ngIf="selectedTab === 'calendars'"
          svgIcon="filters"
          appCalendarsListContextMenu
          [appCalendarsListContextMenuWithAdvancedSearch]="true"
          [appCalendarsListContextMenuStateKey]="calendarsListStateKey"
          (appCalendarsListContextMenuChange)="handleCalendarsListStateChange($event)"
        />
      </div>
    </div>

    <app-tabs
      *ngIf="listState === 'tabs'"
      class="panel-heading-tabs"
      [values]="tabs"
      [(value)]="selectedTab"
    ></app-tabs>
  </div>

  <app-quick-form
    *ngIf="
    selectedTab === 'events' &&
    sidebarFilter !== 'archived' &&
    sidebarFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New Event"
  >
    <app-event-quick-form
      [event]="quickEvent"
      (more)="handleNewEvent($event)"
    ></app-event-quick-form>
  </app-quick-form>

  <app-quick-form
    *ngIf="
    selectedTab === 'calendars' &&
    sidebarFilter !== 'archived' &&
    sidebarFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New Calendar"
  >
    <app-calendar-quick-form
      [calendar]="quickCalendar"
      (more)="handleNewCalendar($event)"
    ></app-calendar-quick-form>
  </app-quick-form>

  <div class="panel-list">
    <app-events-list
      *ngIf="selectedTab === 'events'"
      [filters]="eventsFilters"
      [debug]="debug"
      (loadInProgress)="loading = $event"
      (openEvent)="handleClickEvent($event)"
      (clickEvent)="handleClickEvent($event)"
      (doubleClickEvent)="handleDoubleClickItem($event)"
    ></app-events-list>

    <app-calendars-list
      *ngIf="selectedTab === 'calendars'"
      [filters]="calendarsFilters"
      [debug]="debug"
      (loadInProgress)="loading = $event"
      (openCalendar)="handleClickCalendar($event)"
      (clickCalendar)="handleClickCalendar($event)"
      (doubleClickCalendar)="handleDoubleClickItem($event)"
    />
  </div>
</div>
