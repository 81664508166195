// Common
import { Component, Injector } from '@angular/core';

// Services
import { ContactsService } from '@modules/contacts/services/contacts.service';

// Types
import { Contact } from '@modules/contacts/types/contact';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less'],
})
export class ContactComponent extends BaseStitchComponent<Contact> {
  moveDragDataTypes = [];
  dragDataType = DragDataTypes.contact;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(
      dragData.type === DragDataTypes.contact &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.item.id
    ) &&
    dragDataTypeAllowed(dragData.type)


  constructor(
    injector: Injector,
    protected contactsService: ContactsService,
  ) { super(injector, contactsService); }

  handleMove(dragData: DragData) {}

  get contact(): Contact {
    return this.item;
  }
}
