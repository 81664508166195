<div class="block">
  <div
    *ngFor="let template of autoReplyTemplates"
    class="block-item"
  >
    <span class="block-item-title">{{ template.title }}</span>

    <app-button
      label="Edit Template"
      appearance="purple-outline"
      (click)="handleOpenForm(template)"
    />

    <app-button
      label="Remove Template"
      appearance="purple"
      (click)="handleRemove(template)"
    />
  </div>
</div>
<div class="block no-border">
  <app-button
    label="Create Template"
    appearance="purple"
    (click)="handleOpenForm()"
  />
</div>


