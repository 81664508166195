// Common
import { Component, OnDestroy, Input, OnInit, SimpleChanges, OnChanges, NgZone, Output, EventEmitter, Self, Inject } from '@angular/core';

// RxJS
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { takeUntil, filter, debounceTime } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { NotesAppStateService } from '@modules/notes/services/state.service';

// Injection Tokens
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';

// Types
import { DropdownOption } from '@modules/dropdown/types/dropdown-option';
import { NotesFilters } from '@modules/notes/types/notes-filters';
import { Note } from '@modules/notes/types/note';
import { Like } from '@modules/common/types/like';
import { KnotSource } from '@modules/knots/types/knot-source';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }]
})
export class NotesListComponent extends InfinityScrollListComponent implements OnInit, OnDestroy, OnChanges {

  // Inputs
  @Input() selectedOrder: DropdownOption;
  @Input() scrollPosition: number;
  @Input() placeholderText = 'You have no notes';
  @Input() filters: Like<NotesFilters> = {};
  @Input() withTags = false;
  @Input() withKnots = false;
  @Input() knotsSource: KnotSource;
  @Input() debug: 'score';

  // Outputs
  @Output() clickNote = new EventEmitter<Note>();
  @Output() openNote = new EventEmitter<Note>();
  @Output() doubleClickNote = new EventEmitter<Note>();

  // Public
  public itemHeight = 72;
  public isHover = false;
  public showCountView = new BehaviorSubject(true);
  public resetSelected = new Subject<void>();

  constructor(
    private notesService: NotesService,
    private linkedInfoService: LinkedInfoService,
    protected ngZone: NgZone,
    @Self() @Inject(ScrollToPosition) scrollToPositionSubject,
    private notesStateService: NotesAppStateService
  ) {
    super(ngZone, scrollToPositionSubject);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.showCountView
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      this.linkedInfoService.getRefreshRequired(),
      this.notesService.getRefreshRequired()
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.refreshCurrentItems();
      });

    this.refreshCurrentItems();

    merge(
      this.notesStateService.getRefreshAll(),
      this.notesService.getRefreshRequired(),
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.resetSelected.next();
      });

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('selectedOrder' in changes && this.selectedOrder) {
      this.resetItems();
    }

    if ('filters' in changes) {
      this.resetItems();
    }

    if ('scrollPosition' in changes && this.scrollPosition !== null) {
      this.scrollToIndex(this.scrollPosition >= 0 ? this.scrollPosition : this.items ? this.items.length : 0);
    }
  }

  /**
   * Actions
   */

  getItems(offset: number, limit: number): Observable<{ items: Note[], count: number }> {
    return this.notesService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: false, knotsSource: this.knotsSource }
    );
  }

  resetItems() {
    this.resetSelected.next();
    super.resetItems();
  }

  handleDoubleClickItem(note: Note) {
    this.doubleClickNote.emit(note);
  }

  handleClickItem(note: Note) {
    this.clickNote.emit(note);
  }
}
