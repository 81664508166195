<app-dropdown-base
  [placeholder]="placeholder"
  [withClear]="false"
  [appearance]="appearance"
  [size]="size"
  [disabled]="disabled"
  [placement]="placement"
  [popoverHide]="popoverHide"
  [valueTemplate]="valueTemplate"
  [popoverTemplate]="popoverTemplate"
/>

<ng-template #valueTemplate>
  {{ placeholder | titlecase }}
</ng-template>

<ng-template #popoverTemplate>
  <app-recurrence-dropdown
    [control]="control"
    [referenceDate]="referenceDate"
    appearance="sapphire"
    (onCustom)="handleCustom()"
    (onSelect)="handleSelect()"
  />
</ng-template>

<ng-template #modalTemplate>
  <app-recurrence-form
    [control]="innerControl"
    [referenceDate]="referenceDate"
  />
</ng-template>
