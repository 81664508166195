// Common
import { ComponentRef, Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { ShareContextMenuComponent } from '../components/share-context-menu/share-context-menu.component';

// Types
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';

// RX
import { Stitch } from '../types/stitch';

@Directive({
  selector: '[stchShareContextMenu]'
})
export class ShareContextMenuDirective extends ContextMenuDirective implements OnInit, OnChanges {

  private instance: ShareContextMenuComponent;

  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';
  @Input() stchShareContextMenu: Stitch;
  @Input() stchShareContextMenuDisabled = false;

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.stchPopoverDisabled = this.stchShareContextMenuDisabled;
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if ('stchShareContextMenu' in changes && this.instance) {
      this.instance.stitch = this.stchShareContextMenu;
    }

    if ('stchPopoverDisabled' in changes) {
      this.stchPopoverDisabled = this.stchShareContextMenuDisabled;
    };
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<ShareContextMenuComponent>) {
    this.instance = componentRef.instance;
    this.instance.stitch = this.stchShareContextMenu;
  }

  registerComponent() {
    return ShareContextMenuComponent;
  }
}
