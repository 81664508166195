// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Types
import { StitchType } from '@modules/common/types/stitch-type';
import { StitchTypeFilters } from '@modules/knowledge/types/stitch-type-filters';

@Component({
  selector: 'app-stitch-type-context-menu-item',
  templateUrl: './stitch-type-context-menu-item.component.html',
  styleUrls: ['./stitch-type-context-menu-item.component.less']
})
export class StitchTypeContextMenuItemComponent {
  // Input
  @Input() state: StitchTypeFilters;
  @Input() withCheckOption: boolean;
  @Input() title: string;

  // Outputs
  @Output() onSelect = new EventEmitter<StitchTypeFilters>();

  // Public
  public StitchType = StitchType;

  /**
   * Actions
   */

  select(stitchType: StitchType) {
    this.onSelect.emit({
      ...this.state,
      [stitchType]: !this.state[stitchType]
    });
  }

  selectAll () {
    const value = this.state[StitchType.messageFolder] ||
      this.state[StitchType.message] ||
      this.state[StitchType.event] ||
      this.state[StitchType.project] ||
      this.state[StitchType.task] ||
      this.state[StitchType.notebook] ||
      this.state[StitchType.note] ||
      this.state[StitchType.group] ||
      this.state[StitchType.contact] ||
      this.state[StitchType.folder] ||
      this.state[StitchType.file];

    this.onSelect.emit({
      [StitchType.messageFolder]: !value,
      [StitchType.message]: !value,
      [StitchType.event]: !value,
      [StitchType.project]: !value,
      [StitchType.task]: !value,
      [StitchType.notebook]: !value,
      [StitchType.note]: !value,
      [StitchType.group]: !value,
      [StitchType.contact]: !value,
      [StitchType.folder]: !value,
      [StitchType.file]: !value,
    });
  }
}
