// Common
import { Component, Injector } from '@angular/core';

// Types
import { Note } from '@modules/notes/types/note';
import { StitchType } from '@modules/common/types/stitch-type';

// Components
import { SharedBaseComponent } from '@modules/common/components/shared-base/shared-base.component';

@Component({
  selector: 'app-note-shared',
  templateUrl: './note-shared.component.html',
  styleUrls: ['./note-shared.component.less'],
})
export class NoteSharedComponent extends SharedBaseComponent<Note> {

  protected stitchType = StitchType.note;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  get note() {
    return this.stitchItem;
  }
}
