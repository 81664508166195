// Common
import { Component, Injector, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';

// Services
import { SelectableService } from '@modules/drag-n-drop/services/selectable.service';
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { MailAppStateService } from '@modules/messages/services/state.service';

// Types
import { FoldersFilters } from '@modules/messages/types/folders-filters';
import { MessageFolder } from '@modules/messages/types/message-folder';
import { Message } from '@modules/messages/types/message';
import { Application } from '@modules/common/types/application';
import { StateKey } from '@modules/settings/types/state-key';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Stitch } from '@modules/common/types/stitch';
import { VirtualFolder } from '@modules/messages/types/virtual-folder';

// Components
import { BaseSidebarContainersTreeComponent } from '@modules/common/components/base-sidebar-containers-tree/base-sidebar-containers-tree.component';

@Component({
  selector: 'app-side-bar-folders-tree',
  templateUrl: './side-bar-folders-tree.component.html',
  styleUrls: ['./side-bar-folders-tree.component.less'],
  animations: [heightAnimation],
  providers: [SelectableService]
})
export class SideBarFoldersTreeComponent extends BaseSidebarContainersTreeComponent<MessageFolder, Message, FoldersFilters, VirtualFolder> implements OnInit {

  selfDragDataTypes = [DragDataTypes.message, DragDataTypes.messageFolder];
  application = Application.mail;
  treeStateKey = StateKey.mailSidebarFoldersTree;

  dndPredicate = (stitchItem: Stitch) => (dragData: DragData): boolean =>
    stitchItem &&
    !(
      dragData.type === DragDataTypes.messageFolder &&
      dragData.data.length === 1 &&
      dragData.data?.[0]?.id === stitchItem?.id
    ) &&
    dragDataTypeAllowed(dragData.type)

  constructor(
    injector: Injector,
    private foldersService: MessageFoldersService,
    private messagesService: MessagesService,
    mailAppStateService: MailAppStateService,
  ) {
    super(injector, foldersService, mailAppStateService);
  }

  /**
   * Actions
   */

  public handleMove(dragData: DragData, item: MessageFolder) {
    const message = 'Successfully moved to ' + item.title;

    if (dragData.type === DragDataTypes.message) {
      this.messagesService.bunchUpdate(
        { ids: this.getIds(dragData.data) },
        { folderId: item.id },
        { message }
      );
    } else if (dragData.type === DragDataTypes.messageFolder) {
      this.foldersService.bunchUpdate(
        { ids: this.getIds(dragData.data) },
        { parentId: item.id },
        { message }
      );
    }
  }
}
