// Common
import { ComponentRef, Directive, EventEmitter, Injector, Input, OnChanges, OnInit, Output } from '@angular/core';
import { isEqual } from 'lodash';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { ManageContextMenuComponent } from '../components/manage-context-menu/manage-context-menu.component';

// Types
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { ManageListState } from '../types/manage-list-state';
import { StateKey } from '@modules/settings/types/state-key';

// RX
import { map, take, takeUntil } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';

@Directive({
  selector: '[appConnectionsManageContextMenu]'
})
export class ManageContextMenuDirective extends ContextMenuDirective implements OnInit, OnChanges {

  // Inputs
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';
  @Input() appConnectionsManageContextMenu: boolean;
  @Input() appConnectionsManageContextMenuStateKey: StateKey;
  @Input() appConnectionsManageContextMenuNeighbours: boolean;

  // Outputs
  @Output() appConnectionsManageContextMenuStateChange = new EventEmitter<ManageListState>();

  // Private
  private defaultState: ManageListState = {
    sort: {
      by: 'name',
      order: 'asc'
    },
    filters: {
      createdFrom: null,
      createdTo: null
    }
  };
  private state: ManageListState = { ...this.defaultState };

  constructor (
    private stateService: StateService,
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<ManageContextMenuComponent>) {
    componentRef.instance.state = this.state;

    componentRef.instance.stateChange
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(state => {
        this.setState(state);
        componentRef.instance.state = this.state;

        this.appConnectionsManageContextMenuStateChange.emit(this.state);

        if (this.appConnectionsManageContextMenuStateKey) {
          this.stateService.setState(this.appConnectionsManageContextMenuStateKey, this.state);
        }
      });
  }

  registerComponent() {
    return ManageContextMenuComponent;
  }

  /**
   * Lifecycle
   */

   ngOnInit() {
    this.stateService.getState(this.appConnectionsManageContextMenuStateKey)
      .pipe(
        map(state => Object.assign({}, this.state, state)),
        map((state: any) => ({
          ...(state || {}),
          filters: {
            ...(state?.filters || {}),
            createdFrom: state?.filters?.createdFrom ? new Date(state.filters.createdFrom) : null,
            createdTo: state?.filters?.createdTo ? new Date(state.filters.createdTo) : null,
          }
        })),
        take(1),
        takeUntil(this.alive)
      )
      .subscribe((state: ManageListState) => {
        this.setState(state);
        this.appConnectionsManageContextMenuStateChange.emit(this.state);
      });

    this.isDefaultStateObservable
      ?.pipe(takeUntil(this.alive))
      ?.subscribe(isDefaultState => {
        if (isDefaultState && !isEqual(this.defaultState, this.state)) {
          this.state = this.defaultState;
          this.appConnectionsManageContextMenuStateChange.emit(this.defaultState);
          this.stateService.setState(this.appConnectionsManageContextMenuStateKey, this.defaultState);
        }
      });
  }

  /**
   * Actions
   */

  setState(newState: ManageListState) {
    this.isDefaultStateObservable?.next(isEqual(this.defaultState, newState));

    this.state = newState;
  }
}
