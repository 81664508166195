import { isEqual } from 'lodash';

export function OnChange<T = any>(methodName: string) {
  return (target: T, key: keyof T): void => {
    const originalDescriptor = Object.getOwnPropertyDescriptor(target, key);

    Object.defineProperty(target, key, {
      set(value) {
        this.__innerValue__ ||= {};
        const previousValue = this.__innerValue__[key];

        if (typeof value === 'object' && isEqual(value, previousValue)) {
          return;
        }

        if (value instanceof Date && value.getTime() === previousValue?.getTime()) {
          return;
        }

        if (previousValue === value) {
          return;
        }

        if (originalDescriptor) {
          originalDescriptor.set.call(this, value);
        }

        this.__innerValue__[key] = value;

        if (methodName) {
          this[methodName].call(this, key, value, previousValue);
        }
      },
      get() {
        if (originalDescriptor) {
          return originalDescriptor.get.call(this);
        }

        return this.__innerValue__?.[key];
      }
    });
  };
}
