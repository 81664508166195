import { Filters } from '@modules/common/types/filters';
import { Like } from '@modules/common/types/like';

export class SectionsFilters extends Filters {
  containersIds?: string[];
  title?: string;

  constructor (filters?: Like<SectionsFilters>) {
    super(filters);
    this.containersIds = filters?.containersIds;
    this.title = filters?.title;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.containersIds) { result['containers_ids[]'] = this.containersIds; }
    if (this.title) { result['title'] = this.title; }

    return result;
  }
}
