<div class="container" [ngClass]="appearance">
  <div class="label">
    <ng-content></ng-content>
  </div>

  <mat-icon
    class="icon"
    [class.collapsed]="!collapsed"
    [svgIcon]="appearance === 'default' ? 'dropdown-down' : 'chevron-down'"
  ></mat-icon>
</div>
