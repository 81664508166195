import { Pipe, PipeTransform } from '@angular/core';
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { KPListType } from '@modules/knowledge/types/kp-list-type';

@Pipe({
  name: 'stitchListTitleByType'
})
export class StitchListTitleByTypePipe implements PipeTransform {

  transform(type: KPListType): string {
    if (!type) { return ''; }

    switch (type) {
      case KPListType.messageFolder:
        return 'Message Folders';
      case KPListType.message:
        return 'Messages';
      case KPListType.event:
        return 'Events';
      case KPListType.project:
        return 'Projects';
      case KPListType.task:
        return 'Tasks';
      case KPListType.notebook:
        return 'Notebooks';
      case KPListType.note:
        return 'Notes';
      case KPListType.group:
        return 'Groups';
      case KPListType.contact:
        return 'Contacts';
      case KPListType.folder:
        return 'Folders';
      case KPListType.file:
        return 'Files';
      case KPListType.connection:
        return 'Connections';
      default:
        checkExhaustiveness(type);
    }
  }
}
