// Common
import { Component, OnInit, OnDestroy, Input, OnChanges, ElementRef, SimpleChanges, Self } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

// Types
import { Project } from '@modules/tasks/types/project';
import { SectionTaskAppearance } from '@modules/tasks/types/section-task-appearance';
import { Row } from '@modules/tasks/types/row';

// Services
import { BoardService } from '@modules/tasks/services/board.service';
import { SplitViewService } from '@modules/split-view/services/split-view.service';

@Component({
  selector: 'app-project-board',
  templateUrl: './project-board.component.html',
  styleUrls: ['./project-board.component.less'],
  providers: [BoardService]
})
export class ProjectBoardComponent implements OnInit, OnChanges, OnDestroy {

  @Input() project: Project;

  public tasksAppearance: SectionTaskAppearance;
  public rows: Row[] = [];

  private alive: Subject<void> = new Subject();
  private projectChanged = new Subject<void>();

  constructor (
    @Self() private boardService: BoardService,
    private element: ElementRef,
    private splitViewService: SplitViewService
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.boardService.getRows()
      .pipe(takeUntil(this.alive))
      .subscribe(rows => {
        this.rows = rows;
      });

    this.projectChanged
      .pipe(
        filter(() => !!this.project),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.boardService.setProject(this.project);
      });

    this.projectChanged.next();
 
    this.splitViewService.getSizeChanges()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => this.calculateAppearance());

    this.calculateAppearance();
  }

  ngOnChanges (changes: SimpleChanges) {
    if ('project' in changes) {
      this.projectChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.boardService.detach();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Helpers
   */

  calculateAppearance() {
    const size = this.element.nativeElement.getBoundingClientRect().width;

    if (size < 660) {
      this.tasksAppearance = 'board-small';
    } else if (size >= 660 && size < 1008) {
      this.tasksAppearance = 'board-medium';
    } else {
      this.tasksAppearance = 'board-large';
    }
  }

  /**
   * Actions
   */

  addRow() {
    this.boardService.addRow();
  }
}
