// Common
import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.less'],
})
export class ColorInputComponent {

  @Input() control: UntypedFormControl;
  @Input() size = 18;
  @Input() icon: string;

  public colors = [
    '#842D44', '#277A79',
    '#E9590D', '#62A60A',
    '#8C4CD1', '#2864E8',
    '#8f92a0', '#cccfdb',
    '#558b2f', '#aed581'
  ];

  /**
   * Actions
   */

  handleSelect(color: string) {
    this.control.setValue(color === this.control.value ? null : color);
    this.control.markAsDirty();
  }
}
