// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';

// Types
import { SpaceSettings } from '../types/space-settings';

// Services
import { BaseSettingsService } from '@modules/common/services/base-settings.service';

@Injectable()
export class SpaceSettingsService extends BaseSettingsService<SpaceSettings> {
  protected url = `${environment.baseUrl}/api/account/settings`;

  constructor (http: HttpClient) {
    super(http);
  }

  createInstance(data?: any) {
    return new SpaceSettings(data);
  }
}
