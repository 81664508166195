import { Component, EventEmitter, Input, OnChanges, Output, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { CalendarContact } from '@modules/calendar-app/types/calendar-contact';

@Component({
  selector: 'app-contacts-counter-dropdown',
  templateUrl: './contacts-counter-dropdown.component.html',
  styleUrls: ['./contacts-counter-dropdown.component.less']
})
export class ContactsCounterDropdownComponent implements OnChanges, OnDestroy {

  @Input() contactList: Array<CalendarContact>;
  @Input() selectedContact: CalendarContact;
  @Input() minContactLen = 3;
  @Input() size = 30;
  @Input() showNoReplyMark = false;
  @Output() selectedContactChange = new EventEmitter<CalendarContact>();

  // Public
  public hidePopover = new Subject();
  public haveNoReplies = false;

  visibleContacts: Array<CalendarContact> = [];
  hiddenContactsState: Array<CalendarContact> = [];

  // TODO: Should be replaced with forEach or Lodash method isEqual for any IE support
  /**
   * Check if selected element exist in current contact list with avatars
   * for instance (TO || CC || BCC)
   * @param {Array<CalendarContact>} contacts
   * @param {CalendarContact} searchContact
   * @returns {CalendarContact | undefined}
   */
  private findContact (contacts: Array<CalendarContact>, searchContact: CalendarContact): CalendarContact | undefined {
    return contacts.find(contact => contact.email === searchContact.email);
  }

  constructor() { }

  ngOnChanges() {
    if (this.showNoReplyMark) {
      this.haveNoReplies = this.contactList.some((contact: CalendarContact) => contact.status === 'noreply');
    }
    this.visibleContacts = this.contactList.slice(0, this.minContactLen);
    this.hiddenContactsState = this.contactList.slice(this.minContactLen, undefined);

    if (!this.selectedContact || !this.findContact(this.contactList, this.selectedContact)) {
      return;
    }

    if (!this.findContact(this.visibleContacts, this.selectedContact)) {
      this.visibleContacts.push(this.selectedContact);
    }

    // TODO: findIndex should be replaced with method from Lodash or covered by polyfill for any IE support
    if (this.findContact(this.hiddenContactsState, this.selectedContact)) {
      const indexSelected = this.hiddenContactsState.findIndex((contact: CalendarContact) => contact.email === this.selectedContact.email);

      this.hiddenContactsState.splice(indexSelected, 1);
    }
  }

  ngOnDestroy() {
    this.hidePopover.next();
    this.hidePopover.complete();
  }

  selectContact(contact: CalendarContact) {
    if (this.selectedContact === contact) {
      this.selectedContact = null;
    } else {
      this.selectedContact = contact;
    }
    this.selectedContactChange.emit(this.selectedContact);
  }

  selectCollapsedContact(contact: CalendarContact) {
    this.selectContact(contact);
    this.hidePopover.next();
  }
}
