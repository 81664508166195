// Common
import { Injector } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

// Types

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }]});

export class Column {
  formBuilder = injector.get(UntypedFormBuilder);

  id?: string;
  title?: string;
  position?: number;
  projectId?: string;
  color?: string;
  uncategorized?: boolean;
  completed?: boolean;

  // Tech properties

  /**
   * temp - new column created but not sync with backend
   */
  temp?: boolean;

  constructor(columnObject: any = {}) {
    this.id = columnObject.id;
    this.title = columnObject.title;
    this.position = columnObject.position;
    this.projectId = columnObject.projectId;
    this.uncategorized = columnObject.uncategorized;
    this.temp = columnObject.temp;
    this.color = columnObject.color;
    this.completed = columnObject.completed || false;
  }

  static fromFormGroup(form: UntypedFormGroup): Column {
    const formValues = form.value;

    return new Column({
      id: formValues.id,
      title: formValues.title,
      position: formValues.position,
      projectId: formValues.projectId,
      color: formValues.color,
      completed: formValues.completed
    });
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      id: [this.id],
      title: [this.title],
      position: [this.position],
      projectId: [this.projectId],
      color: [this.color],
      completed: [this.completed]
    });
  }

  asPayloadJSON() {
    return {
      id: this.id,
      title: this.title,
      position: Math.ceil(this.position),
      projectId: this.projectId,
      color: this.color,
      completed: this.completed,
    };
  }
}
