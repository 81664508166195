// Common
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Types
import { Space } from '@modules/settings/types/space';

// Services
import { SpaceStateService } from '@modules/common/services/space-state.service';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'spaces-context-menu',
  templateUrl: './spaces-context-menu.component.html',
  styleUrls: ['./spaces-context-menu.component.less']
})
export class SpacesContextMenuComponent implements OnInit {

  public spaces: Space[] = [];
  public spaceId: string;

  private alive = new Subject<void>();

  constructor(
    private router: Router,
    private stateService: SpaceStateService
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.stateService.getSpaces()
      .pipe(takeUntil(this.alive))
      .subscribe(spaces => {
        this.spaces = spaces;
      });

    this.stateService.getCurrentSpace()
      .pipe(takeUntil(this.alive))
      .subscribe(space => {
        this.spaceId = space?.id;
      })
  }

  /**
   * Actions
   */

  selectSpace(space: Space) {
    this.stateService.setCurrentSpace(space);
  }

  addSpace() {
    this.router.navigate(['settings/spaces']);
  }
}
