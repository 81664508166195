// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Types
import { Icon } from '@modules/icons/types/icons';

@Component({
  selector: 'stch-quick-action',
  templateUrl: './quick-action.component.html',
  styleUrls: ['./quick-action.component.less'],
})
export class QuickActionComponent {
  @Input() icon: Icon;
  @Input() appearance: 'sapphire-toggle' | 'sapphire-button' = 'sapphire-toggle';
  @Input() size: 's' | 'm' = 'm';
  @Input() disabled = false;
  @Input() active = false;

  @Output() onClick = new EventEmitter();

  /**
   * Actions
   */

  handleClick() {
    if (this.disabled) { return; }

    this.onClick.emit();
  }
}
