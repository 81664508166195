// Common
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Types
import { TableColumn } from '@modules/common/types/table-column';
import { SortOrder } from '@modules/common/types/sort-order';
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';

// Services
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';
import { GlobalStateService } from '@modules/common/services/global-state.service';

// RX
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';

@Component({
  selector: 'app-settings-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.less']
})
export class SharedComponent implements OnInit, OnDestroy {

  public items: Stitch[];
  public page = 0;
  public perPage = 10;
  public sortBy: 'sharedViaLinkAt' = 'sharedViaLinkAt';
  public sortOrder: SortOrder = 'asc';
  public count = 0;
  public columns: TableColumn[] = [];
  public stitchType: StitchType;

  private reload = new Subject<void>();
  private alive = new Subject<void>();

  @ViewChild('linkTemplate', { static: true }) linkTemplate: TemplateRef<void>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<void>;

  constructor (
    private stitchServiceFactory: StitchServiceFactory,
    private route: ActivatedRoute,
    private globalStateService: GlobalStateService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.columns = [
      { key: 'title', title: 'Title' },
      { key: 'shareUid', title: 'Shared Link', template: this.linkTemplate },
      { key: 'sharedViaLinkAt', title: 'Shared Via Link At', sortable: true, template: this.dateTemplate },
    ];

    this.reload
      .pipe(
        debounceTime(400),
        switchMap(() =>
          this.stitchType
            ? this.stitchServiceFactory.getServiceByStitchType(this.stitchType)
              .search({
                sortBy: this.sortBy,
                sortOrder: this.sortOrder,
                limit: this.perPage,
                offset: this.page * this.perPage,
                shared: true
              })
            : of({ items: [], count: 0 })
        ),
        takeUntil(this.alive)
      )
      .subscribe(({ items, count }) => {
        this.items = items;
        this.count = count;
      });

    this.route.params.subscribe(params => {
      const stitchType = params['stitchType'];

      if (this.stitchType !== stitchType) {
        this.items = [];
      }

      this.stitchType = stitchType;
      this.reload.next();
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handlePageChange(page: number) {
    this.page = page;
    this.reload.next();
  }

  handleSortByChange(sortBy: 'sharedViaLinkAt') {
    this.sortBy = sortBy;
    this.reload.next();
  }

  handleSortOrderChange(sortOrder: SortOrder) {
    this.sortOrder = sortOrder;
    this.reload.next();
  }

  goToLink(event: MouseEvent, item: Stitch) {
    event.stopPropagation();
    window.open(item.sharedLink, "_blank");
  }

  handleRowClick(item: Stitch) {
    this.globalStateService.setFullView(item);
    this.router.navigate([`/notes`]);
  }
}
