// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// Types
import { Note } from '@modules/notes/types/note';
import { Notebook } from '@modules/notes/types/notebook';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Services
import { NotesService } from '@modules/notes/services/notes.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';

// Components
import { BaseQuickFormComponent } from '../base-quick-form/base-quick-form.component';

@Component({
  selector: 'app-note-quick-form',
  templateUrl: './note-quick-form.component.html',
  styleUrls: ['./note-quick-form.component.less'],
})
export class NoteQuickFormComponent extends BaseQuickFormComponent implements OnChanges {

  // Inputs
  @Input() note: Note = new Note();
  @Input() notebook: Notebook;

  // Outputs
  @Output() more: EventEmitter<Note> = new EventEmitter<Note>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<Note> = new EventEmitter();

  // Public
  public notebooksSuggestions: AutocompleteFactory<Notebook>;
  public popoverClose = new Subject<void>();

  /**
   * Constructor
   */
  constructor (
    private notesService: NotesService,
    private notebooksService: NotebooksService,
    public changeDetector: ChangeDetectorRef
  ) {
    super(changeDetector);
    this.reset();
    this.notebooksSuggestions = this.notebooksService.getAutocompleteSuggestions();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('note' in changes) {
      this.reset();
    }

    if ('notebook' in changes) {
      this.form.patchValue({ notebookId: this.notebook?.id });
    }
  }

  /**
   * Actions
   */

  submit() {
    if (!this.form.valid) {
      return;
    }

    const note = Note.fromFormGroup(this.form);

    if (this.save.observers.length > 0) {
      this.save.emit(note);
      return;
    }

    this.saveInProgress = true;

    this.notesService.create(note)
      .pipe(takeUntil(this.alive))
      .subscribe(
        noteInstance => {
          this.afterSave.emit(noteInstance);
          this.close.emit();
        },
        () => this.handleError()
      );
  }

  reset() {
    if (!this.note) { return; }
    this.form = this.note.asFormGroup();
    this.notebook && this.form.patchValue({ notebookId: this.notebook.id });
    super.reset();
  }

  clickMore() {
    this.more.emit(Note.fromFormGroup(this.form));
    super.clickMore();
  }

  handleSelectNotebook(event: DropdownSelectItem<Notebook>) {
    this.notebook = event.source;
  }

  handleCancelNotebooks() {
    this.popoverClose.next();
  }

  handleSaveNotebooks() {
    this.popoverClose.next();
  }
}
