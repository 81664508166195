<app-quick-form
  class="quick-form"
  [inline]="true"
>
  <app-note-quick-form
    [notebook]="notebook"
  ></app-note-quick-form>
</app-quick-form>

<div class="board">
  <div class="board-area">
    <app-section
      [container]="notebook"
      [itemTemplate]="noteTemplate"
      dragDataType="note"
    ></app-section>

    <app-section
      *ngFor="let section of sections"
      [section]="section"
      [container]="notebook"
      [itemTemplate]="noteTemplate"
      dragDataType="note"
      [maxDepth]="0"
    ></app-section>

    <div
      *ngIf="notebook"
      class="board-area-text-button"
      (click)="addSection()"
    >
      + Add Section
    </div>
  </div>
</div>

<ng-template #noteTemplate
  let-note="item"
  let-depth="depth"
  let-index="index"
  let-position="position"
  let-expandable="expandable"
  let-expanded="expanded"
  let-hovered="hovered"
>
  <app-note
    [item]="note"
    [position]="position"
    (appClick)="clickItem.emit(note)"
    (appDblClick)="dblClickItem.emit(note)"
  ></app-note>
</ng-template>
