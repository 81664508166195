<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <app-autocomplete-multiple-input
    class="form-recipients"
    title="To :"
    placeholder="Type to choose contact"
    [suggestions]="contactsSuggestions"
    valueKey="address"
    [inputFormControl]="form.controls.to"
    [itemTemplate]="contactTemplate"
    [containerStyles]="{ padding: '8px 16px' }"
    [noBorder]="true"
    [useInputText]="true"
  ></app-autocomplete-multiple-input>

  <div class="form-blue">
    <div class="form-blue-title">Subject</div>
    <div class="form-blue-rest">
      <input
        class="form-subject"
        [formControl]="form.controls.subject"
        #titleInput
      />
    </div>
  </div>

  <textarea class="form-textarea" [formControl]="form.controls.bodyHtml"></textarea>

  <div class="form-buttons">
    <div
      *ngIf="!inline"
      class="form-buttons-item"
      (click)="clickMore()"
    >
      More
    </div>

    <div class="form-buttons-filler"></div>

    <div
      *ngIf="!inline"
      class="form-buttons-item form-buttons-item-cancel"
      (click)="!saveInProgress && cancel()"
    >
      Cancel
    </div>

    <div
      class="form-buttons-item"
      (click)="!saveInProgress && submit()"
    >
      Send
    </div>
  </div>
</div>

<ng-template #contactTemplate let-contact="item">
  <div class="contact">
    <app-user-avatar
      class="contact-avatar-icon"
      size="28"
      [contact]="{ title: contact.title, email: contact.address }"
      [usePopover]="false"
    ></app-user-avatar>

    <div class="contact-body">
      <div class="contact-body-heading">
        <div class="contact-body-heading-title">
          {{ contact.title || 'No Name' }}
        </div>
      </div>

      <div class="contact-body-date">
        <div class="contact-body-date-item">
          {{ contact.address || 'No Email'}}
        </div>
      </div>
    </div>
  </div>
</ng-template>
