// Common
import { Component, OnDestroy, Input, OnInit, ViewChild, SimpleChanges, OnChanges, NgZone, Output, EventEmitter, Self, Inject } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

// RxJS
import { interval, BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { takeUntil, filter, debounceTime } from 'rxjs/operators';

// Services
import { TaskingAppStateService } from '@modules/tasks/services/state.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';

// Injection Tokens
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';

// Types
import { ProjectsFilters } from '@modules/tasks/types/projects-filters';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Like } from '@modules/common/types/like';
import { KnotSource } from '@modules/knots/types/knot-source';

// Env
import { environment } from '@environment';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }]
})
export class ProjectsListComponent extends InfinityScrollListComponent implements OnInit, OnDestroy, OnChanges {

  // ViewChildren
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;

  // Inputs
  @Input() placeholderText = 'You have no projects';
  @Input() filters: Like<ProjectsFilters> = {};
  @Input() withTags = false;
  @Input() withKnots = false;
  @Input() knotsSource: KnotSource;
  @Input() debug: 'score';

  // Outputs
  @Output() clickProject = new EventEmitter<Project>();
  @Output() openProject = new EventEmitter<Project>();
  @Output() doubleClickProject = new EventEmitter<Project>();
  @Output() openTask = new EventEmitter<Task>();

  // Public
  public itemHeight = 92;
  public isHover = false;
  public showCountView = new BehaviorSubject(true);
  public resetSelected = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private projectsService: ProjectsService,
    private tasksStateService: TaskingAppStateService,
    private linkedInfoService: LinkedInfoService,
    protected ngZone: NgZone,
    @Self() @Inject(ScrollToPosition) scrollToPositionSubject
  ) {
    super(ngZone, scrollToPositionSubject);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {

    this.showCountView
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      this.tasksStateService.getRefreshAll(),
      this.linkedInfoService.getRefreshRequired(),
      interval(environment.messageFetchInterval),
      this.projectsService.getRefreshRequired()
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.refreshCurrentItems();
      });

    this.refreshCurrentItems();

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('filters' in changes) {
      this.resetItems();
    }
  }

  /**
   * Actions
   */

  getItems(offset: number, limit: number): Observable<{ items: Project[], count: number }> {
    return this.projectsService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: false, knotsSource: this.knotsSource }
    );
  }

  resetItems() {
    this.resetSelected.next();
    super.resetItems();
  }

  handleDoubleClickItem(project: Project) {
    this.doubleClickProject.emit(project);
  }

  handleClickItem(project: Project) {
    this.clickProject.emit(project);
  }

  compareItems(item1: Object, item2: Object): boolean {
    return item1 && item2 && item1['id'] === item2['id'];
  }
}
