// Common
import { Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';

// Types
import { BaseAppAdvancedFilters } from './base-app-advanced-filters';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }]});

export class NotesAppAdvancedFilters extends BaseAppAdvancedFilters {
  formBuilder = injector.get(UntypedFormBuilder);

  body?: string;
  createdAt?: Date;
  description?: string;
  title?: string;
  updatedAt: Date;

  constructor(filters?: any) {
    super(filters);
    this.body = filters?.body || '';
    this.createdAt = filters?.createdAt ? new Date(filters.createdAt) : null;
    this.description = filters?.description || '';
    this.title = filters?.title || '';
    this.updatedAt = filters?.updatedAt ? new Date(filters.updatedAt) : null;
  }

  static fromFormGroup(form: UntypedFormGroup): NotesAppAdvancedFilters {
    return new NotesAppAdvancedFilters(this.getFormValues(form));
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...super.commonFormGroupFields(),
      body: [this.body],
      createdAt: [this.createdAt],
      description: [this.description],
      title: [this.title],
      updatedAt: [this.updatedAt],
    });
  }
}
