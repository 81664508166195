<div class="work-week">
  <div
    *ngFor="let day of weekDays | keyvalue | slice: 7: 14 | sortBy: 'value'"
    class="work-week-day"
    [class.active]="inputFormControl.value | includesBy: day.value"
    (click)="handleSelect(day.value)"
  >
    {{ day.key | slice: 0: 2 }}
  </div>
</div>
