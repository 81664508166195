<div
  *ngIf="calendar"
  class="calendar"
  [class.selected]="selected && (!isDragging || (isDragging && dragEnabled)) "
  [class.dragging]="!dragEnabled"
  [class.hovered]="contextMenuOpened"
  [class.pinned]="calendar.pinned"
  [class.snoozed]="calendar.snoozed"
  [class.followed]="calendar.followed"

  [appCalendarContextMenu]="calendar"
  (appCalendarContextMenuOpened)="contextMenuOpened = $event"
  (appCalendarContextMenuOpenCalendar)="open.emit()"

  appDraggable
  [appDraggableDisabled]="!dragEnabled"
  [appDraggableData]="dragData"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="calendar-selection"></div>

  <div class="calendar-body">
    <div class="calendar-body-title">
      <div
        class="calendar-body-title-text"
        [class.blank]="!calendar.title"
      >
        {{ calendar.title || 'Untitled Calendar' }}
      </div>
    </div>

    <div class="calendar-body-date">
      <div class="calendar-body-date-item">
        Timezone: {{ calendar.timeZone }}
      </div>
    </div>

    <div
      *ngIf="!withTags && !withKnots"
      class="calendar-body-description"
    >
      {{ calendar.description || 'No description' }}
    </div>

    <app-list-item-tags
      *ngIf="withTags"
      class="calendar-body-tags"
      [tags]="calendar.tags"
    ></app-list-item-tags>

    <app-list-item-knots
      *ngIf="withKnots"
      class="calendar-body-tags"
      [knots]="calendar.knots"
    ></app-list-item-knots>

    <app-link-info-bar [stitch]="calendar"></app-link-info-bar>
  </div>

  <mat-icon
    class="calendar-pin-icon"
    [class.active]="calendar.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  ></mat-icon>

  <mat-icon
    class="calendar-flag-icon"
    [class.active]="calendar.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  ></mat-icon>

  <mat-icon
    class="calendar-marker"
    [svgIcon]="calendar.archived ? 'folder-archive' : 'calendar'"
    [style.color]="calendar.color"
  ></mat-icon>

  <div
    class="calendar-hover-icons"
    *ngIf="dragEnabled && actionsButtonEnabled"
    (click)="$event.stopPropagation()"
  >
    <mat-icon
      class="calendar-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming Soon"
      (click)="createMail($event)"
    ></mat-icon>
    <mat-icon
      class="calendar-hover-icons-item"
      svgIcon="snooze"
      [matTooltip]="calendar?.snoozed ? 'Unsnooze' : 'Snooze'"
      [class.active]="!!calendar?.snoozed"
      (click)="snooze()"
    ></mat-icon>
    <mat-icon
      class="calendar-hover-icons-item"
      svgIcon="follow-up"
      [matTooltip]="calendar?.followed ? 'Unfollow' : 'Follow up'"
      [class.active]="!!calendar?.followed"
      (click)="followUp()"
    ></mat-icon>
    <mat-icon
      class="calendar-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="calendar"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    ></mat-icon>
    <mat-icon
      class="calendar-hover-icons-item"
      svgIcon="archive"
      (click)="archive()"
      [matTooltip]="calendar?.archived ? 'Restore from Archive' : 'Archive'"
    ></mat-icon>
    <mat-icon
      class="calendar-hover-icons-item"
      svgIcon="trash"
      (click)="delete()"
      [matTooltip]="calendar?.deleted ? 'Restore from Trash' : 'Trash'"
    ></mat-icon>
  </div>

  <div class="drop-zone"></div>

  <div *ngIf="debug" class="calendar-debug">{{ calendar.score }}</div>
</div>

<div class="placeholder" *ngIf="!calendar">
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
  <div class="placeholder-11"></div>
  <div class="placeholder-12"></div>
</div>

<ng-template #dragPlaceholder>
  <app-calendar [item]="calendar" [dragEnabled]="false"></app-calendar>
</ng-template>

<ng-template #afterDropTemplate let-dragData=dragData>
  <app-button (click)="handleMove(dragData)" label="Move" />
  <app-button (click)="handleStitch(dragData)" label="Stitch" />
</ng-template>
