<mat-icon
  class="icon"
  [svgIcon]="icon"
  [style.color]="control?.value"
  [style.height.px]="size"
  [style.width.px]="size"
  stchPopover
  [stchPopoverContent]="popoverTemplate"
  stchPopoverTrigger="click"
  stchPopoverPlacement="bottom"
  matTooltip="Color"
/>

<ng-template #popoverTemplate>
  <div class="color-input">
    <div class="color-input-list">
      <div
        class="color-input-list-item"
        *ngFor="let color of colors"
        [style.background-color]="color"
        (click)="handleSelect(color)"
      >
        <mat-icon
          *ngIf="control?.value === color"
          class="color-input-list-item-check-mark"
          svgIcon="check-mark"
        />
      </div>
    </div>
  </div>
</ng-template>
