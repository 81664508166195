// Common
import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { environment } from '@environment';

// Services
import { AccountService } from '@modules/account/services/account.service';
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Types
import { Application } from '@modules/common/types/application';
import { Stitch } from '@modules/common/types/stitch';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'stch-share-context-menu',
  templateUrl: './share-context-menu.component.html',
})
export class ShareContextMenuComponent {

  private alive = new Subject<void>();

  @Input() stitch: Stitch;

  constructor(
    private stitchServiceFactory: StitchServiceFactory,
    private toaster: ToasterService
  ) { }

  /**
   * Actions
   */

  shareViaLink() {
    this.stitchServiceFactory
      .getServiceByStitchType(this.stitch.getStitchType())
      .shareViaLink(this.stitch)
      .pipe(takeUntil(this.alive))
      .subscribe((shareUid: string) => {
        this.stitch.shareUid = shareUid;
        this.copyLink();
      });
  }

  revokeAccessViaLink() {
    this.stitchServiceFactory
      .getServiceByStitchType(this.stitch.getStitchType())
      .revokeAccessViaLink(this.stitch)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.stitch.shareUid = null;
        this.toaster.show({ text: 'Link no longer valid' });
      });
  }

  copyLink() {
    navigator.clipboard.writeText(this.stitch.sharedLink);
    this.toaster.show({ text: 'Link copied to clipboard' });
  }
}
