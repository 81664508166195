// Common
import { ComponentRef, Directive, Input, OnInit, Output, EventEmitter, Injector } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { NotesContextMenuComponent } from '../components/notes-context-menu/notes-context-menu.component';

// Types
import { StateKey } from '@modules/settings/types/state-key';
import { VirtualFolder } from '@modules/notes/types/virtual-folder';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';

// RX
import { takeUntil } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';

@Directive({
  selector: '[appDPNotesContextMenu]'
})
export class NotesContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';

  // Outputs
  @Output() appDPNotesContextMenuOpened = this.contextMenuOpened;
  @Output() appDPNotesContextMenuFilterChanged = new EventEmitter<VirtualFolder>();

  // Private
  private filterKey: VirtualFolder;

  constructor (
    private stateService: StateService,
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<NotesContextMenuComponent>) {
    componentRef.instance.filterKey = this.filterKey;
    componentRef.instance.change
      .pipe(
        takeUntil(this.alive)
      )
    .subscribe((filterKey: VirtualFolder) => {
      componentRef.instance.filterKey = filterKey;
      this.stateService.setState(StateKey.notesDPSidebarFilters, filterKey);
    });
  }

  registerComponent() {
    return NotesContextMenuComponent;
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.stateService.getState(StateKey.notesDPSidebarFilters)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((filterKey: VirtualFolder) => {
        this.appDPNotesContextMenuFilterChanged.emit(filterKey);
        this.filterKey = filterKey;
      });
  }
}
