// Common
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, EventEmitter, Output, Optional, Injectable } from '@angular/core';
import { beginningOfMonth, endOfMonth } from '@modules/common/utils/date';

// Services
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';

// Types
import { CalendarDateFormatter, CalendarNativeDateFormatter, DateFormatterParams } from 'angular-calendar';
import { CalendarEvent } from 'calendar-utils';

// RX
import { Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Injectable()
class CustomDateFormatter extends CalendarNativeDateFormatter {
  public monthViewColumnHeader({date, locale}: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, {weekday: 'short'}).format(date);
  }
}

@Component({
  selector: 'stch-calendar-month-small-di',
  templateUrl: './calendar-month-small-di.component.html',
  styleUrls: ['./calendar-month-small-di.component.less'],
  providers: [
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter }
  ]
})
export class CalendarMonthSmallDIComponent implements OnInit, OnChanges, OnDestroy {
  @Input() viewDate: Date = new Date();
  @Input() selectedDate: Date;
  @Input() min: Date;
  @Input() max: Date;
  @Input() range: [Date, Date];

  @Output() onDayClick = new EventEmitter<Date>();

  public events: CalendarEvent[] = [];

  private alive: Subject<void> = new Subject();
  private viewDateChanged = new Subject<Date>();

  constructor(
    @Optional() private calendarEventsService: CalendarEventsService
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.viewDateChanged
      .pipe(
        map(() => this.viewDate),
        startWith(this.viewDate),
        debounceTime(600),
        distinctUntilChanged((previous, next) => (
          previous.getFullYear() === next.getFullYear() && previous.getMonth() === next.getMonth()
        )),
        switchMap((date: Date) => (
          this.calendarEventsService
            ? this.calendarEventsService.getCalendarEvents(beginningOfMonth(date), endOfMonth(date))
            : of([])
        )),
        takeUntil(this.alive)
      )
      .subscribe((events: CalendarEvent[]) => {
        this.events = events;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('viewDate' in changes) {
      this.viewDateChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClick(day: Date) {
    if ((this.min && day < this.min) || (this.max && day > this.max)) { return; }

    this.onDayClick.emit(day);
  }
}
