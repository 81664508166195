import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'year',
  pure: true
})
export class YearPipe implements PipeTransform {
  transform(value: Date): number {
    if (!value) { return 0 }

    return value.getFullYear();
  }
}
