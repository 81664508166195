// Common
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';

// Types
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';
import { ListState } from '@modules/contacts/types/list-state';
import { Tab } from '@modules/common/types/tab';
import { GroupsListState } from '@modules/contacts/types/groups-list-state';
import { ContactsListState } from '@modules/contacts/types/contacts-list-state';
import { StateKey } from '@modules/settings/types/state-key';
import { VirtualFolder } from '@modules/contacts/types/virtual-folder';
import { ContactsFilters } from '@modules/contacts/types/contacts-filters';
import { GroupsFilters } from '@modules/contacts/types/groups-filters';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { ContactsAppStateService } from '@modules/contacts/services/state.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-contacts-list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.less'],
})
export class ContactsListContainerComponent implements OnInit, OnDestroy {

  public minimized: boolean;
  public searchQuery: string;
  public listState: ListState;
  public sidebarFilter: VirtualFolder;
  public quickContact: Contact;
  public quickGroup: Group;
  public listFilters: ContactsFilters;
  public loading: boolean;
  public tabs: Tab[] = [
    { title: 'Groups', value: 'groups'},
    { title: 'Contacts', value: 'contacts'},
  ];
  public selectedTab: Exclude<ListState, 'tabs'> = 'contacts';
  public contactsListState = new BehaviorSubject<ContactsListState>(null);
  public groupsListState = new BehaviorSubject<GroupsListState>(null);
  public groupsListStateKey: StateKey = StateKey.groupsListState;
  public contactsListStateKey: StateKey = StateKey.contactsListState;
  public groupsFilters: GroupsFilters;
  public contactsFilters: ContactsFilters;
  public debug: 'score' = null;

  // Private
  private alive: Subject<void> = new Subject();

  constructor(
    private splitViewService: SplitViewService,
    private stateService: ContactsAppStateService,
    private modalService: ModalService,
    private searchService: AdvancedSearchService,
    private injector: Injector
  ) {
    combineLatest([
      this.contactsListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.contactsFilters = new ContactsFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([
      this.groupsListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.groupsFilters = new GroupsFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.splitViewService.getMinimized('contactsList')
      .pipe(takeUntil(this.alive))
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });

    this.stateService.getTabs()
      .pipe(takeUntil(this.alive))
      .subscribe((state: ListState) => {
        this.listState = state;
        if (this.listState !== 'tabs') {
          this.selectedTab = this.listState;
        }
      });

    combineLatest([
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([state, folder]) => {
        this.sidebarFilter = folder;
        this.quickContact = Contact.fromAdvancedState(state, folder);
        this.quickGroup = Group.fromAdvancedState(state, folder);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewContact(contact = new Contact()) {
    this.stateService.setMainView(contact);
  }

  handleNewGroup(group = new Group()) {
    this.stateService.setMainView(group);
  }

  handleDoubleClickGroup(group: Group) {
    this.modalService.openFullForm(group, this.injector);
  }

  handleDoubleClickContact(contact: Contact) {
    this.modalService.openFullForm(contact, this.injector);
  }

  handleClickGroup(group: Group) {
    this.stateService.setMainView(group);
  }

  handleClickContact(contact: Contact) {
    this.stateService.setMainView(contact);
  }

  handleContactsListStateChange(event: ContactsListState) {
    this.contactsListState.next(event);
  }

  handleGroupsListStateChange(event: GroupsListState) {
    this.groupsListState.next(event);
  }

  doDebug() {
    switch (this.debug) {
      case 'score':
        this.debug = null;
        break;
      default:
        this.debug = 'score';
    }
  }
}
