// Common
import { Injectable } from '@angular/core';

// Types
import { Task } from '../types/task';
import { Project } from '../types/project';
import { Like } from '@modules/common/types/like';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { SectionsService } from './sections.service';
import { SignalsService } from '@modules/common/services/signals.service';
import { BaseSectionsListService } from '@modules/common/services/base-sections-list.service';

@Injectable()
export class SectionsListService extends BaseSectionsListService<Task, Project>  {

  constructor (
    sectionsService: SectionsService,
    itemsService: TasksService,
    signalsService: SignalsService
  ) {
    super(sectionsService, itemsService, signalsService);
  }

  protected createInstance(data: Like<Task>): Task {
    return new Task(data);
  }
}
