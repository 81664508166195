// Common
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';

// Types
import { SubscriptionPlan } from '@modules/admin/types/subscription-plan';
import { TableColumn } from '@modules/common/types/table-column';
import { SortOrder } from '@modules/common/types/sort-order';
import { PlansFilters } from '@modules/admin/types/subscription-plans-filters';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { SubscriptionPlansService } from '@modules/admin/services/subscription-plans.service';
import { AlertService } from '@modules/alert/services/alert.service';

// RX
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Components
import { SubscriptionPlanFormComponent } from '../plan-form/plan-form.component';

@Component({
  selector: 'app-admin-plans-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.less']
})
export class PlansPlansComponent implements OnInit, OnDestroy {

  public plans: SubscriptionPlan[];
  public page = 0;
  public perPage = 10;
  public sortBy: PlansFilters['sortBy'] = 'monthlyPrice';
  public sortOrder: SortOrder = 'asc';
  public count = 0;
  public columns: TableColumn[] = [];

  private reload = new Subject<void>();
  private alive = new Subject<void>();

  @ViewChild('currencyTemplate', { static: true }) currencyTemplate: TemplateRef<void>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<void>;
  @ViewChild('suspendedTemplate', { static: true }) suspendedTemplate: TemplateRef<void>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<void>;

  constructor (
    private plansService: SubscriptionPlansService,
    private modalService: ModalService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.columns = [
      { key: 'title', title: 'Title', sortable: true },
      { key: 'description', title: 'Description' },
      { key: 'monthlyPrice', title: 'Monthly Price', sortable: true, template: this.currencyTemplate },
      { key: 'annuallyPrice', title: 'Annually Price', sortable: true, template: this.currencyTemplate },
      { key: 'suspended', title: 'Active', sortable: true, template: this.suspendedTemplate },
      { key: 'maxSpaces', title: 'Max Number Of Spaces', sortable: true },
      { key: 'maxInboxes', title: 'Max Number Of Inboxes', sortable: true },
      { key: 'maxDiskSpace', title: 'Max Disk Space', sortable: true },
      { key: 'additionalDiskSpacePricePerGb', title: 'Additional Disk Space Price Per Gb', sortable: true, template: this.currencyTemplate },
      { key: 'taskingAppEnabled', title: 'Tasking App' },
      { key: 'mailAppEnabled', title: 'MailApp' },
      { key: 'calendarAppEnabled', title: 'Calendar App' },
      { key: 'notesAppEnabled', title: 'Notes App' },
      { key: 'contactsAppEnabled', title: 'Contacts App' },
      { key: 'filesAppEnabled', title: 'Files App' },
      { key: 'knotsEnabled', title: 'Knots' },
      { key: 'tagsEnabled', title: 'Tags' },
      { key: 'connectionsEnabled', title: 'Connections' },
      { key: 'maxKnotsCount', title: 'Max Knots', sortable: true },
      { key: 'maxTagsCount', title: 'Max Tags', sortable: true },
      { key: 'maxConnectionsCount', title: 'Max Connections', sortable: true },
      { key: 'createdAt', title: 'Created At', sortable: true, template: this.dateTemplate },
      { key: '', template: this.actionsTemplate }
    ];

    this.reload
      .pipe(
        debounceTime(400),
        switchMap(() => this.plansService.search({
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          limit: this.perPage,
          offset: this.page * this.perPage
        })),
        takeUntil(this.alive)
      )
      .subscribe(({ items: plans, count }) => {
        this.plans = plans;
        this.count = count;
      });

    this.reload.next();

    this.plansService.getRefresh()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.reload.next();
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handlePageChange(page: number) {
    this.page = page;
    this.reload.next();
  }

  handleSortByChange(sortBy: PlansFilters['sortBy']) {
    this.sortBy = sortBy;
    this.reload.next();
  }

  handleSortOrderChange(sortOrder: SortOrder) {
    this.sortOrder = sortOrder;
    this.reload.next();
  }

  openForm(plan = new SubscriptionPlan()) {
    this.modalService.showModal({
      component: SubscriptionPlanFormComponent,
      content: { plan },
      appearance: 'amethyst',
      title: plan.id ? 'Edit Subscription Plan' : 'Create Subscription Plan',
      resize: true,
      collapsible: true,
      expandable: false,
      maxModals: 1,
    });
  }

  suspend(plan: SubscriptionPlan) {
    plan.suspended = !plan.suspended;

    this.plansService.update(plan)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {

      });
  }

  removePlan(plan: SubscriptionPlan) {
    this.plansService.delete(plan);
  }

  removePlanPrompt(event: MouseEvent, plan: SubscriptionPlan) {
    event.preventDefault();
    event.stopPropagation();

    this.alertService.show({
      title: 'Are you sure?',
      body: `Are you sure you want to delete Subscription Plan ${plan.title}?`,
      rightButtons: [
        {
          title: 'CANCEL',
          close: true
        },
        {
          title: 'REMOVE',
          click: () => this.removePlan(plan),
          close: true
        }
      ],
      showUntil: this.alive
    });
  }
}
