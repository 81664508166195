<div
  class="heading"
  (resize)="handleResize($event)"
>
  <app-input
    class="input"
    appearance="sapphire-inline"
    [inputFormControl]="form.controls.title"
    placeholder="New Event"
    [validationMessages]="{ required: 'Can\'t be blank' }"
    [withErrors]="submitted"
    size="m"
  />

  <div class="icons">
    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="bug"
      [active]="selectedTab === 'debug'"
      matTooltip="Debug"
      (onClick)="selectedTab = 'debug'"
    />

    <app-button
      *ngIf="!contracted"
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-stitch"
      [disabled]="!event?.id"
      matTooltip="Stitch To"
      [appStitchToPopover]="event"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-archive2"
      [active]="form.controls.archived.value"
      [matTooltip]="form.controls.archived.value ? 'Move To Archive' : 'Restore From Archive'"
      (onClick)="archive()"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-trash"
      [active]="form.controls.deleted.value"
      [matTooltip]="form.controls.deleted.value ? 'Move To Trash' : 'Restore From Trash'"
      (onClick)="delete()"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-share"
      matTooltip="Share"
      [disabled]="!event?.id"
      [stchShareContextMenu]="event"
      [stchShareContextMenuDisabled]="!event?.id"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-ellipsis"
      matTooltip="More Options"
      [appEventContextMenu]="event"
      appEventContextMenuMode="popover"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-close"
      (click)="handleClose()"
    />
  </div>
</div>

<div class="tabs">
  <app-tabs
    appearance="sapphire"
    [values]="tabs"
    [(value)]="selectedTab"
    [selectedStateKey]="tabsStateKey"
    size="m"
  />

  <div class="filler"></div>

  <app-button appearance="sapphire-secondary" size="xs" label="Assign" />

  <stch-knowledge-button
    [active]="displayKnowledge"
    (click)="showKnowledge()"
  />
</div>

<div
  class="form"
  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="drop-area-border"></div>

  <div class="form-heading">
    <div class="form-heading-nav">
      <div class="form-heading-nav-separator"></div>

      <app-context-menu-state-indicator
        *ngIf="selectedTab === 'stitch'"
        class="form-heading-nav-state"
        svgIcon="filters"
        appStitchListContextMenu
        (appStitchListContextMenuChange)="stitchListState = $event"
      ></app-context-menu-state-indicator>

      <app-context-menu-state-indicator
        *ngIf="selectedTab === 'lab'"
        class="form-heading-nav-state"
        svgIcon="filters"
        appKnowledgeChartContextMenu
        (appKnowledgeChartContextMenuChange)="chartState = $event"
        matTooltip="Graph Filters"
      ></app-context-menu-state-indicator>
    </div>

    <div
      *ngIf="displayKnowledge"
      class="form-heading-nav-underline"
    ></div>

    <app-tags
      class="form-tags"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[event]"
      [control]="form.controls['tags']"
      stateKey="ff-tags"
    ></app-tags>

    <app-knots
      class="form-knots"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[event]"
      [control]="form.controls['knots']"
      stateKey="ff-knots"
    ></app-knots>

    <app-connections
      class="form-knots"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[event]"
      [control]="form.controls['connections']"
      stateKey="ff-connections"
    ></app-connections>

    <div class="form-heading-nav-underline"></div>
  </div>

  <app-scroll
    *ngIf="selectedTab !== 'lab'"
    [ngSwitch]="selectedTab"
    class="form-scroll"
    (top)="displayScrollShadow = !$event"
  >
    <div
      *ngIf="displayScrollShadow"
      class="form-scroll-shadow"
    ></div>

    <div
      *ngSwitchCase="'event'"
      class="details"
      [class.contracted]="contracted"
    >
      <div class="details-row">
        <app-date-range-picker
          [style.width.%]="100"
          [fromDate]="form.controls.startDate"
          [toDate]="form.controls.endDate"
          [fromTime]="form.controls.startTime"
          [toTime]="form.controls.endTime"
          [reminders]="form.controls.reminders"
          [recurrence]="form.controls.recurrence"
        />
      </div>

      <div class="details-row">
        <app-checkbox
          [inputFormControl]="form.controls.allDay"
          title="All Day"
          appearance="sapphire"
          size="s"
        />
      </div>

      <div class="details-line"></div>

      <div class="details-row">
        <div class="details-row-label">Repeat</div>
        <app-recurrence-picker
          class="details-row-input"
          [style.width]="contracted ? '100%' : '252px'"
          appearance="sapphire-outline"
          [control]="form.controls.recurrence"
          [referenceDate]="form.controls.startDate"
          size="m"
        />
      </div>

      <div class="details-row">
        <div class="details-row-label">Calendar</div>

        <app-autocomplete-input
          class="details-row-input"
          [style.width]="contracted ? '100%' : '252px'"
          appearance="sapphire-outline"
          placeholder="Add To Calendar"
          [suggestions]="suggestions"
          [inputFormControl]="form.controls.calendarId"
          size="s"
          [withErrors]="submitted"
          [withClear]="true"
        />
      </div>

      <div class="details-row">
        <div class="details-row-label">Visibility</div>

        <app-dropdown-input
          class="details-row-input"
          [style.width]="contracted ? '100%' : '252px'"
          [inputFormControl]="form.controls.visibility"
          placement="bottomFullWidth"
          [options]="[{title: 'Default visibility', value: null}]"
          appearance="sapphire-outline"
          size="m"
        />
      </div>

      <div class="details-row">
        <div class="details-row-label">Timezone</div>
        <app-timezone-input
          class="details-row-input"
          [control]="form.controls.timeZone"
          placeholder="Select Time Zone"
          appearance="sapphire-primary-ghost-button"
        />
      </div>

      <div class="details-line"></div>

      <div class="details-row">
        <div class="details-row-label">Meeting Link</div>
        <app-input
          class="details-row-input"
          [style.width]="contracted ? '100%' : '340px'"
          appearance="sapphire-outline"
          [inputFormControl]="form.controls.meetingLink"
          placeholder="Meeting Link"
          [withErrors]="submitted"
          [withClear]="true"
          size="s"
        />
      </div>

      <div class="details-row">
        <div class="details-row-label">Location</div>
        <app-input
          class="details-row-input"
          [style.width]="contracted ? '100%' : '340px'"
          appearance="sapphire-outline"
          [inputFormControl]="form.get('location').get('address')"
          placeholder="Location"
          [withErrors]="submitted"
          [withClear]="true"
          size="s"
        />
      </div>

      <div class="details-line"></div>

      <div class="details-row">
        <div class="details-row-label">Notifications</div>
      </div>
      <stch-reminders-picker [control]="form.controls.reminders" />

      <div class="details-line"></div>

      <div class="details-row">
        <div class="details-row-label">Participants</div>
      </div>
      <app-participants-input [control]="form.controls.participants" />

      <div class="details-line"></div>

      <div class="details-row">
        <div class="details-row-label">Description</div>
        <app-textarea
          class="details-row-input"
          [style.width]="contracted ? '100%' : '340px'"
          appearance="sapphire-outline"
          [inputFormControl]="form.controls.description"
          [rows]="3"
          [withClear]="true"
          placeholder="Description"
          [validationMessages]="{ required: 'Please enter a value' }"
          [withErrors]="submitted"
        />
      </div>
    </div>

    <app-attachments-list
      class="form-attachments"
      *ngSwitchCase="'attachments'"
      [stitchItem]="stitchItem"
      [inputControl]="form.controls.uploads"
      contextMenuStateVariant="events"
    />

    <app-linked-info-list
      *ngSwitchCase="'stitch'"
      class="form-linked-info"
      [stitch]="event"
      [state]="stitchListState"
      (clickStitchItem)="handleClickItem($event)"
      (dblClickStitchItem)="handleDblClickItem($event)"
    ></app-linked-info-list>

    <stch-comments
      *ngSwitchCase="'comments'"
      class="form-linked-info"
      [stitch]="event"
    ></stch-comments>

    <stch-json-viewer
      *ngSwitchCase="'debug'"
      [json]="event?.debug"
    ></stch-json-viewer>
  </app-scroll>

  <app-knowledge-chart
    *ngIf="selectedTab === 'lab'"
    class="form-lab"
    [sizeChanges]="displayKnowledge"
    [item]="event"
    [state]="chartState"
  ></app-knowledge-chart>
</div>
