import { Notification } from '@modules/notification/types/notification';

export enum SignalEnum {
  RELOAD_PROJECT_BOARD = 'reload-project-board',
  NEW_NOTIFICATION = 'new-notification'
}

export interface Signal {
  spaceId: string;
  type: SignalEnum;
}

export interface SignalReloadProjectBoard extends Signal {
  containerId?: string;
}

export interface SignalNotificationReceived extends Signal {
  unreadCount?: number;
  item?: Notification;
  accountId?: string;
}
