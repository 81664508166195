// Types
import { FoldersListState } from './folders-list-state';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { Like } from '@modules/common/types/like';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { VirtualFolder } from './virtual-folder';

// Utils
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';
import { checkExhaustiveness } from '@modules/common/utils/switch';

export class FoldersFilters extends StitchFilters {
  // Filters
  fromTime?: Date;
  toTime?: Date;
  uploadDate?: Date;
  openedDate?: Date;
  fromSize?: number;
  toSize?: number;

  // Sort Options
  sortBy?: 'title' | 'date' | 'due-date' | 'score' | 'sharedViaLinkAt';

  constructor (filters?: Like<FoldersFilters>) {
    super(filters);
    this.fromTime = filters?.fromTime;
    this.toTime = filters?.toTime;
    this.sortBy = filters?.sortBy;
    this.uploadDate = filters?.uploadDate;
    this.openedDate = filters?.openedDate;
    this.fromSize = filters?.fromSize;
    this.toSize = filters?.toSize;
  }

  applyListState(state: FoldersListState) {
    const today = new Date();

    this.flagged = state?.flagged;
    this.pinned = state?.pinned;
    this.fromTime = state?.from;
    this.toTime = state?.to;
    if (state?.today) {
      this.fromTime = beginningOfDay(today);
      this.toTime = endOfDay(today);
    }
    this.stitch = state?.stitchedWith;
    this.sortBy = state?.sortBy;
    this.sortOrder = state?.sortOrder;
    this.pinnedOnTop = state?.pinnedOnTop;
    this.flaggedOnTop = state?.flaggedOnTop;
    this.snoozedOnTop = state?.snoozedOnTop;
    this.followedOnTop = state?.followedOnTop;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.fromTime) { result['from_time'] = this.fromTime.toISOString(); }
    if (this.toTime) { result['to_time'] = this.toTime.toISOString(); }
    if (this.uploadDate) { result['upload_date'] = this.uploadDate.toISOString(); }
    if (this.fromSize) { result['from_size'] = this.fromSize; }
    if (this.toSize) { result['to_size'] = this.toSize; }
    if (this.openedDate) { result['opened_date'] = this.openedDate.toISOString(); }

    result['sort_by'] = this.sortBy || 'date';

    return result;
  }

  applyAdvancedFilters(advanced: AdvancedSearchState) {
    super.applyAdvancedFilters(advanced);

    const state = advanced.files;

    this.parentsIds = state.containersIds;
    this.noParent = !state.containersIds?.length;
    this.esTitle = state.title;
    this.esBody = state.description;
    this.fromSize = state.fileSize.from;
    this.toSize = state.fileSize.to;
    this.openedDate = state.openedDate;
    this.fromTime = state.dateRangeFrom;
    this.toTime = state.dateRangeTo;
    this.archived = false;
    this.deleted = false;
    this.snoozedOnTop = true;
    this.followedOnTop = true;

    return this;
  }

  applyVirtualFolder(folder: VirtualFolder) {
    if (!folder) { return this; }

    switch (folder) {
      case 'archived':
        this.archived = true;
        this.deleted = null;
        break;
      case 'deleted':
        this.deleted = true;
        this.archived = null;
        break;
      case 'all_files':
      case 'upcoming':
      case 'recent':
      case 'all_folders':
        break;
      case 'flagged':
        this.flagged = true;
        break;
      case 'followed':
        this.followed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      case 'snoozed':
        this.snoozed = true;
        this.snoozedOnTop = false;
        this.followedOnTop = false;
        break;
      default:
        checkExhaustiveness(folder);
    }

    return this;
  }
}
