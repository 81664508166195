// Common
import { Component, ElementRef, Injector, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from '@environment';
import { LSDate, LSString, LocalStorageItem } from 'src/app/decorators/local-storage.decorator';

// Services
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';
import { PopoverService } from '@modules/popover/services/popover.service';
import { EventsService } from '@modules/calendar-app/services/events.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { CalendarType } from '@modules/calendar-app/types/calendar-type';
import { CalendarCellClickEvent } from '@modules/full-calendar/types/calendar-cell-click-event';
import { CalendarDropEvent } from '@modules/full-calendar/types/calendar-drop-event';
import { EventsFilters } from '@modules/calendar-app/types/events-filters';
import { EventsListState } from '@modules/calendar-app/types/events-list-state';
import { DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

// RX
import { debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, interval, merge, Subject } from 'rxjs';

@Component({
  selector: 'stch-full-calendar',
  templateUrl: './full-calendar.component.html',
  styleUrls: ['./full-calendar.component.less']
})
export class FullCalendarComponent implements OnInit, OnDestroy {

  public calendarEvents: AngularCalendarEvent[] = [];
  public events: CalendarEvent[] = [];
  public calendarTypes: DropdownSelectItem<string>[] = [
    { title: 'Year', value: CalendarType.YEAR },
    { title: 'Month', value: CalendarType.MONTH },
    { title: 'Week', value: CalendarType.WEEK },
    { title: 'Work Week', value: CalendarType.WORKWEEK },
    { title: 'Day', value: CalendarType.DAY },
  ];
  public calendarType = new FormControl<CalendarType>(null);
  public date = new FormControl<Date>(null);
  public highlightedDate: Date = new Date();
  public selectedDate: Date; // TODO Consider moving to full-calendar-month
  public calendarEvent: CalendarEvent;

  private alive = new Subject<void>();
  private loadingEvents: Subject<void> = new Subject();
  private virtualEvent = new BehaviorSubject<CalendarEvent>(null);
  private filters: EventsFilters;
  private popoverClose: Subject<void> = new Subject();
  private eventsListState = new BehaviorSubject<EventsListState>(null);
  @LSString({ default: CalendarType.YEAR }) private calendarTypeState: LocalStorageItem<CalendarType>;
  @LSDate({ default: new Date(), lsKey: 'full-calendar-date' }) private dateState: LocalStorageItem<Date>;

  @ViewChild('popoverFormTemplate', { static: true }) popoverFormTemplate: TemplateRef<void>;

  constructor(
    private eventsService: EventsService,
    private stateService: CalendarAppStateService,
    private popoverService: PopoverService,
    private injector: Injector,
    private searchService: AdvancedSearchService,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([
      this.eventsListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder(),
      this.stateService.getFullCalendarView()
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([list, search, folder, calendarIds]) => {
        this.filters = new EventsFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);

        this.filters.containersIds = calendarIds;
        this.loadingEvents.next();
      });

    merge(
      // Global refresh button
      this.stateService.getRefreshAll(),
      interval(environment.messageFetchInterval),
      this.eventsService.getRefresh(),
    )
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.loadingEvents.next();
      });

    this.calendarTypeState.get()
      .pipe(
        filter(type => !!type),
        takeUntil(this.alive)
      )
      .subscribe(type => {
        this.calendarType.setValue(type);
        this.loadingEvents.next();
      });

    this.loadingEvents
      .pipe(
        debounceTime(500),
        switchMap(() => this.eventsService.search({
          ...this.filters,
          fromTime: this.beginningOfPeriod(this.date.value),
          toTime: this.endOfPeriod(this.date.value),
        }, { withRecurrence: true })),
        map(({ items }) => items),
        switchMap((events: CalendarEvent[]) => this.virtualEvent.pipe(
          map((virtualEvent: CalendarEvent) => virtualEvent ? [...events, virtualEvent] : events)
        )),
        takeUntil(this.alive)
      )
      .subscribe(events => {
        this.calendarEvents = events.map(event => event.asAngularCalendarEvent());
        this.events = events;
      });

    this.loadingEvents.next();

    this.stateService.getSelectedEvents()
      .pipe(takeUntil(this.alive))
      .subscribe((events: CalendarEvent[]) => {
        if (events.length === 0) {
          this.highlightedDate = null;
          return;
        }

        this.highlightedDate = events[0].startTime;
      });

    this.stateService.getSelectedCalendarDate()
      .pipe(takeUntil(this.alive))
      .subscribe(date => this.date.setValue(date));

    this.calendarType.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(type => {
        this.calendarTypeState.set(type);
      });

    this.dateState.get()
      .pipe(
        filter(date => (
          date.getFullYear() !== this.date.value?.getFullYear() ||
          date.getMonth() !== this.date.value?.getMonth() ||
          date.getDate() !== this.date.value?.getDate()
        )),
        takeUntil(this.alive)
      )
      .subscribe(date => {
        this.date.setValue(date);
        this.selectedDate = date;
        this.loadingEvents.next();
        this.popoverClose.next();
      });

    this.date.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(date => {
        this.setDate(date);
      });

    this.popoverClose
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.virtualEvent.next(null));
  }

  ngOnDestroy() {
    this.popoverClose.next();
    this.popoverClose.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleDateClicked(cellEvent: CalendarCellClickEvent) {
    this.selectedDate = cellEvent.date;

    this.calendarEvent = CalendarEvent.fromCalendarCell(null, cellEvent.date, this.calendarType.value, true);
    this.calendarEvent.calendarId = this.filters.containersIds[0];
    this.virtualEvent.next(this.calendarEvent);

    this.openPopoverForm(cellEvent.originRef, this.calendarEvent);
  }

  handleDateDblClicked(date: Date) {
    switch (this.calendarType.value) {
      case CalendarType.YEAR:
        this.calendarTypeState.set(CalendarType.DAY);
        this.setDate(date);
        break;
      case CalendarType.MONTH:
      case CalendarType.WEEK:
      case CalendarType.WORKWEEK:
      case CalendarType.DAY:
        this.stateService.setMainView(
          CalendarEvent.fromCalendarCell(null, date, this.calendarType.value, false)
        );
        break;
    }
  }

  handleCellDrop(event: CalendarDropEvent) {
    if (DragDataTypes.event === event.dragData.type) {
      this.eventsService.bunchUpdate(
        { ids: event.dragData.data.map(({ id }) => id) },
        {
          startTime: event.newStart,
          endTime: event.newEnd,
          calendarId: this.filters.containersIds[0]
        }
      );

      return;
    }

    if (!event.originRef) { return; }

    this.calendarEvent = CalendarEvent.fromCalendarCell(
      CalendarEvent.fromDragData(event.dragData),
      event.newStart,
      this.calendarType.value,
      true
    );

    this.calendarEvent.calendarId = this.filters.containersIds[0];
    this.virtualEvent.next(this.calendarEvent);

    this.openPopoverForm(event.originRef, this.calendarEvent);
  }

  handleDayNumberClick(date: Date): void {
    this.calendarTypeState.set(CalendarType.DAY);
    this.setDate(date);
  }

  handleOpenInListView(day: Date) {
    this.stateService.setScrollToDay(day);
  }

  setDate(newDate: Date) {
    this.dateState.set(newDate);
  }

  setToday() {
    this.stateService.setScrollToDay(new Date());
    this.setDate(new Date());
  }

  nextDate() {
    this.setDate(this.calculateDate(1));
  }

  prevDate() {
    this.setDate(this.calculateDate(-1));
  }

  calculateDate(multiplier: number) {
    const date = this.date.value;

    const newDate = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
    };

    switch (this.calendarType.value) {
      case 'day':
        newDate.day += multiplier;
        break;
      case 'week':
      case 'workWeek':
        newDate.day += 7 * multiplier;
        break;
      case 'month':
        newDate.month += multiplier;
        newDate.day = Math.min(newDate.day, this.daysInMonth( newDate.year, newDate.month + 1));
        break;
      case 'year':
        newDate.year += multiplier;
        break;
    }

    return new Date( newDate.year,  newDate.month, newDate.day );
  }

  daysInMonth(year: number, month: number) {
    return new Date(year, month, 0).getDate();
  }

  /**
   * Helpers
   */

  beginningOfPeriod(date: Date): Date {
    switch (this.calendarType.value) {
      case CalendarType.DAY:
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
      case CalendarType.WEEK:
      case CalendarType.WORKWEEK:
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay()) - 1, 23, 59, 59, 999);
      case CalendarType.MONTH:
        return new Date(date.getFullYear(), date.getMonth(), -5, 0, 0, 0, 0);
      case CalendarType.YEAR:
        return new Date(date.getFullYear(), 0, -5, 0, 0, 0, 0);
    }
  }

  endOfPeriod(date: Date): Date {
    switch (this.calendarType.value) {
      case CalendarType.DAY:
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
      case CalendarType.WEEK:
      case CalendarType.WORKWEEK:
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + (7 - date.getDay()), 0, 0, 0, 0);
      case CalendarType.MONTH:
        return new Date(date.getFullYear(), date.getMonth() + 1, 6, 0, 0, 0, 0);
      case CalendarType.YEAR:
        return new Date(date.getFullYear() + 1, 0, 6, 0, 0, 0, 0);
    }
  }

  private openPopoverForm(originRef: ElementRef<any>, event: CalendarEvent) {
    this.popoverService.create(
      originRef,
      {
        template: this.popoverFormTemplate,
        placement: 'bottomLeft',
        trigger: 'click',
        showUntil: this.popoverClose,
        injector: this.injector,
      }
    );
  }
}
