import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class Settings {
  formBuilder = injector.get(FormBuilder);

  notebooksPostponeNotificationsEnabled: boolean;
  notesPostponeNotificationsEnabled: boolean;
  notifications?: boolean;

  constructor(data: any = {}) {
    this.notebooksPostponeNotificationsEnabled = data.notebooksPostponeNotificationsEnabled ?? false;
    this.notesPostponeNotificationsEnabled = data.notesPostponeNotificationsEnabled ?? false;
    this.notifications = data.notifications ?? data.notesNotificationsEnabled ?? false;
  }

  static fromFormGroup(form: FormGroup): Settings {
    const { value: formValues } = form;

    return new Settings({
      notebooksPostponeNotificationsEnabled: formValues.notebooksPostponeNotificationsEnabled,
      notesPostponeNotificationsEnabled: formValues.notesPostponeNotificationsEnabled,
      notifications: formValues.notifications,
    });
  }

  public asFormGroup(): FormGroup {
    return this.formBuilder.group({
      notebooksPostponeNotificationsEnabled: [this.notebooksPostponeNotificationsEnabled],
      notesPostponeNotificationsEnabled: [this.notesPostponeNotificationsEnabled],
      notifications: [this.notifications],
    });
  }

  public asPayloadJSON() {
    return {
      notebooksPostponeNotificationsEnabled: this.notebooksPostponeNotificationsEnabled,
      notesPostponeNotificationsEnabled: this.notesPostponeNotificationsEnabled,
      notificationsEnabled: this.notifications,
    };
  }
}
