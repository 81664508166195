// Services
import { BaseRestService } from '@modules/common/services/base-rest.service';

// Types
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Filters } from '@modules/common/types/filters';
import { TransformFunction } from './virtual-scroll-transform-function';

// RX
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

export class VirtualScrollDataSource<T> extends DataSource<T> {
  public cachedData: T[] = [undefined];
  public dataSizeChanged = new BehaviorSubject<number>(0);
  public perPage = 100;
  public transformStrategy: 'none' | 'highlightFirstLetter' = 'none';
  public transformField: string;

  private fetchedPages = new Set<number>();
  private dataStream = new BehaviorSubject<T[]>(this.cachedData);
  private subscriptions = new Subscription();
  private filters: Observable<Filters>;
  private service: BaseRestService<T, Filters>;
  private pages = new BehaviorSubject<number[]>([0]);

  constructor(service: BaseRestService<T, Filters>, filters: Observable<Filters>) {
    super();

    this.filters = filters;
    this.service = service;
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    this.subscriptions.add(
      collectionViewer.viewChange.subscribe(range => {
        const start = this.getPageForIndex(range.start);
        const end = Math.max(this.getPageForIndex(range.end - 1), 0);

        this.pages.next(
          Array.from({ length: end - start + 1 })
            .map((page, index) => start + index)
        );
      })
    );

    this.subscriptions.add(
      this.filters
        .pipe(
          filter(filters => !!filters),
          switchMap(filters => (
            this.service.getRefreshRequired()
              .pipe(
                map(() => filters)
              )
          )),
          tap(() => {
            this.cachedData = [undefined];
            this.fetchedPages = new Set();
            // this.dataStream.next(this.cachedData);
          }),
          switchMap(filters => (
            this.pages
              .pipe(
                map(pages => ({ pages, filters }))
              )
          )),
          debounceTime(400),
          map(({ pages, filters }) => ({
            pages: pages.filter(page => !this.fetchedPages.has(page)),
            filters
          })),
          filter(({ pages }) => pages.length > 0),
          switchMap(({ pages, filters }) => (
            combineLatest(
              pages.map(page => (
                this.service.search({
                  ...filters,
                  limit: this.perPage,
                  offset: this.perPage * page
                })
                  .pipe(
                    map(data => {
                      if (this.transformStrategy === 'highlightFirstLetter') {
                        return this.highlightFirstLetter(data, page);
                      }
                      // more strategies
                      return data;
                    }),
                    map(({ items, count }) => ({ items, count, page }))
                  )
              ))
            )
              .pipe(
                map(data => ({ data, pages }))
              )
          )),
          tap(({ pages }) => {
            pages.forEach(page => this.fetchedPages.add(page));
          })
        )
        .subscribe(({ data }) => {
          const count = data[0].count;

          if (this.dataSizeChanged.value !== count) {
            this.dataSizeChanged.next(count);
          }

          this.cachedData.length = Math.max(1, count);

          data.forEach(({ items, page }) => {
            this.cachedData.splice(
              page * this.perPage,
              this.perPage,
              ...items
            );
          });

          this.dataStream.next(this.cachedData);
        })
    );

    return this.dataStream;
  }

  disconnect(): void {
    this.subscriptions.unsubscribe();
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.perPage);
  }

  public refreshStream() {
    this.dataStream.next(this.cachedData);
  }

  private highlightFirstLetter: TransformFunction<T> = ({ items, count }, page) => (
    {
      items: items.map((item, index) => ({
        ...item,
        letter: (page === 0 && index === 0) ||
          item[this.transformField][0] !== (index === 0
            ? this.cachedData[page * this.perPage - 1]?.[this.transformField]?.[0]
            : items[index - 1]?.[this.transformField]?.[0]
          )
            ? item[this.transformField][0] : null
      })),
      count
    }
  )
}
