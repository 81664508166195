<div
  class="container"
  [class.has-all-day-more-events-button]="allDayExtraCount > 0 && allDayCollapsed"
  [@dateAnimation]="displayDate"
>
  <mwl-calendar-week-view
    [viewDate]="viewDate"
    [excludeDays]="excludeDays"
    [allDayEventsLabelTemplate]="allDayLabelTemplate"
    [eventTemplate]="eventTemplate"
    [currentTimeMarkerTemplate]="markerTemplate"
    [events]="displayEvents"
    [refresh]="refresh"
    [hourSegmentTemplate]="weekViewHourSegment"
    (eventTimesChanged)="handleMove($event)"
    (dayHeaderClicked)="this.dayNumberClicked.emit($event.day.date)"
  />

  <div class="timezone">
    <div class="timezone-gmt">GMT</div>
    {{ timezoneOffset }}
  </div>
</div>

<ng-template #markerTemplate let-top="topPx" let-visible="isVisible">
  <div
    #currentTimeMarker
    *ngIf="visible"
    class="marker"
    [style.top.px]="top"
  >
    <div class="bubble"></div>
  </div>
</ng-template>

<ng-template #allDayLabelTemplate>
  <div class="all-day-label">
    <button
      *ngIf="allDayExtraCount > 0"
      class="button"
      (click)="collapseAllDay()"
    >
      <div class="button-icon-container">
        <mat-icon
          [svgIcon]="allDayCollapsed ? 'sapphire-chevron-down' : 'sapphire-chevron-up'"
          class="button-icon"
        />
      </div>
    </button>
  </div>
</ng-template>

<ng-template #eventTemplate let-weekEvent="weekEvent">
  <div
    class="event"
    [style.backgroundColor]="weekEvent.event.color.secondary"
    [class.all-day]="weekEvent.event.allDay"
  >
    <div
      class="event-container"
      [class.all-day]="weekEvent.event.allDay"
      [style.borderColor]="weekEvent.event.color.primary"
    >
      <div class="event-title">
        {{ weekEvent.event.title }}
      </div>

      <div
        class="event-time"
        [style.color]="weekEvent.event.color.primary"
      >
        {{ weekEvent.event.start | date : 'hh:mm a' }} - {{ weekEvent.event.end | date : 'hh:mm a' }}
      </div>
    
      <div
        *ngIf="weekEvent.endsAfterWeek"
        class="event-all-day-ends"
      >
        {{ weekEvent.event.end | date : 'dd MMM' }}
      </div>

      <mat-icon
        *ngIf="weekEvent.endsAfterWeek"
        class="event-all-day-icon"
        svgIcon="sapphire-chevron-right"
      />
    </div>
  </div>

  <div
    *ngIf="weekEvent.event.allDay"
    class="all-day-more-events-button"
  >
    <button (click)="collapseAllDay()">
      {{ allDayExtraCount + 1 }} More
    </button>
  </div>
</ng-template>

<ng-template
  #weekViewHourSegment
  let-segment="segment"
  let-isTimeLabel="isTimeLabel"
  let-daysInWeek="daysInWeek"
  let-segmentHeight="segmentHeight"
>
  <div
    class="cal-hour-segment"
    [style.height.px]="segmentHeight"
    [class.cal-hour-start]="segment.isStart"
    [class.cal-after-hour-start]="!segment.isStart"
    [class.weekend]="segment.date | weekend"
    [ngClass]="segment.cssClass"
  >
    <div *ngIf="isTimeLabel" class="cal-time">
      {{ segment.date | date : 'hh:mm a' }}
    </div>

    <div
      #cell
      *ngIf="!isTimeLabel"
      class="cell"

      (appClick)="clickHourSegment($event, segment.date, cell)"
      (appDblClick)="dateDblClicked.emit(segment.date)"
  
      [appCalendarCellContextMenu]="segment.date"
      appCalendarCellContextMenuCalendarType="week"
      (appCalendarCellContextMenuLoadDayEvents)="loadDayEvents.emit(segment.date)"
  
      appDroppable
      appDroppableHoverClass="drag-over"
      (appDroppableDrop)="handleDrop($event, segment.date, cell)"
    ></div>
  </div>
</ng-template>
