<app-context-menu-item
  [withCheckOption]="true"
  title="Events"
  [active]="filterKey === 'all_events'"
  (execute)="selectFilter('all_events')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Flagged"
  [active]="filterKey === 'flagged'"
  (execute)="selectFilter('flagged')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Archive"
  [active]="filterKey === 'archived'"
  (execute)="selectFilter('archived')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Trash"
  [active]="filterKey === 'deleted'"
  (execute)="selectFilter('deleted')"
></app-context-menu-item>
