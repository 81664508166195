// Common
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';

// Utils
import { addDays } from '@modules/common/utils/date';

// RX
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// Services
import { AccountService } from '@modules/account/services/account.service';
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { FoldersService } from '@modules/files/services/folders.service';
import { GroupsService } from '@modules/contacts/services/groups.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';

// Types
import { commonFileSizes } from '@modules/files/types/common-file-sizes';
import { commonFileTypes } from '@modules/files/types/common-file-types';
import { Priority } from '@modules/tasks/types/priority';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { Participant } from '@modules/messages/types/participant';
import { Calendar } from '@modules/calendar-app/types/calendar';
import { Notebook } from '@modules/notes/types/notebook';
import { Project } from '@modules/tasks/types/project';
import { Group } from '@modules/contacts/types/group';
import { Folder } from '@modules/files/types/folder';
import { StitchType } from '@modules/common/types/stitch-type';
import { Account } from '@modules/account/types/account';
import { contactInfoTypes } from '@modules/form-controls/types/contactInfoTypes';

@Component({
  selector: 'app-advanced-forms',
  templateUrl: './advanced-forms.component.html',
  styleUrls: ['./advanced-forms.component.less']
})
export class AdvancedFormsComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() currentApp: 'mail' | 'calendar' | 'tasking' | 'notes' | 'contacts' | 'files';
  @Input() mailForm: UntypedFormGroup;
  @Input() calendarForm: UntypedFormGroup;
  @Input() taskingForm: UntypedFormGroup;
  @Input() notesForm: UntypedFormGroup;
  @Input() contactsForm: UntypedFormGroup;
  @Input() filesForm: UntypedFormGroup;

  // Public
  public filesSizeOptions = commonFileSizes;
  public filesTypeOptions = commonFileTypes;
  public priorityOptions: DropdownSelectItem<Priority>[] = [
    { title: 'Low Priority', value: 'low', icon: 'priority', iconColor: '#0F84FF' },
    { title: 'Normal Priority', value: 'normal', icon: 'priority', iconColor: '#FFBC00' },
    { title: 'High Priority', value: 'high', icon: 'priority', iconColor: '#FF0050' },
  ];
  public contactInfoTypes = contactInfoTypes;
  public contactsSuggestions: AutocompleteFactory<Participant>;
  public calendarsSuggestions: AutocompleteFactory<Calendar>;
  public projectsSuggestions: AutocompleteFactory<Project>;
  public notebooksSuggestions: AutocompleteFactory<Notebook>;
  public groupsSuggestions: AutocompleteFactory<Group>;
  public foldersSuggestions: AutocompleteFactory<Folder>;

  public stitchedOptions: DropdownSelectItem<StitchType>[] = [
    { title: 'Emails', value: StitchType.message, icon: 'app-menu-inbox' },
    { title: 'Events', value: StitchType.event, icon: 'event' },
    { title: 'Projects', value: StitchType.project, icon: 'project' },
    { title: 'Tasks', value: StitchType.task, icon: 'task' },
    { title: 'Notebooks', value: StitchType.notebook, icon: 'notebook' },
    { title: 'Notes', value: StitchType.note, icon: 'note' },
    { title: 'Groups', value: StitchType.group, icon: 'groups' },
    { title: 'Contacts', value: StitchType.contact, icon: 'contacts' },
    { title: 'Folders', value: StitchType.folder, icon: 'folder' },
    { title: 'Files', value: StitchType.file, icon: 'file' },
  ];
  public account: Account;

  private alive = new Subject<void>();

  constructor(
    private contactsService: ContactsService,
    private calendarsService: CalendarsService,
    private projectsService: ProjectsService,
    private notebooksService: NotebooksService,
    private groupsService: GroupsService,
    private foldersService:  FoldersService,
    private datePipe: DatePipe,
    private accountsService: AccountService
  ) {
    this.contactsSuggestions = (title?: string, values?: Participant[], { limit } : { limit: number } = { limit: 5 }) =>
      this.contactsService.getEmailAutocompleteSuggestions()(title, values, { limit })
        .pipe(
          map(participants =>
            participants.map((participant) => (
              { ...participant, source: { ...participant.source, title: participant.source.name } }
            ))
          )
        );
    this.calendarsSuggestions = this.calendarsService.getAutocompleteSuggestions();
    this.projectsSuggestions = this.projectsService.getAutocompleteSuggestions();
    this.notebooksSuggestions = this.notebooksService.getAutocompleteSuggestions();
    this.groupsSuggestions = this.groupsService.getAutocompleteSuggestions();
    this.foldersSuggestions = this.foldersService.getAutocompleteSuggestions();
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.accountsService.getAccount()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(account => {
        this.account = account;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
