<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <input
    class="form-title"
    placeholder="Untitled Project"
    [formControl]="form.controls.title"
    #titleInput
  />

  <app-color-input
    class="form-marker"
    icon="project"
    [control]="form.controls.color"
    [size]="16"
  ></app-color-input>

  <div class="form-date">
    <div
      class="form-date-item"
      [class.selected]="dateButtonSelected === 'from'"
      [class.blank]="!form.controls.fromDate.value && !form.controls.fromTime.value"
      (click)="dateButtonSelected = 'from'"
    >
      {{ form.controls.fromDate.value | dateTimeDisplay: form.controls.fromTime.value: 'No Start Date' }}
    </div>
    <div class="form-date-separator"></div>
    <div
      class="form-date-item"
      [class.selected]="dateButtonSelected === 'to'"
      [class.blank]="!form.controls.toDate.value && !form.controls.toTime.value"
      (click)="dateButtonSelected = 'to'"
    >
      {{ form.controls.toDate.value | dateTimeDisplay: form.controls.toTime.value: 'No Due Date' }}
    </div>
  </div>

  <div class="form-quick-actions">
    <mat-icon
      class="form-quick-actions-item"
      [class.active]="(dateButtonSelected === 'from' ? form.controls.fromDate?.value : form.controls.toDate?.value) | compareToRelativeDate: 'today'"
      svgIcon="quick-today"
      (click)="setDate('today')"
      matTooltip="Today"
    ></mat-icon>

    <mat-icon
      class="form-quick-actions-item"
      [class.active]="(dateButtonSelected === 'from' ? form.controls.fromDate?.value : form.controls.toDate?.value) | compareToRelativeDate: 'tomorrow'"
      svgIcon="quick-tomorrow"
      (click)="setDate('tomorrow')"
      matTooltip="Tomorrow"
    ></mat-icon>

    <mat-icon
      class="form-quick-actions-item"
      [class.active]="(dateButtonSelected === 'from' ? form.controls.fromDate?.value : form.controls.toDate?.value) | compareToRelativeDate: 'nextMonday'"
      svgIcon="quick-week"
      (click)="setDate('nextMonday')"
      matTooltip="Next Monday"
    ></mat-icon>

    <mat-icon
      class="form-quick-actions-item"
      [class.active]="(dateButtonSelected === 'from' ? form.controls.fromDate?.value : form.controls.toDate?.value) | compareToRelativeDate: 'nextMonth'"
      svgIcon="quick-month"
      (click)="setDate('nextMonth')"
      matTooltip="Next Month"
    ></mat-icon>

    <app-date-time-popover
      [fromDate]="form.controls.fromDate"
      [toDate]="form.controls.toDate"
      [fromTime]="form.controls.fromTime"
      [toTime]="form.controls.toTime"
      [reminders]="form.controls.reminders"
    >
      <mat-icon
        class="form-quick-actions-item"
        svgIcon="quick-calendar"
        matTooltip="Custom Date"
      ></mat-icon>
    </app-date-time-popover>

    <app-priority-input
      class="form-quick-actions-item"
      [control]="form.controls.priority"
      [size]="16"
    ></app-priority-input>
  </div>

  <div class="form-buttons">
    <div
      *ngIf="!inline"
      class="form-buttons-item"
      (click)="clickMore()"
    >
      More
    </div>

    <div class="form-buttons-filler"></div>

    <div
      *ngIf="!inline"
      class="form-buttons-item form-buttons-item-cancel"
      (click)="!saveInProgress && cancel()"
    >
      Cancel
    </div>

    <div
      class="form-buttons-item"
      (click)="!saveInProgress && submit()"
    >
      Add
    </div>
  </div>
</div>
