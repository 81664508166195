// Common
import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-input-errors',
  templateUrl: './input-errors.component.html',
  styleUrls: ['./input-errors.component.less'],
})
export class InputErrorsComponent {
  @Input() inputFormControl: UntypedFormControl;
  @Input() validationMessages: { [key: string]: string } = {};
  @Input() appearance: 'default' | 'amethyst' | 'sapphire' = 'default';
  @Input() type: 'input' = 'input';
  @Input() size: 'xs' | 's' | 'm' | 'l' = 'xs';
}
