<div
  class="container"
  (drop)="handleDropFiles($event)"
  (dragenter)="dragOver = dragOver + 1"
  (dragleave)="dragOver = dragOver - 1"
  [class.drag-over]="dragOver > 0"
  #droppableArea
>
  <div class="card">
    <div class="cropper">
      <image-cropper
        [imageFile]="imageFile"
        [maintainAspectRatio]="true"
        [aspectRatio]="1"
        [roundCropper]="true"
        (imageCropped)="imageCropped($event)"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>

    <div class="file-input">
      Drop files to attach, or&nbsp;
      <div
        class="file-input-link"
        (click)="fileUploadInput.click()"
      >
        browse.
      </div>
    
      <input
        #fileUploadInput
        type="file"
        id="files-input"
        (change)="handleChange($event)"
      >
    </div>

    <div class="footer">
      <app-button
        *ngIf="croppedEvent"
        class="footer-button"
        label="SAVE"
        appearance="square-blue"
        [disabled]="uploadInProgress"
        (click)="handleSave()"
      />

      <app-button
        class="footer-button"
        appearance="square"
        label="CANCEL"
        [disabled]="uploadInProgress"
        (click)="handleCancel()"
      />
    </div>
  </div>

  <div for="files-input" class="droppable-area"></div>
</div>
