// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Services
import { CommentsService } from './services/comments.service';

// Modules
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { CommonModule as AppCommonModule } from '@modules/common/common.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { CommentComponent } from './components/comment/comment.component';
import { CommentsComponent } from './components/comments/comments.component';

@NgModule({
  imports: [
    AppCommonModule,
    CommonModule,
    FormControlsModule,
    UtilsModule,
  ],
  declarations: [
    CommentComponent,
    CommentsComponent,
  ],
  exports: [
    CommentsComponent
  ],
  providers: [
    CommentsService,
  ]
})
export class CommentsModule { }
