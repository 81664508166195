// Common
import { Component, Input } from '@angular/core';

// Types
import { Icon } from '@modules/icons/types/icons';
import { ButtonAppearance } from '@modules/form-controls/types/button-appearance';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.less'],
})
export class ButtonComponent {

  @Input() icon: Icon;
  /**
   * [rightIcon] only for 'sapphire-*'
   */
  @Input() rightIcon: Icon;
  @Input() label: string;
  @Input() type: 'button' | 'submit' = 'button';
  @Input() appearance: ButtonAppearance = 'default';
  /**
   * [size] 'small' | 'normal' | 'medium' only for 'default' | 'blue' | 'link' | 'link-blue' | 'square' | 'square-blue' | 'purple' | 'purple-outline'
   * [size] 'xs' | 's' | 'm' | 'l' only for 'sapphire-*'
   */
  @Input() size: 'small' | 'normal' | 'medium' | 'xs' | 's' | 'm' | 'l' = 'normal';
  @Input() disabled = false;
}
