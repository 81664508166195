// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Types
import { MessageFolder } from '@modules/messages/types/message-folder';
import { DragData, DragDataTypes, dragDataTypeAllowed } from '@modules/drag-n-drop/types/drag-data';
import { ViewType } from '@modules/linked-info/types/view-type';

// Services
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { StitchService } from '@modules/common/services/stitch.service';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-link-info-message-folder',
  templateUrl: './link-info-message-folder.component.html',
  styleUrls: ['./link-info-message-folder.component.less']
})
export class LinkInfoMessageFolderComponent {

  // Inputs
  @Input() folder: MessageFolder;
  @Input() viewType: ViewType = 'full';
  @Input() withContextMenu = true;
  @Input() withDraggable = true;
  @Input() withDroppable = true;
  @Input() withActions = true;
  @Input() withUnlink = true;
  @Input() removeOnly = false;

  // Outputs
  @Output() unlink: EventEmitter<void> = new EventEmitter();
  @Output() remove: EventEmitter<void> = new EventEmitter();

  // Public
  public contextMenuOpened = false;
  public isDragging = false;

  // Private
  private alive: Subject<void> = new Subject();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    this.folder &&
    !(
      dragData.type === DragDataTypes.messageFolder &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.folder.id
    ) &&
    dragDataTypeAllowed(dragData.type)

  /**
   * Constructor
   */

  constructor (
    private foldersService: MessageFoldersService,
    private stitchService: StitchService
  ) {

  }

  /**
   * Actions
   */

  unlinkItem(event) {
    event.stopPropagation();
    event.preventDefault();
    this.unlink.emit();
  }

  flag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.foldersService.flag({ ids: [this.folder.id] }, !this.folder.flagged)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.folder.flagged = !this.folder.flagged);
  }

  pin(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.foldersService.pin({ ids: [this.folder.id] }, !this.folder.pinned)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.folder.pinned = !this.folder.pinned);
  }

  archive() {
    this.foldersService.archive({ ids: [this.folder.id] }, !this.folder.archived)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.folder.archived = !this.folder.archived);
  }

  delete() {
    if (this.remove.observers.length > 0) {
      this.remove.emit();
    } else {
      this.folder.deleted
        ? this.foldersService.deletePermanently({ ids: [this.folder.id] })
        : this.foldersService.delete({ ids: [this.folder.id] }, true);
    }
  }

  dndDrop(dragData: DragData) {
    this.stitchService.linkDragData(this.folder, dragData);
  }
}
