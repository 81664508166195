<cdk-virtual-scroll-viewport
  [itemSize]="itemHeight"
  minBufferPx="880"
  maxBufferPx="1320"
  class="messages-list-wrapper"
  (mouseover)="isHover = true"
  (mouseleave)="isHover = false"
  appSelectableList
  [appSelectableListReset]="resetSelected"
>
  <ng-container *cdkVirtualFor="let message of itemsStreamObservable; let index = index">
    <div
      class="days-separator"
      *ngIf="!message?.pinned && isHover && message?.date | differentDay: items[index - 1]?.date"
    >
      <div class="days-separator-date">
        {{ message.date | date: 'MMM d, yyyy' }}
      </div>
    </div>

    <app-message
      [item]="message"
      [withTags]="withTags"
      [withKnots]="withKnots"
      [withConnections]="withConnections"
      [position]="index"
      [debug]="debug"
      (open)="openMessage.emit(message)"
      (appClick)="handleClickItem(message)"
      (appDblClick)="handleDoubleClickItem(message)"

      [appSelectable]="message"
      [appSelectablePosition]="index"
    ></app-message>
  </ng-container>

  <!-- Placeholders -->
  <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  <div *ngIf="loadingError" class="placeholder">Error occured while loading. <span (click)="items?.length ? refreshCurrentItems() : resetItems()" class="error">Click here to try again.</span></div>
</cdk-virtual-scroll-viewport>

<!-- Count -->
<div class="messages-list__selected" *ngIf="items?.length > 0 && ((showCountView | async) === true)">
  <div class="total">Items: {{items.length}}</div>
</div>

<div class="messages-stub" *ngIf="items?.length === 0 && !loading && !loadingError">
  <img class="messages-stub-image" src="assets/placeholders/placeholder-mail.svg"/>
  <p class="messages-stub-text">{{ placeholderText }}</p>
</div>
