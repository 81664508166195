<app-context-menu-item
  title="Insert Row Before"
  [close]="true"
  (execute)="addTop()"
></app-context-menu-item>

<app-context-menu-item
  title="Insert Row After"
  [close]="true"
  (execute)="addBottom()"
></app-context-menu-item>

<app-context-menu-item
  title="Move Up"
  [close]="true"
  (execute)="moveUp()"
></app-context-menu-item>

<app-context-menu-item
  title="Move Down"
  [close]="true"
  (execute)="moveDown()"
></app-context-menu-item>

<app-context-menu-divider></app-context-menu-divider>

<app-context-menu-item
  title="Delete"
  [close]="true"
  (execute)="delete()"
></app-context-menu-item>
