// Common
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';

// Types
import { Tab } from '@modules/common/types/tab';
import { AutomationRule } from '@modules/settings/types/automation-rule';
import { getFiltersFromTab } from '@modules/settings/types/automation-rules';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { AlertService } from '@modules/alert/services/alert.service';
import { AutomationRulesService } from '@modules/settings/services/automation-rules.service';

// RX
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

// Components
import { RuleFormComponent } from './rule-form/rule-form.component';

@Component({
  selector: 'app-settings-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.less']
})
export class RulesComponent implements OnInit, OnDestroy {
  // Public
  public tabs: Tab[] = [
    { title: 'MAIL APP', value: 'mail' },
    { title: 'CALENDAR APP', value: 'calendar' },
    { title: 'TASKING APP', value: 'tasking' },
    { title: 'NOTES APP', value: 'notes' },
    { title: 'CONTACTS APP', value: 'contacts' },
    { title: 'FILES APP', value: 'files' }
  ];
  public selectedTab: typeof this.tabs[number]['value'] = 'mail';
  public rules: AutomationRule[] = [];

  // Private
  private alive = new Subject<void>();
  private tabChange = new BehaviorSubject<typeof this.tabs[number]['value']>(this.selectedTab);

  constructor (
    private modalService: ModalService,
    private alertsService: AlertService,
    private automationRulesService: AutomationRulesService,
    private injector: Injector,
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.automationRulesService.getRefreshRequired()
    .pipe(
        switchMap(() => this.tabChange),
        map(tab => getFiltersFromTab(tab)),
        switchMap(stitchTypes => this.automationRulesService.listAll(stitchTypes)),
        takeUntil(this.alive)
      )
      .subscribe(rules => {
        this.rules = rules;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  openForm(rule?: AutomationRule) {
    this.modalService.showModal({
      component: RuleFormComponent,
      content: { rule },
      appearance: 'amethyst',
      title: 'Create an Automation Rule',
      resize: true,
      frame: {
        x: 'calc(50% - 400px)',
        y: 'calc(50% - 250px)',
        width: '800px',
        height: '500px'
      },
      collapsible: true,
      expandable: false,
      maxModals: 1,
      injector: this.injector,
    });
  }

  handleSelectTab(tab: typeof this.tabs[number]['value']) {
    this.selectedTab = tab;
    this.tabChange.next(tab);
  }

  handleActiveChange(active: boolean, rule: AutomationRule) {
    rule.active = active;
    this.automationRulesService.update(rule);
  }

  removeRule(rule: AutomationRule) {
    this.automationRulesService.delete(rule);
  }

  removeRulePrompt(rule: AutomationRule) {
    this.alertsService.show({
      title: 'Are you sure?',
      body: `Are you sure you want to delete rule ${rule.title}?`,
      rightButtons: [
        {
          title: 'CANCEL',
          close: true
        },
        {
          title: 'REMOVE',
          click: () => this.removeRule(rule),
          close: true
        }
      ],
      showUntil: this.alive
    });
  }
}
