// Common
import { ComponentRef, Directive, Input } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { SidebarContextMenuComponent } from '@modules/tasks/components/sidebar-context-menu/sidebar-context-menu.component';

// Types
import { VirtualFolder } from '@modules/tasks/types/virtual-folder';

@Directive({
  selector: '[appSidebarContextMenu]'
})
export class SidebarContextMenuDirective extends ContextMenuDirective {

  // Inputs
  @Input() appSidebarContextMenu: VirtualFolder;

  registerComponent() {
    return SidebarContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<SidebarContextMenuComponent>) {
    componentRef.instance.sidebarFilterKey = this.appSidebarContextMenu;
  }
}
