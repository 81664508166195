// Common
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';

// Types
import { QuickReplyTemplate } from '@modules/account/types/quick-reply-template';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { QuickReplyTemplatesService } from '@modules/account/services/quick-reply-templates.service';

// Components
import { QuickReplyFormComponent } from '@modules/settings/components/messages/quick-reply/quick-reply-form/quick-reply-form.component';

@Component({
  selector: 'app-settings-messages-quick-reply',
  templateUrl: './quick-reply.component.html',
  styleUrls: ['./quick-reply.component.less']
})
export class QuickReplyComponent implements OnInit, OnDestroy {
  public quickReplyTemplates: QuickReplyTemplate[];

  private onModalClose = new Subject<void>();
  private alive = new Subject<void>();

  constructor(
    private quickReplyTemplatesService: QuickReplyTemplatesService,
    private modalService: ModalService,
    private injector: Injector,
  ) { }

  ngOnInit(): void {
    this.quickReplyTemplatesService.search()
      .pipe(takeUntil(this.alive))
      .subscribe(({ items: quickReplyTemplates  }) => this.quickReplyTemplates = quickReplyTemplates);
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  handleRemove(template: QuickReplyTemplate) {
    this.quickReplyTemplatesService.delete(template);
  }

  handleOpenForm(quickReplyTemplate?: QuickReplyTemplate) {
    this.modalService.showModal({
      component: QuickReplyFormComponent,
      content: { quickReplyTemplate, invertedColor: true, singleButton: false, withCloseToken: true },
      appearance: 'default',
      title: quickReplyTemplate?.id ? 'Update Template' : 'Create Template',
      maxModals: 1,
      closed: this.onModalClose.asObservable,
      injector: this.injector,
    });
  }

}
