// Common
import { Directive, HostListener, Output, EventEmitter, Input } from '@angular/core';

// RX
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';

@Directive({
  selector: '[appClick],[appDblClick],[appClickStopPropagation]'
})
export class ClickDirective {

  // Outputs
  @Output() appClick = new EventEmitter<MouseEvent>();
  @Output() appDblClick = new EventEmitter<MouseEvent>();

  // Inputs
  @Input() appClickStopPropagation = false;

  // Private
  private isSingleClick = true;

  /**
   * Actions
   */

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent) {
    if (this.appClickStopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.isSingleClick = true;

    timer(200)
      .pipe(
        first(),
      )
      .subscribe(() => {
        if (this.isSingleClick) {
          this.appClick.emit(event);
        }
      });
  }

  @HostListener('dblclick', ['$event'])
  handleDblClick(event: MouseEvent) {
    if (this.appClickStopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.isSingleClick = false;
    this.appDblClick.emit(event);
  }
}
