<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <input
    class="form-title"
    placeholder="Untitled Group"
    [formControl]="form.controls.title"
    #titleInput
  />

  <app-color-input
    class="form-marker"
    icon="groups"
    [control]="form.controls.color"
    [size]="16"
  ></app-color-input>

  <div class="form-input-label">
    Phone
  </div>

  <app-phone-number-input
    [inputFormControl]="form.controls.phoneNumber"
    [noBorder]="true"
  ></app-phone-number-input>

  <div class="form-input-label">
    Email
  </div>

  <app-input
    [inputFormControl]="form.controls.email"
    placeholder="Enter Email"
  ></app-input>

  <div class="form-buttons">
    <div
      *ngIf="!inline"
      class="form-buttons-item"
      (click)="clickMore()"
    >
      More
    </div>

    <div class="form-buttons-filler"></div>

    <div
      *ngIf="!inline"
      class="form-buttons-item form-buttons-item-cancel"
      (click)="!saveInProgress && cancel()"
    >
      Cancel
    </div>

    <div
      class="form-buttons-item"
      (click)="!saveInProgress && submit()"
    >
      Add
    </div>
  </div>
</div>
