<div class="notifications">
  <div class="notifications-header">
    <div class="notifications-header-title">
      <h1>Notifications</h1>

      <span
        class="notifications-header-title-mark-all"
        (click)="handleMarkAsRead()"
      >Mark all as read</span>
    </div>

    <span class="notifications-header-toggle-title">Only show unread</span>
    <app-toggle-switch
      [value]="filters?.unread"
      (onChange)="handleChangeFilter('unreadFilter', $event )"
    ></app-toggle-switch>

    <div class="notifications-header-footer">
      <app-dropdown-multiple-input
        class="notifications-header-footer-dropdown"
        [inputFormControl]="applicationFilterControl"
        [options]="applicationDropDownOptions"
        appearance="amethyst"
        (select)="handleSelectApp($event)"
      ></app-dropdown-multiple-input>

      <mat-icon
        class="filter"
        svgIcon="filters"
      ></mat-icon>
    </div>
  </div>

  <div *ngIf="!items?.length">There are no notifications yet</div>

  <cdk-virtual-scroll-viewport
    [itemSize]="itemHeight"
    minBufferPx="500"
    maxBufferPx="1320"
    class="notifications-list-wrapper"
  >
    <ng-container *cdkVirtualFor="let notification of itemsStreamObservable">
      <app-notification
        [notification]="notification"
        [showIssueKey]="showIssueKey"
      ></app-notification>
    </ng-container>
  </cdk-virtual-scroll-viewport>

</div>

