<div class="board">
  <div class="board-area">
    <app-project-board-row
      [appearance]="tasksAppearance"
    ></app-project-board-row>

    <app-project-board-row
      *ngFor="let row of rows"
      [row]="row"
      [appearance]="tasksAppearance"
    ></app-project-board-row>

    <div
      class="board-area-text-button"
      (click)="addRow()"
      *ngIf="project"
    >
      + Add Row
    </div>
  </div>
</div>
