<div
  class="picker"
  [ngClass]="{collapsed: collapsed}"
  [@collapseMotion]="{value: collapsed ? 'collapsed' : 'expanded', params: {height: inlineHeight}}"
>
  <div #calendarContainer>
    <div class="picker-header">

      <div class="picker-header-month">
        <mat-icon
          class="picker-header-month-icon"
          svgIcon="arrow-left-thin"
          (click)="handlePreviousMonthClick()"
        ></mat-icon>

        <div
          class="picker-header-month-label"
          stchPopover
          stchPopoverPlacement="bottom"
          stchPopoverTrigger="click"
          [stchPopoverContent]="monthsTemplate"
          [stchPopoverShowUntil]="monthsPopoverClose"
        >
          {{ activeDate | async | date : 'MMMM y' }}
        </div>

        <mat-icon
          class="picker-header-month-icon"
          svgIcon="arrow-right"
          (click)="handleNextMonthClick()"
        ></mat-icon>
      </div>

      <div
        class="picker-header-today"
        (click)="handleDateSelect()"
      >
        Today
      </div>

      <mat-icon
        *ngIf="collapseable"
        svgIcon="collapse-down"
        class="picker-header-collapse-icon"
        [ngClass]="{collapsed: collapsed}"
        (click)="handleCollapse()"
      ></mat-icon>
    </div>

    <div class="picker-days">
      <mwl-calendar-month-view
        [viewDate]="activeDate | async"
        [events]="events"
        [cellTemplate]="dayTemplate"
        [weekStartsOn]="0"
        (dayClicked)="handleDateSelect($event.day.date)"
      ></mwl-calendar-month-view>
    </div>
  </div>
</div>

<ng-template
  #dayTemplate
  let-day="day"
  let-locale="locale"
  let-trackByEventId="trackByEventId"
>
  <div
    #dayRef
    class="day"
    [class.weekend]="day.isWeekend"
    [class.not-in-month]="!day.inMonth"
    [class.selected]="
      (day.date | sameDay : inputFormControl.value) ||
      (highlightRange && minDate && (day.date | sameDay : minDate)) ||
      (highlightRange && maxDate && (day.date | sameDay : maxDate))
    "
    [class.disabled]="(maxDate && maxDate < day.date) || (minDate && minDate > day.date)"
    [class.in-range]="highlightRange && (day.date | inDateRange : minDate : maxDate : inputFormControl.value)"
    appDroppable
    appDroppableHoverClass="drag-over"
    (appDroppableDrop)="handleDrop($event, day.date, dayRef)"
  >
    {{ day.date | date : 'd' }}
    <div class="day-dots">
      <div
        class="day-dots-item"
        *ngFor="let event of day.events.slice(0, 7)"
        [style.background-color]="event.color?.primary"
      ></div>
    </div>
  </div>
</ng-template>

<ng-template #monthsTemplate>
  <div class="months-input">
    <div class="months-input-label">Year</div>
    <div class="months-input-columns">
      <div class="months-input-columns-months">
        <div
          *ngFor="let month of months; let index = index"
          class="months-input-columns-months-item"
          [class.active]="(activeDate | async) | sameMonth : index"
          (click)="handleMonthClick(index)"
        >
          {{ month }}
        </div>
      </div>
      <div class="months-input-columns-years">
        <mat-icon
          svgIcon="collapse-down"
          class="months-input-columns-years-expand up"
          (click)="handleChangeYearsViewport(-1)"
        ></mat-icon>
        <div
          *ngFor="let year of years"
          class="months-input-columns-years-item"
          [class.active]="(activeDate | async) | sameYear : year"
          (click)="handleYearClick(year)"
        >
          {{ year }}
        </div>
        <mat-icon
          svgIcon="collapse-down"
          class="months-input-columns-years-expand down"
          (click)="handleChangeYearsViewport(1)"
        ></mat-icon>
      </div>
    </div>
    <div class="months-input-buttons">
      <app-button appearance="square" (click)="handleCancel()" label="Cancel" />
      <app-button appearance="square-blue" (click)="handleSave()" label="Save" />
    </div>
  </div>
</ng-template>
