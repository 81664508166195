import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inDateRange'
})
export class InDateRangePipe implements PipeTransform {

  transform(date: Date, minDate: Date, maxDate: Date, fallbackDate: Date): boolean {
    if ((!minDate && !maxDate)) { return false; }

    if (!fallbackDate && (!minDate || !maxDate)) { return false; }

    return (
      !!date &&
      date.getTime() > (minDate ? minDate.getTime() : fallbackDate.getTime()) &&
      date.getTime() < (maxDate ? maxDate.getTime() : fallbackDate.getTime())
    );
  }
}
