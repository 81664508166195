// Common
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

// RxJS
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';

// Services
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { ModalService } from '@modules/modal/services/modal.service';

// Types
import { Contact } from '@modules/mail/types/contact';

@Component({
  selector: 'app-user-context-menu',
  templateUrl: './contact-context-menu.component.html',
  styleUrls: ['./contact-context-menu.component.less']
})
export class ContactContextMenuComponent {
  // Inputs
  @Input() relatedContact: Contact;

  // Outputs
  @Output() loadInKnowledgePanel = new EventEmitter();

  // Private
  private gaMenuEventName = 'contact-context-menu';

  constructor(
    private ga: GoogleAnalyticsService,
    private modalService: ModalService,
    private router: Router,
  ) {

  }

  copyEmail() {
    this.ga.trackEvent(this.gaMenuEventName, 'copy-email');
    const email = this.relatedContact.email;
    fromEvent(document, 'copy')
      .pipe(take(1))
      .subscribe((clipboard: ClipboardEvent) => {
        clipboard.clipboardData.setData('text/plain', email);
        clipboard.preventDefault();
      });
    document.execCommand('copy');
  }

  sendNewEmail() {
    this.ga.trackEvent(this.gaMenuEventName, 'compose-mail');
    // this.modalService.compose(<MailMessage>{to: [{
    //   id: this.relatedContact.id,
    //   email: this.relatedContact.email,
    //   name: this.relatedContact.name,
    // }]});
  }

  openRelatedEmails() {
    // this.ga.trackEvent(this.gaMenuEventName, 'related-emails');
    // const searchParams: SearchParam[] = [new SearchParam(this.relatedContact.email, 'contact')];
    // const params = JSON.stringify(searchParams);
    // this.router.navigate(['/mail/search'], {queryParams: {query: params}});
  }

  openRelatedTopics() {
    // this.ga.trackEvent(this.gaMenuEventName, 'related-topics');
    // const searchParams: SearchParam[] = [new SearchParam(this.relatedContact.email, 'contact')];
    // const params = JSON.stringify(searchParams);
    // this.router.navigate(['/insights/search'], {queryParams: {query: params}});
  }

  loadContactInKnowledgePanel() {
    this.ga.trackEvent(this.gaMenuEventName, 'open-knowledge-pane');
    this.loadInKnowledgePanel.emit();
  }

  changeVipStatus(status: boolean) {

  }
}
