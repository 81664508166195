// Common
import { Injectable } from '@angular/core';
import { Stitch } from '@modules/common/types/stitch';

// Types
import { Tag } from '@modules/tags/types/tag';
import { Knot } from '@modules/knots/types/knot';
import { Connection } from '@modules/connections/types/connection';

// RX
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export class KnowledgePanelService {

  // Private
  private selectedStitchItem = new BehaviorSubject<Stitch>(null);
  private stitchItemKnots = new BehaviorSubject<Knot[]>([]);
  private stitchItemTags = new BehaviorSubject<Tag[]>([]);
  private stitchItemConnections = new BehaviorSubject<Connection[]>([]);
  private knotsHistory = new BehaviorSubject<Knot[][]>([]);
  private tagsHistory = new BehaviorSubject<Tag[][]>([]);
  private connectionsHistory = new BehaviorSubject<Connection[][]>([]);
  private lastKnotsSource: 'ff' | 'kp';
  private lastTagsSource: 'ff' | 'kp';
  private lastConnectionsSource: 'ff' | 'kp';

  /**
   * Actions
   */

  setSelectedStitchItem(item: Stitch) {
    this.selectedStitchItem.next(item);
    this.setKnotsHistory([]);
    this.setTagsHistory([]);
    this.setConnectionsHistory([]);
    this.lastKnotsSource = null;
    this.lastTagsSource = null;
    this.lastConnectionsSource = null;
  }

  getSelectedStitchItem(): Observable<Stitch> {
    return this.selectedStitchItem.pipe(distinctUntilChanged());
  }

  setKnotsHistory(knots: Knot[][]) {
    this.knotsHistory.next(knots);
  }

  setTagsHistory(tags: Tag[][]) {
    this.tagsHistory.next(tags);
  }

  setConnectionsHistory(connections: Connection[][]) {
    this.connectionsHistory.next(connections);
  }

  private pushToKnotsHistory(knots: Knot[], reset: boolean) {
    this.knotsHistory.next(
      [...(reset ? [] : this.knotsHistory.value.slice(-4)), knots]
        .filter(items => items.length)
    );
  }

  private pushToTagsHistory(tags: Tag[], reset: boolean) {
    this.tagsHistory.next(
      [...(reset ? [] : this.tagsHistory.value.slice(-4)), tags]
        .filter(items => items.length)
    );
  }

  addKnotToSelection(knot: Knot, multiple: boolean, resetHistory: boolean, source: 'ff' | 'kp') {
    const current = this.knotsHistory.value.length
      ? this.knotsHistory.value[this.knotsHistory.value.length - 1]
      : [];

    let selectedKnots: Knot[];

    if (current.find(i => knot.name === i.name)) {
      selectedKnots = current.filter(i => knot.name !== i.name);
    } else if (multiple && this.lastKnotsSource === source) {
      selectedKnots = [...current, knot];
    } else {
      selectedKnots = [knot];
    }

    if (selectedKnots) {
      this.lastKnotsSource = source;
      this.pushToKnotsHistory(selectedKnots, resetHistory);
    }
  }

  getSelectedKnots(): Observable<Knot[]> {
    return this.knotsHistory.pipe(
      map(history => history.length ? history[history.length - 1] : []),
      distinctUntilChanged()
    );
  }

  getKnotsHistory(): Observable<Knot[][]> {
    return this.knotsHistory.asObservable();
  }

  addTagToSelection(tag: Tag, multiple: boolean, resetHistory: boolean, source: 'ff' | 'kp') {
    const current = this.tagsHistory.value.length
      ? this.tagsHistory.value[this.tagsHistory.value.length - 1]
      : [];

    let selectedTags: Tag[];

    if (current.find(i => tag.name === i.name)) {
      selectedTags = current.filter(i => tag.name !== i.name);
    } else if (multiple && this.lastTagsSource === source) {
      selectedTags = [...current, tag];
    } else {
      selectedTags = [tag];
    }

    if (selectedTags) {
      this.lastTagsSource = source;
      this.pushToTagsHistory(selectedTags, resetHistory);
    }
  }

  getSelectedTags(): Observable<Tag[]> {
    return this.tagsHistory.pipe(
      map(history => history.length ? history[history.length - 1] : []),
      distinctUntilChanged()
    );
  }

  getTagsHistory(): Observable<Tag[][]> {
    return this.tagsHistory.asObservable();
  }

  setStitchItemKnots(knots: Knot[]) {
    this.stitchItemKnots.next(knots);
  }

  getStitchItemKnots(): Observable<Knot[]> {
    return this.stitchItemKnots.asObservable();
  }

  getStitchItemConnections(): Observable<Connection[]> {
    return this.stitchItemConnections.asObservable();
  }

  setStitchItemTags(tags: Tag[]) {
    this.stitchItemTags.next(tags);
  }

  getStitchItemTags(): Observable<Tag[]> {
    return this.stitchItemTags.asObservable();
  }

  setStitchItemConnections(connections: Connection[]) {
    this.stitchItemConnections.next(connections);
  }

  getSelectedConnections(): Observable<Connection[]> {
    return this.connectionsHistory.pipe(
      map(history => history.length ? history[history.length - 1] : []),
      distinctUntilChanged()
    );
  }

  getConnectionsHistory(): Observable<Connection[][]> {
    return this.connectionsHistory.asObservable();
  }

  addConnectionToSelection(connection: Connection, multiple: boolean, resetHistory: boolean, source: 'ff' | 'kp') {
    const current = this.connectionsHistory.value.length
      ? this.connectionsHistory.value[this.connectionsHistory.value.length - 1]
      : [];

    let selectedConnections: Connection[];

    if (current.find(i => connection.name === i.name)) {
      selectedConnections = current.filter(i => connection.name !== i.name);
    } else if (multiple && this.lastConnectionsSource === source) {
      selectedConnections = [...current, connection];
    } else {
      selectedConnections = [connection];
    }

    if (selectedConnections) {
      this.lastConnectionsSource = source;
      this.pushToConnectionsHistory(selectedConnections, resetHistory);
    }
  }

  private pushToConnectionsHistory(connections: Connection[], reset: boolean) {
    this.connectionsHistory.next(
      [...(reset ? [] : this.connectionsHistory.value.slice(-4)), connections]
        .filter(items => items.length)
    );
  }
}
