// Common
import { Component, Input } from '@angular/core';

@Component({
  selector: 'stch-knowledge-button',
  templateUrl: './knowledge-button.component.html',
  styleUrls: ['./knowledge-button.component.less'],
})
export class KnowledgeButtonComponent {
  @Input() size: 's' | 'l' = 's';
  @Input() disabled = false;
  @Input() active = false;
}
