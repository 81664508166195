<app-input
  class="input"
  [class.no-border]="noBorder"
  [appearance]="appearance"
  [inputFormControl]="innerControl"
  [label]="label"
  [placeholder]="label"
  [withClear]="withClear"
  [focusImmediately]="focusImmediately"
  [leftIcon]="leftIcon"
  [rightIcon]="loading ? 'sapphire-loading' : null"
  [invertedColor]="invertedColor"
  [withErrors]="withErrors"
  [validationMessages]="validationMessages"
  [placeholder]="placeholder"
  [hint]="hint"
  [size]="size"
  (onFocus)="handleFocus()"
  (onBlur)="focused = false"
  (onClear)="handleClear()"

  stchPopover
  stchPopoverPlacement="bottomFullWidth"
  [stchPopoverFallbackPlacements]="['topFullWidth']"
  [stchPopoverTemplate]="popoverTemplate"
  [stchPopoverCustomTrigger]="popoverCustomTrigger"
  [stchPopoverShowUntil]="popoverHide"
  [stchPopoverCloseOnOutsideClick]="true"
  [stchPopoverNoBorder]="true"
  (stchPopoverVisibleChange)="popoverOpened = $event"
/>

<ng-template #popoverTemplate>
  <stch-dropdown-container [appearance]="appearance | firstWord">
    <app-dropdown-item
      *ngFor="let option of options"
      [appearance]="appearance | firstWord"
      [option]="option"
      [itemTemplate]="itemTemplate"
      [active]="option.value === control?.value"
      (click)="handleSelect(option)"
    />

    <div class="options-placeholder" *ngIf="!options?.length">
      No items matched your search for<br />“{{ innerControl.value }}…”.
    </div>
  </stch-dropdown-container>
</ng-template>
