<div class="dropdown">
  <div class="header">
    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-backward"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-chevron-left"
      (click)="handleNextMonthClick(-1)"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-chevron-right"
      (click)="handleNextMonthClick(1)"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-forward"
    />

    <div class="filler"></div>

    <app-dropdown-month-year-input
      [control]="viewDate"
      appearance="sapphire-inline"
      placement="bottomLeft"
      size="l"
      [title]="viewDate.value | date : 'MMM yyyy'"
    />

    <div class="filler"></div>
    <div class="filler"></div>

    <app-button
      appearance="sapphire-primary-ghost"
      size="xs"
      (click)="setToday()"
      label="Today"
    />
  </div>

  <stch-calendar-month-small-di
    class="calendar"
    [selectedDate]="control?.value"
    [viewDate]="viewDate.value"
    [min]="min"
    [max]="max"
    [range]="range"
    (onDayClick)="handleDateSelect($event)"
  />
</div>
