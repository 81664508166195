<div
  class="container"
  (resize)="handleResize($event)"
>
  <div class="tags">
    <div
      *ngFor="let tag of (tags | slice : 0 : displayCount)"
      class="tags-item"
    >
      #{{ tag.name }}
    </div>
  </div>
  <div class="more">
    <mat-icon
      *ngIf="more"
      class="more-icon"
      svgIcon="more-actions"
      matTooltip="More Tags"
      stchPopover
      stchPopoverTrigger="click"
      stchPopoverPlacement="right"
      [stchPopoverContent]="tagsPopover"
      [appClickStopPropagation]="true"
    ></mat-icon>
  </div>
</div>

<div class="sandbox">
  <div
    *ngFor="let tag of tags"
    #tag
    class="tags-item"
  >
    #{{ tag.name }}
  </div>
</div>

<ng-template #tagsPopover>
  <div class="popover">
    <div
      *ngFor="let tag of tags"
      class="tags-item"
    >
      #{{ tag.name }}
    </div>
  </div>
</ng-template>
