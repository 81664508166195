<div
  class="container"
  [class.empty]="tree.length === 0"
  [@skipInitialAnimation]
  appDroppable
  [appDroppableDisabled]="disabled"
  [appDroppableZIndex]="droppableZIndex + depth"
  [appDroppableNoReposition]="true"
  [appDroppablePredicate]="predicate"
  (appDroppableDrop)="handleDrop($event)"
  (appDroppableEnter)="handleEnter()"
  (appDroppableLeave)="handleLeave()"
>
  <div *ngIf="!disabled && tree.length === 0">
    <div class="placeholder" [style.height.px]="hoverEmpty ? draggableBounding?.height : 30">
      Drop here
    </div>
  </div>

  <ng-container *ngFor="let item of tree; let index = index;">
    <div
      [style.margin-left.px]="depth * nestedPadding + (hoverNested ? nestedPadding : 0)"
      [@heightAnimation]="hoverIndex === index && !hoverAfter ? 'show' : 'hide'"
    >
      <div [style.height.px]="draggableBounding?.height"></div>
    </div>

    <div
      #item
      [@heightAnimation]="(dragIds | includesBy : item.id) ? 'hide' : 'show'"
    >
      <ng-container
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{
          $implicit: item.data,
          depth: depth,
          index: item.index,
          position: item.position,
          expandable: item.children.length,
          expanded: item.expanded
        }"
      ></ng-container>
    </div>

    <div
      [style.margin-left.px]="depth * nestedPadding + (hoverNested ? nestedPadding : 0)"
      [@heightAnimation]="hoverIndex === index && hoverAfter ? 'show' : 'hide'"
    >
      <div [style.height.px]="draggableBounding?.height"></div>
    </div>

    <div
      class="collapseable"
      [@heightAnimation]="!expanded[index] || (dragIds | includesBy : item.id) ? 'hide' : 'show'"
    >
      <div #content>
        <app-dnd-nested-items
          *ngIf="item.children.length"
          [template]="template"
          [tree]="item.children"
          [depth]="depth + 1"
          [nestedPadding]="nestedPadding"
          [droppableZIndex]="droppableZIndex"
          [predicate]="predicate"
          [disabled]="disabled"
        ></app-dnd-nested-items>
      </div>
    </div>
  </ng-container>
</div>
