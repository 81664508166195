// Common
import { Injectable, Injector } from '@angular/core';

// RX
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Types
import { Note } from '../types/note';
import { Notebook } from '../types/notebook';
import { ListState } from '@modules/notes/types/list-state';
import { VirtualFolder } from '../types/virtual-folder';
import { Application } from '@modules/common/types/application';

// Services
import { BaseAppStateService } from '@modules/common/services/base-app-state.service';

@Injectable()
export class NotesAppStateService extends BaseAppStateService<Notebook, Note, VirtualFolder> {

  protected application = Application.notes;
  protected defaultVirtualFolder: VirtualFolder = 'all_notes';

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  /**
   * Actions
   */

  getTabs(): Observable<ListState> {
    return this.getVirtualFolder()
      .pipe(
        map(folder => {
          switch (folder) {
            case 'all_notes':
              return 'notes';
            case 'upcoming':
            case 'flagged':
            case 'recent':
            case 'followed':
            case 'archived':
            case 'deleted':
            case 'followed':
            case 'snoozed':
              return 'tabs';
            case 'all_notebooks':
              return 'notebooks';
            default:
              return 'notes';
          }
        })
      );
  }
}
