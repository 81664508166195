<div class="day-list-popover">
  <div class="header">
    <div class="title">{{ day.date | date : 'd MMMM y' }}</div>

    <button class="button" (click)="handleClosePopover()">
      <div class="button-icon-container">
        <mat-icon svgIcon="sapphire-close" class="button-icon" />
      </div>
    </button>
  </div>

  <div class="list">
    <div
      *ngFor="let event of day.events"
      class="item"
      [class.past]="event.end | past"
    >
      <div class="main">
        <div
          class="dot"
          [style.background-color]="event.color.primary"
        ></div>

        <div class="title">{{ event.title }}</div>
      </div>

      <div *ngIf="event.allDay" class="time">All Day</div>

      <div *ngIf="!event.allDay" class="time">
        {{ event.start | date:'h:mm a' }} - {{ event.end | date:'h:mm a' }}
      </div>
    </div>
  </div>
</div>
