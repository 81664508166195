// Common
import { Component, Injector, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { BaseAppStateService } from '@modules/common/services/base-app-state.service';
import { PopoverService } from '@modules/popover/services/popover.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { dndStitchPredicate, DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { Application } from '@modules/common/types/application';
import { Tag } from '@modules/tags/types/tag';
import { Knot } from '@modules/knots/types/knot';
import { Connection } from '@modules/connections/types/connection';
import { StateKey } from '@modules/settings/types/state-key';

// Components
import { SidebarItemComponent } from '@modules/common/components/sidebar-item/sidebar-item.component';

@Component({
  template: ''
})
export abstract class BaseSidebarComponent<C extends Stitch, I extends Stitch, VirtualFolder> implements OnInit, OnDestroy {
  protected readonly StateKey = StateKey;

  public closeDroppedElement = new Subject<void>();
  public minimized: boolean;

  protected abstract application: Application;
  protected abstract sidebarSplitViewKey: SidebarSplitViewKey;
  protected abstract selfDragDataTypes: DragDataTypes[];
  protected sidebarFilter: VirtualFolder;
  protected alive: Subject<void> = new Subject();
  protected popoverService: PopoverService;

  private splitViewService: SplitViewService;
  private searchService: AdvancedSearchService;

  @ViewChildren('elRef') elements: QueryList<SidebarItemComponent>;
  @ViewChild('afterDropTemplate', { static: true }) template: TemplateRef<void>;

  public dndPredicate = (dragData: DragData): boolean => dndStitchPredicate(dragData.type);

  constructor(
    private injector: Injector,
    protected stateService: BaseAppStateService<C, I, VirtualFolder>,
  ) {
    this.splitViewService = injector.get(SplitViewService);
    this.popoverService = injector.get(PopoverService);
    this.searchService = injector.get(AdvancedSearchService);
  }

  /**
   * Lifecycle
   */

  ngOnInit(): void {
    this.splitViewService.getMinimized(this.sidebarSplitViewKey)
      .pipe(takeUntil(this.alive))
      .subscribe(minimized => {
        this.minimized = minimized;
      });

    this.stateService.getVirtualFolder()
      .pipe(takeUntil(this.alive))
      .subscribe(folder => {
        this.sidebarFilter = folder;

        if (!folder) { return; }

        const state = this.searchService.getStateSync();
        state[this.application].containersIds = [];
        this.searchService.setState(state);
      });

    this.searchService.getState()
      .pipe(takeUntil(this.alive))
      .subscribe(state => {
        if (
          state[this.application].containersIds.length > 0 &&
          !!this.sidebarFilter
        ) {
          this.stateService.setVirtualFolder(null);
        } else if (
          state[this.application].containersIds.length === 0 &&
          !this.sidebarFilter
        ) {
          this.stateService.setDefaultVirtualFolder();
        }
      })
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  selectFilter(filter: VirtualFolder) {
    this.stateService.setVirtualFolder(filter);
  }

  dndDrop(dragData: DragData, sidebarFolder: VirtualFolder, index?: number) {
    if (this.selfDragDataTypes.includes(dragData.type)) {
      this.handleMove(dragData, sidebarFolder);
    } else {
      const { elementRef } = this.elements.toArray()[index];

      this.popoverService.create(elementRef, {
        template: this.template,
        context: { item: this.getStitchFromDragData(dragData, sidebarFolder) },
        placement: 'bottom',
        injector: this.injector,
        trigger: 'click',
        showUntil: this.closeDroppedElement
      });
    }
  }

  handleClose() {
    this.closeDroppedElement.next();
  }

  abstract handleMore(item: Stitch): void;

  protected abstract handleMove(dragData: DragData, sidebarFolder: VirtualFolder): void;

  protected abstract getStitchFromDragData(dragData: DragData, sidebarFolder: VirtualFolder): Stitch;

  protected getIds(items: (Stitch | Tag | Knot | Connection)[]): string[] {
    return items.map(({ id }) => id);
  }
}
