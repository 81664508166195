<div
  stchPopover
  [stchPopoverArrow]="true"
  [stchPopoverContent]="popoverTemplate"
  [stchPopoverInnerShadow]="true"
  stchPopoverPlacement="right"
  [stchPopoverDisabled]="!minimized"
  stchPopoverTrigger="click"
>
  <app-sidebar-item
    title="Tags"
    icon="sapphire-tags"
    [depth]="0"
    [expandable]="true"
    [expanded]="expanded"
    [minimized]="minimized"
    [actions]="[{
      icon: 'sapphire-plus',
      template: addTagsTemplate,
      until: quickFormPopoverClose
    }]"

    appSidebarTagsKnotsContextMenu="tags"
  >
    <app-context-menu-state-indicator
      appearance="sapphire"
      svgIcon="sapphire-filters"
      [appTagsManageContextMenu]="true"
      [appTagsManageContextMenuStateKey]="stateKey"
      (appTagsManageContextMenuStateChange)="setState($event)"
    ></app-context-menu-state-indicator>
  </app-sidebar-item>
</div>

<div
  *ngIf="!minimized"
  class="collapseable-body"
  [@heightAnimation]="(expanded | async) ? 'show' : 'hide'"
>
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</div>

<ng-template #popoverTemplate>
  <div
    *ngIf="minimized"
    class="popover-body"
  >
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #contentTemplate>
  <div *ngIf="!count" class="placeholder">No pinned Tags found</div>
  <cdk-virtual-scroll-viewport
    itemSize="24"
    class="scroll-container"
  >
    <div *cdkVirtualFor="let tag of dataSource" class="scroll-container-item">
      <mat-icon
        class="scroll-container-item-unread"
        [class.inactive]="tag?.unreadCount === 0"
        svgIcon="other"
      ></mat-icon>

      <app-tag
        *ngIf="tag"
        class="tag"
        [tag]="tag"
        (click)="handleClick(tag)"
      ></app-tag>
    </div>
  </cdk-virtual-scroll-viewport>
</ng-template>

<ng-template #addTagsTemplate>
  <app-add-tags
    (close)="closePopovers()"
  ></app-add-tags>
</ng-template>
