// Common
import { Pipe, PipeTransform } from '@angular/core';

// RX
import { Contact } from '@modules/contacts/types/contact';

@Pipe({
  name: 'contactInitials'
})
export class ContactInitialsPipe implements PipeTransform {

  transform(contact: Contact): string {
    return contact && contact.title && contact.title.split(' ').map(i => i[0]).join('');
  }
}
