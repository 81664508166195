// Common
import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { PopoverPlacement } from '@modules/popover/types/placement';

// RX
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dropdown-date-input',
  templateUrl: './dropdown-date-input.component.html',
  styleUrls: ['./dropdown-date-input.component.less'],
})
export class DropdownDateInputComponent {
  @Input() control: UntypedFormControl;
  @Input() placeholder: string;
  @Input() withClear = false;
  @Input() appearance: 'default' | 'amethyst' | 'amethyst-simple' | 'sapphire' | 'sapphire-outline' | 'sapphire-inline' | 'sapphire-dark' = 'default';
  @Input() size: 's' | 'm' | 'l' = 'l';
  @Input() disabled = false;
  @Input() placement: PopoverPlacement = 'bottomLeft';
  @Input() dateFormat: 'week' | 'default' = 'default';

  public popoverHide = new Subject();
}
