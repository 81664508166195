// Common
import { Component, Input, OnDestroy, ElementRef, ViewChild, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

// Utils
import { TypedFormGroup } from '@modules/common/utils/form';

// RX
import { Subject, of } from 'rxjs';
import { takeUntil, switchMap, startWith } from 'rxjs/operators';

@Component({
  selector: 'stch-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.less'],
})
export class TimeSelectorComponent implements OnInit, OnChanges, OnDestroy, OnDestroy {

  static nextId = 0;

  @Input() control: FormControl<Date>;

  @ViewChild('hoursInput', { static: true }) hoursInput: ElementRef;
  @ViewChild('minutesInput', { static: true }) minutesInput: ElementRef;

  public period = new FormControl<boolean>(false);

  public parts: FormGroup<TypedFormGroup<{ minutes: string, hours: string, period: boolean }>>;
  public id = `time-input-${TimeSelectorComponent.nextId++}`;
  public selectedHour: number;
  public selectedMinute: number;

  private alive: Subject<void> = new Subject();
  private controlChanged = new Subject<void>();

  constructor() {
    this.parts = new FormGroup({
      minutes: new FormControl<string>(''),
      hours: new FormControl<string>('01'),
      period: new FormControl<boolean>(false),
    })
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.controlChanged
      .pipe(
        switchMap(() => this.control?.valueChanges || of(null as Date)),
        startWith(this.control?.value),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        if (value) {
          let hours = value.getHours();

          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'

          this.parts.setValue({
            minutes: `${ value.getMinutes() < 10 ? '0' : ''}${ value.getMinutes() }`,
            hours: hours.toString(),
            period: value.getHours() > 12
          });

          this.selectedHour = +this.parts.controls.hours.value;
          this.selectedMinute = +this.parts.controls.minutes.value;
        } else {
          this.parts.setValue({ minutes: '', hours: '', period: false }, { emitEvent: false });

          this.selectedHour = null;
          this.selectedMinute = null;
        }
      });

    this.parts.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(({ minutes, hours, period }) => {
        this.selectedHour = +hours;
        this.selectedMinute = +minutes;

        if (minutes.length === 2 && hours.length >= 1) {
          const time = new Date();

          time.setHours(period ? +hours + 12 : +hours);
          time.setMinutes(+minutes);
          time.setSeconds(0, 0);

          return this.control.setValue(time);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('control' in changes) {
      this.controlChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleHoursKeyPress(event: KeyboardEvent) {
    const nextValue = this.hoursInput.nativeElement.value.slice(0, this.hoursInput.nativeElement.selectionStart) + event.key +
    this.hoursInput.nativeElement.value.slice(this.hoursInput.nativeElement.selectionEnd, this.hoursInput.nativeElement.value.length);

    const regexp = this.parts.value.period ? /^(0[0-9]|1[0-1]|[0-9])$/ : /^(0[0-9]|1[0-2]|[0-9])$/;
    if (regexp.test(nextValue)) {
      const nextValueNum = parseInt(nextValue, 10);
      if (nextValueNum > 1 || nextValue.length === 2) {
        setTimeout(() => this.minutesInput.nativeElement.focus());
      }
    } else {
      event.preventDefault();
    }
  }

  handleHoursBlur(event: KeyboardEvent) {
    const value = this.hoursInput.nativeElement.value;
    const regexp = this.parts.value.period ? /^(0[0-9]|1[0-1]|[0-9])$/ : /^(0[0-9]|1[0-2]|[0-9])$/;
    if (regexp.test(value)) {
      const nextValueNum = parseInt(value, 10);
      this.parts.controls.hours.setValue(nextValueNum.toString());
    } else {
      this.parts.controls.hours.setValue('');
    }
  }

  handleHoursKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowRight' && this.hoursInput.nativeElement.selectionStart === this.hoursInput.nativeElement.value.length) {
      this.minutesInput.nativeElement.focus();
    }
  }

  handleMinutesKeyPress(event: KeyboardEvent) {
    const nextValue = this.minutesInput.nativeElement.value.slice(0, this.minutesInput.nativeElement.selectionStart) + event.key +
    this.minutesInput.nativeElement.value.slice(this.minutesInput.nativeElement.selectionEnd, this.minutesInput.nativeElement.value.length);

    if (!/^([0-5]?[0-9])$/.test(nextValue)) {
      event.preventDefault();
    }
  }

  handleMinutesBlur(event: KeyboardEvent) {
    const value = this.minutesInput.nativeElement.value;

    if (/^([0-5]?[0-9])$/.test(value)) {
      const nextValueNum = parseInt(value, 10);
      if (nextValueNum <= 9 && value.length < 2) {
        this.parts.controls.minutes.setValue('0' + nextValueNum);
      }
    } else {
      this.parts.controls.minutes.setValue('');
    }
  }

  handleMinutesKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      if (this.minutesInput.nativeElement.value === '') {
        this.hoursInput.nativeElement.focus();
      }
    }
    if (event.key === 'ArrowLeft' && this.minutesInput.nativeElement.selectionStart === 0) {
      setTimeout(() => this.hoursInput.nativeElement.focus());
    }
  }

  handleMinuteClick(minute: number) {
    this.parts.controls.minutes.setValue(`${ minute < 10 ? '0' : ''}${ minute }`);
  }

  handleHourClick(hour: number) {
    this.parts.controls.hours.setValue('' + hour);
  }
}
