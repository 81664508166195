import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allPropertiesTrue'
})
export class AllPropertiesTruePipe implements PipeTransform {

  transform(object: {[key: string]: boolean} = {}): boolean {
    return Object.values(object).every(Boolean);
  }
}
