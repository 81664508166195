<div
  *ngIf="event"
  class="event"
  [class.selected]="selected && (!isDragging || (isDragging && dragEnabled)) "
  [class.dragging]="!dragEnabled"
  [class.hovered]="contextMenuOpened"
  [class.pinned]="event.pinned"
  [class.snoozed]="event.snoozed"
  [class.followed]="event.followed"

  [appEventContextMenu]="event"
  (appEventContextMenuOpened)="contextMenuOpened = $event"
  (appEventContextMenuOpenEvent)="open.emit()"

  appDraggable
  [appDraggableDisabled]="!dragEnabled"
  [appDraggableData]="dragData"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div
    class="event-hover-overlay"
    stchPopover
    [stchPopoverArrow]="true"
    [stchPopoverPlacement]="hoverPlacement"
    stchPopoverTrigger="hover"
    [stchPopoverContent]="popoverTemplate"
    [stchPopoverDelay]="800"
    [stchPopoverDisabled]="!contextMenuOpened && !dragEnabled"
  ></div>

  <div class="event-selection"></div>

  <div class="event-body">
    <div class="event-body-title">
      <div
        class="event-body-title-text"
        [class.blank]="!event.title"
      >
        {{ event.title || 'Untitled Event' }}
      </div>
    </div>

    <div class="event-body-date">
      <mat-icon
        *ngIf="event?.repeat && event?.repeat !== 'noRepeat'"
        class="event-body-date-repeat"
        svgIcon="repeat"
      ></mat-icon>

      <app-date-time-popover
        [fromDate]="form.controls.startDate"
        [toDate]="form.controls.endDate"
        [fromTime]="form.controls.startTime"
        [toTime]="form.controls.endTime"
        [reminders]="form.controls.reminders"
        (click)="handleDatePicker('from', $event)"
        (onClose)="datePickerOpened = false"
      >
        <div
          class="event-body-date-item"
          [class.selected]="datePickerOpened && dateButtonSelected === 'from'"
          [class.blank]="!form.controls.startDate.value && !form.controls.startTime.value"
        >

          {{ form.controls.startDate.value | dateTimeDisplay: form.controls.startTime.value: 'No Start Date' }}
        </div>
      </app-date-time-popover>

      <div class="event-body-date-separator"></div>

      <app-date-time-popover
        [focusToDate]="true"
        [fromDate]="form.controls.startDate"
        [toDate]="form.controls.endDate"
        [fromTime]="form.controls.startTime"
        [toTime]="form.controls.endTime"
        [reminders]="form.controls.reminders"
        (click)="handleDatePicker('to', $event)"
        (onClose)="datePickerOpened = false"
      >
        <div
          class="event-body-date-item"
          [class.selected]="datePickerOpened && dateButtonSelected === 'to'"
          [class.blank]="!form.controls.endDate.value && !form.controls.endTime.value"
        >
          {{ form.controls.endDate.value | dateTimeDisplay: form.controls.endTime.value: 'No Due Date': event.allDay }}
        </div>
      </app-date-time-popover>
    </div>

    <div
      *ngIf="!withTags && !withKnots"
      class="event-body-description"
    >
      {{ event.description || 'No description' }}
    </div>

    <app-list-item-tags
      *ngIf="withTags"
      class="event-body-tags"
      [tags]="event.tags"
    ></app-list-item-tags>

    <app-list-item-knots
      *ngIf="withKnots"
      class="event-body-tags"
      [knots]="event.knots"
    ></app-list-item-knots>

    <app-link-info-bar [stitch]="event"></app-link-info-bar>
  </div>

  <mat-icon
    class="event-pin-icon"
    [class.active]="event.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  ></mat-icon>

  <mat-icon
    class="event-flag-icon"
    [class.active]="event.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  ></mat-icon>

  <mat-icon
    class="event-marker"
    [svgIcon]="event.archived ? 'folder-archive' : 'event'"
    [style.color]="event.color"
  ></mat-icon>

  <mat-icon
    *ngIf="event?.files?.length > 0"
    class="event-attachments"
    svgIcon="attachment"
  ></mat-icon>

  <app-contacts-counter-dropdown
    class="event-participants"
    [contactList]="event.participants"
    [size]="24"
    [minContactLen]="1"
    [showNoReplyMark]="true"
  ></app-contacts-counter-dropdown>

  <div
    class="event-hover-icons"
    *ngIf="dragEnabled && actionsButtonEnabled"
    (click)="$event.stopPropagation()"
  >
    <mat-icon
      class="event-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming Soon"
      (click)="createMail($event)"
    ></mat-icon>
    <mat-icon
      class="event-hover-icons-item"
      svgIcon="snooze"
      [matTooltip]="event?.snoozed ? 'Unsnooze' : 'Snooze'"
      [class.active]="!!event?.snoozed"
      (click)="snooze()"
    ></mat-icon>
    <mat-icon
      class="event-hover-icons-item"
      svgIcon="follow-up"
      [matTooltip]="event?.followed ? 'Unfollow' : 'Follow up'"
      [class.active]="!!event?.followed"
      (click)="followUp()"
    ></mat-icon>
    <mat-icon
      class="event-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="event"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    ></mat-icon>
    <mat-icon
      class="event-hover-icons-item"
      svgIcon="archive"
      (click)="archive()"
      [matTooltip]="event?.archived ? 'Restore from Archive' : 'Archive'"
    ></mat-icon>
    <mat-icon
      class="event-hover-icons-item"
      svgIcon="trash"
      (click)="delete()"
      [matTooltip]="event.readOnly ? 'Can\'t be deleted, read only' : (event?.deleted ? 'Restore from Trash' : 'Trash')"
    ></mat-icon>
  </div>

  <div class="drop-zone"></div>

  <div *ngIf="debug" class="event-debug">{{ event.score }}</div>
</div>

<div class="placeholder" *ngIf="!event">
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
  <div class="placeholder-11"></div>
  <div class="placeholder-12"></div>
</div>

<ng-template #dragPlaceholder>
  <app-event [item]="event" [dragEnabled]="false"></app-event>
</ng-template>

<ng-template #popoverTemplate>
  <event-popover-preview [event]="event"></event-popover-preview>
</ng-template>
