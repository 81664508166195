import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class Settings {
  formBuilder = injector.get(FormBuilder);

  contactsPostponeNotificationsEnabled: boolean;
  groupsPostponeNotificationsEnabled: boolean;
  notifications: boolean;

  constructor(data: any = {}) {
    this.contactsPostponeNotificationsEnabled = data.contactsPostponeNotificationsEnabled ?? false;
    this.groupsPostponeNotificationsEnabled = data.groupsPostponeNotificationsEnabled ?? false;
    this.notifications = data.notifications ?? data.contactsNotificationsEnabled ?? false;
  }

  static fromFormGroup(form: FormGroup): Settings {
    const { value: formValues } = form;

    return new Settings({
      contactsPostponeNotificationsEnabled: formValues.contactsPostponeNotificationsEnabled,
      groupsPostponeNotificationsEnabled: formValues.groupsPostponeNotificationsEnabled,
      notifications: formValues.notifications,
    });
  }

  public asFormGroup(): FormGroup {
    return this.formBuilder.group({
      contactsPostponeNotificationsEnabled: [this.contactsPostponeNotificationsEnabled],
      groupsPostponeNotificationsEnabled: [this.groupsPostponeNotificationsEnabled],
      notifications: [this.notifications],
    });
  }

  public asPayloadJSON() {
    return {
      contactsPostponeNotificationsEnabled: this.contactsPostponeNotificationsEnabled,
      groupsPostponeNotificationsEnabled: this.groupsPostponeNotificationsEnabled,
      notificationsEnabled: this.notifications,
    };
  }
}
