// Common
import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

// RX
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// Services
import { AuthService } from '../services/auth.service';

@Injectable()
export class UserValidator {
  static unique(authService: AuthService) {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return authService.isUsernameTaken(control.value)
        .pipe(
          map(({ isTaken, alternative }) => (isTaken ? { unique: alternative } : null)),
          catchError(() => of(null))
        );
    };
  }

  static weekPassword(control: AbstractControl): ValidationErrors | null {
    if (control.value.length === 0) { return null; }

    let score = 0;

    // award every unique letter until 5 repetitions
    const letters = {};

    for (let i = 0; i < control.value.length; i++) {
      letters[control.value[i]] = (letters[control.value[i]] || 0) + 1;
      score += 5.0 / letters[control.value[i]];
    }

    // bonus points for mixing it up
    const variations = {
      digits: /\d/.test(control.value),
      lower: /[a-z]/.test(control.value),
      upper: /[A-Z]/.test(control.value),
      nonWords: /\W/.test(control.value),
    };

    let variationCount = 0;

    for (const check of Object.keys(variations)) {
      variationCount += (variations[check]) ? 1 : 0;
    }

    score += (variationCount - 1) * 10;

    return score < 50 ? { weekPassword: true } : null;
  }

  static passwordConfirmation(controlName: string, matchingControlName: string) {
    return (form: UntypedFormGroup) => {
      if (form.controls[controlName].value !== form.controls[matchingControlName].value) {
        form.controls[matchingControlName].setErrors({ notEqual: true });
      } else if (form.controls[matchingControlName].errors?.notEqual) {
        delete form.controls[matchingControlName].errors.notEqual;
        form.controls[matchingControlName].updateValueAndValidity();
      }
      return null;
    };
  }
}
