// Common
import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { LocalStorageItem, LSBoolean } from 'src/app/decorators/local-storage.decorator';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StateKey } from '@modules/settings/types/state-key';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { BaseStitchListState } from '@modules/common/types/base-stitch-list-state';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { Application } from '@modules/common/types/application';

// RX
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { BaseAppStateService } from '@modules/common/services/base-app-state.service';

@Component({ template: '' })
export abstract class BaseSidebarContainersItemComponent<C extends Stitch, I extends Stitch, Filters extends StitchFilters, VirtualFolder, ListState extends BaseStitchListState> implements OnInit {
  @Output() selectFilter = new EventEmitter<VirtualFolder>();

  public abstract application: Application;
  public abstract sidebarSplitViewKey: SidebarSplitViewKey;
  public abstract listStateKey: StateKey;
  public sidebarFilter: VirtualFolder;
  public itemsListState = new Subject<ListState>();
  public minimized = true;
  public quickFormPopoverClose = new Subject<void>();
  public expanded = new BehaviorSubject<boolean>(false);
  
  protected filters: Filters;
  protected alive = new Subject<void>();
  
  private splitViewService: SplitViewService;
  @LSBoolean({ lsKey: 'sidebar-container.collapsed' }) private expandedStore: LocalStorageItem<boolean>;

  constructor(
    injector: Injector,
    protected stateService: BaseAppStateService<C, I, VirtualFolder>,
  ) {
    this.splitViewService = injector.get(SplitViewService);
  }

  ngOnInit(): void {
    this.splitViewService.getMinimized(this.sidebarSplitViewKey)
      .pipe(takeUntil(this.alive))
      .subscribe(minimized => {
        this.minimized = minimized;
      });

    this.stateService.getVirtualFolder()
      .pipe(takeUntil(this.alive))
      .subscribe(sidebar => {
        this.sidebarFilter = sidebar;
      });

    this.expandedStore.get(this.application)
      .pipe(takeUntil(this.alive))
      .subscribe(expanded => this.expanded.next(expanded));

    this.expanded
      .pipe(takeUntil(this.alive))
      .subscribe(expanded => this.expandedStore.set(expanded, this.application));
  }

  handleListStateChange(event: ListState) {
    this.itemsListState.next(event);
  }

  public handleMore(stitch: C) {
    this.stateService.setMainView(stitch);
  }
}
