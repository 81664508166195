// Common
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

// Utils
import phoneParser from 'libphonenumber-js';

export class ContactInfo {
  category: 'home' | 'work' | 'other';
  value?: string;

  constructor(object: any = {}) {
    this.category = object.category || 'home';
    this.value = object.value || '';
  }

  static asFormArray(items: ContactInfo[]): UntypedFormArray {
    return new UntypedFormArray(
      items?.length
        ? items.map(item => item.asFormGroup())
        : [new this({}).asFormGroup()]
    );
  }

  static createItem(type: 'emails' | 'phones'): UntypedFormGroup {
    return new this({}).asFormGroup();
  }

  static fromFormGroup(form: UntypedFormGroup): ContactInfo {
    const { value: values } = form;

    return new ContactInfo({
      category: values.category,
      value: values.value,
    });
  }

  asFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      category: new UntypedFormControl(this.category),
      value: new UntypedFormControl(this.value || '')
    });
  }
}

export class ContactEmail extends ContactInfo {

}

export class ContactPhone extends ContactInfo {
  static fromFormGroup(form: UntypedFormGroup): ContactPhone {
    const { value: values } = form;

    return new ContactPhone({
      category: values.category,
      value: !!phoneParser(values.value) && values.value,
    });
  }
}
