// Common
import { Injectable } from '@angular/core';

// RX
import { Observable, of } from 'rxjs';

@Injectable()
export class OnlineStatusService {

  getStatus(accountId: string): Observable<boolean> {
    return of(Math.random() > .3);
  }
}
