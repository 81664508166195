export enum KPListType {
  connection = 'connection',
  messageFolder = 'messageFolder',
  message = 'message',
  event = 'event',
  project = 'project',
  task = 'task',
  notebook = 'notebook',
  note = 'note',
  group = 'group',
  contact = 'contact',
  folder = 'folder',
  file = 'file'
}
