<div class="container">
  <div *ngFor="let month of months" class="month">
    <div class="header">
      {{ month | date: 'MMMM yyyy' }}
    </div>

    <stch-calendar-month-small
      [viewDate]="month"
      [dayTemplate]="dayTemplate"
      [events]="events"
      [refresh]="refreshCalendar"
    />
  </div>
</div>

<ng-template
  #dayTemplate
  let-day="day"
>
  <div
    #cell
    class="day"

    (appClick)="clickDay(event, day.date, cell)"
    (appDblClick)="dblClickDay(day.date)"

    appDroppable
    appDroppableHoverClass="drag-over"
    (appDroppableDrop)="handleDrop($event, day.date, cell)"

    stchPopover
    stchPopoverPlacement="bottomLeft"
    stchPopoverTrigger="hover"
    [stchPopoverDisabled]="day.events.length === 0 || contextMenuOpened"
    [stchPopoverDelay]="400"
    [stchPopoverContent]="moreTemplate"
    [stchPopoverShowUntil]="popoverClose"
    [stchPopoverContext]="{ day: day }"
    [stchPopoverFallbackPlacements]="['topLeft']"
  >
    <stch-calendar-month-small-cell
      [day]="day"
      [selectedDate]="selectedDate"
      size="l"

      [appCalendarCellContextMenu]="day.date"
      appCalendarCellContextMenuCalendarType="year"
      (appCalendarCellContextMenuLoadDayEvents)="handleLoadDayEvents(day.date)"
      (stchPopoverVisibleChange)="contextMenuOpened = $event"  
    />
  </div>
</ng-template>

<ng-template #moreTemplate let-day=day>
  <stch-events-list [day]="day" [popoverClose]="popoverClose" />
</ng-template>
