// Common
import { Component, Injector, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';

// Services
import { SelectableService } from '@modules/drag-n-drop/services/selectable.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { TaskingAppStateService } from '@modules/tasks/services/state.service';

// Types
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Application } from '@modules/common/types/application';
import { StateKey } from '@modules/settings/types/state-key';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Stitch } from '@modules/common/types/stitch';
import { ProjectsFilters } from '@modules/tasks/types/projects-filters';
import { VirtualFolder } from '@modules/tasks/types/virtual-folder';

// Components
import { BaseSidebarContainersTreeComponent } from '@modules/common/components/base-sidebar-containers-tree/base-sidebar-containers-tree.component';

@Component({
  selector: 'app-side-bar-projects-tree',
  templateUrl: './side-bar-projects-tree.component.html',
  styleUrls: ['./side-bar-projects-tree.component.less'],
  animations: [heightAnimation],
  providers: [SelectableService]
})
export class SideBarProjectsTreeComponent extends BaseSidebarContainersTreeComponent<Project, Task, ProjectsFilters, VirtualFolder> implements OnInit {

  selfDragDataTypes = [DragDataTypes.task, DragDataTypes.project];
  application = Application.tasking;
  treeStateKey = StateKey.taskingSidebarProjectsTree;

  public dndPredicate = (stitchItem: Stitch) => (dragData: DragData): boolean =>
    stitchItem &&
    !(
      dragData.type === DragDataTypes.project &&
      dragData.data.length === 1 &&
      dragData.data?.[0]?.id === stitchItem?.id
    ) &&
    dragDataTypeAllowed(dragData.type)

  constructor(
    injector: Injector,
    private projectsService: ProjectsService,
    private tasksService: TasksService,
    taskingAppStateService: TaskingAppStateService,
  ) {
    super(injector, projectsService, taskingAppStateService);
  }

  /**
   * Actions
   */

  public handleMove(dragData: DragData, item: Project) {
    const message = 'Successfully moved to ' + item.title;

    if (dragData.type === DragDataTypes.task) {
      this.tasksService.bunchUpdate(
        { ids: this.getIds(dragData.data) },
        { projectId: item.id },
        { message }
      );
    } else if (dragData.type === DragDataTypes.project) {
      this.projectsService.bunchUpdate(
        { ids: this.getIds(dragData.data) },
        { parentId: item.id },
        { message }
      );
    }
  }
}
