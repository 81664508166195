import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

export const commonFileTypes: DropdownSelectItem<string[]>[] = [
  {
    title: 'PDF',
    value: [
      'application/pdf'
    ].join('|')
  },
  {
    title: 'XLS File',
    value: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
      'application/vnd.ms-excel',
      'application/vnd.ms-excel.sheet.macroEnabled.12'
    ].join('|')
  },
  {
    title: 'DOC File',
    value: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ].join('|')
  },
  {
    title: 'PPT',
    value: [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.pr'
    ].join('|')
  },
  {
    title: 'JPEG',
    value: [
      'image/jpg',
      'image/jpeg'
    ].join('|')
  },
  { title: 'PNG', value: ['image/png'].join('|')},
  { title: 'Mp3', value: ['audio/mpeg'].join('|')},
  { title: 'Mp4', value: ['video/mp4'].join('|')},
  { title: 'ZIP Archive', value: ['application/zip'].join('|')},
  { title: 'RAR Archive', value: ['application/vnd.rar'].join('|')},
];
