// Common
import { Injectable } from '@angular/core';

// Types
import { TemporalExpression } from '@modules/common/types/temporal-expression';

// RX
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class FullFormService {

  // Private
  private selectedTemporalExpressions = new BehaviorSubject<TemporalExpression[]>([]);

  /**
   * Actions
   */

  addTemporalExpressionToSelection(expression: TemporalExpression, multiple: boolean) {
    const current = this.selectedTemporalExpressions.value;

    let selected: TemporalExpression[] = [];

    if (current.find(i => expression.id === i.id)) {
      selected = current.filter(i => expression.id !== i.id);
    } else if (multiple) {
      selected = [...current, expression];
    } else {
      selected = [expression];
    }

    this.selectedTemporalExpressions.next(selected);
  }

  getSelectedTemporalExpressions(): Observable<TemporalExpression[]> {
    return this.selectedTemporalExpressions.asObservable();
  }
}
