// Common
import { Component, Injector, Input, OnInit } from '@angular/core';

// RX
import { takeUntil } from 'rxjs/operators';

// Types
import { MessageFolder } from '@modules/messages/types/message-folder';
import { Message } from '@modules/messages/types/message';
import { Tab } from '@modules/common/types/tab';
import { VirtualFolder } from '@modules/messages/types/virtual-folder';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';

// Services
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { MailAppStateService } from '@modules/messages/services/state.service';

// Components
import { FullFormBaseComponent } from '@modules/common/components/full-form-base/full-form-base.component';

@Component({
  selector: 'app-message-folder-form',
  templateUrl: './message-folder-form.component.html',
  styleUrls: ['./message-folder-form.component.less']
})
export class MessageFolderFormComponent extends FullFormBaseComponent<MessageFolder> implements OnInit {

  @Input() folder = new MessageFolder();

  public changesKey = 'folder';
  tabs: Tab[] = [
    { title: 'Folder', value: 'folder'},
    { title: 'Details', value: 'details'},
    { title: 'Attachments', value: 'attachments'},
    { title: 'Stitch', value: 'stitch'},
    { title: 'Lab', value: 'lab'},
    { title: 'Comments', value: 'comments'},
  ];
  tabsStateKey = 'ffMessageFolder';
  public currentSidebarFilter: VirtualFolder;
  public quickMessage: Message;
  public parentSuggestions: AutocompleteFactory<MessageFolder>;

  constructor (
    private foldersService: MessageFoldersService,
    private messagesService: MessagesService,
    private stateService: MailAppStateService,
    injector: Injector,
  ) {
    super(injector, foldersService, stateService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    this.stateService.getVirtualFolder()
      .pipe(takeUntil(this.alive))
      .subscribe(folder => this.currentSidebarFilter = folder);

    this.stitchItemChanged
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.parentSuggestions = this.foldersService.getAutocompleteSuggestions({ exceptIds: [this.stitchItem.id] });
      });
  }

  /**
   * Actions
   */

  handleSubmitQuickMessage(message: Message) {
    if (!this.stitchItem) { return; }

    this.quickMessage = new Message({ folderId: this.stitchItem.id });

    this.messagesService.create(message)
      .pipe(takeUntil(this.alive))
      .subscribe();
  }

  /**
   * Helpers
   */

  get stitchItem(): MessageFolder {
    return this.folder;
  }

  protected shouldRefreshList(prev, current) {
    return MessageFolder.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): MessageFolder {
    return MessageFolder.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return this.folder.asFormGroup(this.foldersService);
  }
}
