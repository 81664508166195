// Common
import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RRule } from 'rrule';

// RX
import { Observable } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { BaseStitchChildService } from '@modules/common/services/base-stitch-child.service';
import { StitchService } from '@modules/common/services/stitch.service';

// Types
import { Like } from '@modules/common/types/like';
import { CalendarEvent } from '../types/calendar-event';
import { EventsFilters } from '@modules/calendar-app/types/events-filters';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { StitchType } from '@modules/common/types/stitch-type';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { BunchUpdateOptions } from '@modules/common/types/bunch-update-options';
import { KnowledgeFlags } from '@modules/common/types/knowledge-flags';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';

// Env
import { environment } from '@environment';

// Decorators
import { warmUpObservable } from '@decorators';

@Injectable()
export class EventsService extends BaseStitchChildService<CalendarEvent, EventsFilters> implements CalendarEventsService {
  protected url = environment.baseUrl + '/api/calendar/events/';
  protected stitchType = StitchType.event;
  protected filtersConstructor = EventsFilters;

  constructor (
    protected http: HttpClient,
    protected toasterService: ToasterService,
    @Optional() linkedInfoService: LinkedInfoService,
    @Optional() knotsService: KnotsService,
    @Optional() tagsService: TagsService,
    @Optional() stitchService: StitchService,
  ) {
    super(http, toasterService, stitchService, tagsService, knotsService, linkedInfoService);
  }

  public bunchUpdate(
    filters: Partial<EventsFilters>,
    changes: BunchUpdateOptions & Partial<Pick<CalendarEvent, 'startTime' | 'endTime' | 'calendarId'>>,
    config?: FeedbackConfig
  ): Observable<void> {
    return super.bunchUpdate(filters, changes as BunchUpdateOptions, config);
  }

  search(
    filters?: Partial<EventsFilters>,
    config?: KnowledgeFlags,
  ): Observable<BaseSearchResponse<CalendarEvent>> {
    return super.search(
      filters,
      config,
      ({ items, count }) => {
        if (!config?.withRecurrence || !filters?.fromTime || !filters?.toTime) { return { items, count }; }

        const newEvents = items.reduce((memo, e) => {
          let rule: RRule;

          try {
            rule = e.recurrence && RRule.fromString(e.recurrence);
          } catch (e) {}

          const startTime = e.startTime && new Date(e.startTime);
          const endTime = e.endTime && new Date(e.endTime);

          if (
            !rule ||
            !startTime || isNaN(startTime.getTime()) ||
            !endTime || isNaN(endTime.getTime())
          ) { return [...memo, e]; }

          const duration = new Date(endTime).getTime() - new Date(startTime).getTime();

          return [
            ...memo,
            ...rule.between(filters?.fromTime, filters?.toTime).map(occurence => ({
              ...e,
              startTime: occurence,
              endTime: new Date(occurence.getTime() + duration)
            }))
          ];
        }, []);

        return { items: newEvents, count: newEvents.length };
      });
  }

  create(
    eventInstance: CalendarEvent,
    { emit, toast, message }: FeedbackConfig = { emit: true }
  ): Observable<CalendarEvent> {
    return this.http.post<{ event: CalendarEvent, success: boolean }>(
      `${environment.baseUrl}/api/calendar/events`,
      eventInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }
          emit && this.forceRefresh();
          toast && this.toasterService.show({ text: message || `Event created.` });
        }),
        map(({ event }) => new CalendarEvent(event)),
        switchMap(event => this.processKnowledgeItems(event)),
        switchMap(event => this.processUploads(eventInstance.uploads, event, { other: true, parentFolderName: 'Calendar App' })),
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  update(
    eventInstance: CalendarEvent,
    { emitUpdate, displayToast, toastMessage }: FeedbackConfig = { emitUpdate: true }
  ): Observable<CalendarEvent> {
    return this.http.put<{ item: CalendarEvent, success: boolean }>
    (environment.baseUrl + '/api/calendar/events/' + eventInstance.id, eventInstance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          if (success) {
            emitUpdate && this.forceRefresh();
            displayToast && this.toasterService.show({ text: toastMessage || 'Calendar updated' });
          }
        }),
        map(({ item }) => item),
        catchError(error => this.handleObserverError(error)),
      );
  }

  getCalendarEvents(fromTime: Date, toTime: Date): Observable<AngularCalendarEvent[]> {
    return this.search({ fromTime, toTime }, { withRecurrence: true })
      .pipe(
        map(({ items }) => items.map(event => event.asAngularCalendarEvent()))
      );
  }

  getAutocompleteSuggestions(inputFilters: Like<EventsFilters> = {}): AutocompleteFactory<CalendarEvent> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      const filters: Like<EventsFilters> = {
        limit: config?.limit || 5,
        archived: false,
        deleted: false,
        ...inputFilters
      };

      if (values?.length) {
        filters.ids = values;
      } else if (title?.trim()) {
        filters.query = title;
        filters.esAnalyzer = 'ngrams_2_12';
        filters.esPriority = 'title';
        filters.esMultiMatchType = 'best_fields';
      }

      return this.search(filters)
        .pipe(
          map(({ items }) => items.map(event => ({
            title: event.title,
            value: event.id,
            source: event
          }))),
        );
    };
  }

  createInstance(item): CalendarEvent {
    return new CalendarEvent(item);
  }
}
