<div class="heading">
  <div class="heading-grow">
    <div class="heading1">Spaces</div> 
    <div class="heading2-subtitle">Spaces helps bring people together so that they can get things done.</div>
  </div>

  <app-button
    icon="plus-medium"
    appearance="link-blue"
    label="ADD SPACE"
    (click)="openForm()"
  />
</div>

<div class="items">
  <div
    *ngFor="let space of spaces"
    class="item"
  >
    <div class="item-title">{{ space.title }}</div>

    <div class="item-participants">
      <stch-avatar
        *ngFor="let user of space.participants"
        class="item-participants-avatar"
        [user]="user"
      />
    </div>

    <div class="item-text">{{ space.createdAt && (space.createdAt | timeAgo) }}</div>

    <mat-icon
      class="item-icon"
      svgIcon="groups"
      matTooltip="Participants"
      (click)="openParticipantsForm(space)"
    />

    <mat-icon
      class="item-icon edit"
      svgIcon="edit"
      matTooltip="Edit"
      (click)="openForm(space)"
    ></mat-icon>

    <mat-icon
      class="item-icon"
      svgIcon="trash"
      matTooltip="Delete"
      (click)="removeSpacePrompt($event, space)"
    ></mat-icon>
  </div>
</div>
