// Common
import { Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';

// Types
import { BaseAppAdvancedFilters } from './base-app-advanced-filters';
import { ContactEmail, ContactPhone } from '@modules/contacts/types/contact-info';
import { Country } from '@modules/common/types/country';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }]});

export class ContactsAppAdvancedFilters extends BaseAppAdvancedFilters {
  formBuilder = injector.get(UntypedFormBuilder);

  birthDate?: Date;
  company?: string;
  country: Country;
  description: string;
  email?: ContactEmail;
  jobTitle: string;
  name?: string;
  phone?: ContactPhone;

  constructor(filters?: any) {
    super(filters);
    this.country = filters?.country;
    this.birthDate = filters?.birthDate;
    this.company = filters?.company;
    this.description = filters?.description || '';
    this.email = new ContactEmail(filters?.email);
    this.jobTitle = filters?.jobTitle;
    this.name = filters?.name || '';
    this.phone = new ContactPhone(filters?.phone);
  }

  static fromFormGroup(form: UntypedFormGroup): ContactsAppAdvancedFilters {
    const filters = this.getFormValues(form);
    filters.country = Country.fromIso3(filters.country);
    filters.phone = ContactPhone.fromFormGroup(<UntypedFormGroup> form.controls.phone);
    filters.email = ContactEmail.fromFormGroup(<UntypedFormGroup> form.controls.email);

    return new ContactsAppAdvancedFilters(filters);
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...super.commonFormGroupFields(),
      country: [this.country?.iso3],
      birthDate: [this.birthDate],
      company: [this.company],
      description: [this.description],
      email: this.email.asFormGroup(),
      jobTitle: [this.jobTitle],
      name: [this.name],
      phone: this.phone.asFormGroup(),
    });
  }
}
