// Common
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environment';

// RX
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Types
import { Comment } from './../types/comment';
import { StitchType } from '@modules/common/types/stitch-type';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseService } from '@modules/common/services/base.service';

@Injectable()
export class CommentsService extends BaseService {

  constructor(
    private http: HttpClient,
    private toaster: ToasterService,
  ) {
    super();
  }

  protected handleObserverError(error: HttpErrorResponse) {
    this.toaster.show({ text:
      error?.error?.message ||
      error?.error?.error ||
      error?.error?.message ||
      error?.error?.map?.(i => i.constraints ? Object.values(i.constraints).join(', ') : '' ).join(', ') ||
      'Something went wrong.'
  })

    return throwError(error);
  }

  search(
    type: StitchType,
    id: string,
    { offset, limit }: { offset: number, limit: number}
  ): Observable<BaseSearchResponse<Comment>> {
    return this.http.get<{ count: number, items: Comment[] }>(
      `${environment.baseUrl}/api/comments/${type}/${id}`,
      { params: { limit, offset }}
    )
      .pipe(
        map(({ count, items }) => ({
          count,
          items: items.map(item => new Comment(item))
        })),
        catchError(error => this.handleObserverError(error))
      );
  }

  deletePermanently(
    id: string,
    { emit = true, toast = true, message = null }: FeedbackConfig = {},
  ): Observable<boolean> {
    return this.http.delete<{ success: boolean }>(
      `${environment.baseUrl}/api/comments/${id}`
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (!success) { return; }

          emit && this.forceRefresh();
          toast && this.toaster.show({
            text: message || 'Comment successfully deleted'
          });
        }),
        catchError(error => this.handleObserverError(error))
      );
  }

  create(
    instance: Comment,
    { emit, toast }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<Comment> {
    return this.http.post<{ comment: Comment, success: boolean }>(
      `${environment.baseUrl}/api/comments`,
      instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }
          emit && this.forceRefresh();
          toast && this.toaster.show({ text: 'Comment sent' });
        }),
        map(({ comment }) => new Comment(comment)),
        catchError(error => this.handleObserverError(error))
      );
  }

  update(
    instance: Comment,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<Comment> {
    return this.http.put<{ comment: Comment, success: boolean }>(
      environment.baseUrl + '/api/comments/' + instance.id, instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }
          emit && this.forceRefresh();
          toast && this.toaster.show({ text: message || 'Comment updated' });
        }),
        map(({ comment }) => new Comment(comment)),
        catchError(error => this.handleObserverError(error))
      );
  }

  upsert(
    instance: Comment,
    config?: FeedbackConfig
  ): Observable<Comment> {
    return (instance.id ? this.update(instance, config) : this.create(instance, config));
  }
}
