<stch-dropdown-container appearance="sapphire">
  <app-dropdown-item
    *ngFor="let option of options"
    appearance="sapphire"
    [option]="option"
    [active]="option.value | activeRemindersOption : control"
    (click)="handleSelect(option)"
  />

  <app-dropdown-item
    appearance="sapphire"
    [option]="{ title: 'Custom...' }"
    (click)="this.onCustom.emit()"
  />
</stch-dropdown-container>
