// Common
import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

// Services
import { GoogleAnalyticsService } from './modules/analytics/services/google-analytics.service';
import { PushNotificationsService } from './modules/notification/services/push-notifications.service';
import { AuthService } from './modules/auth/services/auth.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    public router: Router,
    private authService: AuthService,
    private ga: GoogleAnalyticsService,
    pushNotifications: PushNotificationsService
  ) {
    // Track screens to Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.ga.trackPage(event.urlAfterRedirects);
      }
    });

    // Auth changes
    this.authService.authenticated
      .pipe(filter(authenticated => authenticated))
      .subscribe(() => pushNotifications.init());
  }
}
