<cdk-virtual-scroll-viewport
  [itemSize]="itemHeight"
  minBufferPx="880"
  maxBufferPx="1320"
  class="folders-list-wrapper"
  (mouseover)="isHover = true"
  (mouseleave)="isHover = false"
  appSelectableList
  [appSelectableListReset]="resetSelected"
>
  <ng-container *cdkVirtualFor="let folder of itemsStreamObservable; let index = index">
    <div
      class="days-separator"
      *ngIf="!folder?.pinned && isHover && folder?.toTime | differentDay: items[index - 1]?.toTime"
    >
      <div class="days-separator-date">
        {{ folder.createdAt | date: 'MMM d, yyyy' }}
      </div>
    </div>

    <app-folder
      [item]="folder"
      [withTags]="withTags"
      [withKnots]="withKnots"
      [position]="index"
      [debug]="debug"
      (open)="openFolder.emit(folder)"
      (openFile)="openFile.emit($event)"
      (appClick)="handleClickItem(folder)"
      (appDblClick)="handleDoubleClickItem(folder)"

      [appSelectable]="folder"
      [appSelectablePosition]="index"
    ></app-folder>
  </ng-container>

  <!-- Placeholders -->
  <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  <div *ngIf="loadingError" class="placeholder">Error occured while loading. <span (click)="items?.length ? refreshCurrentItems() : resetItems()" class="error">Click here to try again.</span></div>
</cdk-virtual-scroll-viewport>

<!-- Count -->
<div class="folder-list__selected" *ngIf="items?.length > 0 && ((showCountView | async) === true)">
  <div class="total">Items: {{items.length}}</div>
</div>

<div class="folders-stub" *ngIf="items?.length === 0 && !loading && !loadingError">
  <img class="folders-stub-image" src="assets/placeholders/placeholder-tasks.svg"/>
  <p class="folders-stub-text">{{ placeholderText }}</p>
</div>
