export class Tag {
  name: string;
  createdAt?: Date;
  id?: string;
  pinned?: boolean;
  unreadCount?: number;

  // Tech properties
  added?: boolean;
  changed?: boolean;
  deleted?: boolean;

  constructor(tagObject) {
    this.createdAt = tagObject.createdAt && new Date(tagObject.createdAt);
    this.id = tagObject.id;
    this.pinned = tagObject.pinned;
    this.name = tagObject.name;
    this.unreadCount = tagObject.unreadCount || 0;

    this.added = tagObject.added;
    this.deleted = tagObject.deleted;
    this.changed = tagObject.changed;
  }

  static normalizeName = name => name.trim().toLowerCase().replace(/\s+/g, ' ').slice(0, 255);
}
