import { Tag } from '@modules/tags/types/tag';
import { Knot } from '@modules/knots/types/knot';
import { Connection } from '@modules/connections/types/connection';
import { Stitch } from '@modules/common/types/stitch';

export enum DragDataTypes {
  tag = 'tag',
  knot = 'knot',
  connection = 'connection',
  calendar = 'calendar',
  event = 'event',
  messageFolder = 'message-folder',
  message = 'message',
  project = 'project',
  task = 'task',
  notebook = 'notebook',
  note = 'note',
  group = 'group',
  contact = 'contact',
  folder = 'folder',
  file = 'file'
}

export interface DragData {
  type: DragDataTypes;
  data: (Stitch | Tag | Knot | Connection)[];
  index?: number;
  draggableId?: Symbol;
}

export const dragDataTypeAllowed = (type: DragDataTypes, exceptList: DragDataTypes[] = []): boolean => (
  Object.values(DragDataTypes).filter(key => !exceptList.includes(key as DragDataTypes)).includes(type)
);

export const dndStitchPredicate = (type: DragDataTypes): boolean => (
  Object.values(DragDataTypes)
    .filter(key => ![DragDataTypes.tag, DragDataTypes.knot, DragDataTypes.connection].includes(key as DragDataTypes))
    .includes(type)
);
