// Decorators
import { OnChange } from 'src/app/decorators/on-change';

// Types
import { BaseStitchListState } from '@modules/common/types/base-stitch-list-state';
import { Like } from '@modules/common/types/like';
import { StateKey } from '@modules/settings/types/state-key';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';

export class FoldersListState extends BaseStitchListState {
  @OnChange('onPropertyChange') sortBy?: 'title' | 'score';

  constructor(
    { searchService, stateService, stateKey, withAdvanced }:
    {
      searchService?: AdvancedSearchService,
      stateService?: StateService,
      stateKey?: StateKey,
      withAdvanced?: boolean
    }
  ) {
    super({ searchService, stateService, stateKey, withAdvanced });
  }

  protected applyState(state?: Like<FoldersListState>) {
    super.applyState(state);
    this.sortBy = state?.sortBy || 'title';
  }

  syncState() {
    super.syncState({
      sortBy: this.sortBy
    });
  }

  isDefault(): boolean {
    return (
      super.isDefault() &&
      this.sortBy === 'title' &&
      this.sortOrder === 'asc'
    );
  }

  syncFromAS({ mail: state }: AdvancedSearchState) {
    this.stitchedWith = state.stitched;
  }

  syncToAS(attribute) {
    if (!['stitchedWith', 'flagged'].includes(attribute)) {
      return;
    }

    const searchState = this.searchService.getStateSync();
    const state = searchState.mail;

    switch (attribute) {
      case 'flagged':
        break;
      case 'stitchedWith':
        state.stitched = this.stitchedWith;
        break;
    }

    this.searchService.setState(searchState);
  }
}
