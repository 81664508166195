import { KnotSource } from './knot-source';

export class Knot {
  autoDiscovery?: boolean;
  createdAt?: Date;
  entityType?: string;
  frequency?: number;
  id?: string;
  name: string;
  pinned?: boolean;
  recency?: Date;
  score?: number;
  source?: KnotSource;
  unreadCount?: number;

  // Tech properties
  added?: boolean;
  changed?: boolean;
  deleted?: boolean;

  constructor(knotObject) {
    this.autoDiscovery = !!knotObject.autoDiscovery;
    this.createdAt = knotObject.createdAt && new Date(knotObject.createdAt);
    this.entityType = knotObject.entityType;
    this.frequency = knotObject.frequency;
    this.id = knotObject.id;
    this.name = knotObject.name;
    this.pinned = !!knotObject.pinned;
    this.recency = knotObject.recency && new Date(knotObject.recency);
    this.score = parseFloat(knotObject.score);
    this.source = knotObject.source;
    this.unreadCount = knotObject.unreadCount;
    this.added = knotObject.added;
    this.deleted = knotObject.deleted;
    this.changed = knotObject.changed;
  }

  static normalizeName = name => name.trim().toLowerCase().replace(/\s+/g, ' ').slice(0, 255);
}
