import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  canActivate() {
    return this.authService.getAuthenticated().subscribe(authenticated => {
      if (authenticated) { return true; }

      this.router.navigate(['welcome']);
      return false;
    });
  }
}
