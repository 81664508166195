// Common
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { Connection } from '../types/connection';
import { ManageListState } from '../types/manage-list-state';

@Pipe({
  name: 'sortAndFilter'
})
export class SortAndFilterPipe implements PipeTransform {

  transform(connections: Connection[] = [], state: ManageListState): Connection[] {
    return connections
      .filter(connection =>
        !connection.deleted &&
        (!state?.filters?.createdFrom || connection.createdAt >= state.filters.createdFrom) &&
        (!state?.filters?.createdTo || connection.createdAt <= state.filters.createdTo)
      )
      .sort((a, b) => {
        const reverse = (state?.sort?.order === 'desc' ? -1 : 1);

        switch (state?.sort?.by) {
          case 'date':
            return (a.createdAt > b.createdAt ? 1 : -1) * reverse;
          case 'name':
          case 'frequency':
            return (a.name > b.name ? 1 : -1) * reverse;
        }
      });
  }
}
