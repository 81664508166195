// Common
import { Like } from './like';
import { isNil } from 'lodash';

// Types
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { StitchFilters } from './stitch-filters';
import { Application } from './application';

export abstract class StitchChildFilters extends StitchFilters {
  containersIds?: string[];
  noContainer?: boolean;

  constructor(filters?: Like<StitchChildFilters>) {
    super(filters);
    this.containersIds = filters?.containersIds;
    this.noContainer = filters?.noContainer;
  }

  applyAdvancedFilters(advancedState: AdvancedSearchState, application?: Application) {
    super.applyAdvancedFilters(advancedState);

    const appState = advancedState[application];

    this.containersIds = appState.containersIds;
    this.noContainer = !appState.containersIds.length;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.containersIds) { result['containers_ids[]'] = this.containersIds; }
    if (!isNil(this.noContainer)) { result['no_container'] = this.noContainer + ''; }

    return result;
  }
}
