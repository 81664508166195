// Common
import { Directive, Input, ComponentRef, Output, EventEmitter, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { TaskContextMenuComponent } from '../components/context-menus/task-context-menu/task-context-menu.component';

// Types
import { Task } from '@modules/tasks/types/task';
import { PopoverTrigger } from '@modules/popover/types/trigger';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appTaskContextMenu]'
})
export class TaskContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() appTaskContextMenu: Task;
  @Input() appTaskContextMenuDisabled = false;
  @Input() appTaskContextMenuMode: 'contextmenu' | 'popover' = 'contextmenu';

  // Outputs
  @Output() appTaskContextMenuOpened = this.contextMenuOpened;
  @Output() appTaskContextMenuOpenTask = new EventEmitter;
  @Output() appTaskContextMenuOpenSubtask = new EventEmitter<Task>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.stchPopoverDisabled = this.appTaskContextMenuDisabled;

    if (this.appTaskContextMenuMode === 'popover') {
      this.stchPopoverTrigger = 'click';
      this.stchPopoverPlacement = 'bottomLeft';
      this.stchPopoverFallbackPlacements = ['bottomRight'];
    }
  }

  /**
   * Actions
   */

  registerComponent() {
    return TaskContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<TaskContextMenuComponent>) {
    componentRef.instance.task = this.appTaskContextMenu;
    componentRef.instance.open
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.appTaskContextMenuOpenTask.emit();
      });

    componentRef.instance.openSubtask
      .pipe(takeUntil(this.alive))
      .subscribe(task => {
        this.appTaskContextMenuOpenSubtask.emit(task);
      });
  }
}
