<ng-template
  #overlay="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayHasBackdrop]="false"
  [cdkConnectedOverlayPositions]="[overlayPosition]"
  (positionChange)="positionChanged.next()"
  [cdkConnectedOverlayOpen]="visible"
  (overlayKeydown)="handleKeydown($event)"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
>
  <div
    class="arrow-overlay"
    #arrow
    (mousemove)="$event.stopPropagation()"
  >
    <div
      *ngIf="popoverArrow"
      class="arrow"
      [ngClass]="appearance"
    ></div>
  </div>

  <div
    cdkScrollable
    class="popover popover-nested-state-container"
    [ngClass]="nestedClassName"
    #popoverContainer
    (mouseleave)="handleMouseLeave($event)"
    [style.width.px]="staticWidth"
  >
    <div class="popover-shadow"></div>

    <div
      class="popover-inner"
      [ngClass]="appearance"
      [class.no-border]="noBorder"
      [ngStyle]="customStyles"
      (appOutsideClick)="handleOutsideClick()"
      [outsideClickExceptSelectors]="outsideClickExceptSelectors"
    >
      <ng-container *ngTemplateOutlet="contentTemplate; context: context" />
      <ng-template #contentRef />

      <div *ngIf="innerShadow" class="popover-inner-top-shadow"></div>
      <div *ngIf="innerShadow" class="popover-inner-bottom-shadow"></div>
    </div>
  </div>
</ng-template>
