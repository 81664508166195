// Common
import { Injector } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// Helpers
import { isEqual } from 'lodash';
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { addDays } from '@modules/common/utils/date';

// Types
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { Tag } from '@modules/tags/types/tag';
import { Knot } from '@modules/knots/types/knot';
import { StitchType } from './stitch-type';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { Connection } from '@modules/connections/types/connection';
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Like } from '@modules/common/types/like';
import { Space } from '@modules/settings/types/space';
import { Upload } from '@modules/common/types/upload';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }]});

export class Stitch {
  formBuilder = injector.get(UntypedFormBuilder);

  archived?: boolean;
  color?: string;
  connections?: Connection[];
  createdAt?: Date;
  debug?: object;
  deleted?: boolean;
  description?: string;
  flagged?: boolean;
  followed?: Date;
  hasStitched: boolean;
  hasStitchedCalendars?: boolean;
  hasStitchedContacts?: boolean;
  hasStitchedEvents?: boolean;
  hasStitchedFiles?: boolean;
  hasStitchedFolders?: boolean;
  hasStitchedGroups?: boolean;
  hasStitchedMessageFolders?: boolean;
  hasStitchedMessages?: boolean;
  hasStitchedNotebooks?: boolean;
  hasStitchedNotes?: boolean;
  hasStitchedProjects?: boolean;
  hasStitchedTasks?: boolean;
  id?: string;
  knots?: Knot[];
  linkedInfo?: LinkedInfo[];
  parentId?: string;
  pinned?: boolean;
  score?: number;
  shareUid?: string;
  sharedViaLinkAt?: Date;
  snoozed?: Date;
  tags?: Tag[];
  title?: string;
  updatedAt?: Date;
  uploads?: Upload[];

  noNotification?: boolean;

  constructor(data, space?: Space) {
    this.archived = !!data.archived;
    this.color = data.color || '#409aff';
    this.connections = (data.connections || []).map(connections => new Connection(connections));
    this.deleted = !!data.deleted;
    this.description = data.description || '';
    this.flagged = !!data.flagged;
    this.knots = (data.knots || []).map(knot => new Knot(knot));
    this.pinned = !!data.pinned;
    this.tags = (data.tags || []).map(tag => new Tag(tag));
    this.uploads = (data.uploads || []).map(upload => new Upload(upload));

    if (data instanceof Stitch) {
      this.linkedInfo = data.id ? [new LinkedInfo(data)] : [];
    } else {
      this.createdAt = data.createdAt && new Date(data.createdAt);
      this.debug = data.debug;
      this.followed = data.followed && new Date(data.followed);
      this.hasStitchedCalendars = !!data.hasStitchedCalendars;
      this.hasStitchedContacts = !!data.hasStitchedContacts;
      this.hasStitchedEvents = !!data.hasStitchedEvents;
      this.hasStitchedFiles = !!data.hasStitchedFiles;
      this.hasStitchedFolders = !!data.hasStitchedFolders;
      this.hasStitchedGroups = !!data.hasStitchedGroups;
      this.hasStitchedMessageFolders = !!data.hasStitchedMessageFolders;
      this.hasStitchedMessages = !!data.hasStitchedMessages;
      this.hasStitchedNotebooks = !!data.hasStitchedNotebooks;
      this.hasStitchedNotes = !!data.hasStitchedNotes;
      this.hasStitchedProjects = !!data.hasStitchedProjects;
      this.hasStitchedTasks = !!data.hasStitchedTasks;
      this.id = data.id;
      this.linkedInfo = data.linkedInfo || [];
      this.noNotification = data.noNotification;
      this.parentId = data.parentId || null;
      this.score = data.score;
      this.shareUid = data.shareUid;
      this.sharedViaLinkAt = data.sharedViaLinkAt && new Date(data.sharedViaLinkAt);
      this.snoozed = data.snoozed && new Date(data.snoozed);
      this.updatedAt = data.updatedAt && new Date(data.updatedAt);

      this.hasStitched = this.hasStitchedCalendars || this.hasStitchedContacts || this.hasStitchedEvents ||
        this.hasStitchedFiles || this.hasStitchedFolders || this.hasStitchedGroups ||
        this.hasStitchedMessageFolders || this.hasStitchedMessages || this.hasStitchedNotebooks ||
        this.hasStitchedNotes || this.hasStitchedProjects || this.hasStitchedTasks;
    }
  }

  static shouldRefreshList(prev: object, current: object, attributes: string[]): boolean {
    if (!prev || !current) { return true; }
    return attributes.some(attribute => !isEqual(prev[attribute], current[attribute]));
  }

  static fromDragData(dragData: DragData, returnFirstOnly: boolean = true): Stitch | Stitch[] {
    let fromDragData: Stitch[];

    switch (dragData.type) {
      case DragDataTypes.messageFolder:
      case DragDataTypes.message:
      case DragDataTypes.calendar:
      case DragDataTypes.event:
      case DragDataTypes.project:
      case DragDataTypes.task:
      case DragDataTypes.notebook:
      case DragDataTypes.note:
      case DragDataTypes.group:
      case DragDataTypes.contact:
      case DragDataTypes.folder:
      case DragDataTypes.file:
        fromDragData = dragData.data.map(dragItem => new this(dragItem));
        break;
      case DragDataTypes.tag:
        return new this({ tags: dragData.data });
      case DragDataTypes.knot:
        return new this({ knots: dragData.data });
      case DragDataTypes.connection:
        return new this({ connections: dragData.data });
      default:
        return checkExhaustiveness(dragData.type);
    }

    return returnFirstOnly ? fromDragData[0] : fromDragData;
  }

  static fromFormGroup(form: UntypedFormGroup): Stitch { return null; }

  public static getChangesForVirtualFolder(sidebar: 'archived' | 'deleted' | 'snoozed' | 'flagged' | 'followed'):
    { changes: Like<Stitch>, message: string } {
    let message = 'Successfully moved to ';
    switch (sidebar) {
      case 'archived':
        return { changes: { archived: true }, message: message += 'Archived' };
      case 'deleted':
        return { changes: { deleted: true }, message: message += 'Trash' };
      case 'followed':
        return { changes: { followed: addDays(new Date(), 1) }, message: message += 'Followed' };
      case 'snoozed':
        const date = new Date();
        date.setHours(date.getHours(), date.getMinutes() + 5, 0, 0);
        return { changes: { snoozed: date }, message: message += 'Snoozed' };
      case 'flagged':
        return { changes: { flagged: true }, message: message += 'Flagged' };
      default:
        checkExhaustiveness(sidebar);
    }
  }

  getStitchType(): StitchType { return null; }

  get sharedLink() {
    return `${ window.location.origin }/shared/${ this.getStitchType() }/${ this.shareUid }`;
  }

  asAngularCalendarEvent(): AngularCalendarEvent { return null; }

  asFormGroup(): FormGroup { return null; }

  asPayloadJSON(): object { return null; }

  protected fillFromStitch(data: Stitch) {
    this.title = data.title;
    this.linkedInfo = [new LinkedInfo(data)];
  }

  protected convertToAngularCalendarEvent(
    title: string, fromDate: Date, toDate: Date, fromTime: Date, toTime: Date
  ): AngularCalendarEvent {
    return  {
      id: this.id,
      start: fromDate && new Date(
        fromDate.getFullYear(),
        fromDate.getMonth(),
        fromDate.getDate(),
        fromTime && fromTime.getHours(),
        fromTime && fromTime.getMinutes()
      ),
      end: toDate && new Date(
        toDate.getFullYear(),
        toDate.getMonth(),
        toDate.getDate(),
        toTime && toTime.getHours(),
        toTime && toTime.getMinutes()
      ),
      title: title,
      color:  {
        primary: this.color,
        secondary: '#ffffff'
      },
      actions: undefined,
      allDay: undefined,
      cssClass: undefined,
      resizable: undefined,
      draggable: undefined,
      meta: this
    };
  }
}
