// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, Injector } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Services
import { FoldersService } from '@modules/files/services/folders.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Types
import { Folder } from '@modules/files/types/folder';
import { File } from '@modules/files/types/file';
import { PostponeType } from '@modules/common/types/postpone-type';
import { Application } from '@modules/common/types/application';

// RX
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Components
import { StitchContextMenuComponent } from '../stitch-context-menu/stitch-context-menu.component';

@Component({
  selector: 'app-folder-context-menu',
  templateUrl: './folder-context-menu.component.html',
  styleUrls: ['./folder-context-menu.component.less']
})
export class FolderContextMenuComponent extends StitchContextMenuComponent<Folder> implements OnInit, OnChanges {

  @Input() folder: Folder;

  @Output() openFile = new EventEmitter<File>();

  applicationName = Application.files;
  gaPrefix = 'folder-context-menu';
  public form: UntypedFormGroup;

  constructor (
    private foldersService: FoldersService,
    private toasterService: ToasterService,
    injector: Injector,
  ) {
    super(injector, foldersService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.item = this.folder;
    super.ngOnInit();
    this.resetForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('folder' in changes) {
      this.item = this.folder;
      this.resetForm();
    }
  }

  /**
   * Actions
   */

  handleSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.foldersService.update(Folder.fromFormGroup(this.form))
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => this.close());
  }

  resetForm() {
    this.form = (this.folder || new Folder()).asFormGroup();
  }

  handleFileFormMore(file: File) {
    this.openFile.emit(file);
  }

  duplicate() {
    if (!this.folder) { return; }

    const foldersToDuplicate = this.multiple ? this.selectedItems : [this.folder];

    combineLatest(
      foldersToDuplicate.map((folder) => this.foldersService.create(folder, { emitUpdate: false, displayToast: false }))
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.toasterService
          .show({
            text: `Folder${ this.multiple ? 's have' : ' has' } been duplicated`
          });
        this.foldersService.forceRefresh();
        this.close();
      });
  }

  postpone(date: Date, postponeType: PostponeType) {
    this.foldersService[postponeType]({ ids: this.getIds() }, date);
  }

  deletePermanently() {
    super.deletePermanently(`When deleting, all files in ${ this.multiple ? 'these' : 'this' } folder${ this.multiple && 's' } will be removed also`)
  }
}
