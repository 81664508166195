<div
  class="dropdown"
  [ngClass]="[appearance, size]"
  [class.opened]="opened"
  [class.inverted]="invertedColor"
  [class.disabled]="disabled"
  [class.icon-only]="iconOnly"
  [class.no-border]="insideGroup"
  stchPopover
  [stchPopoverPlacement]="placement"
  [stchPopoverFallbackPlacements]="['topLeft']"
  [stchPopoverContent]="optionsPopover"
  [stchPopoverShowUntil]="popoverHide"
  stchPopoverTrigger="click"
  [stchPopoverDisabled]="disabled || !popoverTemplate"
  [stchPopoverNoBorder]="true"
  (stchPopoverVisibleChange)="opened = $event"
>
  <mat-icon
    *ngIf="icon"
    class="icon"
    [style.color]="iconColor"
    [svgIcon]="icon"
  />

  <div
    *ngIf="placeholder && !iconOnly"
    class="placeholder"
    [class.opened]="opened || hasValue"
    [class.with-value]="hasValue"
  >
    <div class="truncate">{{ placeholder }}</div>
  </div>

  <div
    *ngIf="valueTemplate && (hasValue || !placeholder) && !iconOnly"
    class="input"
    [class.with-label]="placeholder"
  >
    <div class="truncate">
      <ng-container *ngTemplateOutlet="valueTemplate"/>
    </div>
  </div>

  <mat-icon
    *ngIf="withClear && hasValue"
    class="clear-icon"
    svgIcon="close-thin"
    (click)="handleClear($event)"
  />

  <mat-icon
    class="chevron-icon"
    [class.with-value]="hasValue"
    [svgIcon]="chevronIcon"
  />
</div>

<ng-template #optionsPopover>
  <ng-container *ngTemplateOutlet="popoverTemplate" />
</ng-template>
