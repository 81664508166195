// Common
import { Injectable } from '@angular/core';

// Types
import { Group } from '../types/group';
import { Contact } from '../types/contact';
import { Like } from '@modules/common/types/like';

// Services
import { ContactsService } from './contacts.service';
import { SectionsService } from './sections.service';
import { SignalsService } from '@modules/common/services/signals.service';
import { BaseSectionsListService } from '@modules/common/services/base-sections-list.service';

@Injectable()
export class SectionsListService extends BaseSectionsListService<Contact, Group>  {

  constructor (
    sectionsService: SectionsService,
    itemsService: ContactsService,
    signalsService: SignalsService
  ) {
    super(sectionsService, itemsService, signalsService);
  }

  protected createInstance(data: Like<Contact>): Contact {
    return new Contact(data);
  }
}
