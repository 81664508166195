// Common
import { Injectable, Injector } from '@angular/core';

// RX
import { Subject } from 'rxjs';

// Components
import { FullFormModalComponent } from '../components/full-form-modal/full-form-modal.component';

// Types
import { ModalConfig } from '../types/modal-config';
import { Stitch } from '@modules/common/types/stitch';

@Injectable()
export class ModalService {

  private display = new Subject<ModalConfig>();

  getDisplay() {
    return this.display.asObservable();
  }

  showModal(setting: ModalConfig) {
    if (this.display.observers.length < 1) {
      console.warn('No subscription from appModalHost');
    }

    if (this.display.observers.length > 1) {
      console.warn('More then one appModalHost defined');
    }

    this.display.next(setting);
  }

  /**
   * @param item
   * @param maxModals Count of modals which could be opened simultaneously.
   *  maxModals = 0 gives possibility to open infinity count of modals
   */
  openFullForm(item: Stitch, injector: Injector, maxModals = 1) {
    if (!item) { return; }

    this.showModal({
      component: FullFormModalComponent,
      content: { stitchItem: item },
      resize: true,
      collapsible: true,
      expandable: false,
      maxModals: maxModals || null,
      title: 'Stitch',
      frame: {
        x: 'calc(50% - 400px)',
        y: 'calc(50% - 250px)',
        width: '800px',
        height: '500px'
      },
      injector
    });
  }
}
