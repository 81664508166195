<mwl-calendar-month-view
  class="days"
  [@dateAnimation]="displayDate"
  [viewDate]="displayDate"
  [events]="events"
  [cellTemplate]="dayTemplate"
  [weekStartsOn]="0"
  (resize)="handleResize()"
  resizeDelayType="throttle"
/>

<ng-template
  #dayTemplate
  let-day="day"
  let-trackByEventId="trackByEventId"
>
  <div
    #dayRef
    class="day"
    [ngClass]="eventsAppearance"
    [class.weekend]="day.isWeekend"
    [class.not-in-month]="!day.inMonth"
    [class.today]="day.isToday"
    [class.past]="day.isPast"
    [class.selected]="(day.date | sameDay : selectedDate)"
    (appClick)="selectCalendarDate($event, day.date, dayRef)"
    (appDblClick)="dblClickCalendarDate(day.date)"

    appDroppable
    appDroppableHoverClass="drag-over"
    (appDroppableDrop)="handleDrop($event, day.date, dayRef)"

    [appCalendarCellContextMenu]="day.date"
    appCalendarCellContextMenuCalendarType="month"
    (appCalendarCellContextMenuLoadDayEvents)="handleLoadDayEvents(day.date)"
  >
    <div class="number">
      {{ day.date | date : 'd' }}
    </div>

    <div
      *ngIf="eventsAppearance === 'dots'"
      class="day-dots"
    >
      <div
        class="day-dots-item"
        *ngFor="let event of day.events"
        [style.background-color]="event.color.primary"
      ></div>
    </div>

    <div
      *ngIf="eventsAppearance === 'list'"
      class="day-list"
    >
      <div
        *ngFor="
          let event of day.events | slice : 0 : (day.events.length > maxEventsPerCell ? maxEventsPerCell - 1 : day.events.length);
          trackBy: trackByEventId
        "
        class="day-list-item"
        [class.past]="event.end | past"
      >
        <div
          class="dot"
          [style.background-color]="event.color.primary"
        ></div>

        <div class="title">
          {{ event.title }}
        </div>

        <div class="time">
          {{ event.start | date:'h:mm a' }}
        </div>
      </div>

      <button
        *ngIf="day.events.length > maxEventsPerCell"
        class="button"
        stchPopover
        stchPopoverPlacement="bottomLeft"
        stchPopoverTrigger="click"
        [stchPopoverContent]="moreTemplate"
        [stchPopoverShowUntil]="popoverClose"
        [stchPopoverContext]="{ day: day }"
        [stchPopoverFallbackPlacements]="['topLeft']"
        (click)="$event.stopPropagation();"
      >
        More
      </button>
    </div>
  </div>
</ng-template>

<ng-template #moreTemplate let-day=day>
  <stch-events-list [day]="day" [popoverClose]="popoverClose" />
</ng-template>
