<div
  *ngIf="form"
  class="block"
>
  <div class="block-grow">
    <div class="heading2">
      Trial Period
    </div>

    <div class="body-secondary">
      Limited-duration period during which users can access and use a service for free to evaluate its features and functionality before making a purchase decision.
    </div>

    <app-input
      class="input"
      appearance="amethyst"
      label="Trial Period"
      [inputFormControl]="form.controls.trialPeriod"
      [number]="true"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="true"
      [validationMessages]="{
        required: 'Can\'t be blank'
      }"
    ></app-input>
  </div>

  <div class="block-grow">
    <div class="heading2">
      Grace Period
    </div>

    <div class="body-secondary">
      Grace period for case when subscription renewal encounters an error or lacks sufficient funds, allowing users to reactivate their subscription without losing data or settings.
    </div>

    <app-input
      class="input"
      appearance="amethyst"
      label="Grace Period"
      [inputFormControl]="form.controls.gracePeriod"
      [number]="true"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="true"
      [validationMessages]="{
        required: 'Can\'t be blank'
      }"
    ></app-input>
  </div>
</div>
