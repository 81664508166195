// Common
import { Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { Priority } from '@modules/tasks/types/priority';

// Types
import { BaseAppAdvancedFilters } from './base-app-advanced-filters';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }]});

export class TaskingAppAdvancedFilters extends BaseAppAdvancedFilters {
  formBuilder = injector.get(UntypedFormBuilder);

  title?: string;
  description?: string;
  priority?: Priority;
  startDate?: Date;
  dueDate?: Date;
  completed?: boolean;
  overdue?: boolean;

  constructor(filters?: any) {
    super(filters);
    this.completed = !!filters?.completed;
    this.description = filters?.description || '';
    this.dueDate = filters?.dueDate ? new Date(filters.dueDate) : null;
    this.overdue = !!filters?.overdue;
    this.priority = ['low', 'normal', 'high'].includes(filters?.priority) ? filters.priority : null;
    this.startDate = filters?.startDate ? new Date(filters.startDate) : null;
    this.title = filters?.title || '';
  }

  static fromFormGroup(form: UntypedFormGroup): TaskingAppAdvancedFilters {
    return new TaskingAppAdvancedFilters(this.getFormValues(form));
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...super.commonFormGroupFields(),
      completed: [this.completed],
      description: [this.description],
      dueDate: [this.dueDate],
      overdue: [this.overdue],
      priority: [this.priority],
      startDate: [this.startDate],
      title: [this.title],
    });
  }
}
