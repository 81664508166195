<div class="header">
  <div class="header-title">
    <ng-container *ngTemplateOutlet="tabs"></ng-container>
  </div>

  <div class="header-separator"></div>

  <app-context-menu-state-indicator
    class="header-filters-icon"
    svgIcon="filters"
    appTagsManageContextMenu
    [appTagsManageContextMenuStateKey]="'kp-tags'"
    (appTagsManageContextMenuStateChange)="setState($event)"
  ></app-context-menu-state-indicator>
</div>

<div
  *ngIf="!stitchItem"
  class="placeholder"
>
  <img src="assets/placeholders/placeholder-pellets.svg"/>
  <div class="placeholder-text">No related tags</div>
</div>

<div
  *ngIf="stitchItem"
  class="list"
>
  <div class="tags">
    <div
      *ngFor="let tag of (neighborsTags | slice : 0 : (page + 1) * perPage)"
      class="tags-item"
      (click)="handleClickItem(tag, $event)"
    >
      #{{ tag?.name }}
    </div>

    <div
      *ngIf="page !== 0"
      class="more-less"
      (click)="showLess()"
    >
      &lt; Less
    </div>

    <div
      *ngIf="page < pagesCount - 1"
      class="more-less"
      (click)="showMore()"
    >
      More >
    </div>
  </div>
</div>
