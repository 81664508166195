<div class="panel" *ngIf="!minimized">
  <div class="panel-heading">
    <div class="panel-heading-top">
      <div class="panel-heading-top-title">
        {{ sidebarFilter | titleByTasksFilter }}
      </div>

      <div class="panel-heading-top-separator"></div>

      <div
        class="debug"
        [class.active]="debug"
        (click)="doDebug()"
      >
        {{ debug || 'Debug' }}
      </div>

      <div class="panel-heading-top-filters">
        <app-context-menu-state-indicator
          *ngIf="selectedTab === 'tasks'"
          svgIcon="filters"
          appTasksListContextMenu
          [appTasksListContextMenuWithSidebarFilters]="true"
          [appTasksListContextMenuWithAdvancedSearch]="true"
          [appTasksListContextMenuStateKey]="tasksListStateKey"
          (appTasksListContextMenuChange)="handleTasksListStateChange($event)"
        ></app-context-menu-state-indicator>

        <app-context-menu-state-indicator
          *ngIf="selectedTab === 'projects'"
          svgIcon="filters"
          appProjectsListContextMenu
          [appProjectsListContextMenuWithSidebarFilters]="true"
          [appProjectsListContextMenuWithAdvancedSearch]="true"
          [appProjectsListContextMenuStateKey]="projectsListStateKey"
          (appProjectsListContextMenuChange)="handleProjectsListStateChange($event)"
        ></app-context-menu-state-indicator>
      </div>
    </div>

    <app-tabs
      *ngIf="listState === 'tabs'"
      class="panel-heading-tabs"
      [values]="tabs"
      [(value)]="selectedTab"
    ></app-tabs>
  </div>

  <app-quick-form
    *ngIf="
      selectedTab === 'tasks' &&
      sidebarFilter !== 'archived' &&
      sidebarFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New Task"
  >
    <app-task-quick-form
      [task]="quickTask"
      (more)="handleNewTask($event)"
    ></app-task-quick-form>
  </app-quick-form>

  <app-quick-form
    *ngIf="
      selectedTab === 'projects' &&
      sidebarFilter !== 'archived' &&
      sidebarFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New Project"
  >
    <app-project-quick-form
      [project]="quickProject"
      (more)="handleNewProject($event)"
    ></app-project-quick-form>
  </app-quick-form>

  <div class="panel-list">
    <app-tasks-list
      *ngIf="selectedTab === 'tasks'"
      [filters]="tasksFilters"
      [debug]="debug"
      (loadInProgress)="loading = $event"
      (openTask)="handleClickTask($event)"
      (openSubtask)="handleClickTask($event)"
      (clickTask)="handleClickTask($event)"
      (doubleClickTask)="handleDoubleClickTask($event)"
    ></app-tasks-list>

    <app-projects-list
      *ngIf="selectedTab === 'projects'"
      [filters]="projectsFilters"
      [debug]="debug"
      (loadInProgress)="loading = $event"
      (openProject)="handleClickProject($event)"
      (openTask)="handleClickTask($event)"
      (clickProject)="handleClickProject($event)"
      (doubleClickProject)="handleDoubleClickProject($event)"
    ></app-projects-list>
  </div>
</div>
