<div
  class="reminder"
  *ngFor="let reminder of control.controls; let index = index"
>
  <stch-inputs-group>
    <app-dropdown-input
      class="type"
      [inputFormControl]="reminder.controls.notifyBy"
      [options]="typeOptions"
      appearance="sapphire-outline"
      size="m"
    />

    <app-input
      class="duration"
      appearance="sapphire-outline"
      [inputFormControl]="reminder.controls.duration"
      [number]="true"
    />

    <app-dropdown-input
      class="units"
      [inputFormControl]="reminder.controls.units"
      placement="bottomLeft"
      [options]="unitsOptions"
      appearance="sapphire-outline"
      size="m"
    />
  </stch-inputs-group>

  <div class="before-label">Before</div>

  <app-toggle-switch
    appearance="sapphire-selector"
    [inputFormControl]="reminder.controls.dateType"
    trueLabel="Start"
    falseLabel="End"
  />

  <mat-icon
    svgIcon="sapphire-close"
    class="delete-icon"
    (click)="deleteReminder(index)"
  />
</div>

<app-button
  appearance="sapphire-primary-ghost"
  size="xs"
  label="+ Add Reminder"
  (click)="addReminder()"
/>
