<div
  class="knot"
  [class.focused]="focused"
>
  <mat-icon
    *ngIf="knot?.pinned"
    class="knot-pin-icon"
    svgIcon="pin-small"
  ></mat-icon>

  <div
    class="knot-background"
    [class.focused]="focused"
    [class.pinned]="knot?.pinned"
    [class.placeholder]="!knot"
  >
    {{knot?.name}}
  </div>

  <input
    *ngIf="knot"
    class="knot-input"
    [class.pinned]="knot.pinned"
    [(ngModel)]="knot.name"
    (change)="handleChange()"
    (focus)="handleFocus()"
    (blur)="handleBlur()"
  />

  <div class="knot-separator"></div>

  <mat-icon
    *ngIf="!focused"
    class="knot-icon"
    [class.focused]="focused"
    svgIcon="pin"
    [ngClass]="{active: knot?.pinned}"
    [matTooltip]="knot?.pinned ? 'Unpin' : 'Pin'"
    (click)="handlePin()"
  ></mat-icon>

  <mat-icon
    *ngIf="!focused"
    class="knot-icon"
    svgIcon="trash"
    matTooltip="Delete"
    (click)="handleDelete()"
  ></mat-icon>
</div>

<ng-template #dragPlaceholder>
  <app-knot [knot]="knot" [dragEnabled]="false"></app-knot>
</ng-template>
