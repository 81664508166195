// Common
import { ComponentRef, Directive, Input } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { TemporalExpressionContextMenuComponent } from '../components/temporal-expression-context-menu/temporal-expression-context-menu.component';

// Types
import { TemporalExpression } from '@modules/common/types/temporal-expression';

@Directive({
  selector: '[appTemporalExpressionContextMenu]'
})
export class TemporalExpressionContextMenuDirective extends ContextMenuDirective {

  // Inputs
  @Input() appTemporalExpressionContextMenu: TemporalExpression;

  /**
   * Actions
   */

  registerComponent() {
    return TemporalExpressionContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<TemporalExpressionContextMenuComponent>) {
    componentRef.instance.temporalExpression = this.appTemporalExpressionContextMenu;
  }
}
