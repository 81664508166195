<div
  class="container"

  stchPopover
  stchPopoverTrigger="click"
  [stchPopoverContent]="prioritiesListTemplate"
  stchPopoverPlacement="right"
  [stchPopoverShowUntil]="popoverShowUntil"
  (stchPopoverVisibleChange)="open.emit($event)"
>
  <div
    class="priority-input-item-flag active"
    [ngClass]="control?.value"
    [style.width.px]="size"
    [style.height.px]="size"
    [style.borderRadius.px]="size / 2"
    svgIcon="controls-button-priority"

    matTooltip="Priority"
  ></div>
  <span
    *ngIf="withLabel"
    class="priority-input-label"
  >{{ control.value | titlecase }}</span>
</div>

<ng-template #prioritiesListTemplate>
  <div class="priority-input">
    <div
      class="priority-input-item"
      *ngFor="let priority of prioritiesList"
    >
      <div
        class="priority-input-item-title"
      >
        {{ priority.title }}
      </div>

      <div
        class="priority-input-item-flag"
        [class.active]="priority.name === control?.value"
        [ngClass]="priority.name"
        svgIcon="controls-button-priority"
        (click)="handleClick(priority.name)"
      ></div>
    </div>
  </div>
</ng-template>
