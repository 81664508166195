// Common
import { Component, Injector, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Services
import { EventsService } from '@modules/calendar-app/services/events.service';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { PopoverPlacement } from '@modules/popover/types/placement';

// RX
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.less'],
})
export class EventComponent extends BaseStitchComponent<CalendarEvent> {
  @Input() hoverPlacement: PopoverPlacement = 'right';
  public dateButtonSelected: 'from' | 'to' = 'from';
  public datePickerOpened = false;
  public form: UntypedFormGroup;

  moveDragDataTypes = [];
  dragDataType = DragDataTypes.event;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(
      dragData.type === DragDataTypes.event &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.item.id
    ) &&
    dragDataTypeAllowed(dragData.type)

  constructor (
    injector: Injector,
    protected eventsService: EventsService,
  ) {
    super(injector, eventsService);

    this.changed
      .pipe(
        tap(() => this.form = (this.item || new CalendarEvent()).asFormGroup()),
        switchMap(() => this.form.valueChanges),
        debounceTime(200),
        takeUntil(this.alive)
      )
      .subscribe(() => this.eventsService.update(CalendarEvent.fromFormGroup(this.form), { emit: false }));
  }

  handleDatePicker(dateButton: 'from' | 'to', event) {
    this.dateButtonSelected = dateButton;
    this.datePickerOpened = true;
    event.stopPropagation();
  }

  handleMove(dragData: DragData) {}

  get event(): CalendarEvent {
    return this.item;
  }
}
