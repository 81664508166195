// Common
import { ComponentRef, Directive, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { GroupsListContextMenuComponent } from '../components/groups-list-context-menu/groups-list-context-menu.component';

// Types
import { StateKey } from '@modules/settings/types/state-key';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { GroupsListState } from '../types/groups-list-state';

// RX
import { takeUntil } from 'rxjs/operators';

// Services
import { StateService } from '@modules/settings/services/state.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

@Directive({
  selector: '[appGroupsListContextMenu]'
})
export class GroupsListContextMenuDirective extends ContextMenuDirective implements OnInit, OnDestroy {

  // Inputs
  @Input() appGroupsListContextMenuStateKey: StateKey;
  @Input() appGroupsListContextMenuWithAdvancedSearch: boolean;
  @Input() appGroupsListContextMenuWithScore: boolean;
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';
  @Input() stchPopoverFallbackPlacements: PopoverPlacement[] = ['right'];

  // Outputs
  @Output() appGroupsListContextMenuChange = new EventEmitter<GroupsListState>();

  // Private
  private state: GroupsListState;

  constructor (
    private stateService: StateService,
    private searchService: AdvancedSearchService,
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<GroupsListContextMenuComponent>) {
    componentRef.instance.state = this.state;
    componentRef.instance.withAdvancedSearch = this.appGroupsListContextMenuWithAdvancedSearch;
    componentRef.instance.withScore = this.appGroupsListContextMenuWithScore;
  }

  registerComponent() {
    return GroupsListContextMenuComponent;
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.state = new GroupsListState({
      searchService: this.searchService,
      stateService: this.stateService,
      stateKey: this.appGroupsListContextMenuStateKey,
      withAdvanced: this.appGroupsListContextMenuWithAdvancedSearch
    });

    this.state.changes()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.appGroupsListContextMenuChange.emit(this.state);
        this.isDefaultStateObservable?.next(this.state.isDefault());
      });

    if (!this.isDefaultStateObservable) { return; }

    this.isDefaultStateObservable
      .pipe(takeUntil(this.alive))
      .subscribe(isDefault => {
        if (isDefault && !this.state.isDefault()) {
          this.state.resetToDefault();
        }
      });
  }

  ngOnDestroy() {
    this.state.detach();
    super.ngOnDestroy();
  }
}
