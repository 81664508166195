<div class="form">
  <div class="form-date">{{ folder?.createdAt | date : 'MMM d' }}</div>

  <h2 class="form-title">
    <mat-icon
      class="form-heading-title-icon"
      svgIcon="folder"
      [style.color]="folder?.color"
    />
    {{ folder?.title }}
  </h2>

  <div class="form-details">
    <div class="form-details-row">
      <div class="form-details-row-title">Parent Folder</div>
      <div class="form-details-row-input">{{ folder?.parentId }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Description</div>
      <div class="form-details-row-input">{{ folder?.description }}</div>
    </div>
  </div>
</div>
