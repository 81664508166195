import { Injector } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }]});

export class SubscriptionPlan {
  formBuilder = injector.get(UntypedFormBuilder);

  description: string;
  id: string;
  title: string;
  currency: string;
  monthlyPrice: number;
  annuallyPrice: number;
  suspended: boolean;
  maxSpaces: number;
  maxInboxes: number;
  maxDiskSpace: number;
  additionalDiskSpacePricePerGb: number;
  taskingAppEnabled: boolean;
  mailAppEnabled: boolean;
  calendarAppEnabled: boolean;
  notesAppEnabled: boolean;
  contactsAppEnabled: boolean;
  filesAppEnabled: boolean;
  knotsEnabled: boolean;
  tagsEnabled: boolean;
  connectionsEnabled: boolean;
  maxKnotsCount: number;
  maxTagsCount: number;
  maxConnectionsCount: number;
  createdAt: Date;
  updatedAt: Date;

  constructor(data: any = {}) {
    this.description = data?.description;
    this.id = data?.id;
    this.title = data?.title;
    this.currency = data?.currency;
    this.monthlyPrice = data?.monthlyPrice;
    this.annuallyPrice = data?.annuallyPrice;
    this.suspended = data?.suspended;
    this.maxSpaces = data?.maxSpaces;
    this.maxInboxes = data?.maxInboxes;
    this.maxDiskSpace = data?.maxDiskSpace;
    this.additionalDiskSpacePricePerGb = data?.additionalDiskSpacePricePerGb;
    this.taskingAppEnabled = !!data?.taskingAppEnabled;
    this.mailAppEnabled = !!data?.mailAppEnabled;
    this.calendarAppEnabled = !!data?.calendarAppEnabled;
    this.notesAppEnabled = !!data?.notesAppEnabled;
    this.contactsAppEnabled = !!data?.contactsAppEnabled;
    this.filesAppEnabled = !!data?.filesAppEnabled;
    this.knotsEnabled = !!data?.knotsEnabled;
    this.tagsEnabled = !!data?.tagsEnabled;
    this.connectionsEnabled = !!data?.connectionsEnabled;
    this.maxKnotsCount = data?.maxKnotsCount;
    this.maxTagsCount = data?.maxTagsCount;
    this.maxConnectionsCount = data?.maxConnectionsCount;
    this.createdAt = data.createdAt ? new Date(data.createdAt) : null;
    this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : null;
  }

  static fromFormGroup(form: UntypedFormGroup): SubscriptionPlan {
    const formValues = form.value;

    return new SubscriptionPlan({
      description: formValues.description,
      id: formValues.id,
      title: formValues.title,
      suspended: formValues.suspended,
      monthlyPrice: formValues.monthlyPrice,
      annuallyPrice: formValues.annuallyPrice,
      maxSpaces: formValues.maxSpaces,
      maxInboxes: formValues.maxInboxes,
      maxDiskSpace: formValues.maxDiskSpace,
      additionalDiskSpacePricePerGb: formValues.additionalDiskSpacePricePerGb,
      taskingAppEnabled: formValues.taskingAppEnabled,
      mailAppEnabled: formValues.mailAppEnabled,
      calendarAppEnabled: formValues.calendarAppEnabled,
      notesAppEnabled: formValues.notesAppEnabled,
      contactsAppEnabled: formValues.contactsAppEnabled,
      filesAppEnabled: formValues.filesAppEnabled,
      knotsEnabled: formValues.knotsEnabled,
      tagsEnabled: formValues.tagsEnabled,
      connectionsEnabled: formValues.connectionsEnabled,
      maxKnotsCount: formValues.maxKnotsCount,
      maxTagsCount: formValues.maxTagsCount,
      maxConnectionsCount: formValues.maxConnectionsCount,
      createdAt: formValues.createdAt,
      updatedAt: formValues.updatedAt,
    });
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      description: [this.description, Validators.required],
      id: [this.id],
      title: [this.title, Validators.required],
      suspended: [true],
      monthlyPrice: [this.monthlyPrice, [Validators.required, Validators.min(50)]],
      annuallyPrice: [this.annuallyPrice, [Validators.required, Validators.min(50)]],
      maxSpaces: [this.maxSpaces, Validators.required],
      maxInboxes: [this.maxInboxes, Validators.required],
      maxDiskSpace: [this.maxDiskSpace, Validators.required],
      additionalDiskSpacePricePerGb: [this.additionalDiskSpacePricePerGb, Validators.required],
      taskingAppEnabled: [this.taskingAppEnabled],
      mailAppEnabled: [this.mailAppEnabled],
      calendarAppEnabled: [this.calendarAppEnabled],
      notesAppEnabled: [this.notesAppEnabled],
      contactsAppEnabled: [this.contactsAppEnabled],
      filesAppEnabled: [this.filesAppEnabled],
      knotsEnabled: [this.knotsEnabled],
      tagsEnabled: [this.tagsEnabled],
      connectionsEnabled: [this.connectionsEnabled],
      maxKnotsCount: [this.maxKnotsCount, Validators.required],
      maxTagsCount: [this.maxTagsCount, Validators.required],
      maxConnectionsCount: [this.maxConnectionsCount, Validators.required],
      createdAt: [this.createdAt],
      updatedAt: [this.updatedAt],
    });
  }

  asPayloadJSON() {
    return {
      description: this.description,
      id: this.id,
      title: this.title,
      monthlyPrice: this.monthlyPrice,
      annuallyPrice: this.annuallyPrice,
      suspended: this.suspended,
      maxSpaces: this.maxSpaces,
      maxInboxes: this.maxInboxes,
      maxDiskSpace: this.maxDiskSpace,
      additionalDiskSpacePricePerGb: this.additionalDiskSpacePricePerGb,
      taskingAppEnabled: this.taskingAppEnabled,
      mailAppEnabled: this.mailAppEnabled,
      calendarAppEnabled: this.calendarAppEnabled,
      notesAppEnabled: this.notesAppEnabled,
      contactsAppEnabled: this.contactsAppEnabled,
      filesAppEnabled: this.filesAppEnabled,
      knotsEnabled: this.knotsEnabled,
      tagsEnabled: this.tagsEnabled,
      connectionsEnabled: this.connectionsEnabled,
      maxKnotsCount: this.maxKnotsCount,
      maxTagsCount: this.maxTagsCount,
      maxConnectionsCount: this.maxConnectionsCount,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}
