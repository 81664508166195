<ng-template #popoverTemplate>
  <div class="template">
    <div
      *ngFor="let action of actions"
      class="action"
      (mousedown)="$event.preventDefault()"
      (mouseup)="$event.stopPropagation(); handleAction(action)"
    >
      {{ action.title }}
    </div>
  </div>
</ng-template>

<div
  #anchor
  class="anchor"
  [style.left.px]="position?.left"
  [style.top.px]="position?.top"
  [style.height.px]="position?.height"
  [style.visibility]="position ? 'visible' : 'hidden'"
></div>
