// Common
import { Component, OnDestroy, Input, OnInit, SimpleChanges, OnChanges, NgZone, Output, EventEmitter, Self, Inject } from '@angular/core';

// RxJS
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, filter, debounceTime, map } from 'rxjs/operators';

// Services
import { ConnectionsService } from '@modules/connections/services/connections.service';

// Injection Tokens
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';

// Types
import { ConnectionsFilters } from '@modules/connections/types/connections-filters';
import { Contact } from '@modules/contacts/types/contact';
import { Like } from '@modules/common/types/like';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

@Component({
  selector: 'app-connections-list',
  templateUrl: './connections-list.component.html',
  styleUrls: ['./connections-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }]
})
export class ConnectionsListComponent extends InfinityScrollListComponent implements OnInit, OnDestroy, OnChanges {

  // Inputs
  @Input() placeholderText = 'No connections found';
  @Input() filters: Like<ConnectionsFilters> = {};

  // Outputs
  @Output() clickConnection = new EventEmitter<Contact>();
  @Output() openConnection = new EventEmitter<Contact>();
  @Output() doubleClickConnection = new EventEmitter<Contact>();

  // Public
  public itemHeight = 72;
  public isHover = false;
  public showCountView = new BehaviorSubject(true);
  public resetSelected = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private connectionsService: ConnectionsService,
    protected ngZone: NgZone,
    @Self() @Inject(ScrollToPosition) scrollToPositionSubject
  ) {
    super(ngZone, scrollToPositionSubject);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.showCountView
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('filters' in changes) {
      this.resetItems();
    }
  }

  /**
   * Actions
   */

  getItems(offset: number, limit: number): Observable<{ items: Object[], count: number }> {
    return this.connectionsService
      .getNeighbors({
        ...this.filters,
        limit,
        offset
      })
      .pipe(
        map(({ connections, count}) => {
          return {
            items: connections.map(connection => new Contact({ homeEmail: connection.name })),
            count
          };
        })
      );
  }

  resetItems() {
    this.resetSelected.next();
    super.resetItems();
  }

  handleDoubleClickItem(contact: Contact) {
    this.doubleClickConnection.emit(contact);
  }

  handleClickItem(contact: Contact) {
    this.clickConnection.emit(contact);
  }

  compareItems(item1: Object, item2: Object): boolean {
    return item1 && item2 && item1['id'] === item2['id'];
  }
}
