import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

@Component({
  selector: 'app-settings-notes-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.less']
})
export class GeneralComponent {
  // Public
  public form = new FormGroup({
    pageWidth: new FormControl<string>('regular'),
    lineHeight: new FormControl<string>('single'),
    blockSpacing: new FormControl<string>('dynamic'),
    showAuthor: new FormControl<boolean>(true),
    showDate: new FormControl<string[]>(['none'])
  });
  public pageWidthOptions: DropdownSelectItem<unknown>[] = [
    { title: 'Regular', value: 'regular' },
    { title: 'Wide', value: 'wide' },
  ];
  public lineHeightOptions: DropdownSelectItem<unknown>[] = [
    { title: 'Single', value: 'single' },
    { title: '1.5 lines', value: '1.5' },
    { title: 'Double', value: 'double' },
    { title: 'At least', value: 'least' },
    { title: 'Exactly', value: 'exactly' },
    { title: 'Multiple', value: 'multiple' },
  ];
  public blockSpacingOptions: DropdownSelectItem<unknown>[] = [
    { title: 'Dynamic', value: 'dynamic' },
    { title: 'Classic', value: 'classic' }
  ];
  public showAuthorOptions: DropdownSelectItem<unknown>[] = [
    { title: 'Enabled', value: true },
    { title: 'Disabled', value: false },
  ];
  public showDateOptions: DropdownSelectItem<unknown>[] = [
    { title: 'None', value: 'none' },
    { title: 'Creation date', value: 'created' },
    { title: 'Modified date', value: 'modified' },
  ];

  constructor() { }

}
