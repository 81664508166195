<div
  stchPopover
  stchPopoverPlacement="bottom"
  stchPopoverTrigger="click"
  [stchPopoverContent]="pickerTemplate"
  [stchPopoverShowUntil]="popoverClose"
  (stchPopoverVisibleChange)="handlePopoverOpen($event)"
>
  <ng-content></ng-content>
</div>

<ng-template #pickerTemplate>
  <div class="time-range-input">
    <div class="time-range-input-value">
      <app-time-input
        (click)="activeTime = 'from'"
        class="time-range-input-value-item"
        [class.active]="activeTime === 'from'"
        [formControl]="innerFromTime"
        placeholder="Start Time"
      ></app-time-input>
      <app-time-input
        (click)="activeTime = 'to'"
        [class.active]="activeTime === 'to'"
        class="time-range-input-value-item"
        [formControl]="innerToTime"
        placeholder="Due Time"
      ></app-time-input>
    </div>

    <div class="time-range-input-meridiem">
      <app-radio-button
        value="am"
        title="AM"
        [inputFormControl]="meridiem"
      ></app-radio-button>
      <app-radio-button
        value="pm"
        title="PM"
        [inputFormControl]="meridiem"
      ></app-radio-button>
    </div>

    <div class="time-range-input-options">
      <div
        class="time-range-input-options-item"
        *ngFor="let option of options"
        [class.active]="option | sameTime : (activeTime === 'from' ? innerFromTime.value : innerToTime.value)"
        (click)="setValue(option)"
      >
        {{ option | date : 'h:mm a' | lowercase }}
      </div>
    </div>

    <div class="time-range-input-buttons">
      <app-button appearance="square" (click)="handleCancel()" label="Cancel" />

      <app-button
        appearance="square-blue"
        label="Save"
        (click)="handleSave()"
      />
    </div>
  </div>
</ng-template>
