// Common
import { ComponentRef, Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { KnotContextMenuComponent } from '../components/knot-context-menu/knot-context-menu.component';

// Types
import { Knot } from '@modules/knots/types/knot';

@Directive({
  selector: '[appKnotContextMenu]'
})
export class KnotContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() appKnotContextMenu: Knot;
  @Input() appKnotContextMenuWithDetach = false;
  @Input() appKnotContextMenuDisabled = false;

  // Outputs
  @Output() appKnotContextMenuOpened = this.contextMenuOpened;
  @Output() appKnotContextMenuDelete = new EventEmitter<void>();
  @Output() appKnotContextMenuPinned = new EventEmitter<void>();

  /**
   * Livecycle
   */

  ngOnInit() {
    this.stchPopoverDisabled = this.appKnotContextMenuDisabled;
  }

  /**
   * Actions
   */

  registerComponent() {
    return KnotContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<KnotContextMenuComponent>) {
    componentRef.instance.knot = this.appKnotContextMenu;
    componentRef.instance.delete = this.appKnotContextMenuDelete;
    componentRef.instance.pinned = this.appKnotContextMenuPinned;
    componentRef.instance.withDetach = this.appKnotContextMenuWithDetach;
  }
}
