import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from '@modules/common/utils/base';

@Pipe({
  name: 'includesBy'
})
export class IncludesByPipe implements PipeTransform {

  transform(
    array: object[] | string[],
    value: object | string,
    key?: string
  ): boolean {
    if (!array || !array.length) { return false; }

    return array.some(
      (element: object | string) => !isNil(element) && ((key ? element[key] : element) === value)
    );
  }

}
