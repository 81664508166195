// Common
import { Component, EventEmitter, Injector, Output } from '@angular/core';

// Services
import { GroupsService } from '@modules/contacts/services/groups.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';

// Types
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.less'],
})
export class GroupComponent extends BaseStitchComponent<Group> {
  @Output() openContact = new EventEmitter<Contact>();

  moveDragDataTypes = [DragDataTypes.contact, DragDataTypes.group];
  dragDataType = DragDataTypes.group;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(
      dragData.type === DragDataTypes.group &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.item.id
    ) &&
    dragDataTypeAllowed(dragData.type)

  constructor(
    injector: Injector,
    protected groupsService: GroupsService,
    private contactsService: ContactsService,
  ) { super(injector, groupsService); }

  handleMove(dragData: DragData) {
    const message = 'Successfully moved to ' + this.item.title;

    if (dragData.type === DragDataTypes.contact) {
      this.contactsService.bunchUpdate(
        { ids: this.getIds(dragData.data) },
        { groupId: this.item.id },
        { message }
      );
    } else if (dragData.type === DragDataTypes.group) {
      this.groupsService.bunchUpdate(
        { ids: this.getIds(dragData.data) },
        { parentId: this.item.id },
        { message }
      );
    }
    this.popoverClose.next();
  }

  get group(): Group {
    return this.item;
  }
}
