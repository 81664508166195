<div class="contacts-list">
  <div *ngIf="showNoReplyMark && haveNoReplies" class="contacts-list-no-reply-mark"></div>
  <div
    *ngFor="let contact of visibleContacts; index as i"
    class="contacts-list-item"
    [class.selected]="contact.name === selectedContact?.name && contact.email === selectedContact?.email"
    [style.marginLeft.px]="i > 0 ? -size*0.485 : 0"
    (click)="selectContact(contact)"
  >
    <app-user-avatar
      [size]="size"
      [contact]="contact"
      [usePopover]="true"
      [appContactContextMenu]="contact"
    ></app-user-avatar>
  </div>

  <!-- Element with dropdown and counter -->
  <div
    class="contacts-load-more"
    *ngIf="contactList?.length > minContactLen"
    stchPopover
    [stchPopoverArrow]="true"
    [stchPopoverContent]="hiddenContacts"
    stchPopoverPlacement="right"
    stchPopoverTrigger="click"
    [stchPopoverShowUntil]="hidePopover"
  >
    +{{hiddenContactsState.length}}
  </div>
  <!-- end Element with dropdown and counter -->
</div>

<!-- Dropdown content -->
<ng-template #hiddenContacts>
  <div class="hidden-contacts-list">
    <div
      *ngFor="let contact of hiddenContactsState"
      (click)="selectCollapsedContact(contact)"
      class="hidden-contacts-list-item"
    >
      <div class="hidden-contacts-list-item-avatar">
        <app-user-avatar
          size="30"
          [contact]="contact"
          [usePopover]="false"
          [appContactContextMenu]="contact"
        ></app-user-avatar>
      </div>
      <div class="hidden-contacts-list-item-info">
        <div [hidden]="contact.name && contact.name.length < 1" class="contact_name">{{contact.name || 'no name'}}</div>
        <div class="contact_email">{{contact.email}}</div>
      </div>
    </div>
  </div>
</ng-template>
<!-- end Dropdown content -->
