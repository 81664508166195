<div class="form">
  <div class="form-date">{{ note?.createdAt | date : 'MMM d' }}</div>

  <h2 class="form-title">
    <mat-icon
      class="form-heading-title-icon"
      svgIcon="notebook"
      [style.color]="note?.color"
    />
    {{ note?.title }}
  </h2>

  <app-editor [data]="note?.body" />

  <div class="form-details">
    <div class="form-details-row">
      <div class="form-details-row-title">
        Add to Notebook
      </div>
      <div class="form-details-row-input">{{ note?.notebookId }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">
        Description
      </div>
      <div class="form-details-row-input">{{ note?.description }}</div>
    </div>
  </div>
</div>
