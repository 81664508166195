<div
  *ngIf="message"
  class="message"
  [class.selected]="selected && !isDragging"
  [class.pinned]="message.pinned"
  [class.snoozed]="message.snoozed"
  [class.followed]="message.followed"
  [class.hovered]="contextMenuOpened"
  [class.with-stitch-info]="message.hasStitched"

  [appMessageContextMenu]="message"
  (appMessageContextMenuOpened)="contextMenuOpened = $event"
  (appMessageContextMenuOpenMessage)="open.emit()"

  appDraggable
  [appDraggableDisabled]="!dragEnabled"
  [appDraggableData]="dragData"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"

  (appClick)="onClick.emit(message)"
  (appDblClick)="onDoubleClick.emit(message)"
>
  <div class="message-selection"></div>
  <div class="message-body">
    <div class="message-body-from">
      <div class="message-body-from-text">
        {{ message | messageSenderName }}
      </div>

      <div class="message-body-from-date">{{ message.date | relativeDate }}</div>
    </div>

    <div class="message-body-title">
      <div
        class="message-body-title-text"
        [class.blank]="!message.subject"
      >
        {{ message.subject || 'No Subject' }}
      </div>

      <mat-icon
        *ngIf="message.attachmentsCount > 0"
        class="message-body-title-attachments"
        svgIcon="mail-attachment"
      ></mat-icon>
    </div>

    <div
      *ngIf="!withTags && !withKnots"
      class="message-body-snippet"
    >
      <div
        class="message-body-snippet-text"
        [class.blank]="!message.snippet"
      >
        {{ message.snippet || 'No body' }}
      </div>

      <mat-icon
        *ngIf="message.replied"
        class="message-body-snippet-reply"
        svgIcon="reply-small"
      ></mat-icon>
    </div>

    <app-list-item-tags
      *ngIf="withTags"
      class="message-body-tags"
      [tags]="message.tags"
    ></app-list-item-tags>

    <app-list-item-knots
      *ngIf="withKnots"
      class="message-body-tags"
      [knots]="message.knots"
    ></app-list-item-knots>

    <app-link-info-bar [stitch]="message"></app-link-info-bar>
  </div>

  <mat-icon
    class="message-unread-icon"
    [class.active]="message.unread"
    svgIcon="mail-unread"
  ></mat-icon>

  <mat-icon
    class="message-pin-icon"
    [class.active]="message.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  ></mat-icon>

  <mat-icon
    class="message-flag-icon"
    [class.active]="message.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  ></mat-icon>

  <div
    class="message-hover-icons"
    *ngIf="dragEnabled && actionsButtonEnabled"
    (click)="$event.stopPropagation()"
  >
    <mat-icon
      class="message-hover-icons-item"
      svgIcon="snooze"
      [matTooltip]="message?.snoozed ? 'Unsnooze' : 'Snooze'"
      [class.active]="!!message?.snoozed"
      (click)="snooze()"
    ></mat-icon>
    <mat-icon
      class="message-hover-icons-item"
      svgIcon="follow-up"
      [matTooltip]="message?.followed ? 'Unfollow' : 'Follow up'"
      [class.active]="!!message?.followed"
      (click)="followUp()"
    ></mat-icon>
    <mat-icon
      class="message-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="message"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    ></mat-icon>
    <mat-icon
      class="message-hover-icons-item"
      svgIcon="archive"
      (click)="archive()"
      [matTooltip]="message?.archived ? 'Restore from Archive' : 'Archive'"
    ></mat-icon>
    <mat-icon
      class="message-hover-icons-item"
      svgIcon="trash"
      (click)="delete()"
      [matTooltip]="message?.deleted ? 'Restore from Trash' : 'Trash'"
    ></mat-icon>
  </div>

  <div class="drop-zone"></div>

  <div *ngIf="debug" class="message-debug">{{ message.score }}</div>
</div>

<div class="placeholder" *ngIf="!message">
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
  <div class="placeholder-11"></div>
</div>

<ng-template #dragPlaceholder>
  <app-message [item]="message" [dragEnabled]="false"></app-message>
</ng-template>
