<app-input
  class="input"
  label="Enter current password"
  appearance="amethyst"
  [password]="true"
  [inputFormControl]="passwordsForm.controls.oldPassword"
></app-input>

<app-input
  class="input"
  label="Enter new password"
  appearance="amethyst"
  [password]="true"
  [inputFormControl]="passwordsForm.controls.newPassword"
></app-input>

<app-input
  class="input"
  label="Confirm password"
  appearance="amethyst"
  [password]="true"
  [inputFormControl]="passwordsForm.controls.passwordConfirmation"
  [withErrors]="passwordsForm.controls.passwordConfirmation.touched && !passwordsForm.controls.passwordConfirmation.valid"
  [validationMessages]="{
        required: 'Confirm password',
        notEqual: 'Passwords do not match'
      }"
></app-input>

<div class="actions">
  <app-button
    appearance="purple-outline"
    label="CANCEL"
    (click)="handleCancel()"
  />

  <app-button
    appearance="purple"
    label="SAVE"
    (click)="handleSave()"
  />
</div>
