// Common
import { Component, OnDestroy, OnInit } from '@angular/core';

// Types
import { Account } from '@modules/account/types/account';
import { SubscriptionPlan } from '@modules/settings/types/subscription-plan';

// Services
import { AccountService } from '@modules/account/services/account.service';
import { SubscriptionPlansService } from '@modules/settings/services/subscription-plans.service';

// RX
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-settings-general-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.less']
})
export class PlansComponent implements OnInit, OnDestroy {
  public plans: SubscriptionPlan[];
  public currentPlan: SubscriptionPlan;
  public account: Account;
  public nextPlanTitle: string;

  private alive = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private plansService: SubscriptionPlansService,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([
      this.accountService.getAccount(true),
      this.plansService.search()
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([account, { items: plans }]) => {
        this.account = account;
        this.plans = plans;
        this.currentPlan = plans.find(plan => plan.id === account.subscriptionPlanId);
        this.nextPlanTitle = plans.find(plan => plan.id === this.account.nextSubscriptionPlanId)?.title;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
