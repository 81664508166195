// Common
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';

// RxJS
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Service
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StateKey } from '@modules/settings/types/state-key';
import { Tag } from '@modules/tags/types/tag';
import { Knot } from '@modules/knots/types/knot';
import { Connection } from '@modules/connections/types/connection';
import { Tab } from '@modules/common/types/tab';

@Component({
  selector: 'app-knowledge-panel',
  templateUrl: './knowledge-panel.component.html',
  styleUrls: ['./knowledge-panel.component.less'],
})
export class KnowledgePanelComponent implements AfterViewInit, OnDestroy {

  public resultsTypeStateKey = StateKey.kpResultsStitchType;
  public resultsTags: Tag[] = [];
  public resultsKnots: Knot[] = [];
  public resultsConnections: Connection[] = [];
  public currentTab: 'knots' | 'tags' | 'connections' = 'knots';
  public tabsValues: Tab[] = [
    { title: 'Knots', value: 'knots'},
    { title: 'Tags', value: 'tags'},
    { title: 'Connections', value: 'connections'},
  ];

  private alive = new Subject<void>();
  private getItemConnections = new Subject<Stitch>();

  constructor (
    private changeDetector: ChangeDetectorRef,
    private kpService: KnowledgePanelService
  ) {}

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    combineLatest([
      this.kpService.getKnotsHistory(),
      this.kpService.getTagsHistory(),
      this.kpService.getConnectionsHistory()
    ])
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(([knots, tags, connections]) => {
        this.resultsKnots = knots.flat();
        this.resultsTags = tags.flat();
        this.resultsConnections = connections.flat();
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClickItem(item: Stitch) {
    this.getItemConnections.next(item);
  }

  handleTabsChange(value: 'knots' | 'tags' | 'connections') {
    this.currentTab = value;
    this.changeDetector.detectChanges();
  }
}
