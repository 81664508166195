// Common
import { Component, Input, OnChanges, SimpleChanges, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, FormArray } from '@angular/forms';

// Utils
import { beginningOfDay, relativeDateToDate, endOfDay } from '@modules/common/utils/date';

// RX
import { Subject } from 'rxjs';
import { takeUntil, switchMap, startWith, filter, map } from 'rxjs/operators';

// Types
import { Reminder, ReminderFormGroup } from '@modules/form-controls/types/reminder';
import { RelativeDate } from '@modules/common/types/relative-date';

// Pipes
import { DisableQuickDatePickerPipe } from '@modules/form-controls/pipes/disable-quick-date-picker.pipe';

@Component({
  selector: 'app-date-time-popover',
  templateUrl: './date-time-popover.component.html',
  styleUrls: ['./date-time-popover.component.less']
})
export class DateTimePopoverComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() fromDate: UntypedFormControl;
  @Input() toDate: UntypedFormControl;
  @Input() fromTime: UntypedFormControl;
  @Input() toTime: UntypedFormControl;
  @Input() reminders: FormArray<ReminderFormGroup>;
  @Input() focusToDate: boolean;
  @Input() minDate: Date;
  @Input() highlightRange = true;

  @Output() onSave = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<void>();

  // Public
  public innerFromDate: UntypedFormControl = new UntypedFormControl();
  public innerToDate: UntypedFormControl = new UntypedFormControl();
  public innerFromTime: UntypedFormControl = new UntypedFormControl();
  public innerToTime: UntypedFormControl = new UntypedFormControl();
  public innerReminders = new FormArray<ReminderFormGroup>([]);
  public dateButtonSelected: 'from' | 'to' = 'from';
  public popoverClose = new Subject<void>();

  // Private
  private alive = new Subject<void>();
  private fromDateInputChanged = new Subject<void>();
  private fromTimeInputChanged = new Subject<void>();
  private toDateInputChanged = new Subject<void>();
  private toTimeInputChanged = new Subject<void>();
  private remindersInputChanged = new Subject<void>();
  private disableQuickDatePickerPipe = new DisableQuickDatePickerPipe();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.watchControl(this.fromDateInputChanged, () => this.fromDate, this.innerFromDate);
    this.watchControl(this.fromTimeInputChanged, () => this.fromTime, this.innerFromTime);
    this.watchControl(this.toDateInputChanged, () => this.toDate, this.innerToDate);
    this.watchControl(this.toTimeInputChanged, () => this.toTime, this.innerToTime);

    this.remindersInputChanged
      .pipe(
        startWith(() => null),
        map(() => (this.reminders?.controls || []).map(item => Reminder.fromFormGroup(item))),
        takeUntil(this.alive)
      )
      .subscribe((reminders) => {
        // this.innerReminders.reset(values) is not possible because of - https://github.com/angular/angular/issues/10960
        this.innerReminders.clear();
        reminders.forEach((reminder) => this.innerReminders.push(reminder.asFormGroup()));
      });

    this.watchInnerTimeControl(this.innerFromTime, this.innerFromDate, 'from');
    this.watchInnerTimeControl(this.innerToTime, this.innerToDate, 'to');
    this.watchInnerDateControl(this.innerFromDate, this.innerFromTime);
    this.watchInnerDateControl(this.innerToDate, this.innerToTime);

    this.innerFromDate.valueChanges
      .pipe(
        filter(() => this.dateButtonSelected === 'from'),
        filter(() => !!this.toDate),
        takeUntil(this.alive)
      )
      .subscribe(() => this.dateButtonSelected = 'to');

    if (this.focusToDate) {
      this.dateButtonSelected = 'to';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('fromDate' in changes) {
      this.fromDateInputChanged.next();
    }
    if ('fromTime' in changes) {
      this.fromTimeInputChanged.next();
    }
    if ('toDate' in changes) {
      this.toDateInputChanged.next();
    }
    if ('toTime' in changes) {
      this.toTimeInputChanged.next();
    }
    if ('reminders' in changes) {
      this.remindersInputChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClose() {
    this.popoverClose.next();
    this.onClose.emit();
  }

  setDate(range: RelativeDate): void {
    const date = relativeDateToDate(range);

    if (this.disableQuickDatePickerPipe.transform(this.innerToDate.value, this.dateButtonSelected, range)) { return; }

    if (this.dateButtonSelected === 'from') {
      this.innerFromDate.setValue(date);
    } else {
      this.innerToDate.setValue(date);
    }
  }

  handleSave() {
    if (this.fromDate) {
      this.fromDate.setValue(this.innerFromDate.value);
      this.fromDate.markAsDirty();
    }

    if (this.toDate) {
      this.toDate.setValue(this.innerToDate.value);
      this.toDate.markAsDirty();
    }

    if (this.fromTime) {
      this.fromTime.setValue(this.innerFromTime.value);
      this.fromTime.markAsDirty();
    }

    if (this.toTime) {
      this.toTime.setValue(this.innerToTime.value);
      this.toTime.markAsDirty();
    }

    if (this.reminders) {
      this.reminders.clear({ emitEvent: false });
      this.innerReminders.controls.forEach((control) => this.reminders.push(control, { emitEvent: false }));
    }
    this.handleClose();
    this.onSave.next();
  }

  cleanFromTime() {
    this.innerFromTime.setValue(null);
  }

  cleanToTime() {
    this.innerToTime.setValue(null);
  }

  deleteReminder(index: number) {
    this.innerReminders.removeAt(index);
  }

  addReminder(reminder: Reminder) {
    this.innerReminders.push(reminder.asFormGroup());
  }

  /**
   * Helpers
   */

  watchControl(
    watcher: Subject<void>,
    control: () => UntypedFormControl,
    innerControl: UntypedFormControl
  ) {
    watcher
      .pipe(
        startWith(() => null),
        filter(() => !!control()),
        switchMap(() => control().valueChanges
          .pipe(startWith(<Date> control().value))
        ),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        innerControl.setValue(value, { emitEvent: false });
      });
  }

  watchInnerTimeControl(innerTimeControl: UntypedFormControl, innerDateControl: UntypedFormControl, type: 'from' | 'to') {
    innerTimeControl.valueChanges
      .pipe(
        filter(time => time || innerDateControl.value),
        takeUntil(this.alive)
      )
      .subscribe(selectedTime => {
        const date = innerDateControl.value || new Date();
        const time = selectedTime ?? (type === 'from'
          ? beginningOfDay(date)
          : endOfDay(date));
        date.setHours(time.getHours(), time.getMinutes(), 0, 0);
        innerDateControl.setValue(date, { emitEvent: false });
      });
  }

  watchInnerDateControl(innerDateControl, innerTimeControl) {
    innerDateControl.valueChanges
      .pipe(
        filter(() => innerTimeControl.value),
        takeUntil(this.alive)
      )
      .subscribe((selectedDate) => {
        const date = selectedDate || new Date();
        date.setHours(innerTimeControl.value.getHours(), innerTimeControl.value.getMinutes(), 0, 0);
        innerDateControl.setValue(date, { emitEvent: false });
      });
  }

  handlePopoverOpen(opened: boolean) {
    if (!opened) {
      this.dateButtonSelected = this.focusToDate ? 'to' : 'from';
      this.fromDateInputChanged.next();
      this.fromTimeInputChanged.next();
      this.toDateInputChanged.next();
      this.toTimeInputChanged.next();
      this.onClose.emit();
    }
  }
}
