<div class="tags tags-form-popover">
  <div class="tags-title">
    Manage Tags
  </div>

  <app-context-menu-state-indicator
    class="tags-top-icon"
    svgIcon="filters"
    appTagsManageContextMenu
    [appTagsManageContextMenuStateKey]="stateKey"
    (appTagsManageContextMenuStateChange)="setState($event)"
  ></app-context-menu-state-indicator>

  <app-simple-input
    class="tags-input"
    [inputFormControl]="inputControl"
    placeholder=""
  ></app-simple-input>

  <div class="tags-add">
    <div
      *ngIf="canAdd && count === 0"
      class="tags-add-button"
      (click)="addTag()"
    >
      + Add Tag «{{ inputControl.value }}»
    </div>
  </div>

  <div
    *ngIf="!count"
    class="tags-placeholder"
  >
    <img
      class="tags-placeholder-image"
      src="assets/placeholders/placeholder-stitch-info.jpg"
    />
    <div class="tags-placeholder-text">
      You Have no Tags Added Yet
    </div>
  </div>

  <div class="tags-list">
    <div class="shadow" *ngIf="scrollShadowTop"></div>

    <cdk-virtual-scroll-viewport
      class="scroll-container"
      [itemSize]="24"
      [minBufferPx]="300"
      [maxBufferPx]="400"
    >
      <div
        *cdkVirtualFor="let tag of dataSource; let index = index"
        class="tags-list-item"
      >
        <div
          class="tags-list-item-letter"
          [class.blank]="!tag?.letter"
        >
          {{ tag?.letter | uppercase }}
        </div>

        <app-manage-tag
          class="tags-list-item-tag"
          [class.deleted]="tag?.deleted"
          [tag]="tag"
          (pin)="handlePin(index)"
          (delete)="handleDelete(index)"
          (change)="handleChange(index)"
        ></app-manage-tag>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <div *ngIf="count" class="shadow-bottom">
    <div class="shadow-bottom-inner" *ngIf="scrollShadowBottom"></div>
  </div>

  <app-pagination
    *ngIf="count"
    class="pagination"
    [pages]="count / itemsPerPage | number : '1.0-0'"
    [currentPage]="currentPage"
    (currentPageChange)="setCurrentPage($event)"
  ></app-pagination>

  <div
    *ngIf="count"
    class="tags-buttons"
  >
    <app-button appearance="square" size="medium" (click)="handleCancel()" label="Cancel" />
    <app-button appearance="square-blue" size="medium" (click)="handleSave()" label="Save" />
  </div>
</div>
