// Common
import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

// Utils
import { uuid } from '@modules/common/utils/base';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.less'],
})
export class ToggleSwitchComponent implements OnChanges {
  @Input() inputFormControl = new FormControl<boolean>(false);
  @Input() value: boolean;
  @Input() appearance: 'default' | 'sapphire-toggle' | 'sapphire-selector';
  @Input() disabled = false;
  @Input() trueLabel: string;
  @Input() falseLabel: string;
  @Input() width: number;

  public uuid = uuid();
  public buttonWidth = null;

  @Output() onChange = new EventEmitter<boolean>();

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('width' in changes) {
      this.buttonWidth = this.width ? (this.width - 6) / 2 : null;
    }
  }
}
