// Common
import { Component, Injector, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

// Types
import { Folder } from '@modules/files/types/folder';
import { File } from '@modules/files/types/file';
import { VirtualFolder } from '@modules/files/types/virtual-folder';
import { FilesListState } from '@modules/files/types/files-list-state';
import { FoldersListState } from '@modules/files/types/folders-list-state';
import { StateKey } from '@modules/settings/types/state-key';
import { FoldersFilters } from '@modules/files/types/folders-filters';
import { FilesFilters } from '@modules/files/types/files-filters';

// Services
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { FilesAppStateService } from '@modules/files/services/state.service';

// RX
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-files-panel',
  templateUrl: './files-panel.component.html',
  styleUrls: ['./files-panel.component.less'],
})
export class FilesPanelComponent implements OnInit, OnDestroy {

  public filesListState = new BehaviorSubject<FilesListState>(null);
  public foldersListState = new BehaviorSubject<FoldersListState>(null);
  public foldersListStateKey: StateKey = StateKey.foldersDPListState;
  public filesListStateKey: StateKey = StateKey.filesDPListState;
  public foldersFilters: FoldersFilters;
  public filesFilters: FilesFilters;
  public contextMenuOpened: boolean;
  public selectedFilter: VirtualFolder;

  private alive = new Subject<void>();

  @Input() searchTemplate: TemplateRef<any>;

  constructor (
    private dpService: DynamicPanelService,
    private modalService: ModalService,
    private injector: Injector,
    private searchService: AdvancedSearchService,
    private stateService: FilesAppStateService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([
      this.foldersListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.foldersFilters = new FoldersFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([
      this.filesListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.filesFilters = new FilesFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewFile(file = new File()) {
    this.dpService.setFormItem(file);
  }

  handleNewFolder(folder = new Folder()) {
    this.dpService.setFormItem(folder);
  }

  openFileInModal(file: File) {
    this.modalService.openFullForm(file, this.injector);
  }

  openFile(file: File) {
    this.dpService.setFormItem(file);
  }

  openFolder(folder: Folder) {
    this.dpService.setFormItem(folder);
  }

  handleFilesListStateChange(event: FilesListState) {
    this.filesListState.next(event);
  }

  handleFoldersListStateChange(event: FoldersListState) {
    this.foldersListState.next(event);
  }

  selectFilter(folder: VirtualFolder): void {
    this.selectedFilter = folder;
    this.stateService.setVirtualFolder(folder);
  }
}
