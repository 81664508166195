// Common
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

// Types
import { SpaceParticipant } from './space-participant';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }]});

export class Space {
  formBuilder = injector.get(UntypedFormBuilder);
  id?: string;
  title: string;
  description?: string;
  createdAt?: Date;
  participants: SpaceParticipant[] = [];

  constructor(data: any = {}) {
    this.createdAt = data.createdAt && new Date(data.createdAt);
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;

    if (data instanceof Space) {
      this.participants = data.participants;
    } else {
      this.participants = (data.participants || []).map(i => new SpaceParticipant(i))
    }
  }

  static fromFormGroup(form: UntypedFormGroup): Space {
    const formValues = form.value;

    return new Space({
      id: formValues.id,
      title: formValues.title,
      description: formValues.description
    });
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      id: [this.id],
      title: [this.title, Validators.required],
      description: [this.description]
    });
  }

  asPayloadJSON() {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
    };
  }
}
