// Rx
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// Types
import { BaseSearchResponse } from '../types/base-search-response';
import { FeedbackConfig } from '../types/base-service-types';
import { Filters } from '../types/filters';

// Services
import { BaseService } from './base.service';

export abstract class BaseRestService<T, TFilters extends Filters> extends BaseService {

  abstract search(filters: Partial<Filters>, config?: object): Observable<BaseSearchResponse<T>>;

  abstract create(instance: T, feedbackConfig?: FeedbackConfig, ...rest: unknown[]): Observable<T>;

  abstract update(instance: T, feedbackConfig?: FeedbackConfig): Observable<T>;

  findAll(filters?: Partial<TFilters>, page = 0, cachedItems: T[] = []): Observable<BaseSearchResponse<T>> {
    const limit = filters?.limit || 100;

    return this.search({ ...filters, limit, offset: page * limit })
      .pipe(
        switchMap(({ count, items }) => {
          const allItems = [...cachedItems, ...items];

          return page * limit + limit < count
            ? this.findAll(filters, page + 1, allItems)
            : of({ count, items: allItems });
        })
      );
  }

  upsert(instance: T, config?: FeedbackConfig): Observable<T> {
    return instance['id'] && instance['id'] !== 'temp' ? this.update(instance, config) : this.create(instance, config);
  }
}
