<div
  class="temp"
  [class.status-available]="expression.availability === 'available'"
  [class.status-partially]="expression.availability === 'partially'"
  [class.status-busy]="expression.availability === 'busy'"
  [class.selected]="selected"
  [matTooltip]="'Type: ' + expression?.type + '; Text: ' + expression?.text"
  matTooltipPosition="above"
  [appTemporalExpressionContextMenu]="expression"
>
  <mat-icon
    class="temp-calendar-icon"
    svgIcon="event"
  ></mat-icon>

  <div
    class="temp-debug"
  >
    <div *ngIf="debug" class="temp-debug-text">{{ expression?.source }}</div>
    <span
      *ngIf="expression?.source === 'cesar'"
      [ngSwitch]="expression?.type"
      class="temp-label"
    >
      <ng-container *ngSwitchCase="'datetimerange'">
        <ng-container *ngIf="expression.fromTime | sameDay : expression.toTime">
          {{ expression.fromTime | date : 'shortDate' }}, {{ expression.fromTime | date : 'shortTime'  }} - {{ expression.toTime | date : 'shortTime' }}
        </ng-container>
        <ng-container *ngIf="!(expression.fromTime | sameDay : expression.toTime)">
          {{ expression.fromTime | date : 'shortDate' }} - {{ expression.toTime | date : 'shortDate' }}
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'datetime'">
        {{ expression.fromTime | date : 'short' }}
      </ng-container>

      <ng-container *ngSwitchCase="'time'">
        {{ expression.fromTime | date : 'shortTime' }}
      </ng-container>

      <ng-container *ngSwitchCase="'timerange'">
        {{ expression.fromTime | date : 'shortTime' }} - {{ expression.toTime | date : 'shortTime' }}
      </ng-container>

      <ng-container *ngSwitchCase="'date'">
        {{ expression.fromTime | date : 'shortDate' }}
      </ng-container>

      <ng-container *ngSwitchCase="'daterange'">
        {{ expression.fromTime | date : 'shortDate' }} - {{ expression.toTime | date : 'shortDate' }}
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container *ngIf="expression.toTime">
          {{ expression.fromTime | date : 'short' }} - {{ expression.toTime | date : 'short' }}
        </ng-container>
        <ng-container *ngIf="!expression.toTime">
          {{ expression.fromTime | date : 'short' }}
        </ng-container>
      </ng-container>    
    </span>

    <span
      *ngIf="expression?.source === 'deepframe'"
      class="temp-label"
    >
      <ng-container *ngIf="expression.toTime">
        {{ expression.fromTime | date : 'short' }} - {{ expression.toTime | date : 'short' }}
      </ng-container>
      <ng-container *ngIf="!expression.toTime">
        {{ expression.fromTime | date : 'short' }}
      </ng-container>
    </span>
  </div>
</div>
