// Common
import { Injectable } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

// Components
import { AlertComponent } from '../components/alert/alert.component';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

// Types
import { AlertConfig } from '../types/config';

@Injectable()
export class AlertService {

  constructor (
    private overlay: Overlay
  ) { }

  show(
    config: AlertConfig,
  ): void {
    const overlayRef = this.overlay.create();
    const popoverRef = overlayRef.attach(new ComponentPortal(AlertComponent));

    const close = new Subject();

    config.showUntil
      ?.pipe(takeUntil(close))
      ?.subscribe(() => close.next());

    popoverRef.instance.close
      .pipe(takeUntil(close))
      .subscribe(() => close.next());

    close
      .pipe(take(1))
      .subscribe(() => {
        popoverRef.instance.hide();
        popoverRef.destroy();
        overlayRef.dispose();
        close.complete();
      });

    popoverRef.instance.parentNestedSelectors = config.origin?.closest('.popover-nested-state-container')?.['dataset']?.['nestedSelectors']
    popoverRef.instance.config = config;
  }
}
