// Common
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

// RxJS
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { StitchService } from '@modules/common/services/stitch.service';

// Types
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { LinkedInfoData } from '@modules/linked-info/types/linked-info-data';
import { ListState as StitchListState } from '@modules/linked-info/types/list-state';
import { dndStitchPredicate, DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { MessageFolder } from '@modules/messages/types/message-folder';
import { Message } from '@modules/messages/types/message';
import { Calendar } from '@modules/calendar-app/types/calendar';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Notebook } from '@modules/notes/types/notebook';
import { Note } from '@modules/notes/types/note';
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';
import { Folder } from '@modules/files/types/folder';
import { File } from '@modules/files/types/file';

@Component({
  selector: 'app-linked-info-list',
  templateUrl: './linked-info-list.component.html',
  styleUrls: ['./linked-info-list.component.less']
})
export class LinkedInfoListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() stitch: Stitch;
  @Input() allowedStitchTypes: StitchType[];
  @Input() linkedInfo: LinkedInfo[] = [];
  @Input() placeholder;
  @Input() withPlaceholderImage = true;
  @Input() state: StitchListState;
  @Input() withContextMenu = true;
  @Input() withDraggable = true;
  @Input() withDroppable = false;
  @Input() withActions = true;
  @Input() removeOnly = false;
  @Input() withUnlink = true;
  @Input() withUnlinkAll = true;
  @Input() unlinkAllLabel = 'Unlink All';
  @Input() appearance: 'list' | 'inline' = 'list';

  @Output() removeItem = new EventEmitter<LinkedInfo>();
  @Output() unlinkAll = new EventEmitter();
  @Output() clickStitchItem = new EventEmitter<Stitch>();
  @Output() dblClickStitchItem = new EventEmitter<Stitch>();
  @Output() linkedInfoChange = new EventEmitter<LinkedInfoData[]>();

  public linkedInfoData: LinkedInfoData[] = [];

  private alive = new Subject();
  private linkedInfoSubject = new BehaviorSubject<LinkedInfo[]>(null);
  private stateSubject = new BehaviorSubject<StitchListState>(null);
  private stitchChanged = new Subject<void>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    dndStitchPredicate(dragData.type) &&
    dragData.data.some(data =>
      !this.linkedInfoData.some(info => info.type.toString() === dragData.type.toString() && info.data.id === data.id)
    )

  constructor (
    private linkedInfoService: LinkedInfoService,
    private stitchService: StitchService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    merge(
      this.stitchChanged,
      this.linkedInfoService.getRefreshRequired()
    )
      .pipe(
        filter(() => !!this.stitch?.id),
        switchMap(() => this.linkedInfoService.getLinkedInfo(this.stitch.getStitchType(), this.stitch.id)),
        takeUntil(this.alive)
      )
      .subscribe(linkedInfo => {
        this.linkedInfoSubject.next(linkedInfo);
      });

    this.linkedInfoSubject
      .pipe(
        switchMap(info => this.linkedInfoService.fill(info)),
        switchMap((data: LinkedInfoData[]) => (
          this.stateSubject
            .pipe(
              map(state => this.applyFilters(data, state))
            )
        )),
        takeUntil(this.alive)
      )
      .subscribe(data => {
        this.linkedInfoData = data;
      });

    this.stitchChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('stitch' in changes && this.stitch) {
      this.stitchChanged.next();
    }

    if ('linkedInfo' in changes) {
      this.linkedInfoSubject.next(this.linkedInfo);
    }

    if ('state' in changes) {
      this.stateSubject.next(this.state);
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleRemoveItem(info: LinkedInfo) {
    if (info) {
      this.removeItem.emit(info);
    }
  }

  handleUnlinkItem(info: LinkedInfo) {
    if (!info) { return; }

    if (!this.stitch?.id) {
      this.linkedInfoData = this.linkedInfoData.filter((item) => item.id !== info.id);
      this.linkedInfoChange.emit(this.linkedInfoData);
      return;
    }

    this.linkedInfoService.unlinkItems([
      info,
      { type: this.stitch.getStitchType(), id: this.stitch.id }
    ]);
  }

  public handleUnlinkAll() {
    if (this.unlinkAll.observers.length > 0) {
      this.unlinkAll.emit();
      return;
    }

    if (!this.stitch?.id) {
      this.linkedInfoData = [];
      this.linkedInfoChange.emit([]);
      return;
    }

    this.linkedInfoService.unlinkItems([
      ...this.linkedInfoData,
      { type: this.stitch.getStitchType(), id: this.stitch.id }
    ]);
  }

  public handleDrop(dragData: DragData) {
    if (this.stitch?.id) {
      this.stitchService.linkDragData(this.stitch, dragData);
      return;
    }

    if (
      dragDataTypeAllowed(dragData.type, [DragDataTypes.knot, DragDataTypes.tag, DragDataTypes.connection]) &&
      dragData.data
    ) {
      const items = dragData.data as Stitch[];
      this.linkedInfoData = this.linkedInfoData.concat(items.map(item => new LinkedInfoData(item)));
      this.linkedInfoChange.emit(this.linkedInfoData);
    }
  }

  /**
   * Helpers
   */

  applyFilters(data: LinkedInfoData[], state: StitchListState): LinkedInfoData[] {
    const filters = state && state.filters || { stitch: {} };
    const sort = state && state.sort || { by: 'title', order: 'asc' };
    const stitch = filters && filters.stitch || {};

    if (this.allowedStitchTypes) {
      this.allowedStitchTypes.forEach(type => stitch[type] = true);
    }

    const allDeselected = !stitch[StitchType.messageFolder] &&
      !stitch[StitchType.message] &&
      !stitch[StitchType.event] &&
      !stitch[StitchType.calendar] &&
      !stitch[StitchType.project] &&
      !stitch[StitchType.task] &&
      !stitch[StitchType.notebook] &&
      !stitch[StitchType.note] &&
      !stitch[StitchType.group] &&
      !stitch[StitchType.contact] &&
      !stitch[StitchType.folder] &&
      !stitch[StitchType.file];

    return data
      .filter(item => filters.flagged ? item.data.flagged : true)
      .filter(item => allDeselected || stitch[item.type])
      .sort((a, b) => (
        (sort.order === 'asc' ? 1 : -1) *
        (this.getSortMapping(a)[sort.by] > this.getSortMapping(b)[sort.by] ? 1 : -1)
      ));
  }

  getSortMapping(data: LinkedInfoData): { title: string, date: Date } {
    const item = data.data;

    if (!item) {
      return { title: null, date: null };
    }

    if (item instanceof Message) {
      return { title: item.subject?.toLowerCase(), date: item.date };
    } else if (item instanceof MessageFolder) {
      return { title: item.title.toLowerCase(), date: item.createdAt };
    } else if (item instanceof Message) {
      return { title: item.subject.toLowerCase(), date: item.date };
    } else if (item instanceof Calendar) {
      return { title: item.title.toLowerCase(), date: item.createdAt };
    } else if (item instanceof CalendarEvent) {
      return { title: item.title.toLowerCase(), date: item.startTime };
    } else if (item instanceof Project) {
      return { title: item.title.toLowerCase(), date: item.fromDate };
    } else if (item instanceof Task) {
      return { title: item.title.toLowerCase(), date: item.fromDate };
    } else if (item instanceof Notebook) {
      return { title: item.title.toLowerCase(), date: item.createdAt };
    } else if (item instanceof Note) {
      return { title: item.title.toLowerCase(), date: item.createdAt };
    } else if (item instanceof Group) {
      return { title: item.title.toLowerCase(), date: item.createdAt };
    } else if (item instanceof Contact) {
      return { title: item.title.toLowerCase(), date: item.createdAt };
    } else if (item instanceof Folder) {
      return { title: item.title.toLowerCase(), date: item.createdAt };
    } else if (item instanceof File) {
      return { title: item.title.toLowerCase(), date: item.createdAt };
    }
  }
}
