import { environment } from "@environment";

export class User {
  admin?: boolean
  avatar?: string
  birthDate?: Date
  createdAt?: Date
  id?: string
  userName?: string
  recoveryEmail?: string
  firstName: string
  from: string
  industry: string
  newsletter: string
  lastName: string
  subscriptionPlan: {
    id: string,
    title: string,
    suspended: boolean,
    monthlyPrice: number,
    annuallyPrice: number,
  }
  stripeCustomerId: string
  chargeType: string

  constructor(data: any = {}) {
    this.admin = data.admin;
    this.avatar = data.avatar ? `${environment.baseUrl}/api/attachments/avatar/avatar/${data.avatar}` : null;
    this.birthDate = data.birthDate ? new Date(data.birthDate) : null;
    this.createdAt = data.createdAt ? new Date(data.createdAt) : null;
    this.id = data.id;
    this.userName = data.userName;
    this.recoveryEmail = data.recoveryEmail;
    this.firstName = data.firstName;
    this.from = data.from;
    this.industry = data.industry;
    this.newsletter = data.newsletter;
    this.lastName = data.lastName;
    this.subscriptionPlan = {
      id: data.subscriptionPlan?.id,
      title: data.subscriptionPlan?.title,
      suspended: data.subscriptionPlan?.suspended,
      monthlyPrice: data.subscriptionPlan?.monthlyPrice,
      annuallyPrice: data.subscriptionPlan?.annuallyPrice,
    };
    this.stripeCustomerId = data.stripeCustomerId;
    this.chargeType = data.chargeType;
  }
}
