<div
  class="radio-button"
  [class.active]="checked"
  [class.disabled]="disabled"
  [style.width.px]="width"
  [ngClass]="[appearance, size]"
  (click)="handleClick()"
>
  <mat-icon
    *ngIf="!icon && appearance !== 'button'"
    class="icon"
    [class.disabled]="disabled"
    [ngClass]="size"
    [svgIcon]="checked ? iconOn : iconOff"
  />

  <mat-icon
    *ngIf="icon"
    class="custom-icon"
    [svgIcon]="icon"
  />

  {{ title }}

  <app-input-errors
    *ngIf="withErrors"
    [inputFormControl]="inputFormControl"
    [validationMessages]="validationMessages"
  />
</div>
