// Common
import { Component, Injector } from '@angular/core';
import { DateByDayIndexPipe } from '@modules/utils/pipes/date-by-day-index.pipe';
import { DatePipe } from '@angular/common';

// Types
import { VirtualFolder } from '@modules/tasks/types/virtual-folder';
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { priorities } from '@modules/form-controls/types/priorities';
import { next7DaysArray } from '@modules/common/types/next-7-days';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { Application } from '@modules/common/types/application';
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { SidebarItem } from '@modules/common/types/sidebar-item';

// Services
import { TaskingAppStateService } from '@modules/tasks/services/state.service';
import { TasksService } from '@modules/tasks/services/tasks.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';

// Components
import { BaseSidebarComponent } from '@modules/common/components/base-sidebar/base-sidebar.component';

@Component({
  selector: 'app-tasks-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.less'],
})

export class TasksSideBarComponent extends BaseSidebarComponent<Project, Task, VirtualFolder> {

  private dayByDateIndexPipe = new DateByDayIndexPipe();
  private datePipe = new DatePipe('en-us');

  protected application = Application.tasking;
  protected sidebarSplitViewKey = SidebarSplitViewKey.tasking;
  protected selfDragDataTypes = [DragDataTypes.task, DragDataTypes.project];

  public items: SidebarItem[] = [
    { title: 'Tasks', id: 'all_tasks', icon: 'sapphire-tasks' },
    { title: 'Scheduled', id: 'scheduled', parentId: 'all_tasks' },
    { title: 'Unscheduled', id: 'unscheduled', parentId: 'all_tasks' },
    { title: 'Overdue', id: 'overdue', icon: 'sapphire-overdue' },
    { title: 'Today', id: 'today', icon: 'sapphire-today' },
    { title: 'Next 7 days', id: 'week', icon: 'sapphire-week' },
    { title: 'Priority', id: 'priority', icon: 'sapphire-priority' },
    { title: 'Flagged', id: 'flagged', icon: 'sapphire-flagged' },
    { title: 'Follow Up', id: 'followed', icon: 'sapphire-follow-up' },
    { title: 'Snoozed', id: 'snoozed', icon: 'sapphire-snooze' },
    { title: 'Archived', id: 'archived', icon: 'sapphire-archive' },
    { title: 'Trash', id: 'deleted', icon: 'sapphire-trash' },
    ...next7DaysArray.map((day, index) =>
      ({
        title: index === 0
          ? 'Tomorrow'
          : this.datePipe.transform(this.dayByDateIndexPipe.transform(day), 'EEEE'),
        id: day,
        parentId: 'week'
      })
    ),
    ...priorities.map((priority, index): SidebarItem =>
      ({ title: priority.title, id: priority.name, parentId: 'priority', icon: 'sapphire-priority', color: priority.color })
    ),
  ];

  constructor(
    injector: Injector,
    stateService: TaskingAppStateService,
    private tasksService: TasksService,
    private projectsService: ProjectsService,
  ) {
    super(injector, stateService);
  }

  /**
   * Actions
   */

  handleMove(dragData: DragData, sidebarFolder: VirtualFolder) {
    if (dragData.type === DragDataTypes.task) {
      const { changes, message } = Task.getChangesForVirtualFolder(sidebarFolder);
      this.tasksService.bunchUpdate({ ids: this.getIds(dragData.data) }, changes, { message });
    } else if (dragData.type === DragDataTypes.project) {
      const { changes, message } = Project.getChangesForVirtualFolder(sidebarFolder);
      this.projectsService.bunchUpdate({ ids: this.getIds(dragData.data) }, changes, { message });
    }
  }

  handleMore(item: Task) {
    this.stateService.setMainView(item);
  }

  getStitchFromDragData(dragData: DragData, sidebarFolder: VirtualFolder) {
    const { changes } = Task.getChangesForVirtualFolder(sidebarFolder);

    return new Task({ ...changes, title: dragData.data[0]['title'] });
  }

}
