<stch-calendar-week
  [viewDate]="viewDate"
  [events]="events"
  [excludeDays]="excludeDays"
  (dateClicked)="dateClicked.emit($event)"
  (dateDblClicked)="dateDblClicked.emit($event)"
  (eventDropped)="eventDropped.emit($event)"
  (dayNumberClicked)="dayNumberClicked.emit($event)"
  (loadDayEvents)="loadDayEvents.emit($event)"
/>
