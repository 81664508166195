<div
  *ngIf="notebook"
  class="notebook"
  [class.no-actions]="!withActions"
  [class.short-view]="viewType === 'short'"
  [class.pinned]="notebook.pinned"
  [class.with-description]="notebook.description"
  [appNotebookContextMenu]="notebook"
  [appNotebookContextMenuDisabled]="!withContextMenu"
  (appNotebookContextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableDisabled]="!withDraggable"
  [appDraggableData]="{data: [notebook], type: 'notebook'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableDisabled]="!withDroppable"
  [appDroppableZIndex]="2"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="notebook-body">
    <div class="notebook-body-heading">
      <div class="notebook-body-heading-title">
        {{notebook.title}}
      </div>
    </div>

    <app-date-time
      class="notebook-body-date-time"
      [fromDate]="notebook.createdAt"
      [fromTime]="notebook.createdAt"
      [toDate]="notebook.updatedAt"
      [toTime]="notebook.updatedAt"
    ></app-date-time>

    <div class="notebook-body-description" *ngIf="notebook.description && viewType !== 'short'">
      {{notebook.snippet}}
    </div>

    <app-link-info-bar
      class="notebook-body-info-bar"
      *ngIf="viewType !== 'short'"
      [stitch]="notebook"
    ></app-link-info-bar>
  </div>

  <mat-icon class="notebook-pin-icon" *ngIf="notebook.pinned" svgIcon="pin-direct"></mat-icon>
  <mat-icon class="notebook-starred-icon" *ngIf="!notebook.starred" svgIcon="starred"></mat-icon>
  <mat-icon class="notebook-marker" svgIcon="notebook"></mat-icon>
  <mat-icon class="notebook-attachments-icon" *ngIf="notebook.files?.length" svgIcon="attachment"></mat-icon>

  <div
    class="notebook-hover-icons"
    *ngIf="withDraggable && withActions && !removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="notebook-hover-icons-item"
      svgIcon="flag"
      [class.active]="notebook.flagged"
      matTooltip="Flag"
      (click)="flag()"
    ></mat-icon>
    <mat-icon
      class="notebook-hover-icons-item"
      svgIcon="pin"
      [class.active]="notebook.pinned"
      [matTooltip]="notebook?.pinned ? 'Unpin' : 'Pin'"
      (click)="pin()"
    ></mat-icon>
    <mat-icon
      class="notebook-hover-icons-item"
      svgIcon="snooze"
      matTooltip="Coming soon"  
    ></mat-icon>
    <mat-icon
      class="notebook-hover-icons-item"
      svgIcon="event"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="notebook-hover-icons-item"
      svgIcon="archive"
      [class.active]="notebook.archived"
      [matTooltip]="notebook?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    ></mat-icon>
    <mat-icon
      class="notebook-hover-icons-item"
      svgIcon="trash"
      [class.active]="notebook.deleted"
      [matTooltip]="notebook?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    ></mat-icon>
  </div>

  <div
    class="notebook-hover-icons"
    *ngIf="withActions && removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="notebook-hover-icons-item"
      svgIcon="trash"
      [class.active]="notebook.deleted"
      [matTooltip]="notebook?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    ></mat-icon>
  </div>

  <div class="notebook-bottom-container">
    <mat-icon
      *ngIf="withUnlink"
      class="notebook-bottom-container-unlink"
      svgIcon="unlink"
      matTooltip="Unlink"
      (click)="unlinkItem($event)"
    ></mat-icon>
  </div>

  <div class="drop-zone"></div>
</div>

<div
  class="notebook placeholder"
  *ngIf="!notebook"
  [class.short-view]="viewType === 'short'"
>
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
</div>

<ng-template #dragPlaceholder>
  <app-link-info-notebook
    [notebook]="notebook"
    [withDraggable]="false"
    [withContextMenu]="false"
    [withActions]="false"
    [viewType]="viewType"
  ></app-link-info-notebook>
</ng-template>
