// Common
import { Component, OnDestroy, OnInit } from '@angular/core';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

// Services
import { MailAppSettingsService } from '@modules/messages/services/settings.service';
import { Settings } from '@modules/messages/types/settings';

// RX
import { Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-settings-messages-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.less']
})
export class GeneralComponent implements OnInit, OnDestroy {

  public form = new Settings().asFormGroup();

  public undoSendOptions: DropdownSelectItem<number>[] = [
    { title: '5 seconds', value: 5 },
    { title: '10 seconds', value: 10 },
    { title: '20 seconds', value: 20 },
    { title: '30 seconds', value: 30 }
  ];

  private alive = new Subject<void>();

  constructor(
    private settingsService: MailAppSettingsService,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.settingsService.listAll(true)
      .pipe(
        tap(settings => {
          this.form = settings.asFormGroup()
        }),
        switchMap(() => this.form.valueChanges),
        debounceTime(500),
        switchMap(() => this.settingsService.update(Settings.fromFormGroup(this.form))),
        takeUntil(this.alive)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
