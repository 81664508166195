<app-split-view
  #splitView
  side="right"
  key="dp"
  [collapsedSize]="15"
  [magneticSize]="200"
  [iconTop]="46"
  [withIcon]="true"
>
  <div split-left-pane class="main" *spaceContext="'main'">
    <app-nav-bar [withSearch]="true"></app-nav-bar>

    <div class="under-nav-bar">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div split-right-pane class="knowledge-panel" *spaceContext="'dp'">
    <app-dynamic-panel></app-dynamic-panel>
  </div>
</app-split-view>
