// Common
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';

// Types
import { Folder } from '@modules/files/types/folder';
import { File } from '@modules/files/types/file';
import { ListState } from '@modules/files/types/list-state';
import { Tab } from '@modules/common/types/tab';
import { FoldersListState } from '@modules/files/types/folders-list-state';
import { FilesListState } from '@modules/files/types/files-list-state';
import { StateKey } from '@modules/settings/types/state-key';
import { VirtualFolder } from '@modules/files/types/virtual-folder';
import { FilesFilters } from '@modules/files/types/files-filters';
import { FoldersFilters } from '@modules/files/types/folders-filters';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { FilesAppStateService } from '@modules/files/services/state.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-files-list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.less'],
})
export class FilesListContainerComponent implements OnInit, OnDestroy {

  public minimized: boolean;
  public searchQuery: string;
  public listState: ListState;
  public sidebarFilter: VirtualFolder;
  public quickFolder: Folder;
  public quickFile: File;
  public loading: boolean;
  public tabs: Tab[] = [
    { title: 'Folders', value: 'folders'},
    { title: 'Files', value: 'files'}
  ];
  public selectedTab: Exclude<ListState, 'tabs'> = 'files';
  public filesListState = new BehaviorSubject<FilesListState>(null);
  public foldersListState = new BehaviorSubject<FoldersListState>(null);
  public foldersListStateKey: StateKey = StateKey.foldersListState;
  public filesListStateKey: StateKey = StateKey.filesListState;
  public foldersFilters: FoldersFilters;
  public filesFilters: FilesFilters;
  public debug: 'score' = null;

  private alive: Subject<void> = new Subject();

  constructor (
    private splitViewService: SplitViewService,
    private stateService: FilesAppStateService,
    private modalService: ModalService,
    private searchService: AdvancedSearchService,
    private injector: Injector,
  ) {
    combineLatest([
      this.foldersListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.foldersFilters = new FoldersFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([
      this.filesListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.filesFilters = new FilesFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.splitViewService.getMinimized('filesList')
      .pipe(takeUntil(this.alive))
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });

    this.stateService.getTabs()
      .pipe(takeUntil(this.alive))
      .subscribe((state: ListState) => {
        this.listState = state;
        if (this.listState !== 'tabs') {
          this.selectedTab = this.listState;
        }
      });

    combineLatest([
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([state, folder]) => {
        this.sidebarFilter = folder;
        this.quickFile = File.fromAdvancedState(state, folder);
        this.quickFolder = Folder.fromAdvancedState(state, folder);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewFile(file = new File()) {
    this.stateService.setMainView(file);
  }

  handleNewFolder(folder = new Folder()) {
    this.stateService.setMainView(folder);
  }

  handleDoubleClickFile(file: File) {
    this.modalService.openFullForm(file, this.injector);
  }

  handleDoubleClickFolder(folder: Folder) {
    this.modalService.openFullForm(folder, this.injector);
  }

  handleClickFile(file: File) {
    this.stateService.setMainView(file);
  }

  handleClickFolder(folder: Folder) {
    this.stateService.setMainView(folder);
  }

  handleFilesListStateChange(event: FilesListState) {
    this.filesListState.next(event);
  }

  handleFoldersListStateChange(event: FoldersListState) {
    this.foldersListState.next(event);
  }

  doDebug() {
    switch (this.debug) {
      case 'score':
        this.debug = null;
        break;
      default:
        this.debug = 'score';
    }
  }
}
