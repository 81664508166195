// Common
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.less']
})
export class DateTimeComponent {

  // Inputs
  @Input() public fromDate: Date;
  @Input() public fromTime: Date;
  @Input() public toDate: Date;
  @Input() public toTime: Date;
  @Input() public dateFormat = 'MMM dd';
  @Input() public timeFormat = 'h:mm aa';
  @Input() public twoRows = false;

  // Constructor
  constructor () {

  }
}
