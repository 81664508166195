// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// Types
import { Group } from '@modules/contacts/types/group';

// RX
import { takeUntil } from 'rxjs/operators';

// Services
import { GroupsService } from '@modules/contacts/services/groups.service';

// Components
import { BaseQuickFormComponent } from '../base-quick-form/base-quick-form.component';

@Component({
  selector: 'app-group-quick-form',
  templateUrl: './group-quick-form.component.html',
  styleUrls: ['./group-quick-form.component.less'],
})
export class GroupQuickFormComponent extends BaseQuickFormComponent implements OnChanges {

  // Inputs
  @Input() group: Group = new Group();

  // Outputs
  @Output() more: EventEmitter<Group> = new EventEmitter<Group>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<Group> = new EventEmitter();

  constructor (
    private groupsService: GroupsService,
    public changeDetector: ChangeDetectorRef
  ) {
    super(changeDetector);
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('group' in changes) {
      this.reset();
    }
  }

  /**
   * Actions
   */

  submit() {
    if (!this.form.valid) {
      return;
    }

    const group = Group.fromFormGroup(this.form);

    if (this.save.observers.length > 0) {
      this.save.emit(group);
      return;
    }

    this.saveInProgress = true;

    this.groupsService.create(group)
      .pipe(takeUntil(this.alive))
      .subscribe(
        groupInstance => {
          this.afterSave.emit(groupInstance);
          this.close.emit();
        },
        () => this.handleError()
      );
  }

  reset() {
    if (!this.group) { return; }
    this.form = this.group.asFormGroup();
    super.reset();
  }

  clickMore() {
    this.more.emit(Group.fromFormGroup(this.form));
    super.clickMore();
  }
}
