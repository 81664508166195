import { environment } from "@environment";
import { Account } from "@modules/account/types/account";

export class SpaceParticipant {
  avatar: string;
  firstName: string;
  id: string;
  joinedAt: Date;
  lastName: string;
  role: 'owner' | 'manager' | 'regular';
  status: 'pending' | 'accepted' | 'rejected';
  userName: string;
  fullName: string;

  constructor(data: any = {}) {
    this.avatar = data instanceof Account
      ? data.avatar
      : (data?.avatar && `${environment.baseUrl}/api/attachments/avatar/avatar/${data?.avatar}?t=${new Date().getTime()}&account-id=${data?.id}`);
    this.firstName = data?.firstName;
    this.id = data?.id;
    this.joinedAt = data.joinedAt && new Date(data.joinedAt);
    this.lastName = data?.lastName;
    this.role = data?.role;
    this.status = data?.status;
    this.userName = data?.userName;
    this.fullName = [this.firstName, this.lastName].join(' ');
  }

  asPayloadJSON() {
    return {
      role: this.role
    };
  }
}
