<div class="block">
  <div class="block-grow">
    <div class="block-grow-item">
      <div class="heading2">POP Download</div>
      <div class="body-primary pop-download">
        You can open your messages from Stitch in other mail clients that support POP, like Gmail, Microsoft Outlook.
      </div>
    </div>

    <div class="block-grow-item">
      <div class="heading3">Configure your email client</div>
      <app-button
        appearance="link-blue"
        label="Configuration Instructions"
      />
    </div>
  </div>

  <div class="block-grow">
    <span class="heading3">Status: </span>
    <span class="pop-imap-status" [class.active]="imapSettings.popEnabled !== 'off'">POP is {{ imapSettings.popEnabled !== 'off' ? 'enabled' : 'disabled' }}</span>
    for all mail that has arrived since {{ imapSettings.popSince | date: 'MM/dd/YY' }}

    <div class="radio-group">
      <app-radio-button
        title="Enable POP for all mail (even mail that’s already been downloaded)"
        [inputFormControl]="form.controls.popEnabled"
        value="on"
        appearance="radio"
      ></app-radio-button>

      <app-radio-button
        title="Enable POP for all mail (even mail that’s already been downloaded)"
        [inputFormControl]="form.controls.popEnabled"
        value="since"
        appearance="radio"
      ></app-radio-button>

      <app-radio-button
        title="Enable POP for all mail (even mail that’s already been downloaded)"
        [inputFormControl]="form.controls.popEnabled"
        value="off"
        appearance="radio"
      ></app-radio-button>

      <div class="pop-accessed">
        <div class="heading3">When messages are accessed with POP</div>
        <app-dropdown-input
          [inputFormControl]="form.controls.popAccessed"
          appearance="amethyst-simple"
          [options]="popAccessedOptions"
        ></app-dropdown-input>
      </div>
    </div>
  </div>
</div>

<div class="block">
  <div class="block-grow">
    <div class="block-grow-item">
      <div class="heading2">IMAP Access:</div>
      <div class="body-primary">Access Stitch from other clients using IMAP</div>
    </div>

    <div class="block-grow-item">
      <div class="heading3">Configure your email client</div>
      <app-button
        appearance="link-blue"
        label="Configuration Instructions"
      />
    </div>
  </div>

  <div class="block-grow">
    <div class="block-grow-item">
      <span class="heading3">Status: </span>
      <span class="pop-imap-status" [class.active]="imapSettings.imapEnabled !== 'off'">IMAP is {{ imapSettings.imapEnabled !== 'off' ? 'enabled' : 'disabled' }}</span>

      <div class="radio-group">
        <app-radio-button
          title="Enable IMAP"
          [inputFormControl]="form.controls.imapEnabled"
          value="on"
          appearance="radio"
        ></app-radio-button>

        <app-radio-button
          title="Disable IMAP"
          [inputFormControl]="form.controls.imapEnabled"
          value="off"
          appearance="radio"
        ></app-radio-button>
      </div>
    </div>

    <div class="block-grow-item">
      <div class="heading3">When I mark a message in IMAP as deleted:</div>

      <div class="radio-group">
        <app-radio-button
          title="Auto Expunge ON - Immediately update the server (default)."
          [inputFormControl]="form.controls.autoRemoveMessages"
          [value]="true"
          appearance="radio"
        ></app-radio-button>

        <app-radio-button
          title="Auto Expunge OFF - Wait for the client to update the server."
          [inputFormControl]="form.controls.autoRemoveMessages"
          [value]="false"
          appearance="radio"
        ></app-radio-button>
      </div>
    </div>

    <div class="block-grow-item">
      <div class="heading3 behaviour">When a message is marked as deleted and expunged from the last visible IMAP folder:</div>

      <div class="radio-group">
        <app-radio-button
          title="Archive the message (default)"
          [inputFormControl]="form.controls.removingBehaviour"
          value="archive"
          appearance="radio"
        ></app-radio-button>

        <app-radio-button
          title="Move the message to the Trash"
          [inputFormControl]="form.controls.removingBehaviour"
          value="trash"
          appearance="radio"
        ></app-radio-button>

        <app-radio-button
          title="Immediately delete the message forever"
          [inputFormControl]="form.controls.removingBehaviour"
          value="permanently"
          appearance="radio"
        ></app-radio-button>
      </div>
    </div>

    <div class="block-grow-item">
      <div class="heading3">Folder size limits</div>

      <div class="radio-group">
        <app-radio-button
          title="Do not limit the number of messages in an IMAP folder (default)"
          [inputFormControl]="form.controls.messagesLimitEnabled"
          [value]="true"
          appearance="radio"
        ></app-radio-button>

        <app-radio-button
          title="Limit IMAP folders to contain no more than this many messages"
          [inputFormControl]="form.controls.messagesLimitEnabled"
          [value]="false"
          appearance="radio"
        ></app-radio-button>

        <app-input
          class="messages-limit-input"
          appearance="amethyst"
          [inputFormControl]="form.controls.messagesLimitSize"
        ></app-input>
      </div>
    </div>
  </div>
</div>
