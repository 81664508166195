<div
  class="checkbox"
  [ngClass]="[appearance, size]"
  [class.checked]="checked"
  [class.unset]="unset"
  [class.disabled]="disabled"
>
  <input
    class="checkbox-input"
    [formControl]="inputFormControl"
    type="checkbox"
    [id]="uuid"
  />

  <div
    class="checkbox-mark"
    (click)="check($event)"
  >
    <mat-icon
      class="checkbox-mark-icon"
      [svgIcon]="icon"
    />
  </div>

  <label
    *ngIf="title"
    class="checkbox-title body-primary"
    [for]="uuid"
  >
    {{ title }}
  </label>
</div>
