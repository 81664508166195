// Common
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '@modules/common/interceptors/auth.interceptor';
import { DatePipe } from '@angular/common';

// Libs
import * as Raven from 'raven-js';

// Components
import { AppComponent } from './app.component';

// Route
import { AppRoutingModule } from './app-routing.module';

// Services
import { GeoLocationService } from '@modules/common/services/geo-location.service';
import { ModalService } from '@modules/modal/services/modal.service';

// Modules
import { AccountModule } from '@modules/account/account.module';
import { AnalyticsModule } from './modules/analytics/analytics.module';
import { AuthModule } from './modules/auth/auth.module';
import { IconsModule } from '@modules/icons/icons.module';
import { MatIconModule } from '@angular/material/icon';
import { ModalModule } from './modules/modal/modal.module';
import { NotificationModule } from './modules/notification/notification.module';
import { PagesModule } from './modules/pages/pages.module';
import { PopupModule } from './modules/popup/popup.module';
import { ToasterModule } from '@modules/toaster/toaster.module';

// Env
import { environment } from '../environments/environment';

if (environment.sentry.enabled) {
  Raven
    .config(environment.sentry.dsn)
    .install();
}

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (environment.sentry.enabled) {
      Raven.captureException(err);
    } else {
      console.error(err);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AccountModule,
    AnalyticsModule,
    AppRoutingModule,
    AuthModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    IconsModule,
    MatIconModule,
    ModalModule,
    NotificationModule,
    PagesModule,
    PopupModule,
    ToasterModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    GeoLocationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    DatePipe,
    ModalService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
