<app-split-view
  side="left"
  direction="vertical"
  key="kp"
  [collapsedSize]="42"
  [shadow]="true"
  [magneticSize]="120"
>
  <div split-left-pane class="split-area">
    <app-history></app-history>

    <app-tags-knowledge-section
      *ngIf="currentTab === 'tags'"
      [tabs]="tabs"
    ></app-tags-knowledge-section>

    <app-knots-knowledge-section
      *ngIf="currentTab === 'knots'"
      [tabs]="tabs"
    ></app-knots-knowledge-section>

    <app-connections-knowledge-section
      *ngIf="currentTab === 'connections'"
      [tabs]="tabs"
    ></app-connections-knowledge-section>
  </div>

  <div split-right-pane class="split-area">
    <app-knowledge-section
      title="Results"
      [stateKey]="resultsTypeStateKey"
      [tags]="currentTab === 'tags' ? resultsTags : []"
      [knots]="currentTab === 'knots' ? resultsKnots : []"
      [connections]="currentTab === 'connections' ? resultsConnections : []"
      (itemClick)="handleClickItem($event)"
    ></app-knowledge-section>
  </div>
</app-split-view>

<ng-template #tabs>
  <app-tabs
    selectedStateKey="kpTabs"
    [values]="tabsValues"
    [value]="currentTab"
    (valueChange)="handleTabsChange($event)"
  ></app-tabs>
</ng-template>
