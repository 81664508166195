// Types
import { Filters } from '@modules/common/types/filters';
import { Like } from '@modules/common/types/like';

export class ChargesFilters extends Filters {
  sortBy?: 'createdAt';
  userId?: string;

  constructor (filters?: Like<ChargesFilters>) {
    super(filters);
    this.sortBy = filters?.sortBy;
    this.userId = filters?.userId;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    result['sort_by'] = this.sortBy || 'createdAt';
    result['user_id'] = this.userId;

    return result;
  }
}
