// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Utils
import { getMonths } from '@modules/common/utils/date';

@Component({
  selector: 'app-months-context-menu',
  templateUrl: './months-context-menu.component.html',
  styleUrls: ['./months-context-menu.component.less']
})
export class MonthsContextMenuComponent {

  // Public
  public months = getMonths();

  // Inputs
  @Input() date: Date = new Date();

  // Outputs
  @Output() select = new EventEmitter<Date>();

  /**
   * Actions
   */

  handleMonthClick(monthIndex: number): void {
    this.select.emit(new Date(
      this.date.getFullYear(),
      monthIndex,
      this.date.getDate()
    ));
  }
}
