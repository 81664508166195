<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Personal Space"
  [active]="!spaceId"
  (execute)="selectSpace(null)"
></app-context-menu-item>

<app-context-menu-item
  *ngFor="let space of spaces"
  [withCheckOption]="true"
  [close]="true"
  [title]="space.title"
  [active]="space.id === spaceId"
  (execute)="selectSpace(space)"
></app-context-menu-item>

<app-context-menu-divider *ngIf="spaces.length"></app-context-menu-divider>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Add New Space"
  (execute)="addSpace()"
></app-context-menu-item>
