// Common
import { Component, Injector, Input, OnInit } from '@angular/core';

// Services
import { EventsService } from '@modules/calendar-app/services/events.service';
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { PostponeType } from '@modules/common/types/postpone-type';
import { Application } from '@modules/common/types/application';

// RX
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Components
import { StitchContextMenuComponent } from '../stitch-context-menu/stitch-context-menu.component';

@Component({
  selector: 'app-event-context-menu',
  templateUrl: './event-context-menu.component.html',
  styleUrls: ['./event-context-menu.component.less']
})
export class EventContextMenuComponent extends StitchContextMenuComponent<CalendarEvent> implements OnInit {

  @Input() event: CalendarEvent;

  applicationName = Application.calendar;
  gaPrefix = 'event-context-menu';

  constructor (
    private eventsStateService: CalendarAppStateService,
    private eventsService: EventsService,
    private toasterService: ToasterService,
    injector: Injector,
  ) {
    super(injector, eventsService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.item = this.event;
    super.ngOnInit();
  }

  /**
   * Actions
   */

  cut() {
    this.ga.trackEvent(this.gaPrefix, 'cut');
    this.eventsStateService.addToClipboard(this.includedInSelected() ? this.selectedItems : [this.event], 'cut');
  }

  copy() {
    this.ga.trackEvent(this.gaPrefix, 'copy');
    this.eventsStateService.addToClipboard(this.includedInSelected() ? this.selectedItems : [this.event], 'copy');
  }

  /**
   * Helpers
   */

  private includedInSelected(): boolean {
    return this.selectedItems.map(event => event.id).includes(this.event.id);
  }

  duplicate() {
    if (!this.event) { return; }

    const eventsToDuplicate = this.multiple ? this.selectedItems : [this.event];

    combineLatest(
      eventsToDuplicate.map((event) => this.eventsService.create(event, { emitUpdate: false, displayToast: false }))
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.toasterService
          .show({
            text: `Event${ this.multiple ? 's have' : ' has' } been duplicated`
          });

        this.eventsService.forceRefresh();
        this.close();
      });
  }

  postpone(date: Date, postponeType: PostponeType) {
    this.eventsService[postponeType](
      {
        ids: this.includedInSelected()
          ? this.selectedItems.filter(event => !event.readOnly).map(event => event.id)
          : [this.event.id]
      },
      date
    );
  }

  deletePermanently() {
    super.deletePermanently(`Are you sure you want to remove ${ this.multiple ? 'these' : 'this' } event${ this.multiple && 's'}?`);
  }
}
