// Common
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

// Rx
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

// Types
import { PermissionParticipant } from '../types/permission-participant';
import { PermissionParticipantsFilters } from '../types/permission-participants-filters';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';

// Env
import { environment } from '@environment';

@Injectable()
export class PermissionParticipantsService extends BaseRestService<PermissionParticipant, PermissionParticipantsFilters> {

  constructor (
    protected http: HttpClient,
    protected toaster: ToasterService,
  ) {
    super();
  }

  search(filters: Partial<PermissionParticipantsFilters>): Observable<BaseSearchResponse<PermissionParticipant>> {
    return this.http.get<{ items: PermissionParticipant[], count: number }>(
      `${environment.baseUrl}/api/mail/permission-participants`,
      { params: new PermissionParticipantsFilters(filters).format() }
    )
      .pipe(
        map(({ items, count }) => ({ items: items.map(item => new PermissionParticipant(item)), count })),
        catchError((error: Error) => {
          this.toaster.show({ text: error.message });

          return of({ items: [], count: 0 });
        })
      );
  }

  create(
    instance: PermissionParticipant,
    { emit, toast, message }: FeedbackConfig = { emit: true }
  ): Observable<PermissionParticipant> {
    return this.http.post<{ item: PermissionParticipant, success: boolean }>(
      `${environment.baseUrl}/api/mail/permission-participants`,
      instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || `Participant added.` });
        }),
        map(({ item }) => new PermissionParticipant(item)),
        catchError((error: HttpErrorResponse) => {
          this.toaster.show({ text: error.error.error });
          return throwError(error);
        })
      );
  }

  update(): Observable<PermissionParticipant> {
    throw new Error('Method forbidden')
  }

  deletePermanently(
    id: string,
    { emit = true, toast = true, message = null }: FeedbackConfig = {},
  ): Observable<boolean> {
    return this.http.delete<{ success: boolean }>(
      environment.baseUrl + `/api/mail/permission-participants/${ id }`
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (!success) { return; }

          emit && this.forceRefresh();
          toast && this.toaster.show({
            text: message || 'Permission Participant successfully deleted'
          });
        })
      );
  }
}
