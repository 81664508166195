// Common
import { Component, Injector, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

// Types
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';
import { ContactsListState } from '@modules/contacts/types/contacts-list-state';
import { GroupsListState } from '@modules/contacts/types/groups-list-state';
import { StateKey } from '@modules/settings/types/state-key';
import { GroupsFilters } from '@modules/contacts/types/groups-filters';
import { ContactsFilters } from '@modules/contacts/types/contacts-filters';
import { VirtualFolder } from '@modules/contacts/types/virtual-folder';

// Services
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { ContactsAppStateService } from '@modules/contacts/services/state.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-contacts-panel',
  templateUrl: './contacts-panel.component.html',
  styleUrls: ['./contacts-panel.component.less'],
})
export class ContactsPanelComponent implements OnInit, OnDestroy {

  public contactsListState = new BehaviorSubject<ContactsListState>(null);
  public groupsListState = new BehaviorSubject<GroupsListState>(null);
  public groupsListStateKey: StateKey = StateKey.groupsDPListState;
  public contactsListStateKey: StateKey = StateKey.contactsDPListState;
  public groupsFilters: GroupsFilters;
  public contactsFilters: ContactsFilters;
  public contextMenuOpened: boolean;
  public selectedFilter: VirtualFolder;

  private alive = new Subject<void>();

  @Input() searchTemplate: TemplateRef<any>;

  constructor (
    private dpService: DynamicPanelService,
    private modalService: ModalService,
    private injector: Injector,
    private searchService: AdvancedSearchService,
    private stateService: ContactsAppStateService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([
      this.contactsListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.contactsFilters = new ContactsFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([
      this.groupsListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.groupsFilters = new GroupsFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewContact(contact = new Contact()) {
    this.dpService.setFormItem(contact);
  }

  handleNewGroup(group = new Group()) {
    this.dpService.setFormItem(group);
  }

  openContactInModal(contact: Contact) {
    this.modalService.openFullForm(contact, this.injector);
  }

  openContact(contact: Contact) {
    this.dpService.setFormItem(contact);
  }

  openGroup(group: Group) {
    this.dpService.setFormItem(group);
  }

  handleContactsListStateChange(event: ContactsListState) {
    this.contactsListState.next(event);
  }

  handleGroupsListStateChange(event: GroupsListState) {
    this.groupsListState.next(event);
  }

  selectFilter(folder: VirtualFolder): void {
    this.selectedFilter = folder;
    this.stateService.setVirtualFolder(folder);
  }
}
