// Utils
import { isNil } from '@modules/common/utils/base';

// Types
import { Filters } from '@modules/common/types/filters';
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { Connection } from '@modules/connections/types/connection';
import { Knot } from '@modules/knots/types/knot';
import { ManageListState } from './manage-list-state';

export class TagFilters extends Filters {
  // Filters
  connections?: Connection[];
  createdFrom?: Date;
  createdTo?: Date;
  items?: Stitch[];
  knots?: Knot[];
  names?: string[];
  pinned?: boolean;
  query?: string;
  withUnread?: boolean;

  // Sort Options
  sortBy?: 'name' | 'date' | 'recency' | 'frequency';
  pinnedOnTop?: boolean;

  constructor(filters: Partial<TagFilters> = {}) {
    super(filters);
    this.connections = filters.connections;
    this.createdFrom = filters.createdFrom;
    this.createdTo = filters.createdTo;
    this.items = filters.items;
    this.knots = filters.knots;
    this.names = filters.names;
    this.pinned = filters.pinned;
    this.pinnedOnTop = filters.pinnedOnTop;
    this.query = filters.query;
    this.sortBy = filters.sortBy;
    this.withUnread = filters.withUnread;
  }

  static fromManageListState(state: ManageListState): TagFilters {
    return new TagFilters({
      createdFrom: state.filters.createdFrom,
      createdTo: state.filters.createdTo,
      sortOrder: state.sort.order,
      sortBy: state.sort.by
    });
  }

  format(): { [param: string]: string | string[]; } {
    const result = {};

    if (this.pinned !== null && this.pinned !== undefined) {
      result['pinned'] = this.pinned + '';
    }

    if (this.withUnread !== null && this.withUnread !== undefined) {
      result['with_unread'] = this.withUnread + '';
    }

    if (this.names && this.names.length) {
      result['names[]'] = this.names;
    }

    if (this.knots?.length) {
      result['knots[]'] = this.knots.map(({ name }) => name);
    }

    if (this.connections?.length) {
      result['connections[]'] = this.connections.map(({ name }) => name);
    }

    if (this.query && this.query.length) {
      result['query'] = this.query;
    }

    if (this.ids && this.ids.length) {
      result['ids[]'] = this.ids;
    }

    if (this.createdFrom) { result['created_from_time'] = this.createdFrom.toISOString(); }
    if (this.createdTo) { result['created_to_time'] = this.createdTo.toISOString(); }

    [
      ['message_folders_ids[]', StitchType.messageFolder],
      ['messages_ids[]', StitchType.message],
      ['calendars_ids[]', StitchType.calendar],
      ['events_ids[]', StitchType.event],
      ['projects_ids[]', StitchType.project],
      ['tasks_ids[]', StitchType.task],
      ['notebooks_ids[]', StitchType.notebook],
      ['notes_ids[]', StitchType.note],
      ['groups_ids[]', StitchType.group],
      ['contacts_ids[]', StitchType.contact],
      ['folders_ids[]', StitchType.folder],
      ['files_ids[]', StitchType.file],
    ]
      .forEach(([key, stitchType]) => {
        const items = this.items?.filter(item => item?.getStitchType() === stitchType) || [];

        if (items.length) {
          result[key] = items.map(item => item.id);
        }
      });

    if (this.sortBy) { result['sort_by'] = this.sortBy; }
    if (this.sortOrder) { result['sort_order'] = this.sortOrder; }
    if (this.pinnedOnTop) { result['pinned_on_top'] = true; }

    if (!isNil(this.limit)) { result['limit'] = this.limit + ''; }
    if (!isNil(this.offset)) { result['offset'] = this.offset + ''; }

    return result;
  }
}
