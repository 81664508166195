// Common
import { Component, OnDestroy, Input, OnInit, SimpleChanges, OnChanges, NgZone, Output, EventEmitter, Self, Inject, Injector } from '@angular/core';

// RX
import { interval, BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { takeUntil, filter, debounceTime } from 'rxjs/operators';

// Services
import { MailAppStateService } from '@modules/messages/services/state.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { MessagesService } from '@modules/messages/services/messages.service';

// Injection Tokens
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';

// Types
import { MessagesFilters } from '@modules/messages/types/messages-filters';
import { Message } from '@modules/messages/types/message';
import { Like } from '@modules/common/types/like';
import { KnotSource } from '@modules/knots/types/knot-source';

// Env
import { environment } from '@environment';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

@Component({
  selector: 'app-messages2-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }]
})
export class MessagesListComponent extends InfinityScrollListComponent implements OnInit, OnDestroy, OnChanges {

  // Inputs
  @Input() placeholderText = 'You have no messages';
  @Input() filters: Like<MessagesFilters> = {};
  @Input() withTags = false;
  @Input() withKnots = false;
  @Input() withConnections = false;
  @Input() knotsSource: KnotSource;

  @Input() debug: 'score';

  // Outputs
  @Output() clickMessage = new EventEmitter<Message>();
  @Output() openMessage = new EventEmitter<Message>();
  @Output() doubleClickMessage = new EventEmitter<Message>();

  // Public
  public itemHeight = 72;
  public isHover = false;
  public showCountView = new BehaviorSubject(true);
  public resetSelected = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private messagesService: MessagesService,
    private messagesStateService: MailAppStateService,
    private linkedInfoService: LinkedInfoService,
    protected ngZone: NgZone,
    @Self() @Inject(ScrollToPosition) scrollToPositionSubject,
    private injector: Injector,
  ) {
    super(ngZone, scrollToPositionSubject);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.showCountView
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      this.messagesStateService.getRefreshAll(),
      this.linkedInfoService.getRefreshRequired(),
      interval(environment.messageFetchInterval),
      this.messagesService.getRefreshRequired(),
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.refreshCurrentItems();
      });

    this.refreshCurrentItems();

    merge(
      this.messagesStateService.getRefreshAll(),
      this.messagesService.getRefreshRequired(),
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.resetSelected.next();
      });

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('filters' in changes) {
      this.resetItems();
    }
  }

  /**
   * Actions
   */

  getItems(offset: number, limit: number): Observable<{ items: Object[], count: number }> {
    return this.messagesService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: this.withConnections, knotsSource: this.knotsSource }
    );
  }

  resetItems() {
    this.resetSelected.next();
    super.resetItems();
  }

  handleDoubleClickItem(message: Message) {
    if (message.draft) {
      this.messagesStateService.composeMessage({ message, injector: this.injector });
    } else {
      this.doubleClickMessage.emit(message);
    }
  }

  handleClickItem(message: Message) {
    if (message.draft) {
      this.messagesStateService.composeMessage({ message, injector: this.injector });
    } else {
      this.clickMessage.emit(message);
    }
  }

  compareItems(item1: Object, item2: Object): boolean {
    return item1 && item2 && item1['id'] === item2['id'];
  }
}
