// Common
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

// Types
import { Signature } from '@modules/account/types/signature';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { SignaturesService } from '@modules/account/services/signatures.service';

// Injection Tokens
import CloseToken from '@modules/modal/types/modal-close.injection-token';

@Component({
  selector: 'app-settings-messages-signatures-signature-form',
  templateUrl: './signature-form.component.html',
  styleUrls: ['./signature-form.component.less']
})
export class SignatureFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() signature: Signature;

  public signatureForm: FormGroup<{
    id: FormControl<string>,
    title: FormControl<string>,
    content: FormControl<string>,
    isDefault: FormControl<boolean>
  }>;

  private signatureChanged = new Subject<void>();
  private alive = new Subject<void>();

  constructor(
    private signaturesService: SignaturesService,
    @Inject(CloseToken) private closeToken
  ) { }

  ngOnInit(): void {
    this.signatureChanged
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.signatureForm = (this.signature || new Signature()).asFormGroup();
      });

    this.signatureChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('signature' in changes) {
      this.signatureChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  handleSubmit() {
    if (this.signatureForm.controls.content.value.search(/<!--signature-->/g) === -1) {
      this.signatureForm.controls.content.setValue(
        `<!--signature-->
        ${ this.signatureForm.controls.content.value }
        <!--endSignature-->`,
        { emitEvent: false }
      );
    }

    const signature = Signature.fromFormGroup(this.signatureForm);

    this.signaturesService.upsert(signature)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.handleClose());
  }

  handleClose() {
    this.closeToken.next();
  }

}
