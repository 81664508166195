<stch-full-calendar *ngIf="!item" />

<ng-container
  *ngIf="item"
  [ngSwitch]="item | stitchType"
>
  <app-event-form
    *ngSwitchCase="StitchType.event"
    [event]="item"
    (close)="handleFormClose()"
  />

  <app-calendar-form
    *ngSwitchCase="StitchType.calendar"
    [calendar]="item"
    (close)="handleFormClose()"
  />
</ng-container>
