// Common
import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Like } from '@modules/common/types/like';
import { CLOSE_POPOVER } from '@modules/popover/types/close-popover.injection-token';

// Utils
import { addDays } from '@modules/common/utils/date';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { Application } from '@modules/common/types/application';

// Services
import { BaseStitchService } from '@modules/common/services/base-stitch.service';
import { BaseAppStateService } from '@modules/common/services/base-app-state.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({ template: '' })
export abstract class BaseSidebarContextMenuComponent<StitchItem extends Stitch, StitchContainer extends Stitch, VirtualFolder> implements OnInit, OnDestroy {

  @Input() sidebarFilterKey: VirtualFolder;

  public abstract advancedSearchStateKey: Application;
  public stitchItem: StitchItem;
  public containerStitchItem: StitchContainer;

  protected selectedContainerStitchItemId: string;
  protected alive = new Subject<void>();

  protected router: Router;
  protected searchService: AdvancedSearchService;
  protected closePopoverToken: Subject<void>;

  constructor(
    injector: Injector,
    protected itemsService: BaseStitchService<StitchItem, StitchFilters>,
    protected containerItemsService: BaseStitchService<StitchContainer, StitchFilters>,
    protected stitchStateService: BaseAppStateService<StitchContainer, StitchItem, VirtualFolder>,
  ) {
    this.router = injector.get(Router);
    this.closePopoverToken = injector.get(CLOSE_POPOVER, undefined, { optional: true });
    this.searchService = injector.get(AdvancedSearchService);
  }

  ngOnInit(): void {
    this.searchService.getState()
      .pipe(
        map(({ [this.advancedSearchStateKey]: { containersIds } }) => containersIds[0]),
        takeUntil(this.alive)
      )
      .subscribe((containerStitchItemId) => {
        this.selectedContainerStitchItemId = containerStitchItemId;
        this.prepareQuickItems();
      });
  }

  // ngOnChanges(changes: SimpleChanges) { //  TODO fix this
  //   if (this.sidebarFilterKey in changes) {
  //     this.prepareQuickItems();
  //   }
  // }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  public unFlagAll() {
    this.itemsService.flag({ flagged: true }, false);
    this.containerItemsService.flag({ flagged: true }, false);
  }

  public unFollowAll() {
    this.itemsService.followUp({ followed: true }, null);
    this.containerItemsService.followUp({ followed: true }, null);
  }

  public unSnoozeAll() {
    this.itemsService.snooze({ snoozed: true }, null);
    this.containerItemsService.snooze({ snoozed: true }, null);
  }

  public deleteAll(): void {
    this.itemsService.delete({ archived: true }, true);
    this.containerItemsService.delete({ archived: true }, true);
  }

  public emptyTrash(): void {
    this.itemsService.deletePermanently({ deleted: true });
    this.containerItemsService.deletePermanently({ deleted: true });
  }

  public handleMore(entity: StitchItem | StitchContainer) {
    this.stitchStateService.setMainView(entity);
    this.handleClose();
  }

  public handleClose() {
    this.closePopoverToken.next();
  }

  public openSettings() {
    this.router.navigate([`settings/${this.advancedSearchStateKey}`]);
  }

  protected abstract stitchItemFactory(params: Like<Stitch>): StitchItem;
  protected abstract containerStitchItemFactory(params: Like<Stitch>): StitchContainer;

  protected getCommonParams(): Like<Stitch> {
    const params: Like<Stitch> = {};
    let date = new Date();

    switch (this.sidebarFilterKey) {
      case 'snoozed':
        date.setMinutes(date.getMinutes() + 5);

        params.snoozed = date;

        break;
      case 'followed':
        date = addDays(date, 1);
        date.setHours(9, 0, 0, 0);

        params.followed = date;

        break;
      case 'flagged':
        params.flagged = true;

        break;
      case 'deleted':
      case 'archived':
        break;
    }

    return params;
  }

  private prepareQuickItems() {
    const params = this.getCommonParams();

    this.stitchItem = this.stitchItemFactory(params);
    this.containerStitchItem = this.containerStitchItemFactory(params);
  }
}
