// Common
import { FormGroup, UntypedFormGroup, Validators } from '@angular/forms';

// Utils
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Validators
import { NestedValidators } from '@modules/form-controls/validators/nested.validators';

// Services
import { MessageFoldersService } from '../services/message-folders.service';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { Message } from './message';
import { VirtualFolder } from '@modules/messages/types/virtual-folder';
import { Like } from '@modules/common/types/like';
import { FileValidators } from '@modules/form-controls/validators/file.validators';

export class MessageFolder extends Stitch {
  description?: string;
  parentId?: string;
  title?: string;

  constructor(data: any = {}) {
    super(data);

    if (data instanceof Stitch) {
      this.fillFromStitch(data);
    } else {
      this.description = data.description;
      this.parentId = data.parentId || null;
      this.title = data.title;
    }
  }

  static fromFormGroup(form: UntypedFormGroup): MessageFolder {
    const formValues = form.value;

    return new MessageFolder({
      archived: formValues.archived,
      color: formValues.color,
      connections: formValues.connections,
      deleted: formValues.deleted,
      description: formValues.description,
      flagged: formValues.flagged,
      id: formValues.id,
      knots: formValues.knots,
      linkedInfo: formValues.linkedInfo,
      parentId: formValues.parentId,
      tags: formValues.tags,
      title: formValues.title,
      uploads: formValues.uploads,
    });
  }

  static fromDragData(dragItem: DragData): MessageFolder {
    return <MessageFolder>super.fromDragData(dragItem);
  }

  static fromAdvancedState(filters: AdvancedSearchState, folder: VirtualFolder): MessageFolder {
    return new MessageFolder({
      title: filters.query || filters.mail.subject,
      tags: filters.tags,
      knots: filters.knots,
      description: filters.mail.body,
      parentId: filters.mail.containersIds?.[0],
      flagged: folder === 'flagged',
      archived: folder === 'archived',
      deleted: folder === 'deleted'
    });
  }

  static shouldRefreshList(prev, current) {
    return super.shouldRefreshList(prev, current, ['title', 'description', 'parentId']);
  }

  static getChangesForVirtualFolder(sidebar: VirtualFolder): { changes: Like<MessageFolder>, message: string } {
    let message = 'Successfully moved to ';

    switch (sidebar) {
      case 'all_messages':
        break;
      case 'all_folders':
        return { changes: { parentId: null }, message: message += 'Orphan' };
      case 'bulk':
      case 'draft':
      case 'sent':
      case 'spam':
      case 'scheduled':
        break;
      case 'archived':
      case 'deleted':
      case 'followed':
      case 'snoozed':
      case 'flagged':
        return super.getChangesForVirtualFolder(sidebar);
      default:
        checkExhaustiveness(sidebar);
    }
  }

  getStitchType(): StitchType {
    return StitchType.messageFolder;
  }

  asFormGroup(foldersService?: MessageFoldersService): FormGroup {
    return this.formBuilder.group(
      {
        archived: [this.archived],
        color: [this.color],
        connections: [this.connections],
        deleted: [this.deleted],
        description: [this.description || ''],
        flagged: [this.flagged],
        id: [this.id],
        knots: [this.knots],
        linkedInfo: [this.linkedInfo || []],
        parentId: [this.parentId || ''],
        tags: [this.tags || []],
        title: [this.title, Validators.required],
        uploads: [this.uploads || [], FileValidators.size(26214400)],
      },
      {
        asyncValidators: [
          NestedValidators.circular('id', 'parentId', foldersService)
        ]
      }
    );
  }

  asPayloadJSON() {
    return {
      archived: this.archived,
      color: this.color,
      deleted: this.deleted,
      description: this.description,
      flagged: this.flagged,
      parentId: this.parentId,
      title: this.title && this.title.trim(),
    };
  }

  asAngularCalendarEvent(): AngularCalendarEvent {
    return this.convertToAngularCalendarEvent(
      this.title, this.createdAt, this.createdAt, this.createdAt, this.createdAt
    );
  }

  protected fillFromStitch(data: Stitch) {
    super.fillFromStitch(data);

    if (data instanceof Message) {
      this.description = data.bodyText;
    }
  }
}
