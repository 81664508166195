// Common
import { Component, Input, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// RX
import { Subject, merge } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

// Types
import { Column } from '@modules/tasks/types/column';
import { SectionTaskAppearance } from '@modules/tasks/types/section-task-appearance';
import { Row } from '@modules/tasks/types/row';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { Task } from '@modules/tasks/types/task';
import { NestedDataItem } from '@modules/drag-n-drop/types/nested-item';

// Services
import { BoardService } from '@modules/tasks/services/board.service';
import { TaskingAppStateService } from '@modules/tasks/services/state.service';
import { TasksService } from '@modules/tasks/services/tasks.service';

@Component({
  selector: 'app-project-board-column',
  templateUrl: './project-board-column.component.html',
  styleUrls: ['./project-board-column.component.less'],
})
export class ProjectBoardColumnComponent implements OnInit, OnChanges, OnDestroy {

  @Input() column: Column;
  @Input() row: Row;
  @Input() appearance: SectionTaskAppearance = 'board-large';

  public dndPredicate = (dragData: DragData) => dragData.type === 'task';
  public tasks: Task[] = [];
  public columnForm: UntypedFormGroup;
  public collapsed = false;
  public tasksChanges = new Subject<NestedDataItem<Task>[]>();
  public tasksCount = 0;
  public quickTask: Task;

  private alive = new Subject<void>();
  private columnChanged = new Subject<void>();
  private rowChanged = new Subject<void>();

  constructor (
    private boardService: BoardService,
    private messagesStateService: TaskingAppStateService,
    private tasksService: TasksService
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.columnForm = this.column.asFormGroup();

    this.columnChanged
      .pipe(
        switchMap(() => this.boardService.getColumnCollapsed(this.column.id)),
        takeUntil(this.alive)
      )
      .subscribe((value: boolean) => this.collapsed = value);

    merge([this.columnChanged, this.rowChanged])
      .pipe(
        switchMap(() => this.boardService.getTasks(this.column, this.row)),
        takeUntil(this.alive)
      )
      .subscribe(tasks => {
        this.tasks = tasks;
      });

    this.columnChanged
      .pipe(
        switchMap(() => this.boardService.getTasksCount(this.column)),
        takeUntil(this.alive)
      )
      .subscribe(count => {
        this.tasksCount = count;
      });

    this.columnChanged.next();
    this.rowChanged.next();

    this.tasksChanges
      .pipe(takeUntil(this.alive))
      .subscribe(changes => {
        this.boardService.updateTasks(changes, this.row?.id, this.column.id);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('column' in changes) {
      this.columnChanged.next();
    }

    if ('row' in changes) {
      this.rowChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleBlur() {
    if (
      this.columnForm.get('title').dirty &&
      this.columnForm.get('title').value.trim() !== ''
    ) {
      this.boardService.updateColumn(Column.fromFormGroup(this.columnForm));
    }
  }

  handleNewColumnRight () {
    if (this.column.temp) { return; }
    this.boardService.addColumn(true, this.column.position);
  }

  collapse() {
    this.boardService.setColumnCollapsed(this.column.id, !this.collapsed);
  }

  handleNewTask() {
    this.quickTask = new Task({ projectId: this.column.projectId, rowId: this.row?.id, columnId: this.column.id });
  }

  public handleNewTaskDrop(dragData: DragData) {
    const task = Task.fromDragData(dragData);
    task.projectId = this.column.projectId;
    task.rowId = this.row?.id;
    task.columnId = this.column.id;

    this.quickTask = task;
  }

  handleQuickFormClose() {
    this.quickTask = null;
  }

  handleQuickFormSave(task: Task) {
    task.position = this.tasks.reduce(
      (memo, { parentId, position }) => parentId === null && memo < position ? position : memo, -1
    ) + 1;

    this.tasksService.create(task)
      .pipe(takeUntil(this.alive))
      .subscribe(savedTask => {
        this.boardService.addTask(savedTask);
        this.quickTask = null;
      });
  }

  handleQuickFormMore(task: Task) {
    this.messagesStateService.setMainView(task);
  }

  /**
   * Helpers
   */

  tasksTrackByFn(task: Task): string {
    return task && task.id;
  }
}
