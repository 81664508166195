<div
  *ngIf="note"
  class="note"
  [class.no-actions]="!withActions"
  [class.short-view]="viewType === 'short'"
  [class.pinned]="note.pinned"
  [class.with-description]="note.description"
  [appNoteContextMenu]="note"
  [appNoteContextMenuDisabled]="!withContextMenu"
  (appNoteContextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableDisabled]="!withDraggable"
  [appDraggableData]="{data: [note], type: 'note'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableDisabled]="!withDroppable"
  [appDroppableZIndex]="2"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="note-body">
    <div class="note-body-heading">
      <div class="note-body-heading-title">
        {{note.title}}
      </div>
    </div>

    <app-date-time
      class="note-body-date-time"
      [fromDate]="note.createdAt"
      [fromTime]="note.createdAt"
      [toDate]="note.updatedAt"
      [toTime]="note.updatedAt"
    ></app-date-time>

    <div class="note-body-description" *ngIf="note.snippet && viewType !== 'short'">
      {{ note.snippet }}
    </div>

    <app-link-info-bar
      class="note-body-info-bar"
      *ngIf="viewType !== 'short'"
      [stitch]="note"
    ></app-link-info-bar>
  </div>

  <mat-icon class="note-pin-icon" *ngIf="note.pinned" svgIcon="pin-direct"></mat-icon>
  <mat-icon class="note-starred-icon" *ngIf="!note.starred" svgIcon="starred"></mat-icon>
  <mat-icon class="note-marker" svgIcon="note-small"></mat-icon>
  <mat-icon class="note-attachments-icon" *ngIf="note.files?.length" svgIcon="attachment"></mat-icon>

  <div
    class="note-hover-icons"
    *ngIf="withDraggable && withActions && !removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="note-hover-icons-item"
      svgIcon="flag"
      [class.active]="note.flagged"
      matTooltip="Flag"
      (click)="flag()"
    ></mat-icon>
    <mat-icon
      class="note-hover-icons-item"
      svgIcon="pin"
      [class.active]="note.pinned"
      [matTooltip]="note?.pinned ? 'Unpin' : 'Pin'"
      (click)="pin()"
    ></mat-icon>
    <mat-icon
      class="note-hover-icons-item"
      svgIcon="snooze"
      matTooltip="Coming soon"  
    ></mat-icon>
    <mat-icon
      class="note-hover-icons-item"
      svgIcon="event"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="note-hover-icons-item"
      svgIcon="archive"
      [class.active]="note.archived"
      [matTooltip]="note?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    ></mat-icon>
    <mat-icon
      class="note-hover-icons-item"
      svgIcon="trash"
      [class.active]="note.deleted"
      [matTooltip]="note?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    ></mat-icon>
  </div>

  <div
    class="note-hover-icons"
    *ngIf="withActions && removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="note-hover-icons-item"
      svgIcon="trash"
      [class.active]="note.deleted"
      [matTooltip]="note?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    ></mat-icon>
  </div>

  <div class="note-bottom-container">
    <mat-icon
      *ngIf="withUnlink"
      class="note-bottom-container-unlink"
      svgIcon="unlink"
      matTooltip="Unlink"
      (click)="unlinkItem($event)"
    ></mat-icon>
  </div>

  <div class="drop-zone"></div>
</div>

<!-- TODO waiting for design -->
<div
  class="note placeholder"
  *ngIf="!note"
  [class.short-view]="viewType === 'short'"
>
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
</div>

<ng-template #dragPlaceholder>
  <app-link-info-note
    [note]="note"
    [withDraggable]="false"
    [withContextMenu]="false"
    [withActions]="false"
    [viewType]="viewType"
  ></app-link-info-note>
</ng-template>
