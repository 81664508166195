<div class="form-container" [ngSwitch]="formType">
  <app-message-folder-form
    *ngSwitchCase="StitchType.messageFolder"
    [folder]="formItem"
    [tab]="preSelectedTab"
    [dp]="true"
    (close)="handleCancel()"
  />

  <app-message2-form
    *ngSwitchCase="StitchType.message"
    (close)="handleCancel()"
    [message]="formItem"
    [autoSaveAllowed]="false"
    [tab]="preSelectedTab"
    [dp]="true"
  />

  <app-calendar-form
    *ngSwitchCase="StitchType.calendar"
    [calendar]="formItem"
    [tab]="preSelectedTab"
    [dp]="true"
    (close)="handleCancel()"
  ></app-calendar-form>

  <app-event-form
    *ngSwitchCase="StitchType.event"
    [event]="formItem"
    [tab]="preSelectedTab"
    [dp]="true"
    (close)="handleCancel()"
  />

  <app-project-form
    *ngSwitchCase="StitchType.project"
    [project]="formItem"
    [tab]="preSelectedTab"
    [dp]="true"
    (close)="handleCancel()"
  />

  <app-task-form
    *ngSwitchCase="StitchType.task"
    [task]="formItem"
    [tab]="preSelectedTab"
    [dp]="true"
    (close)="handleCancel()"
  />

  <app-notebook-form
    *ngSwitchCase="StitchType.notebook"
    [notebook]="formItem"
    [tab]="preSelectedTab"
    [dp]="true"
    (close)="handleCancel()"
  />

  <app-note-form
    *ngSwitchCase="StitchType.note"
    [note]="formItem"
    [tab]="preSelectedTab"
    [dp]="true"
    (close)="handleCancel()"
  />

  <app-group-form
    *ngSwitchCase="StitchType.group"
    [group]="formItem"
    [tab]="preSelectedTab"
    [dp]="true"
    (close)="handleCancel()"
  />

  <app-contact-form
    *ngSwitchCase="StitchType.contact"
    [contact]="formItem"
    [tab]="preSelectedTab"
    [dp]="true"
    (close)="handleCancel()"
  />

  <app-folder-form
    *ngSwitchCase="StitchType.folder"
    [folder]="formItem"
    [tab]="preSelectedTab"
    [dp]="true"
    (close)="handleCancel()"
  />

  <app-file-form
    *ngSwitchCase="StitchType.file"
    [file]="formItem"
    [tab]="preSelectedTab"
    [dp]="true"
    (close)="handleCancel()"
  />
</div>
