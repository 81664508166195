// Common
import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Injectable()
export class DateTimeValidators {
  static dateRange(
    startDateKey: string,
    startTimeKey: string,
    endDateKey: string,
    endTimeKey: string,
  ) {
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const startDate = form.get(startDateKey).value;
      const startTime = form.get(startTimeKey).value;
      const endDate = form.get(endDateKey).value;
      const endTime = form.get(endTimeKey).value;

      if (!startDate || !startTime || !endDate || !endTime) { return null; }

      if (
        new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          startTime.getHours(),
          startTime.getMinutes()
        ) >
        new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          endTime.getHours(),
          endTime.getMinutes()
        )
      ) {
        return { dateRange: 'Start date/time occurs after end date/time' };
      }

      return null;
    };
  }
}
