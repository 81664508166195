<app-context-menu-item
  [withCheckOption]="true"
  title="Only Pinned Tags"
  [active]="state?.filters?.pinnedTags"
  (execute)="setBooleanFilter('pinnedTags')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Only Pinned Knots"
  [active]="state?.filters?.pinnedKnots"
  (execute)="setBooleanFilter('pinnedKnots')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Auto Discovery"
  [active]="state?.filters?.autoDiscovery"
  (execute)="setBooleanFilter('autoDiscovery')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Score"
>
  <div class="slider">
    <app-slider
      [fromControl]="scoreFrom"
      [toControl]="scoreTo"
      [floor]="0"
      [ceil]="100"
      [step]="0.5"
    ></app-slider>
  </div>
</app-context-menu-item>

<app-context-menu-divider></app-context-menu-divider>

<app-context-menu-item
  [withCheckOption]="true"
  title="Display Knots"
  [active]="state?.filters?.displayKnots"
  (execute)="setBooleanFilter('displayKnots')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Display Tags"
  [active]="state?.filters?.displayTags"
  (execute)="setBooleanFilter('displayTags')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Display Connections"
  [active]="state?.filters?.displayConnections"
  (execute)="setBooleanFilter('displayConnections')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Display Stitched"
  [active]="state?.filters?.displayStitched"
  (execute)="setBooleanFilter('displayStitched')"
></app-context-menu-item>

<app-stitch-type-context-menu-item
  [withCheckOption]="true"
  title="Stitch"
  [state]="state?.filters?.stitch"
  (onSelect)="stitch($event)"
></app-stitch-type-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Collapsed Knots"
  [active]="state?.filters?.collapsed"
  (execute)="setBooleanFilter('collapsed')"
></app-context-menu-item>

<app-context-menu-divider></app-context-menu-divider>

<app-context-menu-item
  [withCheckOption]="true"
  title="Created At"
  [active]="
    state?.filters?.createdLastHour ||
    state?.filters?.createdLast3Hours ||
    state?.filters?.createdLast5Hours ||
    state?.filters?.createdToday ||
    state?.filters?.createdThisWeek ||
    state?.filters?.createdThisMonth ||
    state?.filters?.createdThisYear ||
    state?.filters?.createdFrom ||
    state?.filters?.createdTo
  "
>
  <app-context-menu-item
    [withCheckOption]="true"
    title="Last Hour"
    [active]="state?.filters?.createdLastHour"
    (execute)="setDateCreatedFilter('createdLastHour')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    title="Last 3 Hours"
    [active]="state?.filters?.createdLast3Hours"
    (execute)="setDateCreatedFilter('createdLast3Hours')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    title="Last 5 Hours"
    [active]="state?.filters?.createdLast5Hours"
    (execute)="setDateCreatedFilter('createdLast5Hours')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    title="Today"
    [active]="state?.filters?.createdToday"
    (execute)="setDateCreatedFilter('createdToday')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    title="This Week"
    [active]="state?.filters?.createdThisWeek"
    (execute)="setDateCreatedFilter('createdThisWeek')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    title="This Month"
    [active]="state?.filters?.createdThisMonth"
    (execute)="setDateCreatedFilter('createdThisMonth')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    title="This Year"
    [active]="state?.filters?.createdThisYear"
    (execute)="setDateCreatedFilter('createdThisYear')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    [active]="state?.filters?.createdFrom || state?.filters?.createdTo"
    title="Selected Date"
  >
    <div class="date">
      <div class="date-buttons">
        <div
          class="date-buttons-item"
          [class.selected]="dateButtonSelected === 'from'"
          [class.active]="createdFromControl?.value"
          (click)="dateButtonSelected = 'from'"
        >
          {{ createdFromControl?.value ? (createdFromControl?.value | date : 'MMM d') : 'Start Date'}}
        </div>
        <div
          class="date-buttons-item"
          [class.selected]="dateButtonSelected === 'to'"
          [class.active]="createdToControl?.value"
          (click)="dateButtonSelected = 'to'"
        >
          {{ createdToControl?.value ? (createdToControl?.value | date : 'MMM d') : 'Due Date'}}
        </div>
      </div>

      <app-date-picker-2
        [inputFormControl]="dateButtonSelected === 'from' ? createdFromControl : createdToControl"
        [collapseable]="false"
        [maxDate]="dateButtonSelected === 'from' && createdToControl?.value"
        [minDate]="dateButtonSelected === 'to' && createdFromControl?.value"
        [highlightRange]="true"
      ></app-date-picker-2>
    </div>
  </app-context-menu-item>
</app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  title="Linked At"
  [active]="
    state?.filters?.linkedLastHour ||
    state?.filters?.linkedLast3Hours ||
    state?.filters?.linkedLast5Hours ||
    state?.filters?.linkedToday ||
    state?.filters?.linkedThisWeek ||
    state?.filters?.linkedThisMonth ||
    state?.filters?.linkedThisYear ||
    state?.filters?.linkedFrom ||
    state?.filters?.linkedTo
  "
>
  <app-context-menu-item
    [withCheckOption]="true"
    title="Last Hour"
    [active]="state?.filters?.linkedLastHour"
    (execute)="setDateLinkedFilter('linkedLastHour')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    title="Last 3 Hours"
    [active]="state?.filters?.linkedLast3Hours"
    (execute)="setDateLinkedFilter('linkedLast3Hours')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    title="Last 5 Hours"
    [active]="state?.filters?.linkedLast5Hours"
    (execute)="setDateLinkedFilter('linkedLast5Hours')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    title="Today"
    [active]="state?.filters?.linkedToday"
    (execute)="setDateLinkedFilter('linkedToday')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    title="This Week"
    [active]="state?.filters?.linkedThisWeek"
    (execute)="setDateLinkedFilter('linkedThisWeek')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    title="This Month"
    [active]="state?.filters?.linkedThisMonth"
    (execute)="setDateLinkedFilter('linkedThisMonth')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    title="This Year"
    [active]="state?.filters?.linkedThisYear"
    (execute)="setDateLinkedFilter('linkedThisYear')"
  ></app-context-menu-item>

  <app-context-menu-item
    [withCheckOption]="true"
    [active]="state?.filters?.linkedFrom || state?.filters?.linkedTo"
    title="Selected Date"
  >
    <div class="date">
      <div class="date-buttons">
        <div
          class="date-buttons-item"
          [class.selected]="dateButtonSelected === 'from'"
          [class.active]="linkedFromControl?.value"
          (click)="dateButtonSelected = 'from'"
        >
          {{ linkedFromControl?.value ? (linkedFromControl?.value | date : 'MMM d') : 'Start Date'}}
        </div>
        <div
          class="date-buttons-item"
          [class.selected]="dateButtonSelected === 'to'"
          [class.active]="linkedToControl?.value"
          (click)="dateButtonSelected = 'to'"
        >
          {{ linkedToControl?.value ? (linkedToControl?.value | date : 'MMM d') : 'Due Date'}}
        </div>
      </div>

      <app-date-picker-2
        [inputFormControl]="dateButtonSelected === 'from' ? linkedFromControl : linkedToControl"
        [collapseable]="false"
        [maxDate]="dateButtonSelected === 'from' && linkedToControl?.value"
        [minDate]="dateButtonSelected === 'to' && linkedFromControl?.value"
        [highlightRange]="true"
      ></app-date-picker-2>
    </div>
  </app-context-menu-item>
</app-context-menu-item>

<app-context-menu-heading>Knots source:</app-context-menu-heading>

<app-context-menu-item
  [withCheckOption]="true"
  *ngFor="let source of sources"
  [title]="source.title"
  [active]="state?.filters?.source === source.key"
  (execute)="setSource(source.key)"
></app-context-menu-item>
