<div class="form">
  <div class="form-body">
    <div class="form-body-scroll-container">
      <div
        class="form-body-scroll"
        appScroll
        (appScrollTopReached)="scrollShadowTop = !$event"
        (appScrollBottomReached)="scrollShadowBottom = !$event"
      >
        <input
          class="form-body-title-input"
          placeholder="Untitled Rule"
          [formControl]="form.controls.title"
        >

        <div class="body-secondary form-body-active">
          Active
          <app-toggle-switch
            class="form-body-active-toggle"
            [inputFormControl]="form.controls.active"
          ></app-toggle-switch>
        </div>

        <div class="form-body-condition">
          <div class="form-body-condition-line"></div>

          <app-button
            class="form-body-condition-button"
            label="IF"
            appearance="purple"
            size="small"
          />

          <div class="form-body-condition-line"></div>
        </div>

        <app-dropdown-input
          class="form-body-stitch"
          [inputFormControl]="form.controls.stitchType"
          placeholder="Stitch Type"
          [options]="stitchTypeOptions"
          appearance="amethyst-simple"
          [invertedColor]="true"
        ></app-dropdown-input>

        <ng-container
          *ngFor="let input of currentSchema | filterBy : 'condition' : 'if' : 'active' : true"
          [ngTemplateOutlet]="inputTemplate"
          [ngTemplateOutletContext]="{ input }"
        ></ng-container>

        <div
          *ngIf="currentSchema | hasOne : 'condition' : 'and' : 'active' : true"
          class="form-body-condition"
        >
          <div class="form-body-condition-line"></div>

          <app-button
            class="form-body-condition-button"
            label="AND"
            appearance="purple"
            size="small"
          />

          <div class="form-body-condition-line"></div>
        </div>

        <ng-container
          *ngFor="let input of currentSchema | filterBy : 'condition' : 'and' : 'active' : true"
          [ngTemplateOutlet]="inputTemplate"
          [ngTemplateOutletContext]="{ input }"
        ></ng-container>

        <div
          *ngIf="currentSchema | hasOne : 'condition' : 'then' : 'active' : true"
          class="form-body-condition"
        >
          <div class="form-body-condition-line"></div>

          <app-button
            class="form-body-condition-button"
            label="THEN"
            appearance="purple"
            size="small"
          />

          <div class="form-body-condition-line"></div>
        </div>

        <ng-container
          *ngFor="let input of currentSchema | filterBy : 'condition' : 'then' : 'active' : true"
          [ngTemplateOutlet]="inputTemplate"
          [ngTemplateOutletContext]="{ input }"
        ></ng-container>

        <div
          *ngIf="!form.controls.stitchType.value"
          class="form-body-placeholder body-primary"
        >
          Automate your daily processes with 'If this - <br/> Then that' workflow automation rules.
        </div>
      </div>

      <div class="shadow" *ngIf="scrollShadowTop"></div>
      <div class="shadow bottom" *ngIf="scrollShadowBottom"></div>
    </div>

    <div class="form-body-buttons">
      <app-button
        class="form-body-buttons-item"
        label="CANCEL"
        appearance="purple-outline"
        (click)="handleCancel()"
      />

      <app-button
        class="form-body-buttons-item"
        label="SAVE"
        appearance="purple"
        (click)="handleSubmit()"
      />
    </div>
  </div>

  <div class="form-sidebar">
    <app-tabs
      class="form-sidebar-tabs"
      [values]="tabs"
      [(value)]="selectedTab"
      appearance="buttongroup"
    ></app-tabs>

    <div
      *ngFor="let input of currentSchema | filterBy : 'condition' : selectedTab"
      class="form-sidebar-button"
      [class.active]="input.active"
      (click)="handleClickInput(input)"
    >
      {{ input.name }}
    </div>
  </div>
</div>

<ng-template #inputTemplate let-input="input">
  <ng-container [ngSwitch]="input.type">
    <app-autocomplete-multiple-input
      *ngSwitchCase="'autocomplete-multiple'"
      appearance="amethyst"
      class="form-body-input"
      [title]="input.name"
      [suggestions]="input.suggestions"
      [inputFormControl]="input.control"
      [itemStyles]="input.itemStyles"
      [itemTitlePattern]="input.itemTitlePattern"
      [icon]="input.itemIcon"
      [withClear]="true"
      [useInputText]="input.useInputText"
      [useInputTextGetValue]="input.useInputTextGetValue"
      [invertedColor]="true"
      [sideOptions]="input.sideOptions"
    ></app-autocomplete-multiple-input>

    <app-input
      *ngSwitchCase="'text'"
      appearance="amethyst"
      class="form-body-input"
      [label]="input.name"
      [inputFormControl]="input.control"
      [withClear]="true"
      [invertedColor]="true"
    ></app-input>

    <div
      *ngSwitchCase="'date-range'"
      class="form-body-input input-date-range"
    >
      <app-date-input
        class="input-date-range-item"
        appearance="amethyst"
        [inputFormControl]="input.control.controls.from"
        [placeholder]="input.fromLabel"
        [withClear]="true"
        [invertedColor]="true"
        [maxDate]="input.control.controls.to.value"
      ></app-date-input>

      <app-date-input
        class="input-date-range-item"
        appearance="amethyst"
        [inputFormControl]="input.control.controls.to"
        [placeholder]="input.toLabel"
        [withClear]="true"
        [invertedColor]="true"
        [minDate]="input.control.controls.from.value"
      ></app-date-input>
    </div>

    <app-dropdown-input
      *ngSwitchCase="'select'"
      appearance="amethyst"
      class="form-body-input"
      [inputFormControl]="input.control"
      [placeholder]="input.name"
      [invertedColor]="true"
      [withClear]="true"
      [options]="input.options"
    ></app-dropdown-input>

    <app-autocomplete-input
      *ngSwitchCase="'autocomplete'"
      appearance="amethyst"
      class="form-body-input"
      [label]="input.name"
      [suggestions]="input.suggestions"
      [inputFormControl]="input.control"
      [leftIcon]="input.itemIcon"
      [withClear]="true"
      [invertedColor]="true"
    ></app-autocomplete-input>

    <app-dropdown-multiple-input
      *ngSwitchCase="'priority'"
      class="form-body-input"
      appearance="amethyst"
      [invertedColor]="true"
      [inputFormControl]="input.control"
      [options]="input.options"
      [placeholder]="input.name"
      [itemStyles]="input.itemStyles"
    ></app-dropdown-multiple-input>

    <app-country-picker
      *ngSwitchCase="'country'"
      class="form-body-input"
      appearance="amethyst"
      [inputFormControl]="input.control"
      [label]="input.name"
    ></app-country-picker>
  </ng-container>
</ng-template>
