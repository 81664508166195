import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

const mb = 1048576;

export const commonFileSizes: DropdownSelectItem<{from?: number, to?: number}>[] = [
  { title: '< 1 MB', value: { from: null, to: mb }},
  { title: '> 1 MB', value: { from: mb, to: null }},
  { title: '< 5 MB', value: { from: null, to: 5 * mb }},
  { title: '< 10 MB', value: { from: null, to: 10 * mb }},
  { title: '> 10 MB', value: { from: 10 * mb, to: null }},
  { title: '< 25 MB', value: { from: null, to: 25 * mb }},
  { title: '> 25 MB', value: { from: 25 * mb, to: null }},
  { title: '< 50 MB', value: { from: null, to: 50 * mb }},
  { title: '> 50 MB', value: { from: 50 * mb, to: null }},
  { title: '< 100 MB', value: { from: null, to: 100 * mb }},
  { title: '> 100 MB', value: { from: 100 * mb, to: null }},
];
