import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: string | number | Date, relative = true, withTime = true, relativeOnly = false): string {
    const messageDate = new Date(value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const dateDelta = (new Date(messageDate).setHours(0, 0, 0, 0) - currentDate.getTime()) / 86400000;

    let dateString: string;

    if (relative && dateDelta >= -1 && dateDelta <= 1) {
      dateString = ['Yesterday', 'Today', 'Tomorrow'][dateDelta + 1] +
        (withTime ? ` at ${this.datePipe.transform(messageDate, 'h:mmaaaaa\'m\'')}` : '');
    } else if (relativeOnly) {
      return '';
    } else if (messageDate.getFullYear() === currentDate.getFullYear()) {
      dateString = this.datePipe.transform(messageDate, (withTime) ? 'MMM d, H:mm' : 'MMM, d');
    } else {
      dateString = this.datePipe.transform(messageDate, withTime ? 'MMM, d, y h:mmaaaaa\'m\'' : 'MMM, d, y');
    }

    return dateString;
  }

}
