// Common
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { ActivatedRoute } from '@angular/router';
import { StitchType } from '@modules/common/types/stitch-type';

@Component({
  template: ''
})
export abstract class SharedBaseComponent<T extends Stitch> implements OnInit, OnDestroy {
  protected alive = new Subject();
  protected stitchType: StitchType;
  protected stitchItem: T;

  private route: ActivatedRoute;
  private stitchServiceFactory: StitchServiceFactory

  constructor (
    protected injector: Injector,
  ) {
    this.route = this.injector.get(ActivatedRoute);
    this.stitchServiceFactory = this.injector.get(StitchServiceFactory)
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.route.params
      .pipe(
        map(params => params['id']),
        switchMap(id =>
          this.stitchServiceFactory.getServiceByStitchType(this.stitchType).getSharedItem(id)
        ),
        takeUntil(this.alive)
      )
      .subscribe(item => {
        this.stitchItem = item as T;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
