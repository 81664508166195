<div
  class="circular-progress"
  [ngClass]="priority"
  [style.width.px]="size"
  [style.height.px]="size"
  (click)="onClick.emit(); $event.stopPropagation()"
>
  <svg [attr.viewBox]="'0 0 ' + viewBoxSize + ' ' + viewBoxSize">
    <g fill="none" fill-rule="nonzero">
      <circle
        class="back"
        [attr.r]="radius"
        [attr.cx]="viewBoxSize / 2"
        [attr.cy]="viewBoxSize / 2"
        [attr.stroke-width]="1.5"
      ></circle>

      <circle
        [attr.r]="radius"
        [attr.cx]="viewBoxSize / 2"
        [attr.cy]="viewBoxSize / 2"
        [attr.stroke-width]="1.5"
        [attr.stroke-dasharray]="strokeDashArray"
        stroke-dashoffset="0"
      ></circle>

      <path
        *ngIf="checkmark && completed"
        d="M11.5 13.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708l1.646 1.647z"
      />
    </g>
  </svg>
</div>
