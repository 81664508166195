import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateTimeDisplay'
})
export class DateTimeDisplayPipe implements PipeTransform {

  transform(date: Date, time: Date, placeholder = 'No Date Specified', allDay = false): string {
    const datePipe = new DatePipe('en-us');

    if (!date && !time) { return placeholder; }
    if (allDay) { return 'All day'; }

    let result = '';
    result += datePipe.transform(date, 'MMM d');
    result += date && time ? ', ' : '';
    result += time ? datePipe.transform(time, 'h:mm a').toLowerCase() : '';
    return result;
  }

}
