// Common
import { Component, Injector, Input, OnInit } from '@angular/core';

// RX
import { of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { Calendar } from '@modules/calendar-app/types/calendar';
import { DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Tab } from '@modules/common/types/tab';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';

// Services
import { EventsService } from '@modules/calendar-app/services/events.service';
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';

// Components
import { FullFormBaseComponent } from '@modules/common/components/full-form-base/full-form-base.component';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.less']
})
export class EventFormComponent extends FullFormBaseComponent<CalendarEvent> implements OnInit {

  public changesKey = 'event';
  public conferencingOptions = [
    {title: 'Hangouts', value: 'hangouts', icon: 'social-hangouts'},
    {title: 'Skype', value: 'skype', icon: 'social-skype'},
    {title: 'Slack', value: 'slack', icon: 'social-slack'},
    {title: 'Zoom', value: 'zoom', icon: 'social-zoom'},
    {title: 'No Conferencing', value: 'none', icon: 'social-no-conf'},
  ];
  public calendar: Calendar;
  tabs: Tab[] = [
    { title: 'Event', value: 'event'},
    { title: 'Attachments', value: 'attachments'},
    { title: 'Stitch', value: 'stitch'},
    { title: 'Lab', value: 'lab'},
    { title: 'Comments', value: 'comments'},
  ];
  tabsStateKey = 'ffEvent';
  public suggestions: AutocompleteFactory<CalendarEvent>;

  protected dragDataType = DragDataTypes.event;

  @Input() event = new CalendarEvent();

  constructor(
    private calendarsService: CalendarsService,
    private eventsService: EventsService,
    calendarAppStateService: CalendarAppStateService,
    injector: Injector,
  ) {
    super(injector, eventsService, calendarAppStateService);

    this.calendarsService.search()
      .pipe(takeUntil(this.alive))
      .subscribe(({ items: calendars }) => {
        this.suggestions = (title, values) => of(
          calendars
            .filter(calendar => !title || calendar.title.includes(title))
            .filter(calendar => !values || values.includes(calendar.id))
            .map((calendar: Calendar) => ({
              title: calendar.title,
              value: calendar.id
            }))
        );
        this.setDefaultCalendar();
        this.setCurrentCalendar();
      });
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();
    this.setDefaultCalendar();
    this.setCurrentCalendar();
  }

  /**
   * Actions
   */

  get stitchItem(): CalendarEvent {
    return this.event;
  }

  handleSubmit(emitListUpdate, shouldSetId) {
    !this.form.get('color').value && this.calendar?.color
    && this.form.setValue({ color: this.calendar?.color }, { emitEvent: false });

    super.handleSubmit(emitListUpdate, shouldSetId);
  }

  mail() {
    // TODO account type
    // this.modalService.compose(MailMessage.fromEvent(this.event));
  }

  /**
   * Helpers
   */

  private setDefaultCalendar() {
    // if (!this.form.controls['calendarId'].value) {
    //   const writableCalendar = this.calendars.find(calendar => !calendar.readOnly);
    //   if (writableCalendar) {
    //     this.form.patchValue({ calendarId: writableCalendar.id });
    //   }
    // }
  }

  private setCurrentCalendar(): void {
    // this.calendar = this.calendars.find(calendar => calendar.id === this.form.controls['calendarId'].value);
  }

  /**
   * Helpers
   */

  protected shouldRefreshList(prev, current) {
    return CalendarEvent.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): CalendarEvent {
    return CalendarEvent.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return this.event.asFormGroup();
  }
}
