// Common
import { Directive, Input, OnDestroy, Output, EventEmitter, OnInit, Renderer2, ElementRef, NgZone, Inject } from '@angular/core';
import { SPACE_ID } from '@modules/common/injection-tokens/space-id.injection-token';

// Services
import { DragNDropService } from '../services/drag-n-drop.service';

// RX
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

// Types
import { DragData } from '../types/drag-data';
import { DroppableAreaTrackingStrategy } from '../types/droppable-area-tracking-strategy';

@Directive({
  selector: '[appDroppable]',
})
export class DroppableDirective implements OnInit, OnDestroy {

  private alive: Subject<void> = new Subject();
  private isDragging = false;
  private uniqueID: symbol;
  private dragData: DragData;

  @Input() appDroppableHoverClass: string;
  @Input() appDroppableDisabled: boolean;
  @Input() appDroppablePredicate: (data: DragData) => boolean;
  @Input() appDroppableZIndex = 0;
  @Input() appDroppableNoReposition: boolean;
  @Input() appDroppableTrackingStrategy: DroppableAreaTrackingStrategy = 'mousePointer';

  @Output() appDroppableDrop = new EventEmitter<DragData>();
  @Output() appDroppableEnter = new EventEmitter<DragData>();
  @Output() appDroppableLeave = new EventEmitter<void>();

  constructor(
    private dragNDropService: DragNDropService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private ngZone: NgZone,
    @Inject(SPACE_ID) private spaceId: BehaviorSubject<string>,
  ) {
    this.uniqueID = Symbol();
    this.appDroppablePredicate = () => true;
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.dragNDropService.getDraggingDataChanges()
      .pipe(
        filter(() => !this.appDroppableDisabled),
        takeUntil(this.alive)
      )
      .subscribe((data: DragData) => {
        this.dragData = data;
        this.isDragging = !!data;

        if (this.isDragging) {
          if (this.appDroppableDisabled) { return; }

          this.dragNDropService.registerDroppableArea(
            this.uniqueID,
            this.elementRef.nativeElement,
            this.appDroppableZIndex,
            this.appDroppableNoReposition,
            this.validatePredicate(),
            this.appDroppableTrackingStrategy
          );
        } else {
          this.dragNDropService.unregisterDroppableArea(this.uniqueID);
        }
      });

    this.dragNDropService.getDragEnter(this.uniqueID)
      .pipe(
        filter(() => this.validatePredicate()),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.ngZone.run(() => {
          if (this.appDroppableHoverClass) {
            this.renderer.addClass(this.elementRef.nativeElement, this.appDroppableHoverClass);
          }
          if (this.dragData) {
            this.appDroppableEnter.emit(this.dragData);
          }
        });
      });

    this.dragNDropService.getDragLeave(this.uniqueID)
      .pipe(
        filter(() => this.validatePredicate()),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.ngZone.run(() => {
          if (this.appDroppableHoverClass) {
            this.renderer.removeClass(this.elementRef.nativeElement, this.appDroppableHoverClass);
          }

          this.appDroppableLeave.emit();
        });
      });

    this.dragNDropService.getDrop(this.uniqueID)
      .pipe(
        filter(() => this.validatePredicate()),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.appDroppableDrop.emit(this.dragData);
      });
  }

  ngOnDestroy(): void {
    this.dragNDropService.unregisterDroppableArea(this.uniqueID);
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  private validatePredicate() {
    if (this.dragNDropService.getCurrentSpaceId() !== this.spaceId.getValue()) { return false; } 

    return this.appDroppablePredicate ? this.appDroppablePredicate(this.dragData) : true;
  }
}
