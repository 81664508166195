// Types
import { CollapsedState } from './collapsed-state';

export class GlobalState {

  // Settings
  isTextEditorToolbarExpanded?: boolean;
  collapsed?: CollapsedState;

  constructor(jsonObject: object = {}) {
    // Setting
    this.isTextEditorToolbarExpanded = jsonObject['isTextEditorToolbarExpanded'],
    this.collapsed = jsonObject['collapsed'] || {};
  }
}
