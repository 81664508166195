<div class="row">
  <div
    *ngIf="row"
    class="row-title"
    [appRowContextMenu]="row"
  >
    <input
      class="row-title-input"
      (blur)="handleBlur()"
      placeholder="Enter row title"
      [formControl]="rowForm.controls['title']"
    >

    <div class="row-title-hover-icons">
      <mat-icon
        svgIcon="up"
        class="row-title-hover-icons-icon"
        matTooltip="Move Up"
        (click)="moveUp()"
      ></mat-icon>

      <mat-icon
        svgIcon="down"
        class="row-title-hover-icons-icon"
        matTooltip="Move Down"
        (click)="moveDown()"
      ></mat-icon>

      <mat-icon
        svgIcon="trash"
        class="row-title-hover-icons-icon"
        (click)="delete()"
        [matTooltip]="'Delete Permanently'"
      ></mat-icon>
    </div>

    <div class="row-title-icons">
      <mat-icon
        svgIcon="dropdown-down"
        class="row-title-icon"
        [ngClass]="{collapsed: collapsed}"
        (click)="toggleCollapse()"
      ></mat-icon>
    </div>
  </div>

  <div
    class="row-collapseable-body"
    [ngClass]="{ collapsed: collapsed }"
    [@heightAnimation]="collapsed ? 'hide' : 'show'"
  >
    <div class="row-collapseable-body-content">
      <app-project-board-column
        *ngFor="let column of columns"
        [appearance]="appearance"
        [column]="column"
        [row]="row"
      ></app-project-board-column>
    </div>
  </div>
</div>
