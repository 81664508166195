<div class="heading">
  <div class="heading-grow">
    <div class="heading1">Automation Rules</div>
    <div class="heading2-subtitle">Stitch Rules allow you to automate your workflow.</div>
  </div>

  <app-button
    icon="plus-medium"
    label="ADD RULE"
    appearance="link-blue"
    (click)="openForm()"
  />
</div>


<app-tabs
  class="tabs"
  [values]="tabs"
  [value]="selectedTab"
  (valueChange)="handleSelectTab($event)"
></app-tabs>

<div class="items">
  <div
    *ngFor="let rule of rules"
    class="item"
  >
    <div class="item-title">{{ rule.title }}</div>
    <div class="item-text">{{ rule.lastRunAt ? 'Last run ' + (rule.lastRunAt | timeAgo) : 'Rule has never run' }}</div>
    <div class="item-text">Updated on {{ rule.updatedAt | date : 'mediumDate' }}</div>
    <div class="item-text active">
      {{ rule.active ? 'Active' : 'Inactive' }}
      <app-toggle-switch
        class="item-toggle"
        [value]="rule.active"
        (onChange)="handleActiveChange($event, rule)"
      ></app-toggle-switch>
    </div>

    <mat-icon
      class="item-icon edit"
      svgIcon="edit"
      (click)="openForm(rule)"
    ></mat-icon>

    <mat-icon
      class="item-icon"
      svgIcon="trash"
      (click)="removeRulePrompt(rule)"
    ></mat-icon>
  </div>
</div>
