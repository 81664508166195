// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { warmUpObservable } from '@decorators';

// RxJS
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Types
import { Session } from '@modules/account/types/session';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { BaseService } from '@modules/common/services/base.service';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { Filters } from '@modules/common/types/filters';

@Injectable()
export class SessionsService extends BaseService {
  constructor (
    private http: HttpClient
  ) { super(); }

  search(filters?: Filters, config?: object): Observable<BaseSearchResponse<Session>> {
    return this.refreshRequired
      .pipe(
        switchMap(() => this.http.get<{ items: object[], count: number }>(environment.baseUrl + '/api/account/sessions')),
        map(({ items = [], count }) => ({
          items: items.map(item => new Session(item)),
          count
        }))
      );
  }

  @warmUpObservable
  destroySession(session) {
    return this.http.delete(environment.baseUrl + '/api/account/sessions/' + session.id)
      .pipe(tap(() => this.refreshRequired.next(0)));
  }

  @warmUpObservable
  destroyOtherSessions() {
    return this.http.delete(environment.baseUrl + '/api/account/sessions/logout-other-sessions')
      .pipe(tap(() => this.refreshRequired.next(0)));
  }

  create(instance: Session, feedbackConfig: FeedbackConfig): Observable<Session> {
    throw new Error('Method is not available');
  }
}
