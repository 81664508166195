<div
  *ngIf="task"
  class="task"
  [class.no-actions]="!withActions"
  [class.short-view]="viewType === 'short'"
  [class.pinned]="task.pinned"
  [class.with-description]="task.description"
  [appTaskContextMenu]="task"
  [appTaskContextMenuDisabled]="!withContextMenu"
  (appTaskContextMenuOpened)="contextMenuOpened = $event"

  appDraggable
  [appDraggableDisabled]="!withDraggable"
  [appDraggableData]="{data: [task], type: 'task'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppableDisabled]="!withDroppable"
  [appDroppableZIndex]="2"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="task-body">
    <div class="task-body-heading">

      <app-circular-progress
        progress="25"
        class="task-body-heading-progress"
      ></app-circular-progress>

      <div class="task-body-heading-title">
        {{task.title}}
      </div>

      <div class="task-body-heading-column">
        {{task.columnTitle}}
      </div>
    </div>

    <app-date-time
      class="task-body-date-time"
      [fromDate]="task.fromTime"
      [fromTime]="task.fromTime"
      [toDate]="task.toTime"
      [toTime]="task.toTime"
    ></app-date-time>

    <div class="task-body-description" *ngIf="task.description && viewType !== 'short'">
      {{task.description}}
    </div>

    <app-link-info-bar
      class="task-body-info-bar"
      *ngIf="viewType !== 'short'"
      [stitch]="task"
    ></app-link-info-bar>
  </div>

  <mat-icon class="task-pin-icon" *ngIf="task.pinned" svgIcon="pin-direct"></mat-icon>
  <mat-icon class="task-starred-icon" *ngIf="!task.starred" svgIcon="starred"></mat-icon>
  <mat-icon class="task-marker" svgIcon="task"></mat-icon>
  <mat-icon class="task-attachments-icon" *ngIf="task.files?.length" svgIcon="attachment"></mat-icon>

  <div
    class="task-hover-icons"
    *ngIf="withDraggable && withActions && !removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="flag"
      [class.active]="task.flagged"
      matTooltip="Flag"
      (click)="flag()"
    ></mat-icon>
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="pin"
      [class.active]="task.pinned"
      [matTooltip]="task?.pinned ? 'Unpin' : 'Pin'"
      (click)="pin()"
    ></mat-icon>
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="snooze"
      matTooltip="Coming soon"  
    ></mat-icon>
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="event"
      matTooltip="Coming soon"
    ></mat-icon>
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="archive"
      [class.active]="task.archived"
      [matTooltip]="task?.archived ? 'Remove From Archive' : 'Add To Archive'"
      (click)="archive()"
    ></mat-icon>
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="trash"
      [class.active]="task.deleted"
      [matTooltip]="task?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    ></mat-icon>
  </div>

  <div
    class="task-hover-icons"
    *ngIf="withActions && removeOnly"
    [appClickStopPropagation]="true"
  >
    <mat-icon
      class="task-hover-icons-item"
      svgIcon="trash"
      [class.active]="task.deleted"
      [matTooltip]="task?.deleted ? 'Delete Permanently' : 'Delete'"
      (click)="delete()"
    ></mat-icon>
  </div>

  <div class="task-bottom-container">
    <mat-icon
      *ngIf="withUnlink"
      class="task-bottom-container-unlink"
      svgIcon="unlink"
      matTooltip="Unlink"
      (click)="unlinkItem($event)"
    ></mat-icon>
  </div>

  <div class="drop-zone"></div>
</div>

<div
  class="task placeholder"
  *ngIf="!task"
  [class.short-view]="viewType === 'short'"
>
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
</div>

<ng-template #dragPlaceholder>
  <app-link-info-task
    [task]="task"
    [withDraggable]="false"
    [withContextMenu]="false"
    [withActions]="false"
    [viewType]="viewType"
  ></app-link-info-task>
</ng-template>
