<div
  *ngIf="!item"
  class="empty-state"
>
  <img class="empty-state-image" src="assets/placeholders/placeholder-contacts.svg"/>
</div>

<ng-container
  *ngIf="item"
  [ngSwitch]="item | stitchType"
>
  <app-contact-form
    *ngSwitchCase="StitchType.contact"
    [contact]="item"
    (close)="handleFormClose()"
  />

  <app-group-form
    *ngSwitchCase="StitchType.group"
    [group]="item"
    (close)="handleFormClose()"
  />
</ng-container>
