<div
  stchPopover
  [stchPopoverArrow]="true"
  [stchPopoverContent]="popoverTemplate"
  [stchPopoverInnerShadow]="true"
  stchPopoverPlacement="right"
  [stchPopoverDisabled]="!minimized"
  stchPopoverTrigger="click"
>
  <app-sidebar-item
    title="Folders"
    icon="sapphire-folders"
    [depth]="0"
    [expandable]="true"
    [expanded]="expanded"
    [minimized]="minimized"
    [active]="sidebarFilter === 'all_folders'"
    [actions]="[{
      icon: 'sapphire-plus',
      template: folderQuickFormTemplate,
      until: quickFormPopoverClose
    }]"
    (appClick)="selectFilter.emit('all_folders')"
    appSidebarContextMenu="all_folders"
  >
    <app-context-menu-state-indicator
      appearance="sapphire"
      svgIcon="sapphire-filters"
      appFoldersListContextMenu
      [appFoldersListContextMenuStateKey]="listStateKey"
      (appFoldersListContextMenuChange)="handleListStateChange($event)"
    />
  </app-sidebar-item>
</div>

<div
  *ngIf="!minimized"
  class="collapseable-body"
  [@heightAnimation]="(expanded | async) ? 'show' : 'hide'"
>
  <ng-container *ngTemplateOutlet="contentTemplate" />
</div>

<ng-template #popoverTemplate>
  <div
    *ngIf="minimized"
    class="popover-body"
  >
    <ng-container *ngTemplateOutlet="contentTemplate" />
  </div>
</ng-template>

<ng-template #contentTemplate>
  <app-side-bar-folders-tree [filters]="filters" />
</ng-template>

<ng-template #folderQuickFormTemplate let-parentId="parentId">
  <div class="folder-quick-form-container">
    <app-folder-quick-form
      [parentId]="parentId"
      (close)="quickFormPopoverClose.next()"
      (more)="handleMore($event)"
    />
  </div>
</ng-template>

<ng-template #placeholder let-item="item">
  <app-sidebar-item [title]="item.title" />
</ng-template>
