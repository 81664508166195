// Common
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { isNil } from 'lodash';

// Types
import { LabelType, Options } from 'ngx-slider-v2';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.less'],
})
export class SliderComponent implements OnInit, OnChanges {

  @Input() fromControl: UntypedFormControl;
  @Input() toControl: UntypedFormControl;
  @Input() fromLabel: string;
  @Input() toLabel: string;
  @Input() floor: number;
  @Input() ceil: number;
  @Input() step: number;
  @Input() leftOutColor = '#EBEFF5';
  @Input() color = '#656da4a7';

  public sliderOptions: Options = {
    floor: 0,
    ceil: 100,
    step: 1,
    showOuterSelectionBars: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return this.fromLabel ? this.fromLabel.replace('$value', value.toString()) : value.toString();
        case LabelType.High:
          return this.toLabel ? this.toLabel.replace('$value', value.toString()) : value.toString();
        default:
          return value.toString();
      }
    }
  };

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.sliderOptions.floor = this.floor;
    this.sliderOptions.ceil = this.ceil;
    this.sliderOptions.step = this.step;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('floor' in changes && isNil(this.floor)) {
      this.sliderOptions.floor = this.floor;
    }

    if ('ceil' in changes && isNil(this.ceil)) {
      this.sliderOptions.ceil = this.ceil;
    }

    if ('step' in changes && isNil(this.step)) {
      this.sliderOptions.step = this.step;
    }
  }

  /**
   * Actions
   */

  setScoreValue(range) {
    const { value, highValue } = range;

    this.fromControl?.setValue?.(value);
    this.toControl?.setValue?.(highValue);
  }
}
