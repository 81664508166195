// Common
import { Component, Output, EventEmitter, Input } from '@angular/core';

// Types
import { VirtualFolder } from '@modules/calendar-app/types/virtual-folder';

@Component({
  selector: 'app-events-list-context-menu',
  templateUrl: './events-context-menu.component.html',
  styleUrls: ['./events-context-menu.component.less']
})
export class EventsContextMenuComponent {

  @Input() filterKey: VirtualFolder;

  @Output() change: EventEmitter<VirtualFolder> = new EventEmitter();

  /**
   * Actions
   */

  selectFilter(filter: VirtualFolder) {
    this.change.emit(filter);
  }
}
