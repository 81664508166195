// Common
import { Injectable, Optional} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { warmUpObservable } from '@decorators';
import { environment } from '@environment';

// RX
import { Observable, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Types
import { Like } from '@modules/common/types/like';
import { File } from '../types/file';
import { FilesFilters } from '../types/files-filters';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { StitchType } from '@modules/common/types/stitch-type';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { BunchUpdateOptions } from '@modules/common/types/bunch-update-options';
import { BunchCreateFilesOptions } from '@modules/common/types/bunch-create-files-options';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';
import { BaseStitchChildService } from '@modules/common/services/base-stitch-child.service';
import { StitchService } from '@modules/common/services/stitch.service';

@Injectable()
export class FilesService extends BaseStitchChildService<File, FilesFilters> implements CalendarEventsService {
  protected url = environment.baseUrl + '/api/files/files/';
  protected stitchType = StitchType.file;
  protected filtersConstructor = FilesFilters;

  constructor(
    protected http: HttpClient,
    protected toasterService: ToasterService,
    @Optional() linkedInfoService: LinkedInfoService,
    @Optional() knotsService: KnotsService,
    @Optional() tagsService: TagsService,
    @Optional() stitchService: StitchService,
  ) {
    super(http, toasterService, stitchService, tagsService, knotsService, linkedInfoService);
  }

  /**
   * Methods
   */

  create(
    fileInstance: File,
    { emit, toast, message }: FeedbackConfig = { emit: true }
  ): Observable<File> {
    return this.http.post<{ file: File, success: boolean }>(
      `${environment.baseUrl}/api/files/files`,
      fileInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }
          emit && this.forceRefresh();
          toast && this.toasterService.show({ text: message || 'File created.' });
        }),
        map(({ file }) => new File(file)),
        switchMap(file => this.processKnowledgeItems(file)),
        catchError(error => this.handleObserverError(error))
      );
  }

  bunchCreate(
    files: File[],
    { other, parentFolderName }: BunchCreateFilesOptions = {},
    { emit, toast, message }: FeedbackConfig = { emit: true }
  ): Observable<File[]> {
    return this.http.post<{ items: File[], success: boolean }>(
      `${environment.baseUrl}/api/files/files/bulk`,
      {
        files: files.map(file => file.asPayloadJSON()),
        other,
        folderName: parentFolderName
      }
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }

          emit && this.forceRefresh();
          toast && this.toasterService.show({ text: message || 'Files created' });
        }),
        map(({ items }) => items.map(this.createInstance))
      );
  }

  @warmUpObservable
  update(
    fileInstance: File,
    { emitUpdate, displayToast, toastMessage }: FeedbackConfig = { emitUpdate: true }
  ): Observable<File> {
    return this.http.put<{ file: File, success: boolean }>
    (environment.baseUrl + '/api/files/files/' + fileInstance.id, fileInstance.asPayloadJSON())
      .pipe(
        tap(({ file, success }) => {
          if (success) {
            emitUpdate && this.forceRefresh();
            displayToast && this.toasterService.show({ text: toastMessage || 'File updated' });
          }
        }),
        map(({ file }) => file),
        catchError(error => this.handleObserverError(error)),
      );
  }

  bunchUpdate(
    filters: Partial<FilesFilters>,
    changes: BunchUpdateOptions & Partial<Pick<File, 'folderId'>>,
    config: FeedbackConfig
  ): Observable<void> {
    return super.bunchUpdate(filters, changes, config);
  }

  @warmUpObservable
  duplicate(ids: string[], toastMessage?: string, displayToast = true, emitChanges = true): Observable<any> {
    return this.http.post<{ success: boolean, files: File[] }>
    (environment.baseUrl + '/api/files/files/duplicate/', { ids })
      .pipe(
        map(({ success }) => {
          if (success) {
            emitChanges && this.forceRefresh();
            displayToast && this.toasterService.show({
              text: toastMessage || `File${ ids?.length === 1 ? '' : 's' } successfully duplicated` });
          }
        })
      );
  }

  getCalendarEvents(fromTime: Date, toTime: Date): Observable<AngularCalendarEvent[]> {
    return this.search({ fromTime, toTime })
      .pipe(
        map(({ items }) => items.map(file => file.asAngularCalendarEvent()))
      );
  }

  getAutocompleteSuggestions(inputFilters: Like<FilesFilters> = {}): AutocompleteFactory<File> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      const filters: Like<FilesFilters> = {
        limit: config?.limit || 5,
        archived: false,
        deleted: false,
        ...inputFilters
      };

      if (values?.length) {
        filters.ids = values;
      } else if (title?.trim()) {
        filters.query = title.trim();
        filters.esAnalyzer = 'ngrams_2_12';
        filters.esPriority = 'title';
        filters.esMultiMatchType = 'best_fields';
      }

      return this.search(filters)
        .pipe(
          map(({ items }) => items.map(file => ({
            title: file.title,
            value: file.id,
            source: file
          }))),
        );
    };
  }

  createInstance(item): File {
    return new File(item);
  }

  print(file: File) {
    if (!file) { return of(); }

    if (!['application/pdf'].includes(file.attachmentType)) { return of(); }

    return this.http.get(
      `${environment.baseUrl}/api/attachments/${file.id}/${file.id}/${file.attachmentName}`,
      { responseType: 'blob' }
    ).pipe(
      tap(response => {
        var blob = new Blob([response], {type: 'application/pdf'});
        const blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
      })
    );
  }
}
