// Common
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { addDays } from '@modules/common/utils/date';
import { FileValidators } from '@modules/form-controls/validators/file.validators';

// Utils
import { asHtml } from '@modules/common/utils/editor';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { Participant } from '@modules/messages/types/participant';
import { SendLater } from './send-later';
import { Upload } from '@modules/common/types/upload';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { Note } from '@modules/notes/types/note';
import { Contact } from '@modules/contacts/types/contact';
import { Signature } from '@modules/account/types/signature';
import { MessageTemplate } from '@modules/account/types/message-template';
import { VirtualFolder } from '@modules/messages/types/virtual-folder';
import { Like } from '@modules/common/types/like';

export class Message extends Stitch {
  attachmentsCount: number;
  bcc: Participant[];
  bodyHtml: string;
  bodyText: string;
  bulk: boolean;
  cc: Participant[];
  date: Date;
  draft: boolean;
  emlFileName: string;
  emlSize: number;
  folderId: string;
  forwardOf: string;
  from: Participant[];
  notifyIfRead: boolean;
  raw: string;
  replied: boolean;
  replyTo: string;
  scheduled: SendLater;
  sent: boolean;
  snippet: string;
  spam: boolean;
  subject: string;
  to: Participant[];
  unread: boolean;
  uploads: Upload[];

  noSend?: boolean;

  constructor(data: any = {}) {
    super(data);

    if (data instanceof Stitch) {
      this.fillFromStitch(data);
    } else {
      this.attachmentsCount = data.attachmentsCount;
      this.bcc = data.bcc;
      this.bodyHtml = data.bodyHtml;
      this.bodyText = data.bodyText;
      this.bulk = !!data.bulk;
      this.cc = data.cc;
      this.date = new Date(data.date);
      this.draft = !!data.draft;
      this.sent = !!data.sent;
      this.emlFileName = data.emlFileName;
      this.emlSize = data.emlSize;
      this.folderId = data.folderId;
      this.forwardOf = data.forwardOf;
      this.from = data.from;
      this.notifyIfRead = data.notifyIfRead;
      this.raw = data.raw;
      this.replyTo = data.replyTo;
      this.replied = data.replied;
      this.scheduled = data.scheduled;
      this.snippet = data.snippet;
      this.spam = !!data.spam;
      this.subject = data.subject;
      this.title = data.subject;
      this.to = data.to;
      this.unread = data.unread;
      this.uploads = data.uploads;
      this.noSend = data.noSend;
    }
  }

  static fromFormGroup(form: UntypedFormGroup): Message {
    const formValues = form.value;

    return new Message({
      bcc: formValues.bcc,
      bodyHtml: formValues.bodyHtml,
      bodyText: formValues.bodyText,
      bulk: formValues.bulk,
      cc: formValues.cc,
      color: formValues.color,
      connections: formValues.connections || [],
      draft: formValues.draft,
      followed: formValues.followed,
      forwardOf: formValues.forwardOf,
      folderId: formValues.folderId,
      id: formValues.id,
      knots: formValues.knots,
      linkedInfo: formValues.linkedInfo || [],
      notifyIfRead: formValues.notifyIfRead,
      participants: formValues.participants || [],
      replyTo: formValues.replyTo,
      replied: formValues.replied,
      scheduled: formValues.scheduled,
      snoozed: formValues.snoozed,
      spam: formValues.spam,
      subject: formValues.subject,
      tags: formValues.tags || [],
      to: formValues.to,
      uploads: formValues.uploads || [],
      noSend: formValues.noSend,
    });
  }

  static fromDragData(dragItem: DragData): Message {
    return <Message>super.fromDragData(dragItem);
  }

  static fromAdvancedState(filters: AdvancedSearchState, folder: VirtualFolder): Message {
    return new Message({
      title: filters.query || filters.mail.subject,
      tags: filters.tags,
      knots: filters.knots,
      body: filters.mail.body,
      folderId: filters.mail.containersIds?.[0],
      flagged: folder === 'flagged',
      archived: folder === 'archived',
      deleted: folder === 'deleted'
    });
  }

  static shouldRefreshList(prev, current) {
    return super.shouldRefreshList(prev, current, []);
  }

  static formatSignatureTemplate(bodyHtmlControl: AbstractControl, template: Signature | MessageTemplate): void {
    const regExp = new RegExp(template instanceof Signature
      ? /^<!--signature-->\s*[^]*<!--endSignature-->/gm
      : /^<!--template-->\s*[^]*<!--endTemplate-->/gm
    );

    if (regExp.test(bodyHtmlControl.value)) {
      bodyHtmlControl.setValue(bodyHtmlControl.value.replace(regExp, '\n' + template.content));
    } else {
      bodyHtmlControl.setValue(bodyHtmlControl.value + '\n' + template.content);
    }
  }

  static getChangesForVirtualFolder(sidebar: VirtualFolder): { changes: Like<Message>, message: string } {
    let message = 'Successfully moved to ';

    switch (sidebar) {
      case 'all_messages':
        return { changes: { folderId: null }, message: message += 'Orphan' };
      case 'all_folders':
        break;
      case 'bulk':
        return { changes: { bulk: true }, message: message += 'Bulk' };
      case 'draft':
        return { changes: { draft: true }, message: message += 'Draft' };
      case 'sent':
        break;
      case 'spam':
        return { changes: { spam: true }, message: message += 'Spam' };
      case 'scheduled':
        return { changes: { scheduled: 'endOfDay' }, message: message += 'Scheduled' };
      case 'archived':
      case 'deleted':
      case 'followed':
      case 'snoozed':
      case 'flagged':
        return super.getChangesForVirtualFolder(sidebar);
      default:
        checkExhaustiveness(sidebar);
    }
  }

  getStitchType(): StitchType {
    return StitchType.message;
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      bcc: [this.bcc || []],
      bodyHtml: [this.bodyHtml || ''],
      bodyText: [this.bodyText || ''],
      bulk: [this.bulk],
      cc: [this.cc || []],
      color: [this.color],
      connections: [this.connections || []],
      draft: [this.draft],
      followed: [this.followed],
      folderId: [this.folderId],
      forwardOf: [this.forwardOf],
      id: [this.id],
      knots: [this.knots],
      linkedInfo: [this.linkedInfo || []],
      notifyIfRead: [this.notifyIfRead],
      replyTo: [this.replyTo],
      replied: [this.replied],
      scheduled: [this.scheduled],
      snoozed: [this.snoozed],
      spam: [this.spam],
      subject: [this.subject],
      tags: [this.tags || []],
      to: [this.to || []],
      uploads: [this.uploads || [], FileValidators.size(26214400)],
      noSend: [this.noSend]
    });
  }

  asPayloadJSON() {
    return {
      archived: this.archived,
      bcc: this.bcc,
      bodyHtml: this.bodyHtml,
      bodyText: this.bodyText,
      bulk: this.bulk,
      cc: this.cc,
      color: this.color,
      deleted: this.deleted,
      draft: this.draft,
      flagged: this.flagged,
      folderId: this.folderId,
      uid: this.id,
      followed: this.followed,
      forwardOf: this.forwardOf,
      notifyIfRead: this.notifyIfRead,
      replyTo: this.replyTo,
      replied: this.replied,
      scheduled: this.formatScheduled(this.scheduled),
      snoozed: this.snoozed,
      spam: this.spam,
      subject: this.subject,
      to: this.to,
      uploads: this.uploads?.map(({ id, name, size, type, copy, inline }) => ({ id, name, size, type, copy, inline })),
      noNotification: this.noNotification,
      noSend: this.noSend,
    };
  }

  asAngularCalendarEvent(): AngularCalendarEvent {
    return this.convertToAngularCalendarEvent(
      this.subject, this.date, this.date, this.date, this.date
    );
  }

  protected fillFromStitch(data: Stitch) {
    super.fillFromStitch(data);
    this.subject = data.title;
    this.bodyHtml = data.description;

    if (data instanceof Message) {
      this.bcc = data.bcc;
      this.bodyHtml = data.bodyHtml;
      this.cc = data.cc;
      this.folderId = data.folderId;
      this.from = data.from;
      this.notifyIfRead = data.notifyIfRead;
      this.subject = data.subject;
      this.to = data.to;
    } else if (data instanceof Contact) {
      this.subject = data.title;
      this.to = [{
        id: data.id,
        address: data.emails?.[0]?.value || 'no address',
        name: data.title
      }];
    } else if (data instanceof Note) {
      this.bodyHtml = asHtml(data.body);
    }
  }

  private formatScheduled(scheduled: SendLater) {
    if (!scheduled) { return; }

    if (scheduled instanceof Date) {
      return scheduled.getTime() - Date.now();
    } else if (typeof scheduled === 'number') {
      return scheduled;
    }

    let date = new Date();

    switch (scheduled) {
      case 'endOfDay':
        date.setHours(17);
        break;
      case 'tomorrow':
        date = addDays(date, 1);
        break;
    }

    return date.getTime() - Date.now();
  }
}
