// Common
import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, Self, Output, EventEmitter } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

// Types
import { Group } from '@modules/contacts/types/group';
import { Contact } from '@modules/contacts/types/contact';
import { Section } from '@modules/common/types/section';

// Services
import { SectionsListService } from '@modules/contacts/services/sections-list.service';
import { BaseSectionsListService } from '@modules/common/services/base-sections-list.service';

@Component({
  selector: 'app-group-sections',
  templateUrl: './group-sections.component.html',
  styleUrls: ['./group-sections.component.less'],
  providers: [{ provide: BaseSectionsListService, useClass: SectionsListService }]
})
export class GroupSectionsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() group: Group;

  @Output() clickItem = new EventEmitter<Contact>();
  @Output() dblClickItem = new EventEmitter<Contact>();

  public sections: Section[] = [];

  private alive: Subject<void> = new Subject();
  private groupChanged = new Subject<void>();

  constructor (
    @Self() private listService: BaseSectionsListService<Contact, Group>,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.listService.getSections()
      .pipe(takeUntil(this.alive))
      .subscribe(sections => {
        this.sections = sections;
      });

    this.groupChanged
      .pipe(
        filter(() => !!this.group),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.listService.setContainer(this.group);
      });

    this.groupChanged.next();
  }

  ngOnChanges (changes: SimpleChanges) {
    if ('group' in changes) {
      this.groupChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.listService.detach();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  addSection() {
    this.listService.addSection();
  }
}
