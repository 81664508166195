// Common
import { Component } from '@angular/core';

@Component({
  selector: 'app-context-menu-divider',
  templateUrl: './context-menu-divider.component.html',
  styleUrls: ['./context-menu-divider.component.less']
})
export class ContextMenuDividerComponent {

}
