<div
  class="connection"
  [class.drag-placeholder]="!dragEnabled"
  [class.selected]="selected"
  [class.with-remove]="withRemove"

  [stchConnectionContextMenu]="connection"
  [stchConnectionContextMenuWithDetach]="withDetach"
  [stchConnectionContextMenuDisabled]="!withControls"
  (stchConnectionContextMenuDelete)="handleDelete()"

  appDraggable
  [appDraggableDisabled]="!dragEnabled"
  [appDraggableData]="{data: [connection], type: 'connection'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  [appDraggableContainerAdjustFit]="true"
  [appDraggableContainerStyles]="{'border-radius': '20px', 'overflow': 'hidden'}"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  {{ connection?.name }}

  <mat-icon
    *ngIf="withRemove"
    class="connection-remove-icon"
    svgIcon="close"
    (click)="onRemove.emit()"
  ></mat-icon>
</div>

<ng-template #dragPlaceholder>
  <app-connection [connection]="connection" [dragEnabled]="false"> </app-connection>
</ng-template>
