import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[stchRepeat]'
})
export class RepeatDirective {
  @Input() set stchRepeat(count: number) {
    this.viewContainer.clear();

    for (let i = 1; i <= count; i++) {
      this.viewContainer.createEmbeddedView(this.templateRef, { $implicit: i });
    }
  }

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) { }
}
