<app-quick-form
  class="quick-form"
  [inline]="true"
>
  <app-file-quick-form
    [folder]="folder"
  ></app-file-quick-form>
</app-quick-form>

<div class="board">
  <div class="board-area">
    <app-section
      [container]="folder"
      [itemTemplate]="fileTemplate"
      dragDataType="file"
    ></app-section>

    <app-section
      *ngFor="let section of sections"
      [section]="section"
      [container]="folder"
      [itemTemplate]="fileTemplate"
      dragDataType="file"
      [maxDepth]="0"
    ></app-section>

    <div
      *ngIf="folder"
      class="board-area-text-button"
      (click)="addSection()"
    >
      + Add Section
    </div>
  </div>
</div>

<ng-template #fileTemplate
  let-file="item"
  let-depth="depth"
  let-index="index"
  let-position="position"
  let-expandable="expandable"
  let-expanded="expanded"
  let-hovered="hovered"
>
  <app-file
    [item]="file"
    [position]="position"
    (appClick)="clickItem.emit(file)"
    (appDblClick)="dblClickItem.emit(file)"
  ></app-file>
</ng-template>
