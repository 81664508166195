<app-user-avatar
  class="avatar"
  size="48"
  [contact]="{ name: message?.from?.[0]?.name, email: message?.from?.[0]?.address }"
  [usePopover]="false"
  [appParticipantContextMenu]="message?.from?.[0]"
  (click)="selectContact(message?.from?.[0], $event)"
/>

<div class="info">
  <div
    class="info-name"
    [appParticipantContextMenu]="message?.from?.[0]"
  >
    {{ message?.from?.[0] | formatParticipant }}
    <mat-icon
      *ngIf="message?.from?.[0]?.vip"
      svgIcon="crown"
      class="info-name-vip"
    />
  </div>

  <app-contacts-inline-list
    *ngIf="message?.to?.length"
    label="To:"
    [contacts]="message?.to"
  />

  <app-contacts-inline-list
    *ngIf="message?.cc?.length"
    label="CC:"
    [contacts]="message?.cc"
  />

  <app-contacts-inline-list
    *ngIf="message?.bcc?.length"
    label="BCC:"
    [contacts]="message?.bcc"
  />
</div>
