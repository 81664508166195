// Common
import { Component, Injector, Input } from '@angular/core';

// Types
import { TemporalExpression } from '@modules/common/types/temporal-expression';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';

// Services
import { ModalService } from '@modules/modal/services/modal.service';

@Component({
  selector: 'app-temporal-expression-context-menu',
  templateUrl: './temporal-expression-context-menu.component.html',
  styleUrls: ['./temporal-expression-context-menu.component.less']
})
export class TemporalExpressionContextMenuComponent {

  @Input() temporalExpression: TemporalExpression;

  constructor (
    private modalService: ModalService,
    private injector: Injector,
  ){}

  /**
   * Actions
   */

  createAppointment() {
    const item = new CalendarEvent({
      title: this.temporalExpression.sentence,
      allDay: false,
      startTime: this.temporalExpression.fromTime,
      endTime: this.temporalExpression.toTime,
    });
    this.modalService.openFullForm(item, this.injector);
  }
}
