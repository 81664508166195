<div class="restore-password">
  <div class="heading">Restore password</div>
  <img class="image" src="assets/logo/welcome.png" alt="img"/>
  <div class="heading3">Enter your new password</div>


  <app-input
    class="input"
    [inputFormControl]="passwordsForm.controls.password"
    placeholder="Enter password"
  />

  <app-input
    class="input"
    [inputFormControl]="passwordsForm.controls.passwordConfirmation"
    placeholder="Confirm password"
  />

  <app-button
    label="Restore"
    appearance="purple"
    (click)="handleSubmit()"
  />
</div>

