<div
  class="tag"
  [class.focused]="focused"
>
  <mat-icon
    *ngIf="tag?.pinned"
    class="tag-pin-icon"
    svgIcon="pin-small"
  ></mat-icon>

  <span class="tag-hash">#</span>

  <input
    *ngIf="tag"
    [(ngModel)]="tag.name"
    class="tag-input"
    (change)="handleChange()"
    (focus)="handleFocus()"
    (blur)="handleBlur()"
  >

  <mat-icon
    *ngIf="!focused"
    class="tag-icon"
    svgIcon="pin"
    [ngClass]="{active: tag?.pinned}"
    [matTooltip]="tag?.pinned ? 'Unpin' : 'Pin'"
    (click)="handlePin()"
  ></mat-icon>

  <mat-icon
    *ngIf="!focused"
    class="tag-icon"
    svgIcon="trash"
    matTooltip="Delete"
    (click)="handleDelete()"
  ></mat-icon>
</div>

<ng-template #dragPlaceholder>
  <app-tag [tag]="tag" [dragEnabled]="false"> </app-tag>
</ng-template>
