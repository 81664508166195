import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasOne'
})
export class HasOnePipe implements PipeTransform {

  transform(
    array: object[],
    ...rest
  ): boolean {
    if (!array || !array.length) {
      return false;
    }

    const pairs = rest.reduce((memo, item, i) => i % 2 !== 0
      ? memo
      : [...memo, [item, rest[i + 1]]]
    , [])

    return array.some(item => pairs.every(([key, value]) => item[key] === value));
  }
}
