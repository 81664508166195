// Common
import { Component, Injector } from '@angular/core';

// Types
import { Group } from '@modules/contacts/types/group';
import { StitchType } from '@modules/common/types/stitch-type';

// Components
import { SharedBaseComponent } from '@modules/common/components/shared-base/shared-base.component';

@Component({
  selector: 'stch-group-shared',
  templateUrl: './group-shared.component.html',
  styleUrls: ['./group-shared.component.less'],
})
export class GroupSharedComponent extends SharedBaseComponent<Group> {

  protected stitchType = StitchType.group;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  get group() {
    return this.stitchItem;
  }
}
