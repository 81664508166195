// Common
import { Component, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Service
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';

// Types
import { Connection } from '@modules/connections/types/connection';
import { Knot } from '@modules/knots/types/knot';
import { Tag } from '@modules/tags/types/tag';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.less'],
})
export class HistoryComponent implements OnInit, OnDestroy {

  public connectionsHistory: Connection[][] = [];
  public knotsHistory: Knot[][] = [];
  public tagsHistory: Tag[][] = [];

  private alive = new Subject<void>();

  constructor (
    private kpService: KnowledgePanelService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.kpService.getConnectionsHistory()
      .pipe(takeUntil(this.alive))
      .subscribe(history => {
        this.connectionsHistory = history;
      });

    this.kpService.getKnotsHistory()
      .pipe(takeUntil(this.alive))
      .subscribe(history => {
        this.knotsHistory = history;
      });

    this.kpService.getTagsHistory()
      .pipe(takeUntil(this.alive))
      .subscribe(history => {
        this.tagsHistory = history;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClickHistoryItem(event: MouseEvent, type: 'connections' | 'knots' | 'tags', index: number) {
    event.stopPropagation();
    event.preventDefault();

    switch (type) {
      case 'connections':
        if (!this.connectionsHistory[index]) { return; }
        this.kpService.setConnectionsHistory(this.connectionsHistory.slice(0, index + 1));
        break;
      case 'knots':
        if (!this.knotsHistory[index]) { return; }
        this.kpService.setKnotsHistory(this.knotsHistory.slice(0, index + 1));
        break;
      case 'tags':
        if (!this.tagsHistory[index]) { return; }
        this.kpService.setTagsHistory(this.tagsHistory.slice(0, index + 1));
        break;
    }
  }

  removeHistoryItem(type: 'connections' | 'knots' | 'tags', index: number) {
    switch (type) {
      case 'connections':
        this.kpService.setConnectionsHistory(this.connectionsHistory.filter((_, i) => i !== index));
        break;
      case 'knots':
        this.kpService.setKnotsHistory(this.knotsHistory.filter((_, i) => i !== index));
        break;
      case 'tags':
        this.kpService.setTagsHistory(this.tagsHistory.filter((_, i) => i !== index));
        break;
    }
  }
}
