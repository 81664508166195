// Common
import { environment } from '@environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

// Utils
import { groupByIds } from '@modules/common/utils/stitch';

// RxJS
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

// Decorators
import { warmUpObservable } from '@decorators';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { TagFilters } from '../types/tag-filters';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { Tag } from '../types/tag';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { TagsNeighboursFilters } from '../types/neighbours-filters';

@Injectable()
export class TagsService extends BaseRestService<Tag, TagFilters> {

  constructor(
    protected http: HttpClient,
    protected toaster: ToasterService
  ) {
    super();
    this.handleObserverError = this.handleObserverError.bind(this);
  }

  /**
   * Static methods
   */

  handleObserverError(error: HttpErrorResponse) {
    if (error?.error?.message || error?.error?.error) {
      this.toaster.show({
        text: error.error.message || error?.error?.error,
        icon: 'tags'
      });
    }

    console.error(error);
    return throwError(error);
  }

  /**
   * Methods
   */

  search(filters: Partial<TagFilters>): Observable<BaseSearchResponse<Tag>> {
    return this.http.get<{tags: Tag[], count: number }>(
      `${environment.baseUrl}/api/tags`,
      { params: new TagFilters(filters).format() }
    )
      .pipe(
        map(({ tags, count }) => ({ items: tags.map(tag => new Tag(tag)), count })),
        catchError((error: Error) => {
          this.toaster.show(
            { text: error.message }
          );

          return of ({ items: [], count: 0 });
        })
      );
  }

  @warmUpObservable
  createBulk(tags: Tag[], { emit, toast }: FeedbackConfig = {}) {
    return this.http.post<{ success: boolean }>(
      `${environment.baseUrl}/api/tags`,
      {
        tags: tags.map(tag => ({
          name: tag.name,
          pinned: tag.pinned
        }))
      }
    )
      .pipe(
        tap(({ success }) => {
          toast && this.toaster.show({
            text: success
              ? 'New Tag(s) were added.'
              : 'Something went wrong while adding tags',
            icon: 'tags'
          });

          emit && this.forceRefresh();
        }),
        map(({ success }) => success),
        catchError(error => this.handleObserverError(error))
      );
  }

  create(tag: Tag, config: FeedbackConfig) {
    return this.createBulk([tag], config)
      .pipe(map(() => null));
  }

  update(tag: Tag, config: FeedbackConfig): Observable<Tag> {
    throw new Error('Method forbidden');
  }

  updateBulk(tags: Tag[], config: FeedbackConfig) {
    return this.http.put<{ success: boolean }>(
      `${environment.baseUrl}/api/tags`,
      {
        tags: tags.map(tag => ({
          id: tag.id,
          name: tag.name,
          pinned: tag.pinned
        }))
      }
    )
      .pipe(
        tap(() => {
          config.toast && this.toaster.show({
            text: 'Tag(s) were updated.',
            icon: 'tags'
          });

          config.emit && this.forceRefresh();
        }),
        map(({ success }) => success ),
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  deletePermanently(filters: Partial<TagFilters>, customToastMessage?, emitUpdate = true, withToast = true) {
    return this.http.delete<{ success: boolean }>(
      `${environment.baseUrl}/api/tags`,
      { params: new TagFilters(filters).format() }
    )
      .pipe(
        tap(({ success }) => {
          if (withToast) {
            this.toaster.show({
              text: success
                ? 'Tag(s) were removed.'
                : 'Something went wrong while removing tags',
              icon: 'tags'
            });
          }

          if (emitUpdate) {
            this.forceRefresh();
          }
        }),
        map(({ success }) => success),
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  link(
    tags: Tag[],
    items: Stitch[],
    emitUpdate = true,
    withToast = true
  ): Observable<boolean> {
    return this.http.post<{ success: boolean }>(
      `${environment.baseUrl}/api/tags/link`,
      {
        tags: tags.map(tag => tag.name),
        items: groupByIds(items)
      }
    )
      .pipe(
        tap(({ success }) => {
          if (withToast) {
            this.toaster.show({
              text: success
                ? 'Tag(s) linked.'
                : 'Something went wrong while removing tags',
              icon: 'tags'
            });
          }

          if (emitUpdate) {
            this.forceRefresh();
          }
        }),
        map(({ success }) => success),
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  unlink(
    tags: Tag[],
    items: Stitch[],
    emitUpdate = true,
    withToast = true
  ): Observable<boolean> {
    return this.http.request<{ success: boolean }>(
      'DELETE',
      `${environment.baseUrl}/api/tags/link`,
      {
        body: {
          tags: tags.map(tag => tag.name),
          items: groupByIds(items)
        }
      }
    )
      .pipe(
        tap(({ success }) => {
          if (withToast) {
            this.toaster.show({
              text: success
                ? 'Tag(s) unlinked.'
                : 'Something went wrong while unlinking tags',
              icon: 'tags'
            });
          }

          if (emitUpdate) {
            this.forceRefresh();
          }
        }),
        map(({ success }) => success),
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  pin(tags: Tag[], pinned: boolean, emit = true) {
    return this.updateBulk(tags.map(tag => ({ ...tag, pinned })), { emit, toast: false })
      .pipe(
        tap((success) => {
          if (success) {
            this.toaster.show({
              text: `Tag(s) ${pinned ? 'pinned' : 'unpinned'}.`,
              icon: 'tags'
            });
          }
        }),
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  upsertBulk(
    tags: Tag[],
    items: Stitch[],
    emitUpdate = true,
    withToast = true
  ) {
    return this.createBulk(tags, { emit: true })
      .pipe(
        switchMap(() => this.link(tags, items, emitUpdate, withToast))
      );
  }

  getAutocompleteSuggestions(): AutocompleteFactory<Tag> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      if (title && title.trim() !== '') {
        return this.search({ limit: config?.limit || 5, query: title })
          .pipe(
            map(({ items: tags }) => tags.map(tag => ({
              title: '#' + tag.name,
              value: tag.name,
              source: tag
            }))),
          );
      } else if (values?.length > 0) {
        return this.search({ limit: config?.limit || 5, names: values })
          .pipe(
            map(({ items: tags }) => tags.map(tag => ({
              title: '#' + tag.name,
              value: tag.name,
              source: tag
            }))),
          );
      } else {
        return of([])
      }
    };
  }

  getNeighbors(filters: Partial<TagsNeighboursFilters>) {
    return this.http.get<{tags: Tag[], count: number}>(
      `${environment.baseUrl}/api/tags/neighbors`,
      { params: new TagsNeighboursFilters(filters).format() }
    )
      .pipe(
        map(({ tags, count }) => ({ tags: tags.map(tag => new Tag(tag)), count })),
        catchError((error: Error) => {
          this.toaster.show(
            { text: error.message }
          );

          return of({ tags: [], count: 0 });
        })
      );
  }

  getRecommendations(items: Stitch[]): Observable<{ key: string, doc_count: number }[]> {
    if (!items || items.length === 0) { return of([]) }

    const ids = items.map(({ id }) => id).filter(id => !!id)

    if (!ids.length) { return of([]) }

    return this.http.get<{ recommendations: { key: string, doc_count: number }[] }>(
      `${environment.baseUrl}/api/search/tags-recommendations`,
      { params: { 'items_ids[]': ids } }
    )
      .pipe(
        map(({ recommendations }) => recommendations),
        catchError(error => this.handleObserverError(error))
      );
  }
}
