<div class="knots knots-form-popover">
  <div class="knots-title">
    Manage Knots
  </div>

  <app-context-menu-state-indicator
    class="knots-top-icon"
    svgIcon="filters"
    appKnotsManageContextMenu
    [appKnotsManageContextMenuStateKey]="stateKey"
    (appKnotsManageContextMenuStateChange)="setState($event)"
    [appKnotsManageContextMenuWithTemporalExpressions]="false"
  ></app-context-menu-state-indicator>

  <app-simple-input
    class="knots-input"
    [inputFormControl]="inputControl"
    placeholder=""
  ></app-simple-input>

  <div class="knots-add">
    <div
      *ngIf="canAdd && count === 0"
      class="knots-add-button"
      (click)="addKnot()"
    >
      + Add Knot «{{ inputControl.value }}»
    </div>
  </div>

  <div
    *ngIf="!count"
    class="knots-placeholder"
  >
    <img
      class="knots-placeholder-image"
      src="assets/placeholders/placeholder-stitch-info.jpg"
    />
    <div class="knots-placeholder-text">
      You Have no Knots Added Yet
    </div>
  </div>

  <div class="knots-list">
    <div class="shadow" *ngIf="scrollShadowTop"></div>

    <cdk-virtual-scroll-viewport
      class="scroll-container"
      [itemSize]="24"
      [minBufferPx]="300"
      [maxBufferPx]="400"
    >
      <div
        *cdkVirtualFor="let knot of dataSource; let index = index"
        class="knots-list-item"
      >
        <div
          class="knots-list-item-letter"
          [class.blank]="!knot?.letter"
        >
          {{ knot?.letter | uppercase }}
        </div>

        <app-manage-knot
          class="knots-list-item-knot"
          [class.deleted]="knot?.deleted"
          [knot]="knot"
          (pin)="handlePin(index)"
          (delete)="handleDelete(index)"
          (change)="handleChange(index)"
        ></app-manage-knot>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <div *ngIf="count" class="shadow-bottom">
    <div class="shadow-bottom-inner" *ngIf="scrollShadowBottom"></div>
  </div>

  <app-pagination
    *ngIf="count"
    class="pagination"
    [pages]="count / itemsPerPage | number : '1.0-0'"
    [currentPage]="currentPage"
    (currentPageChange)="setCurrentPage($event)"
  ></app-pagination>

  <div
    *ngIf="count"
    class="knots-buttons"
  >
    <app-button appearance="square" size="medium" (click)="handleCancel()" label="Cancel" />
    <app-button appearance="square-blue" size="medium" (click)="handleSave()" label="Save" />
  </div>
</div>
