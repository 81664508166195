<app-context-menu-item
  *ngIf="clipboard"
  title="Paste"
  [close]="true"
  (execute)="pasteEvents()"
/>

<app-context-menu-divider *ngIf="clipboard" />

<app-context-menu-item
  title="Create Event"
  [close]="true"
  (execute)="createEvent()"
/>

<app-context-menu-item
  title="Load Day's Events in List View"
  [close]="true"
  (execute)="handleLoadDayEvents()"
/>

<app-context-menu-item
  *ngIf="calendarType !== 'day'"
  title="Open Day"
  [close]="true"
  (execute)="openCalendar('day')"
/>

<app-context-menu-item
  *ngIf="calendarType !== 'week'"
  title="Open Week"
  [close]="true"
  (execute)="openCalendar('week')"
/>

<app-context-menu-item
  *ngIf="calendarType !== 'month'"
  title="Open Month"
  [close]="true"
  (execute)="openCalendar('month')"
/>

<app-context-menu-item
  *ngIf="calendarType !== 'year'"
  title="Open Year"
  [close]="true"
  (execute)="openCalendar('year')"
/>
