<div class="container">
  <div
    class="input month"
    [appMonthsContextMenu]="innerDate"
    (appMonthsContextMenuSelect)="handleSelect($event, 'month')"
    stchPopoverPlacement="bottomFullWidth"
  >
    {{ monthSet ? (innerDate | date : 'MMMM') : placeholder.month }}
  </div>

  <div
    class="input day"
    [appDaysContextMenu]="innerDate"
    (appDaysContextMenuSelect)="handleSelect($event, 'day')"
  >
    {{ daySet ? (innerDate | date : 'd') : placeholder.day }}
  </div>

  <div
    class="input year"
    [appYearsContextMenu]="innerDate"
    [appYearsContextMenuMax]="maxYear"
    (appYearsContextMenuSelect)="handleSelect($event, 'year')"
  >
    {{ yearSet ? (innerDate | date : 'y') : placeholder.year }}
  </div>
</div>
