import { Component, Input } from '@angular/core';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { Attachment } from '@modules/form-controls/types/attachment';


@Component({
  selector: 'event-popover-preview',
  templateUrl: './event-popover-preview.component.html',
  styleUrls: ['./event-popover-preview.component.less']
})
export class EventPopoverPreviewComponent {

  @Input() event: CalendarEvent;

  /**
   * Actions
   */

  saveAttachment(file: Attachment, event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    window.open(file.url, '_blank');
  }
}
