// Common
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// RX
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { StitchService } from '@modules/common/services/stitch.service';
import { AccountService } from '@modules/account/services/account.service';

// Types
import { Space } from '../types/space';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { SpaceParticipant } from '../types/space-participant';

// Env
import { environment } from '@environment';

// Injection tokens
import { SPACE_ID } from '@modules/common/injection-tokens/space-id.injection-token';

@Injectable()
export class SpacesService {
  private refreshRequired = new BehaviorSubject<void>(null);
  private spacesCache: { [key: string]: Space } = {};

  constructor (
    protected http: HttpClient,
    protected toaster: ToasterService,
    protected stitchService: StitchService,
    private accountService: AccountService,
    @Inject(SPACE_ID) private spaceId: BehaviorSubject<string>
  ) {}

  private handleObserverError(error: HttpErrorResponse) {
    this.toaster.show({ text: error?.error?.message || error?.error?.error || error?.message });
    return throwError(error);
  }

  public forceRefresh() {
    this.spacesCache = {};
    this.refreshRequired.next();
  }

  getRefreshRequired(): Observable<void> {
    return this.refreshRequired.asObservable();
  }

  /**
   * Methods
   */

  listAll(): Observable<Space[]> {
    return this.refreshRequired
      .pipe(
        switchMap(() => this.http.get<{ spaces: object[] }>(
          `${environment.baseUrl}/api/account/spaces`
        )),
        map(({ spaces }) => spaces.map(item => new Space(item))),
        catchError(error => this.handleObserverError(error))
      );
  }

  getOne(id: string): Observable<Space> {
    return this.http.get<{ space: object }>(`${environment.baseUrl}/api/account/spaces/${id}`)
      .pipe(
        map(({ space }) => new Space(space)),
        catchError(error => this.handleObserverError(error))
      );
  }

  getCurrentSpace(): Observable<Space> {
    return this.getRefreshRequired()
      .pipe(
        switchMap(() => combineLatest([this.spaceId, this.accountService.getAccount()])),
        switchMap(([id, account]) => {
          if (!id) { return of(new Space({ participants: [account] })); }
          if (this.spacesCache[id]) { return of(this.spacesCache[id]); }
          return this.getOne(id).pipe(tap((space) => this.spacesCache[id] = space));
        }));
  }

  create(
    instance: Space,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<boolean> {
    return this.http.post<{ success: boolean }>(
      `${environment.baseUrl}/api/account/spaces`,
      instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }

          emit && this.forceRefresh();
          toast && this.toaster.show({ text: message || `Space created.` });
        }),
        map(({ success }) => success),
        catchError(error => this.handleObserverError(error))
      );
  }

  update(
    instance: Space,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<boolean> {
    return this.http.put<{ success: boolean }>(
      environment.baseUrl + '/api/account/spaces/' + instance.id,
      instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (success) {
            emit && this.forceRefresh();
            toast && this.toaster.show({ text: message || 'Space updated' });
          }
        }),
        map(({ success }) => success),
        catchError(error => this.handleObserverError(error))
      );
  }

  upsert(instance: Space, config?: FeedbackConfig): Observable<boolean> {
    return instance.id ? this.update(instance, config) : this.create(instance, config);
  }

  @warmUpObservable
  delete(
    instance: Space,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<boolean> {
    return this.http.delete<{ success: boolean }>(
      `${environment.baseUrl}/api/account/spaces/${instance.id}`
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (!success) { return; }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || 'Space successfully deleted' });
        }),
        catchError(error => this.handleObserverError(error))
      );
  }

  addParticipant(
    space: Space,
    email: string,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<boolean> {
    return this.http.post<{ success: boolean }>(
      `${environment.baseUrl}/api/account/spaces/${space.id}/participants`,
      { email }
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || `Participant ${email} added to space ${space.title}.` });
        }),
        map(({ success }) => success),
        catchError(error => this.handleObserverError(error))
      );
  }

  updateParticipant(
    space: Space,
    participant: SpaceParticipant,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<boolean> {
    return this.http.put<{ success: boolean }>(
      `${environment.baseUrl}/api/account/spaces/${space.id}/participants/${participant.id}`,
      participant.asPayloadJSON()
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (!success) { return; }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || `Participant ${participant.userName} role updated in space ${space.title}.` });
        }),
        catchError(error => this.handleObserverError(error))
      );
  }

  deleteParticipant(
    space: Space,
    participant: SpaceParticipant,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<boolean> {
    return this.http.delete<{ success: boolean }>(
      `${environment.baseUrl}/api/account/spaces/${space.id}/participants/${participant.id}`
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (!success) { return; }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || `Participant ${participant.userName} removed from space ${space.title}.` });
        }),
        catchError(error => this.handleObserverError(error))
      );
  }

  accept(
    space: Space,
    accepted: boolean,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ) {
    return this.http.post<{ success: boolean }>(
      `${environment.baseUrl}/api/account/spaces/${space.id}/participants/accept`,
      { accepted }
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (!success) { return; }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || `You have ${ accepted ? 'accepted' : 'rejected' } an invitation to participate in a space ${space.title}.` });
        }),
        catchError(error => this.handleObserverError(error))
      );
  }
}
