// Common
import { Component, OnDestroy, Input, OnInit, SimpleChanges, OnChanges, NgZone, Output, EventEmitter, Inject, Self } from '@angular/core';

// RxJS
import { interval, BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { takeUntil, filter, debounceTime } from 'rxjs/operators';

// Services
import { FilesAppStateService } from '@modules/files/services/state.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { FoldersService } from '@modules/files/services/folders.service';

// Injection Tokens
import ScrollToPosition from '@modules/common/services/scroll-to-index.injection-token';

// Types
import { Like } from '@modules/common/types/like';
import { FoldersFilters } from '@modules/files/types/folders-filters';
import { Folder } from '@modules/files/types/folder';
import { File } from '@modules/files/types/file';
import { KnotSource } from '@modules/knots/types/knot-source';

// Env
import { environment } from '@environment';

// Components
import { InfinityScrollListComponent } from '@modules/common/components/infinity-scroll-list/infinity-scroll-list.component';

@Component({
  selector: 'app-folders-list',
  templateUrl: './folders-list.component.html',
  styleUrls: ['./folders-list.component.less'],
  providers: [{ provide: ScrollToPosition, useFactory: () => new BehaviorSubject<number>(null) }]
})
export class FoldersListComponent extends InfinityScrollListComponent implements OnInit, OnDestroy, OnChanges {

  // Inputs
  @Input() placeholderText = 'You have no folders';
  @Input() filters: Like<FoldersFilters> = {};
  @Input() withTags = false;
  @Input() withKnots = false;
  @Input() knotsSource: KnotSource;
  @Input() debug: 'score';

  // Outputs
  @Output() clickFolder = new EventEmitter<Folder>();
  @Output() openFolder = new EventEmitter<Folder>();
  @Output() openFile = new EventEmitter<File>();
  @Output() doubleClickFolder = new EventEmitter<Folder>();

  public itemHeight = 92;
  public isHover = false;
  public showCountView = new BehaviorSubject(true);
  public resetSelected = new Subject<void>();

  constructor(
    private foldersService: FoldersService,
    private filesStateService: FilesAppStateService,
    private linkedInfoService: LinkedInfoService,
    protected ngZone: NgZone,
    @Self() @Inject(ScrollToPosition) scrollToPositionSubject
  ) {
    super(ngZone, scrollToPositionSubject);
  }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.showCountView
      .pipe(
        filter(value => !!value),
        debounceTime(5000),
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.showCountView.next(false);
      });

    merge(
      this.filesStateService.getRefreshAll(),
      this.linkedInfoService.getRefreshRequired(),
      this.foldersService.getRefreshRequired(),
      interval(environment.messageFetchInterval)
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.refreshCurrentItems();
      });

    this.refreshCurrentItems();

    merge(
      this.filesStateService.getRefreshAll(),
      this.foldersService.getRefreshRequired(),
    )
      .pipe(
        takeUntil(this.componentNotDestroyed)
      )
      .subscribe(() => {
        this.resetSelected.next();
      });

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showCountView.next(true);

    if ('filters' in changes) {
      this.resetItems();
    }
  }

  /**
   * Actions
   */

   getItems(offset: number, limit: number): Observable<{ items: Folder[], count: number }> {
    return this.foldersService.search(
      { ...this.filters, limit, offset },
      { withTags: this.withTags, withKnots: this.withKnots, withConnections: false, knotsSource: this.knotsSource }
    );
  }

  resetItems() {
    this.resetSelected.next();
    super.resetItems();
  }

  handleDoubleClickItem(folder: Folder) {
    this.doubleClickFolder.emit(folder);
  }

  handleClickItem(folder: Folder) {
    this.clickFolder.emit(folder);
  }

  compareItems(item1: Object, item2: Object): boolean {
    return item1 && item2 && item1['id'] === item2['id'];
  }
}
