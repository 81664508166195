// Common
import { Injectable } from '@angular/core';

// RX
import { Observable } from 'rxjs';

// Types
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';

@Injectable()
export abstract class CalendarEventsService {
  abstract getCalendarEvents(fromTime: Date, toTime: Date): Observable<AngularCalendarEvent[]>;
}
