// Common
import { Component, Input, OnDestroy, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { isEqual } from 'lodash';

// RX
import { combineLatest, of, Subject } from 'rxjs';
import { debounceTime, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';

// Types
import { Tag } from '@modules/tags/types/tag';
import { Stitch } from '@modules/common/types/stitch';
import { TagFilters } from '@modules/tags/types/tag-filters';
import { ManageListState } from '@modules/tags/types/manage-list-state';
import { StateKey } from '@modules/settings/types/state-key';

// Services
import { TagsService } from '@modules/tags/services/tags.service';
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.less']
})
export class TagsComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() stitchItems: Stitch[];
  @Input() control = new UntypedFormControl();
  @Input() withControls = true;
  @Input() withPlaceholder = false;
  @Input() stateKey: StateKey;

  // Public
  public hidePopover = new Subject();
  public tags: Tag[] = [];
  public selectedTags: Tag[];
  public pagesCount = 0;
  public page = 0;
  public perPage = 20;
  public state: ManageListState;
  public recommendations: { key: string, doc_count: number}[] = [];

  // Private
  private alive = new Subject<void>();
  private loadPage = new Subject<void>();
  private shouldRequestRecommendations = new Subject<void>();

  constructor (
    private tagsService: TagsService,
    private kpService: KnowledgePanelService
  ) {
    this.loadPage
      .pipe(
        switchMap(() => this.tagsService.getRefreshRequired()),
        debounceTime(300),
        switchMap(() => this.stitchItems?.filter(({ id }) => !!id)?.length
          ? this.tagsService.search({
              ...TagFilters.fromManageListState(this.state),
              items: this.stitchItems,
              offset: this.page * this.perPage,
              limit: this.perPage
            })
          : (this.control?.valueChanges || of([{ items: [] }]))
            .pipe(
              startWith(this.control?.value || [{ items: [] }]),
              map(items => ({ items, count: items.length }))
            )
        ),
        takeUntil(this.alive)
      )
      .subscribe(({ items: knots, count }) => {
        this.pagesCount = Math.ceil(count / this.perPage);
        this.tags.length = count;
        this.tags = [
          ...this.tags.slice(0, this.page * this.perPage),
          ...knots,
          ...this.tags.slice((this.page + 1) * this.perPage, count),
        ].filter(i => !!i);

        this.notifyVisibleTagsChanged();
      });

    this.kpService.getSelectedTags()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((tags: Tag[]) => {
        this.selectedTags = tags;
      });
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.shouldRequestRecommendations
      .pipe(
        switchMap(() => this.tagsService.getRecommendations(this.stitchItems)),
        takeUntil(this.alive)
      )
      .subscribe(recommendations => {
        this.recommendations = recommendations
      })

    this.shouldRequestRecommendations.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      'stitchItems' in changes &&
      !isEqual(
        changes.stitchItems.previousValue?.map(({ id }) => id ),
        changes.stitchItems.currentValue?.map(({ id }) => id )
      )
    ) {
      this.page = 0;
      this.tags = [];
      this.loadPage.next();
      this.shouldRequestRecommendations.next();
    }
  }

  ngOnDestroy() {
    this.loadPage.complete();
    this.hidePopover.next();
    this.hidePopover.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClick(tag: Tag, event: MouseEvent) {
    this.kpService.addTagToSelection(tag, event.shiftKey, true, 'ff');
  }

  closePopovers() {
    this.hidePopover.next();
  }

  saveTags(tags: Tag[]) {
    this.control?.setValue(
      tags.reduce(
        (acc: Tag[], item) => {
          if (acc.find(existingItem => existingItem.name === item.name)) {
            if (item.pinned) {
              return acc.map(existingItem => existingItem.name === item.name ? item : existingItem);
            } else {
              return acc;
            }
          } else {
            return [...acc, item];
          }
        },
        this.control.value || []
      )
    );

    const existingStitchItems = this.stitchItems.filter(({ id }) => id);
    if (existingStitchItems.length === 0) { return; }

    const newTags = tags.filter(tag => tag.added && tag.id === undefined || tag.id === null);
    const changedTags = tags.filter(tag => tag.changed && tag.id !== undefined && tag.id !== null);
    const linkedTags = tags.filter(tag => tag.added);
    const unlinkedTags = tags.filter(tag => tag.deleted);

    combineLatest([
      newTags.length ? this.tagsService.createBulk(newTags) : of(true),
      changedTags.length ? this.tagsService.updateBulk(changedTags, { toast: false, emit: false }) : of(true)
    ])
      .pipe(
        switchMap(([created, updated]) => (
          created && updated
            ? combineLatest([
              linkedTags.length ? this.tagsService.link(linkedTags, existingStitchItems, false, false) : of(true),
              unlinkedTags.length ? this.tagsService.unlink(unlinkedTags, existingStitchItems, false, false) : of(true)
            ])
            : of(null)
        )),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.tagsService.forceRefresh();
        this.closePopovers();
      });
  }

  addRecommendation(recommendation: { key: string, doc_count: number}) {
    this.tagsService.link([new Tag({ name: recommendation.key })], this.stitchItems, true, true )
      .pipe(
        take(1),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.recommendations = this.recommendations.filter(({ key }) => key !== recommendation.key)
      })
  }

  handleDelete(tag: Tag) {
    if (this.stitchItems?.length) {
      this.tagsService.unlink([tag], this.stitchItems)
        .pipe(
          takeUntil(this.alive)
        )
        .subscribe((success: boolean) => {
          if (success) {
            this.setRemovedValue(tag);
          }
        });
    } else {
      this.setRemovedValue(tag);
    }
  }

  handlePin(tag: Tag) {
    if (this.stitchItems?.length) {
      this.tagsService.pin([tag], !tag.pinned)
        .pipe(
          takeUntil(this.alive)
        )
        .subscribe((success: boolean) => {
          if (success) {
            this.setPinnedValue(tag);
          }
        });
    } else {
      this.setPinnedValue(tag);
    }
  }

  setPinnedValue(tag: Tag) {
    if (!this.stitchItems?.length) {
      this.control.setValue(this.control.value.map(item => item.name === tag.name ? { ...item, pinned: !tag.pinned} : item));
    }
  }

  setRemovedValue(tag: Tag) {
    if (!this.stitchItems?.length) {
      this.control.setValue(this.control.value.filter(item => item.name !== tag.name));
    }
  }

  showMore() {
    this.page = Math.min(this.page + 1, this.pagesCount - 1);

    if (this.tags[this.page * this.perPage]) {
      this.notifyVisibleTagsChanged();
    } else {
      this.loadPage.next();
    }
  }

  showLess() {
    this.page = 0;
    this.notifyVisibleTagsChanged();
  }

  notifyVisibleTagsChanged() {
    this.kpService.setStitchItemTags(this.tags.slice(0, (this.page + 1) * this.perPage));
  }

  setState(state: ManageListState) {
    this.page = 0;
    this.state = state;
    this.tags = [];
    this.loadPage.next();
  }
}
