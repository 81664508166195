// Common
import { Injectable, OnDestroy } from '@angular/core';
import { OneSignal } from 'onesignal-ngx';
import { environment } from '@environment';

// Services
import { AccountService } from '@modules/account/services/account.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// RxJS
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, from, Subject } from 'rxjs';

@Injectable()

export class PushNotificationsService implements OnDestroy {

  public PERMISSION_DEFAULT = 'default';
  public PERMISSION_GRANTED = 'granted';
  public PERMISSION_DENIED = 'denied';

  private currentPermission = new BehaviorSubject<string>(this.PERMISSION_DEFAULT);
  private alive = new Subject<void>();

  constructor(
    private oneSignal: OneSignal,
    private accountService: AccountService,
    private toasterService: ToasterService
  ) {}

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  init() {
    from(this.oneSignal.init({ appId: environment.oneSignal.appId }))
      .pipe(
        switchMap(() => from(this.oneSignal.getNotificationPermission())),
        tap((currentPermission) => {
          this.currentPermission.next(currentPermission);
          this.oneSignal.on('notificationPermissionChange', ({ to: permission }) => {
            this.currentPermission.next(permission);
          });
        }),
        switchMap(() => this.accountService.getAccount()),
        filter(({ id }) => !!id),
        takeUntil(this.alive)
      )
      .subscribe(({ id: accountId }) => {
        this.oneSignal.setExternalUserId(accountId);
        this.oneSignal.registerForPushNotifications();
        this.oneSignal.setSubscription(true);
        this.watchPermissionChanges();
      });
  }

  getPermission(): Observable<string> {
    if (!environment.oneSignal.enabled) {
      return of(this.PERMISSION_DEFAULT);
    }

    return this.currentPermission.asObservable();
  }

  requestGrantPermission(): void {
    from(Notification.requestPermission())
      .pipe(takeUntil(this.alive))
      .subscribe((permission) => this.currentPermission.next(permission));
  }

  isEnabled(): Observable<boolean> {
    return this.currentPermission
      .pipe(switchMap(() => from(this.oneSignal.isPushNotificationsEnabled())));
  }

  private watchPermissionChanges(): void {
    this.getPermission()
      .pipe(takeUntil(this.alive))
      .subscribe((permission) => {
        if (permission !== this.PERMISSION_GRANTED) {
          this.toasterService.show({ text: 'User denied Notifications' });
        } else {
          this.oneSignal.registerForPushNotifications();
          this.oneSignal.setSubscription(true);
        }
      });
  }
}
