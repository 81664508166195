// Common
import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { environment } from '@environment';
import heic2any from 'heic2any';

// EditorJS tools
import EditorJS, { OutputData } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Marker from '@editorjs/marker';
import Table from '@editorjs/table';
import Delimiter from '@editorjs/delimiter';
import NestedList from '@editorjs/nested-list';
import Checklist from '@editorjs/checklist';
import Underline from '@editorjs/underline';
import RawTool from '@editorjs/raw';
import Quote from '@editorjs/quote';
import LinkTool from '@editorjs/link';
import TextVariantTune from '@editorjs/text-variant-tune';
import ImageTool from '@editorjs/image';
import VideoTool from '@weekwood/editorjs-video';

// RX
import { Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';

// Types
import { Upload } from '@modules/common/types/upload';
import { Like } from '@modules/common/types/like';

// Services
import { UploadsService } from '@modules/common/services/uploads.service';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.less'],
})
export class EditorComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input() inputFormControl: UntypedFormControl;
  @Input() data: string;

  @Output() attachmentUploaded = new EventEmitter<Upload>();

  @ViewChild('editorJs') el: ElementRef;

  private alive = new Subject();
  private editor: EditorJS;
  private controlChanged = new Subject<void>();
  private cachedData: OutputData;
  private change = new Subject<void>();

  constructor(
    private uploadsService: UploadsService
  ) { }

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.editor = new EditorJS({
      holder: this.el.nativeElement,
      tools: {
        header: {
          class: Header,
          inlineToolbar: ['link', 'bold', 'italic']
        },
        Marker: {
          class: Marker,
          shortcut: 'CMD+SHIFT+M'
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: { rows: 3, cols: 2 },
        },
        list: {
          class: NestedList,
          inlineToolbar: true,
        },
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        delimiter: Delimiter,
        underline: Underline,
        raw: RawTool,
        quote: {
          class: Quote,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+O',
          config: {
            quotePlaceholder: 'Enter a quote',
            captionPlaceholder: 'Quote\'s author',
          },
        },
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: environment.baseUrl + '/api/editorjs-link'
          }
        },
        textVariant: TextVariantTune,
        image: {
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile: this.handleUpload.bind(this)
            }
          }
        },
        video: {
          class: VideoTool,
          config: {
            uploader: {
              uploadByFile: this.handleUpload.bind(this)
            },
            player: { controls: true }
          }
        },
      },
      readOnly: !this.inputFormControl,
      tunes: ['textVariant'],
      onReady: () => this.cachedData && this.editor.render(this.cachedData),
      onChange: () => this.change.next()
    });

    this.change
      .pipe(
        filter(() => !!this.inputFormControl),
        debounceTime(1000),
        switchMap(() => this.editor.save()),
        takeUntil(this.alive)
      )
      .subscribe(output => {
        this.inputFormControl.setValue(JSON.stringify(output));
        this.inputFormControl.markAsDirty();
      });

    this.controlChanged
      .pipe(
        filter(() => !!this.inputFormControl),
        map(() => this.inputFormControl.value),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        this.setData(value);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('inputFormControl' in changes) {
      this.controlChanged.next();
    }

    if ('data' in changes) {
      this.setData(this.data)
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  setData(data: string) {
    try {
      const jsonValue = JSON.parse(data);
      if (jsonValue?.blocks?.length > 0) {
        this.editor?.render?.(jsonValue);
        this.cachedData = jsonValue;
      } else {
        this.editor?.blocks?.clear();
      }
    } catch (e) {
      this.editor?.blocks?.clear();
    }
  }

  private handleUploadAttachment(file): Promise<{success: 1 | 0, file: Like<Upload> & { url: string } }> {
    return new Promise((resolve, reject) => {
      const upload = new Upload({
        nativeFile: file,
        name: file.name,
        size: file.size,
        type: file.type,
        inline: true,
        temp: true
      });

      this.uploadsService.upload(upload)
        .pipe(takeUntil(this.alive))
        .subscribe(
          ({ id }) => {
            upload.id = id;
            this.attachmentUploaded.emit(upload);

            resolve({
              success: 1,
              file: {
                url: `${environment.baseUrl}/api/attachments/temp/${id}`,
                temp: true,
                id,
                name: upload.name,
                type: upload.type,
                size: upload.size
              }
            });
          },
          (error) => reject({ success: 0, error })
        );
    });
  }

  private handleUpload(file) {
    return ['image/heic', 'image/heif'].includes(file.type)
      ? heic2any({ blob: file, toType: 'image/jpeg' })
        .then((result: Blob) => {
          const convertedFile = new File(
            [result],
            file.name.replace(/\.(hei[cf])$/gi, '.jpeg'),
            { type: result.type, lastModified: file.lastModified }
          );
          return this.handleUploadAttachment(convertedFile);
        })
      : this.handleUploadAttachment(file);
  }
}
