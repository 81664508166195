// Common
import { Component, Injector, Input, OnInit } from '@angular/core';

// RX
import { BehaviorSubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

// Types
import { Group } from '@modules/contacts/types/group';
import { DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Tab } from '@modules/common/types/tab';
import { ContactsListState } from '@modules/contacts/types/contacts-list-state';
import { StateKey } from '@modules/settings/types/state-key';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { ContactsFilters } from '@modules/contacts/types/contacts-filters';

// Services
import { GroupsService } from '@modules/contacts/services/groups.service';
import { ContactsAppStateService } from '@modules/contacts/services/state.service';

// Components
import { FullFormBaseComponent } from '@modules/common/components/full-form-base/full-form-base.component';

@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.less']
})
export class GroupFormComponent extends FullFormBaseComponent<Group> implements OnInit {

  public changesKey = 'group';
  tabs: Tab[] = [
    { title: 'Group', value: 'group'},
    { title: 'Details', value: 'details'},
    { title: 'Attachments', value: 'attachments'},
    { title: 'Stitch', value: 'stitch'},
    { title: 'Sections', value: 'sections' },
    { title: 'Lab', value: 'lab'},
    { title: 'Comments', value: 'comments'},
  ];
  tabsStateKey = 'ffGroup';
  public contactsListStateKey: StateKey = StateKey.contactsFFListState;
  public contactsListState = new BehaviorSubject<ContactsListState>(null);
  public parentSuggestions: AutocompleteFactory<Group>;
  public contactsFilters: ContactsFilters;

  protected dragDataType = DragDataTypes.group;

  @Input() group = new Group();

  constructor(
    private groupsService: GroupsService,
    contactsAppStateService: ContactsAppStateService,
    injector: Injector,
  ) {
    super(injector, groupsService, contactsAppStateService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    this.stitchItemChanged
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.parentSuggestions = this.groupsService.getAutocompleteSuggestions({ exceptIds: [this.stitchItem.id] });
      });

    this.stitchItemChanged
      .pipe(
        switchMap(() => this.contactsListState),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.contactsFilters = new ContactsFilters({ containersIds: [this.stitchItem.id] });
        this.contactsFilters.applyListState(this.contactsListState.getValue());
      });
  }

  /**
   * Actions
   */

  handleContactsListStateChange(state: ContactsListState) {
    this.contactsListState.next(state);
  }

  /**
   * Helpers
   */

  get stitchItem(): Group {
    return this.group;
  }

  protected shouldRefreshList(prev, current) {
    return Group.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): Group {
    return Group.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return this.group.asFormGroup(this.groupsService);
  }
}
