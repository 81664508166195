// Common
import { Component, Injector, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

// Types
import { Notebook } from '@modules/notes/types/notebook';
import { Note } from '@modules/notes/types/note';
import { NotesListState } from '@modules/notes/types/notes-list-state';
import { NotebooksListState } from '@modules/notes/types/notebooks-list-state';
import { StateKey } from '@modules/settings/types/state-key';
import { NotebooksFilters } from '@modules/notes/types/notebooks-filters';
import { NotesFilters } from '@modules/notes/types/notes-filters';
import { VirtualFolder } from '@modules/notes/types/virtual-folder';

// Services
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { NotesAppStateService } from '@modules/notes/services/state.service';

// RX
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-notes-panel',
  templateUrl: './notes-panel.component.html',
  styleUrls: ['./notes-panel.component.less'],
})
export class NotesPanelComponent implements OnInit, OnDestroy {

  public notesListState = new BehaviorSubject<NotesListState>(null);
  public notebooksListState = new BehaviorSubject<NotebooksListState>(null);
  public notebooksListStateKey: StateKey = StateKey.notebooksDPListState;
  public notesListStateKey: StateKey = StateKey.notesDPListState;
  public notebooksFilters: NotebooksFilters;
  public notesFilters: NotesFilters;
  public contextMenuOpened: boolean;
  public selectedFilter: VirtualFolder;

  private alive = new Subject<void>();

  @Input() searchTemplate: TemplateRef<any>;

  constructor (
    private dpService: DynamicPanelService,
    private modalService: ModalService,
    private injector: Injector,
    private searchService: AdvancedSearchService,
    private stateService: NotesAppStateService,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([
      this.notebooksListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.notebooksFilters = new NotebooksFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([
      this.notesListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.notesFilters = new NotesFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewNote(note = new Note()) {
    this.dpService.setFormItem(note);
  }

  handleNewNotebook(notebook = new Notebook()) {
    this.dpService.setFormItem(notebook);
  }

  openNoteInModal(note: Note) {
    this.modalService.openFullForm(note, this.injector);
  }

  openNote(note: Note) {
    this.dpService.setFormItem(note);
  }

  openNotebook(notebook: Notebook) {
    this.dpService.setFormItem(notebook);
  }

  handleNotesListStateChange(event: NotesListState) {
    this.notesListState.next(event);
  }

  handleNotebooksListStateChange(event: NotebooksListState) {
    this.notebooksListState.next(event);
  }

  selectFilter(folder: VirtualFolder): void {
    this.selectedFilter = folder;
    this.stateService.setVirtualFolder(folder);
  }
}
