// Common
import { Component, Injector } from '@angular/core';

// Utils
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { Like } from '@modules/common/types/like';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { VirtualFolder } from '@modules/calendar-app/types/virtual-folder';
import { Calendar } from '@modules/calendar-app/types/calendar';
import { Application } from '@modules/common/types/application';

// Services
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';
import { EventsService } from '@modules/calendar-app/services/events.service';
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';

// Components
import { BaseSidebarContextMenuComponent } from '@modules/common/components/base-sidebar-context-menu/base-sidebar-context-menu.component';


@Component({
  selector: 'app-sidebar-context-menu',
  templateUrl: './sidebar-context-menu.component.html',
  styleUrls: ['./sidebar-context-menu.component.less']
})
export class SidebarContextMenuComponent extends BaseSidebarContextMenuComponent<CalendarEvent, Calendar, VirtualFolder> {

  advancedSearchStateKey = Application.calendar;

  constructor (
    injector: Injector,
    eventsService: EventsService,
    calendarsService: CalendarsService,
    eventsStateService: CalendarAppStateService,
  ) {
    super(injector, eventsService, calendarsService, eventsStateService);
  }

  protected getCommonParams(): Like<CalendarEvent | Calendar> {
    let params: Like<CalendarEvent | Calendar> = {};

    switch (this.sidebarFilterKey) {
      case 'all_events':
      case 'all_calendars':
        break;
      case 'flagged':
      case 'snoozed':
      case 'deleted':
      case 'archived':
      case 'followed':
        params = super.getCommonParams();
        break;
      default:
        checkExhaustiveness(this.sidebarFilterKey);
    }

    return params;
  }

  protected stitchItemFactory(params: Like<CalendarEvent>): CalendarEvent {
    return new CalendarEvent({ ...params, calendarId: this.selectedContainerStitchItemId });
  }

  protected containerStitchItemFactory(params: Like<Calendar>): Calendar {
    return new Calendar({ ...params, containerId: this.selectedContainerStitchItemId });
  }

}
