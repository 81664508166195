// Common
import { Inject, Injectable } from '@angular/core';
import { LocalStorageItem, LSString } from 'src/app/decorators/local-storage.decorator';
import { SPACE_ID } from '@modules/common/injection-tokens/space-id.injection-token';

// Services
import { SpacesService } from '@modules/settings/services/spaces.service';

// RX
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// Types
import { Space } from '@modules/settings/types/space';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { SpaceParticipant } from '@modules/settings/types/space-participant';

@Injectable()
export class SpaceStateService {
  public key: string;

  @LSString({ lsKey: 'space' }) private spaceId: LocalStorageItem<string>;
  private space = new BehaviorSubject<Space>(undefined);
  private spaces = new BehaviorSubject<Space[]>([]);
  private alive = new Subject<void>();

  constructor(
    private spacesService: SpacesService,
    @Inject(SPACE_ID) private spaceIdToken: Subject<string>,
  ) {}

  init(key: string) {
    this.key = key;

    combineLatest([
      this.spacesService.listAll(),
      this.spaceId.get(this.key)
    ])
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(([spaces, spaceId]) => {
        this.spaces.next(spaces);

        const currentSpace = spaces.find(space => space.id === spaceId);

        this.spaceIdToken.next(currentSpace?.id || null);
        this.space.next(currentSpace || null);
      });
  }

  detach() {
    this.alive.next();
    this.alive.complete();
  }

  getCurrentSpace() {
    return this.space.asObservable();
  }

  setCurrentSpace(space: Space) {
    this.spaceId.set(space?.id, this.key);
  }

  getSpaces() {
    return this.spaces.asObservable();
  }

  getUsersAutocompleteSuggestions(): AutocompleteFactory<SpaceParticipant> {
    return (title?: string, values?: string[]) => {
      return this.getCurrentSpace()
        .pipe(
          map(space => space?.participants || []),
          map(participants => {
            if (values?.length) {
              return participants.filter(({ id }) => values.includes(id));
            } else if (title?.trim()) {
              return participants.filter(({ fullName }) =>
                fullName.toLowerCase().includes(title.trim().toLowerCase())
              );
            }

            return participants;
          }),
          map(participants => participants.map(participant => ({
            title: participant.fullName,
            value: participant.id,
            source: participant
          }))),
        );
    };
  }
}
