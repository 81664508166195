// Common
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, Input, Output, EventEmitter, SimpleChanges, OnChanges, Injector } from '@angular/core';
import { Like } from '@modules/common/types/like';

// RxJS
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';
import { StateService } from '@modules/settings/services/state.service';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StateKey } from '@modules/settings/types/state-key';
import { Knot } from '@modules/knots/types/knot';
import { Tag } from '@modules/tags/types/tag';
import { Connection } from '@modules/connections/types/connection';
import { KPListType } from '@modules/knowledge/types/kp-list-type';

import { FoldersListState as MessageFoldersListState } from '@modules/messages/types/folders-list-state';
import { MessagesListState } from '@modules/messages/types/messages-list-state';
import { EventsListState } from '@modules/calendar-app/types/events-list-state';
import { ProjectsListState } from '@modules/tasks/types/projects-list-state';
import { TasksListState } from '@modules/tasks/types/tasks-list-state';
import { NotebooksListState } from '@modules/notes/types/notebooks-list-state';
import { NotesListState } from '@modules/notes/types/notes-list-state';
import { GroupsListState } from '@modules/contacts/types/groups-list-state';
import { ContactsListState } from '@modules/contacts/types/contacts-list-state';
import { FoldersListState } from '@modules/files/types/folders-list-state';
import { FilesListState } from '@modules/files/types/files-list-state';
import { ManageListState as ConnectionsListState } from '@modules/connections/types/manage-list-state';

import { FoldersFilters as MessageFoldersFilters } from '@modules/messages/types/folders-filters';
import { MessagesFilters } from '@modules/messages/types/messages-filters';
import { EventsFilters } from '@modules/calendar-app/types/events-filters';
import { ProjectsFilters } from '@modules/tasks/types/projects-filters';
import { TasksFilters } from '@modules/tasks/types/tasks-filters';
import { NotebooksFilters } from '@modules/notes/types/notebooks-filters';
import { NotesFilters } from '@modules/notes/types/notes-filters';
import { GroupsFilters } from '@modules/contacts/types/groups-filters';
import { ContactsFilters } from '@modules/contacts/types/contacts-filters';
import { FilesFilters } from '@modules/files/types/files-filters';
import { FoldersFilters } from '@modules/files/types/folders-filters';
import { ConnectionsFilters } from '@modules/connections/types/connections-filters';
import { ManageListState } from '@modules/knots/types/manage-list-state';
import { KnotSource } from '@modules/knots/types/knot-source';

type AllFiltersType = Like<MessageFoldersFilters | MessagesFilters | EventsFilters | ProjectsFilters |
  TasksFilters | NotebooksFilters | NotesFilters | GroupsFilters | ContactsFilters | FilesFilters | FoldersFilters | ConnectionsFilters>;

@Component({
  selector: 'app-knowledge-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.less'],
})
export class KnowledgeSectionComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  // Inputs
  @Input() title: string;
  @Input() stateKey: StateKey;
  @Input() tags: Tag[];
  @Input() knots: Knot[];
  @Input() connections: Connection[];

  // Output
  @Output() itemClick = new EventEmitter<Stitch>();
  @Output() typeChange = new EventEmitter<KPListType>();
  @Output() filterChange = new EventEmitter<void>();

  public KPListType = KPListType;
  public menuOpened = false;
  public type: KPListType = KPListType.message;
  public state = {};
  public knotsSource: KnotSource;

  private alive = new Subject<void>();
  private filtersReceived = new BehaviorSubject<AllFiltersType>({});
  private tagsReceived = new BehaviorSubject<Tag[]>([]);
  private knotsReceived = new BehaviorSubject<Knot[]>([]);
  private connectionsReceived = new BehaviorSubject<Connection[]>([]);
  private selectedStitchItem: Stitch;

  constructor (
    private changeDetector: ChangeDetectorRef,
    private modalService: ModalService,
    private kpService: KnowledgePanelService,
    private stateService: StateService,
    private injector: Injector
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.state = {
      messageFolder: new MessageFoldersFilters({ withEmpty: true }),
      message: new MessagesFilters({ withEmpty: true }),
      event: new EventsFilters({ withEmpty: true }),
      project: new ProjectsFilters({ withEmpty: true }),
      task: new TasksFilters({ withEmpty: true }),
      notebook: new NotebooksFilters({ withEmpty: true }),
      note: new NotesFilters({ withEmpty: true }),
      group: new GroupsFilters({ withEmpty: true }),
      contact: new ContactsFilters({ withEmpty: true }),
      folder: new FoldersFilters({ withEmpty: true }),
      file: new FilesFilters({ withEmpty: true }),
      connection: new ConnectionsFilters({ withEmpty: true }),
    };

    this.stateService.getState(StateKey.kpKnotsState)
      .pipe(takeUntil(this.alive))
      .subscribe((state: ManageListState) => {
        this.knotsSource = state?.filters?.source;
      });
  }

  ngAfterViewInit() {
    this.kpService.getSelectedStitchItem()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((stitchItem: Stitch) => {
        this.selectedStitchItem = stitchItem;
      });

    combineLatest([
      this.filtersReceived,
      this.tagsReceived,
      this.knotsReceived,
      this.connectionsReceived,
    ])
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(([filters, tags, knots, connections]) => {
        const resultsFilters = {
          ...filters,
          esAllKnowledge: true,
          esAnalyzer: 'english',
          esMultiMatchType: 'most_fields'
        };

        if (tags.length) {
          resultsFilters.tags = tags;
          resultsFilters.query = tags.map(i => i.name).join(' ');
        } else if (knots.length) {
          resultsFilters.knots = knots;
          resultsFilters.query = knots.map(i => i.name).join(' ');
        } else if (connections.length) {
          resultsFilters.connections = connections;
          resultsFilters.query = connections.map(i => i.name).join(' ');
        } else {
          resultsFilters.withEmpty = true;
        }

        if (this.type === this.selectedStitchItem?.getStitchType() as unknown as KPListType) {
          resultsFilters.exceptIds = [this.selectedStitchItem.id];
        }

        this.state[this.type] = resultsFilters;
        this.changeDetector.detectChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    'tags' in changes && this.tagsReceived.next(this.tags);
    'knots' in changes && this.knotsReceived.next(this.knots);
    'connections' in changes && this.connectionsReceived.next(this.connections);
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleFilter(
    listState: MessageFoldersListState | MessagesListState | EventsListState |
      ProjectsListState | TasksListState | NotebooksListState | NotesListState | GroupsListState | ContactsListState |
      FoldersListState | FilesListState | ConnectionsListState
  ) {
    this.filterChange.emit();

    const filtersObjectByType = {
      [KPListType.messageFolder]: MessageFoldersFilters,
      [KPListType.message]: MessagesFilters,
      [KPListType.event]: EventsFilters,
      [KPListType.project]: ProjectsFilters,
      [KPListType.task]: TasksFilters,
      [KPListType.notebook]: NotebooksFilters,
      [KPListType.note]: NotesFilters,
      [KPListType.group]: GroupsFilters,
      [KPListType.contact]: ContactsFilters,
      [KPListType.folder]: FoldersFilters,
      [KPListType.file]: FilesFilters,
      [KPListType.connection]: ConnectionsFilters
    };

    const filters = new filtersObjectByType[this.type]();

    if (filters instanceof ConnectionsFilters) {
      // TODO Connections will be removed from this component
    } else {
      filters.applyListState(listState as any);
    }

    this.filtersReceived.next(filters);
  }

  handleClickItem(item: Stitch) {
    this.itemClick.emit(item);
  }

  handleDoubleClickItem(item: Stitch) {
    this.modalService.openFullForm(item, this.injector);
  }

  handleTypeChange(type: KPListType) {
    this.type = type;
    this.typeChange.emit(type);
  }
}
