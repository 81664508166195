import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { LSNumber, LocalStorageItem } from 'src/app/decorators/local-storage.decorator';
import { FormControl } from '@angular/forms';

// Utils
import { isNil } from '@modules/common/utils/base';

// Types
import { Tab } from '../../types/tab';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

// RX
import { takeUntil, take, delay } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.less']
})
export class TabsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() values: Tab[] = [];
  @Input() value: string;
  @Input() selectedStateKey: string;
  @Input() appearance: 'tabs' | 'buttongroup' | 'sapphire' | 'sapphire-filled' = 'tabs';
  @Input() size: 's' | 'm' | 'l' = 's';

  @Output() valueChange = new EventEmitter<string>();

  public tabsWidth: number;
  public containerWidth: number;
  public control = new FormControl<string>(null);

  public options: DropdownSelectItem<string>[] = [];

  @LSNumber({ lsKey: 'tabs', default: 0 }) private state: LocalStorageItem<number>;
  private currentIndex: number;
  private alive = new Subject<void>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.selectedStateKey && this.state.get(this.selectedStateKey)
      .pipe(
        delay(0),
        take(1),
        takeUntil(this.alive)
      )
      .subscribe(index => {
        !this.value && this.handleSelect(index)
      });

    this.control.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(tab => {
        this.handleSelect(this.values.findIndex(({ value }) => value === tab));
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('values' in changes) {
      this.options = (this.values || [])
        .map(({ title, value }) => ({ title, value }));
    }

    if ('value' in changes) {
      this.currentIndex = this.values.findIndex(({ value }) => value === this.value);
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSelect(index: number, tab?: Tab) {
    if (tab?.disabled || (!isNil(this.currentIndex) && index === this.currentIndex)) { return; }

    this.currentIndex = index;

    this.selectedStateKey && this.state.set(index, this.selectedStateKey);

    this.control.setValue(this.values[index]?.value);
    this.valueChange.emit(this.values[index]?.value);
  }

  handleContainerResize([width]) {
    this.containerWidth = width;
  }

  handleTabsResize([width]) {
    this.tabsWidth = width;
  }
}
