<div class="content">
  <div class="header">
    <app-button
      appearance="sapphire-secondary-ghost"
      size="s"
      icon="sapphire-chevron-left"
      (click)="prevDate()"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="s"
      icon="sapphire-chevron-right"
      (click)="nextDate()"
    />

    <app-dropdown-month-year-input
      *ngIf="['month', 'year'] | includesBy : calendarType.value"
      [control]="date"
      appearance="sapphire-inline"
      size="l"
      [title]="date?.value | date : 'MMM yyyy (\'Week\' w)'"
    />

    <app-dropdown-date-input
      *ngIf="['week', 'workWeek', 'day'] | includesBy : calendarType.value"
      [control]="date"
      appearance="sapphire-inline"
      size="l"
      dateFormat="week"
    />

    <div class="header-divider"></div>

    <div class="header-right">
      <app-button
        appearance="sapphire-primary-ghost"
        size="xs"
        (click)="setToday()"
        label="Today"
      />

      <app-dropdown-input
        [inputFormControl]="calendarType"
        [options]="calendarTypes"
        appearance="sapphire-inline"
        size="l"
        placement="bottomLeft"
      />

      <app-button
        appearance="sapphire-secondary-ghost"
        size="xs"
        icon="sapphire-ellipsis"
      />
    </div>
  </div>

  <div [ngSwitch]="calendarType.value" class="full-height">
    <stch-calendar-year
      *ngSwitchCase="'year'"
      [viewDate]="date.value"
      [selectedDate]="selectedDate"
      [events]="calendarEvents"
      (dateClicked)="handleDateClicked($event)"
      (dateDblClicked)="handleDateDblClicked($event)"
      (loadDayEvents)="handleOpenInListView($event)"
      (eventDropped)="handleCellDrop($event)"
    />

    <stch-full-calendar-month
      *ngSwitchCase="'month'"
      [viewDate]="date.value"
      [selectedDate]="selectedDate"
      [events]="events"
      (dateClicked)="handleDateClicked($event)"
      (dateDblClicked)="handleDateDblClicked($event)"
      (eventDropped)="handleCellDrop($event)"
      (loadDayEvents)="handleOpenInListView($event)"
    />

    <stch-calendar-week
      *ngSwitchCase="(['week', 'workWeek'] | includesBy: calendarType.value) && calendarType.value"
      [viewDate]="date.value"
      [events]="calendarEvents"
      [selectedDate]="selectedDate"
      [excludeDays]="calendarType.value === 'workWeek' ? [0, 6] : []"
      (dateClicked)="handleDateClicked($event)"
      (dateDblClicked)="handleDateDblClicked($event)"
      (eventDropped)="handleCellDrop($event)"
      (dayNumberClicked)="handleDayNumberClick($event)"
      (loadDayEvents)="handleOpenInListView($event)"
    />

    <stch-calendar-day
      *ngSwitchCase="'day'"
      [viewDate]="date.value"
      [events]="calendarEvents"
      (dateClicked)="handleDateClicked($event)"
      (dateDblClicked)="handleDateDblClicked($event)"
      (eventDropped)="handleCellDrop($event)"
      (dayNumberClicked)="handleDayNumberClick($event)"
      (loadDayEvents)="handleOpenInListView($event)"
    />
  </div>
</div>

<ng-template #popoverFormTemplate>
  <stch-event-popover-form
    [event]="calendarEvent"
    (close)="popoverClose.next()"
  />
</ng-template>
