// Common
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { Tag } from '../types/tag';
import { ManageListState } from '../types/manage-list-state';

@Pipe({
  name: 'groupAlphabetically'
})
export class GroupAlphabeticallyPipe implements PipeTransform {

  transform(tags: Tag[] = [], state: ManageListState): { letter: string, tags: Tag[] }[] {
    return Object.entries(
      tags.reduce((memo, tag) => {
        const firstLetter = tag.name[0].toLocaleUpperCase();

        return {
          ...memo,
          [firstLetter]: [
            ...(memo[firstLetter] || []),
            tag
          ]
        };
      }, {})
    )
      .map(([k, v]) => ({ letter: k, tags: v as Tag[] }))
      .sort((a, b) => (a.letter > b.letter ? 1 : -1) * (state.sort.order === 'desc' ? -1 : 1));
  }
}
