// Common
import { Component } from '@angular/core';
import { Tab } from '@modules/common/types/tab';

@Component({
  selector: 'app-admin-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less']
})
export class UsersComponent {
  public tabs: Tab[] = [
    { title: 'USERS', value: 'users' },
    { title: 'CONFIG', value: 'config' },
  ];

  public selectedTab = 'users';
}
