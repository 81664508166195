<ng-container *ngTemplateOutlet="template; context: { history: connectionsHistory, type: 'connections' }"></ng-container>
<ng-container *ngTemplateOutlet="template; context: { history: knotsHistory, type: 'knots' }"></ng-container>
<ng-container *ngTemplateOutlet="template; context: { history: tagsHistory, type: 'tags' }"></ng-container>

<ng-template #template let-history="history" let-type="type">
  <div
    *ngIf="history?.length"
    class="history"
  >
    <div class="history-label">{{ type | titlecase }}:</div>

    <div
      *ngFor="let items of history; let index = index"
      class="history-item"
      (click)="handleClickHistoryItem($event, type, index)"
    >
      {{ items | map : 'name' | join : ' | ' }}
      <mat-icon
        class="history-item-close"
        svgIcon="close-small"
        (click)="removeHistoryItem(type, index)"
      ></mat-icon>
    </div>
  </div>
</ng-template>
