// Utils
import { isNil } from '@modules/common/utils/base';
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { Application } from '@modules/common/types/application';
import { ContactsListState } from './contacts-list-state';
import { StitchChildFilters } from '@modules/common/types/stitch-child-filters';
import { Like } from '@modules/common/types/like';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { ContactEmail, ContactPhone } from '@modules/contacts/types/contact-info';
import { Country } from '@modules/common/types/country';
import { VirtualFolder } from './virtual-folder';

export class ContactsFilters extends StitchChildFilters {
  // Filters
  birthDate?: Date;
  company?: string;
  country?: Country;
  email?: ContactEmail;
  fromTime?: Date;
  jobTitle?: string;
  phone?: ContactPhone;
  toTime?: Date;
  vip?: boolean;

  // Sort Options
  sortBy?: 'title' | 'date' | 'due-date' | 'score' | 'sharedViaLinkAt';

  constructor (filters?: Like<ContactsFilters>) {
    super(filters);
    this.birthDate = filters?.birthDate;
    this.company = filters?.company;
    this.country = new Country(filters?.country);
    this.email = new ContactEmail(filters?.email);
    this.fromTime = filters?.fromTime;
    this.jobTitle = filters?.jobTitle;
    this.phone = new ContactPhone(filters?.phone);
    this.sortBy = filters?.sortBy;
    this.toTime = filters?.toTime;
    this.vip = filters?.vip;
  }

  applyListState(state: ContactsListState) {
    const today = new Date();

    this.flagged = state?.flagged;
    this.pinned = state?.pinned;
    this.fromTime = state?.from;
    this.toTime = state?.to;

    if (state?.today) {
      this.fromTime = beginningOfDay(today);
      this.toTime = endOfDay(today);
    }

    this.stitch = state?.stitchedWith;
    this.sortBy = state?.sortBy;
    this.sortOrder = state?.sortOrder;
    this.pinnedOnTop = state?.pinnedOnTop;
    this.flaggedOnTop = state?.flaggedOnTop;
    this.snoozedOnTop = state?.snoozedOnTop;
    this.followedOnTop = state?.followedOnTop;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.containersIds) { result['groups_ids[]'] = this.containersIds; }
    if (this.originsIds) { result['groups_ids[]'] = this.originsIds; }
    if (!isNil(this.noContainer)) { result['no_group'] = this.noContainer + ''; }
    if (!isNil(this.vip)) { result['vip'] = this.vip + ''; }

    if (this.fromTime) { result['from_time'] = this.fromTime.toISOString(); }
    if (this.toTime) { result['to_time'] = this.toTime.toISOString(); }

    if (this.email?.value) {
      result['email'] = this.email.value;
      result['email_category'] = this.email.category;
    }
    if (this.phone?.value) {
      result['phone'] = encodeURI(this.phone.value);
      result['phone_category'] = this.phone.category;
    }

    if (this.jobTitle) { result['job_title'] = this.jobTitle; }
    if (this.birthDate) { result['birth_date'] = this.birthDate.toISOString(); }
    if (this.company) { result['company'] = this.company; }
    if (this.country?.iso3) { result['country'] = this.country.iso3; }

    result['sort_by'] = this.sortBy || 'date';

    return result;
  }

  applyAdvancedFilters(advanced: AdvancedSearchState) {
    super.applyAdvancedFilters(advanced, Application.contacts);

    const state = advanced.contacts;

    this.esTitle = state.name;
    this.esBody = state.description;
    this.phone = state.phone;
    this.email = state.email;
    this.jobTitle = state.jobTitle;
    this.birthDate = state.birthDate;
    this.company = state.company;
    this.country = state.country;

    return this;
  }

  applyVirtualFolder(folder: VirtualFolder) {
    this.archived = false;
    this.deleted = false;
    this.snoozedOnTop = true;
    this.followedOnTop = true;
    this.noContainer = null;

    if (!folder) { return this; }

    switch (folder) {
      case 'archived':
        this.archived = true;
        break;
      case 'deleted':
        this.deleted = true;
        break;
      case 'all_contacts':
        this.noContainer = true;
        break;
      case 'all_groups':
      case 'upcoming':
      case 'frequent':
      case 'recent':
        break;
      case 'vip':
        this.vip = true;
        break;
      case 'flagged':
        this.flagged = true;
        break;
      case 'followed':
        this.followed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      case 'snoozed':
        this.snoozed = true;
        this.snoozedOnTop = false;
        this.followedOnTop = false;
        break;
      default:
        checkExhaustiveness(folder);
    }

    return this;
  }
}
