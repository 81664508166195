// Common
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// RxJS
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

// Environment
import { environment } from '@environment';

// Types
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { QuickReplyTemplate } from '@modules/account/types/quick-reply-template';

// Services
import { BaseService } from '@modules/common/services/base.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

@Injectable()
export class QuickReplyTemplatesService extends BaseService {

  constructor (
    private http: HttpClient,
    private toasterService: ToasterService,
  ) { super(); }

  search(filters?: unknown, config?: object): Observable<BaseSearchResponse<QuickReplyTemplate>> {
    return this.refreshRequired.pipe(
      switchMap(() => this.http.get<{ count: number, items: QuickReplyTemplate[] }>(environment.baseUrl + '/api/mail/quick-reply-templates')),
      map(({ count, items = [] }) => ({ count: count, items: items.map(template => new QuickReplyTemplate(template)) }))
    );
  }

  create(instance: QuickReplyTemplate): Observable<QuickReplyTemplate> {
    return this.http.post<{ success: boolean, data: QuickReplyTemplate }>(
      environment.baseUrl + '/api/mail/quick-reply-templates', instance.asPayloadJSON()
    )
      .pipe(map((response: any) => new QuickReplyTemplate(response.data)));
  }

  update(instance: QuickReplyTemplate): Observable<QuickReplyTemplate> {
    return this.http.put<{ success: boolean, data: QuickReplyTemplate }>(
      environment.baseUrl + '/api/mail/quick-reply-templates/' + instance.id, instance.asPayloadJSON()
    )
      .pipe(map((response: any) => new QuickReplyTemplate(response.data)));
  }

  @warmUpObservable
  upsert(instance: QuickReplyTemplate, feedbackConfig: FeedbackConfig = { emitUpdate: true }): Observable<QuickReplyTemplate> {
    return (
      instance.id
        ? this.update(instance)
        : this.create(instance)
    )
      .pipe(
        tap(() => {
          feedbackConfig.emitUpdate && this.forceRefresh();
          feedbackConfig.displayToast && this.toasterService.show({
            text: feedbackConfig.toastMessage || `Quick reply template ${ instance.id ? 'updated' : 'created' } successfully`
          });
        }),
        catchError((error: HttpErrorResponse) => {
          this.toasterService.show({ text: error.error.error });
          return throwError(error);
        })
      );
  }

  @warmUpObservable
  delete(instance: QuickReplyTemplate, feedbackConfig: FeedbackConfig = { emitUpdate: true }): Observable<boolean> {
    return this.http.delete<boolean>(environment.baseUrl + '/api/mail/quick-reply-templates/' + instance.id)
      .pipe(
        tap(() => {
          feedbackConfig.emitUpdate && this.forceRefresh();
          feedbackConfig.displayToast && this.toasterService.show({
            text: feedbackConfig.toastMessage || 'Quick reply template removed successfully'
          });
        }),
        catchError((error: HttpErrorResponse) => {
          this.toasterService.show({ text: error.error.error });
          return throwError(error);
        })
      );
  }
}
