// Common
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { Clipboard } from '@modules/calendar-app/types/clipboard';

// Services
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';

// RX
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'isEventCut'
})
export class IsEventCutPipe implements PipeTransform {

  constructor(private eventsStateService: CalendarAppStateService) {}

  transform(event: CalendarEvent): Observable<boolean> {
    return this.eventsStateService.getClipboard()
      .pipe(
        map((clipboard: Clipboard) =>
          !!clipboard && clipboard.type === 'cut' && clipboard.data.map(item => item.id).includes(event.id)
        )
      );
  }
}
