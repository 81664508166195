<stch-comment
  [account]="account"
  [space]="space"
  [stitch]="stitch"
></stch-comment>

<stch-comment
  *ngFor="let comment of comments"
  [account]="account"
  [comment]="comment"
  [space]="space" 
  [stitch]="stitch"
></stch-comment>

<div
  *ngIf="page < pagesCount - 1"
  class="more"
  (click)="showMore()"
>
  Load More Comments
</div>

<div
  *ngIf="count === 0"
  class="placeholder"
>
  <img
    class="placeholder-image"
    src="assets/placeholders/placeholder-stitch-info.jpg"
    alt=""
  />
  <div class="placeholder-text">
    {{ placeholder || 'You don\'t have any comments yet' }}
  </div>
</div>
