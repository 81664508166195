// Common
import { ComponentRef, Directive, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { EventsContextMenuComponent } from '../components/events-context-menu/events-context-menu.component';

// Types
import { StateKey } from '@modules/settings/types/state-key';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { VirtualFolder } from '@modules/calendar-app/types/virtual-folder';

// RX
import { takeUntil } from 'rxjs/operators';
import { StateService } from '@modules/settings/services/state.service';

@Directive({
  selector: '[appDPEventsContextMenu]'
})
export class EventsContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';

  // Outputs
  @Output() appDPEventsContextMenuOpened = this.contextMenuOpened;
  @Output() appDPEventsContextMenuFilterChanged = new EventEmitter<VirtualFolder>();

  private filterKey: VirtualFolder;

  constructor (
    private stateService: StateService,
    injector: Injector,
  ) {
    super(injector);
  }

  registerInstance(componentRef: ComponentRef<EventsContextMenuComponent>) {
    componentRef.instance.filterKey = this.filterKey;
    componentRef.instance.change
      .pipe(takeUntil(this.alive))
      .subscribe((filterKey: VirtualFolder) => {
        componentRef.instance.filterKey = filterKey;
        this.stateService.setState(StateKey.eventsDPSidebarFilters, filterKey);
      });
  }

  registerComponent() {
    return EventsContextMenuComponent;
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.stateService.getState(StateKey.eventsDPSidebarFilters)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((filterKey: VirtualFolder) => {
        this.appDPEventsContextMenuFilterChanged.emit(filterKey);
        this.filterKey = filterKey;
      });
  }
}
