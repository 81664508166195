import { Pipe, PipeTransform } from '@angular/core';

const ONE_DAY = 24 * 60 * 60 * 1000;

@Pipe({
  name: 'sameWeek'
})
export class SameWeekPipe implements PipeTransform {

  transform(day1: Date, day2: Date): boolean {
    if (!day1 || !day2) { return false; }

    const startWeek1 = new Date(day1.getTime() - (day1.getDay() * ONE_DAY));
    const startWeek2 = new Date(day2.getTime() - (day2.getDay() * ONE_DAY));

    return startWeek1.getTime() === startWeek2.getTime();
  }
}
