<app-dropdown-base
  [placeholder]="placeholder"
  [withClear]="withClear"
  [appearance]="appearance"
  [size]="size"
  [disabled]="disabled"
  [placement]="placement"
  [popoverHide]="popoverHide"
  [valueTemplate]="valueTemplate"
  [popoverTemplate]="popoverTemplate"
/>

<ng-template #valueTemplate>
  {{ title }}
</ng-template>

<ng-template #yearValueTemplate>
  {{ control?.value | date : 'yyyy' }}
</ng-template>

<ng-template #popoverTemplate>
  <stch-dropdown-container
    class="dropdown"
    [appearance]="appearance | firstWord"
  >
    <div
      *ngIf="view === 'months'"
      class="header"
    >
      <app-dropdown-base
        appearance="sapphire-inline"
        size="m"
        placement="bottomLeft"
        [valueTemplate]="yearValueTemplate"
        (click)="view = 'years'"
      />

      <div class="filler"></div>

      <app-button
        appearance="sapphire-secondary-ghost"
        size="xs"
        icon="sapphire-backward"
      />

      <app-button
        appearance="sapphire-secondary-ghost"
        size="xs"
        icon="sapphire-chevron-left"
      />

      <app-button
        appearance="sapphire-secondary-ghost"
        size="xs"
        icon="sapphire-chevron-right"
      />

      <app-button
        appearance="sapphire-secondary-ghost"
        size="xs"
        icon="sapphire-forward"
      />
    </div>

    <div
      *ngIf="view === 'years'"
      class="header"
    >
      <div
        class="years-range"
        (click)="view = 'months'"
      >
        {{ years | first }} - {{ years | last }}
      </div>

      <div class="filler"></div>

      <app-button
        appearance="sapphire-secondary-ghost"
        size="xs"
        icon="sapphire-chevron-left"
        (click)="handleChangeYearsViewport(-1)"
      />

      <app-button
        appearance="sapphire-secondary-ghost"
        size="xs"
        icon="sapphire-chevron-right"
        (click)="handleChangeYearsViewport(1)"
      />
    </div>

    <div
      *ngIf="view === 'months'"
      class="items"
    >
      <div
        *ngFor="let month of months; let index = index"
        class="item"
        [class.past]="(control?.value | month) <= (today | month)"
        [class.active]="(control?.value | month) === index"
        (click)="handleMonthSelect(index)"
      >
        {{ month | slice: 0 : 3 }}
      </div>
    </div>

    <div
      *ngIf="view === 'years'"
      class="items"
    >
      <div
        *ngFor="let year of years; let index = index"
        class="item"
        [class.past]="year < (today | year)"
        [class.active]="year === (control?.value | year)"
        (click)="handleYearSelect(year)"
      >
        {{ year }}
      </div>
    </div>
  </stch-dropdown-container>
</ng-template>
