// Common
import { Component, Injector, OnDestroy } from '@angular/core';

// Services
import { NotesAppStateService } from '@modules/notes/services/state.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { NotesService } from '@modules/notes/services/notes.service';

// Types
import { VirtualFolder } from '@modules/notes/types/virtual-folder';
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Application } from '@modules/common/types/application';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { Note } from '@modules/notes/types/note';
import { Notebook } from '@modules/notes/types/notebook';
import { SidebarItem } from '@modules/common/types/sidebar-item';

// Components
import { BaseSidebarComponent } from '@modules/common/components/base-sidebar/base-sidebar.component';

@Component({
  selector: 'app-notes-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.less'],
})

export class NotesSideBarComponent extends BaseSidebarComponent<Notebook, Note, VirtualFolder> implements OnDestroy {
  application = Application.notes;
  sidebarSplitViewKey = SidebarSplitViewKey.notes;
  selfDragDataTypes = [DragDataTypes.note, DragDataTypes.notebook];

  public items: SidebarItem[] = [
    { title: 'Notes', id: 'all_notes', icon: 'sapphire-note' },
    { title: 'Upcoming', id: 'upcoming', icon: 'sapphire-upcoming' },
    { title: 'Flagged', id: 'flagged', icon: 'sapphire-flagged' },
    { title: 'Follow Up', id: 'followed', icon: 'sapphire-follow-up' },
    { title: 'Snoozed', id: 'snoozed', icon: 'sapphire-snooze' },
    { title: 'Recent', id: 'recent', icon: 'sapphire-recent' },
    { title: 'Archived', id: 'archived', icon: 'sapphire-archive' },
    { title: 'Trash', id: 'deleted', icon: 'sapphire-trash' },
  ];

  constructor(
    injector: Injector,
    stateService: NotesAppStateService,
    private notesService: NotesService,
    private notebooksService: NotebooksService
  ) {
    super(injector, stateService);
  }

  handleMove(dragData: DragData, sidebarFolder: VirtualFolder) {
    if (dragData.type === DragDataTypes.note) {
      const { changes, message } = Note.getChangesForVirtualFolder(sidebarFolder);
      this.notesService.bunchUpdate({ ids: this.getIds(dragData.data) }, changes, { message });
    } else if (dragData.type === DragDataTypes.notebook) {
      const { changes, message } = Notebook.getChangesForVirtualFolder(sidebarFolder);
      this.notebooksService.bunchUpdate({ ids: this.getIds(dragData.data) }, changes, { message });
    }
  }

  handleMore(item: Note) {
    this.stateService.setMainView(item);
  }

  getStitchFromDragData(dragData: DragData, sidebarFolder: VirtualFolder) {
    const { changes } = Note.getChangesForVirtualFolder(sidebarFolder);

    return new Note({ ...changes, title: dragData.data[0]['title'] });
  }
}
