// Common
import { Component } from '@angular/core';

@Component({
  selector: 'app-sidebar-items-divider',
  templateUrl: './sidebar-items-divider.component.html',
  styleUrls: ['./sidebar-items-divider.component.less'],
})
export class SidebarItemsDividerComponent {

}
