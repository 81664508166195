// Common
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

// Types
import { Space } from '@modules/settings/types/space';
import { SpaceParticipant } from '@modules/settings/types/space-participant';
import { Account } from '@modules/account/types/account';

// RX
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

// Services
import { SpacesService } from '@modules/settings/services/spaces.service';
import { AlertService } from '@modules/alert/services/alert.service';
import { AccountService } from '@modules/account/services/account.service';

@Component({
  selector: 'app-settings-spaces-space-participants',
  templateUrl: './space-participants.component.html',
  styleUrls: ['./space-participants.component.less']
})
export class SpaceParticipantsComponent implements OnInit, OnDestroy {

  @Input() space: Space;

  public roles = ['owner', 'manager', 'regular'];
  public account: Account;
  public owner: boolean;
  public manager: boolean;

  private alive = new Subject<void>();

  constructor (
    private spacesService: SpacesService,
    private alertsService: AlertService,
    private accountService: AccountService
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    if (this.space) {
      this.setSpace(this.space)
    }

    this.accountService.getAccount()
      .pipe(takeUntil(this.alive))
      .subscribe(account => {
        this.account = account;
        this.setSpace(this.space);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  removeParticipant(item: SpaceParticipant) {
    this.spacesService.deleteParticipant(this.space, item)
      .pipe(
        switchMap(() => this.spacesService.getOne(this.space.id)),
        takeUntil(this.alive)
      )
      .subscribe(space => {
        this.setSpace(space);
      });
  }

  removeParticipantPrompt(event: MouseEvent, item: SpaceParticipant) {
    event.preventDefault();
    event.stopPropagation();

    this.alertsService.show({
      title: 'Are you sure?',
      body: `Are you sure you want to remove Participant ${item.userName} from Space ${this.space.title}?`,
      rightButtons: [
        { title: 'CANCEL', close: true },
        { title: 'REMOVE', click: () => this.removeParticipant(item), close: true }
      ],
      showUntil: this.alive
    });
  }

  changeRole(user: SpaceParticipant, role: SpaceParticipant['role']) {
    this.spacesService.updateParticipant(this.space, new SpaceParticipant({ ...user, role }))
      .pipe(
        switchMap(() => this.spacesService.getOne(this.space.id)),
        takeUntil(this.alive)
      )
      .subscribe(space => {
        this.setSpace(space);
      });
  }

  addParticipant(email: string) {
    this.spacesService.addParticipant(this.space, email)
      .pipe(
        switchMap(() => this.spacesService.getOne(this.space.id)),
        takeUntil(this.alive)
      )
      .subscribe(space => {
        this.setSpace(space);
      });
  }

  openParticipantForm() {
    this.alertsService.show({
      title: 'Add participant',
      body: `Enter participant's email address:`,
      inputs: [
        { type: 'simple', label: 'Email', name: 'email' }
      ],
      rightButtons: [
        { title: 'CANCEL', close: true },
        { title: 'ADD', click: data => this.addParticipant(data.email), close: true }
      ],
      showUntil: this.alive
    });
  }

  accept(accepted: boolean) {
    this.spacesService.accept(this.space, accepted)
      .pipe(
        switchMap(() => this.spacesService.getOne(this.space.id)),
        takeUntil(this.alive)
      )
      .subscribe(space => {
        this.setSpace(space);
      });
  }

  setSpace(space: Space) {
    this.owner = space.participants.some(participant => participant.id === this.account?.id && participant.role === 'owner');
    this.manager = space.participants.some(participant => participant.id === this.account?.id && participant.role === 'manager');
    this.space = space;
  }
}
