// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Services
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';
import { EventsService } from '@modules/calendar-app/services/events.service';

// Types
import { StitchType } from '@modules/common/types/stitch-type';
import { Calendar } from '@modules/calendar-app/types/calendar';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'stch-calendar-app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.less'],
  providers: [
    { provide: CalendarEventsService, useClass: EventsService }
  ]
})
export class MainViewComponent implements OnInit, OnDestroy {

  public item: Calendar | CalendarEvent;
  public StitchType = StitchType;

  private alive: Subject<void> = new Subject();

  constructor(
    private stateService: CalendarAppStateService,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.stateService.getMainView()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((item) => {
        this.item = item;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleFormClose() {
    this.stateService.setMainView(null);
  }
}
