// Common
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { Tag } from '@modules/tags/types/tag';
import { ManageListState } from '@modules/tags/types/manage-list-state';

// RX
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Services
import { TagsService } from '@modules/tags/services/tags.service';

@Component({
  selector: 'app-manage-tags-form',
  templateUrl: './manage-tags-form.component.html',
  styleUrls: ['./manage-tags-form.component.less']
})
export class ManageTagsFormComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() existingTags: Tag[] = [];

  // Outputs
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter<Tag[]>();

  // Public
  public inputControl = new UntypedFormControl('');
  public scrollShadowTop = false;
  public scrollShadowBottom = false;
  public state: ManageListState;
  public canAdd = false;
  public tags: Tag[] = [];

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor (
    private tagsService: TagsService
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.inputControl.valueChanges
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(value => {
        this.canAdd = value.trim() !== '' && !this.tags?.some(item => item.name === value);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('existingTags' in changes && this.existingTags?.length) {
      this.tags = this.existingTags.map(tag => new Tag(tag));
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  addTag() {
    const name = this.inputControl.value.trim();

    if (name === '') { return; }

    this.tagsService.search({ names: [name] })
      .pipe(
        take(1),
        takeUntil(this.alive)
      )
      .subscribe(({ items: tags }) => {
        if (tags[0]) {
          this.tags = [...this.tags, new Tag({ ...tags[0], added: true })];
        } else {
          this.tags = [...this.tags, new Tag({ name, added: true })];
        }
      });

    this.inputControl.setValue('');
  }

  handlePin(tag: Tag) {
    this.tags = this.tags.map(item => item.name === tag.name
      ? new Tag({ ...tag, pinned: !tag.pinned, changed: true })
      : item
    );
  }

  handleDelete(tag: Tag) {
    if (tag.added) {
      this.tags = this.tags.filter(item => item.name !== tag.name);
    } else {
      this.tags = this.tags.map(item => item.name === tag.name
        ? new Tag({ ...tag, deleted: true })
        : item
      );
    }
  }

  handleChange(tag: Tag) {
    if (tag.id === null || tag.id === undefined) { return; }

    this.tags = this.tags.map(item => tag.id === item.id ? new Tag({ ...item, changed: true }) : item);
  }

  handleCancel() {
    this.close.emit();
  }

  handleSave() {
    this.save.emit(this.tags);
  }
}
