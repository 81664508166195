// Common
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, TemplateRef, ViewChildren } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';
import { skipInitialAnimation } from '@modules/common/animations/skip-initial.animation';

// Types
import { NestedTreeItem } from '@modules/drag-n-drop/types/nested-item';
import { DraggableBounding } from '@modules/drag-n-drop/types/draggable-bounding';
import { Stitch } from '@modules/common/types/stitch';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

// Services
import { DragNDropService } from '@modules/drag-n-drop/services/drag-n-drop.service';
import { DNDListService } from '@modules/drag-n-drop/services/dnd-list.service';

// RX
import { fromEvent, merge, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-dnd-nested-items',
  templateUrl: './dnd-nested-items.component.html',
  styleUrls: ['./dnd-nested-items.component.less'],
  animations: [heightAnimation, skipInitialAnimation]
})
export class DnDNestedItemsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() template: TemplateRef<DnDNestedItemsComponent>;
  @Input() tree: NestedTreeItem[];
  @Input() depth = 0;
  @Input() nestedPadding = 30;
  @Input() droppableZIndex = 0;
  @Input() predicate: (dragData: DragData) => boolean;
  @Input() disabled: boolean;

  @ViewChildren('content') contentElements: QueryList<ElementRef>;
  @ViewChildren('item') itemElements: QueryList<ElementRef>;

  public expanded: boolean[] = [];
  public treeChanged = new Subject<void>();
  public hoverIndex: number;
  public hoverAfter: boolean;
  public hoverNested: boolean;
  public hoverEmpty: boolean;
  public draggableBounding: DraggableBounding;
  public dragIds: string[] = [];
  public maxDepth = 0;

  private alive = new Subject<void>();
  private dragHover = new Subject<boolean>();

  constructor(
    private cd: ChangeDetectorRef,
    private dndService: DragNDropService,
    private dndListService: DNDListService,
    private ngZone: NgZone,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.dndListService.getMaxDepth()
      .pipe(takeUntil(this.alive))
      .subscribe((v) => {
        this.maxDepth = v;
      });
  }

  ngAfterViewInit() {
    this.treeChanged
      .pipe(
        filter(() => !!this.tree),
        switchMap(() => merge(
          ...this.tree.map((item, index) =>
            item.expanded.pipe(map(value => [index, value]))
          )
        )),
        filter(() => !!this.contentElements),
        takeUntil(this.alive)
      )
      .subscribe(([index, value]: [number, boolean]) => {
        this.expanded[index] = value;
        this.cd.detectChanges();
      });

    this.treeChanged.next();

    this.ngZone.runOutsideAngular(() => {
      this.dragHover
        .pipe(
          filter(() => !this.disabled),
          switchMap(over => over ? fromEvent(window, 'mousemove').pipe(takeUntil(this.dragHover)) : []),
          throttleTime(200),
          takeUntil(this.alive)
        )
        .subscribe((event) => {
          if (!this.draggableBounding) { return }
    
          const boundings = this.itemElements.toArray().map((i, index) => {
            const bounding = i.nativeElement.getBoundingClientRect();
            return { x: bounding.x, y: bounding.y, height: bounding.height, index };
          })

          if (!boundings.length) {
            this.hoverEmpty = true;
            return;
          }

          const anchorY = event.pageY - this.draggableBounding.mouseYAdjustment + this.draggableBounding.height / 2;

          const target = boundings.reduce((memo, bounding) => (
            Math.abs(bounding.y + bounding.height/2 - anchorY) <
            Math.abs(memo.y + memo.height/2 - anchorY) ? bounding : memo
          ))

          const newHoverIndex = target?.index

          const newHoverAfter =
            Math.abs(target.y + target.height - anchorY) <
            Math.abs(target.y - anchorY)

          const anchorX = event.pageX - this.draggableBounding.mouseXAdjustment;

          const newHoverNested = this.maxDepth > this.depth && anchorX - target.x > this.nestedPadding

          if (
            this.hoverIndex !== newHoverIndex ||
            this.hoverNested !== newHoverNested ||
            this.hoverAfter !== newHoverAfter
          ) {
            this.hoverIndex = newHoverIndex;
            this.hoverNested = newHoverNested;
            this.hoverAfter = newHoverAfter;
            // this.cd.detectChanges();
          }
        });
    });

    this.dndService.getDraggableBounding()
      .pipe(
        filter(() => !this.disabled),
        takeUntil(this.alive)
      )
      .subscribe(draggableBounding => {
        this.draggableBounding = draggableBounding;
      });

    this.dndService.getDragIds()
      .pipe(
        filter(() => !this.disabled),
        takeUntil(this.alive)
      )
      .subscribe((ids: string[]) => {
        this.dragIds = ids;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('tree' in changes) {
      this.treeChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleDrop({ data }: { data: Stitch[] }) {
    this.dragHover.next(false);

    const nestedData = data.map(item => ({
      id: item.id,
      parentId: null, // parentId doesn't matter because we are moving item to a new place anyway
      position: null, // the same for position
      data: item
    }));

    this.dndListService.move(
      nestedData,
      this.tree[this.hoverIndex]?.id,
      this.hoverAfter,
      this.hoverNested
    );
  }

  handleEnter() {
    this.dragHover.next(true);
  }

  handleLeave() {
    this.dragHover.next(false);
    this.hoverIndex = null;
    this.hoverEmpty = false;
  }

  // trackByFn(index, item) { ngFor;trackBy + animation is broken
}
