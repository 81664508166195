// Common
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { Knot } from '@modules/knots/types/knot';
import { ManageListState } from '@modules/knots/types/manage-list-state';
import { VirtualScrollDataSource } from '@modules/scroll/types/virtual-scroll-datasource';
import { StateKey } from '@modules/settings/types/state-key';
import { KnotFilters } from '@modules/knots/types/knot-filters';

// RX
import { switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, of, Subject } from 'rxjs';

// Services
import { KnotsService } from '@modules/knots/services/knots.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Components
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-knots-form',
  templateUrl: './knots-form.component.html',
  styleUrls: ['./knots-form.component.less']
})
export class KnotsFormComponent implements AfterViewInit, OnInit, OnDestroy {

  // Inputs
  @Input() stitchItem: Stitch;
  @Input() existingKnots: Knot[] = [];

  // Outputs
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter<Knot[]>();

  // ViewChildren
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;

  // Public
  public inputControl = new UntypedFormControl('');
  public scrollShadowTop = false;
  public scrollShadowBottom = false;
  public canAdd = false;
  public dataSource: VirtualScrollDataSource<Knot>;
  public count = 0;
  public currentPage = 0;
  public state: ManageListState;
  public itemsPerPage = 12;
  public itemSize = 24;
  public lockScrollPagination = false;
  public stateKey = StateKey.sidebarKnotsPopoverState;

  // Private
  private alive = new Subject<void>();
  private filters = new BehaviorSubject<KnotFilters>(null);
  private changedKnots: { [id: number]: Knot } = {};
  private deletedKnots: { [id: number]: Knot } = {};

  /**
   * Constructor
   */

  constructor (
    private knotsService: KnotsService,
    private ngZone: NgZone,
    private toasterService: ToasterService
  ) {
    this.dataSource = new VirtualScrollDataSource(this.knotsService, this.filters);
  }

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.viewport.elementScrolled()
        .pipe(takeUntil(this.alive))
        .subscribe(() => {
          const offset = this.viewport.measureScrollOffset();
          const top = offset !== 0;
          const bottom = offset < this.count * this.itemSize - 300 - 4;
          const currentPage = Math.floor(offset / (this.itemsPerPage * this.itemSize));

          if (!this.lockScrollPagination && this.currentPage !== currentPage) {
            this.currentPage = currentPage;
          } else if (this.lockScrollPagination && this.currentPage === currentPage) {
            this.lockScrollPagination = false;
          }

          if (this.scrollShadowTop !== top) {
            this.ngZone.run(() => {
              this.scrollShadowTop = top;
            });
          }

          if (this.scrollShadowBottom !== bottom) {
            this.ngZone.run(() => {
              this.scrollShadowBottom = bottom;
            });
          }
      });
    });
  }

  ngOnInit() {
    this.inputControl.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(value => {
        this.filters.next(new KnotFilters({
          ...this.filters.value,
          query: value
        }));

        this.canAdd = Knot.normalizeName(value) !== '';
      });

    this.dataSource.dataSizeChanged
      .pipe(takeUntil(this.alive))
      .subscribe(count => {
        this.count = count;
        if (this.count > this.itemsPerPage) {
          this.scrollShadowBottom = true;
        }
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  addKnot() {
    if (!this.canAdd) { return; }

    this.knotsService.createBulk([{ name: this.inputControl.value }]);
  }

  handlePin(index: number) {
    const knot = this.dataSource.cachedData[index];
    knot.pinned = !knot.pinned;

    this.changedKnots[knot.id] = knot;
  }

  handleDelete(index: number) {
    const knot = this.dataSource.cachedData[index];
    knot.deleted = !knot.deleted;

    if (knot.deleted) {
      this.deletedKnots[knot.id] = knot;
    } else {
      delete this.deletedKnots[knot.id];
    }
  }

  handleChange(index: number) {
    const knot = this.dataSource.cachedData[index];

    this.changedKnots[knot.id] = knot;
  }

  handleCancel() {
    this.close.emit();
  }

  handleSave() {
    if (this.save.observers.length) {
      // this.save.emit(this.knots);
      return;
    }

    const changedKnots = Object.values(this.changedKnots);
    const deletedKnots = Object.values(this.deletedKnots);

    (changedKnots.length
      ? this.knotsService.updateBulk(changedKnots, false, false)
      : of(true)
    )
      .pipe(
        switchMap(() => (deletedKnots.length
          ? this.knotsService.deletePermanently({ ids: deletedKnots.map(k => k.id) }, null, false, false)
          : of(true)
        )),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.toasterService.show({
          text: 'Changes applied successfully.',
          icon: 'pellets'
        });
        this.knotsService.forceRefresh();
        this.close.emit();
      });
  }

  setState(state: ManageListState) {
    this.state = state;

    if (this.dataSource && this.state?.sort?.by === 'name') {
      this.dataSource.transformStrategy = 'highlightFirstLetter';
      this.dataSource.transformField = 'name';
    } else {
      this.dataSource.transformStrategy = 'none';
    }

    this.filters.next(new KnotFilters({
      ...KnotFilters.fromManageListState(state),
      query: this.inputControl.value
    }));
  }

  setCurrentPage(value: number) {
    this.currentPage = value;
    this.lockScrollPagination = true;
    this.viewport.scrollToOffset(value * this.itemsPerPage * this.itemSize, 'smooth');
  }
}
