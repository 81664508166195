// Common
import { Component, Injector } from '@angular/core';

// Utils
import { Like } from '@modules/common/types/like';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { Note } from '@modules/notes/types/note';
import { Notebook } from '@modules/notes/types/notebook';
import { VirtualFolder } from '@modules/notes/types/virtual-folder';
import { Application } from '@modules/common/types/application';

// Services
import { NotesAppStateService } from '@modules/notes/services/state.service';
import { NotesService } from '@modules/notes/services/notes.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';

// Components
import { BaseSidebarContextMenuComponent } from '@modules/common/components/base-sidebar-context-menu/base-sidebar-context-menu.component';

@Component({
  selector: 'app-sidebar-note-context-menu',
  templateUrl: './sidebar-context-menu.component.html',
  styleUrls: ['./sidebar-context-menu.component.less']
})
export class SidebarContextMenuComponent extends BaseSidebarContextMenuComponent<Note, Notebook, VirtualFolder> {
  advancedSearchStateKey = Application.notes;

  constructor (
    injector: Injector,
    notesService: NotesService,
    notesStateService: NotesAppStateService,
    notebooksService: NotebooksService,
  ) {
    super(injector, notesService, notebooksService, notesStateService);
  }

  protected getCommonParams(): Like<Note | Notebook> {
    let params: Like<Note | Notebook> = {};

    switch (this.sidebarFilterKey) {
      case 'all_notes':
      case 'all_notebooks':
      case 'upcoming':
      case 'recent':
        break;
      case 'followed':
      case 'archived':
      case 'deleted':
      case 'snoozed':
      case 'flagged':
        params = super.getCommonParams();
        break;
      default:
        checkExhaustiveness(this.sidebarFilterKey);
    }

    return params;
  }

  protected stitchItemFactory(params: Like<Note>): Note {
    return new Note({ ...params, notebookId: this.selectedContainerStitchItemId });
  }

  protected containerStitchItemFactory(params: Like<Notebook>): Notebook {
    return new Notebook({ ...params, parentId: this.selectedContainerStitchItemId });
  }
}
