// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Modules
import { AuthRoutingModule } from './auth-routing.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { MatIconModule } from '@angular/material/icon';
import { UtilsModule } from '@modules/utils/utils.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

// Services
import { AuthService } from './services/auth.service';
import { GoogleApiService } from './services/google-api.service';
import { AuthGuard } from './services/auth-guard.service';
import { UnauthGuard } from './services/unauth-guard.service';
import { AdminGuard } from './services/admin.guard';

// Components
import { SigninComponent } from './components/signin/signin.component';
import { SignupComponent } from './components/signup/signup.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { RestorePasswordComponent } from './components/restore-password/restore-password.component';

// Validators
import { UserValidator } from './validators/user.validator';

@NgModule({
  imports: [
    AuthRoutingModule,
    CommonModule,
    FormControlsModule,
    FormsModule,
    MatIconModule,
    UtilsModule,
    ScrollingModule,
  ],
  declarations: [
    ForgotPasswordComponent,
    RestorePasswordComponent,
    SigninComponent,
    SignupComponent,
    WelcomeComponent,
  ],
  providers: [
    GoogleApiService,
    AuthService,
    AuthGuard,
    UnauthGuard,
    AdminGuard,

    UserValidator,
  ]
})
export class AuthModule { }
