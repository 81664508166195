<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <input
    class="form-title"
    placeholder="Untitled Note"
    [formControl]="form.controls.title"
    #titleInput
  />

  <app-color-input
    class="form-marker"
    icon="note-small"
    [control]="form.controls.color"
    [size]="16"
  ></app-color-input>

  <div class="form-date">
    <div class="form-date-notebook">
      {{ notebook?.title || 'No Notebook'}}
    </div>
  </div>

  <div class="form-quick-actions">
    <mat-icon
      class="form-quick-actions-item"
      [class.active]="form.controls.notebookId.value"
      svgIcon="quick-project"
      matTooltip="Notebook"

      stchPopover
      [stchPopoverArrow]="true"
      stchPopoverPlacement="right"
      stchPopoverTrigger="click"
      [stchPopoverContent]="notebooksPopoverTemplate"
      [stchPopoverShowUntil]="popoverClose"
    ></mat-icon>
  </div>

  <div class="form-buttons">
    <div
      *ngIf="!inline"
      class="form-buttons-item"
      (click)="clickMore()"
    >
      More
    </div>

    <div class="form-buttons-filler"></div>

    <div
      *ngIf="!inline"
      class="form-buttons-item form-buttons-item-cancel"
      (click)="!saveInProgress && cancel()"
    >
      Cancel
    </div>

    <div
      class="form-buttons-item"
      (click)="!saveInProgress && submit()"
    >
      Add
    </div>
  </div>
</div>

<ng-template #notebooksPopoverTemplate>
  <div class="notebooks-popover">
    <div class="notebooks-popover-title">
      Add to Notebook
    </div>
    <app-simple-input
      class="form-details-row-input"
      [suggestions]="notebooksSuggestions"
      [inputFormControl]="form.controls.notebookId"
      placeholder="Select Notebook"
      (onSelect)="handleSelectNotebook($event)"
    ></app-simple-input>

    <div class="notebooks-popover-buttons">
      <app-button appearance="square" size="medium" (click)="handleCancelNotebooks()" label="Cancel" />
      <app-button appearance="square-blue" size="medium" (click)="handleSaveNotebooks()" label="Save" />
    </div>
  </div>
</ng-template>
