// Common
import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

// Types
import { Upload } from '@modules/common/types/upload';

@Injectable()
export class FileValidators {
  static mimeTypes(types: string[]) {
    return (control: FormControl<Upload | Upload[]>): ValidationErrors | null => {
      if (control.value instanceof Upload) {
        return types.includes(control.value.type) ? null : { mimeTypes: true };
      } else if (control.value?.length) {
        return control.value.every(i => types.includes(i.type)) ? null : { mimeTypes: true };
      }
    };
  }

  static size(max: number) {
    return (control: FormControl<Upload | Upload[]>): ValidationErrors | null => {
      if (control.value instanceof Upload) {
        return control.value?.size < max ? null : { size: true };
      } else if (control.value?.length) {
        return control.value.every(i => i.size < max) ? null : { size: true };
      }
    };
  }
}
