// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { AdminModule } from '@modules/admin/admin.module';
import { AttachmentsModule } from '@modules/attachments/attachments.module';
import { CalendarAppModule } from '@modules/calendar-app/calendar-app.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { ContactsModule } from '@modules/contacts/contacts.module';
import { DynamicPanelModule } from '../dynamic-panel/dynamic-panel.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { FilesModule } from '@modules/files/files.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { MatIconModule } from '@angular/material/icon';
import { MessagesModule } from '@modules/messages/messages.module';
import { NotesModule } from '@modules/notes/notes.module';
import { NotificationModule } from '@modules/notification/notification.module';
import { PagesRoutingModule } from './pages-routing.module';
import { PopoverModule } from '@modules/popover/popover.module';
import { SearchModule } from '@modules/search/search.module';
import { SettingsModule } from '@modules/settings/settings.module';
import { SplitViewModule } from '@modules/split-view/split-view.module';
import { TasksModule } from '@modules/tasks/tasks.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { AdminLayoutComponent } from './components/admin-layout/admin-layout.component';
import { ContactsLayoutComponent } from './components/contacts-layout/contacts-layout.component';
import { EventsLayoutComponent } from './components/events-layout/events-layout.component';
import { FilesLayoutComponent } from './components/files-layout/files-layout.component';
import { LayoutComponent } from './components/layout/layout.component';
import { MailLayoutComponent } from './components/mail-layout/mail-layout.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { NotesLayoutComponent } from './components/notes-layout/notes-layout.component';
import { SettingsLayoutComponent } from './components/settings-layout/settings-layout.component';
import { SharedLayoutComponent } from './components/shared-layout/shared-layout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TaskingLayoutComponent } from './components/tasking-layout/tasking-layout.component';

@NgModule({
  imports: [
    AdminModule,
    AppCommonModule,
    AttachmentsModule,
    CalendarAppModule,
    CommonModule,
    ContactsModule,
    DynamicPanelModule,
    ElementsModule,
    FilesModule,
    LinkedInfoModule,
    MatIconModule,
    MessagesModule,
    NotesModule,
    NotificationModule,
    PagesRoutingModule,
    PopoverModule,
    SearchModule,
    SettingsModule,
    SplitViewModule,
    TasksModule,
    UtilsModule,
  ],
  declarations: [
    AdminLayoutComponent,
    ContactsLayoutComponent,
    EventsLayoutComponent,
    FilesLayoutComponent,
    LayoutComponent,
    MailLayoutComponent,
    NavBarComponent,
    NotesLayoutComponent,
    SettingsLayoutComponent,
    SharedLayoutComponent,
    SidebarComponent,
    TaskingLayoutComponent
  ],
  exports: [
    LayoutComponent
  ],
})
export class PagesModule { }
