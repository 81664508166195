// Common
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import phoneParser from 'libphonenumber-js';

// RxJs
import { Subject, combineLatest } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.less']
})
export class PhoneNumberInputComponent implements OnInit, OnChanges, OnDestroy {

  /**
  * Inputs
  **/
  @Input() inputFormControl: UntypedFormControl;
  @Input() noBorder = true;

  /**
  * Public
  **/
  public phoneCode = new UntypedFormControl('');
  public phoneNumber = new UntypedFormControl('');

  /**
  * Private
  **/
  private inputChanged = new Subject<void>();
  private alive = new Subject<void>();

  /**
  * Constructor
  **/
  constructor() { }

  /**
  * Lifecycle
  **/
  ngOnInit(): void {
    this.inputChanged
      .pipe(
        switchMap(() => combineLatest([this.phoneCode.valueChanges, this.phoneNumber.valueChanges])),
        takeUntil(this.alive)
      )
      .subscribe(([phoneCode, phoneNumber]) => {
        const parsedPhone = phoneParser(`${ phoneCode }${ phoneNumber }`);
        this.inputFormControl.setValue(`${ phoneCode }${ phoneNumber }`);

        if (!parsedPhone?.isValid()) {
          // TODO move to Contact class, display validation errors on UI
          // this.inputFormControl.setErrors({ notValid: true });
          // this.phoneNumber.setErrors({ notValid: true });
        }
      });

    const phone = this.inputFormControl.value &&
      phoneParser(typeof this.inputFormControl.value === 'string' ? this.inputFormControl.value : '');

    if (phone) {
      this.phoneCode.setValue('+' + phone.countryCallingCode);
      this.phoneNumber.setValue(phone.nationalNumber, { emitEvent: false });
    }

    this.inputChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('inputFormControl' in changes) {
      this.inputChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

}
