// Common
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { Connection } from '@modules/connections/types/connection';
import { ManageListState } from '@modules/connections/types/manage-list-state';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

// RX
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, of, Subject } from 'rxjs';

// Services
import { ConnectionsService } from '@modules/connections/services/connections.service';

@Component({
  selector: 'app-add-connections',
  templateUrl: './add-connections.component.html',
  styleUrls: ['./add-connections.component.less']
})
export class AddConnectionsComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() stitchItems: Stitch[];
  @Input() control: UntypedFormControl;

  // Outputs
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter<Connection[]>();

  // Public
  public inputControl = new UntypedFormControl('');
  public canAdd = false;
  public state: ManageListState = {
    sort: {
      by: 'name',
      order: 'asc'
    },
    filters: {
      createdFrom: null,
      createdTo: null
    }
  };
  public connections: Connection[] = [];
  public suggestions: AutocompleteFactory<Connection>;
  public suggestionsReceived = false;
  public focused = false;

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor (
    private connectionsService: ConnectionsService
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.suggestions = (query?, values?, { limit }: { limit: number } = { limit: 5 }) => {
      if (!query || query.trim() === '') {
        return of([]);
      }

      if (query.trim().split(',').length > 1) {
        this.suggestionsReceived = true;
        return of([]);
      }

      this.suggestionsReceived = false;

      return this.connectionsService.search({ limit, query })
        .pipe(
          tap(() => this.suggestionsReceived = true),
          map(({ items: connections }) => connections.map(connection => ({
            title: connection.name,
            value: connection.name,
            source: connection
          }))),
        );
    };

    this.inputControl.valueChanges
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((value: string) => {
        const newConnections = (value || '')
          .split(',')
          .map((item) => item.trim())
          .filter(item => !!item);

        const connectionsNames = this.connections.map(({ name }) => name);

        this.canAdd = newConnections.some((item) => !connectionsNames.includes(item));
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  addConnection() {
    if (!this.canAdd) { return; }

    const names: string[] = this.inputControl.value
      .split(',')
      .map((item) => item.trim())
      .filter(item => !!item && !this.connections.some(({ name }) => name === item));

    if (!names.length) { return; }

    this.connectionsService.search({ names })
      .pipe(
        take(1),
        takeUntil(this.alive)
      )
      .subscribe(({ items }) => {
        this.connections = [
          ...this.connections,
          ...names
            .map(name => new Connection({ ...(items.find(i => i.name === name) || { name }), added: true }))
        ]
      });

    this.inputControl.setValue('');
  }

  handleDelete(connection: Connection) {
    if (connection.added) {
      this.connections = this.connections.filter(item => item.name !== connection.name);
    } else {
      this.connections = this.connections.map(item => item.name === connection.name
        ? new Connection({ ...connection, deleted: true })
        : item
      );
    }
  }

  handleCancel() {
    this.close.emit();
  }

  handleSelect(option: DropdownSelectItem<Connection>) {
    this.connections = [...this.connections, new Connection({ ...option.source, added: true })];
    this.inputControl.setValue('');
  }

  handleSave() {
    if (this.save.observers.length > 0 ) {
      this.save.emit(this.connections);
      this.close.emit();
    } else {
      const newConnections = this.connections.filter(connection => connection.added && !connection.id);
      const changedConnections = this.connections.filter(connection => connection.changed && !!connection.id);
      const linkedConnections = this.connections.filter(connection => connection.added);
      const unlinkedConnections = this.connections.filter(connection => connection.deleted);

      combineLatest([
        newConnections.length ? this.connectionsService.createBulk(newConnections, false, false) : of(true),
        changedConnections.length ? this.connectionsService.update(changedConnections, false, false) : of(true)
      ])
        .pipe(
          switchMap(([created, updated]) => (
            created && updated && this.stitchItems?.length
              ? combineLatest([
                linkedConnections.length ? this.connectionsService.link(linkedConnections, this.stitchItems, false, false) : of(true),
                unlinkedConnections.length ? this.connectionsService.unlink(unlinkedConnections, this.stitchItems, false, false) : of(true)
              ])
              : of(null)
          )),
          takeUntil(this.alive)
        )
        .subscribe(() => {
          this.connectionsService.forceRefresh();
          this.close.emit();
        });
    }
  }
}
