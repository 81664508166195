<div class="form">
  <div class="form-date">{{ group?.createdAt | date : 'MMM d' }}</div>

  <h2 class="form-title">
    <mat-icon
      class="form-heading-title-icon"
      svgIcon="groups"
      [style.color]="group?.color"
    />
    {{ group?.title }}
  </h2>

  <div class="form-details">
    <div class="form-details-row">
      <div class="form-details-row-title">
        Parent Group
      </div>
      <div class="form-details-row-input">{{ group?.parentId }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Vip</div>
      <div class="form-details-row-input">{{ group?.vip }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">
        Country
      </div>
      <div class="form-details-row-input">{{ group?.country }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">
        Email
      </div>
      <div class="form-details-row-input">{{ group?.email }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">
        Phone Number
      </div>
      <div class="form-details-row-input">{{ group?.phoneNumber }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">
        Address
      </div>
      <div class="form-details-row-input">{{ group?.address }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">
        Website
      </div>
      <div class="form-details-row-input">{{ group?.website }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">
        Description
      </div>
      <div class="form-details-row-input">{{ group?.description }}</div>
    </div>
  </div>
</div>
