import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToWords',
  pure: true
})
export class DateToWordsPipe implements PipeTransform {
  constructor (
    private datePipe: DatePipe
  ) {}

  transform(value: Date, format?: string, possessive: boolean = false): string {
    if (!value) return '';

    const now = new Date();
    const diffDays = Math.ceil((value.getTime() - now.getTime()) / (1000 * 3600 * 24));

    if (diffDays > 1 || diffDays < -1) {
      return this.datePipe.transform(value, format);
    }

    let result: string;

    switch (diffDays) {
      case -1:
        result = 'yesterday';
        break;
      case 0:
        result = 'today';
        break;
      case 1:
        result = 'tomorrow';
        break;
    }

    return result + (possessive ? '\'s' : '');
  }
}
