// Common
import { Directive, Input, ComponentRef, Output, EventEmitter, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { FolderContextMenuComponent } from '../components/context-menus/folder-context-menu/folder-context-menu.component';

// Types
import { Folder } from '@modules/files/types/folder';
import { File } from '@modules/files/types/file';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appFolderContextMenu]'
})
export class FolderContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() appFolderContextMenu: Folder;
  @Input() appFolderContextMenuDisabled = false;
  @Input() appFolderContextMenuMode: 'contextmenu' | 'popover' = 'contextmenu';

  // Outputs
  @Output() appFolderContextMenuOpened = this.contextMenuOpened;
  @Output() appFolderContextMenuOpenFolder = new EventEmitter<void>();
  @Output() appFolderContextMenuOpenFile = new EventEmitter<File>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.stchPopoverDisabled = this.appFolderContextMenuDisabled;

    if (this.appFolderContextMenuMode === 'popover') {
      this.stchPopoverTrigger = 'click';
      this.stchPopoverPlacement = 'bottomLeft';
      this.stchPopoverFallbackPlacements = ['bottomRight'];
    }
  }

  /**
   * Actions
   */

  registerComponent() {
    return FolderContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<FolderContextMenuComponent>) {
    componentRef.instance.folder = this.appFolderContextMenu;
    componentRef.instance.open
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.appFolderContextMenuOpenFolder.emit();
      });

    componentRef.instance.openFile
      .pipe(takeUntil(this.alive))
      .subscribe(file => {
        this.appFolderContextMenuOpenFile.emit(file);
      });
  }
}
