// Common
import { Component, Injector } from '@angular/core';

// Utils
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { Like } from '@modules/common/types/like';

// Types
import { Contact } from '@modules/contacts/types/contact';
import { Group } from '@modules/contacts/types/group';
import { VirtualFolder } from '@modules/contacts/types/virtual-folder';
import { Application } from '@modules/common/types/application';

// Services
import { ContactsAppStateService } from '@modules/contacts/services/state.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';
import { GroupsService } from '@modules/contacts/services/groups.service';

// Components
import { BaseSidebarContextMenuComponent } from '@modules/common/components/base-sidebar-context-menu/base-sidebar-context-menu.component';

@Component({
  selector: 'app-sidebar-context-menu',
  templateUrl: './sidebar-context-menu.component.html',
  styleUrls: ['./sidebar-context-menu.component.less']
})
export class SidebarContextMenuComponent extends BaseSidebarContextMenuComponent<Contact, Group, VirtualFolder> {

  advancedSearchStateKey = Application.contacts;

  constructor (
    injector: Injector,
    contactsService: ContactsService,
    contactsStateService: ContactsAppStateService,
    groupsService: GroupsService,
  ) {
    super(injector, contactsService, groupsService, contactsStateService);
  }

  protected getCommonParams(): Like<Contact | Group> {
    let params: Like<Contact | Group> = {};

    switch (this.sidebarFilterKey) {
      case 'all_contacts':
      case 'all_groups':
      case 'upcoming':
      case 'frequent':
      case 'recent':
        break;
      case 'vip':
        params.vip = true;
        break;
      case 'archived':
      case 'snoozed':
      case 'deleted':
      case 'flagged':
      case 'followed':
        params = super.getCommonParams();
        break;
      default:
        checkExhaustiveness(this.sidebarFilterKey);
    }

    return params;
  }

  protected stitchItemFactory(params: Like<Contact>): Contact {
    return new Contact({ ...params, groupId: this.selectedContainerStitchItemId });
  }

  protected containerStitchItemFactory(params: Like<Group>): Group {
    return new Group({ ...params, containerId: this.selectedContainerStitchItemId });
  }
}
