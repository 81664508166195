<div
  *ngIf="folder"
  class="folder"
  [class.selected]="selected && !isDragging"
  [class.pinned]="folder.pinned"
  [class.snoozed]="folder.snoozed"
  [class.followed]="folder.followed"
  [class.hovered]="contextMenuOpened"

  [appMessageFolderContextMenu]="folder"
  (appMessageFolderContextMenuOpened)="contextMenuOpened = $event"
  (appMessageFolderContextMenuOpenFolder)="open.emit()"

  appDraggable
  [appDraggableDisabled]="!dragEnabled"
  [appDraggableData]="dragData"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="folder-selection"></div>
  <div class="folder-body">
    <div class="folder-body-title">
      <div
        class="folder-body-title-text"
        [class.blank]="!folder.title"
      >
        {{ folder.title || 'Untitled Folder' }}
      </div>
    </div>

    <div class="folder-body-date">
      <div class="folder-body-date-item">
        {{ folder.createdAt ? 'Created: ' + (folder.createdAt | date : 'MMM d') : 'No Created' }}
      </div>
      <div class="folder-body-date-separator"></div>
      <div class="folder-body-date-item">
        {{ folder.updatedAt ? 'Updated: ' + (folder.updatedAt | date : 'MMM d') : 'No Updated' }}
      </div>
    </div>

    <div
      *ngIf="!withTags && !withKnots"
      class="folder-body-description"
    >
      {{ folder.description || 'No description' }}
    </div>

    <app-list-item-tags
      *ngIf="withTags"
      class="folder-body-tags"
      [tags]="folder.tags"
    ></app-list-item-tags>

    <app-list-item-knots
      *ngIf="withKnots"
      class="folder-body-tags"
      [knots]="folder.knots"
    ></app-list-item-knots>

    <app-link-info-bar [stitch]="folder"></app-link-info-bar>
  </div>

  <mat-icon
    class="folder-pin-icon"
    [class.active]="folder.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  ></mat-icon>

  <mat-icon
    class="folder-flag-icon"
    [class.active]="folder.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  ></mat-icon>

  <mat-icon
    class="folder-marker"
    [svgIcon]="folder.archived ? 'folder-archive' : 'folder'"
    [style.color]="folder.color"
  ></mat-icon>

  <div
    class="folder-hover-icons"
    *ngIf="dragEnabled && actionsButtonEnabled"
    (click)="$event.stopPropagation()"
  >
    <mat-icon
      class="folder-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="folder"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    ></mat-icon>
    <mat-icon
      class="folder-hover-icons-item"
      svgIcon="archive"
      (click)="archive()"
      [matTooltip]="folder?.archived ? 'Restore from Archive' : 'Archive'"
    ></mat-icon>
    <mat-icon
      class="folder-hover-icons-item"
      svgIcon="trash"
      (click)="delete()"
      [matTooltip]="folder?.deleted ? 'Restore from Trash' : 'Trash'"
    ></mat-icon>
  </div>

  <div class="drop-zone"></div>

  <div *ngIf="debug" class="folder-debug">{{ folder.score }}</div>
</div>

<div class="placeholder" *ngIf="!folder">
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
  <div class="placeholder-11"></div>
  <div class="placeholder-12"></div>
</div>

<ng-template #dragPlaceholder>
  <app-message-folder [item]="folder" [dragEnabled]="false"></app-message-folder>
</ng-template>

<ng-template #afterDropTemplate let-dragData=dragData>
  <app-button (click)="handleMove(dragData)" label="Move" />
  <app-button (click)="handleStitch(dragData)" label="Stitch" />
</ng-template>
