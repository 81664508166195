// Common
import { Component, Injector, OnInit } from '@angular/core';

// Utils
import { Like } from '@modules/common/types/like';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { VirtualFolder } from '@modules/messages/types/virtual-folder';
import { MessagesFilters } from '@modules/messages/types/messages-filters';
import { MailAppStateService } from '@modules/messages/services/state.service';
import { MessageFolder } from '@modules/messages/types/message-folder';
import { Message } from '@modules/messages/types/message';
import { Application } from '@modules/common/types/application';
import { Settings } from '@modules/messages/types/settings';

// Services
import { MessageFoldersService } from '@modules/messages/services/message-folders.service';
import { MessagesService } from '@modules/messages/services/messages.service';
import { MailAppSettingsService } from '@modules/messages/services/settings.service';

// RX
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

// Components
import { BaseSidebarContextMenuComponent } from '@modules/common/components/base-sidebar-context-menu/base-sidebar-context-menu.component';

@Component({
  selector: 'app-sidebar-context-menu',
  templateUrl: './sidebar-context-menu.component.html',
  styleUrls: ['./sidebar-context-menu.component.less']
})
export class SidebarContextMenuComponent extends BaseSidebarContextMenuComponent<Message, MessageFolder, VirtualFolder> implements OnInit {
  advancedSearchStateKey = Application.mail;

  public filters = new Settings().asFormGroup();

  constructor (
    injector: Injector,
    private messagesService: MessagesService,
    messageFoldersService: MessageFoldersService,
    messagesStateService: MailAppStateService,
    private settingsService: MailAppSettingsService
  ) {
    super(injector, messagesService, messageFoldersService, messagesStateService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.settingsService.listAll(true)
      .pipe(
        tap(settings => {
          this.filters = settings.asFormGroup();
        }),
        switchMap(() => this.filters.valueChanges),
        debounceTime(500),
        switchMap(() =>
          this.settingsService.update(Settings.fromFormGroup(this.filters))
        ),
        takeUntil(this.alive)
      )
      .subscribe();
  }

  public readAll() {
    const filters = new MessagesFilters();
    filters.applyVirtualFolder(this.sidebarFilterKey);

    this.messagesService.read(filters, true);
  }

  public sendAll() {
    this.messagesService.sendAll();
  }

  protected getCommonParams(): Like<Message | MessageFolder> {
    let params: Like<Message | MessageFolder> = {};

    switch (this.sidebarFilterKey) {
      case 'all_messages':
      case 'all_folders':
      case 'spam':
      case 'sent':
      case 'scheduled':
      case 'draft':
      case 'bulk':
        break;
      case 'snoozed':
      case 'archived':
      case 'deleted':
      case 'flagged':
      case 'followed':
        params = super.getCommonParams();
        break;
      default:
        checkExhaustiveness(this.sidebarFilterKey);
    }

    return params;
  }

  protected stitchItemFactory(params: Like<Message>): Message {
    return new Message({ ...params, folderId: this.selectedContainerStitchItemId });
  }

  protected containerStitchItemFactory(params: Like<MessageFolder>): MessageFolder {
    return new MessageFolder({ ...params, containerId: this.selectedContainerStitchItemId });
  }
}
