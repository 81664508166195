<div
  *ngIf="!item"
  class="empty-state"
>
  <img class="empty-state-image" src="assets/placeholders/placeholder-files.svg"/>
</div>

<ng-container
  *ngIf="item"
  [ngSwitch]="item | stitchType"
>
  <app-message-folder-form
    *ngSwitchCase="StitchType.messageFolder"
    [folder]="item"
    (close)="handleFormClose()"
  />

  <app-message2-form
    *ngSwitchCase="StitchType.message"
    [message]="item"
    [autoSaveAllowed]="false"
    (close)="handleFormClose()"
  />
</ng-container>
