// Common
import { Pipe, PipeTransform } from '@angular/core';
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { Icon } from '@modules/icons/types/icons';

// Types
import { Application } from '../../common/types/application';

@Pipe({
  name: 'appIcon'
})
export class AppIconPipe implements PipeTransform {

  transform(app: Application): Icon {
    if (!app) { return null }

    switch (app) {
      case Application.mail:
        return 'mail';
      case Application.calendar:
        return 'calendar-today';
      case Application.tasking:
        return 'project';
      case Application.notes:
        return 'notebook';
      case Application.contacts:
        return 'groups';
      case Application.files:
        return 'folder';
      case Application.kp:
        return 'kp';
      case Application.settings:
        return 'kp';
      case Application.admin:
        return 'kp';
      default:
        return checkExhaustiveness(app);
    }
  }
}
