// Common
import { ComponentRef, Directive, Input } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { SidebarTagsKnotsContextMenuComponent } from '../components/sidebar-tags-knots-context-menu/sidebar-tags-knots-context-menu.component';

@Directive({
  selector: '[appSidebarTagsKnotsContextMenu]'
})
export class SidebarTagsKnotsContextMenuDirective extends ContextMenuDirective {

  // Inputs
  @Input() appSidebarTagsKnotsContextMenu: 'tags' | 'knots';

  registerComponent() {
    return SidebarTagsKnotsContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<SidebarTagsKnotsContextMenuComponent>) {
    componentRef.instance.appearance = this.appSidebarTagsKnotsContextMenu;
  }
}
