<div class="heading1">Shared</div> 
<div class="heading2-subtitle">Shared {{ stitchType | titlecase }}s.</div>

<stch-table
  class="items"
  [items]="items"
  [columns]="columns"
  [page]="page"
  [perPage]="perPage"
  [count]="count"
  [sortBy]="sortBy"
  [sortOrder]="sortOrder"
  (pageChange)="handlePageChange($event)"
  (sortByChange)="handleSortByChange($event)"
  (sortOrderChange)="handleSortOrderChange($event)"
  (rowClick)="handleRowClick($event)"
/>

<ng-template #dateTemplate let-item=item>
  {{ item.sharedViaLinkAt | date : 'mediumDate' }}
</ng-template>

<ng-template #linkTemplate let-item=item>
  <p
    *ngIf="item.shareUid"
    (click)="goToLink($event, item)"
  >
    {{ item.sharedLink }}
  </p>
</ng-template>
