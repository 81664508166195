<div class="combined-dropdown-input">
  <app-input
    appearance="amethyst"
    class="combined-dropdown-input-input"
    [inputFormControl]="inputFormControl"
  ></app-input>

  <div class="combined-dropdown-input-delimiter"></div>

  <app-dropdown-input
    class="combined-dropdown-input-dropdown"
    [inputFormControl]="dropDownFormControl"
    [options]="dropDownOptions"
    appearance="amethyst-simple"
  ></app-dropdown-input>
</div>
