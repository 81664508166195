// Common
import { ComponentRef, Directive, EventEmitter, Input, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { CalendarCellContextMenuComponent } from '../components/calendar-cell-context-menu/calendar-cell-context-menu.component';

// Types
import { CalendarType } from '@modules/calendar-app/types/calendar-type';

@Directive({
  selector: '[appCalendarCellContextMenu]'
})
export class CalendarCellContextMenuDirective extends ContextMenuDirective {

  // Inputs
  @Input() appCalendarCellContextMenu: Date;
  @Input() appCalendarCellContextMenuCalendarType: CalendarType;

  // Outputs
  @Output() appCalendarCellContextMenuLoadDayEvents: EventEmitter<Date> = new EventEmitter<Date>();

  registerComponent() {
    return CalendarCellContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<CalendarCellContextMenuComponent>) {
    componentRef.instance.date = this.appCalendarCellContextMenu;
    componentRef.instance.calendarType = this.appCalendarCellContextMenuCalendarType;
    componentRef.instance.loadDayEvents = this.appCalendarCellContextMenuLoadDayEvents;
  }
}
