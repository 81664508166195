// Common
import { Component, Output, EventEmitter, OnInit, OnDestroy, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { CLOSE_POPOVER } from '@modules/popover/types/close-popover.injection-token';

// Services
import { SelectableService } from '@modules/drag-n-drop/services/selectable.service';
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { BaseStitchService } from '@modules/common/services/base-stitch.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { AlertService } from '@modules/alert/services/alert.service';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { Application } from '@modules/common/types/application';

@Component({ template: '' })
export class StitchContextMenuComponent<T extends Stitch> implements OnInit, OnDestroy {

  @Output() open = new EventEmitter();

  public item: T;
  public selectedItems: T[] = [];
  public multiple = false;
  public selectable = false;

  private closePopoverToken: Subject<void>;
  private selectableService: SelectableService;
  protected ga: GoogleAnalyticsService;
  private modalService: ModalService;
  private dpService: DynamicPanelService;
  private linkedInfoService: LinkedInfoService;
  private alertService: AlertService;
  private router: Router;

  protected gaPrefix: string;
  protected applicationName: Application;
  protected alive = new Subject<void>();

  constructor (
    protected injector: Injector,
    private stitchService: BaseStitchService<Stitch, StitchFilters>
  ) {
    this.closePopoverToken = this.injector.get(CLOSE_POPOVER, undefined, { optional: true });
    this.selectableService = this.injector.get(SelectableService, undefined, { optional: true });
    this.ga = this.injector.get(GoogleAnalyticsService);
    this.modalService = this.injector.get(ModalService);
    this.dpService = this.injector.get(DynamicPanelService);
    this.linkedInfoService = this.injector.get(LinkedInfoService);
    this.alertService = this.injector.get(AlertService);
    this.router = this.injector.get(Router);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.selectable = !!this.selectableService;

    this.selectableService?.getSelectedItems()
      ?.pipe(
        takeUntil(this.alive)
      )
      ?.subscribe((selectedItems: T[]) => {
        this.selectedItems = selectedItems;

        if (selectedItems.length > 1 && selectedItems.some(({ id }) => this.item.id === id)) {
          this.multiple = true;
        } else {
          this.multiple = false;
        }
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  close() {
    this.closePopoverToken.next();
  }

  selectAll() {
    this.selectableService?.triggerSelectAll?.();
  }

  openInModal() {
    if (!this.item) { return; }

    this.ga.trackEvent(this.gaPrefix, 'open modal');

    this.modalService.openFullForm(this.item, this.injector);
  }

  openInDP() {
    if (!this.item) { return; }

    this.ga.trackEvent(this.gaPrefix, 'open DP');

    this.dpService.setFormItem(this.item);
  }

  pin() {
    if (!this.item) { return; }

    this.ga.trackEvent(this.gaPrefix, this.item.pinned ? 'unpin' : 'pin');

    this.stitchService.pin({ ids: this.getIds() }, !this.item.pinned);
  }

  flag() {
    if (!this.item) { return; }

    this.ga.trackEvent(this.gaPrefix, this.item.flagged ? 'unflag' : 'flag');

    this.stitchService.flag({ ids: this.getIds() }, !this.item.flagged);
  }

  archive() {
    if (!this.item) { return; }

    this.ga.trackEvent(this.gaPrefix, this.item.archived ? 'archive' : 'restore-from-archive');

    this.stitchService.archive({ ids: this.getIds() }, !this.item.archived);
  }

  trash() {
    if (!this.item) { return; }

    this.ga.trackEvent(this.gaPrefix, this.item.deleted ? 'trash' : 'restore-from-trash');

    this.stitchService.delete({ ids: this.getIds() }, !this.item.deleted);
  }

  deletePermanently(message?: string) {
    if (!this.item) { return; }

    this.ga.trackEvent(this.gaPrefix, 'delete-permanently');

    this.alertService.show({
      title: 'Are you sure?',
      body: message,
      rightButtons: [
        {
          title: 'CANCEL',
          close: true
        },
        {
          title: 'DELETE PERMANENTLY',
          click: () => {
            this.stitchService.deletePermanently({ ids: this.getIds() });
          },
          close: true
        }
      ]
    });
  }

  stitchAllTogether() {
    this.linkedInfoService.linkItems(this.selectedItems.map(item => new LinkedInfo(item)));
  }

  handleOpen() {
    if (!this.item) { return; }

    this.ga.trackEvent(this.gaPrefix, 'open');

    this.open.emit();
  }

  reprocessKnots() {
    this.stitchService.reprocessKnots(this.multiple ? this.selectedItems : [this.item])
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.close();
      });
  }

  openSettings() {
    this.router.navigate([`/settings/${this.applicationName}`]);
  }

  protected getIds(): string[] {
    return this.multiple ? this.selectedItems.map(item => item.id) : [this.item.id];
  }
}
