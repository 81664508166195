// Common
import { ComponentRef, Directive, EventEmitter, Input, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Types
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { PopoverPlacement } from '@modules/popover/types/placement';

// RX
import { takeUntil } from 'rxjs/operators';

// Components
import { MonthsContextMenuComponent } from '../components/months-context-menu/months-context-menu.component';

@Directive({
  selector: '[appMonthsContextMenu]'
})
export class MonthsContextMenuDirective extends ContextMenuDirective {

  // Inputs
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottom';
  @Input() appMonthsContextMenu: Date;

  // Outputs
  @Output() appMonthsContextMenuOpened = this.contextMenuOpened;
  @Output() appMonthsContextMenuSelect = new EventEmitter<Date>();

  /**
   * Actions
   */

  registerComponent() {
    return MonthsContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<MonthsContextMenuComponent>) {
    componentRef.instance.date = this.appMonthsContextMenu;
    componentRef.instance.select
      .pipe(takeUntil(this.alive))
      .subscribe((date: Date) => this.appMonthsContextMenuSelect.emit(date));
  }
}
