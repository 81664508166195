<div
  *ngIf="contact"
  class="contact"
  [class.selected]="selected && !isDragging"
  [class.pinned]="contact.pinned"
  [class.hovered]="contextMenuOpened"
  [class.snoozed]="contact.snoozed"
  [class.followed]="contact.followed"

  [appContactContextMenu2]="contact"
  (appContactContextMenu2Opened)="contextMenuOpened = $event"
  (appContactContextMenu2OpenContact)="open.emit()"

  appDraggable
  [appDraggableDisabled]="!dragEnabled"
  [appDraggableData]="dragData"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"

  (appClick)="onClick.emit(contact)"
  (appDblClick)="onDoubleClick.emit(contact)"
>
  <div class="contact-selection"></div>

  <div class="contact-avatar-icon">
    {{ contact | contactInitials }}
  </div>

  <div class="contact-body">
    <div class="contact-body-title">
      <div
        class="contact-body-title-text"
        [class.blank]="!contact.title"
      >
        {{ contact.title || 'Untitled Contact' }}
      </div>
    </div>

    <div class="contact-body-date">
      <div
        *ngIf="!withTags && !withKnots"
        class="contact-body-date-item"
        [class.blank]="!contact.emails?.[0]"
      >
        {{ contact.emails?.[0]?.value || 'No Email' }}
      </div>

      <app-list-item-tags
        *ngIf="withTags"
        class="contact-body-tags"
        [tags]="contact.tags"
      ></app-list-item-tags>

      <app-list-item-knots
        *ngIf="withKnots"
        class="contact-body-tags"
        [knots]="contact.knots"
      ></app-list-item-knots>
    </div>

    <app-link-info-bar [stitch]="contact"></app-link-info-bar>
  </div>

  <mat-icon
    class="contact-pin-icon"
    [class.active]="contact.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  ></mat-icon>

  <mat-icon
    class="contact-flag-icon"
    [class.active]="contact.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  ></mat-icon>

  <mat-icon
    class="contact-marker"
    [svgIcon]="contact.archived ? 'folder-archive' : 'contacts'"
    [style.color]="contact.color"
  ></mat-icon>

  <div
    class="contact-hover-icons"
    *ngIf="dragEnabled && actionsButtonEnabled"
    (click)="$event.stopPropagation()"
  >
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="mail"
      matTooltip="Compose message"
      (click)="createMail()"
    ></mat-icon>
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="snooze"
      [matTooltip]="contact?.snoozed ? 'Unsnooze' : 'Snooze'"
      [class.active]="!!contact?.snoozed"
      (click)="snooze()"
    ></mat-icon>
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="follow-up"
      [matTooltip]="contact?.followed ? 'Unfollow' : 'Follow up'"
      [class.active]="!!contact?.followed"
      (click)="followUp()"
    ></mat-icon>
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="contact"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    ></mat-icon>
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="archive"
      (click)="archive()"
      [matTooltip]="contact?.archived ? 'Restore from Archive' : 'Archive'"
    ></mat-icon>
    <mat-icon
      class="contact-hover-icons-item"
      svgIcon="trash"
      (click)="delete()"
      [matTooltip]="contact?.deleted ? 'Restore from Trash' : 'Trash'"
    ></mat-icon>
  </div>

  <div class="drop-zone"></div>

  <div *ngIf="debug" class="contact-debug">{{ contact.score }}</div>
</div>

<div class="placeholder" *ngIf="!contact">
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
  <div class="placeholder-11"></div>
  <div class="placeholder-12"></div>
</div>

<ng-template #dragPlaceholder>
  <app-contact [item]="contact" [dragEnabled]="false"></app-contact>
</ng-template>
