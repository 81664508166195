// Common
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

// Services
import { SubscriptionPlansService } from '@modules/settings/services/subscription-plans.service';
import { AccountService } from '@modules/account/services/account.service';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { Account } from '@modules/account/types/account';
import { SubscriptionPlan } from '@modules/settings/types/subscription-plan';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.less']
})
export class PlanCardComponent implements OnInit, OnChanges, OnDestroy {
  public features = [
    { key: 'mailAppEnabled', title: 'Email App' },
    { key: 'calendarAppEnabled', title: 'Calendar App' },
    { key: 'taskingAppEnabled', title: 'Task App' },
    { key: 'notesAppEnabled', title: 'Notes App' },
    { key: 'contactsAppEnabled', title: 'Contact App' },
    { key: 'filesAppEnabled', title: 'Files App' },
    { key: 'knotsEnabled', title: 'Knots' },
    { key: 'tagsEnabled', title: 'Tags' },
    { key: 'connectionsEnabled', title: 'Connections'},
  ]
  public chargeTypeControl = new FormControl<'monthly' | 'annually'>('monthly');
  public chargeTypeSidebarOptions: DropdownSelectItem<'monthly' | 'annually'>[] = [
    { title: 'Billed Monthly', value: 'monthly' },
    { title: 'Billed Annually', value: 'annually' },
  ];
  public buttonTitle: string;

  private alive = new Subject<void>();

  @Input() plan: SubscriptionPlan;
  @Input() account: Account;
  @Input() currentPlan: SubscriptionPlan;

  constructor(
    private plansService: SubscriptionPlansService,
    private accountService: AccountService
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.chargeTypeControl.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(value => {
        if (
          this.currentPlan?.id === this.plan.id &&
          value !== this.account.chargeType
        ) {
          this.buttonTitle = `Change to ${ this.chargeTypeControl.value } payments`
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('account' in changes && 'currentPlan' in changes && this.account) {
      if (this.currentPlan) {
        if (
          this.currentPlan.id === this.plan.id &&
          this.chargeTypeControl.value !== this.account.chargeType
        ) {
          this.buttonTitle = `Change to ${ this.chargeTypeControl.value } payments`
        } else {
          this.buttonTitle = `${ this.plan.monthlyPrice > this.currentPlan.monthlyPrice ? 'Upgrade' : 'Downgrade' } to ${ this.plan.title }`
        }
      } else {
        this.buttonTitle = `Get ${ this.plan.title }`
      }
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  select() {
    this.plansService.migrateToPlan(this.plan, this.chargeTypeControl.value)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.accountService.reloadAccount();
      });
  }
}
