<div class="container">
  <app-toggle-switch
    appearance="sapphire-selector"
    [inputFormControl]="parts.controls.period"
    trueLabel="PM"
    falseLabel="AM"
    [width]="124"
  />

  <div class="inputs">
    <input
      #hoursInput
      class="hours"
      [formControl]="parts.controls.hours"
      size="2"
      (keydown)="handleHoursKeyDown($event)"
      (blur)="handleHoursBlur($event)"
      (keypress)="handleHoursKeyPress($event)"
      placeholder="00"
    >
    <span>:</span>
    <input
      #minutesInput
      class="minutes"
      [formControl]="parts.controls.minutes"
      size="2"
      (keydown)="handleMinutesKeyDown($event)"
      (blur)="handleMinutesBlur($event)"
      (keypress)="handleMinutesKeyPress($event)"
      placeholder="00"
    >
  </div>

  <div class="spinners">
    <div class="spinner">
      <div
        *stchRepeat="12; let i"
        class="item"
        [class.selected]="selectedHour === i"
        (click)="handleHourClick(i)"
      >
        {{ i }}
      </div>
    </div>
    <div class="divider"></div>
    <div class="spinner">
      <div
        *stchRepeat="59; let i"
        class="item"
        [class.selected]="selectedMinute === i"
        (click)="handleMinuteClick(i)"
      >
        {{ i }}
      </div>
    </div>
  </div>
</div>
