<div
  class="container"
  [ngSwitch]="file?.attachmentType"
>
  <div
    *ngSwitchCase="'image/jpeg'"
    class="image"
  >
    <img [src]="fileUrl"/>
  </div>

  <div
    *ngSwitchCase="'image/png'"
    class="image"
  >
    <img [src]="fileUrl"/>
  </div>

  <app-pdf-preview
    *ngSwitchCase="'application/pdf'"
    [file]="fileUrlString"
  ></app-pdf-preview>
</div>
