// Common
import { Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// RX
import { Observable, throwError, forkJoin, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Types
import { SectionsFilters } from '../types/sections-filters';
import { Section } from '../../common/types/section';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

@Injectable()
export class BaseSectionsService extends BaseRestService<Section, SectionsFilters> {
  protected url: string;

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService
  ) {
    super();
  }

  /**
   * Methods
   */

  search(filters?: Partial<SectionsFilters>): Observable<BaseSearchResponse<Section>> {

    if (!filters?.containersIds?.length) { return of({ items: [], count: 0 }); }

    const requestParams = { params: new SectionsFilters(filters || {}).format() };

    return this.http.get<{ count: number, items: Section[] }>(
      this.url,
      requestParams
    )
      .pipe(
        map(({ count, items }) => ({
          count,
          items: items.map(item => new Section(item))
        })),
        catchError(error => this.handleObserverError(error))
      );
  }

  create(
    instance: Section,
    { emit, toast, message }: FeedbackConfig = { emit: true }
  ): Observable<Section> {
    return this.http.post<{ section: Section, success: boolean }>(
      this.url,
      instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          success && emit && this.forceRefresh();
          success && toast && this.toasterService.show({ text: message || `Section added.` });
        }),
        map(({ section }) => new Section(section)),
        catchError((error: HttpErrorResponse) => {
          this.toasterService.show({ text: error.error.error });
          return throwError(error);
        })
      );
  }

  @warmUpObservable
  update(instance: Section): Observable<Section> {
    return this.http.put<{ section: Section, success: boolean }>(
      this.url + '/' + instance.id, instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (success) {
            this.forceRefresh();
            this.toasterService.show({ text: `Section updated` });
          }
        }),
        map(({ section }) => new Section(section))
      );
  }

  reorder(sections: { id: string, position: number }[], config?: FeedbackConfig): Observable<boolean> {
    return this.http.post<{ success: boolean }>(this.url + '/reorder', { sections })
      .pipe(
        tap(({ success }) => {
          success && config?.emit && this.forceRefresh();
        }),
        map(({ success }) => success)
      );
  }

  deletePermanently(sectionIds: string[], emitChanges = true): Observable<boolean> {
    return forkJoin(
      sectionIds.map(id => this.http.delete<{ success: boolean }>(this.url + '/' + id, {}))
    )
      .pipe(
        map((results: { success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            if (emitChanges) {
              this.forceRefresh();
            }
            this.toasterService.show({ text: `Section(s) successfully deleted.` });
          }
        })
      );
  }

  getAutocompleteSuggestions(defaultFilters: Partial<SectionsFilters>): AutocompleteFactory<Section> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      const filters = new SectionsFilters({ limit: config?.limit || 5, ...defaultFilters });

      if (values?.length) {
        filters.ids = values;
      }

      if (title) {
        filters.title = title;
      }

      return this.search(filters)
        .pipe(
          map((response: BaseSearchResponse<Section>) => response.items.map(section => ({
            title: section.title,
            value: section.id
          })))
        );
    };
  }
}
