<div class="panel">
  <div class="panel-heading">
    <div class="panel-heading-top">
      <app-dropdown-label
        appDPNotesContextMenu
        (appDPNotesContextMenuOpened)="contextMenuOpened = $event"
        (appDPNotesContextMenuFilterChanged)="selectFilter($event)"
        [collapsed]="!contextMenuOpened"
      >
        {{ selectedFilter | titleByNotesFilter }}
      </app-dropdown-label>
      <div class="panel-heading-top-filters">
        <app-context-menu-state-indicator
          *ngIf="selectedFilter !== 'all_notebooks'"
          svgIcon="filters"
          appNotesListContextMenu
          [appNotesListContextMenuStateKey]="notesListStateKey"
          (appNotesListContextMenuChange)="handleNotesListStateChange($event)"
        ></app-context-menu-state-indicator>
        <app-context-menu-state-indicator
          *ngIf="selectedFilter === 'all_notebooks'"
          svgIcon="filters"
          appNotebooksListContextMenu
          [appNotebooksListContextMenuStateKey]="notebooksListStateKey"
          (appNotebooksListContextMenuChange)="handleNotebooksListStateChange($event)"
        ></app-context-menu-state-indicator>

        <ng-container [ngTemplateOutlet]="searchTemplate"></ng-container>
      </div>
    </div>
  </div>

  <app-quick-form
    *ngIf="
      selectedFilter !== 'all_notebooks' &&
      selectedFilter !== 'archived' &&
      selectedFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New Note"
  >
    <app-note-quick-form
      (more)="handleNewNote($event)"
    ></app-note-quick-form>
  </app-quick-form>

  <app-quick-form
    *ngIf="selectedFilter === 'all_notebooks'"
    class="panel-quick-form"
    placeholder="Add New Notebook"
  >
    <app-notebook-quick-form
      (more)="handleNewNotebook($event)"
    ></app-notebook-quick-form>
  </app-quick-form>

  <div class="panel-list">
    <app-notes-list
      *ngIf="selectedFilter !== 'all_notebooks'"
      [filters]="notesFilters"
      (openNote)="openNote($event)"
      (doubleClickNote)="openNote($event)"
    ></app-notes-list>

    <app-notebooks-list
      *ngIf="selectedFilter === 'all_notebooks'"
      [filters]="notebooksFilters"
      (openNote)="openNoteInModal($event)"
      (openNotebook)="openNotebook($event)"
      (doubleClickNotebook)="openNotebook($event)"
    ></app-notebooks-list>
  </div>
</div>
