// Common
import { Component, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

// RX
import { Subject } from 'rxjs';
import { takeUntil, startWith, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-time-range-picker',
  templateUrl: './time-range-picker.component.html',
  styleUrls: ['./time-range-picker.component.less']
})
export class TimeRangePickerComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() fromTimeControl: UntypedFormControl;
  @Input() toTimeControl: UntypedFormControl;
  @Input() focusToTime: boolean;

  // Output
  @Output() save: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Output() delete = new EventEmitter();

  // Public
  public activeTime: 'from' | 'to' = 'from';
  public popoverClose = new Subject<void>();
  public meridiem: UntypedFormControl = new UntypedFormControl('am');
  public options: Date[] = [];
  public innerFromTime = new UntypedFormControl();
  public innerToTime = new UntypedFormControl();

  // Private
  private alive: Subject<void> = new Subject();
  private shouldEmit = true;

  /**
   * Constructor
   */

  constructor () {
    this.meridiem.valueChanges
      .pipe(
        startWith(this.meridiem.value),
        takeUntil(this.alive),
      )
      .subscribe(() => {
        this.generateOptions();
      });
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.innerFromTime.setValue(this.fromTimeControl && this.fromTimeControl.value, { emitEvent: false });
    this.innerToTime.setValue(this.toTimeControl && this.toTimeControl.value, { emitEvent: false });

    if (this.focusToTime) {
      this.activeTime = 'to';
    }

    this.innerFromTime.valueChanges
      .pipe(
        map(() => {
          const shouldEmit = this.shouldEmit;
          this.shouldEmit = true;
          return shouldEmit;
        }),
        filter((shouldEmit) => shouldEmit && this.activeTime === 'from'),
        takeUntil(this.alive)
      )
      .subscribe(() => this.activeTime = 'to');
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('fromTimeControl' in changes) {
      this.innerFromTime.setValue(this.fromTimeControl && this.fromTimeControl.value, { emitEvent: false });
    }

    if ('toTimeControl' in changes) {
      this.innerToTime.setValue(this.toTimeControl && this.toTimeControl.value, { emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleCancel() {
    this.popoverClose.next();
  }

  handleSave() {
    if (this.fromTimeControl) {
      this.fromTimeControl.setValue(this.innerFromTime.value);
    }
    if (this.toTimeControl) {
      this.toTimeControl.setValue(this.innerToTime.value);
    }

    this.popoverClose.next();
  }

  setValue(option: Date) {
    if (this.activeTime === 'from') {
      this.innerFromTime.setValue(option);
    } else {
      this.innerToTime.setValue(option);
    }
  }

  /**
   * Helpers
   */

  private generateOptions() {
    const today = new Date();

    // TODO - start time can not be greater than end time ?
    // TODO or it can - if start date and end date are not the same day ?

    // const from = this.activeTime === 'to' && this.innerFromTime.value && new Date(
    //   today.getFullYear(),
    //   today.getMonth(),
    //   today.getDate(),
    //   this.innerFromTime.value.getHours(),
    //   this.innerFromTime.value.getMinutes()
    // );

    // const to = this.activeTime === 'from' && this.innerToTime.value && new Date(
    //   today.getFullYear(),
    //   today.getMonth(),
    //   today.getDate(),
    //   this.innerToTime.value.getHours(),
    //   this.innerToTime.value.getMinutes()
    // );

    const halfHourIndexes = Array.from(Array(48).keys())
      .filter(item => this.meridiem.value === 'am' ? (item < 24) : (item >= 24));

    this.options = halfHourIndexes.map(halfAnHour => {
      return new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        halfAnHour / 2,
        halfAnHour % 2 ? 30 : 0,
        0
      );
    });
  }

  handlePopoverOpen(opened: boolean) {
    if (opened) {
      this.activeTime = this.focusToTime ? 'to' : 'from';
      // TODO Need because of angular bug
      // TODO valueChanges emits with emitEvent: false
      this.shouldEmit = false;

      this.innerFromTime.setValue(this.fromTimeControl.value, { emitEvent: false });
      this.innerToTime.setValue(this.toTimeControl && this.toTimeControl.value, { emitEvent: false });
    }
  }
}
