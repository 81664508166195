// Common
import { Injectable } from '@angular/core';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '../types/stitch-type';

// RX
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GlobalStateService {
  private fullView = new BehaviorSubject<Stitch>(null);

  getFullView(stitchTypes: StitchType[]): Observable<Stitch> {
    return this.fullView.pipe(
      filter(item => item && stitchTypes.includes(item.getStitchType()))
    );
  }

  setFullView(item: Stitch) {
    this.fullView.next(item);
  }
}
