// Utils
import { beginningOfDay, beginningOfMonth, beginningOfWeek, beginningOfYear } from '@modules/common/utils/date';

// Types
import { KnotSource } from '@modules/knots/types/knot-source';
import { StitchTypeFilters } from './stitch-type-filters';

export class ChartState {
  filters: {
    autoDiscovery?: boolean;
    collapsed?: boolean;
    createdFrom?: Date;
    createdLast3Hours?: boolean,
    createdLast5Hours?: boolean,
    createdLastHour?: boolean,
    createdThisMonth?: boolean;
    createdThisWeek?: boolean;
    createdThisYear?: boolean;
    createdTo?: Date;
    createdToday?: boolean;
    displayConnections?: boolean;
    displayKnots?: boolean;
    displayStitched?: boolean;
    displayTags?: boolean;
    linkedFrom?: Date;
    linkedLast3Hours?: boolean,
    linkedLast5Hours?: boolean,
    linkedLastHour?: boolean,
    linkedThisMonth?: boolean;
    linkedThisWeek?: boolean;
    linkedThisYear?: boolean;
    linkedTo?: Date;
    linkedToday?: boolean;
    pinnedKnots?: boolean;
    pinnedTags?: boolean;
    scoreFrom?: number;
    scoreTo?: number;
    source?: KnotSource
    stitch?: StitchTypeFilters;
  };

  limit?: number;
  offset?: number;

  static format(state: ChartState): { [param: string]: string | string[]; } {
    const today = new Date();
    const result = {};

    if (state.filters.pinnedTags !== null && state.filters.pinnedTags !== undefined) {
      result['pinned_tags'] = state.filters.pinnedTags + '';
    }

    if (state.filters.pinnedKnots !== null && state.filters.pinnedKnots !== undefined) {
      result['pinned_knots'] = state.filters.pinnedKnots + '';
    }

    if (state.filters.autoDiscovery !== null && state.filters.autoDiscovery !== undefined) {
      result['auto_discovery'] = state.filters.autoDiscovery + '';
    }

    if (state.filters.displayKnots) { result['display_knots'] = true; }
    if (state.filters.displayTags) { result['display_tags'] = true; }
    if (state.filters.displayConnections) { result['display_connections'] = true; }
    if (state.filters.displayStitched) { result['display_stitched'] = true }

    if (state.filters.source) { result['source'] = state.filters.source }
    if (state.filters.collapsed) { result['collapsed'] = state.filters.collapsed }

    if (state.filters.createdFrom) { result['created_from_time'] = state.filters.createdFrom.toISOString(); }
    if (state.filters.createdTo) { result['created_to_time'] = state.filters.createdTo.toISOString(); }

    if (state.filters.createdThisYear) { result['created_from_time'] = beginningOfYear(today).toISOString(); }
    if (state.filters.createdThisMonth) { result['created_from_time'] = beginningOfMonth(today).toISOString(); }
    if (state.filters.createdThisWeek) { result['created_from_time'] = beginningOfWeek(today).toISOString(); }
    if (state.filters.createdToday) { result['created_from_time'] = beginningOfDay(today).toISOString(); }

    if (state.filters.createdLastHour) {
      const time = new Date(
        today.getFullYear(), today.getMonth(), today.getDate(),
        today.getHours() - 1, today.getMinutes(), today.getSeconds()
      );
      result['created_from_time'] = time.toISOString();
    }

    if (state.filters.createdLast3Hours) {
      const time = new Date(
        today.getFullYear(), today.getMonth(), today.getDate(),
        today.getHours() - 3, today.getMinutes(), today.getSeconds()
      );
      result['created_from_time'] = time.toISOString();
    }

    if (state.filters.createdLast5Hours) {
      const time = new Date(
        today.getFullYear(), today.getMonth(), today.getDate(),
        today.getHours() - 5, today.getMinutes(), today.getSeconds()
      );
      result['created_from_time'] = time.toISOString();
    }

    if (state.filters.linkedFrom) { result['linked_from_time'] = state.filters.linkedFrom.toISOString(); }
    if (state.filters.linkedTo) { result['linked_to_time'] = state.filters.linkedTo.toISOString(); }

    if (state.filters.linkedThisYear) { result['linked_from_time'] = beginningOfYear(today).toISOString(); }
    if (state.filters.linkedThisMonth) { result['linked_from_time'] = beginningOfMonth(today).toISOString(); }
    if (state.filters.linkedThisWeek) { result['linked_from_time'] = beginningOfWeek(today).toISOString(); }
    if (state.filters.linkedToday) { result['linked_from_time'] = beginningOfDay(today).toISOString(); }

    if (state.filters.linkedLastHour) {
      const time = new Date(
        today.getFullYear(), today.getMonth(), today.getDate(),
        today.getHours() - 1, today.getMinutes(), today.getSeconds()
      );
      result['linked_from_time'] = time.toISOString();
    }

    if (state.filters.linkedLast3Hours) {
      const time = new Date(
        today.getFullYear(), today.getMonth(), today.getDate(),
        today.getHours() - 3, today.getMinutes(), today.getSeconds()
      );
      result['linked_from_time'] = time.toISOString();
    }

    if (state.filters.linkedLast5Hours) {
      const time = new Date(
        today.getFullYear(), today.getMonth(), today.getDate(),
        today.getHours() - 5, today.getMinutes(), today.getSeconds()
      );
      result['linked_from_time'] = time.toISOString();
    }

    if (state.filters.scoreFrom) { result['score_from'] = state.filters.scoreFrom; }
    if (state.filters.scoreTo) { result['score_to'] = state.filters.scoreTo; }

    if (state.filters.stitch.message) { result['stitch_messages'] = state.filters.stitch.message; }
    if (state.filters.stitch.event) { result['stitch_events'] = state.filters.stitch.event; }
    if (state.filters.stitch.project) { result['stitch_projects'] = state.filters.stitch.project; }
    if (state.filters.stitch.task) { result['stitch_tasks'] = state.filters.stitch.task; }
    if (state.filters.stitch.notebook) { result['stitch_notebooks'] = state.filters.stitch.notebook; }
    if (state.filters.stitch.note) { result['stitch_notes'] = state.filters.stitch.note; }
    if (state.filters.stitch.group) { result['stitch_groups'] = state.filters.stitch.group; }
    if (state.filters.stitch.contact) { result['stitch_contacts'] = state.filters.stitch.contact; }
    if (state.filters.stitch.folder) { result['stitch_folders'] = state.filters.stitch.folder; }
    if (state.filters.stitch.file) { result['stitch_files'] = state.filters.stitch.file; }

    if (state.limit) { result['limit'] = state.limit + ''; }
    if (state.offset) { result['offset'] = state.offset + ''; }

    return result;
  }
}
