// Common
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { Knot } from '../types/knot';
import { ManageListState } from '../types/manage-list-state';

@Pipe({
  name: 'groupAlphabetically'
})
export class GroupAlphabeticallyPipe implements PipeTransform {

  transform(knots: Knot[] = [], state: ManageListState): { letter: string, knots: Knot[] }[] {
    return Object.entries(
      knots.reduce((memo, knot) => {
        const firstLetter = knot.name[0].toLocaleUpperCase();

        return {
          ...memo,
          [firstLetter]: [
            ...(memo[firstLetter] || []),
            knot
          ]
        };
      }, {})
    )
      .map(([k, v]) => ({ letter: k, knots: v as Knot[] }))
      .sort((a, b) => (a.letter > b.letter ? 1 : -1) * (state.sort.order === 'desc' ? -1 : 1));
  }
}
