// Common
import { Component, Input, Output, EventEmitter } from '@angular/core';

// Services
import { TagsService } from '@modules/tags/services/tags.service';

// Types
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Tag } from '@modules/tags/types/tag';
import { Stitch } from '@modules/common/types/stitch';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.less']
})
export class TagComponent {

  // Inputs
  @Input() tag: Tag;
  @Input() dragEnabled = true;
  @Input() withDetach = false;
  @Input() withControls = true;
  @Input() selected = false;
  @Input() withRemove = false;

  // Output
  @Output() delete = new EventEmitter();
  @Output() pin = new EventEmitter<boolean>();
  @Output() onRemove = new EventEmitter<void>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    this.tag &&
    dragData.type !== DragDataTypes.tag &&
    dragDataTypeAllowed(dragData.type)

  constructor (
    private tagsService: TagsService
  ) { }

  /**
   * Actions
   */

  handleDelete() {
    this.delete.emit();
  }

  handlePin() {
    if (this.pin.observers.length) {
      this.pin.emit(this.tag.pinned);
    } else {
      this.tagsService.pin([this.tag], !this.tag.pinned);
    }
  }

  dndDrop(dragData: DragData) {
    this.tagsService.upsertBulk([this.tag], dragData.data as Stitch[]);
  }
}
