<div
  *ngIf="notification"
  class="notification"
  (click)="handleClickEntity()"
>
  <img
    *ngIf="notification.icon"
    class="notification-icon"
    [src]="notification.icon"
    alt=""
  />

  <div class="notification-body">
    <h4>
      <span *ngIf="notification?.subjectType === 'task' && showIssueKey && notification?.entity?.issueKeyTitle">{{ notification?.entity?.issueKeyTitle }}</span>
      {{ notification.title }}
    </h4>
    <div>{{ notification.content }}</div>
    <div
      class="notification-body-entity"
      [class.underline]="notification.entity"
    >
      <span *ngIf="notification?.subjectType === 'task' && showIssueKey && notification?.entity?.issueKeyTitle">{{ notification?.entity?.issueKeyTitle }}</span>
      <span>{{ notification?.entity?.title }}</span>
    </div>
  </div>

  <span class="notification-created">{{ notification.createdAt | date: 'MMM dd hh:mm aaa' }}</span>

  <app-checkbox
    class="notification-read-checkbox"
    [inputFormControl]="checkboxControl"
    appearance="amethyst"
    (onCheck)="handleCheckRead()"
  ></app-checkbox>

  <div
    *ngIf="notification.buttons?.length && !notification.isRead"
    class="notification-actions"
  >
    <ng-container
      *ngFor="let button of notification.buttons"
      [ngSwitch]="button.actionType"
    >
      <app-button
        *ngSwitchCase="'followed'"
        appearance="link-blue"
        [label]="button.title"
        (click)="$event.stopPropagation()"
        stchPopover
        [stchPopoverArrow]="true"
        [stchPopoverContent]="followedTemplate"
        stchPopoverTrigger="click"
        stchPopoverPlacement="bottom"
        [stchPopoverShowUntil]="popoverShowUntil"
      />

      <app-button
        *ngSwitchCase="'snoozed'"
        appearance="link-blue"
        [label]="button.title"
        (click)="$event.stopPropagation()"

        stchPopover
        [stchPopoverArrow]="true"
        [stchPopoverContent]="snoozedTemplate"
        stchPopoverTrigger="click"
        stchPopoverPlacement="bottom"
        [stchPopoverShowUntil]="popoverShowUntil"
      />

      <app-button
        *ngSwitchDefault
        appearance="link-blue"
        [label]="button.title"
        (click)="handleClickAction(button.actionType)"
      />
    </ng-container>
  </div>
</div>

<ng-template #snoozedTemplate>
  <app-postpone-context-menu
    (onSelect)="handleClickAction('snoozed', $event)"
  ></app-postpone-context-menu>
</ng-template>

<ng-template #followedTemplate>
  <app-postpone-context-menu
    appearance="followUp"
    (onSelect)="handleClickAction('followed', $event)"
  ></app-postpone-context-menu>
</ng-template>
