// Common
import { Component, Injector, Input, OnInit } from '@angular/core';

// RX
import { take, takeUntil } from 'rxjs/operators';

// Components
import { FullFormBaseComponent } from '@modules/common/components/full-form-base/full-form-base.component';

// Services
import { MessagesService } from '../../services/messages.service';
import { FullFormService } from '@modules/messages/services/full-form.service';
import { MailAppStateService } from '@modules/messages/services/state.service';

// Types
import { Message } from '@modules/messages/types/message';
import { Knot } from '@modules/knots/types/knot';
import { Tab } from '@modules/common/types/tab';
import { TemporalExpression } from '@modules/common/types/temporal-expression';

@Component({
  selector: 'app-message2-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.less']
})
export class MessageFormComponent extends FullFormBaseComponent<Message> implements OnInit {

  @Input() message = new Message();

  public changesKey = 'message';
  tabs: Tab[] = [
    { title: 'Email', value: 'message'},
    { title: 'Attachments', value: 'attachments'},
    { title: 'Stitch', value: 'stitch'},
    { title: 'Lab', value: 'lab'},
    { title: 'Comments', value: 'comments'},
    { title: 'Raw', value: 'raw'},
  ];
  tabsStateKey = 'ffMessage';
  public selectedKnots: Knot[] = [];
  public selectedTemporalExressions: TemporalExpression[] = [];

  constructor(
    private messagesService: MessagesService,
    private ffService: FullFormService,
    private stateService: MailAppStateService,
    protected injector: Injector,
  ) {
    super(injector, messagesService, stateService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    this.kpService.getSelectedKnots()
      .pipe(takeUntil(this.alive))
      .subscribe((knots: Knot[]) => {
        this.selectedKnots = knots;
      });

    this.ffService.getSelectedTemporalExpressions()
      .pipe(takeUntil(this.alive))
      .subscribe((expressions: TemporalExpression[]) => {
        this.selectedTemporalExressions = expressions;
      });

    this.stitchItemChanged
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        if (this.stitchItem?.unread) {
          this.messagesService.read({ ids: [this.stitchItem.id] }, true, { toast: false });
        }
      });
  }

  /**
   * Actions
   */

  forward(action: 'forward' | 'reply' | 'replyAll') {
    this.stateService.composeMessage({ reply: this.stitchItem, forward: [this.stitchItem], action, injector: this.injector });
  }

  /**
   * Helpers
   */

  get stitchItem(): Message {
    return this.message;
  }

  protected shouldRefreshList(prev, current) {
    return Message.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): Message {
    return Message.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return this.message.asFormGroup();
  }
}
