
export class Session {
  id: string;
  accountId: string;
  expirationDate: Date;
  recentActivity: Date;
  os: string;
  location: string;
  browser: string;
  current: boolean;
  active: boolean;

  constructor(session) {
    this.id = session.id;
    this.accountId = session.accountId;
    this.expirationDate = session.expirationDate ? new Date(session.expirationDate) : null;
    this.recentActivity = session.recentActivity ? new Date(session.recentActivity) : null;
    this.os = session.os;
    this.location = session.location;
    this.browser = session.browser;
    this.current = session.current;
    this.active = this.recentActivity?.getTime() + 1000 * 60 * 10 >= Date.now();
  }
}
