<div class="knots">
  <div class="knots-title">
    Manage Knots
  </div>

  <mat-icon
    *ngIf="!knots?.length"
    class="knots-top-icon"
    svgIcon="close-thin"
    (click)="handleCancel()"
  ></mat-icon>

  <mat-icon
    *ngIf="knots?.length"
    class="knots-top-icon"
    svgIcon="filters"
    appKnotsManageContextMenu
    [appKnotsManageContextMenuStateKey]="'mf-knots'"
    (appKnotsManageContextMenuStateChange)="state = $event"
    [appKnotsManageContextMenuWithTemporalExpressions]="false"
  ></mat-icon>

  <app-simple-input
    class="knots-input"
    [inputFormControl]="inputControl"
    placeholder=""
  ></app-simple-input>

  <div class="knots-add">
    <div
      *ngIf="canAdd"
      class="knots-add-button"
      (click)="addKnot()"
    >
      + Add Knot «{{ inputControl.value }}»
    </div>
  </div>

  <div
    *ngIf="!knots?.length"
    class="knots-placeholder"
  >
    <img
      class="knots-placeholder-image"
      src="assets/placeholders/placeholder-stitch-info.jpg"
    />
    <div class="knots-placeholder-text">
      You Have no Knots Added Yet
    </div>
  </div>

  <app-scroll
    *ngIf="knots?.length"
    class="knots-list"
    (top)="scrollShadowTop = !$event"
    (bottom)="scrollShadowBottom = !$event"
  >
    <div class="shadow" *ngIf="scrollShadowTop"></div>

    <ng-container *ngIf="state.sort.by === 'name'">
      <div
        class="knots-list-group"
        *ngFor="let group of ((knots | sortAndFilter : state) | groupAlphabetically : state)"
      >
        <div class="knots-list-group-letter">{{ group.letter }}</div>
        <div class="knots-list-group-knots">
          <app-manage-knot
            *ngFor="let knot of group.knots | sortAndFilter : state; let index = index"
            [knot]="knot"
            (pin)="handlePin(knot)"
            (delete)="handleDelete(knot)"
            (change)="handleChange(knot)"
          ></app-manage-knot>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="state.sort.by !== 'name'">
      <div class="knots-list-group">
        <div class="knots-list-group-letter-filler"></div>
        <div class="knots-list-group-knots">
          <app-manage-knot
            *ngFor="let knot of knots | sortAndFilter : state"
            [knot]="knot"
            (pin)="handlePin(knot)"
            (delete)="handleDelete(knot)"
          ></app-manage-knot>
        </div>
      </div>
    </ng-container>
  </app-scroll>

  <div class="shadow-bottom">
    <div class="shadow-bottom-inner" *ngIf="scrollShadowBottom"></div>
  </div>

  <div
    *ngIf="knots?.length"
    class="knots-buttons"
  >
    <app-button appearance="square" size="medium" (click)="handleCancel()" label="Cancel" />
    <app-button appearance="square-blue" size="medium" (click)="handleSave()" label="Save" />
  </div>
</div>
