// Common
import { Injectable, Injector } from '@angular/core';

// RX
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Types
import { ListState } from '@modules/files/types/list-state';
import { Folder } from '@modules/files/types/folder';
import { File } from '@modules/files/types/file';
import { VirtualFolder } from '../types/virtual-folder';
import { Application } from '@modules/common/types/application';

// Services
import { BaseAppStateService } from '@modules/common/services/base-app-state.service';

@Injectable()
export class FilesAppStateService extends BaseAppStateService<Folder, File, VirtualFolder> {

  protected application = Application.files;
  protected defaultVirtualFolder: VirtualFolder = 'all_files';

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Actions
   */

  getTabs(): Observable<ListState> {
    return this.getVirtualFolder()
      .pipe(
        map(folder => {
          switch (folder) {
            case 'all_files':
              return 'files';
            case 'upcoming':
            case 'flagged':
            case 'recent':
            case 'followed':
            case 'snoozed':
            case 'archived':
            case 'deleted':
              return 'tabs';
            case 'all_folders':
              return 'folders';
            default:
              return 'files';
          }
        })
      );
  }
}
