// Common
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { AutoReplyTemplate } from '@modules/account/types/auto-reply-template';

// Services
import { AutoReplyTemplatesService } from '@modules/account/services/auto-reply-templates.service';

@Component({
  selector: 'app-settings-messages-auto-reply-form',
  templateUrl: './auto-reply-form.component.html',
  styleUrls: ['./auto-reply-form.component.less']
})
export class AutoReplyFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() autoReplyTemplate: AutoReplyTemplate;

  public form: FormGroup<{
    id: FormControl<string>,
    title: FormControl<string>,
    content: FormControl<string>,
    enabled: FormControl<boolean>,
    dateFrom: FormControl<Date>,
    dateTo: FormControl<Date>
  }>;

  private autoReplyChanged = new Subject<void>();
  private alive = new Subject<void>();

  constructor(
    private autoReplyTemplatesService: AutoReplyTemplatesService,
    @Inject(CloseToken) private closeToken
  ) { }

  ngOnInit(): void {
    this.autoReplyChanged
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.form = (this.autoReplyTemplate || new AutoReplyTemplate()).asFormGroup();
      });

    this.autoReplyChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('autoReplyTemplate' in changes) {
      this.autoReplyChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  handleSubmit() {
    const template = AutoReplyTemplate.fromFormGroup(this.form);

    this.autoReplyTemplatesService.upsert(template)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.handleClose());
  }

  handleClose() {
    this.closeToken.next();
  }

}
