// Common
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FileValidators } from '@modules/form-controls/validators/file.validators';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }]});

export class Import {
  formBuilder = injector.get(UntypedFormBuilder);
  id?: string;
  folderName: string;
  attachmentName?: string;
  attachmentType?: string;
  attachmentSize?: number;
  tempAttachmentId?: string;
  createdAt?: Date;
  error?: string;
  processedCount?: number;
  progress?: number;
  status?: 'pending' | 'failure' | 'success';
  totalCount?: number;

  constructor(data: any = {}) {
    this.attachmentName = data.attachmentName;
    this.attachmentSize = data.attachmentSize;
    this.attachmentType = data.attachmentType;
    this.createdAt = data.createdAt && new Date(data.createdAt);
    this.error = data.error;
    this.folderName = data.folderName;
    this.id = data.id;
    this.processedCount = data.processedCount;
    this.progress = data.progress;
    this.status = data.status;
    this.tempAttachmentId = data.tempAttachmentId;
    this.totalCount = data.totalCount;
  }

  static fromFormGroup(form: UntypedFormGroup): Import {
    const formValues = form.value;

    return new Import({
      id: formValues.id,
      tempAttachmentId: formValues.attachment?.id,
      attachmentName: formValues.attachment?.name,
      attachmentType: formValues.attachment?.type,
      attachmentSize: formValues.attachment?.size,
      folderName: formValues.folderName
    });
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      id: [this.id],
      attachment: [null, [Validators.required, FileValidators.mimeTypes(['application/mbox', 'application/vnd.ms-outlook']), FileValidators.size(1e+11)]],
      folderName: [this.folderName]
    });
  }

  asPayloadJSON() {
    return {
      id: this.id,
      tempAttachmentId: this.tempAttachmentId,
      attachmentName: this.attachmentName,
      attachmentType: this.attachmentType,
      attachmentSize: this.attachmentSize,
      folderName: this.folderName
    };
  }
}
