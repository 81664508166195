// Common
import { Injector } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }]});

export class Section {
  formBuilder = injector.get(UntypedFormBuilder);

  id: string;
  title?: string;
  position?: number;
  containerId?: string;

  // Tech properties

  /**
   * default - invisible section for items without section
   */
  default?: boolean;

  constructor(raw: any = {}) {
    this.id = raw.id;
    this.title = raw.title;
    this.position = raw.position;
    this.containerId = raw.containerId;
    this.default = raw.default;
  }

  static fromFormGroup(form: UntypedFormGroup): Section {
    const formValues = form.value;

    return new Section({
      id: formValues.id,
      title: formValues.title,
      position: formValues.position,
      containerId: formValues.containerId,
    });
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      id: [this.id],
      title: [this.title],
      position: [this.position],
      containerId: [this.containerId],
    });
  }

  asPayloadJSON() {
    return {
      id: this.id,
      title: this.title,
      position: Math.ceil(this.position),
      containerId: this.containerId
    };
  }
}
