<div class="heading">
  <mat-icon
    class="icon"
    svgIcon="sapphire-chevron-right"
    (click)="back()"
  ></mat-icon>

  <div>
    <div class="heading1">{{ user?.firstName }} {{ user?.lastName }}</div> 
    <div class="heading2-subtitle">{{ user?.userName }}&#64;stage-stitch.com</div>
  </div>
</div>

<app-tabs
  class="tabs"
  [values]="tabs"
  [(value)]="selectedTab"
></app-tabs>

<ng-container [ngSwitch]="selectedTab">
  <app-admin-user-user *ngSwitchCase="'user'" [user]="user"></app-admin-user-user>
  <app-admin-user-charges *ngSwitchCase="'payments'" [user]="user"></app-admin-user-charges>
</ng-container>
