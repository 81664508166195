<div class="heading">
  <div class="heading-grow">
    <div class="heading1">Payments History</div> 
    <div class="heading2-subtitle">Spaces helps bring people together so that they can get things done.</div>
  </div>
</div>

<stch-table
  class="items"
  [items]="charges"
  [columns]="columns"
  [page]="page"
  [perPage]="perPage"
  [count]="count"
  [sortBy]="sortBy"
  [sortOrder]="sortOrder"
  (pageChange)="handlePageChange($event)"
  (sortByChange)="handleSortByChange($event)"
  (sortOrderChange)="handleSortOrderChange($event)"
></stch-table>

<ng-template #currencyTemplate let-value=value let-charge=item>
  {{ value | currency : (charge.currency | uppercase) }}
</ng-template>

<ng-template #cardTemplate let-charge=item>
  **** **** **** {{ charge.paymentMethod?.cardLast4 }}
</ng-template>

<ng-template #dateTemplate let-value=value>
  {{ value | date : 'medium' }}
</ng-template>
