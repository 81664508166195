<app-context-menu-item
  *ngFor="let option of sortOptions"
  [title]="option.title"
  [active]="state?.sort?.by === option.key"
  [secondary]="state?.sort?.secondaryBy === option.key"
  [badge]="state?.sort?.by === option.key || state?.sort?.secondaryBy === option.key"
  [sortable]="true"
  [reversed]="
    (state?.sort?.by === option.key && state?.sort?.order === 'desc') ||
    (state?.sort?.secondaryBy === option.key && state?.sort?.secondaryOrder === 'desc')
  "
  (execute)="sort(option.key)"
></app-context-menu-item>

<app-context-menu-divider *ngIf="neighbours"></app-context-menu-divider>

<app-context-menu-item
  *ngIf="neighbours"
  title="3 in a row (neo4j)"
  [active]="state?.filters?.threeInARow"
  (execute)="setBooleanFilter('threeInARow')"
></app-context-menu-item>

<app-context-menu-item
  *ngIf="neighbours"
  title="Common neighbours (neo4j)"
  [active]="state?.filters?.commonNeighbours"
  (execute)="setBooleanFilter('commonNeighbours')"
></app-context-menu-item>

<app-context-menu-item
  title="Collapsed"
  [active]="state?.filters?.collapsed"
  (execute)="setBooleanFilter('collapsed')"
></app-context-menu-item>

<app-context-menu-divider></app-context-menu-divider>

<app-context-menu-item
  title="Auto Discovery"
  [active]="state?.filters?.autodiscovered"
  (execute)="setBooleanFilter('autodiscovered')"
></app-context-menu-item>

<app-context-menu-item
  title="Selected Date"
>
  <div class="date">
    <div class="date-buttons">
      <div
        class="date-buttons-item"
        [class.selected]="dateButtonSelected === 'from'"
        [class.active]="innerFromDate?.value"
        (click)="dateButtonSelected = 'from'"
      >
        {{ innerFromDate?.value ? (innerFromDate?.value | date : 'MMM d') : 'Start Date'}}
      </div>
      <div
        class="date-buttons-item"
        [class.selected]="dateButtonSelected === 'to'"
        [class.active]="innerToDate?.value"
        (click)="dateButtonSelected = 'to'"
      >
        {{ innerToDate?.value ? (innerToDate?.value | date : 'MMM d') : 'Due Date'}}
      </div>
    </div>

    <app-date-picker-2
      [inputFormControl]="dateButtonSelected === 'from' ? innerFromDate : innerToDate"
      [collapseable]="false"
      [maxDate]="dateButtonSelected === 'from' && innerToDate?.value"
      [minDate]="dateButtonSelected === 'to' && innerFromDate?.value"
      [highlightRange]="true"
    ></app-date-picker-2>
  </div>
</app-context-menu-item>

<app-context-menu-item
  title="Score"
>
  <div class="slider">
    <app-slider
      [fromControl]="scoreFrom"
      [toControl]="scoreTo"
      [floor]="0"
      [ceil]="100"
      [step]="0.5"
    ></app-slider>
  </div>
</app-context-menu-item>

<app-context-menu-heading>Knots source:</app-context-menu-heading>

<app-context-menu-item
  *ngFor="let source of sources"
  [title]="source.title"
  [active]="state?.filters?.source === source.key"
  (execute)="setSource(source.key)"
></app-context-menu-item>

<app-context-menu-divider *ngIf="global"></app-context-menu-divider>

<app-context-menu-item
  *ngIf="global"
  title="Manage My Knots"
>
  <app-knots-form
    (close)="handleClose()"
  ></app-knots-form>
</app-context-menu-item>

<ng-container *ngIf="!global">
  <app-context-menu-heading *ngIf="withTemporalExpressions">Temporal expressions:</app-context-menu-heading>

  <app-context-menu-item
    *ngIf="withTemporalExpressions"
    title="Meeting Intent by ML"
    [active]="state?.filters?.meetingIntentML"
    (execute)="meetingIntentML()"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngIf="withTemporalExpressions"
    title="Meeting Intent by Rule"
    [active]="state?.filters?.meetingIntentRule"
    (execute)="meetingIntentRule()"
  ></app-context-menu-item>
</ng-container>
