<div #container class="container">
  <div #graph class="graph">
    
  </div>
</div>

<div class="buttons">
  <div
    *ngIf="limit === 100"
    class="buttons-item"
    (click)="showMore()"
  >
    Show More
  </div>
  <div
    *ngIf="limit === 1000"
    class="buttons-item"
    (click)="showLess()"
  >
    Show Less
  </div>
</div>
