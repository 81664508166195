<div class="event-tooltip-container">
  <div class="title-container flexRow">
    <div class="text-ellipsis"> {{ event.title || '(No Title)'}} </div>
    <mat-icon
      svgIcon="event"
      class="standard-blue-icon"
      [ngStyle]="{ 'color': event.color }"></mat-icon>
  </div>
  <div class="description" *ngIf="event?.description?.length > 0">
    <span> {{ event.description }} </span>
  </div>
  <app-contacts-counter-dropdown class="contacts-list" *ngIf="event?.participants?.length > 0"
    [contactList]="event.participants"
    [size]="16"
  ></app-contacts-counter-dropdown>
  <hr/>

  <div class="event-detailed">
    <div class="text-ellipsis event-detailed-item" *ngIf="event?.startTime">
      <mat-icon class="gray-icon" svgIcon="event"></mat-icon>
      <span class="text"> {{ event.startTime | relativeDate : true : false }} </span>
    </div>
    <div class="text-ellipsis event-detailed-item" *ngIf="event?.startTime && !event?.allDay">
      <mat-icon class="gray-icon gray-icon-clock" svgIcon="followup"></mat-icon>
      <span class="text">{{ event.startTime | date : 'shortTime'}}</span>
      <span class="text" *ngIf="event?.endTime"> - {{ event.endTime | date : 'shortTime'}} </span>
    </div>
    <div class="text-ellipsis event-detailed-item" *ngIf="event?.startTime">
      <mat-icon class="gray-icon gray-icon-time-zone" svgIcon="time-zone"></mat-icon>
      <span class="text"> GMT+02:00 Eastern Europian Time - Kiev </span></div>
    <div class="text-ellipsis event-detailed-item" *ngIf="event?.location?.address">
      <mat-icon class="gray-icon gray-icon-map-pin" svgIcon="map-pin"></mat-icon>
      <span class="text"> {{ event?.location?.address }} </span></div>
  </div>

  <div class="map-container" *ngIf="event?.location?.coordinates?.latitude && event?.location?.coordinates?.longitude">
    <app-image-map-preview [latitude]="event.location.coordinates.latitude"
                           [longitude]="event.location.coordinates.longitude">
    </app-image-map-preview>
  </div>

  <div class="linked-information" *ngIf="event?.hasStitched">
    <div class="title-container"><mat-icon class="title-blue-icon" svgIcon="link-bold"></mat-icon>
      <span class="title"> Linked Information </span>
    </div>
   <app-link-info-bar class="linked-list" [stitch]="event"></app-link-info-bar>
  </div>
</div>
