import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinal'
})
export class OrdinalPipe implements PipeTransform {

  transform(value: number | string): string {
    if (value === null || value === undefined || value === '') { return null; }

    let numValue: number;

    if (typeof value === 'string') {
      numValue = parseInt(value, 10);
      if (isNaN(numValue)) { return null; }
    } else {
      numValue = value;
    }

    if (!Number.isInteger(numValue)) { return null; }

    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = numValue % 100;

    return suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0];
  }
}
