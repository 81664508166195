// Common
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

// Types
import { SortOrder } from '@modules/common/types/sort-order';
import { TableColumn } from '@modules/common/types/table-column';

// RX
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'stch-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.less']
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {
  public totalPages = 0;
  public displayHeading = true;
  public waitingForData = true;

  private alive = new Subject<void>();

  @Input() items: any[] = [];
  @Input() columns: TableColumn[] = [];
  @Input() gridTemplate: string;
  @Input() sortBy: string;
  @Input() sortOrder: SortOrder;
  @Input() page: number;
  @Input() perPage: number;
  @Input() count: number;

  @Output() pageChange = new EventEmitter<number>();
  @Output() sortByChange = new EventEmitter<string>();
  @Output() sortOrderChange = new EventEmitter<SortOrder>();
  @Output() rowClick = new EventEmitter<any>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    timer(1000)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.waitingForData = false;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('columns' in changes) {
      this.displayHeading = this.columns.some(({ title }) => title);
    }

    if (!this.gridTemplate && 'columns' in changes) {
      this.gridTemplate = 'minmax(max-content, 1fr) '.repeat(this.columns.length);
    }

    if ('perPage' in changes || 'count' in changes) {
      this.totalPages = Math.ceil(this.count / this.perPage);
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  sort(column: TableColumn) {
    if (!column.sortable) { return; }

    if (this.sortBy === column.key) {
      this.sortOrderChange.emit(this.sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      this.sortByChange.emit(column.key);
      this.sortOrderChange.emit('desc');
    }
  }

  handlePageChange(page: number) {
    this.pageChange.emit(page);
  }
}
