// Common
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

interface Item { type: 'filler' | 'regular'; value?: number; }

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.less']
})
export class PaginationComponent implements OnChanges {
  // Input
  @Input() pages = 1;
  @Input() currentPage = 0;

  // Outputs
  @Output() currentPageChange = new EventEmitter<number>();

  // Public
  public items: Item[] = [];
  public manualInput = new UntypedFormControl('');

  // Private
  private range = 2;

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('pages' in changes || 'currentPage' in changes) {
      this.buildItems();
    }
  }

  /**
   * Actions
   */

  buildItems () {
    const newItems = [];

    newItems.push({ type: 'regular', value: 0 });

    if (this.currentPage - this.range - 1  > 0) {
      newItems.push({ type: 'filler' });
    }

    for (let i = this.currentPage - this.range; i <= this.currentPage + this.range; i++) {
      if (i > 0 && i < this.pages - 1) {
        newItems.push({ type: 'regular', value: i });
      }
    }

    if (this.currentPage + this.range < this.pages - 2) {
      newItems.push({ type: 'filler' });
    }

    if (this.pages > 1) {
      newItems.push({ type: 'regular', value: this.pages - 1 });
    }

    this.items = newItems;
  }

  jumpTo(value: number) {
    if (
      value === null ||
      value === undefined ||
      value < 0 ||
      value > this.pages - 1
    ) { return; }

    this.currentPageChange.emit(value);
  }

  maunalJumpTo() {
    this.jumpTo(this.manualInput.value - 1);
  }
}
