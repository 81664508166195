// Common
import { Component, OnDestroy, Input, TemplateRef, OnInit, ChangeDetectorRef } from '@angular/core';

// RxJS
import { combineLatest, of, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';

// Service
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';
import { KnotsService } from '@modules/knots/services/knots.service';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { Knot } from '@modules/knots/types/knot';
import { Connection } from '@modules/connections/types/connection';
import { Tag } from '@modules/tags/types/tag';
import { ManageListState } from '@modules/knots/types/manage-list-state';
import { KnotFilters } from '@modules/knots/types/knot-filters';

// Components
import { TabsComponent } from '@modules/common/components/tabs/tabs.component';

@Component({
  selector: 'app-knots-knowledge-section',
  templateUrl: './knots-section.component.html',
  styleUrls: ['./knots-section.component.less'],
})
export class KnowledgeKnotsSectionComponent implements OnInit, OnDestroy {
  @Input() tabs: TemplateRef<TabsComponent>;

  public StitchType = StitchType;
  public state: ManageListState;
  public itemTags: Tag[] = [];
  public itemKnots: Knot[] = [];
  public itemConnections: Connection[] = [];
  public neighborsKnots: Knot[] = [];
  public stitchItem: Stitch;
  public pagesCount = 0;
  public page = 0;
  public perPage = 20;
  public knotsHistory: Knot[] = [];
  public tagsHistory: Tag[] = [];
  public connectionsHistory: Connection[] = [];
  public debug: 'score' | 'createdAt' | 'recency' | 'frequency' | 'source' | 'entityType' = null;

  private alive = new Subject<void>();
  private loadNeighbors = new Subject<void>();

  constructor (
    private kpService: KnowledgePanelService,
    private knotsService: KnotsService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    combineLatest([
      this.kpService.getKnotsHistory(),
      this.kpService.getTagsHistory(),
      this.kpService.getConnectionsHistory()
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([knots, tags, connections]) => {
        this.knotsHistory = knots.flat();
        this.tagsHistory = tags.flat();
        this.connectionsHistory = connections.flat();
        this.page = 0;
        this.changeDetector.detectChanges();
        this.loadNeighbors.next();
      });

    this.loadNeighbors
      .pipe(
        debounceTime(300),
        map(() => [
          this.knotsHistory.length ? this.knotsHistory : this.itemKnots,
          this.tagsHistory.length ? this.tagsHistory : this.itemTags,
          this.connectionsHistory.length ? this.connectionsHistory : this.itemConnections
        ]),
        switchMap(([knots, tags, connections]) => (
          knots.length || tags.length || connections.length
            ? this.knotsService.getNeighbors({
                ...KnotFilters.fromManageListState(this.state),
                excludeItems: [this.stitchItem?.id],
                ids: knots.map(({ id }) => id),
                tags,
                connections,
                offset: this.page * this.perPage,
                limit: this.perPage
              })
            : of({ knots: [], count: 0 })
        )),
        takeUntil(this.alive)
      )
      .subscribe(({ knots, count }) => {
        this.pagesCount = Math.ceil(count / this.perPage);
        this.neighborsKnots.length = count;
        this.neighborsKnots = [
          ...this.neighborsKnots.slice(0, this.page * this.perPage),
          ...knots,
          ...this.neighborsKnots.slice((this.page + 1) * this.perPage, count),
        ].filter(i => !!i);
      });

    this.kpService.getSelectedStitchItem()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(stitchItem => {
        this.stitchItem = stitchItem;
      });

    combineLatest([
      this.kpService.getStitchItemTags(),
      this.kpService.getStitchItemKnots(),
      this.kpService.getStitchItemConnections()
    ])
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(([tags, knots, connections]) => {
        this.itemTags = tags;
        this.itemKnots = knots;
        this.itemConnections = connections;
        this.page = 0;
        this.loadNeighbors.next();
      });
  }

  ngOnDestroy() {
    this.loadNeighbors.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClickItem(knot: Knot) {
    this.kpService.addKnotToSelection(knot, false, false, 'kp');
  }

  showMore() {
    this.page = Math.min(this.page + 1, this.pagesCount - 1);
    if (
      !this.neighborsKnots[this.page * this.perPage]
    ) {
      this.loadNeighbors.next();
    }
  }

  showLess() {
    this.page = 0;
  }

  doDebug() {
    switch (this.debug) {
      case 'score':
        this.debug = 'recency';
        break;
      case 'recency':
        this.debug = 'frequency';
        break;
      case 'frequency':
        this.debug = 'createdAt';
        break;
      case 'createdAt':
        this.debug = 'source';
        break;
      case 'source':
        this.debug = 'entityType';
        break;
      case 'entityType':
        this.debug = null;
        break;
      default:
        this.debug = 'score';
    }
  }

  setState(state: ManageListState) {
    this.page = 0;
    this.state = state;
    this.loadNeighbors.next();
  }
}
