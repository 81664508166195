<app-knowledge-chart-2d
  *ngIf="view === '2d'"
  class="chart"
  [sizeChanges]="sizeChanges"
  [item]="item"
  [state]="state"
></app-knowledge-chart-2d>

<app-knowledge-chart-3d
  *ngIf="view === '3d'"
  class="chart"
  [sizeChanges]="sizeChanges"
  [item]="item"
  [state]="state"
></app-knowledge-chart-3d>

<div class="buttons">
  <div
    class="buttons-item"
    [class.active]="view === '2d'"
    (click)="view = '2d'"
  >
    2D
  </div>
  <div
    class="buttons-item"
    [class.active]="view === '3d'"
    (click)="view = '3d'"
  >
    3D
  </div>
</div>
