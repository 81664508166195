// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { CommonModule as AppCommonModule } from '@modules/common/common.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { ElementsModule } from '../elements/elements.module';
import { PopoverModule } from '@modules/popover/popover.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { AddConnectionsComponent } from './components/add-connections/add-connections.component';
import { ConnectionComponent } from './components/connection/connection.component';
import { ConnectionContextMenuComponent } from './components/connection-context-menu/connection-context-menu.component';
import { ConnectionsComponent } from './components/connections/connections.component';
import { ConnectionsListComponent } from './components/connections-list/connections-list.component';
import { ManageConnectionsFormComponent } from './components/manage-components-form/manage-connections-form.component';
import { ManageContextMenuComponent } from './components/manage-context-menu/manage-context-menu.component';

// Directives
import { ManageContextMenuDirective } from './directives/manage-context-menu.directive';
import { ConnectionContextMenuDirective } from './directives/connection-context-menu.directive';

// Pipes
import { GroupAlphabeticallyPipe } from './pipes/group-alphabetically.pipe';
import { SortAndFilterPipe } from './pipes/sort-and-filter.pipe';

@NgModule({
  imports: [
    AppCommonModule,
    CommonModule,
    DragNDropModule,
    ElementsModule,
    FormControlsModule,
    FormsModule,
    MatIconModule,
    MatTooltipModule,
    PopoverModule,
    ScrollingModule,
    UtilsModule,
  ],
  declarations: [
    AddConnectionsComponent,
    ConnectionComponent,
    ConnectionContextMenuComponent,
    ConnectionsComponent,
    ConnectionsListComponent,
    ManageConnectionsFormComponent,
    ManageContextMenuComponent,

    // Directives
    ManageContextMenuDirective,
    ConnectionContextMenuDirective,

    // Pipes
    SortAndFilterPipe,
    GroupAlphabeticallyPipe
  ],
  exports: [
    ConnectionComponent,
    ConnectionsComponent,
    ConnectionsListComponent,
    ManageConnectionsFormComponent,

    // Directives
    ManageContextMenuDirective,
  ]
})
export class ConnectionsModule { }
