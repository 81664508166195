// Common
import { Injectable } from '@angular/core';

// Types
import { Folder } from '../types/folder';
import { File } from '../types/file';
import { Like } from '@modules/common/types/like';

// Services
import { FilesService } from './files.service';
import { SectionsService } from './sections.service';
import { SignalsService } from '@modules/common/services/signals.service';
import { BaseSectionsListService } from '@modules/common/services/base-sections-list.service';

@Injectable()
export class SectionsListService extends BaseSectionsListService<File, Folder>  {

  constructor (
    sectionsService: SectionsService,
    itemsService: FilesService,
    signalsService: SignalsService
  ) {
    super(sectionsService, itemsService, signalsService);
  }

  protected createInstance(data: Like<File>): File {
    return new File(data);
  }
}
