
// Common
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input } from '@angular/core';

// Types
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

const MAX_ZOOM = 6;
const MIN_ZOOM = 0.25;
@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.less']
})
export class PDFPreviewComponent {

  // Inputs
  @Input() file: string;

  // Public
  public currentPage = 0;
  public pagesCount = 1;
  public zoom = 1;
  public zoomStep = 0.25;
  public rotation = 0;
  public rotationStep = 90;

  // Private
  private fullscreen = false;

  /**
   * Constructor
   */

  constructor(
    @Inject(DOCUMENT) private document: any,
    private element: ElementRef
  ) {

  }

  /**
   * Actions
   */

  handlePDFAfterLoad(pdf: PDFDocumentProxy) {
    this.pagesCount = pdf.numPages;
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  zoomIn () {
    this.zoom = Math.min(MAX_ZOOM, this.zoom + this.zoomStep);
  }

  zoomOut () {
    this.zoom = Math.max(MIN_ZOOM, this.zoom - this.zoomStep);
  }

  rotate () {
    this.rotation += this.rotationStep;
  }

  toggleFullscreen() {
    if (this.fullscreen) {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    } else {
      if (this.element.nativeElement.requestFullscreen) {
        this.element.nativeElement.requestFullscreen();
      } else if (this.element.nativeElement.mozRequestFullScreen) {
        /* Firefox */
        this.element.nativeElement.mozRequestFullScreen();
      } else if (this.element.nativeElement.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.nativeElement.webkitRequestFullscreen();
      } else if (this.element.nativeElement.msRequestFullscreen) {
        /* IE/Edge */
        this.element.nativeElement.msRequestFullscreen();
      }
    }

    this.fullscreen = !this.fullscreen;
  }
}
