// Types
import { Like } from '@modules/common/types/like';
import { Filters } from '@modules/common/types/filters';

export class RowsFilters extends Filters {
  projectsIds?: string[];
  title?: string;

  constructor (filters?: Like<RowsFilters>) {
    super(filters);
    this.projectsIds = filters?.projectsIds;
    this.title = filters?.title;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.projectsIds) { result['projects_ids'] = this.projectsIds; }
    if (this.title) { result['title'] = this.title; }

    return result;
  }
}
