// Common
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';

// Types
import { Notebook } from '@modules/notes/types/notebook';
import { Note } from '@modules/notes/types/note';
import { ListState } from '@modules/notes/types/list-state';
import { Tab } from '@modules/common/types/tab';
import { NotebooksListState } from '@modules/notes/types/notebooks-list-state';
import { NotesListState } from '@modules/notes/types/notes-list-state';
import { StateKey } from '@modules/settings/types/state-key';
import { VirtualFolder } from '@modules/notes/types/virtual-folder';
import { NotesFilters } from '@modules/notes/types/notes-filters';
import { NotebooksFilters } from '@modules/notes/types/notebooks-filters';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { NotesAppStateService } from '@modules/notes/services/state.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-notes-list-container',
  templateUrl: './notes-list-container.component.html',
  styleUrls: ['./notes-list-container.component.less'],
})
export class NotesListContainerComponent implements OnInit, OnDestroy {

  public minimized: boolean;
  public searchQuery: string;
  public listState: ListState;
  public sidebarFilter: VirtualFolder;
  public quickNotebook: Notebook;
  public quickNote: Note;
  public loading: boolean;
  public tabs: Tab[] = [
    { title: 'Notebooks', value: 'notebooks'},
    { title: 'Notes', value: 'notes'}
  ];
  public selectedTab: Exclude<ListState, 'tabs'> = 'notes';
  public notesListState = new BehaviorSubject<NotesListState>(null);
  public notebooksListState = new BehaviorSubject<NotebooksListState>(null);
  public notebooksListStateKey: StateKey = StateKey.notebooksListState;
  public notesListStateKey: StateKey = StateKey.notesListState;
  public notebooksFilters: NotebooksFilters;
  public notesFilters: NotesFilters;
  public debug: 'score' = null;

  private alive: Subject<void> = new Subject();

  constructor(
    private splitViewService: SplitViewService,
    private stateService: NotesAppStateService,
    private modalService: ModalService,
    private searchService: AdvancedSearchService,
    private injector: Injector,
  ) {
    combineLatest([
      this.notebooksListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.notebooksFilters = new NotebooksFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([
      this.notesListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.notesFilters = new NotesFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.splitViewService.getMinimized('notesList')
      .pipe(takeUntil(this.alive))
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });

    this.stateService.getTabs()
      .pipe(takeUntil(this.alive))
      .subscribe((state: ListState) => {
        this.listState = state;
        if (this.listState !== 'tabs') {
          this.selectedTab = this.listState;
        }
      });

    combineLatest([
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([state, folder]) => {
        this.sidebarFilter = folder;
        this.quickNote = Note.fromAdvancedState(state, folder);
        this.quickNotebook = Notebook.fromAdvancedState(state, folder);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewNote(note = new Note()) {
    this.stateService.setMainView(note);
  }

  handleNewNotebook(notebook = new Notebook()) {
    this.stateService.setMainView(notebook);
  }

  handleDoubleClickNote(note: Note) {
    this.modalService.openFullForm(note, this.injector);
  }

  handleClickNote(note: Note) {
    this.stateService.setMainView(note);
  }

  handleDoubleClickNotebook(notebook: Notebook) {
    this.modalService.openFullForm(notebook, this.injector);
  }

  handleClickNotebook(notebook: Notebook) {
    this.stateService.setMainView(notebook);
  }

  handleNotesListStateChange(event: NotesListState) {
    this.notesListState.next(event);
  }

  handleNotebooksListStateChange(event: NotebooksListState) {
    this.notebooksListState.next(event);
  }

  doDebug() {
    switch (this.debug) {
      case 'score':
        this.debug = null;
        break;
      default:
        this.debug = 'score';
    }
  }
}
