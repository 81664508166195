// Common
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// Types
import { SidebarItem } from '@modules/common/types/sidebar-item';

// RX
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-settings-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.less'],
})

export class SettingsSideBarComponent implements OnInit, OnDestroy {
  public currentItemId: string;
  public currentContainerId: string;
  public items: SidebarItem[] = [
    { title: 'General', icon: 'sapphire-contact', id: 'general' },
    { title: 'Account', id: 'general/account', parentId: 'general' },
    { title: 'Time & Date', id: 'general/time', parentId: 'general' },
    { title: 'Subscription Plans', id: 'general/plans', parentId: 'general' },
    { title: 'Billing', id: 'general/billing', parentId: 'general' },
    { title: 'Payments History', id: 'general/charges', parentId: 'general' },
    { title: 'Notifications', id: 'general/notifications', parentId: 'general' },
    { title: 'Security', id: 'general/security', parentId: 'general' },

    { title: 'Spaces', icon: 'sapphire-groups', id: 'spaces' },

    { title: 'Email App', icon: 'sapphire-inbox', id: 'mail' },
    { title: 'General', id: 'mail/general', parentId: 'mail' },
    { title: 'IMAP/POP', id: 'mail/imap-pop', parentId: 'mail' },
    { title: 'Email Permissions', id: 'mail/permissions', parentId: 'mail' },
    { title: 'Import', id: 'mail/import', parentId: 'mail' },
    { title: 'Signatures', id: 'mail/signatures', parentId: 'mail' },
    { title: 'Auto Replay Templates', id: 'mail/auto-reply', parentId: 'mail' },
    { title: 'Templates', id: 'mail/templates', parentId: 'mail' },
    { title: 'Quick Replay Templates', id: 'mail/quick-reply', parentId: 'mail' },
    { title: 'Shared Messages', id: 'mail/shared/message', parentId: 'mail' },
    { title: 'Shared Folders', id: 'mail/shared/message-folder', parentId: 'mail' },

    { title: 'Calendar App', icon: 'sapphire-calendars', id: 'calendar' },
    { title: 'Default', id: 'calendar/default', parentId: 'calendar' },
    { title: 'Calendar', id: 'calendar/calendar', parentId: 'calendar' },
    { title: 'Shared Events', id: 'calendar/shared/event', parentId: 'calendar' },
    { title: 'Shared Calendars', id: 'calendar/shared/calendar', parentId: 'calendar' },

    { title: 'Tasking App', icon: 'sapphire-tasks', id: 'tasking' },
    { title: 'Default', id: 'tasking/default', parentId: 'tasking' },
    { title: 'Start & Due Dates', id: 'tasking/due_dates', parentId: 'tasking' },
    { title: 'Shared Tasks', id: 'tasking/shared/task', parentId: 'tasking' },
    { title: 'Shared Projects', id: 'tasking/shared/project', parentId: 'tasking' },

    { title: 'Notes App', icon: 'sapphire-notebook', id: 'notes' },
    { title: 'Default', id: 'notes/default', parentId: 'notes' },
    { title: 'Additional Options', id: 'notes/additional', parentId: 'notes' },
    { title: 'Shared Notes', id: 'notes/shared/note', parentId: 'notes' },
    { title: 'Shared Notebooks', id: 'notes/shared/notebook', parentId: 'notes' },

    { title: 'Contacts App', icon: 'sapphire-contact', id: 'contacts' },
    { title: 'Shared Contacts', id: 'contacts/shared/contact', parentId: 'contacts' },
    { title: 'Shared Groups', id: 'contacts/shared/group', parentId: 'contacts' },

    { title: 'Files App', icon: 'sapphire-file', id: 'files' },
    { title: 'Shared Files', id: 'files/shared/file', parentId: 'files' },
    { title: 'Shared Folders', id: 'files/shared/folder', parentId: 'files' },

    { title: 'Automation Rules', icon: 'sapphire-project', id: 'rules' },
  ];

  private alive = new Subject<void>();

  constructor(
    private router: Router,
  ) {}

  ngOnInit() {
    this.setItem(this.router.url);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.alive)
      )
      .subscribe((event: NavigationEnd) => {
        this.setItem(event.url);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  selectFilter(item: SidebarItem): void {
    this.router.navigate([`/settings/${ item.id }`]);
  }

  setItem(url: string) {
    const pathElements = url.split('/').slice(2)

    this.currentItemId = pathElements.join('/');
    this.currentContainerId = pathElements[0]; 
  }
}
