// Common
import { Component, Input, Output, EventEmitter } from '@angular/core';

// Types
import { Attachment } from '@modules/attachments/types/attachment';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.less']
})
export class AttachmentComponent {

  // Inputs
  @Input() attachment: Attachment;
  @Input() contextMenuEnabled = true;
  @Input() receivedDate: Date = new Date();

  // Outputs
  @Output() open = new EventEmitter();
  @Output() onClick = new EventEmitter<File>();
  @Output() onDoubleClick = new EventEmitter<File>();

  // Public
  public contextMenuOpened = false;

  /**
   * Actions
   */

  download() {
    this.attachment.download();
  }
}
