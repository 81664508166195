<div
  *ngIf="notebook"
  class="notebook"
  [class.selected]="selected && !isDragging"
  [class.pinned]="notebook.pinned"
  [class.hovered]="contextMenuOpened"
  [class.snoozed]="notebook.snoozed"
  [class.followed]="notebook.followed"

  [appNotebookContextMenu]="notebook"
  (appNotebookContextMenuOpened)="contextMenuOpened = $event"
  (appNotebookContextMenuOpenNotebook)="open.emit()"
  (appNotebookContextMenuOpenNote)="openNote.emit($event)"

  appDraggable
  [appDraggableDisabled]="!dragEnabled"
  [appDraggableData]="dragData"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="notebook-selection"></div>
  <div class="notebook-body">
    <div class="notebook-body-title">
      <div
        class="notebook-body-title-text"
        [class.blank]="!notebook.title"
      >
        {{ notebook.title || 'Untitled Notebook' }}
      </div>
    </div>

    <div class="notebook-body-date">
      <div class="notebook-body-date-item">
        {{ notebook.createdAt ? 'Created: ' + (notebook.createdAt | date : 'MMM d') : 'No Created' }}
      </div>
      <div class="notebook-body-date-separator"></div>
      <div class="notebook-body-date-item">
        {{ notebook.updatedAt ? 'Updated: ' + (notebook.updatedAt | date : 'MMM d') : 'No Updated' }}
      </div>
    </div>

    <div
      *ngIf="!withTags && !withKnots"
      class="notebook-body-description"
    >
      {{ notebook.description || 'No description' }}
    </div>

    <app-list-item-tags
      *ngIf="withTags"
      class="notebook-body-tags"
      [tags]="notebook.tags"
    ></app-list-item-tags>

    <app-list-item-knots
      *ngIf="withKnots"
      class="notebook-body-tags"
      [knots]="notebook.knots"
    ></app-list-item-knots>

    <app-link-info-bar [stitch]="notebook"></app-link-info-bar>
  </div>

  <mat-icon
    class="notebook-pin-icon"
    [class.active]="notebook.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  ></mat-icon>

  <mat-icon
    class="notebook-flag-icon"
    [class.active]="notebook.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  ></mat-icon>

  <mat-icon
    class="notebook-marker"
    [svgIcon]="notebook.archived ? 'folder-archive' : 'notebook'"
    [style.color]="notebook.color"
  ></mat-icon>

  <div
    class="notebook-hover-icons"
    *ngIf="dragEnabled && actionsButtonEnabled"
    (click)="$event.stopPropagation()"
  >
    <mat-icon
      class="notebook-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming Soon"
    ></mat-icon>
    <mat-icon
      class="notebook-hover-icons-item"
      svgIcon="snooze"
      [matTooltip]="notebook?.snoozed ? 'Unsnooze' : 'Snooze'"
      [class.active]="!!notebook?.snoozed"
      (click)="snooze()"
    ></mat-icon>
    <mat-icon
      class="notebook-hover-icons-item"
      svgIcon="follow-up"
      [matTooltip]="notebook?.followed ? 'Unfollow' : 'Follow up'"
      [class.active]="!!notebook?.followed"
      (click)="followUp()"
    ></mat-icon>
    <mat-icon
      class="notebook-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="notebook"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    ></mat-icon>
    <mat-icon
      class="notebook-hover-icons-item"
      svgIcon="archive"
      (click)="archive()"
      [matTooltip]="notebook?.archived ? 'Restore from Archive' : 'Archive'"
    ></mat-icon>
    <mat-icon
      class="notebook-hover-icons-item"
      svgIcon="trash"
      (click)="delete()"
      [matTooltip]="notebook?.deleted ? 'Restore from Trash' : 'Trash'"
    ></mat-icon>
  </div>

  <div class="drop-zone"></div>

  <div *ngIf="debug" class="notebook-debug">{{ notebook.score }}</div>
</div>

<div class="placeholder" *ngIf="!notebook">
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
  <div class="placeholder-11"></div>
  <div class="placeholder-12"></div>
</div>

<ng-template #dragPlaceholder>
  <app-notebook [item]="notebook" [dragEnabled]="false"></app-notebook>
</ng-template>

<ng-template #afterDropTemplate let-dragData=dragData>
  <app-button (click)="handleMove(dragData)" label="Move" />
  <app-button (click)="handleStitch(dragData)" label="Stitch" />
</ng-template>
