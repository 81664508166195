// Common
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// Types
import { SidebarItem } from '@modules/common/types/sidebar-item';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-admin-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.less'],
})

export class AdminSideBarComponent implements OnInit, OnDestroy {
  public currentItemId: string;
  public currentContainerId: string;
  public items: SidebarItem[] = [
    { title: 'Users', icon: 'sapphire-contact', id: 'users' },

    { title: 'Subscription Plans', icon: 'sapphire-groups', id: 'plans' },
    { title: 'Plans', id: 'plans/plans', parentId: 'plans' },
    { title: 'Config', id: 'plans/config', parentId: 'plans' },
  ];

  private alive = new Subject<void>();

  constructor(
    private router: Router,
  ) {}

  ngOnInit() {
    this.setItem(this.router.url);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.alive)
      )
      .subscribe((event: NavigationEnd) => {
        this.setItem(event.url);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  selectFilter(item: SidebarItem): void {
    this.router.navigate([`/admin/${ item.id }`]);
  }

  setItem(url: string) {
    const pathElements = url.split('/').slice(2)

    this.currentItemId = pathElements.join('/');
    this.currentContainerId = pathElements[0];
  }
}
