<div class="event">
  <div
    appDraggable
    [appDraggableData]="dragData"
    [appDraggablePlaceholder]="template"
  >
    <ng-template
      [ngTemplateOutlet]="template"
      [ngTemplateOutletContext]="{ notDraggable: true }"
    ></ng-template>
  </div>
</div>

<ng-template #template let-notDraggable="notDraggable">
  <div
    class="data"
    [class.past]="event.startTime | past"
    [class.selected]="selected"
    [class.not-draggable]="notDraggable"
  >
    <div
      class="dot"
      [style.background-color]="event.color"
    ></div>

    <div class="title">{{ event.title }}</div>

    <div class="time">
      {{ event.startTime | date : 'h:mm a' }} -
      {{ event.endTime | date : 'h:mm a' }}
    </div>
  </div>
</ng-template>
