<div
  *ngIf="note"
  class="note"
  [class.selected]="selected && !isDragging"
  [class.pinned]="note.pinned"
  [class.hovered]="contextMenuOpened"
  [class.snoozed]="note.snoozed"
  [class.followed]="note.followed"

  [appNoteContextMenu]="note"
  (appNoteContextMenuOpened)="contextMenuOpened = $event"
  (appNoteContextMenuOpenNote)="open.emit()"

  appDraggable
  [appDraggableDisabled]="!dragEnabled"
  [appDraggableData]="dragData"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"

  (appClick)="onClick.emit(note)"
  (appDblClick)="onDoubleClick.emit(note)"
>
  <div class="note-selection"></div>
  <div class="note-body">
    <div class="note-body-title">
      <div
        class="note-body-title-text"
        [class.blank]="!note.title"
      >
        {{ note.title || 'Untitled Note' }}
      </div>
    </div>

    <div
      *ngIf="!withTags && !withKnots"
      class="note-body-description"
    >
      {{ note.description || note.snippet || 'No description' }}
    </div>

    <app-list-item-tags
      *ngIf="withTags"
      class="note-body-tags"
      [tags]="note.tags"
    ></app-list-item-tags>

    <app-list-item-knots
      *ngIf="withKnots"
      class="note-body-tags"
      [knots]="note.knots"
    ></app-list-item-knots>

    <app-link-info-bar [stitch]="note"></app-link-info-bar>
  </div>

  <mat-icon
    class="note-pin-icon"
    [class.active]="note.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  ></mat-icon>

  <mat-icon
    class="note-flag-icon"
    [class.active]="note.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  ></mat-icon>

  <mat-icon
    class="note-marker"
    [svgIcon]="note.archived ? 'folder-archive' : 'note-small'"
    [style.color]="note.color"
  ></mat-icon>

  <div
    class="note-hover-icons"
    *ngIf="dragEnabled && actionsButtonEnabled"
    (click)="$event.stopPropagation()"
  >
    <mat-icon
      class="note-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming Soon"
      (click)="createMail($event)"
    ></mat-icon>
    <mat-icon
      class="note-hover-icons-item"
      svgIcon="snooze"
      [matTooltip]="note?.snoozed ? 'Unsnooze' : 'Snooze'"
      [class.active]="!!note?.snoozed"
      (click)="snooze()"
    ></mat-icon>
    <mat-icon
      class="note-hover-icons-item"
      svgIcon="follow-up"
      [matTooltip]="note?.followed ? 'Unfollow' : 'Follow up'"
      [class.active]="!!note?.followed"
      (click)="followUp()"
    ></mat-icon>
    <mat-icon
      class="note-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="note"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    ></mat-icon>
    <mat-icon
      class="note-hover-icons-item"
      svgIcon="archive"
      (click)="archive()"
      [matTooltip]="note?.archived ? 'Restore from Archive' : 'Archive'"
    ></mat-icon>
    <mat-icon
      class="note-hover-icons-item"
      svgIcon="trash"
      (click)="delete()"
      [matTooltip]="note?.deleted ? 'Restore from Trash' : 'Trash'"
    ></mat-icon>
  </div>

  <div class="drop-zone"></div>

  <div *ngIf="debug" class="note-debug">{{ note.score }}</div>
</div>

<div class="placeholder" *ngIf="!note">
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
  <div class="placeholder-11"></div>
</div>

<ng-template #dragPlaceholder>
  <app-note [item]="note" [dragEnabled]="false"></app-note>
</ng-template>
