<app-context-menu-state-indicator
  class="state-indicator"
  svgIcon="filters"
  appStitchListContextMenu
  [appStitchListContextMenuWithStitchType]="false"
  (appStitchListContextMenuChange)="listState = $event"
  [appStitchListContextMenuStateVariant]="contextMenuStateVariant"
/>

<app-files-input
  [inputFormControl]="inputControl"
  [multiple]="true"
  [withErrors]="true"
  [validationMessages]="{
    size: 'File is too large. Maximum size 25MB.',
    uploading: 'Can\'t upload file'
  }"
/>

<app-linked-info-list
  class="form-linked-info"
  [stitch]="stitchItem"
  [withUnlink]="false"
  [withUnlinkAll]="false"
  [allowedStitchTypes]="allowedStitchTypes"
  [state]="listState"
  (clickStitchItem)="onClickItem.emit($event)"
  (dblClickStitchItem)="onDblClickItem.emit($event)"
/>
