import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'last'
})
export class LastPipe implements PipeTransform {

  transform(
    array?: unknown[],
  ): unknown {
    return array?.[array?.length - 1];
  }
}
