<app-input
  class="input"
  [inputFormControl]="form.controls.title"
  appearance="amethyst"
  label="Title"
></app-input>

<app-date-input
  class="input"
  [inputFormControl]="form.controls.dateFrom"
  placeholder="Date from"
  appearance="amethyst"
></app-date-input>

<app-date-input
  class="input"
  [inputFormControl]="form.controls.dateTo"
  placeholder="Date to"
  appearance="amethyst"
></app-date-input>

<div class="toggle-container">
  <span class="toggle-container-title">Enable this template</span>
  <app-toggle-switch
    [inputFormControl]="form.controls.enabled"
  ></app-toggle-switch>
</div>

<app-rich-text-editor
  class="editor"
  [control]="form.controls.content"
/>

<div class="actions">
  <app-button
    appearance="purple-outline"
    label="Cancel"
    (click)="handleClose()"
  />

  <app-button
    appearance="purple"
    label="Submit"
    (click)="handleSubmit()"
  />
</div>
