// Common
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';

// Types
import { Space } from '@modules/settings/types/space';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { AlertService } from '@modules/alert/services/alert.service';
import { SpacesService } from '@modules/settings/services/spaces.service';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Components
import { SpaceFormComponent } from './space-form/space-form.component';
import { SpaceParticipantsComponent } from './space-participants/space-participants.component';

@Component({
  selector: 'app-settings-spaces-spaces',
  templateUrl: './spaces.component.html',
  styleUrls: ['./spaces.component.less']
})
export class SpacesSpacesComponent implements OnInit, OnDestroy {

  public spaces: Space[];

  private alive = new Subject<void>();

  constructor (
    private modalService: ModalService,
    private alertsService: AlertService,
    private spacesService: SpacesService,
    private injector: Injector,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.spacesService.listAll()
      .pipe(takeUntil(this.alive))
      .subscribe(spaces => {
        this.spaces = spaces;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  openForm(space = new Space()) {
    this.modalService.showModal({
      component: SpaceFormComponent,
      content: { space },
      appearance: 'amethyst',
      title: space.id ? 'EditSpace' : 'Create Space',
      resize: true,
      collapsible: true,
      expandable: false,
      maxModals: 1,
      injector: this.injector,
    });
  }

  openParticipantsForm(space: Space) {
    this.modalService.showModal({
      component: SpaceParticipantsComponent,
      content: { space },
      appearance: 'amethyst',
      title: `${ space.title } Participants`,
      resize: true,
      collapsible: true,
      expandable: false,
      maxModals: 1,
      injector: this.injector,
    });
  }

  removeSpace(item: Space) {
    this.spacesService.delete(item);
  }

  removeSpacePrompt(event: MouseEvent, item: Space) {
    event.preventDefault();
    event.stopPropagation();

    this.alertsService.show({
      title: 'Are you sure?',
      body: `Are you sure you want to delete Space ${item.title}?`,
      rightButtons: [
        {
          title: 'CANCEL',
          close: true
        },
        {
          title: 'REMOVE',
          click: () => this.removeSpace(item),
          close: true
        }
      ],
      showUntil: this.alive
    });
  }
}
