// Common
import { AccountService } from '@modules/account/services/account.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// RX
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard  {
  constructor(private accountService: AccountService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.accountService.getAccount().pipe(
      map(account => {
        if (account.admin) {
          return true;
        } else {
          this.router.navigate(['/mail']);
          return false;
        }
      })
    );
  }
}
