<div
  class="container"
  stchPopover
  stchPopoverPlacement="bottomLeft"
  stchPopoverTrigger="click"
  [stchPopoverContent]="formTemplate"
  [stchPopoverShowUntil]="popoverClose"
  [stchPopoverFallbackPlacements]="['bottomRight', 'bottomLeft']"
  (stchPopoverVisibleChange)="handlePopoverOpen($event)"
>
  <stch-inputs-group
    class="group"
    size="s"
    [active]="opened"
  >
    <app-input
      class="group-input"
      appearance="sapphire-outline"
      size="s"
      [inputFormControl]="displayFromDate"
      placeholder="No Date"
      [disabledInteraction]="true"
    />
    <app-dropdown-input
      class="group-input"
      [placeholder]="displayFromTime"
      [options]="[]"
      appearance="sapphire-outline"
      size="m"
    />
  </stch-inputs-group>

  <mat-icon class="icon" svgIcon="sapphire-arrow-right" />

  <stch-inputs-group
    class="group"
    size="s"
    [active]="opened"
  >
    <app-input
      class="group-input"
      appearance="sapphire-outline"
      size="s"
      [inputFormControl]="displayToDate"
      placeholder="No Date"
      [disabledInteraction]="true"
    />
    <app-dropdown-input
      class="group-input"
      [placeholder]="displayToTime"
      [options]="[]"
      appearance="sapphire-outline"
      size="m"
    />
  </stch-inputs-group>
</div>

<ng-template #formTemplate>
  <div class="popover">
    <div class="header">
      <ng-container
        [ngTemplateOutlet]="headerDateInput"
        [ngTemplateOutletContext]="{
          placeholder: 'Start Date',
          control: innerFromDate,
          dateButtonType: 'from'
        }"
      />
      <ng-container
        [ngTemplateOutlet]="headerTimeInput"
        [ngTemplateOutletContext]="{
          control: innerFromTime,
          timeButtonType: 'from'
        }"
      />
      <div class="filler"></div>
      <ng-container
        [ngTemplateOutlet]="headerDateInput"
        [ngTemplateOutletContext]="{
          placeholder: 'End Date',
          control: innerToDate,
          dateButtonType: 'to'
        }"
      />
      <ng-container
        [ngTemplateOutlet]="headerTimeInput"
        [ngTemplateOutletContext]="{
          control: innerToTime,
          timeButtonType: 'to'
        }"
      />
    </div>

    <div
      *ngIf="state === 'main'"
      class="main"
    >
      <div class="sidebar">
        <div class="quick-options">
          <div
            class="item"
            [class.active]="innerFromDate?.value | compareToRelativeDate: 'today'"
            (click)="setDate('today')"
          >
            <div class="title">Today</div>
            <div class="preview">{{ 'today' | fromRelativeDate | date : 'EEE' }}</div>
          </div>

          <div
            class="item"
            [class.active]="innerFromDate?.value | compareToRelativeDate: 'tomorrow'"
            (click)="setDate('tomorrow')"
          >
            <div class="title">Tomorrow</div>
            <div class="preview">{{ 'tomorrow' | fromRelativeDate | date : 'EEE' }}</div>
          </div>

          <div
            class="item"
            [class.active]="innerFromDate?.value | compareToRelativeDate: 'nextMonday'"
            (click)="setDate('nextMonday')"
          >
            <div class="title">Next Week</div>
            <div class="preview">{{ 'nextMonday' | fromRelativeDate | date : 'EEE' }}</div>
          </div>

          <div
            class="item"
            [class.active]="innerFromDate?.value | compareToRelativeDate: 'next2Weeks'"
            (click)="setDate('next2Weeks')"
          >
            <div class="title">2 Weeks</div>
            <div class="preview">{{ 'next2Weeks' | fromRelativeDate | date : 'd MMM' }}</div>
          </div>

          <div
            class="item"
            [class.active]="innerFromDate?.value | compareToRelativeDate: 'nextMonth'"
            (click)="setDate('nextMonth')"
          >
            <div class="title">Next 30 Days</div>
            <div class="preview">{{ 'nextMonth' | fromRelativeDate | date : 'd MMM' }}</div>
          </div>
        </div>
        <div class="filler"></div>
        <div class="selectors">
          <div
            *ngIf="recurrence"
            class="item"
            [class.active]="recurrencePopoverOpened"
            [class.has-value]="!!innerRecurrence.value"
            stchPopover
            stchPopoverPlacement="topLeft"
            [stchPopoverContent]="recurrencePopover"
            stchPopoverTrigger="click"
            [stchPopoverNoBorder]="true"
            [stchPopoverShowUntil]="recurrencePopoverHide"
            (stchPopoverVisibleChange)="recurrencePopoverOpened = $event"
          >
            <mat-icon class="icon" svgIcon="sapphire-repeat" />
            <div class="text">{{ displayRecurrence }}</div>
            <mat-icon class="icon" svgIcon="sapphire-chevron-right" />
          </div>
          <div
            class="item"
            [class.active]="remindersPopoverOpened"
            [class.has-value]="innerReminders.value.length"
            stchPopover
            stchPopoverPlacement="topFullWidth"
            stchPopoverFallbackPlacements="[]"
            [stchPopoverContent]="remindersPopover"
            stchPopoverTrigger="click"
            [stchPopoverNoBorder]="true"
            [stchPopoverShowUntil]="remindersPopoverHide"
            (stchPopoverVisibleChange)="remindersPopoverOpened = $event"
          >
            <mat-icon class="icon" svgIcon="sapphire-reminder" />
            <div class="text">{{ displayReminders }}</div>
            <mat-icon class="icon" svgIcon="sapphire-chevron-right" />
          </div>
        </div>
      </div>

      <div class="form">
        <stch-date-picker
          [control]="dateButtonSelected === 'from' ? innerFromDate : innerToDate"
          [max]="dateButtonSelected === 'from' ? innerToDate.value : null"
          [min]="dateButtonSelected === 'from' ? null : innerFromDate.value"
          [range]="[innerFromDate.value, innerToDate.value]"
        />
      </div>
    </div>

    <div
      *ngIf="state === 'reminders'"
      class="secondary-form"
    >
      <div class="secondary-form-header">
        <app-button
          appearance="sapphire-secondary-ghost"
          size="xs"
          icon="sapphire-arrow-left"
          (click)="state = 'main'"
        />
        Reminders
      </div>

      <stch-reminders-picker
        class="reminders"
        [control]="innerReminders"
      />
    </div>

    <div
      *ngIf="state === 'recurrency'"
      class="secondary-form"
    >
      <div class="secondary-form-header">
        <app-button
          appearance="sapphire-secondary-ghost"
          size="xs"
          icon="sapphire-arrow-left"
          (click)="state = 'main'"
        />
        Repeat
      </div>

      <app-recurrence-form
        class="recurrence"
        [control]="innerRecurrence"
        [referenceDate]="innerFromDate"
      />
    </div>

    <div class="footer">
      <app-button
        *ngIf="state === 'main'"
        appearance="sapphire-primary"
        size="s"
        (click)="handleSave()"
        label="Save"
      />

      <app-button
        *ngIf="state !== 'main'"
        appearance="sapphire-primary"
        size="s"
        (click)="state = 'main'"
        label="Apply"
      />
    </div>
  </div>
</ng-template>

<ng-template #recurrencePopover>
  <app-recurrence-dropdown
    [control]="innerRecurrence"
    [referenceDate]="innerFromDate"
    appearance="sapphire"
    (onSelect)="recurrencePopoverHide.next()"
    (onCustom)="state = 'recurrency'"
  />
</ng-template>

<ng-template #remindersPopover>
  <app-reminders-dropdown
    [control]="innerReminders"
    appearance="sapphire"
    (onSelect)="remindersPopoverHide.next()"
    (onCustom)="state = 'reminders'"
  />
</ng-template>

<ng-template
  #headerDateInput
  let-placeholder="placeholder"
  let-control="control"
  let-dateButtonType="dateButtonType"
>
  <div
    class="header-input"
    [class.active]="dateButtonSelected === dateButtonType"
    (click)="dateButtonSelected = dateButtonType"
  >
    <mat-icon class="icon" svgIcon="sapphire-date" />
    <div [class.filled]="!!control.value">
      {{ (control.value | date : 'yy/MM/dd') || placeholder }}
    </div>
    <mat-icon
      *ngIf="control.value"
      class="icon-close"
      svgIcon="sapphire-close"
      (click)="control.setValue(null)"
    />
  </div>
</ng-template>

<ng-template
  #headerTimeInput
  let-control="control"
  let-timeButtonType="timeButtonType"
>
  <div
    class="header-input"
    [class.active]="timeButtonSelected === timeButtonType"

    stchPopover
    stchPopoverPlacement="bottomLeft"
    stchPopoverTrigger="click"
    [stchPopoverTemplate]="timeSelector"
    [stchPopoverContext]="{ control: control }"
    [stchPopoverFallbackPlacements]="[]"
    [stchPopoverNoBorder]="true"
    (stchPopoverVisibleChange)="handleTimePopoverOpen($event, timeButtonType)"
  >
    <mat-icon class="icon" svgIcon="sapphire-time" />
    <div [class.filled]="!!control.value">
      {{ (control.value | date : 'hh/mm a') || 'Add Time' }}
    </div>
    <mat-icon
      *ngIf="control.value"
      class="icon-close"
      svgIcon="sapphire-close"
      (click)="control.setValue(null)"
    />
  </div>
</ng-template>

<ng-template #timeSelector let-control="control">
  <stch-time-selector [control]="control"/>
</ng-template>
