<div class="form">
  <div class="form-date">{{ task?.createdAt | date : 'MMM d' }}</div>

  <h2 class="form-title">
    <mat-icon
      class="form-heading-title-icon"
      svgIcon="project"
      [style.color]="task?.color"
    />
    {{ task?.title }}
  </h2>

  <div class="form-details">
    <div class="form-details-row">
      <div class="form-details-row-title">Parent Task</div>
      <div class="form-details-row-input">{{ task?.parentId }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Project</div>
      <div class="form-details-row-input">{{ task?.projectId }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Description</div>
      <div class="form-details-row-input">{{ task?.description }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Issue Key Title</div>
      <div class="form-details-row-input">{{ task?.issueKeyTitle }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">From Date</div>
      <div class="form-details-row-input">{{ task?.fromDate }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">To Date</div>
      <div class="form-details-row-input">{{ task?.toDate }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Priority</div>
      <div class="form-details-row-input">{{ task?.priority }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Assignee</div>
      <div class="form-details-row-input">{{ task?.assigneeId }}</div>
    </div>
  </div>
</div>
