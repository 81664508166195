import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';

export class LinkedInfo {
  type: StitchType;
  id: string;
  createdAt?: Date;
  color?: string;

  constructor(stitchItem: Stitch) {
    this.id = stitchItem?.id;
    this.type = stitchItem.getStitchType();
    this.color = stitchItem.color;
  }
}
