// Types
import { SortOrder } from './sort-order';

export abstract class Filters {
  originsIds?: string[];
  sortOrder?: SortOrder;
  ids?: string[];
  exceptIds?: string[];

  withEmpty?: boolean;

  limit?: number;
  offset?: number;

  constructor(filters?: Partial<Filters>) {
    this.exceptIds = filters?.exceptIds;
    this.ids = filters?.ids;
    this.limit = filters?.limit;
    this.offset = filters?.offset;
    this.originsIds = filters?.originsIds;
    this.sortOrder = filters?.sortOrder;
    this.withEmpty = filters?.withEmpty;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = {};

    if (this.ids) { result['ids[]'] = this.ids; }
    if (this.exceptIds) { result['except_ids[]'] = this.exceptIds; }

    if (this.limit) { result['limit'] = this.limit + ''; }
    if (this.offset) { result['offset'] = this.offset + ''; }

    result['sort_order'] = this.sortOrder || 'asc';

    return result;
  }
}
