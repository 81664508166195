import { Injector } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }]});

export class Settings {
  formBuilder = injector.get(UntypedFormBuilder);

  trialPeriod?: number;
  gracePeriod?: number;

  constructor(data: any = {}) {
    this.trialPeriod = data?.trialPeriod;
    this.gracePeriod = data?.gracePeriod;
  }

  static fromFormGroup(form: UntypedFormGroup): Settings {
    const formValues = form.value;

    return new Settings({
      trialPeriod: formValues.trialPeriod,
      gracePeriod: formValues.gracePeriod,
    });
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      trialPeriod: [this.trialPeriod, [Validators.required, Validators.min(1)]],
      gracePeriod: [this.gracePeriod, [Validators.required, Validators.min(1)]]
    });
  }

  asPayloadJSON() {
    return {
      trialPeriod: this.trialPeriod,
      gracePeriod: this.gracePeriod,
    };
  }
}
