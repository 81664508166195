// Common
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { Knot } from '@modules/knots/types/knot';
import { ManageListState } from '@modules/knots/types/manage-list-state';

// RX
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Services
import { KnotsService } from '@modules/knots/services/knots.service';

@Component({
  selector: 'app-manage-knots-form',
  templateUrl: './manage-knots-form.component.html',
  styleUrls: ['./manage-knots-form.component.less']
})
export class ManageKnotsFormComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() existingKnots: Knot[] = [];

  // Outputs
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter<Knot[]>();

  // Public
  public inputControl = new UntypedFormControl('');
  public scrollShadowTop = false;
  public scrollShadowBottom = false;
  public state: ManageListState;
  public canAdd = false;
  public knots: Knot[] = [];

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor (
    private knotsService: KnotsService
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.inputControl.valueChanges
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(value => {
        this.canAdd = value.trim() !== '' && !this.knots?.some(item => item.name === value);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('existingKnots' in changes && this.existingKnots?.length) {
      this.knots = this.existingKnots.map(knot => new Knot(knot));
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  addKnot() {
    const name = this.inputControl.value.trim();

    if (name === '') { return; }

    this.knotsService.search({ names: [name] })
      .pipe(
        take(1),
        takeUntil(this.alive)
      )
      .subscribe(({ items: knots }) => {
        if (knots[0]) {
          this.knots = [...this.knots, new Knot({ ...knots[0], added: true })];
        } else {
          this.knots = [...this.knots, new Knot({ name, added: true })];
        }
      });

    this.inputControl.setValue('');
  }

  handlePin(knot: Knot) {
    this.knots = this.knots.map(item => item.name === knot.name
      ? new Knot({ ...knot, pinned: !knot.pinned, changed: true })
      : item
    );
  }

  handleDelete(knot: Knot) {
    if (knot.added) {
      this.knots = this.knots.filter(item => item.name !== knot.name);
    } else {
      this.knots = this.knots.map(item => item.name === knot.name
        ? new Knot({ ...knot, deleted: true })
        : item
      );
    }
  }

  handleChange(knot: Knot) {
    if (knot.id === null || knot.id === undefined) { return; }

    this.knots = this.knots.map(item => knot.id === item.id ? new Knot({ ...item, changed: true }) : item);
  }

  handleCancel() {
    this.close.emit();
  }

  handleSave() {
    this.save.emit(this.knots);
  }
}
