// Common
import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { CamelCaseToWordsPipe } from '@modules/utils/pipes/string/camel-case-to-words.pipe';

// Types
import { User } from '@modules/admin/types/user';
import { TableColumn } from '@modules/common/types/table-column';

@Component({
  selector: 'app-admin-user-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.less']
})
export class UserUserComponent implements OnInit, OnChanges {

  public columns: TableColumn[];
  public items: any[] = [];

  @Input() user: User;

  @ViewChild('rowTemplate', { static: true }) rowTemplate: TemplateRef<void>;

  constructor (
    private camelCaseToWordsPipe: CamelCaseToWordsPipe
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.columns = [
      { key: 'title' },
      { key: 'value', template: this.rowTemplate },
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('user' in changes && this.user) {
      this.items = Object.entries(this.user)
        .filter(([key]) => !['avatar', 'id'].includes(key))
        .map(([key, value]) => ({
          key,
          title: this.camelCaseToWordsPipe.transform(key),
          value
        }));
    }
  }
}
