// Common
import { Like } from '@modules/common/types/like';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { StitchFilters } from '@modules/common/types/stitch-filters';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';
import { VirtualFolder } from './virtual-folder';
import { CalendarsListState } from './calendars-list-state';

export class CalendarsFilters extends StitchFilters {
  // Filters
  location?: number; // TODO Need to find out what data google geoposition API returns
  fromTime?: Date;
  toTime?: Date;
  sortBy?: 'title' | 'date' | 'due-date' | 'score' | 'sharedViaLinkAt';
  timeZone?: string;

  constructor (filters?: Like<CalendarsFilters>) {
    super(filters);
    this.fromTime = filters?.fromTime;
    this.toTime = filters?.toTime;
    this.snoozedOnTop = filters?.snoozedOnTop;
    this.followedOnTop = filters?.followedOnTop;
    this.timeZone = filters?.timeZone;
  }

  static fromListState(state: CalendarsListState): CalendarsFilters {
    const filters = new CalendarsFilters();

    if (state?.flagged) {
      filters.flagged = true;
    }

    if (state?.pinned) {
      filters.pinned = true;
    }

    filters.stitch = state?.stitchedWith;

    if (state?.sortOrder) {
      filters.sortOrder = state.sortOrder;
    }

    if (state?.pinnedOnTop) {
      filters.pinnedOnTop = state.pinnedOnTop;
    }

    if (state?.flaggedOnTop) {
      filters.flaggedOnTop = state.flaggedOnTop;
    }

    return filters;
  }

  applyListState(state: CalendarsListState) {
    this.flagged = state?.flagged;
    this.pinned = state?.pinned;
    this.fromTime = state?.from;
    this.toTime = state?.to;

    if (state?.today) {
      const today = new Date();
      this.fromTime = beginningOfDay(today);
      this.toTime = endOfDay(today);
    }

    this.stitch = state?.stitchedWith;
    this.sortBy = state?.sortBy;
    this.sortOrder = state?.sortOrder;
    this.pinnedOnTop = state?.pinnedOnTop;
    this.flaggedOnTop = state?.flaggedOnTop;
    this.snoozedOnTop = state?.snoozedOnTop;
    this.followedOnTop = state?.followedOnTop;

    return this;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.location) { result['location'] = this.location; }
    if (this.timeZone) { result['time_zone'] = this.timeZone; }

    result['sort_by'] = this.sortBy || 'title';

    return result;
  }

  applyAdvancedFilters(advanced: AdvancedSearchState) {
    super.applyAdvancedFilters(advanced);

    const state = advanced.calendar;

    this.parentsIds = state.containersIds;
    this.noParent = !state.containersIds?.length;
    this.esTitle = state.subject;
    this.esBody = state.body;
    this.fromTime = state.fromDate;
    this.toTime = state.toDate;
    this.timeZone = state.timeZone;

    return this;
  }

  applyVirtualFolder(folder: VirtualFolder) {
    if (!folder) { return this; }

    switch (folder) {
      case 'archived':
        this.archived = true;
        this.deleted = null;
        break;
      case 'deleted':
        this.deleted = true;
        this.archived = null;
        break;
      case 'followed':
        this.followed = true;
        this.followedOnTop = false;
        this.snoozedOnTop = false;
        break;
      case 'snoozed':
        this.snoozed = true;
        this.snoozedOnTop = false;
        this.followedOnTop = false;
        break;
      case 'all_events':
      case 'all_calendars':
        break;
      case 'flagged':
        this.flagged = true;
        break;
      default:
        checkExhaustiveness(folder);
    }

    return this;
  }
}
