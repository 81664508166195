// Common
import {
  Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, TemplateRef,
  Output, EventEmitter, ViewChild, ElementRef, AfterViewInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';

// RX
import { Subject, of, timer } from 'rxjs';
import { switchMap, filter, takeUntil, debounceTime, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-simple-input',
  templateUrl: './simple-input.component.html',
  styleUrls: ['./simple-input.component.less', '../../styles/input.less'],
})
export class SimpleInputComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  // View Children
  @ViewChild('input', { static: true }) searchInput: ElementRef;

  // Inputs
  @Input() inputFormControl: UntypedFormControl;
  @Input() suggestions?: AutocompleteFactory<unknown>;
  @Input() placeholder: string;
  @Input() staticOptions?: DropdownSelectItem<unknown>[];
  @Input() sideOptions?: DropdownSelectItem<unknown>[];
  @Input() sideValue?: string;
  @Input() itemTemplate?: TemplateRef<unknown>;
  @Input() noBorder = false;
  @Input() withClear = false;
  @Input() forceOutput = false;
  @Input() openImmediately = true;
  @Input() focusImmediately = false;

  // Outputs
  @Output() onSelect = new EventEmitter<DropdownSelectItem<unknown>>();
  @Output() onSideValueChange = new EventEmitter<unknown>();
  @Output() onEnter = new EventEmitter<void>();
  @Output() onFocus = new EventEmitter<void>();
  @Output() onBlur = new EventEmitter<void>();

  // Public
  public innerControl = new UntypedFormControl();
  public options: DropdownSelectItem<unknown>[] = [];
  public popoverCustomTrigger = new Subject();
  public popoverHide = new Subject();
  public sidePopoverHide = new Subject();
  public focused = false;
  public selectedSideOption: DropdownSelectItem<unknown>;
  public popoverWidth = 0;

  // Private
  private alive: Subject<void> = new Subject();
  private suggestionsChanged = new Subject();
  private inputControlChanged = new Subject();
  private currentItem: DropdownSelectItem<unknown> = { title: null, value: null };
  private skipLoadingSuggestions = false;

  /**
   * Constructor
   */

  constructor () {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.suggestionsChanged
      .pipe(
        switchMap(() => this.innerControl.valueChanges.pipe(startWith(''))),
        debounceTime(600),
        filter(() => {
          const skip = this.skipLoadingSuggestions;
          this.skipLoadingSuggestions = false;
          return !skip;
        }),
        filter(() => !!this.suggestions),
        // distinctUntilChanged((a, b) => a === b),
        switchMap(title => this.suggestions(title)),
        takeUntil(this.alive)
      )
      .subscribe(options => {
        this.options = options;

        if (this.options.length > 0 && !this.openImmediately) {
          this.open();
        }

        if (this.options.length === 0 && !this.openImmediately) {
          this.popoverHide.next();
        }
      });

    this.suggestionsChanged.next();

    this.innerControl.valueChanges
      .pipe(
        filter(() => !this.suggestions || this.forceOutput),
        takeUntil(this.alive),
      )
      .subscribe(value => {
        if (value !== this.inputFormControl?.value) {
          this.inputFormControl?.setValue(value);
        }

        if (value === '' && !this.openImmediately) {
          this.popoverHide.next();
        }
      });

    this.inputControlChanged
      .pipe(
        filter(() => !!this.inputFormControl),
        switchMap(() => (
          this.inputFormControl.valueChanges
            .pipe(startWith(this.inputFormControl.value))
        )),
        filter(value => value !== this.currentItem.value),
        switchMap(value => value && this.suggestions ? this.suggestions(undefined, [value]) : of([])),
        takeUntil(this.alive)
      )
      .subscribe(([option]) => {
        if (this.forceOutput) {
          this.innerControl.setValue(this.inputFormControl.value);
        } else if (!this.suggestions && this.inputFormControl.value !== this.innerControl.value) {
          this.innerControl.setValue(this.inputFormControl.value);
        } else if (option) {
          this.currentItem = option;
          this.innerControl.setValue(option.title);
        } else if (this.suggestions) {
          this.currentItem = { title: null, value: null };
          this.innerControl.setValue('');
        }
      });

    this.inputControlChanged.next();
  }

  ngAfterViewInit() {
    timer(0)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.focusImmediately && this.searchInput.nativeElement.focus();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('suggestions' in changes) {
      this.suggestionsChanged.next();
    }

    if ('inputFormControl' in changes) {
      this.inputControlChanged.next();
    }

    if (('sideOptions' in changes || 'sideValue' in changes) && this.sideOptions && this.sideValue) {
      this.selectedSideOption = this.sideOptions.find(option => option.value === this.sideValue);
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleFocus() {
    this.onFocus.emit();
    this.focused = true;
    if (this.openImmediately) {
      this.open();
    }
  }

  handleBlur() {
    this.onBlur.emit();
    this.focused = false;
  }

  handleSelect(option: DropdownSelectItem<unknown>) {
    if (option.disabled) { return; }

    this.currentItem = option;
    this.skipLoadingSuggestions = true;
    this.innerControl.setValue(option.title);

    if (this.inputFormControl) {
      this.inputFormControl.setValue(option.value);
      this.inputFormControl.markAsDirty();
    }

    this.onSelect.emit(option);
    this.popoverHide.next();
  }

  handleSideSelect(option: DropdownSelectItem<unknown>) {
    this.onSideValueChange.emit(option.value);
    this.sidePopoverHide.next();
  }

  handleEnter() {
    this.onEnter.emit();
  }

  handleClear() {
    this.innerControl.setValue('');
    this.inputFormControl.setValue(null);
    this.inputFormControl.markAsDirty();
  }

  open() {
    this.popoverWidth = this.searchInput.nativeElement.offsetWidth + (this.withClear ? 24 : 0);
    this.popoverCustomTrigger.next();
  }
}
