<div
  class="stitch-info-list"
  [ngClass]="appearance"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="handleDrop($event)"
>
  <div
    *ngIf="withDroppable"
    class="drop-area-border"
  ></div>

    <ng-container
      *ngFor="let info of linkedInfoData"
      [ngSwitch]="info.type"
    >
      <app-link-info-message-folder
        *ngSwitchCase="'message-folder'"
        class="item"
        [folder]="info.data"
        (remove)="handleRemoveItem(info)"
        (unlink)="handleUnlinkItem(info)"
        [viewType]="state?.view"
        [withDraggable]="withDraggable"
        [withActions]="withActions"
        [withContextMenu]="withContextMenu"
        [removeOnly]="removeOnly"
        [withUnlink]="withUnlink"
        (appClick)="clickStitchItem.emit(info.data)"
        (appDblClick)="dblClickStitchItem.emit(info.data)"
      ></app-link-info-message-folder>

      <app-link-info-message
        *ngSwitchCase="'message'"
        class="item"
        [message]="info.data"
        (remove)="handleRemoveItem(info)"
        (unlink)="handleUnlinkItem(info)"
        [viewType]="state?.view"
        [withDraggable]="withDraggable"
        [withActions]="withActions"
        [withContextMenu]="withContextMenu"
        [removeOnly]="removeOnly"
        [withUnlink]="withUnlink"
        (appClick)="clickStitchItem.emit(info.data)"
        (appDblClick)="dblClickStitchItem.emit(info.data)"
      ></app-link-info-message>

      <app-link-info-event
        *ngSwitchCase="'event'"
        class="item"
        [event]="info.data"
        (remove)="handleRemoveItem(info)"
        (unlink)="handleUnlinkItem(info)"
        [viewType]="state?.view"
        [withDraggable]="withDraggable"
        [withActions]="withActions"
        [withContextMenu]="withContextMenu"
        [removeOnly]="removeOnly"
        [withUnlink]="withUnlink"
        (appClick)="clickStitchItem.emit(info.data)"
        (appDblClick)="dblClickStitchItem.emit(info.data)"
      ></app-link-info-event>

      <app-link-info-project
        *ngSwitchCase="'project'"
        class="item"
        [project]="info.data"
        (remove)="handleRemoveItem(info)"
        (unlink)="handleUnlinkItem(info)"
        [viewType]="state?.view"
        [withDraggable]="withDraggable"
        [withActions]="withActions"
        [withContextMenu]="withContextMenu"
        [removeOnly]="removeOnly"
        [withUnlink]="withUnlink"
        (appClick)="clickStitchItem.emit(info.data)"
        (appDblClick)="dblClickStitchItem.emit(info.data)"
      ></app-link-info-project>

      <app-link-info-task
        *ngSwitchCase="'task'"
        class="item"
        [task]="info.data"
        (remove)="handleRemoveItem(info)"
        (unlink)="handleUnlinkItem(info)"
        [viewType]="state?.view"
        [withDraggable]="withDraggable"
        [withActions]="withActions"
        [withContextMenu]="withContextMenu"
        [removeOnly]="removeOnly"
        [withUnlink]="withUnlink"
        (appClick)="clickStitchItem.emit(info.data)"
        (appDblClick)="dblClickStitchItem.emit(info.data)"
      ></app-link-info-task>

      <app-link-info-notebook
        *ngSwitchCase="'notebook'"
        class="item"
        [notebook]="info.data"
        (remove)="handleRemoveItem(info)"
        (unlink)="handleUnlinkItem(info)"
        [viewType]="state?.view"
        [withDraggable]="withDraggable"
        [withActions]="withActions"
        [withContextMenu]="withContextMenu"
        [removeOnly]="removeOnly"
        [withUnlink]="withUnlink"
        (appClick)="clickStitchItem.emit(info.data)"
        (appDblClick)="dblClickStitchItem.emit(info.data)"
      ></app-link-info-notebook>

      <app-link-info-note
        *ngSwitchCase="'note'"
        class="item"
        [note]="info.data"
        (remove)="handleRemoveItem(info)"
        (unlink)="handleUnlinkItem(info)"
        [viewType]="state?.view"
        [withDraggable]="withDraggable"
        [withActions]="withActions"
        [withContextMenu]="withContextMenu"
        [removeOnly]="removeOnly"
        [withUnlink]="withUnlink"
        (appClick)="clickStitchItem.emit(info.data)"
        (appDblClick)="dblClickStitchItem.emit(info.data)"
      ></app-link-info-note>

      <app-link-info-group
        *ngSwitchCase="'group'"
        class="item"
        [group]="info.data"
        (remove)="handleRemoveItem(info)"
        (unlink)="handleUnlinkItem(info)"
        [viewType]="state?.view"
        [withDraggable]="withDraggable"
        [withActions]="withActions"
        [withContextMenu]="withContextMenu"
        [removeOnly]="removeOnly"
        [withUnlink]="withUnlink"
        (appClick)="clickStitchItem.emit(info.data)"
        (appDblClick)="dblClickStitchItem.emit(info.data)"
      ></app-link-info-group>

      <app-link-info-contact
        *ngSwitchCase="'contact'"
        class="item"
        [contact]="info.data"
        (remove)="handleRemoveItem(info)"
        (unlink)="handleUnlinkItem(info)"
        [viewType]="state?.view"
        [withDraggable]="withDraggable"
        [withActions]="withActions"
        [withContextMenu]="withContextMenu"
        [removeOnly]="removeOnly"
        [withUnlink]="withUnlink"
        (appClick)="clickStitchItem.emit(info.data)"
        (appDblClick)="dblClickStitchItem.emit(info.data)"
      ></app-link-info-contact>

      <app-link-info-folder
        *ngSwitchCase="'folder'"
        class="item"
        [folder]="info.data"
        (remove)="handleRemoveItem(info)"
        (unlink)="handleUnlinkItem(info)"
        [viewType]="state?.view"
        [withDraggable]="withDraggable"
        [withActions]="withActions"
        [withContextMenu]="withContextMenu"
        [removeOnly]="removeOnly"
        [withUnlink]="withUnlink"
        (appClick)="clickStitchItem.emit(info.data)"
        (appDblClick)="dblClickStitchItem.emit(info.data)"
      ></app-link-info-folder>

      <app-link-info-file
        *ngSwitchCase="'file'"
        class="item"
        [file]="info.data"
        (remove)="handleRemoveItem(info)"
        (unlink)="handleUnlinkItem(info)"
        [viewType]="state?.view"
        [withDraggable]="withDraggable"
        [withActions]="withActions"
        [withContextMenu]="withContextMenu"
        [removeOnly]="removeOnly"
        [withUnlink]="withUnlink"
        (appClick)="clickStitchItem.emit(info.data)"
        (appDblClick)="dblClickStitchItem.emit(info.data)"
      ></app-link-info-file>
    </ng-container>

    <div
      *ngIf="!linkedInfoData.length"
      class="placeholder"
    >
      <img
        *ngIf="withPlaceholderImage"
        class="placeholder-image"
        src="assets/placeholders/placeholder-stitch-info.jpg"
        alt=""
      />
      <div class="placeholder-text">
        {{ placeholder
        ? placeholder
        : withDroppable
          ? 'Drop Stitch Info here'
          : 'You don\'t have any Stitch Information yet'
        }}
      </div>
    </div>

    <div
      class="unlink-all"
      *ngIf="withUnlinkAll && linkedInfoData.length"
      (click)="handleUnlinkAll()"
    >
      {{ unlinkAllLabel }}
    </div>
</div>

