<div class="participants">
  <div *ngIf="currentParticipants.length" class="heading">
    <div class="title">Add Participants</div>
    <div class="count">{{ currentParticipants.length }}</div>
    <div class="separator"></div>
    <app-button
      appearance="sapphire-text-accent"
      size="m"
      label="See All"
      (click)="openModal()"
    />
  </div>

  <ng-container
    *ngFor="let item of currentParticipants; trackBy: trackByParticipant"
    [ngTemplateOutlet]="participantTemplate"
    [ngTemplateOutletContext]="{ item: item.participant }"
  />

  <app-button
    appearance="sapphire-primary-ghost"
    size="xs"
    label="+ Add Participants"
    (click)="openModal()"
  />
</div>

<ng-template #modalTemplate>
  <div class="modal">
    <app-autocomplete-input
      class="form-details-row-input"
      appearance="sapphire-filled"
      [suggestions]="suggestions"
      [inputFormControl]="searchControl"
      placeholder="Guest Name"
      size="m"
      [itemTemplate]="participantTemplate"
    />

    <div class="selected-participants">
      <ng-container
        *ngFor="let item of innerParticipants; trackBy: trackByParticipant"
        [ngTemplateOutlet]="participantTemplate"
        [ngTemplateOutletContext]="{ item: item.participant, optional: item.optional, withControls: true }"
      />
    </div>
  </div>
</ng-template>

<ng-template
  #participantTemplate
  let-participant="item"
  let-withControls="withControls"
  let-optional="optional"
>
  <div class="participant">
    <stch-avatar [user]="participant" />
    <div class="participant-name">{{ participant.fullName }}</div>
    <div class="separator"></div>
    <div
      *ngIf="withControls"
      class="actions"
    >
      <app-button
        appearance="sapphire-secondary-ghost"
        size="xs"
        [icon]="optional ? 'sapphire-user-filled' : 'sapphire-user'"
        (click)="handleOptionalCheck(participant.id)"
      />

      <app-button
        appearance="sapphire-secondary-ghost"
        size="xs"
        icon="sapphire-close"
        (click)="handleRemove(participant.id)"
      />
    </div>
  </div>
</ng-template>
