// Types
import { Filters } from '@modules/common/types/filters';
import { Like } from '@modules/common/types/like';

export class PaymentMethodsFilters extends Filters {
  fromTime?: Date;
  toTime?: Date;
  sortBy?: 'createdAt' | 'title';

  constructor (filters?: Like<PaymentMethodsFilters>) {
    super(filters);
    this.fromTime = filters?.fromTime;
    this.sortBy = filters?.sortBy;
    this.toTime = filters?.toTime;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = super.format();

    if (this.fromTime) { result['from_time'] = this.fromTime.toISOString(); }
    if (this.toTime) { result['to_time'] = this.toTime.toISOString(); }

    result['sort_by'] = this.sortBy || 'createdAt';

    return result;
  }
}
