// Common
import { Component, Injector, Input, OnInit } from '@angular/core';

// RX
import { takeUntil } from 'rxjs/operators';

// Types
import { File } from '@modules/files/types/file';
import { Tab } from '@modules/common/types/tab';
import { VirtualFolder } from '@modules/files/types/virtual-folder';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { Folder } from '@modules/files/types/folder';
import { DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

// Services
import { FilesService } from '@modules/files/services/files.service';
import { FoldersService } from '@modules/files/services/folders.service';
import { FilesAppStateService } from '@modules/files/services/state.service';

// Components
import { FullFormBaseComponent } from '@modules/common/components/full-form-base/full-form-base.component';

@Component({
  selector: 'app-file-form',
  templateUrl: './file-form.component.html',
  styleUrls: ['./file-form.component.less'],
})
export class FileFormComponent extends FullFormBaseComponent<File> implements OnInit {

  public changesKey = 'file';
  tabs: Tab[] = [
    { title: 'File', value: 'file'},
    { title: 'Details', value: 'details'},
    { title: 'Stitch', value: 'stitch'},
    { title: 'Lab', value: 'lab'},
    { title: 'Comments', value: 'comments'},
  ];
  tabsStateKey = 'ffFile';
  public currentSidebarFilter: VirtualFolder;
  public foldersSuggestions: AutocompleteFactory<Folder>;

  protected dragDataType = DragDataTypes.file;

  @Input() file = new File();

  constructor (
    private filesService: FilesService,
    foldersService: FoldersService,
    private stateService: FilesAppStateService,
    injector: Injector,
  ) {
    super(injector, filesService, stateService);
    this.foldersSuggestions = foldersService.getAutocompleteSuggestions();
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    this.stateService.getVirtualFolder()
      .pipe(takeUntil(this.alive))
      .subscribe(folder => this.currentSidebarFilter = folder);
  }

  download() {
    this.stitchItem.download();
  }

  /**
   * Helpers
   */

  get stitchItem(): File {
    return this.file;
  }

  protected shouldRefreshList(prev, current) {
    return File.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): File {
    return File.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return this.file.asFormGroup();
  }

  print() {
    this.filesService.print(this.file)
      .pipe(takeUntil(this.alive))
      .subscribe();
  }
}
