// Common
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';

// Types
import { StitchType } from '@modules/common/types/stitch-type';
import { castInputValue, Input } from './automation-rules';

export class AutomationRule {
  id?: string;
  stitchType: StitchType;
  title: string;
  lastRunAt: Date;
  updatedAt: Date;
  active: boolean;
  value: { key: string, value: unknown }[];

  constructor(data: Partial<AutomationRule> = {}) {
    this.id = data.id;
    this.stitchType = data.stitchType;
    this.title = data.title;
    this.lastRunAt = data.lastRunAt ? new Date(data.lastRunAt) : null;
    this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : null;
    this.active = !!data.active;
    this.value = Array.isArray(data.value) ? data.value : [];
  }

  static fromFormGroup(form: UntypedFormGroup, inputs: Input[]): AutomationRule {
    const formValues = form.value;

    return new AutomationRule({
      id: formValues.id,
      title: formValues.title,
      stitchType: formValues.stitchType,
      active: formValues.active,
      value: inputs
        .filter(({ active }) => active)
        .map(({ key, control }) => ({ key, value: control?.value }))
    });
  }

  asFormGroup(): UntypedFormGroup {
    return new FormGroup(
      {
        id: new FormControl(this.id),
        active: new FormControl(this.active),
        title: new FormControl(this.title),
        stitchType: new FormControl(this.stitchType)
      }
    );
  }

  asPayloadJSON() {
    return {
      stitchType: this.stitchType,
      title: this.title,
      active: this.active,
      value: this.value
    };
  }

  getSchema(schemas: { [k in StitchType]: Input[] }): Input[] {
    const schema = this.stitchType ? schemas[this.stitchType] : [];

    this.value.forEach(({ key, value }) => {
      const input = schema.find(input => input.key === key)
      input?.control?.setValue(castInputValue(input, value));
      input.active = true;
    })

    return schema;
  }
}
