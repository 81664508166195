// Common
import {
  Component, OnDestroy, OnChanges, Input, SimpleChanges, NgZone, ViewChild, ElementRef,
  AfterViewInit, OnInit, EventEmitter, Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// RX
import { Subject, fromEvent, Subscription } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

// Types
import { Upload } from '@modules/common/types/upload';

// Services
import { UploadsService } from '@modules/common/services/uploads.service';

@Component({
  selector: 'app-files-input',
  templateUrl: './files-input.component.html',
  styleUrls: ['./files-input.component.less']
})
export class FilesInputComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  // Inputs
  @Input() inputFormControl: UntypedFormControl;
  @Input() titleFormControl: UntypedFormControl;
  @Input() outherDropSource: Subject<FileList>;
  @Input() multiple: boolean;
  @Input() withErrors = false;
  @Input() validationMessages: { [key: string]: string} = {};

  // Outputs
  @Output() uploading = new EventEmitter<boolean>();

  // Public
  public uploads: Upload[] = [];
  public uploadsInProgress = 0;
  public dragOver = 0;

  // Private
  private controlChanged = new Subject<void>();
  private dropSourceChanged = new Subject<void>();
  private alive = new Subject<void>();
  private subscriptions = new Subscription();

  // View Children
  @ViewChild('droppableArea') droppableArea: ElementRef;

  constructor(
    private ngZone: NgZone,
    private uploadsService: UploadsService,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.controlChanged
      .pipe(
        startWith(() => null),
        filter(() => !!this.inputFormControl),
        switchMap(() => this.inputFormControl?.valueChanges
          .pipe(
            startWith(this.inputFormControl?.value),
          )
        ),
        map(value => this.multiple ? value : [value].filter(v => !!v)),
        takeUntil(this.alive)
      )
      .subscribe((uploads: Upload[]) => {
        this.uploads = uploads;
      });

    this.dropSourceChanged
      .pipe(
        startWith(() => null),
        filter(() => !!this.outherDropSource),
        switchMap(() => this.outherDropSource),
        takeUntil(this.alive)
      )
      .subscribe((files: FileList) => {
        this.addFiles(files);
      });
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.droppableArea.nativeElement, 'dragover')
        .pipe(
          takeUntil(this.alive)
        )
        .subscribe((event: DragEvent) => event.preventDefault());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('inputFormControl' in changes) {
      this.controlChanged.next();
    }

    if ('outherDropSource' in changes) {
      this.dropSourceChanged.next();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleDrop(event: DragEvent) {
    this.dragOver = 0;

    if (event.dataTransfer && event.dataTransfer.files) {
      this.addFiles(event.dataTransfer.files);
    }

    event.stopPropagation();
    event.preventDefault();
  }

  handleChange(event: Event) {
    const filesInput = (event.target as HTMLInputElement);
    if (filesInput && filesInput.files) {
      this.addFiles(filesInput.files);
    }
    filesInput.value = null;
  }

  addFiles(files: FileList) {
    const uploads = Array.from(files).map(file => new Upload({ nativeFile: file, temp: true }));

    if (this.multiple) {
      this.inputFormControl.setValue([...this.inputFormControl.value, ...uploads]);
    } else {
      this.titleFormControl?.setValue(files[0]?.name);
      this.inputFormControl.setValue(uploads[0]);
    }

    if (!this.inputFormControl.valid) { return; }

    this.uploadsInProgress += uploads.length;
    this.uploading.emit(true);

    uploads.forEach(upload => {
      this.subscriptions.add(
        this.uploadsService.upload(upload).subscribe(
          ({ id }) => this.handleUploadResponse(upload, id),
          error => this.handleUploadError(upload, error)
        )
      );
    });
  }

  delete(index: number) {
    if (this.uploadsInProgress > 0) { return; }

    this.uploadsService.delete([this.inputFormControl.value[index]?.id]);

    this.inputFormControl.setValue(
      this.multiple
        ? this.inputFormControl.value.filter((_, i) => index !== i)
        : null
    );
  }

  retry(index: number) {
    if (this.uploadsInProgress > 0) { return; }

    this.uploadsInProgress += 1;
    this.uploading.emit(true);

    const upload = this.multiple ? this.inputFormControl.value[index] : this.inputFormControl.value;
    upload.error = null;

    this.subscriptions.add(
      this.uploadsService.upload(upload).subscribe(
        ({ id }) => this.handleUploadResponse(upload, id),
        error => this.handleUploadError(upload, error)
      )
    );
  }

  handleUploadResponse(upload: Upload, id: string) {
    upload.id = id;
    upload.name = id.split('-').slice(2).join('-');

    this.uploadsInProgress -= 1;

    if (this.inputFormControl?.errors?.uploading) {
      delete this.inputFormControl.errors.uploading;
    }
    this.inputFormControl.updateValueAndValidity();

    if (this.uploadsInProgress === 0) {
      this.uploading.emit(false);
    }
  }

  handleUploadError(upload: Upload, error: Error) {
    upload.error = error.message;
    this.uploadsInProgress -= 1;
    if (this.uploadsInProgress === 0) {
      this.uploading.emit(false);
    }

    this.inputFormControl.setErrors({
      uploading: this.multiple
        ? this.inputFormControl.value.some((i: Upload) => i.error)
        : this.inputFormControl.value?.error
    });
  }
}
