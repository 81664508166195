<div class="block">
  <div class="block-grow">
    <div class="heading2">
      Vacation Responder
      <app-toggle-switch
        class="heading2-toggle"
        [inputFormControl]="form.controls.vacationResponderEnabled"
      ></app-toggle-switch>
    </div>
    <div class="body-secondary">
      Sends an automated reply to incoming messages. If a contact sends you several messages, this automated reply will be sent at most once every 4 days
    </div>
  </div>
</div>

<div class="block">
  <div class="block-grow">
    <div class="heading2">
      Undo Send
    </div>
    <div class="body-secondary">
      If you decide you don't want to send an email, you have a
      <br/>short time after to cancel it. Right after you send a message,
      <br/>you can undo it.
    </div>
  </div>

  <div class="block-col">
    <app-dropdown-input
      class="block-col-button"
      [inputFormControl]="form.controls.undoSendTimeout"
      placeholder="Timeout"
      [options]="undoSendOptions"
      appearance="amethyst-simple"
    ></app-dropdown-input>
  </div>
</div>

<div class="block">
  <div class="block-grow">
    <div class="heading2">
      SpamAssassin Threshold
    </div>
    <div class="body-secondary">
      Apache SpamAssassin will move suspicious email messages (whose calculated spam score exceeds the spam threshold score) to Bulk(Junk) or Spam.
      Recommended value is 5.0 for Spam, 3.0 for Bulk.
    </div>
  </div>
  <div class="block-col">
    <app-slider
      [fromControl]="form.controls.spamAssassinThresholdJunk"
      [toControl]="form.controls.spamAssassinThresholdSpam"
      fromLabel="<b>Bulk:</b> $value"
      toLabel="<b>Spam:</b> $value"
      [floor]="0"
      [ceil]="50"
      [step]="1"
      color="#FD785D"
      leftOutColor="#61C7BD"
    ></app-slider>
  </div> 
</div>

<div class="block">
  <div class="block-grow">
    <div class="heading2">
      Cesar Bulk Analyzer Threshold
    </div>
    <div class="body-secondary">
      Cesar Bulk Analyzer will move suspicious email messages (whose calculated bulk score exceeds the bulk threshold score) to Bulk(Junk).
      Recommended value is 0.9.
    </div>
  </div>
  <div class="block-col">
    <app-slider
      [fromControl]="form.controls.cesarBulkAnalyzerThreshold"
      fromLabel="<b>Bulk:</b> $value"
      [floor]="0"
      [ceil]="1"
      [step]="0.01"
      color="#61C7BD"
    ></app-slider>
  </div> 
</div>
