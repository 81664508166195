// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Types
import { TemporalExpression } from '@modules/common/types/temporal-expression';
import { Stitch } from '../types/stitch';

// Env
import { environment } from '@environment';

@Injectable()
export class TemporalExpressionsService {

  /**
   * Constructor
   */

  constructor(
    private http: HttpClient
  ) {

  }

  /**
   * Methods
   */

  listAll(stitchItem: Stitch): Observable<TemporalExpression[]> {
    return this.http.get(
      `${environment.baseUrl}/api/temporal-expressions/${stitchItem.getStitchType()}/${stitchItem.id}`,
      {
        params: {
          timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
          timezone_offset: new Date().getTimezoneOffset() + ''
        }
      }
    )
      .pipe(
        map(response => response['expressions'].map(expression => ({
          ...expression,
          fromTime: expression.fromTime ? new Date(expression.fromTime) : null,
          toTime: expression.toTime ? new Date(expression.toTime) : null
        } as TemporalExpression[])))
      );
  }
}
