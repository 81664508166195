<div
  *ngIf="label"
  class="label"
  [class.disabled]="disabled"
  [class.error]="withErrors && !inputFormControl?.valid"
  [ngClass]="[appearance, size]"
>
  {{ label }}
</div>

<div
  class="input"
  [class.focused]="focused"
  [class.error]="withErrors && !inputFormControl?.valid"
  [class.inverted]="invertedColor"
  [class.disabled]="disabled"
  [class.no-border]="insideGroup"
  [ngClass]="[appearance, size]"
>
  <mat-icon
    *ngIf="leftIcon"
    class="input-left-icon"
    [svgIcon]="leftIcon"
    (click)="handleClickLeftIcon()"
  />

  <div
    *ngIf="label"
    class="input-label"
    [class.with-left-icon]="leftIcon"
    [class.focused]="focused || inputFormControl?.value"
  >
    {{ label }}
  </div>

  <input
    #input
    [class.with-left-icon]="leftIcon"
    [class.with-label]="label"
    [class.disabled]="disabled"
    [class.error]="withErrors && !inputFormControl?.valid"
    [ngClass]="size"
    [formControl]="inputFormControl"
    [placeholder]="placeholder"
    [readonly]="disabled || disabledInteraction"
    (focus)="handleFocus()"
    (blur)="handleBlur()"
    [type]="passwordVisible ? 'text' : inputType"
    (keyup.enter)="handleEnter()"
    autocomplete="new-password"
    [min]="min"
    [max]="max"
  />

  <div class="right-icons">
    <mat-icon
      *ngIf="number && !disabled"
      class="right-icon"
      svgIcon="sapphire-spin-count"
      (click)="handleSpin($event)"
    />

    <mat-icon
      *ngIf="withClear && !disabled && !!inputFormControl?.value"
      class="right-icon"
      svgIcon="sapphire-close"
      (click)="handleClear()"
    />

    <mat-icon
      *ngIf="rightIcon"
      class="right-icon"
      [svgIcon]="rightIcon"
    />

    <mat-icon
      *ngIf="password"
      class="right-icon"
      [svgIcon]="passwordVisible ? 'sapphire-eye' : 'sapphire-eye-closed'"
      (mousedown)="passwordVisible = !passwordVisible"
    />
  </div>

  <div
    *ngIf="sideText"
    class="side-text"
  >
    {{ sideText }}
  </div>

  <ng-container *ngTemplateOutlet="template"></ng-container>

  <div
    *ngIf="hint && !(withErrors && !inputFormControl?.valid)"
    class="hint"
  >
    {{ hint }}
  </div>

  <app-input-errors
    *ngIf="withErrors"
    class="errors"
    [appearance]="appearance | firstWord"
    type="input"
    [size]="size"
    [inputFormControl]="originalControl || inputFormControl"
    [validationMessages]="validationMessages"
  />
</div>
