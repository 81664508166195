import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map'
})
export class MapPipe implements PipeTransform {

  transform(value: any[], property: string): any[] {
    return value && value.map(item => item[property]);
  }
}
