// Common
import { Component, Injector, Input } from '@angular/core';

// Services
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';

// Types
import { Calendar } from '@modules/calendar-app/types/calendar';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { PopoverPlacement } from '@modules/popover/types/placement';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';
import { EventsService } from '@modules/calendar-app/services/events.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.less'],
})
export class CalendarComponent extends BaseStitchComponent<Calendar> {
  @Input() hoverPlacement: PopoverPlacement = 'right';

  moveDragDataTypes = [];
  dragDataType = DragDataTypes.calendar;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(
      dragData.type === DragDataTypes.calendar &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.item.id
    ) &&
    dragDataTypeAllowed(dragData.type)


  constructor (
    injector: Injector,
    protected calendarsService: CalendarsService,
    private eventsService: EventsService,
  ) { super(injector, calendarsService); }

  handleMove(dragData: DragData) {
    const message = 'Successfully moved to ' + this.item.title;

    if (dragData.type === DragDataTypes.event) {
      this.eventsService.bunchUpdate(
        { ids: this.getIds(dragData.data) },
        { calendarId: this.item.id },
        { message }
      );
    } else if (dragData.type === DragDataTypes.calendar) {
      this.calendarsService.bunchUpdate(
        { ids: this.getIds(dragData.data) },
        { parentId: this.item.id },
        { message }
      );
    }
    this.popoverClose.next();
  }

  get calendar(): Calendar {
    return this.item;
  }
}
