// Common
import { Directive, Input, ComponentRef, Output, EventEmitter, OnInit, Injector } from '@angular/core';
import { isEqual } from 'lodash';
import { LocalStorageItem, LSItem } from '../../../decorators/local-storage.decorator';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { StitchListContextMenuComponent } from '../components/context-menus/stitch-list-context-menu/stitch-list-context-menu.component';

// Types
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { ListState as StitchListState, ListState } from '@modules/linked-info/types/list-state';

// RX
import { takeUntil } from 'rxjs/operators';

const defaultState: ListState = {
  sort: {
    by: 'title',
    order: 'asc'
  },
  filters: {
    stitch: {
      message: false,
      event: false,
      project: false,
      task: false,
      notebook: false,
      note: false,
      group: false,
      contact: false,
      folder: false,
      file: false,
    }
  },
  view: 'short'
};

// Services
import { StateService } from '@modules/settings/services/state.service';

@Directive({
  selector: '[appStitchListContextMenu]'
})
export class StitchListContextMenuDirective extends ContextMenuDirective implements OnInit {

  @Input() appStitchListContextMenuStateVariant = 'default';
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() appStitchListContextMenuWithStitchType = true;

  @Output() appStitchListContextMenuChange = new EventEmitter<ListState>();

  @LSItem({ lsKey: 'ff-stitch', default: defaultState }) private stitchListState: LocalStorageItem<StitchListState>;

  constructor (
    private stateService: StateService,
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<StitchListContextMenuComponent>) {
    componentRef.instance.state = this.stitchListState.getSync(this.appStitchListContextMenuStateVariant);
    componentRef.instance.withStitchType = this.appStitchListContextMenuWithStitchType;

    componentRef.instance.change
      .pipe(takeUntil(this.alive))
      .subscribe((state: ListState) => {
        componentRef.instance.state = state;

        this.stitchListState.set(state, this.appStitchListContextMenuStateVariant);
      });
  }

  registerComponent() {
    return StitchListContextMenuComponent;
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.stitchListState.get(this.appStitchListContextMenuStateVariant)
      .pipe(takeUntil(this.alive))
      .subscribe((state) => {
        this.isDefaultStateObservable?.next?.(isEqual(defaultState, state));

        this.appStitchListContextMenuChange.emit(state);
      });

    if (this.isDefaultStateObservable) {
      this.isDefaultStateObservable
        .pipe(takeUntil(this.alive))
        .subscribe(isDefaultState => {
          if (isDefaultState && !isEqual(defaultState, this.stitchListState.getSync(this.appStitchListContextMenuStateVariant))) {
            this.stitchListState.set(defaultState, this.appStitchListContextMenuStateVariant);
          }
        });
    }
  }
}
