<editor
  *ngIf="settingsPrepared"
  class="editor"
  [formControl]="control"
  [init]="settings"
  (onInit)="editorInitialized($event)"
></editor>

<app-input-errors
  *ngIf="withErrors"
  [inputFormControl]="control"
  [validationMessages]="validationMessages"
></app-input-errors>
