// Common
import { ComponentRef, Directive, EventEmitter, Input, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Types
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { PopoverPlacement } from '@modules/popover/types/placement';

// RX
import { takeUntil } from 'rxjs/operators';

// Components
import { YearsContextMenuComponent } from '../components/years-context-menu/years-context-menu.component';

@Directive({
  selector: '[appYearsContextMenu]'
})
export class YearsContextMenuDirective extends ContextMenuDirective {

  // Inputs
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottom';
  @Input() appYearsContextMenu: Date;
  @Input() appYearsContextMenuMax: number;

  // Outputs
  @Output() appYearsContextMenuOpened = this.contextMenuOpened;
  @Output() appYearsContextMenuSelect = new EventEmitter<Date>();

  /**
   * Actions
   */

  registerComponent() {
    return YearsContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<YearsContextMenuComponent>) {
    componentRef.instance.date = this.appYearsContextMenu;
    componentRef.instance.max = this.appYearsContextMenuMax;
    componentRef.instance.select
      .pipe(takeUntil(this.alive))
      .subscribe((date: Date) => this.appYearsContextMenuSelect.emit(date));
  }
}
