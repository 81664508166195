// Common
import { ComponentRef, Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { TagContextMenuComponent } from '../components/tag-context-menu/tag-context-menu.component';

// Types
import { Tag } from '@modules/tags/types/tag';

@Directive({
  selector: '[appTagContextMenu]'
})
export class TagContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() appTagContextMenu: Tag;
  @Input() appTagContextMenuWithDetach = false;
  @Input() appTagContextMenuDisabled = false;

  // Outputs
  @Output() appTagContextMenuOpened = this.contextMenuOpened;
  @Output() appTagContextMenuDelete = new EventEmitter<void>();
  @Output() appTagContextMenuPinned = new EventEmitter<void>();

  /**
   * Livecycle
   */

  ngOnInit() {
    this.stchPopoverDisabled = this.appTagContextMenuDisabled;
  }

  /**
   * Actions
   */

  registerComponent() {
    return TagContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<TagContextMenuComponent>) {
    componentRef.instance.tag = this.appTagContextMenu;
    componentRef.instance.delete = this.appTagContextMenuDelete;
    componentRef.instance.pinned = this.appTagContextMenuPinned;
    componentRef.instance.withDetach = this.appTagContextMenuWithDetach;
  }
}
