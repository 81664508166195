// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';

// RX
import { Observable, of, zip } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Decorators
import { warmUpObservable } from '@decorators';

// Types
import { LinkedInfo } from './../types/linked-info';
import { StitchType } from '@modules/common/types/stitch-type';
import { Stitch } from '@modules/common/types/stitch';
import { LinkedInfoData } from '../types/linked-info-data';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseService } from '@modules/common/services/base.service';
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';

@Injectable()
export class LinkedInfoService extends BaseService {

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
    private stitchServiceFactory: StitchServiceFactory,
  ) {
    super();
  }

  getLinkedInfo(type: StitchType, id: string): Observable<LinkedInfo[]> {
    return this.http.get<{ items: LinkedInfo[] }>(`${environment.baseUrl}/api/stitched-items/${type}/${id}`)
      .pipe(
        map(({ items }) => items),
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  linkItems(items: LinkedInfo[]): Observable<boolean> {
    return this.http.post<{success: boolean}>(
      environment.baseUrl + '/api/stitched-items',
      { items: items.map(({ id, type }) => ({ id, type })) }
    )
      .pipe(
        tap(({ success }) => {
          this.forceRefresh();
          if (success) {
            this.toasterService.show({ text: `Item(s) linked.` });
          }
        }),
        map(({ success }) => success),
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  unlinkItems(items: LinkedInfo[]): Observable<boolean> {
    const params = { body: { items: items.map(({ id, type }) => ({ id, type })) }};

    return this.http.request<{success: boolean}>('DELETE', environment.baseUrl + '/api/stitched-items', params)
      .pipe(
        tap(() => this.forceRefresh()),
        map(({ success }) => success),
        catchError(error => this.handleObserverError(error))
      );
  }

  fill(items: LinkedInfo[]): Observable<LinkedInfoData[]> {
    return this.loadItems(this.getIds(items))
  }

  private getIds(linkedInfo: LinkedInfo[]): { [key in StitchType]?: string[] } {
    return (linkedInfo || []).reduce(
      (memo, item) => ({
        ...memo,
        [item.type]: [...memo[item.type], item.id]
      }),
      Object.values(StitchType).reduce((memo, type) => ({ ...memo, [type]: [] }), {} as { [key in StitchType]: string[] })
    );
  }

  private loadItems(ids: { [key in StitchType]?: string[] }): Observable<LinkedInfoData[]> {
    return zip(
      ...(Object.values(StitchType).map(type => (
        ids[type].length
          ? this.stitchServiceFactory
            .getServiceByStitchType(type)
            .search({ ids: ids[type], limit: ids[type].length })
            .pipe(map(({ items }) => items))
          : of([] as Stitch[])
      )))
    )
      .pipe(
        map(results => {
          return results.flat().reduce((memo, item) => [...memo, { id: item.id, type: item.getStitchType(), data: item }], [] as LinkedInfoData[])
        })
      )
  }
}
