<stch-calendar-month-small
  [viewDate]="viewDate"
  [dayTemplate]="dayTemplate"
  [events]="events"
/>

<ng-template
  #dayTemplate
  let-day="day"
>
  <stch-calendar-month-small-cell
    [day]="day"
    [disabled]="(min && day.date < min) || (max && day.date > max)"
    [inRange]="range?.[0] && range?.[1] && day.date >= range[0] && day.date <= range[1]"
    [selectedDate]="selectedDate"
    (click)="handleClick(day.date)"
  />
</ng-template>
