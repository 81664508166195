// Common
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-days-context-menu',
  templateUrl: './days-context-menu.component.html',
  styleUrls: ['./days-context-menu.component.less']
})
export class DaysContextMenuComponent implements OnInit, OnChanges {

  // Public
  public days: number[];

  // Inputs
  @Input() date: Date = new Date();

  // Outputs
  @Output() select = new EventEmitter<Date>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.generateDaysList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('date' in changes) {
      this.generateDaysList();
    }
  }

  /**
   * Actions
   */

  handleDayClick(day: number): void {
    this.select.emit(new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      day
    ));
  }

  generateDaysList() {
    if (!this.date) { return; }

    const daysCount = (new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)).getDate();

    this.days = [...Array(daysCount).keys()].map(index => index + 1);
  }
}
