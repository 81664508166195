// Common
import { HttpClient } from '@angular/common/http';

// RX
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class BaseSettingsService<Settings extends { asPayloadJSON(): any }> {
  private settings = new BehaviorSubject<Settings>(this.createInstance());
  private loading = false;
  protected abstract url;

  constructor (
    private http: HttpClient,
  ) {
    this.reloadSettings();
  }

  abstract createInstance(data?: any): Settings

  reloadSettings() {
    this.loading = true;

    this.http.get<{ settings: Settings }>(this.url) // we assume httpService resolves only once. take(1) by default
      .pipe(
        map(({ settings }) => this.createInstance(settings))
      )
      .subscribe(
        settings => {
          this.loading = false;
          this.settings.next(settings);
        },
        () => {
          this.loading = false;
          this.settings.next(this.createInstance());
        }
      );
  }

  listAll(force: boolean = false): Observable<Settings> {
    if (force && !this.loading) {
      this.reloadSettings();
    }

    return this.settings.asObservable();
  }

  update(settings: Settings): Observable<boolean> {
    return this.http.put<{ success: boolean }>(this.url, settings.asPayloadJSON())
      .pipe(map(({ success }) => success));
  }
}
