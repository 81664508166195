<div class="row">
  <div
    *ngIf="section"
    class="row-title"
  >
    <input
      class="row-title-input"
      (blur)="handleBlur()"
      placeholder="Enter row title"
      [formControl]="sectionForm.controls['title']"
    >

    <div class="row-title-hover-icons">
      <mat-icon
        svgIcon="up"
        class="row-title-hover-icons-icon"
        matTooltip="Move Up"
        (click)="moveUp()"
      ></mat-icon>

      <mat-icon
        svgIcon="down"
        class="row-title-hover-icons-icon"
        matTooltip="Move Down"
        (click)="moveDown()"
      ></mat-icon>

      <mat-icon
        svgIcon="trash"
        class="row-title-hover-icons-icon"
        (click)="delete()"
        [matTooltip]="'Delete Permanently'"
      ></mat-icon>
    </div>

    <div class="row-title-icons">
      <mat-icon
        svgIcon="dropdown-down"
        class="row-title-icon"
        [ngClass]="{collapsed: collapsed}"
        (click)="toggleCollapse()"
      ></mat-icon>
    </div>
  </div>

  <div
    class="row-collapseable-body"
    [ngClass]="{ collapsed: collapsed }"
    [@heightAnimation]="collapsed ? 'hide' : 'show'"
  >
    <ng-container
      *dndNestedFor="
        let item of items;
        let index = index;
        let position = position;
        let depth = depth;
        let expandable = expandable;
        let expanded = expanded;
        stateKey: container?.id + section?.id;
        positionKey: 'position';
        maxDepth: maxDepth;
        droppableZIndex: 1;
        changes: itemsChanges;
        predicate: dndPredicate;
      "
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{
        item: item,
        depth: depth,
        index: index,
        position: position,
        expandable: expandable,
        expanded: expanded,
        hovered: hovered
      }"
    ></ng-container>
  </div>
</div>
