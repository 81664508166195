import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class Settings {
  formBuilder = injector.get(FormBuilder);

  isPushEnabled?: boolean;

  constructor(data: any = {}) {
    this.isPushEnabled = data.isPushEnabled ?? true;
  }

  static fromFormGroup(form: FormGroup): Settings {
    const { value: formValues } = form;

    return new Settings({
      isPushEnabled: formValues.isPushEnabled,
    });
  }

  public asFormGroup(): FormGroup {
    return this.formBuilder.group({
      isPushEnabled: [this.isPushEnabled],
    });
  }

  public asPayloadJSON() {
    return {
      isPushEnabled: this.isPushEnabled,
    };
  }
}
