// Types
import { Space } from '@modules/settings/types/space';
import { Like } from './like';
import { Stitch } from './stitch';

export abstract class ChildStitch extends Stitch {
  sectionId?: string;
  position?: number;
  containerId?: string;
  temp?: boolean;

  constructor(data: Like<ChildStitch>, space?: Space) {
    super(data, space);
    this.sectionId = data.sectionId;
    this.temp = data.temp;
    this.position = data.position;
    this.containerId = data.containerId;
  }
}
