<div class="panel">
  <div class="panel-heading">
    <div class="panel-heading-top">
      <app-dropdown-label
        appDPTasksContextMenu
        (appDPTasksContextMenuOpened)="contextMenuOpened = $event"
        (appDPTasksContextMenuFilterChanged)="selectFilter($event)"
        [collapsed]="!contextMenuOpened"
      >
        {{ selectedFilter | titleByTasksFilter }}
      </app-dropdown-label>
      <div class="panel-heading-top-filters">
        <app-context-menu-state-indicator
          *ngIf="selectedFilter !== 'all_projects'"
          svgIcon="filters"
          appTasksListContextMenu
          [appTasksListContextMenuStateKey]="tasksListStateKey"
          (appTasksListContextMenuChange)="handleTasksListStateChange($event)"
        ></app-context-menu-state-indicator>

        <app-context-menu-state-indicator
          *ngIf="selectedFilter === 'all_projects'"
          svgIcon="filters"
          appProjectsListContextMenu
          [appProjectsListContextMenuStateKey]="projectsListStateKey"
          (appProjectsListContextMenuChange)="handleProjectsListStateChange($event)"
        ></app-context-menu-state-indicator>

        <ng-container [ngTemplateOutlet]="searchTemplate"></ng-container>
      </div>
    </div>
  </div>

  <app-quick-form
    *ngIf="
      selectedFilter !== 'all_projects' &&
      selectedFilter !== 'archived' &&
      selectedFilter !== 'deleted'
    "
    class="panel-quick-form"
    placeholder="Add New Task"
  >
    <app-task-quick-form
      (more)="handleNewTask($event)"
    ></app-task-quick-form>
  </app-quick-form>

  <app-quick-form
    *ngIf="selectedFilter === 'all_projects'"
    class="panel-quick-form"
    placeholder="Add New Project"
  >
    <app-project-quick-form
      (more)="handleNewProject($event)"
    ></app-project-quick-form>
  </app-quick-form>

  <div class="panel-list">
    <app-tasks-list
      *ngIf="selectedFilter !== 'all_projects'"
      [filters]="tasksFilters"
      (openTask)="openTask($event)"
      (openSubtask)="openTaskInModal($event)"
      (doubleClickTask)="openTask($event)"
    ></app-tasks-list>

    <app-projects-list
      *ngIf="selectedFilter === 'all_projects'"
      [filters]="projectsFilters"
      (openProject)="openProject($event)"
      (openTask)="openTaskInModal($event)"
      (doubleClickProject)="openProject($event)"
    ></app-projects-list>
  </div>
</div>
