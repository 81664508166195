<div
  class="input"
  [class.no-border]="noBorder"
  [class.focused]="focused"
  [class.inverted]="invertedColor"
  [class.disabled]="disabled"
  [ngClass]="appearance"
  stchPopover
  stchPopoverPlacement="bottomLeft"
  [stchPopoverContent]="datePickerPopover"
  [stchPopoverDisabled]="disabled"
  [stchPopoverShowUntil]="popoverClose"
  stchPopoverTrigger="click"
  (stchPopoverVisibleChange)="focused = $event"
>
  <div
    *ngIf="placeholder"
    class="input-label"
    [class.focused]="focused || inputFormControl?.value"
  >
    {{ placeholder }}
  </div>

  <div
    *ngIf="appearance === 'default'"
    class="input-value"
    [class.placeholder]="!inputFormControl.value"
  >
    {{ inputFormControl.value ? (inputFormControl.value | date : 'mediumDate') : placeholder }}
  </div>

  <div
    *ngIf="appearance === 'amethyst'"
    class="input-value"
    [class.with-label]="placeholder"
  >
    {{ inputFormControl.value | date : 'y/MM/dd' }}
  </div>

  <mat-icon
    *ngIf="withClear && !disabled && inputFormControl.value"
    class="input-clear-icon"
    svgIcon="close-thin"
    (click)="handleClear()"
  ></mat-icon>
</div>

<ng-template #datePickerPopover>
  <div class="popover">
    <mat-icon
      class="popover-close-icon"
      svgIcon="close-thin"
      (click)="handleCancel()"
    ></mat-icon>

    <app-date-picker-2
      [inputFormControl]="inputFormControl"
      [collapseable]="false"
      [maxDate]="maxDate"
      [minDate]="minDate"
    ></app-date-picker-2>

    <div class="popover-buttons">
      <app-button appearance="square" size="medium" (click)="handleCancel()" label="Cancel" />
      <app-button appearance="square-blue" size="medium" (click)="handleCancel()" label="Save" />
    </div>
  </div>
</ng-template>
