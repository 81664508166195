import { environment } from '@environment';
import { CountryType } from '../../../../assets/countries/countries';

export class Account {
  admin: boolean
  avatar: string;
  avatarFileName: string;
  country: CountryType['iso2'];
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  name: string;
  provider: string;
  recoveryEmail: string;
  status: string;
  tempAvatarId: string;
  chargeType: 'monthly' | 'annually';
  subscriptionPlanId: string;
  bonusBalance: number;
  subscriptionStatus: 'trial' | 'active' | 'error' | 'suspended';
  nextSubscriptionPlanId: string;
  nextChargeDate: Date;
  nextChargeType: 'monthly' | 'annually';
  fullName: string;

  constructor(account) {
    this.avatar = account?.avatar && `${environment.baseUrl}/api/attachments/avatar/avatar/${account.avatar}?t=${new Date().getTime()}`;
    this.avatarFileName = account?.avatar;
    this.email = account.email;
    this.firstName = account.first_name || account.firstName;
    this.id = account.id;
    this.lastName = account.last_name || account.lastName;
    this.name = account.name;
    this.provider = account.provider;
    this.recoveryEmail = account.recovery_email || account.recoveryEmail;
    this.status = account.status;
    this.admin = account.admin;
    this.chargeType = account.chargeType;
    this.subscriptionPlanId = account.subscriptionPlanId;
    this.bonusBalance = account.bonusBalance;
    this.subscriptionStatus = account.subscriptionStatus;
    this.nextSubscriptionPlanId = account.nextSubscriptionPlanId;
    this.nextChargeDate = account.nextChargeDate ? new Date(account.nextChargeDate) : null;
    this.nextChargeType = account.nextChargeType;

    this.fullName = [this.firstName, this.lastName].join(' ');
  }
}
