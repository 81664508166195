// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// Utils
import { relativeDateToDate } from '@modules/common/utils/date';

// Types
import { Task } from '@modules/tasks/types/task';
import { Project } from '@modules/tasks/types/project';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { RelativeDate } from '@modules/common/types/relative-date';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Services
import { TasksService } from '@modules/tasks/services/tasks.service';
import { ProjectsService } from '@modules/tasks/services/projects.service';

// Components
import { BaseQuickFormComponent } from '../base-quick-form/base-quick-form.component';

@Component({
  selector: 'app-task-quick-form',
  templateUrl: './task-quick-form.component.html',
  styleUrls: ['./task-quick-form.component.less'],
})
export class TaskQuickFormComponent extends BaseQuickFormComponent implements OnChanges {

  // Inputs
  @Input() task: Task = new Task();
  @Input() project: Project;
  @Input() forceRefresh = true;

  // Outputs
  @Output() more: EventEmitter<Task> = new EventEmitter<Task>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<Task> = new EventEmitter();

  // Public
  public dateButtonSelected: 'from' | 'to' = 'from';
  public projectsSuggestions: AutocompleteFactory<Project>;
  public popoverClose = new Subject<void>();

  constructor (
    private tasksService: TasksService,
    private projectsService: ProjectsService,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(changeDetector);
    this.reset();
    this.projectsSuggestions = this.projectsService.getAutocompleteSuggestions();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('task' in changes) {
      this.reset();
    }

    if ('project' in changes) {
      this.form.patchValue({ projectId: this.project?.id });
    }
  }

  /**
   * Actions
   */

  setDate(range: RelativeDate): void {
    const date = relativeDateToDate(range);

    if (this.dateButtonSelected === 'from') {
      this.form.controls.fromDate.setValue(date);
    } else {
      this.form.controls.toDate.setValue(date);
    }
  }

  submit() {
    if (!this.form.valid) {
      return;
    }

    const task = Task.fromFormGroup(this.form);

    if (this.save.observers.length > 0) {
      this.save.emit(task);
      return;
    }

    this.saveInProgress = true;
    this.tasksService.create(task, { emit: this.forceRefresh })
      .pipe(takeUntil(this.alive))
      .subscribe(
        taskInstance => {
          this.afterSave.emit(taskInstance);
          this.close.emit();
        },
        () => this.handleError()
      );
  }

  reset() {
    if (!this.task) { return; }
    this.form = this.task.asFormGroup();
    this.dateButtonSelected = 'from';
    super.reset();
  }

  clickMore() {
    this.more.emit(Task.fromFormGroup(this.form));
    super.clickMore();
  }

  handleSelectProject(event: DropdownSelectItem<Project>) {
    this.project = event.source;
  }

  handleCancelProjects() {
    this.popoverClose.next();
  }

  handleSaveProjects() {
    this.popoverClose.next();
  }
}
