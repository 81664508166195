<app-split-view
  side="left"
  key="filesSidebar"
  [collapsedSize]="76"
  [shadow]="true"
  [magneticSize]="120"
  [withIcon]="true"
>
  <app-sidebar split-left-pane splitViewKey="filesSidebar">
    <app-files-side-bar *stchSpaceRerender></app-files-side-bar>
  </app-sidebar>

  <app-split-view
    split-right-pane
    side="left"
    key="filesList"
    [collapsedSize]="25"
    [shadow]="true"
    [magneticSize]="300"
    [withIcon]="true"
  >
    <app-files-list-container
      split-left-pane
      *stchSpaceRerender
      class="list"
    ></app-files-list-container>

    <div class="full-form" split-right-pane *stchSpaceRerender>
      <app-files-main-view></app-files-main-view>
    </div>
  </app-split-view>
</app-split-view>
