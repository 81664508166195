<cdk-virtual-scroll-viewport
  [itemSize]="itemHeight"
  minBufferPx="880"
  maxBufferPx="1320"
  class="files-list-wrapper"
  (mouseover)="isHover = true"
  (mouseleave)="isHover = false"
  appSelectableList
  [appSelectableListReset]="resetSelected"
>
  <ng-container *cdkVirtualFor="let file of itemsStreamObservable; let index = index">
    <div
      class="days-separator"
      *ngIf="!file?.pinned && isHover && file?.createdAt | differentDay: items[index - 1]?.createdAt"
    >
      <div class="days-separator-date">
        {{ file.createdAt | date: 'MMM d, yyyy' }}
      </div>
    </div>

    <app-file
      [item]="file"
      [withTags]="withTags"
      [withKnots]="withKnots"
      [position]="index"
      [debug]="debug"
      (open)="openFile.emit(file)"
      (appClick)="handleClickItem(file)"
      (appDblClick)="handleDoubleClickItem(file)"

      [appSelectable]="file"
      [appSelectablePosition]="index"
    ></app-file>
  </ng-container>

  <!-- Placeholders -->
  <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  <div *ngIf="loadingError" class="placeholder">Error occured while loading. <span (click)="items?.length ? refreshCurrentItems() : resetItems()" class="error">Click here to try again.</span></div>
</cdk-virtual-scroll-viewport>

<!-- Count -->
<div class="files-list__selected" *ngIf="items?.length > 0 && ((showCountView | async) === true)">
  <div class="total">Items: {{items.length}}</div>
</div>

<div class="files-stub" *ngIf="items?.length === 0 && !loading && !loadingError">
  <img class="files-stub-image" src="assets/placeholders/placeholder-files.svg"/>
  <p class="files-stub-text">{{ placeholderText }}</p>
</div>
