<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <input
    class="form-title"
    placeholder="Untitled File"
    [formControl]="form.controls.title"
    #titleInput
  />

  <app-color-input
    class="form-marker"
    icon="file"
    [control]="form.controls.color"
    [size]="16"
  ></app-color-input>

  <app-files-input
    class="form-file-input"
    [titleFormControl]="form.controls.title"
    [inputFormControl]="form.controls.attachment"
    [withErrors]="submitted"
    [validationMessages]="{
      required: 'Please select a file to upload',
      size: 'File is too large. Maximum size 25MB.',
      uploading: 'Can\'t upload file'
    }"

  ></app-files-input>

  <div class="form-buttons">
    <div
      *ngIf="!inline"
      class="form-buttons-item"
      [class.waiting]="saveInProgress"
      (click)="!saveInProgress && clickMore()"
    >
      More
    </div>

    <div class="form-buttons-filler"></div>

    <div
      *ngIf="!inline"
      class="form-buttons-item form-buttons-item-cancel"
      [class.waiting]="saveInProgress"
      (click)="!saveInProgress && cancel()"
    >
      Cancel
    </div>

    <div
      class="form-buttons-item"
      [class.waiting]="saveInProgress"
      (click)="!saveInProgress && submit()"
    >
      Add
    </div>
  </div>
</div>
