// Common
import { Component, Input } from '@angular/core';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { ChartState } from '@modules/knowledge/types/chart-state';

const PER_PAGE = 25;
@Component({
  selector: 'app-knowledge-chart',
  templateUrl: './knowledge-chart.component.html',
  styleUrls: ['./knowledge-chart.component.less'],
})
export class KnowledgeChartComponent {
  @Input() item: Stitch;
  @Input() sizeChanges: any;
  @Input() state: ChartState;

  public view: '2d' | '3d' = '2d';
}
