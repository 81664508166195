
<div class="block">
  <div class="block-grow">
    <div class="heading2">
      {{ account?.name }}
    </div>
    <div class="body-secondary">
      {{ account?.email }}
    </div>
  </div>
</div>

<div class="block">
  <div
    *ngIf="account?.avatar"
    class="block-col"
  >
    <img class="avatar" [src]="account?.avatar">
  </div>

  <div class="block-grow">
    <div class="body-secondary">
      A profile picture helps personalize your account.
      <br/>
      Image formats with max size of 3 mb
    </div>
  </div>

  <app-button
    class="block-col"
    label="EDIT"
    appearance="link-blue"
    (click)="editAvatar()"
  />

  <app-button
    *ngIf="account?.avatar"
    class="block-col"
    label="REMOVE"
    appearance="link-blue"
    (click)="removeAvatarPrompt()"
  />
</div>

<div class="block">
  <div class="block-grow">
    <div class="heading2">
      Full Name
    </div>
    <div class="body-secondary">
      Anyone can see this info when they communicate with you
    </div>

    <app-input
      class="input"
      appearance="amethyst"
      [inputFormControl]="generalForm.controls.firstName"
      label="First Name"
      [validationMessages]="{
        required: 'Please enter a name'
      }"
    ></app-input>

    <app-input
      class="input"
      appearance="amethyst"
      [inputFormControl]="generalForm.controls.lastName"
      label="Last Name"
      [validationMessages]="{
        required: 'Please enter a name'
      }"
    ></app-input>
  </div>
</div>

<div class="block">
  <div class="block-grow">
    <div class="heading2">
      Recovery Email
    </div>
    <div class="body-secondary">
      The address where Stitch can contact you if there’s unusual activity in your account or if you get locked out.
    </div>

    <app-input
      class="input"
      appearance="amethyst"
      [inputFormControl]="generalForm.controls.recoveryEmail"
      label="Recovery Email"
      [validationMessages]="{
        pattern: 'Invalid email address'
      }"
    ></app-input>
  </div>
</div>

<div class="block">
  <div class="block-grow">
    <div class="heading2">
      Password
    </div>
    <div class="body-secondary">
      A secure password helps protect your Stitch Account. Last changed Oct 29, 2019
    </div>
  </div>

  <app-button
    class="block-col"
    appearance="link-blue"
    label="CHANGE PASSWORD"
    (click)="changePassword()"
  />
</div>

<div class="block">
  <div class="block-grow">
    <div class="heading2">
      Export Data
    </div>
    <div class="body-secondary">
      Stitch App stores all your email, calendar items, files notes, and contacts. You can request a copy of your mailbox at any time by clicking Export Data
    </div>
  </div>

  <app-button
    class="block-col"
    appearance="link-blue"
    label="EXPORT DATA"
  />
</div>

<div class="block">
  <div class="block-grow">
    <div class="heading2">
      Delete Account
    </div>
    <div class="body-secondary">
      If you delete your account, your data will be lost beyond recovery.
    </div>
  </div>

  <app-button
    class="block-col"
    appearance="link-blue"
    label="DELETE ACCOUNT"
    (click)="cancelAccountPrompt()"
  />
</div>

<div class="block">
  <app-button
    class="block-col"
    appearance="link-blue"
    label="STORYBOOK"
    (click)="openStorybook()"
  />
</div>

<div class="block">
  <app-button
    class="block-col"
    label="SIGN OUT"
    appearance="link-blue"
    (click)="signOut()"
  />
</div>
