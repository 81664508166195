// Common
import {
  Component, Input, Output, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef, OnDestroy, Optional, Inject
} from '@angular/core';
import { CLOSE_POPOVER } from '@modules/popover/types/close-popover.injection-token';

// RX
import { Subject } from 'rxjs';

@Component({
  selector: 'app-context-menu-item',
  templateUrl: './context-menu-item.component.html',
  styleUrls: ['./context-menu-item.component.less']
})
export class ContextMenuItemComponent implements AfterViewInit, OnDestroy {

  // Inputs
  @Input() active: boolean;
  @Input() title: string;
  @Input() sortable: boolean;
  @Input() reversed: boolean;
  @Input() arrow: boolean;
  @Input() icon: string;
  @Input() activeIcon: string;
  @Input() close: boolean;
  @Input() withCheckOption = false;
  @Input() secondary: boolean;
  @Input() badge: boolean;

  // Outputs
  @Output() execute = new EventEmitter();

  // View Children
  @ViewChild('popoverContainer') popoverContainer;

  // Public
  public popoverOpened = false;
  public hasNextLevel = false;

  // Private
  private alive: Subject<void> = new Subject();

  /**
   * Constructor
   */

  constructor (
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(CLOSE_POPOVER) private closePopoverToken,
  ) {

  }

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.hasNextLevel = this.popoverContainer.nativeElement &&
      this.popoverContainer.nativeElement.children.length > 0;

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClick() {
    this.execute.emit();

    if (this.close) {
      this.closePopoverToken?.next();
    }
  }
}
