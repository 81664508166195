import { Application } from "@modules/common/types/application";

export const AppRoutesMap = [
  { app: Application.mail, path: '/mail' },
  { app: Application.calendar, path: '/events' },
  { app: Application.tasking, path: '/tasks' },
  { app: Application.notes, path: '/notes' },
  { app: Application.contacts, path: '/contacts' },
  { app: Application.files, path: '/files' },
  { app: Application.settings, path: '/settings' },
  { app: Application.admin, path: '/admin' },
]
