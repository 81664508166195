// Common
import { Component, ElementRef, Injector, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { TextSelectEvent } from '@modules/popover/types/text-select-event';
import { Action } from '@modules/popover/types/action';
import { PopoverService } from '@modules/popover/services/popover.service';

@Component({
  selector: 'selection-tooltip',
  templateUrl: './selection-tooltip.component.html',
  styleUrls: ['./selection-tooltip.component.less']
})
export class SelectionTooltipComponent implements OnInit {

  public position: { top: number, left: number, height: number };

  private text: string;
  private alive: Subject<void> = new Subject();
  private popoverClose = new Subject<void>();

  @Input() selectEvent: Subject<TextSelectEvent>;
  @Input() actions: Action[];

  @ViewChild('popoverTemplate', { static: true }) public popoverTemplate: TemplateRef<any>;
  @ViewChild('anchor') public anchor: ElementRef;

  constructor(
    private popoverService: PopoverService,
    private injector: Injector,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.selectEvent
      .pipe(takeUntil(this.alive))
      .subscribe(({ text, host: bounding }) => {
        this.text = text;

        if (!text) {
          this.position = null;
          this.popoverClose.next();
          return;
        }

        this.position = {
          top: bounding.top,
          left: bounding.left + bounding.width/2,
          height: bounding.height
        };

        this.popoverService.create(
          this.anchor,
          {
            template: this.popoverTemplate,
            placement: 'top',
            innerShadow: false,
            arrow: true,
            showUntil: this.popoverClose,
            injector: this.injector,
            appearance: 'black',
          }
        );
      });
  }

  /**
   * Actions
   */

  handleAction(action: Action) {
    action.handler(this.text);

    document.getSelection().removeAllRanges();
  }
}
