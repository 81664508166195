// Common
import { Component, Input, OnDestroy, Optional, Self, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, ControlValueAccessor, UntypedFormBuilder, NgControl, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as Chrono from 'chrono-node';

// RX
import { Subject, timer } from 'rxjs';
import { takeUntil, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

// Types
import { BundledEvent } from '@modules/bundled-inputs/types/bundled-event';

// Pipes
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-time-input',
  providers: [{provide: MatFormFieldControl, useExisting: TimeInputComponent}],
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.less'],
})
export class TimeInputComponent implements ControlValueAccessor, MatFormFieldControl<Date>, OnDestroy {

  // Static
  static nextId = 0;

  // ViewChildren
  @ViewChild('hoursInput', { static: true }) hoursInput: ElementRef;
  @ViewChild('minutesInput', { static: true }) minutesInput: ElementRef;
  @ViewChild('periodInput', { static: true }) periodInput: ElementRef;

  // Inputs
  @Input() bundledInputConsumerKeys: string[];
  @Input() bundledInputAppearance: 'start' | 'end';
  @Input() bundledInputConsumerGroup: string;
  @Input() bundledInputFormControl: UntypedFormControl;
  @Input() bundledInputInvisible = false;

  // Outputs
  @Output() focus = new EventEmitter<void>();
  @Output() blur = new EventEmitter<void>();

  // Public
  public parts: UntypedFormGroup;
  public stateChanges = new Subject<void>();
  public focused = false;
  public errorState = false;
  public controlType = 'time-input';
  public id = `time-input-${TimeInputComponent.nextId++}`;
  public describedBy = '';
  public placeholderValue: string;

  // Private
  private requiredValue = false;
  private disabledValue = false;
  private alive: Subject<void> = new Subject();
  private focusSubject: Subject<boolean> = new Subject(); // subject with delay to prevent floating label blinking
  private onChange = (_: any) => {};
  private onTouched = () => {};

  get empty() {
    const {value: {minutes, hours, period}} = this.parts;
    return !minutes || !hours || !period;
  }

  get shouldLabelFloat() { return this.focused || !this.empty; }

  @Input()
  get placeholder(): string { return this.placeholderValue; }
  set placeholder(value: string) {
    this.placeholderValue = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean { return this.requiredValue; }
  set required(value: boolean) {
    this.requiredValue = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this.disabledValue; }
  set disabled(value: boolean) {
    this.disabledValue = coerceBooleanProperty(value);
    this.disabledValue ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): Date | null {
    const {value: {minutes, period}} = this.parts;
    let hours = this.parts.value.hours;

    if (minutes.length === 2 && hours.length >= 1 && period.length === 2) {
      if (period === 'pm') {
        hours = +hours + 12;
      }
      const time = new Date();
      time.setHours(+hours);
      time.setMinutes(+minutes);
      time.setSeconds(0, 0);
      return time;
    }
    return null;
  }
  set value(date: Date | null) {
    if (date) {
      let hours: number | string = date.getHours();
      let minutes: number | string = date.getMinutes();
      const period = hours > 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'

      minutes = `${ minutes < 10 ? '0' : ''}${minutes}`;

      this.parts.setValue({minutes, hours: hours.toString(), period});
    } else {
      this.parts.setValue({minutes: '', hours: '', period: ''});
    }
    this.stateChanges.next();
  }

  /**
   * Constructor
   */

  constructor(
    formBuilder: UntypedFormBuilder,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    private datePipe: DatePipe,
  ) {
    this.parts = formBuilder.group({
      minutes: '',
      hours: '01',
      period: 'am',
    });

    focusMonitor.monitor(elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }

      this.focusSubject.next(!!origin);
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.parts.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(({minutes, hours, period}) => this.onChange(this.value));

    this.bundledTransformFunction = this.bundledTransformFunction.bind(this);

    this.focusSubject
      .pipe(
        distinctUntilChanged(),
        switchMap((value: boolean) => (
          timer(value ? 0 : 300)
            .pipe(map(() => value))
        )),
        takeUntil(this.alive),
      )
      .subscribe((value: boolean) => {
        if (value) {
          this.focus.emit();
        } else {
          this.blur.emit();
        }
        this.focused = value;
      });
  }

  /**
   * Component Lifecycle
   */

  ngOnDestroy() {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Event Handlers
   */

  handleHoursFocus(event: KeyboardEvent) {
    this.hoursInput.nativeElement.select();
  }

  handleHoursKeyPress(event: KeyboardEvent) {
    const nextValue = this.hoursInput.nativeElement.value.slice(0, this.hoursInput.nativeElement.selectionStart) + event.key +
    this.hoursInput.nativeElement.value.slice(this.hoursInput.nativeElement.selectionEnd, this.hoursInput.nativeElement.value.length);

    const regexp = this.parts.value.period === 'pm' ? /^(0[0-9]|1[0-1]|[0-9])$/ : /^(0[0-9]|1[0-2]|[0-9])$/;
    if (regexp.test(nextValue)) {
      const nextValueNum = parseInt(nextValue, 10);
      if (nextValueNum > 1 || nextValue.length === 2) {
        setTimeout(() => this.minutesInput.nativeElement.focus());
      }
    } else {
      event.preventDefault();
    }
  }

  handleHoursBlur(event: KeyboardEvent) {
    const value = this.hoursInput.nativeElement.value;
    const regexp = this.parts.value.period === 'pm' ? /^(0[0-9]|1[0-1]|[0-9])$/ : /^(0[0-9]|1[0-2]|[0-9])$/;
    if (regexp.test(value)) {
      const nextValueNum = parseInt(value, 10);
      this.parts.controls.hours.setValue(nextValueNum.toString());
    } else {
      this.parts.controls.hours.setValue('');
    }
  }

  handleHoursKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowRight' && this.hoursInput.nativeElement.selectionStart === this.hoursInput.nativeElement.value.length) {
      this.minutesInput.nativeElement.focus();
    }
  }

  handleMinutesFocus(event: KeyboardEvent) {
    this.minutesInput.nativeElement.select();
  }

  handleMinutesKeyPress(event: KeyboardEvent) {
    const nextValue = this.minutesInput.nativeElement.value.slice(0, this.minutesInput.nativeElement.selectionStart) + event.key +
    this.minutesInput.nativeElement.value.slice(this.minutesInput.nativeElement.selectionEnd, this.minutesInput.nativeElement.value.length);

    if (/^([0-5]?[0-9])$/.test(nextValue)) {
      const nextValueNum = parseInt(nextValue, 10);
      if (nextValueNum > 5 || nextValue.length === 2) {
        setTimeout(() => this.periodInput.nativeElement.focus());
      }
    } else {
      event.preventDefault();
    }
  }

  handleMinutesBlur(event: KeyboardEvent) {
    const value = this.minutesInput.nativeElement.value;

    if (/^([0-5]?[0-9])$/.test(value)) {
      const nextValueNum = parseInt(value, 10);
      if (nextValueNum <= 9 && value.length < 2) {
        this.parts.controls.minutes.setValue('0' + nextValueNum);
      }
    } else {
      this.parts.controls.minutes.setValue('');
    }
  }

  handleMinutesKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      if (this.minutesInput.nativeElement.value === '') {
        this.hoursInput.nativeElement.focus();
      }
    }
    if (event.key === 'ArrowLeft' && this.minutesInput.nativeElement.selectionStart === 0) {
      setTimeout(() => this.hoursInput.nativeElement.focus());
    }
    if (event.key === 'ArrowRight' && this.minutesInput.nativeElement.selectionStart === this.minutesInput.nativeElement.value.length) {
      setTimeout(() => this.periodInput.nativeElement.focus());
    }
  }

  handlePeriodKeyPress(event: KeyboardEvent) {
    const value = this.periodInput.nativeElement.value;
    let nextValue = '';
    if (event.key.toLowerCase() === 'a') {
      nextValue = 'am';
    } else if (event.key.toLowerCase() === 'p') {
      nextValue = 'pm';
    } else if (event.key.toLowerCase() === 'm') {
      nextValue = value;
    }
    if (value !== nextValue) {
      this.parts.controls.period.setValue(nextValue);
    }
    event.preventDefault();
  }

  handlePeriodBlur(event: KeyboardEvent) {
    const value = this.periodInput.nativeElement.value;

    if (value === 'am') {

    } else if (value === 'pm') {
      if (this.parts.value.hours === '12') {
        this.parts.controls.hours.setValue('00');
      }
    } else {
      this.parts.controls.period.setValue('');
    }
  }

  handlePeriodKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      if (this.periodInput.nativeElement.value === '') {
        this.minutesInput.nativeElement.focus();
      } else {
        this.parts.controls.period.setValue('');
      }
      event.preventDefault();
    }
    if (event.key === 'ArrowLeft' && this.periodInput.nativeElement.selectionStart === 0) {
      setTimeout(() => this.minutesInput.nativeElement.focus());
    }
  }

  handlePeriodFocus(event: KeyboardEvent) {
    this.periodInput.nativeElement.select();
  }

  /**
   * MatForm Interface Implementing
   */

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.hoursInput.nativeElement.focus();
    }
  }

  writeValue(value: Date | null): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Helpers
   */

  bundledTransformFunction(event: BundledEvent) {
    const chronoResult = Chrono.parse(event.fromValue);

    const date = chronoResult && this.bundledInputAppearance === 'start' ?
      (chronoResult[0] && chronoResult[0].start)
      :
      ((chronoResult[0] && chronoResult[0].end) || (chronoResult[1] && chronoResult[1].start));

    if (
      date &&
      date.knownValues &&
      (
        date.knownValues.hour ||
        date.knownValues.minute ||
        date.knownValues.meridiem
      )
    ) {
      const dateParts = {
        ...date.impliedValues,
        ...date.knownValues
      };

      const dateObject = new Date(dateParts.year, dateParts.month, dateParts.day, dateParts.hour, dateParts.minute);
      event.toValue = this.datePipe.transform(dateObject, 'hh:mm a');
      event.formControlValue = dateObject;
      event.formControlValueChanged = !this.bundledInputFormControl.value ||
        dateObject.getHours() !== this.bundledInputFormControl.value.getHours() ||
        dateObject.getMinutes() !== this.bundledInputFormControl.value.getMinutes();

      let index = 1;
      if (
        this.bundledInputAppearance === 'start' ||
        (this.bundledInputAppearance === 'end' && chronoResult[0].end)
      ) {
        index = 0;
      }

      event.fromValueHighlightRange = [chronoResult[index].index, chronoResult[index].index + chronoResult[index].text.length];
    } else {
      event.toValue = null;
    }
  }

  bundledValueCompareFunction(value: Date, bundledValue: Date): boolean {
    return (
      value &&
      bundledValue &&
      value.getHours() === bundledValue.getHours() &&
      value.getMinutes() === bundledValue.getMinutes()
    );
  }
}
