// Common
import { Pipe, PipeTransform } from '@angular/core';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';

@Pipe({
  name: 'stitchType'
})
export class StitchTypePipe implements PipeTransform {

  transform(item: Stitch): StitchType {
    return item?.getStitchType();
  }
}
