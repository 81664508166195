import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'testAllElements'
})
export class TestAllElementsPipe implements PipeTransform {

  transform(items: object[], values: any[], key?: string): boolean {
    if (!items || !items.length) {
      return false;
    }

    return values.some(value => items.every(item => (key ? item[key] : item) === value));
  }
}
