// Common
import { Component, Output, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { KnotSource } from '@modules/knots/types/knot-source';

// Types
import { ChartState } from '@modules/knowledge/types/chart-state';
import { StitchTypeFilters } from '@modules/knowledge/types/stitch-type-filters';

// RX
import { combineLatest, Subject } from 'rxjs';
import { skip, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-knowledge-chart-context-menu',
  templateUrl: './knowledge-chart-context-menu.component.html',
  styleUrls: ['./knowledge-chart-context-menu.component.less']
})
export class KnowledgeChartContextMenuComponent implements OnInit, OnDestroy {

  @Input() state: ChartState;

  @Output() change: EventEmitter<ChartState> = new EventEmitter();

  public createdFromControl = new UntypedFormControl();
  public createdToControl = new UntypedFormControl();
  public linkedFromControl = new UntypedFormControl();
  public linkedToControl = new UntypedFormControl();
  public scoreFrom = new UntypedFormControl();
  public scoreTo = new UntypedFormControl();
  public dateButtonSelected: 'from' | 'to' = 'from';
  public sources = [
    { key: 'user', title: 'User' },
    { key: 'deepentropy', title: 'Deepentropy' },
    { key: 'cesar_named_entities', title: 'Cesar (named entities)' },
    { key: 'cesar_textrank_scored', title: 'Cesar (bm25 scored)' },
    { key: 'cesar_tfidf_scored', title: 'Cesar (tfidf scored)' },
    { key: 'cesar_perturbation_scored_phrases', title: 'Cesar (perturbation scored)'},
  ];

  private alive = new Subject<void>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    if (this.state.filters.scoreFrom) {
      this.scoreFrom.setValue(this.state.filters.scoreFrom);
    }

    if (this.state.filters.scoreTo) {
      this.scoreTo.setValue(this.state.filters.scoreTo);
    }

    combineLatest([
      this.scoreFrom.valueChanges.pipe(startWith(this.state.filters.scoreFrom)),
      this.scoreTo.valueChanges.pipe(startWith(this.state.filters.scoreTo))
    ])
      .pipe(
        skip(1),
        takeUntil(this.alive)
      )
      .subscribe(([scoreFrom, scoreTo]) => {
        this.change.emit({
          ...this.state,
          filters: {
            ...this.state.filters,
            scoreFrom,
            scoreTo
          }
        });
      });

    combineLatest([
      this.createdFromControl.valueChanges.pipe(startWith(this.state.filters.createdFrom)),
      this.createdToControl.valueChanges.pipe(startWith(this.state.filters.createdTo)),
    ])
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(([from, to]) => {
        if (
          from?.getTime() !== this.state?.filters?.createdFrom?.getTime() ||
          to?.getTime() !== this.state?.filters?.createdTo?.getTime()
        ) {
          this.change.emit({
            ...this.state,
            filters: {
              ...this.state.filters,
              createdFrom: from,
              createdTo: to,
              createdToday: false,
              createdThisWeek: false,
              createdThisMonth: false,
              createdThisYear: false,
              createdLastHour: false,
              createdLast3Hours: false,
              createdLast5Hours: false,
            }
          });
        }
      });

    combineLatest([
      this.linkedFromControl.valueChanges.pipe(startWith(this.state.filters.linkedFrom)),
      this.linkedToControl.valueChanges.pipe(startWith(this.state.filters.linkedTo)),
    ])
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(([from, to]) => {
        if (
          from?.getTime() !== this.state?.filters?.linkedFrom?.getTime() ||
          to?.getTime() !== this.state?.filters?.linkedTo?.getTime()
        ) {
          this.change.emit({
            ...this.state,
            filters: {
              ...this.state.filters,
              linkedFrom: from,
              linkedTo: to,
              linkedToday: false,
              linkedThisWeek: false,
              linkedThisMonth: false,
              linkedThisYear: false,
              linkedLastHour: false,
              linkedLast3Hours: false,
              linkedLast5Hours: false,
            }
          });
        }
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  setBooleanFilter(
    property: 'autoDiscovery' | 'pinnedTags' | 'pinnedKnots' | 'pinnedItems' |
    'displayKnots' | 'displayTags' | 'displayConnections' | 'displayStitched' | 'collapsed'
  ) {
    this.change.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        [property]: !this.state.filters[property]
      }
    });
  }

  stitch(stitch: StitchTypeFilters) {
    this.change.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        stitch
      }
    });
  }

  setDateCreatedFilter(
    key: 'createdToday' | 'createdThisWeek' | 'createdThisMonth' |
    'createdThisYear' | 'createdLastHour' | 'createdLast3Hours' | 'createdLast5Hours'
  ) {
    this.createdFromControl.setValue(null);
    this.createdToControl.setValue(null);

    this.change.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        createdFrom: null,
        createdTo: null,
        createdToday: false,
        createdThisWeek: false,
        createdThisMonth: false,
        createdThisYear: false,
        createdLastHour: false,
        createdLast3Hours: false,
        createdLast5Hours: false,
        [key]: !this.state.filters[key]
      }
    });
  }

  setDateLinkedFilter(
    key: 'linkedToday' | 'linkedThisWeek' | 'linkedThisMonth' |
    'linkedThisYear' | 'linkedLastHour' | 'linkedLast3Hours' | 'linkedLast5Hours'
  ) {
    this.linkedFromControl.setValue(null);
    this.linkedToControl.setValue(null);

    this.change.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        linkedFrom: null,
        linkedTo: null,
        linkedToday: false,
        linkedThisWeek: false,
        linkedThisMonth: false,
        linkedThisYear: false,
        linkedLastHour: false,
        linkedLast3Hours: false,
        linkedLast5Hours: false,
        [key]: !this.state.filters[key]
      }
    });
  }

  setSource(source: KnotSource) {
    this.change.emit({
      ...this.state,
      filters: {
        ...this.state.filters,
        source
      }
    });
  }
}
