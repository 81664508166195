import { Pipe, PipeTransform } from '@angular/core';
import { Stitch } from '@modules/common/types/stitch';
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

@Pipe({
  name: 'selectedItemsDragData'
})
export class SelectedItemsDragDataPipe implements PipeTransform {
  transform = (items: Stitch[], selectedIds: string[], currentItem: Stitch): DragData => {
    // Stitch type of messageFolder is not match with key of DragDataTypes messageFolder
    const dragDataType = DragDataTypes[currentItem.getStitchType()] ?? DragDataTypes.messageFolder;

  return selectedIds.includes(currentItem.id)
    ? { data: items.filter(({ id }) => selectedIds.includes(id)), type: dragDataType }
    : { data: [currentItem], type: dragDataType };
  }
}
