// Common
import { Component, Input, TemplateRef } from '@angular/core';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

@Component({
  selector: 'app-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.less'],
})
export class DropdownItemComponent {
  @Input() itemTemplate?: TemplateRef<unknown>; 
  @Input() appearance: 'default' | 'amethyst' | 'sapphire' = 'default';
  @Input() option: DropdownSelectItem<unknown>;
  @Input() active: boolean;
}
