<div class="container">
  <div class="container-input">
    <ng-content></ng-content>
  </div>

  <div
    class="container-side"

    stchPopover
    stchPopoverPlacement="bottomLeft"
    stchPopoverTrigger="click"
    [stchPopoverContent]="sideOptionsPopover"
    [stchPopoverShowUntil]="popoverHide"
  >
    <div class="container-side-separator"></div>
    <div class="container-side-value">{{ (sideOptions | findBy: 'value': sideFormControl?.value)?.title }}</div>
    <mat-icon class="container-side-icon" svgIcon="dropdown-down"></mat-icon>
  </div>
</div>

<ng-template #sideOptionsPopover>
  <div
    class="options no-scroll"
    [style.minWidth.px]="130"
  >
    <div
      *ngFor="let option of sideOptions"
      class="options-item"
      [class.active]="option.value === sideFormControl?.value"
      (click)="handleSelect(option)"
    >
      <mat-icon class="options-item-icon" *ngIf="option.icon" [svgIcon]="option.icon"></mat-icon>
      {{ option.title }}
    </div>
  </div>
</ng-template>
