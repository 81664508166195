<div
  class="compose"
  (drop)="handleDropFiles($event)"
  (dragenter)="dragOver = dragOver + 1"
  (dragleave)="dragOver = dragOver - 1"
  [class.drag-over]="dragOver > 0"
  #droppableArea
>
  <div class="row">
    <div class="row-rest">
      <app-autocomplete-multiple-input
        title="To:"
        class="recipients"
        placeholder="Type to choose contact"
        [suggestions]="contactsSuggestions"
        valueKey="address"
        [inputFormControl]="form.controls.to"
        [itemTemplate]="contactTemplate"
        [containerStyles]="{ padding: '8px 8px 8px 0' }"
        [noBorder]="true"
        [useInputText]="true"
        [useInputTextGetValue]="useInputTextGetValue"
        [optionsDraggable]="true"

        appDroppable
        appDroppableHoverClass="drag-over"
        [appDroppablePredicate]="dndPredicate(form.controls.to.value)"
        (appDroppableDrop)="handleDrop($event, 'to')"
      ></app-autocomplete-multiple-input>
    </div>
    <div *ngIf="!cc" class="row-more" (click)="cc = true">CC</div>
    <div *ngIf="!bcc" class="row-more" (click)="bcc = true">BCC</div>
  </div>

  <div *ngIf="cc" class="row">
    <div class="row-rest">
      <app-autocomplete-multiple-input
        title="CC:"
        class="recipients"
        placeholder="Type to choose contact"
        [suggestions]="contactsSuggestions"
        valueKey="address"
        [inputFormControl]="form.controls.cc"
        [itemTemplate]="contactTemplate"
        [containerStyles]="{ padding: '8px 8px 8px 0' }"
        [noBorder]="true"
        [useInputText]="true"
        [useInputTextGetValue]="useInputTextGetValue"
        [optionsDraggable]="true"

        appDroppable
        appDroppableHoverClass="drag-over"
        (appDroppableDrop)="handleDrop($event, 'cc')"
        [appDroppablePredicate]="dndPredicate(form.controls.cc.value)"
      ></app-autocomplete-multiple-input>
    </div>
  </div>

  <div *ngIf="bcc" class="row">
    <div class="row-rest">
      <app-autocomplete-multiple-input
        title="BCC:"
        class="recipients"
        placeholder="Type to choose contact"
        [suggestions]="contactsSuggestions"
        valueKey="address"
        [inputFormControl]="form.controls.bcc"
        [itemTemplate]="contactTemplate"
        [containerStyles]="{ padding: '8px 8px 8px 0' }"
        [noBorder]="true"
        [useInputText]="true"
        [useInputTextGetValue]="useInputTextGetValue"
        [optionsDraggable]="true"

        appDroppable
        appDroppableHoverClass="drag-over"
        (appDroppableDrop)="handleDrop($event, 'bcc')"
        [appDroppablePredicate]="dndPredicate(form.controls.bcc.value)"
      ></app-autocomplete-multiple-input>
    </div>
  </div>

  <div class="row no-border">
    <div class="row-title">Subject</div>
    <div class="row-rest">
      <input
        class="row-subject"
        [formControl]="form.controls.subject"
        #titleInput
      />
    </div>

    <app-button
      class="row-label"
      label="Quick Reply"
      appearance="link-blue"
      stchPopover
      [stchPopoverContent]="quickReplyTemplatesTemplate"
      [stchPopoverShowUntil]="customPopoverClose"
      stchPopoverPlacement="bottom"
      stchPopoverTrigger="click"
    />

    <app-button
      class="row-label"
      label="Template"
      appearance="link-blue"
      stchPopover
      [stchPopoverContent]="templatesTemplate"
      [stchPopoverShowUntil]="customPopoverClose"
      stchPopoverPlacement="bottom"
      stchPopoverTrigger="click"
    />

    <app-button
      class="row-label"
      label="Signature"
      appearance="link-blue"
      stchPopover
      [stchPopoverContent]="signaturesTemplate"
      [stchPopoverShowUntil]="customPopoverClose"
      stchPopoverPlacement="bottom"
      stchPopoverTrigger="click"
    />
  </div>

  <div class="card">
    <div class="body">
      <app-rich-text-editor
        [control]="form.controls['bodyHtml']"
        [editorCommandExecutor]="editorCommandExecutor"
        [disableTableMenu]="true"
        (imageUploaded)="handleEditorUploadedImage($event)"
      />
    </div>

    <app-files-input
      class="files"
      [inputFormControl]="form.controls.uploads"
      [outherDropSource]="droppedFiles"
      (uploading)="filesUploading = $event"
      [multiple]="true"
      [withErrors]="true"
      [validationMessages]="{
        size: 'File is too large. Maximum size 25MB.',
        uploading: 'Can\'t upload file'
      }"
    />

    <div class="footer">
      <app-button
        class="footer-button"
        label="SEND"
        [disabled]="loading || filesUploading || !form.valid"
        icon="send"
        appearance="square-blue"
        (click)="send()"
      />

      <app-button
        class="footer-button"
        label="SAVE"
        appearance="square"
        (click)="send(true)"
      />

      <div class="footer-divider"></div>

      <app-button
        class="footer-button"
        [label]="'Send Later ' + (form.controls.scheduled.value ? ': ' : '') + (form.controls.scheduled.value | formatSendLater)"
        icon="send-later"
        [appearance]="!!form.controls.followed.value ? 'link-blue' : 'link'"
        stchPopover
        [stchPopoverContent]="sendLaterPopoverTemplate"
        stchPopoverPlacement="top"
        stchPopoverTrigger="click"
        [stchPopoverShowUntil]="customPopoverClose"
      />

      <app-button
        class="footer-button"
        label="Follow Up..."
        icon="follow-up-small"
        [appearance]="!!form.controls.followed.value ? 'link-blue' : 'link'"
        stchPopover
        [stchPopoverArrow]="true"
        [stchPopoverContent]="followUpPopoverTemplate"
        stchPopoverPlacement="top"
        stchPopoverTrigger="click"
        [stchPopoverShowUntil]="customPopoverClose"
      />

      <app-radio-button
        class="footer-button"
        icon="send-notify"
        appearance="checkbox"
        [booleanValue]="true"
        [inputFormControl]="form.controls.notifyIfRead"
        title="Notify If Read"
      >
        Notify If Read
      </app-radio-button>
    </div>
  </div>

  <div for="files-input" class="droppable-area"></div>
</div>

<ng-template #contactTemplate let-contact="item">
  <div class="contact">
    <app-user-avatar
      class="contact-avatar-icon"
      size="28"
      [contact]="{ name: contact.name, email: contact.address }"
      [usePopover]="false"
    ></app-user-avatar>

    <div class="contact-body">
      <div class="contact-body-heading">
        <div class="contact-body-heading-title">
          {{ contact.name || 'No Name' }}
        </div>
      </div>

      <div class="contact-body-date">
        <div class="contact-body-date-item">
          {{ contact.address || 'No Email'}}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #followUpPopoverTemplate>
  <app-postpone-context-menu
    [inputFormControl]="form.controls.followed"
    appearance="followUp"
    (close)="customPopoverClose.next()"
  />
</ng-template>

<ng-template #sendLaterPopoverTemplate>
  <app-postpone-context-menu
    [inputFormControl]="form.controls.scheduled"
    appearance="sendLater"
    [convertToDate]="false"
    (close)="customPopoverClose.next()"
  />
</ng-template>

<ng-template #signaturesTemplate>
  <app-context-menu-item
    *ngIf="!signatures?.length"
    title="New signature"
    (execute)="handleNewTemplate('signatures')"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngFor="let signature of signatures"
    [title]="signature.title"
    (execute)="selectSignature(signature)"
  ></app-context-menu-item>
</ng-template>

<ng-template #templatesTemplate>
  <app-context-menu-item
    *ngIf="!templates?.length"
    title="New template"
    (execute)="handleNewTemplate('templates')"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngFor="let template of templates"
    [title]="template.title"
    (execute)="selectTemplate(template)"
  ></app-context-menu-item>
</ng-template>

<ng-template #quickReplyTemplatesTemplate>
  <app-context-menu-item
    *ngIf="!quickReplyTemplates?.length"
    title="New quick reply template"
    (execute)="handleNewTemplate('quick_reply')"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngFor="let template of quickReplyTemplates"
    [title]="template.title"
    (execute)="selectQuickReply(template)"
  ></app-context-menu-item>
</ng-template>
