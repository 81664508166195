// Common
import { Component, Injector } from '@angular/core';

// Services
import { CalendarsService } from '@modules/calendar-app/services/calendars.service';
import { EventsService } from '@modules/calendar-app/services/events.service';
import { CalendarAppStateService } from '@modules/calendar-app/services/state.service';

// Types
import { VirtualFolder } from '@modules/calendar-app/types/virtual-folder';
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Application } from '@modules/common/types/application';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { Calendar } from '@modules/calendar-app/types/calendar';
import { SidebarItem } from '@modules/common/types/sidebar-item';

// Components
import { BaseSidebarComponent } from '@modules/common/components/base-sidebar/base-sidebar.component';

@Component({
  selector: 'app-events-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.less'],
})

export class EventsSideBarComponent extends BaseSidebarComponent<Calendar, CalendarEvent, VirtualFolder> {
  application = Application.calendar;
  sidebarSplitViewKey = SidebarSplitViewKey.calendar;
  selfDragDataTypes = [DragDataTypes.event, DragDataTypes.calendar];

  public items: SidebarItem[] = [
    { title: 'Events', id: 'all_events', icon: 'sapphire-events' },
    { title: 'Flagged', id: 'flagged', icon: 'sapphire-flagged' },
    { title: 'Follow Up', id: 'followed', icon: 'sapphire-follow-up' },
    { title: 'Snoozed', id: 'snoozed', icon: 'sapphire-snooze' },
    { title: 'Archive', id: 'archived', icon: 'sapphire-archive' },
    { title: 'Trash', id: 'deleted', icon: 'sapphire-trash' },
  ];

  constructor(
    stateService: CalendarAppStateService,
    injector: Injector,
    private calendarsService: CalendarsService,
    private eventsService: EventsService,
  ) {
    super(injector, stateService);
  }

  /**
   * Actions
   */

  handleMove(dragData: DragData, sidebarFolder: VirtualFolder) {
    if (dragData.type === DragDataTypes.event) {
      const { changes, message } = CalendarEvent.getChangesForVirtualFolder(sidebarFolder);
      this.eventsService.bunchUpdate({ ids: this.getIds(dragData.data) }, changes, { message });
    } else if (dragData.type === DragDataTypes.calendar) {
      const { changes, message } = Calendar.getChangesForVirtualFolder(sidebarFolder);
      this.calendarsService.bunchUpdate({ ids: this.getIds(dragData.data) }, changes, { message });
    }
  }

  handleMore(item: CalendarEvent) {
    this.stateService.setMainView(item);
  }

  getStitchFromDragData(dragData: DragData, sidebarFolder: VirtualFolder): CalendarEvent {
    const { changes } = CalendarEvent.getChangesForVirtualFolder(sidebarFolder);

    return new CalendarEvent({ ...changes, title: dragData.data[0]['title'] });
  }
}
