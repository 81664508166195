import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'stch-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.less']
})
export class JSONViewerComponent implements OnChanges {
  @Input() json: string;

  public items: { title: string, id: string, parentId?: string }[] = [];

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('json' in changes) {
      this.parse();
    }
  }

  /**
   * Actions
   */

  private parse() {
    if (!this.json) {
      this.items = [];
      return;
    }

    let parsed = {};

    try {
      parsed = JSON.parse(this.json);
    } catch (e) {
      this.items = [];
      return;
    }

    this.items = this.parseObject(parsed);
  }

  private parseObject(parsed: unknown, parentId = null) {
    if (typeof parsed !== 'object') { return []; }

    const result = []

    Object.entries(parsed).forEach(([key, value], index) => {
      const id = `${ parentId }-${ index }`;
      let title = ''

      if (
        value &&
        typeof value === 'object' &&
        !(
          Array.isArray(value) &&
          typeof value[0] === 'string' &&
          value.length < 5
        )
      ) {
        title = `${ key }:`
        result.push(...this.parseObject(value, id));
      } else {
        title = `${ key }: ${ JSON.stringify(value) }`
      }

      result.push({ id, title, parentId })
    });

    return result;
  }

  trigger(expanded: BehaviorSubject<boolean>) {
    expanded.next(!expanded.getValue())
  }
}
