// Common
import { ComponentRef, Directive, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { NotesListContextMenuComponent } from '../components/notes-list-context-menu/notes-list-context-menu.component';

// Types
import { StateKey } from '@modules/settings/types/state-key';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { NotesListState } from '../types/notes-list-state';

// RX
import { takeUntil } from 'rxjs/operators';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';

@Directive({
  selector: '[appNotesListContextMenu]'
})
export class NotesListContextMenuDirective extends ContextMenuDirective implements OnInit, OnDestroy {

  // Inputs
  @Input() appNotesListContextMenuStateKey: StateKey;
  @Input() appNotesListContextMenuWithAdvancedSearch: boolean;
  @Input() appNotesListContextMenuWithScore: boolean;
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';

  // Outputs
  @Output() appNotesListContextMenuChange = new EventEmitter<NotesListState>();

  // Private
  private state: NotesListState;

  constructor (
    private stateService: StateService,
    private searchService: AdvancedSearchService,
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<NotesListContextMenuComponent>) {
    componentRef.instance.state = this.state;
    componentRef.instance.withAdvancedSearch = this.appNotesListContextMenuWithAdvancedSearch;
    componentRef.instance.withScore = this.appNotesListContextMenuWithScore;
  }

  registerComponent() {
    return NotesListContextMenuComponent;
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.state = new NotesListState({
      searchService: this.searchService,
      stateService: this.stateService,
      stateKey: this.appNotesListContextMenuStateKey,
      withAdvanced: this.appNotesListContextMenuWithAdvancedSearch
    });

    this.state.changes()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.appNotesListContextMenuChange.emit(this.state);
        this.isDefaultStateObservable?.next(this.state.isDefault());
      });

    if (!this.isDefaultStateObservable) { return; }

    this.isDefaultStateObservable
      .pipe(takeUntil(this.alive))
      .subscribe(isDefault => {
        if (isDefault && !this.state.isDefault()) {
          this.state.resetToDefault();
        }
      });
  }

  ngOnDestroy() {
    this.state.detach();
    super.ngOnDestroy();
  }
}
