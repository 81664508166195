// Common
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Account } from '@modules/account/types/account';

// Services
import { OnlineStatusService } from '@modules/account/services/online-status.service';

// Types
import { SpaceParticipant } from '@modules/settings/types/space-participant';

// RX
import { Subject, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'stch-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.less'],
})
export class AvatarComponent implements OnInit, OnChanges, OnDestroy {

  @Input() user: SpaceParticipant | Account;

  public url: string;
  public initials: string;
  public online: boolean;

  private userChanged = new Subject<void>();
  private alive = new Subject<void>();

  constructor(
    private onlineService: OnlineStatusService
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.userChanged
      .pipe(
        switchMap(() => this.user ? this.onlineService.getStatus(this.user.id) : of(false)),
        takeUntil(this.alive)
      )
      .subscribe(online => {
        this.online = online;
      });

    this.userChanged.next(); // ngOnChanges called before ngOnInit
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('user' in changes) {
      this.initials = this.user.fullName.split(' ').map(n => n[0]).join('');
      this.url = this.user.avatar;
      this.userChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleError() {
    this.url = null;
  }
}
