<app-context-menu-item
  *ngFor="let option of options[appearance]"
  [title]="option?.title"
  [close]="true"
  [active]="inputFormControl?.value === option?.value"
  (execute)="handleSelect(option)"
></app-context-menu-item>

<app-context-menu-divider></app-context-menu-divider>

<app-date-time-popover
  [fromDate]="dateControl"
  [fromTime]="timeControl"
  [minDate]="minDate"
  [highlightRange]="false"
  (onSave)="handleCustomSelect()"
>
  <app-context-menu-item
    title="Custom Date"
  ></app-context-menu-item>
</app-date-time-popover>

<ng-container
  *ngIf="
    (appearance === 'snooze' && items?.[0]?.snoozed) ||
    (appearance === 'followUp' && items?.[0]?.followed)
  "
>
  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    [title]="appearance === 'snooze' ? 'Unsnooze' : 'Unfollow'"
    (execute)="handleUnSnooze()"
    [close]="true"
  ></app-context-menu-item>
</ng-container>
