<div class="main" *spaceContext="'main'">
  <app-nav-bar></app-nav-bar>

  <div class="under-nav-bar">
    <app-split-view
      side="left"
      key="adminSidebar"
      [collapsedSize]="76"
      [shadow]="true"
      [magneticSize]="120"
      [withIcon]="true"
    >
      <app-sidebar split-left-pane splitViewKey="adminSidebar">
        <app-admin-side-bar></app-admin-side-bar>
      </app-sidebar>

      <div split-right-pane class="content">
        <router-outlet *stchSpaceRerender></router-outlet>
      </div>
    </app-split-view>
  </div>
</div>
