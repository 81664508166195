// Common
import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, Self, Output, EventEmitter } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

// Types
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { Section } from '@modules/common/types/section';

// Services
import { SectionsListService } from '@modules/tasks/services/sections-list.service';
import { BaseSectionsListService } from '@modules/common/services/base-sections-list.service';

@Component({
  selector: 'app-project-sections',
  templateUrl: './project-sections.component.html',
  styleUrls: ['./project-sections.component.less'],
  providers: [{ provide: BaseSectionsListService, useClass: SectionsListService }]
})
export class ProjectSectionsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() project: Project;

  @Output() clickItem = new EventEmitter<Task>();
  @Output() dblClickItem = new EventEmitter<Task>();

  public sections: Section[] = [];

  private alive: Subject<void> = new Subject();
  private projectChanged = new Subject<void>();

  constructor (
    @Self() private listService: BaseSectionsListService<Task, Project>,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.listService.getSections()
      .pipe(takeUntil(this.alive))
      .subscribe(sections => {
        this.sections = sections;
      });

    this.projectChanged
      .pipe(
        filter(() => !!this.project),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.listService.setContainer(this.project);
      });

    this.projectChanged.next();
  }

  ngOnChanges (changes: SimpleChanges) {
    if ('project' in changes) {
      this.projectChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.listService.detach();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  addSection() {
    this.listService.addSection();
  }
}
