import { Subject } from 'rxjs';
import { isNil } from '../utils/base';
import { NativeFile } from './native-file';
import mime from '../utils/mime';
import { Like } from '@modules/common/types/like';

export class Upload {
  id?: string;
  nativeFile?: NativeFile;
  progress?: Subject<number>;
  error?: string;
  name?: string;
  type?: string;
  size?: number;
  temp?: boolean;
  copy?: string;
  inline?: boolean;

  constructor(upload: Like<Upload>) {
    this.id = upload.id;
    this.nativeFile = upload.nativeFile;
    this.progress = new Subject();
    this.name = upload.name || upload.nativeFile.name;
    this.type = upload.type || upload.nativeFile.type || mime.getType(upload.nativeFile.name);
    this.size = isNil(upload.size) ? upload.nativeFile?.size : upload.size;
    this.temp = upload.temp;
    this.copy = upload.copy;
    this.inline = upload.inline || false;
  }

  asPayloadJSON() {
    return {
      id: this.id,
      name: this.name,
      size: this.size,
      type: this.type,
      copy: this.copy,
      inline: this.inline
    };
  }
}
