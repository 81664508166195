// Common
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { TemplatesService } from '@modules/account/services/templates.service';

// Components
import { TemplateFormComponent } from '@modules/settings/components/messages/templates/template-form/template-form.component';

// Types
import { MessageTemplate } from '@modules/account/types/message-template';

@Component({
  selector: 'app-settings-messages-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.less']
})
export class TemplatesComponent implements OnInit, OnDestroy {
  public templates: MessageTemplate[];

  private onModalClose = new Subject<void>();
  private alive = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private templatesService: TemplatesService,
    private injector: Injector,
  ) { }

  ngOnInit(): void {
    this.templatesService.search()
      .pipe(takeUntil(this.alive))
      .subscribe(({ items }) => this.templates = items);
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  handleOpenForm(template?: MessageTemplate) {
    this.modalService.showModal({
      component: TemplateFormComponent,
      content: { template, invertedColor: true, singleButton: false, withCloseToken: true },
      appearance: 'default',
      title: template?.id ? 'Update Template' : 'Create Template',
      maxModals: 1,
      closed: this.onModalClose.asObservable,
      injector: this.injector
    });
  }

  handleRemove(template: MessageTemplate) {
    this.templatesService.delete(template);
  }

}
