<img
  *ngIf="url"
  class="avatar"
  [src]="url"
  (error)="handleError()"
  [matTooltip]="user?.fullName"
/>

<div
  *ngIf="!url"
  class="initials"
  [matTooltip]="user?.fullName"
>
  {{ initials }}
</div>

<div
  *ngIf="online"
  class="online"
></div>
