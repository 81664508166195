<div class="control"
  (click)="handleChangeViewport(-1)"
>
  <mat-icon
    svgIcon="collapse-down"
    class="up-icon"
  ></mat-icon>
</div>

<app-context-menu-item
  *ngFor="let year of years"
  [title]="year"
  [close]="true"
  [active]="year === selectedYear"
  (execute)="handleYearClick(year)"
></app-context-menu-item>

<div class="control"
  *ngIf="upEnabled"
  (click)="handleChangeViewport(1)"
>
  <mat-icon
    svgIcon="collapse-down"
    class="down-icon"
  ></mat-icon>
</div>
