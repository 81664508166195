<div class="container">
  <div class="row">
    Repeat Every

    <stch-inputs-group size="s">
      <app-input
        [style.width.px]="79"
        appearance="sapphire-outline"
        [inputFormControl]="form.controls.every"
        [number]="true"
        size="s"
      />

      <app-dropdown-input
        [style.width.px]="116"
        [inputFormControl]="form.controls.everyUnit"
        [options]="everyUnitOptions"
        appearance="sapphire-outline"
        size="m"
      />
    </stch-inputs-group>
  </div>

  <div
    *ngIf="form.controls.everyUnit.value === RRule.WEEKLY"
    class="row"
  >
    On

    <div class="row-group">
      <app-radio-button
        *ngFor="let option of daysOptions"
        [value]="option[0]"
        [title]="option[1]"
        [inputFormControl]="form.controls.weeklyDays"
        appearance="button"
        [multiple]="true"
      />
    </div>
  </div>

  <div
    *ngIf="form.controls.everyUnit.value === RRule.MONTHLY"
    class="row"
  >
    <app-radio-button
      value="on-days"
      title="On Days"
      [inputFormControl]="form.controls.monthlyType"
      appearance="sapphire"
      size="m"
    />

    <stch-inputs-group size="s">
      <app-dropdown-input
        [style.width.px]="81"
        [inputFormControl]="form.controls.monthlyOnDaysPosition"
        [options]="monthlyOnDaysPositionOptions"
        placement="bottomLeft"
        [disabled]="form.controls.monthlyType.value !== 'on-days'"
        appearance="sapphire-outline"
        size="m"
      />

      <app-dropdown-multiple-input
        [style.width.px]="148"
        [inputFormControl]="form.controls.monthlyOnDaysWeekday"
        [options]="monthlyOnDaysWeekdayOptions"
        [disabled]="form.controls.monthlyType.value !== 'on-days'"
        appearance="sapphire-outline"
        size="m"
      />
    </stch-inputs-group>
  </div>

  <div
    *ngIf="form.controls.everyUnit.value === RRule.MONTHLY"
    class="row"
  >
    <app-radio-button
      value="on-dates"
      title="On Dates"
      [inputFormControl]="form.controls.monthlyType"
      appearance="sapphire"
      size="m"
    />

    <stch-inputs-group
      [style.width.px]="229"
      size="s"
    >
      <app-input
        [style.width.px]="80"
        appearance="sapphire-outline"
        [inputFormControl]="form.controls.monthlyOnDates"
        [number]="true"
        [disabled]="form.controls.monthlyType.value !== 'on-dates'"
        [min]="1"
        [max]="31"
      />
      <div
        class="group-text"
        [class.disabled]="form.controls.monthlyType.value !== 'on-dates'"
      >
        {{ form.controls.monthlyOnDates.value | ordinal }}
      </div>
    </stch-inputs-group>
  </div>

  <div
    *ngIf="form.controls.everyUnit.value === RRule.YEARLY"
    class="row"
  >
    On Month

    <stch-inputs-group size="s">
      <app-dropdown-input
        [style.width.px]="81"
        [inputFormControl]="form.controls.annuallyOnDaysPosition"
        [options]="monthlyOnDaysPositionOptions"
        appearance="sapphire-outline"
        placement="bottomLeft"
        size="m"
      />

      <app-dropdown-multiple-input
        [style.width.px]="148"
        [inputFormControl]="form.controls.annuallyOnDaysWeekday"
        [options]="monthlyOnDaysWeekdayOptions"
        appearance="sapphire-outline"
        size="m"
      />
    </stch-inputs-group>
  </div>

  <div
    *ngIf="form.controls.everyUnit.value === RRule.YEARLY"
    class="row"
  >
    <div></div>

    <div
      [style.width.px]="350"
      [style.gap.px]="8"
      class="row-group"
    >
      <app-radio-button
        *ngFor="let option of monthsOptions"
        [value]="option[0]"
        [title]="option[1]"
        [inputFormControl]="form.controls.annuallyDays"
        appearance="button"
        [multiple]="true"
        [width]="51"
      />
    </div>
  </div>

  <div class="divider"></div>

  <div class="row">
    <app-radio-button
      value="never"
      title="No End Date"
      [inputFormControl]="form.controls.ends"
      appearance="sapphire"
      size="m"
    />
  </div>

  <div class="row">
    <app-radio-button
      value="on"
      title="Ends On"
      [inputFormControl]="form.controls.ends"
      appearance="sapphire"
      size="m"
    />

    <app-dropdown-date-input
      [style.width.px]="233"
      [control]="form.controls.endsOn"
      appearance="sapphire-outline"
      size="m"
      [disabled]="form.controls.ends.value !== 'on'"
    />
  </div>

  <div class="row">
    <app-radio-button
      value="after"
      title="Ends After"
      [inputFormControl]="form.controls.ends"
      appearance="sapphire"
      size="m"
    />

    <stch-inputs-group 
      [style.width.px]="233"
    >
      <app-input
        [style.width.px]="100"
        appearance="sapphire-outline"
        [inputFormControl]="form.controls.occurences"
        [number]="true"
        [disabled]="form.controls.ends.value !== 'after'"
        [min]="1"
      />

      <div
        class="group-text"
        [class.disabled]="form.controls.ends.value !== 'after'"
      >
        occurences
      </div>
    </stch-inputs-group>
  </div>

  <div class="placeholder" [matTooltip]="placeholder">
    <div>{{ placeholder }}</div>
  </div>
</div>
