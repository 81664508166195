// Common
import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Rx
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// Types
import { UsersFilters } from '../types/users-filters';
import { User } from '../types/user';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';

// Env
import { environment } from '@environment';

// Services
import { BaseRestService } from '@modules/common/services/base-rest.service';

@Injectable()
export class AdminUsersService extends BaseRestService<User, UsersFilters> {
  private url = environment.baseUrl + '/api/admin/users/';

  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  search(
    filters?: Partial<UsersFilters>,
  ): Observable<BaseSearchResponse<User>> {
    const requestParams = { params: new UsersFilters(filters || {}).format() };

    return this.http.get<{ count: number, items: User[] }>(this.url, requestParams)
      .pipe(
        map(({ count, items }) => ({
          count,
          items: items.map(item => new User(item))
        })),
        catchError(error => this.handleObserverError(error))
      );
  }

  create(): Observable<User> {
    throw new Error('Implementation missing');
  }

  update(): Observable<User> {
    throw new Error('Implementation missing');
  }

  getItem(id: string): Observable<User> {
    return this.http.get<{ item: User }>(this.url + id)
      .pipe(
        map(({ item }) => new User(item)),
      );
  }
}
