// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { CommentsModule } from '@modules/comments/comments.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { ConnectionsModule } from '@modules/connections/connections.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ElementsModule } from '../elements/elements.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { FormsModule } from '@angular/forms';
import { KnotsModule } from '@modules/knots/knots.module';
import { LinkedInfoModule } from '@modules/linked-info/linked-info.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopoverModule } from '@modules/popover/popover.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TagsModule } from '@modules/tags/tags.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { NoteFormComponent } from './components/note-form/note-form.component';
import { NoteSharedComponent } from './components/note-shared/note-shared.component';
import { NotebookFormComponent } from './components/notebook-form/notebook-form.component';
import { NotebookSectionsComponent } from './components/notebook-sections/notebook-sections.component';
import { NotebookSharedComponent } from './components/notebook-shared/notebook-shared.component';
import { NotebooksListComponent } from './components/notebooks-list/notebooks-list.component';
import { NotebooksListContextMenuComponent } from './components/notebooks-list-context-menu/notebooks-list-context-menu.component';
import { NotesListComponent } from './components/notes-list/notes-list.component';
import { NotesListContainerComponent } from './components/notes-list-container/notes-list-container.component';
import { NotesListContextMenuComponent } from './components/notes-list-context-menu/notes-list-context-menu.component';
import { NotesMainViewComponent } from './components/notes-main-view/notes-main-view.component';
import { NotesSideBarComponent } from './components/side-bar/side-bar.component';
import { SideBarNotebooksComponent } from '@modules/notes/components/side-bar-notebooks/side-bar-notebooks.component';
import { SideBarNotebooksTreeComponent } from './components/side-bar-notebooks-tree/side-bar-notebooks-tree.component';
import { SidebarContextMenuComponent } from '@modules/notes/components/sidebar-context-menu/sidebar-context-menu.component';

// Directives
import { NotesListContextMenuDirective } from './directives/notes-list-context-menu.directive';
import { NotebooksListContextMenuDirective } from './directives/notebooks-list-context-menu.directive';

// Pipes
import { TitleByNotesFilterPipe } from '@modules/notes/pipes/title-by-notes-filter.pipe';

// Directives
import { SidebarContextMenuDirective } from '@modules/notes/directives/sidebar-context-menu.directive';

@NgModule({
  imports: [
    AppCommonModule,
    CommentsModule,
    CommonModule,
    ConnectionsModule,
    DragNDropModule,
    EditorModule,
    ElementsModule,
    FormControlsModule,
    FormsModule,
    KnotsModule,
    LinkedInfoModule,
    MatIconModule,
    MatTooltipModule,
    PopoverModule,
    ReactiveFormsModule,
    ScrollModule,
    ScrollingModule,
    TagsModule,
    UtilsModule,
  ],
  declarations: [
    // Components
    NoteFormComponent,
    NoteSharedComponent,
    NotebookFormComponent,
    NotebookSectionsComponent,
    NotebookSharedComponent,
    NotebooksListComponent,
    NotebooksListContextMenuComponent,
    NotesListComponent,
    NotesListContainerComponent,
    NotesListContextMenuComponent,
    NotesMainViewComponent,
    NotesSideBarComponent,
    SideBarNotebooksComponent,
    SideBarNotebooksTreeComponent,
    SidebarContextMenuComponent,
    // Directives
    NotebooksListContextMenuDirective,
    NotesListContextMenuDirective,
    // Pipes
    TitleByNotesFilterPipe,
    SidebarContextMenuDirective,
  ],
  exports: [
    // Components
    NoteFormComponent,
    NotebookFormComponent,
    NotebooksListComponent,
    NotesListComponent,
    NotesListContainerComponent,
    NotesMainViewComponent,
    NotesSideBarComponent,
    // Directives
    NotebooksListContextMenuDirective,
    NotesListContextMenuDirective,
    // Pipes
    TitleByNotesFilterPipe,
  ],
  providers: []
})
export class NotesModule { }
