<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <input
    class="form-title"
    placeholder="Untitled Notebook"
    [formControl]="form.controls.title"
    #titleInput
  />

  <app-color-input
    class="form-marker"
    icon="notebook"
    [control]="form.controls.color"
    [size]="16"
  ></app-color-input>

  <div class="form-buttons">
    <div
      *ngIf="!inline"
      class="form-buttons-item"
      (click)="clickMore()"
    >
      More
    </div>

    <div class="form-buttons-filler"></div>

    <div
      *ngIf="!inline"
      class="form-buttons-item form-buttons-item-cancel"
      (click)="!saveInProgress && cancel()"
    >
      Cancel
    </div>

    <div
      class="form-buttons-item"
      (click)="!saveInProgress && submit()"
    >
      Add
    </div>
  </div>
</div>
