<app-dropdown-base
  [control]="inputFormControl"
  [placeholder]="placeholder"
  [withClear]="withClear"
  [appearance]="appearance"
  [invertedColor]="invertedColor"
  [size]="size"
  [disabled]="disabled || !options"
  [icon]="icon"
  [iconOnly]="iconOnly"
  [placement]="placement"
  [popoverHide]="popoverHide"
  [valueTemplate]="valueTemplate"
  [popoverTemplate]="popoverTemplate"
/>

<ng-template #valueTemplate>
  <ng-container
    *ngIf="
      appearance === 'default' ||
      appearance === 'amethyst' ||
      appearance === 'amethyst-simple'
    "
  >
    <div
      *ngFor="let item of selectedItems; last as last"
      class="selected-item"
      [ngStyle]="itemStyles | assign: item.itemStyles"
    >
      <mat-icon
        *ngIf="icon || item.icon"
        class="selected-item-icon"
        [svgIcon]="icon || item.icon"
        [style.color]="iconColor || itemStyles.iconColor || item.itemStyles?.iconColor"
      />

      <div
        class="selected-item-title"
        [style.color]="itemStyles.color || item.itemStyles?.color"
      >
        {{ item.title }}
      </div>

      <span *ngIf="!last" class="selected-item-title-delimiter">,</span>

      <mat-icon
        class="selected-item-close-icon"
        svgIcon="close-small"
        [style.color]="itemStyles.color || item.itemStyles?.color"
        (click)="removeItem($event, item)"
      />
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      appearance === 'sapphire' ||
      appearance === 'sapphire-outline' ||
      appearance === 'sapphire-inline' ||
      appearance === 'sapphire-dark'
    "
  >
    {{ selectedItems | map : 'title' : ', ' }}
  </ng-container>
</ng-template>

<ng-template #popoverTemplate>
  <stch-dropdown-container [appearance]="appearance | firstWord">
    <app-dropdown-item
      *ngFor="let option of options"
      [appearance]="appearance | firstWord"
      [option]="option"
      [itemTemplate]="itemTemplate"
      [active]="
        option.multiple
          ? (inputFormControl?.value | sameArray : option.value)
          : (inputFormControl?.value | includesBy : option.value)
        "
      (click)="handleSelect(option)"
    />
  </stch-dropdown-container>
</ng-template>
