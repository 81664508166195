// Common
import { Component, Input, Output, EventEmitter } from '@angular/core';

// Services
import { KnotsService } from '@modules/knots/services/knots.service';

// Types
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Knot } from '@modules/knots/types/knot';
import { Stitch } from '@modules/common/types/stitch';

@Component({
  selector: 'app-knot',
  templateUrl: './knot.component.html',
  styleUrls: ['./knot.component.less']
})
export class KnotComponent {

  // Inputs
  @Input() knot: Knot;
  @Input() dragEnabled = true;
  @Input() withDetach = false;
  @Input() withControls = true;
  @Input() withRemove = false;
  @Input() selected = false;
  @Input() debug: 'score' | 'createdAt' | 'recency' | 'frequency' | 'source' | 'entityType' = null;

  // Output
  @Output() delete = new EventEmitter();
  @Output() pin = new EventEmitter<boolean>();
  @Output() onRemove = new EventEmitter<void>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    this.knot &&
    dragData.type !== DragDataTypes.knot &&
    dragDataTypeAllowed(dragData.type)

  constructor (
    private knotsService: KnotsService
  ) { }

  /**
   * Actions
   */

  handleDelete() {
    this.delete.emit();
  }

  handlePin() {
    if (this.pin.observers.length) {
      this.pin.emit(this.knot.pinned);
    } else {
      this.knotsService.pin([this.knot], !this.knot.pinned);
    }
  }

  dndDrop(dragData: DragData) {
    this.knotsService.upsertBulk([this.knot], dragData.data as Stitch[]);
  }
}
