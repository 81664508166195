// Common
import { Component, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// RX
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

// Types
import { Stitch } from '@modules/common/types/stitch';

@Component({
  selector: '',
  template: ''
})
export abstract class BaseQuickFormComponent implements AfterViewInit, OnDestroy {
  // Inputs
  @Input() inline = false;
  @Input() autofocus = false;
  @Input() title = '';
  @Input() parentId: string;

  // Outputs
  @Output() more: EventEmitter<Stitch> = new EventEmitter<Stitch>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<Stitch> = new EventEmitter<Stitch>();
  @Output() afterSave: EventEmitter<Stitch> = new EventEmitter<Stitch>();

  // Public
  public form: UntypedFormGroup;
  public saveInProgress = false;
  public titleFocusSubject = new Subject<void>();

  // Protected
  protected alive = new Subject<void>();

  // View Children
  @ViewChild('titleInput', { static: true }) titleInput: ElementRef;

  /**
   * Constructor
   */

  constructor (
    public changeDetector: ChangeDetectorRef
  ) {
    this.titleFocusSubject
      .pipe(
        debounceTime(0),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.titleInput.nativeElement.focus();
      });
  }

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    if (this.autofocus) {
      this.titleInput.nativeElement.focus();
    }

    if (this.title) {
      this.form.controls['title']?.setValue?.(this.title);
      this.form.controls['subject']?.setValue?.(this.title);
    }

    if (this.parentId) {
      this.form.controls['parentId']?.setValue?.(this.parentId);
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleError() {
    this.saveInProgress = false;
  }

  abstract submit(): void;

  reset() {
    this.saveInProgress = false;
  }

  clickMore() {
    this.close.emit();
  }

  cancel() {
    this.close.emit();
  }

  handleEnterKeyPressed(event: KeyboardEvent) {
    event.stopPropagation();
    this.submit();
  }
}
