// Common
import { Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';

// Types
import { ReminderNotifyByType, Reminder, ReminderFormGroup, ReminderUnits } from '@modules/form-controls/types/reminder';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

@Component({
  selector: 'stch-reminders-picker',
  templateUrl: './reminders-picker.component.html',
  styleUrls: ['./reminders-picker.component.less']
})
export class RemindersPickerComponent {

  @Input() control: FormArray<ReminderFormGroup>;

  public unitsOptions: DropdownSelectItem<ReminderUnits>[] = [
    { title: 'minutes', value: 'minutes' },
    { title: 'hours', value: 'hours' },
    { title: 'days', value: 'days' },
    { title: 'weeks', value: 'weeks' },
  ];

  public typeOptions: DropdownSelectItem<ReminderNotifyByType>[] = [
    { title: 'Notification', value: 'notification' },
    { title: 'Email', value: 'email' },
    { title: 'Push', value: 'push' },
  ];

  public dateTypeOptions: DropdownSelectItem<'from' | 'to'>[] = [
    { title: 'Start', value: 'from' },
    { title: 'End', value: 'to' },
  ];

  /**
   * Actions
   */

  deleteReminder(index: number) {
    this.control.removeAt(index);
  }

  addReminder() {
    this.control.push((new Reminder()).asFormGroup());
  }
}
