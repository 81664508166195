<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Notes"
  [active]="filterKey === 'all_notes'"
  (execute)="selectFilter('all_notes')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Upcoming"
  [active]="filterKey === 'upcoming'"
  (execute)="selectFilter('upcoming')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Flagged"
  [active]="filterKey === 'flagged'"
  (execute)="selectFilter('flagged')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Recent"
  [active]="filterKey === 'recent'"
  (execute)="selectFilter('recent')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Follow Up"
  [active]="filterKey === 'followed'"
  (execute)="selectFilter('followed')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Archive"
  [active]="filterKey === 'archived'"
  (execute)="selectFilter('archived')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Trash"
  [active]="filterKey === 'deleted'"
  (execute)="selectFilter('deleted')"
></app-context-menu-item>

<app-context-menu-item
  [withCheckOption]="true"
  [close]="true"
  title="Notebooks"
  [active]="filterKey === 'all_notebooks'"
  (execute)="selectFilter('all_notebooks')"
></app-context-menu-item>
