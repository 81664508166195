// Common
import { Directive, Input, ComponentRef, Output, EventEmitter, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { ProjectContextMenuComponent } from '../components/context-menus/project-context-menu/project-context-menu.component';

// Types
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appProjectContextMenu]'
})
export class ProjectContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() appProjectContextMenu: Project;
  @Input() appProjectContextMenuDisabled = false;
  @Input() appProjectContextMenuMode: 'contextmenu' | 'popover' = 'contextmenu';

  // Outputs
  @Output() appProjectContextMenuOpened = this.contextMenuOpened;
  @Output() appProjectContextMenuOpenProject = new EventEmitter;
  @Output() appProjectContextMenuOpenTask = new EventEmitter<Task>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.stchPopoverDisabled = this.appProjectContextMenuDisabled;

    if (this.appProjectContextMenuMode === 'popover') {
      this.stchPopoverTrigger = 'click';
      this.stchPopoverPlacement = 'bottomLeft';
      this.stchPopoverFallbackPlacements = ['bottomRight'];
    }
  }

  /**
   * Actions
   */

  registerComponent() {
    return ProjectContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<ProjectContextMenuComponent>) {
    componentRef.instance.project = this.appProjectContextMenu;
    componentRef.instance.open
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.appProjectContextMenuOpenProject.emit();
      });

    componentRef.instance.openTask
      .pipe(takeUntil(this.alive))
      .subscribe(task => {
        this.appProjectContextMenuOpenTask.emit(task);
      });
  }
}
