<div class="controls-list">
  <ng-container *ngFor="let item of items.controls; index as index">
    <div class="controls-list-item">
      <div class="controls-list-item-template">
        <ng-container [ngTemplateOutlet]="itemTemplateRef" [ngTemplateOutletContext]="{ item, index }"></ng-container>
      </div>

      <div class="controls-list-item-actions" *ngIf="withActions">
        <div
          class="controls-list-item-actions-action"
          (click)="handleAddItem(index)"
        >
          <mat-icon
            class="controls-list-item-actions-action-icon"
            svgIcon="plus-medium"
          ></mat-icon>
        </div>

        <div
          class="controls-list-item-actions-action"
          (click)="handleRemoveItem(index)"
        >
          <mat-icon
            class="controls-list-item-actions-action-icon"
            svgIcon="minus-medium"
          ></mat-icon>
        </div>
      </div>
    </div>
  </ng-container>
</div>
