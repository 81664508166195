
<div
  *dndNestedFor="
    let item of items;
    let index = index;
    let position = position;
    let depth = depth;
    let expandable = expandable;
    let expanded = expanded;
    dropDisabled: true;
    positionKey: 'position';
  "
  class="item"
  [class.expandable]="expandable"
  [style.paddingLeft.px]="depth * 20"
  (click)="trigger(expanded)"
>
  <mat-icon
    *ngIf="expandable"
    class="icon"
    [class.expanded]="expanded | async"
    svgIcon="sapphire-chevron-right"
  ></mat-icon>

  <div
    [style.paddingLeft.px]="(expandable ? 0 : 30)"
  >
    {{ item.title }}
  </div>
</div>
