<div class="tags">
  <div class="tags-title">
    Add Tags
  </div>

  <app-tags
    class="tags-existing-items"
    [stitchItems]="stitchItems"
    [control]="control"
    [withControls]="false"
    [withPlaceholder]="true"
  ></app-tags>

  <div
    class="tags-area"
    [class.focused]="focused"
  >
    <app-simple-input
      class="tags-input"
      placeholder="Add New Tag"
      [inputFormControl]="inputControl"
      [noBorder]="true"
      [withClear]="true"
      [suggestions]="suggestions"
      [forceOutput]="true"
      [openImmediately]="false"
      (onEnter)="addTag()"
      (onSelect)="handleSelect($event)"
      (onFocus)="focused = true"
      (onBlur)="focused = false"
    ></app-simple-input>

    <div
      *ngIf="canAdd && suggestionsReceived"
      class="tags-add"
      (click)="addTag()"
    >
      + Add Tag «{{ inputControl.value }}»
    </div>

    <div
      class="tags-placeholder"
      *ngIf="!canAdd && tags?.length === 0"
    >
      You can add multiple Tags with enter e.g. personal, must read, educational
    </div>

    <div class="tags-list">
      <div
        *ngFor="let tag of tags | sortAndFilter : state"
        class="tags-list-item"
        [class.pinned]="tag.pinned"
        [appTagContextMenu]="tag"
        (appTagContextMenuPinned)="handlePin(tag)"
        (appTagContextMenuDelete)="handleDelete(tag)"
      >
        <mat-icon
          *ngIf="tag.pinned"
          class="tags-list-item-pin-icon"
          svgIcon="pin-small"
        ></mat-icon>

        #{{ tag.name }}

        <mat-icon
          class="tags-list-item-delete-icon"
          svgIcon="close-thin"
          (click)="handleDelete(tag)"
        ></mat-icon>
      </div>
    </div>
  </div>

  <div class="tags-buttons">
    <app-button appearance="square" size="medium" (click)="handleCancel()" label="Cancel" />
    <app-button appearance="square-blue" size="medium" (click)="handleSave()" label="Save" />
  </div>
</div>
