<div
  *ngIf="!items?.length && !waitingForData"
  class="placeholder"
>
  No Data
</div>

<div
  *ngIf="items?.length"
  class="items"
  [style.gridTemplateColumns]="gridTemplate"
>
  <div
    *ngIf="displayHeading"
    class="row"
  >
    <div
      *ngFor="let column of columns"
      class="cell heading"
      (click)="sort(column)"
    >
      {{ column.title }}

      <mat-icon
        *ngIf="sortBy === column.key"
        svgIcon="arrow-up"
        class="arrow-icon"
        [class.active]="true"
        [class.reversed]="sortOrder === 'desc'"
      ></mat-icon>
    </div>
  </div>

  <div
    *ngFor="let item of items"
    class="row"
    (click)="rowClick.emit(item)"
  >
    <div
      *ngFor="let column of columns"
      class="cell"
    >
      <ng-container
        *ngIf="column.template"
        [ngTemplateOutlet]="column.template"
        [ngTemplateOutletContext]="{ item: item, value: item[column.key] }"
      ></ng-container>

      <p *ngIf="!column.template">
        {{ item | accessProperty : column.key }}
      </p>
    </div>
  </div>
</div>

<app-pagination
  *ngIf="totalPages > 1"
  class="pagination"
  [pages]="totalPages"
  [currentPage]="page"
  (currentPageChange)="handlePageChange($event)"
></app-pagination>
