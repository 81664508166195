import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'someTrue'
})
export class SomeTruePipe implements PipeTransform {

  transform(object: {[key: string]: boolean} = {}): boolean {
    return Object.values(object).some(Boolean);
  }
}
