<div class="modal-body">
  <app-split-view
    split-top-pane
    side="right"
    key="dpModal"
    [collapsedSize]="15"
    [magneticSize]="200"
    [withIcon]="true"
    [iconTop]="46"
  >
    <div split-left-pane class="form-container" [ngSwitch]="stitchItem | stitchType">
      <app-message-folder-form
        *ngSwitchCase="'message-folder'"
        class="modal-form"
        [folder]="stitchItem"
        (close)="close()"
      />

      <app-message2-form
        *ngSwitchCase="'message'"
        class="modal-form"
        [message]="stitchItem"
        [autoSaveAllowed]="false"
        (close)="close()"
      />

      <app-calendar-form
        *ngSwitchCase="'calendar'"
        class="modal-form"
        [calendar]="stitchItem"
        (close)="close()"
      />

      <app-event-form
        *ngSwitchCase="'event'"
        class="modal-form"
        [event]="stitchItem"
        (close)="close()"
      />

      <app-project-form
        *ngSwitchCase="'project'"
        class="modal-form"
        [project]="stitchItem"
        (close)="close()"
      />

      <app-task-form
        *ngSwitchCase="'task'"
        class="modal-form"
        [task]="stitchItem"
        (close)="close()"
      />

      <app-notebook-form
        *ngSwitchCase="'notebook'"
        class="modal-form"
        [notebook]="stitchItem"
        (close)="close()"
      />

      <app-note-form
        *ngSwitchCase="'note'"
        class="modal-form"
        [note]="stitchItem"
        (close)="close()"
      />

      <app-group-form
        *ngSwitchCase="'group'"
        class="modal-form"
        [group]="stitchItem"
        (close)="close()"
      />

      <app-contact-form
        *ngSwitchCase="'contact'"
        class="modal-form"
        [contact]="stitchItem"
        (close)="close()"
      />

      <app-folder-form
        *ngSwitchCase="'folder'"
        class="modal-form"
        [folder]="stitchItem"
        (close)="close()"
      />

      <app-file-form
        *ngSwitchCase="'file'"
        class="modal-form"
        [file]="stitchItem"
        (close)="close()"
      />
    </div>

    <div
      split-right-pane
      class="dynamic-panel-container"
    >
      <app-dynamic-panel
        class="dynamic-panel"
        splitViewKey="dpModal"
        stateKey="modal"
      ></app-dynamic-panel>
    </div>
  </app-split-view>
</div>
