const getTunes = block => {
  switch (block.tunes.textVariant) {
    case 'citation':
      return 'font-style: italic;'
    case 'details':
      return 'font-size: 0.84em;'
    case 'call-out':
      return `
        border: 1px solid rgb(255, 135, 135);
        padding: calc(8px + 0.4em) 16px;
        border-radius: 6px;
        box-shadow: 0 0 0 5px rgb(255 120 84 / 16%);
        margin-top: 0.6em;
        margin-bottom: 0.6em;
      `
  }
}

const parseParagraph = block => {
  return `
    <div style="line-height: 1.6em; padding: 0.4em 0; ${getTunes(block)}">
      ${block.data.text}
    </div>
  `
}

const parseHeader = block => {
  return `
    <h${block.data.level || 6} style="margin-top: 0; padding: 1em 0; margin-bottom: -0.9em; line-height: 1.5em; ${getTunes(block)}">
      ${block.data.text}
    </h${block.data.level || 6}>
  `
}

const parseTable = block => {
  return `
    <div style="margin: 10px; ${getTunes(block)}">
      <table style="border-collapse: collapse; width: 100%;">
        ${(block.data.content || []).map(row => `
          <tr style="">
            ${(row || []).map(col => `
              <td style="border: 1px solid #dbdbe2; padding: 10px; min-height: 1.5em;">${col}</td>
            `).join('')}
          </tr>
        `).join('')}
      </table>
    </div>
  `
}

const parseList = block => {
  const list = block.data.style === 'ordered' ? 'ol' : 'ul'

  const drawItem = ({ content, items }) => `
    <li id="${block.id}" style="margin: 2px 0; counter-increment: item;">
      ${content}
      ${items.length ? `
        <${list} style="counter-reset: item; ${list === 'ol' ? 'list-style: none;' : ''}">
          ${(items || []).map((item, index) => drawItem(item)).join('')}
        </${list}>
      ` : ''}
    </li>
  `

  return `
    ${list === 'ol' ? `<style>li#${block.id}:before { content: counters(item, ".") ". ";}</style>` : ''}
    <${list} style="counter-reset: item; ${list === 'ol' ? 'list-style: none;' : ''} ${getTunes(block)}">
      ${(block.data.items || []).map((item, index) => drawItem(item)).join('')}
    </${list}>
  `
}

const parseChecklist = block => {
  return `
    <div style="padding: 0.4em 0; ${getTunes(block)}">
      ${(block.data.items || []).map(item => `
        <div style="display: flex; flex-direction: row; align-items: center;">
          <div
            style="
              flex-shrink: 0;
              position: relative;
              width: 20px;
              height: 20px;
              margin: 5px;
              margin-left: 0;
              margin-right: 7px;
              border-radius: 50%;
              border: 1px solid #d0d0d0;
              background: #fff;
              ${item.checked ? 'background: #388ae5; border-color: #388ae5;' : ''}
            "
          >
            <div
              style="
                position: absolute;
                top: 6px;
                left: 5px;
                width: 9px;
                height: 4px;
                border: 2px solid #fff;
                border-top: none;
                border-right: none;
                background: transparent;
                opacity: ${item.checked ? '1' : '0'};
                transform: rotate(-45deg)
              "
            ></div>
          </div>
          ${item.text}
        </div>
      `).join('')}
    </div>
  `
}

const parseRaw = block => {
  return `
    <div style="padding: 0.4em 0; ${getTunes(block)}">
      ${block.data.html}
    </div>
  `
}

const parseQuote = block => {
  return `
    <div style="padding: 0.4em; text-align: ${block.data.alignment} ${getTunes(block)}">
      <div style="padding: 10px; border-left: 1px solid #dbdbe2;">
        ${block.data.text}
        <div style="font-size: 0.9em; color: #dbdbe2; text-align: right">${block.data.caption}</div>
      </div>
    </div>
  `
}

const parseDelimiter = block => {
  return `
    <div style="padding: 0.4em 0; ${getTunes(block)}">
      <div
        style="
          font-size: 30px;
          height: 30px;
          text-align: center;
          letter-spacing: 0.2em;
        "
      >
        ***
      </div>
    </div>
  `
}

const parseLink = block => {
  return `
    <div style="padding: 0.4em 0; ${getTunes(block)}">
      <a
        href="${block.data.link}"
        style="
          display: flex;
          flex-direction: row;
          background: #fff;
          border: 1px solid rgba(201, 201, 204, 0.48);
          box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
          border-radius: 6px;
          outline: none;
          padding: 25px;
          text-decoration: none !important;
          color: initial !important;
        "
      >
        ${block.data.meta.image.url ? `<img style="width: 150px; object-fit: contain; margin-right: 10px;" src="${block.data.meta.image.url}"/>` : ''}

        <div>
          ${block.data.meta.title ? `<div style="font-size: 17px; font-weight: 600; line-height: 1.5em; margin: 0 0 10px 0;">${block.data.meta.title}</div>` : ''}
          ${block.data.meta.description ? `<div style="margin: 0 0 20px 0; font-size: 15px; line-height: 1.55em; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;">${block.data.meta.description}</div>` : ''}
          ${block.data.link ? `<div style="display: block; font-size: 15px; line-height: 1em; color: #888 !important; border: 0 !important; padding: 0 !important;">${block.data.link}</div>` : ''}
        </div>
      </a>
    </div>
  `
}

const processors = {
  'paragraph': parseParagraph,
  'header': parseHeader,
  'table': parseTable,
  'list': parseList,
  'checklist': parseChecklist,
  'raw': parseRaw,
  'quote': parseQuote,
  'delimiter': parseDelimiter,
  'linkTool': parseLink
}

export const asHtml = data => {
  const blocks = (data.blocks || [])
    .map(block => {
      return processors[block.type] ? processors[block.type](block) : `<div>${JSON.stringify(block)}</div>`
    })
    .join('')

  return `<div style="color: #4a4a4a; font-size: 13px; font-family: Roboto-Regular; letter-spacing: 0.3px;">${blocks}</div>`
}

export const asText = data => {
  if (!data || !Array.isArray(data.blocks)) {
    return ''
  }

  const parseList = items => {
    let body = []

    items.forEach(item => {
      body.push(item.content)

      if (item.items.length) {
        body = body.concat(parseList(item.items))
      }
    })

    return body
  }

  let text = []

  data.blocks.forEach(block => {
    switch (block.type) {
      case 'paragraph':
        text.push(block.data.text)
        break
      case 'header':
        text.push(block.data.text)
        break
      case 'table':
        block.data.content.forEach(row => {
          row.forEach(col => {
            text.push(col)
          })
        })
        break
      case 'list':
        text = text.concat(parseList(block.data.items))
        break
      case 'checklist':
        text = text.concat(block.data.items.map(item => item.text))
        break
      case 'raw':
        text.push(block.data.html)
        break
      case 'quote':
        text.push(block.data.text.replace('&nbsp;', ''))
        text.push(block.data.caption.replace('&nbsp;', ''))
        break
      case 'embded':
      case 'delimiter':
      case 'image':
        break
      default:
        console.log('Error: Note. Notes App. Unknown block type', block.type)
    }
  })

  return text.join(' ')
}

export const fromText = (text: string): string => {
  return JSON.stringify({
    blocks: [{
      id: 0,
      type: 'paragraph',
      data: { text }
    }]
  });
};
