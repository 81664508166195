import { Pipe, PipeTransform } from "@angular/core";
import { Reminder, ReminderFormGroup } from "../types/reminder";
import { FormArray } from "@angular/forms";

@Pipe({
  name: 'activeRemindersOption'
})
export class ActiveRemindersOptionPipe implements PipeTransform {
  transform(reminders: Reminder[], control: FormArray<ReminderFormGroup>): boolean {
    if (!reminders.length) {
      if (!control.controls.length) { return true; }
      return false;
    }

    return reminders.every(optionReminder => control.controls.some(reminder => (
      optionReminder.duration === reminder.value.duration &&
      optionReminder.units === reminder.value.units &&
      optionReminder.reminderType === (reminder.value.dateType ? 'beforeStart' : 'beforeEnd')
    )));
  }
}
