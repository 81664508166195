// Common
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-years-context-menu',
  templateUrl: './years-context-menu.component.html',
  styleUrls: ['./years-context-menu.component.less']
})
export class YearsContextMenuComponent implements OnInit, OnChanges {

  // Public
  public years: number[];
  public selectedYear: number;
  public upEnabled = true;

  // Private
  private yearsShift = 0;
  private baseYear: number;

  // Inputs
  @Input() date: Date = new Date();
  @Input() max: number;

  // Outputs
  @Output() select = new EventEmitter<Date>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.baseYear = (new Date()).getFullYear();

    if (this.max && this.baseYear > this.max) {
      this.baseYear = this.max;
    }

    this.calculateSelectedYear(); // ngOnChanges is not triggered first time

    this.yearsShift = Math.ceil((this.selectedYear - this.baseYear) / 10);
    this.generateYearsList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('date' in changes) {
      this.calculateSelectedYear();
    }
  }

  /**
   * Actions
   */

  calculateSelectedYear() {
    const inputYear = (this.date || new Date()).getFullYear();
    this.selectedYear = this.max && inputYear > this.max ? this.max : inputYear;
  }

  handleYearClick(year: number): void {
    this.select.emit(new Date(
      year,
      this.date.getMonth(),
      this.date.getDate()
    ));
  }

  handleChangeViewport(direction: 1 | -1) {
    if (direction === 1 && !this.upEnabled) { return; }

    this.yearsShift += direction;
    this.generateYearsList();
  }

  generateYearsList() {
    if (!this.date) { return; }

    const viewportOffset = this.baseYear + this.yearsShift * 10;

    this.years = [...Array(12).keys()].map(index => viewportOffset - 10 + index);

    this.upEnabled = !(this.max && viewportOffset >= this.max);
  }
}
