// Common
import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Types
import { AlertConfig, AlertInputConfig } from '@modules/alert/types/config';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  styleUrls: ['./alert.component.less']
})
export class AlertComponent implements OnInit, AfterViewInit {
  public visible = false;
  public inputs: AlertInputConfig[] = [];
  public nestedClassName: string[] = [];
  public outsideClickExceptSelectors: string;

  private uniqClassName = 'a' + Math.random().toString(36).slice(-5);

  @Input() config: AlertConfig;
  @Input() parentNestedSelectors: string;
  @Input() allowedOutsideSelectorsClick: string;

  @Output() close = new EventEmitter<void>();

  @ViewChild('popoverContainer') popoverContainer: ElementRef;

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.inputs = (this.config?.inputs || []).map(input => ({
      ...input,
      control: input.control || new UntypedFormControl()
    }))
  }

  ngAfterViewInit() {
    this.nestedClassName = this.parentNestedSelectors
      ? [...this.parentNestedSelectors.split(','), this.uniqClassName]
      : [this.uniqClassName];

    this.outsideClickExceptSelectors = [
      `.${this.uniqClassName}`,
      this.allowedOutsideSelectorsClick
    ]
      .filter(i => !!i)
      .join(', ');

    this.popoverContainer.nativeElement.dataset.nestedSelectors = this.nestedClassName.join(',');

    this.nestedClassName.forEach(token => {
      this.popoverContainer.nativeElement.classList.add(token);
    });
  }

  /**
   * Actions
   */

  hide(): void {
    this.close.emit();
  }

  handleButtonClick(buttonsGroup: 'right' | 'left', index: number) {
    const button = (buttonsGroup === 'right' ? this.config.rightButtons : this.config.leftButtons)?.[index];

    const data = this.inputs.reduce((memo, input) => ({ ...memo, [input.name]: input.control.value }), {});

    button?.click?.(data);
    button?.close && this.hide();
  }
}
