// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Services
import { MailAppStateService } from '@modules/messages/services/state.service';

// Types
import { StitchType } from '@modules/common/types/stitch-type';
import { Message } from '@modules/messages/types/message';
import { MessageFolder } from '@modules/messages/types/message-folder';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-messages2-main-view',
  templateUrl: './messages-main-view.component.html',
  styleUrls: ['./messages-main-view.component.less'],
})
export class MessagesMainViewComponent implements OnInit, OnDestroy {

  // Public
  public StitchType = StitchType;
  public item: Message | MessageFolder;

  // Private
  private alive: Subject<void> = new Subject();

  constructor (
    private messagesStateService: MailAppStateService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.messagesStateService.getMainView()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((item) => {
        this.item = item;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleFormClose() {
    this.messagesStateService.setMainView(null);
  }
}
