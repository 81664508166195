// Common
import { Pipe, PipeTransform } from '@angular/core';
import { addDays } from '@modules/common/utils/date';

// Types
import { Next7Days } from '@modules/common/types/next-7-days';

@Pipe({
  name: 'dateByDayIndex'
})
export class DateByDayIndexPipe implements PipeTransform {

  transform(value: Next7Days | 'today' | 'week'): Date {
    const today = new Date();

    switch (value) {
      case 'today':
        return new Date(today);
      case 'week':
        return addDays(today, 1);
      default:
        const amount = parseInt(value.replace('day', ''), 10) + 1;
        return addDays(today, amount);
    }
  }
}
