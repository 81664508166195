// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Services
import { TaskingAppStateService } from '@modules/tasks/services/state.service';

// Types
import { Task } from '@modules/tasks/types/task';
import { Project } from '@modules/tasks/types/project';
import { StitchType } from '@modules/common/types/stitch-type';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tasks-main-view',
  templateUrl: './tasks-main-view.component.html',
  styleUrls: ['./tasks-main-view.component.less'],
})
export class TasksMainViewComponent implements OnInit, OnDestroy {

  public item: Task | Project;
  public StitchType = StitchType;

  private alive: Subject<void> = new Subject();

  constructor(
    private tasksStateService: TaskingAppStateService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.tasksStateService.getMainView()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((item) => {
        this.item = item;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleFormClose() {
    this.tasksStateService.setMainView(null);
  }
}
