<ng-container [ngSwitch]="currentApp">
  <div
    *ngSwitchCase="'mail'"
    class="forms"
  >
    <div class="forms-item">
      <div class="forms-item-label">
        From:
      </div>

      <app-autocomplete-multiple-input
        class="forms-item-input"
        placeholder="Enter Name"
        valueKey="address"
        [suggestions]="contactsSuggestions"
        [inputFormControl]="mailForm.controls.from"
        [itemTemplate]="contactTemplate"
        icon="contact-small"
        iconColor="#409aff"
        [withClear]="true"
      ></app-autocomplete-multiple-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        To:
      </div>

      <app-autocomplete-multiple-input
        class="forms-item-input"
        placeholder="Enter Name"
        valueKey="address"
        [suggestions]="contactsSuggestions"
        [inputFormControl]="mailForm.controls.to"
        [itemTemplate]="contactTemplate"
        icon="contact-small"
        iconColor="#409aff"
        [withClear]="true"
      ></app-autocomplete-multiple-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        CC:
      </div>

      <app-autocomplete-multiple-input
        class="forms-item-input"
        placeholder="Enter Name"
        valueKey="address"
        [suggestions]="contactsSuggestions"
        [inputFormControl]="mailForm.controls.cc"
        [itemTemplate]="contactTemplate"
        icon="contact-small"
        iconColor="#409aff"
        [withClear]="true"
      ></app-autocomplete-multiple-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        BCC:
      </div>

      <app-autocomplete-multiple-input
        class="forms-item-input"
        placeholder="Enter Name"
        valueKey="address"
        [suggestions]="contactsSuggestions"
        [inputFormControl]="mailForm.controls.bcc"
        [itemTemplate]="contactTemplate"
        icon="contact-small"
        iconColor="#409aff"
        [withClear]="true"
      ></app-autocomplete-multiple-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Subject has words:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="mailForm.controls.subject"
        placeholder="Enter Subject"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Body has words:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="mailForm.controls.body"
        placeholder="Enter Body"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Date received:
      </div>

      <app-date-input
        class="forms-item-input"
        [inputFormControl]="mailForm.controls.dateReceived"
        placeholder="Select Date"
        [withClear]="true"
      ></app-date-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Date sent:
      </div>

      <app-date-input
        class="forms-item-input"
        [inputFormControl]="mailForm.controls.dateSent"
        placeholder="Select Date"
        [withClear]="true"
      ></app-date-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Date range:
      </div>

      <div class="forms-item-combined">
        <app-date-input
          class="forms-item-input half"
          [inputFormControl]="mailForm.controls.dateRangeFrom"
          placeholder="Select Date"
          [withClear]="true"
          [maxDate]="mailForm.controls.dateRangeTo.value"
        ></app-date-input>
        <span class="forms-item-label">to</span>
        <app-date-input
          class="forms-item-input half"
          [inputFormControl]="mailForm.controls.dateRangeTo"
          placeholder="Select Date"
          [withClear]="true"
          [minDate]="mailForm.controls.dateRangeFrom.value"
        ></app-date-input>
      </div>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Size:
      </div>

      <app-dropdown-input
        class="forms-item-input"
        [inputFormControl]="mailForm.controls.size"
        placeholder="Select Size"
        [withClear]="true"
        [options]="filesSizeOptions"
        optionUniqKey="from,to"
      ></app-dropdown-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Stitched With:
      </div>

      <app-dropdown-multiple-input
        class="forms-item-input"
        placeholder="Select items"
        [options]="stitchedOptions"
        [inputFormControl]="mailForm.controls.stitched"
        iconColor="#409aff"
        [withClear]="true"
      ></app-dropdown-multiple-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Has Attachments:
      </div>

      <app-radio-button
        class="forms-item-input-checkbox"
        appearance="checkbox"
        [booleanValue]="true"
        title="Has Attachments"
        [inputFormControl]="mailForm.controls.hasAttachments"
      ></app-radio-button>
    </div>
  </div>

  <div
    *ngSwitchCase="'calendar'"
    class="forms"
  >
    <div class="forms-item">
      <div class="forms-item-label">
        Search In:
      </div>

      <app-autocomplete-multiple-input
        class="forms-item-input"
        placeholder="Select Calendars"
        [suggestions]="calendarsSuggestions"
        [inputFormControl]="calendarForm.controls.containersIds"
        icon="event"
        iconColor="#409aff"
        [withClear]="true"
      ></app-autocomplete-multiple-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Subject:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="calendarForm.controls.subject"
        placeholder="Enter Subject"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Time Zone:
      </div>

      <app-timezone-input
        class="forms-item-input"
        [control]="calendarForm.controls.timeZone"
        placeholder="Select Time Zone"
        [withClear]="true"
      ></app-timezone-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Location:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="calendarForm.controls.location"
        placeholder="Enter Location"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Body has words:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="calendarForm.controls.body"
        placeholder="Enter Words"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Start Date:
      </div>

      <app-date-input
        class="forms-item-input"
        [inputFormControl]="calendarForm.controls.fromDate"
        placeholder="Select Date"
        [withClear]="true"
      ></app-date-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        End Date:
      </div>

      <app-date-input
        class="forms-item-input"
        [inputFormControl]="calendarForm.controls.toDate"
        placeholder="Select Date"
        [withClear]="true"
      ></app-date-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Date Range:
      </div>

      <div class="forms-item-combined">
        <app-date-input
          class="forms-item-input half"
          [inputFormControl]="calendarForm.controls.dateRangeFrom"
          placeholder="Select Date"
          [withClear]="true"
        ></app-date-input>
        <span class="forms-item-label">to</span>
        <app-date-input
          class="forms-item-input half"
          [inputFormControl]="calendarForm.controls.dateRangeTo"
          placeholder="Select Date"
          [withClear]="true"
        ></app-date-input>
      </div>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Attendees:
      </div>

      <app-autocomplete-multiple-input
        class="forms-item-input"
        placeholder="Add Attendees"
        valueKey="address"
        [suggestions]="contactsSuggestions"
        [inputFormControl]="calendarForm.controls.attendees"
        [itemTemplate]="contactTemplate"
        icon="contact-small"
        iconColor="#409aff"
        [withClear]="true"
      ></app-autocomplete-multiple-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Stitched With:
      </div>

      <app-dropdown-multiple-input
        class="forms-item-input"
        placeholder="Select items"
        [options]="stitchedOptions"
        [inputFormControl]="calendarForm.controls.stitched"
        iconColor="#409aff"
        [withClear]="true"
      ></app-dropdown-multiple-input>
    </div>
  </div>

  <div
    *ngSwitchCase="'tasking'"
    class="forms"
  >
    <div class="forms-item">
      <div class="forms-item-label">
        Title:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="taskingForm.controls.title"
        placeholder="Enter Title"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Projects:
      </div>

      <app-autocomplete-multiple-input
        class="forms-item-input"
        placeholder="Select Projects"
        [suggestions]="projectsSuggestions"
        [inputFormControl]="taskingForm.controls.containersIds"
        icon="project"
        iconColor="#409aff"
        [withClear]="true"
      ></app-autocomplete-multiple-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Priority:
      </div>

      <app-dropdown-input
        class="forms-item-input"
        [inputFormControl]="taskingForm.controls.priority"
        placeholder="Select Priority"
        [withClear]="true"
        [options]="priorityOptions"
      ></app-dropdown-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Description:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="taskingForm.controls.description"
        placeholder="Enter Words"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Start date:
      </div>

      <app-date-input
        class="forms-item-input"
        [inputFormControl]="taskingForm.controls.startDate"
        placeholder="Select Date"
        [withClear]="true"
      ></app-date-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Due Date:
      </div>

      <app-date-input
        class="forms-item-input"
        [inputFormControl]="taskingForm.controls.dueDate"
        placeholder="Select Date"
        [withClear]="true"
      ></app-date-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Date Range:
      </div>

      <div class="forms-item-combined">
        <app-date-input
          class="forms-item-input half"
          [inputFormControl]="taskingForm.controls.dateRangeFrom"
          placeholder="Select Date"
          [withClear]="true"
          [maxDate]="taskingForm.controls.dateRangeTo.value"
        ></app-date-input>
        <span class="forms-item-label">to</span>
        <app-date-input
          class="forms-item-input half"
          [inputFormControl]="taskingForm.controls.dateRangeTo"
          placeholder="Select Date"
          [withClear]="true"
          [minDate]="taskingForm.controls.dateRangeFrom.value"
        ></app-date-input>
      </div>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Stitched With:
      </div>

      <app-dropdown-multiple-input
        class="forms-item-input"
        placeholder="Select items"
        [options]="stitchedOptions"
        [inputFormControl]="taskingForm.controls.stitched"
        iconColor="#409aff"
        [withClear]="true"
      ></app-dropdown-multiple-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        &nbsp;
      </div>

      <div class="forms-item-combined start">
        <app-radio-button
          class="forms-item-input"
          title="Completed"
          appearance="checkbox"
          [inputFormControl]="taskingForm.controls.completed"
          [booleanValue]="true"
        ></app-radio-button>

        <app-radio-button
          class="forms-item-input"
          title="Overdue"
          appearance="checkbox"
          [inputFormControl]="taskingForm.controls.overdue"
          [booleanValue]="true"
        ></app-radio-button>
      </div>
    </div>
  </div>

  <div
    *ngSwitchCase="'notes'"
    class="forms"
  >
    <div class="forms-item">
      <div class="forms-item-label">
        Title:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="notesForm.controls.title"
        placeholder="Enter Title"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Notebooks:
      </div>

      <app-autocomplete-multiple-input
        class="forms-item-input"
        placeholder="Select Notebooks"
        [suggestions]="notebooksSuggestions"
        [inputFormControl]="notesForm.controls.containersIds"
        icon="notebook"
        iconColor="#409aff"
        [withClear]="true"
      ></app-autocomplete-multiple-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Body Has Words:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="notesForm.controls.body"
        placeholder="Enter Words"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Description:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="notesForm.controls.description"
        placeholder="Enter Words"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Date Created:
      </div>

      <app-date-input
        class="forms-item-input"
        [inputFormControl]="notesForm.controls.createdAt"
        placeholder="Select Date"
        [withClear]="true"
      ></app-date-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Date Modified:
      </div>

      <app-date-input
        class="forms-item-input"
        [inputFormControl]="notesForm.controls.updatedAt"
        placeholder="Select Date"
        [withClear]="true"
      ></app-date-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Date Range:
      </div>

      <div class="forms-item-combined">
        <app-date-input
          class="forms-item-input half"
          [inputFormControl]="notesForm.controls.dateRangeFrom"
          placeholder="Select Date"
          [withClear]="true"
          [maxDate]="notesForm.controls.dateRangeTo.value"
        ></app-date-input>
        <span class="forms-item-label">to</span>
        <app-date-input
          class="forms-item-input half"
          [inputFormControl]="notesForm.controls.dateRangeTo"
          placeholder="Select Date"
          [withClear]="true"
          [minDate]="notesForm.controls.dateRangeFrom.value"
        ></app-date-input>
      </div>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Stitched With:
      </div>

      <app-dropdown-multiple-input
        class="forms-item-input"
        placeholder="Select items"
        [options]="stitchedOptions"
        [inputFormControl]="notesForm.controls.stitched"
        iconColor="#409aff"
        [withClear]="true"
      ></app-dropdown-multiple-input>
    </div>
  </div>

  <div
    *ngSwitchCase="'contacts'"
    class="forms"
  >
    <div class="forms-item">
      <div class="forms-item-label">
        Name:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="contactsForm.controls.name"
        placeholder="Enter Name Contact or Group"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Groups:
      </div>

      <app-autocomplete-multiple-input
        class="forms-item-input"
        placeholder="Select Groups"
        [suggestions]="groupsSuggestions"
        [inputFormControl]="contactsForm.controls.containersIds"
        icon="groups"
        iconColor="#409aff"
        [withClear]="true"
      ></app-autocomplete-multiple-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Job Title:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="contactsForm.controls.jobTitle"
        placeholder="Enter Job Title"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Description:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="contactsForm.controls.description"
        placeholder="Enter Words"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Birth Date:
      </div>

      <app-date-input
        class="forms-item-input"
        [inputFormControl]="contactsForm.controls.birthDate"
        placeholder="Select Date"
        [withClear]="true"
      ></app-date-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Company:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="contactsForm.controls.company"
        placeholder="Enter Words"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Country:
      </div>

      <app-country-picker
        class="forms-item-input"
        [inputFormControl]="contactsForm.controls.country"
        placeholder="Select country"
        [withClear]="true"
      ></app-country-picker>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Phone:
      </div>

      <app-input-side-options
        class="forms-item-input"
        [sideFormControl]="contactsForm.controls.phone.controls.category"
        [sideOptions]="contactInfoTypes"
      >
        <app-phone-number-input
          [inputFormControl]="contactsForm.controls.phone.controls.value"
        ></app-phone-number-input>
      </app-input-side-options>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Email:
      </div>

      <app-input-side-options
        class="forms-item-input"
        [sideFormControl]="contactsForm.controls.email.controls.category"
        [sideOptions]="contactInfoTypes"
      >
        <app-input
          class="forms-item-input"
          [inputFormControl]="contactsForm.controls.email.controls.value"
          placeholder="Enter Email"
        ></app-input>
      </app-input-side-options>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Stitched With:
      </div>

      <app-dropdown-multiple-input
        class="forms-item-input"
        placeholder="Select items"
        [options]="stitchedOptions"
        [inputFormControl]="contactsForm.controls.stitched"
        iconColor="#409aff"
        [withClear]="true"
      ></app-dropdown-multiple-input>
    </div>
  </div>

  <div
    *ngSwitchCase="'files'"
    class="forms"
  >
    <div class="forms-item">
      <div class="forms-item-label">
        Title:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="filesForm.controls.title"
        placeholder="Enter Title"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Folders:
      </div>

      <app-autocomplete-multiple-input
        class="forms-item-input"
        placeholder="Select Folders"
        [suggestions]="foldersSuggestions"
        [inputFormControl]="filesForm.controls.containersIds"
        icon="folder"
        iconColor="#409aff"
        [withClear]="true"
      ></app-autocomplete-multiple-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        File Type:
      </div>

      <app-dropdown-multiple-input
        class="forms-item-input"
        placeholder="Select Type"
        [options]="filesTypeOptions"
        [inputFormControl]="filesForm.controls.fileType"
        [withClear]="true"
      ></app-dropdown-multiple-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Description:
      </div>

      <app-input
        class="forms-item-input"
        [inputFormControl]="filesForm.controls.description"
        placeholder="Enter Words"
        [withClear]="true"
      ></app-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Received Date:
      </div>

      <app-date-input
        class="forms-item-input"
        [inputFormControl]="filesForm.controls.receivedDate"
        placeholder="Select Date"
        [withClear]="true"
      ></app-date-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Upload Date:
      </div>

      <app-date-input
        class="forms-item-input"
        [inputFormControl]="filesForm.controls.uploadDate"
        placeholder="Select Date"
        [withClear]="true"
      ></app-date-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Opened Date:
      </div>

      <app-date-input
        class="forms-item-input"
        [inputFormControl]="filesForm.controls.openedDate"
        placeholder="Select Date"
        [withClear]="true"
      ></app-date-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        File Size:
      </div>

      <app-dropdown-input
        class="forms-item-input"
        [inputFormControl]="filesForm.controls.fileSize"
        placeholder="Select Size"
        [withClear]="true"
        [options]="filesSizeOptions"
        optionUniqKey="from,to"
      ></app-dropdown-input>
    </div>

    <div class="forms-item">
      <div class="forms-item-label">
        Stitched With:
      </div>

      <app-dropdown-multiple-input
        class="forms-item-input"
        placeholder="Select items"
        [options]="stitchedOptions"
        [inputFormControl]="filesForm.controls.stitched"
        iconColor="#409aff"
        [withClear]="true"
      ></app-dropdown-multiple-input>
    </div>
  </div>
</ng-container>

<ng-template #contactTemplate let-contact="item">
  <app-link-info-contact
    [contact]="contact"
    viewType="short"
    [withDraggable]="false"
    [withContextMenu]="false"
    [withActions]="false"
    [withUnlink]="false"
  ></app-link-info-contact>
</ng-template>
