<div
  class="tag"
  [class.drag-placeholder]="!dragEnabled"
  [class.selected]="selected"
  [class.pinned]="tag?.pinned"
  [class.with-remove]="withRemove"

  [appTagContextMenu]="tag"
  [appTagContextMenuDisabled]="!withControls"
  [appTagContextMenuWithDetach]="withDetach"
  (appTagContextMenuPinned)="handlePin()"
  (appTagContextMenuDelete)="handleDelete()"

  appDraggable
  [appDraggableDisabled]="!dragEnabled"
  [appDraggableData]="{data: [tag], type: 'tag'}"
  [appDraggablePlaceholder]="dragPlaceholder"
  [appDraggableContainerAdjustFit]="true"
  [appDraggableContainerStyles]="{'border-radius': '20px', 'overflow': 'hidden'}"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <mat-icon
    *ngIf="tag?.pinned"
    class="tag-pin-icon"
    svgIcon="sapphire-pin"
  ></mat-icon>

  <div class="tag-name">#{{ tag?.name }}</div>

  <mat-icon
    *ngIf="withRemove"
    class="tag-remove-icon"
    svgIcon="sapphire-close-small"
    (click)="onRemove.emit()"
  ></mat-icon>
</div>

<ng-template #dragPlaceholder>
  <app-tag [tag]="tag" [dragEnabled]="false"> </app-tag>
</ng-template>
