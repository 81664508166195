// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// Utils
import { relativeDateToDate } from '@modules/common/utils/date';

// Types
import { Project } from '@modules/tasks/types/project';
import { RelativeDate } from '@modules/common/types/relative-date';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Services
import { ProjectsService } from '@modules/tasks/services/projects.service';

// Components
import { BaseQuickFormComponent } from '../base-quick-form/base-quick-form.component';

@Component({
  selector: 'app-project-quick-form',
  templateUrl: './project-quick-form.component.html',
  styleUrls: ['./project-quick-form.component.less'],
})
export class ProjectQuickFormComponent extends BaseQuickFormComponent implements OnChanges {

  // Inputs
  @Input() project: Project = new Project();

  // Outputs
  @Output() more: EventEmitter<Project> = new EventEmitter<Project>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<Project> = new EventEmitter();

  // Public
  public dateButtonSelected: 'from' | 'to' = 'from';
  public popoverClose = new Subject<void>();

  constructor (
    private projectsService: ProjectsService,
    public changeDetector: ChangeDetectorRef
  ) {
    super(changeDetector);
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('project' in changes) {
      this.reset();
    }
  }

  /**
   * Actions
   */

  setDate(range: RelativeDate): void {
    const date = relativeDateToDate(range);

    if (this.dateButtonSelected === 'from') {
      this.form.controls.fromDate.setValue(date);
    } else {
      this.form.controls.toDate.setValue(date);
    }
  }

  submit() {
    if (!this.form.valid) {
      return;
    }

    const project = Project.fromFormGroup(this.form);

    if (this.save.observers.length > 0) {
      this.save.emit(project);
      return;
    }

    this.saveInProgress = true;

    this.projectsService.create(project)
      .pipe(takeUntil(this.alive))
      .subscribe(
        projectInstance => {
          this.afterSave.emit(projectInstance);
          this.close.emit();
        },
        () => this.handleError()
      );
  }

  reset() {
    if (!this.project) { return; }
    this.form = this.project.asFormGroup();
    this.dateButtonSelected = 'from';
    super.reset();
  }

  clickMore() {
    this.more.emit(Project.fromFormGroup(this.form));
    super.clickMore();
  }
}
