// Common
import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RX
import { Observable } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Services
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';
import { StitchService } from '@modules/common/services/stitch.service';
import { NestedService } from '@modules/common/types/nested-service.interface';
import { BaseStitchService } from '@modules/common/services/base-stitch.service';

// Types
import { Like } from '@modules/common/types/like';
import { Group } from '../types/group';
import { GroupsFilters } from '../types/groups-filters';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { StitchType } from '@modules/common/types/stitch-type';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { BunchUpdateOptions } from '@modules/common/types/bunch-update-options';

// Env
import { environment } from '@environment';

// Decorators
import { warmUpObservable } from '@decorators';

@Injectable()
export class GroupsService extends BaseStitchService<Group, GroupsFilters> implements CalendarEventsService, NestedService {

  protected url = environment.baseUrl + '/api/contacts/groups/';
  protected stitchType = StitchType.group;
  protected filtersConstructor = GroupsFilters;

  constructor(
    protected http: HttpClient,
    @Optional() linkedInfoService: LinkedInfoService,
    protected toasterService: ToasterService,
    @Optional() knotsService: KnotsService,
    @Optional() tagsService: TagsService,
    @Optional() stitchService: StitchService,
  ) {
    super(http, toasterService, stitchService, tagsService, knotsService, linkedInfoService);
  }

  /**
   * Methods
   */

  public bunchUpdate(
    filters: Partial<GroupsFilters>,
    changes: BunchUpdateOptions & Partial<Pick<Group, 'parentId'>>,
    config: FeedbackConfig
  ): Observable<void> {
    return super.bunchUpdate(filters, changes, config);
  }

  create(
    groupInstance: Group,
    { emit, toast, message }: FeedbackConfig = { emit: true },
  ): Observable<Group> {
    return this.http.post<{ group: Group, success: boolean }>(
      `${environment.baseUrl}/api/contacts/groups`,
      groupInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (! success) { return; }
          emit && this.forceRefresh();
          toast && this.toasterService.show({ text: message || `Group created.` });
        }),
        map(({ group }) => new Group(group)),
        switchMap(group => this.processKnowledgeItems(group)),
        switchMap(group => this.processUploads(groupInstance.uploads, group, { other: true, parentFolderName: 'Contact App' })),
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  update(
    groupInstance: Group,
    { emitUpdate, displayToast, toastMessage }: FeedbackConfig = { emitUpdate: true }
  ): Observable<Group> {
    return this.http.put<{ group: Group, success: boolean }>(
      environment.baseUrl + '/api/contacts/groups/' + groupInstance.id, groupInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ group, success }) => {
          if (success) {
            emitUpdate && this.forceRefresh();
            displayToast && this.toasterService.show({ text: toastMessage || 'Group updated' });
          }
        }),
        map(({ group }) => group),
        catchError(error => this.handleObserverError(error))
      );
  }

  getCalendarEvents(fromTime: Date, toTime: Date): Observable<AngularCalendarEvent[]> {
    return this.search({ fromTime, toTime })
      .pipe(
        map(({ items }) => items.map(group => group.asAngularCalendarEvent()))
      );
  }

  getAutocompleteSuggestions(inputFilters: Like<GroupsFilters> = {}): AutocompleteFactory<Group> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      const filters: Like<GroupsFilters> = {
        limit: config?.limit || 5,
        archived: false,
        deleted: false,
        ...inputFilters
      };

      if (values?.length) {
        filters.ids = values;
      } else if (title?.trim()) {
        filters.query = title.trim();
      }

      return this.search(filters)
        .pipe(
          map((response: BaseSearchResponse<Group>) => response.items.map(group => ({
            title: group.title,
            value: group.id,
            source: group
          }))),
        );
    };
  }

  createInstance(item): Group {
    return new Group(item);
  }

  validateNested(id: string, parentId: string) {
    return this.http.get<{ deep: boolean, circular: boolean }>(
      `${ environment.baseUrl }/api/contacts/groups/validate-nesting/${ id }/${ parentId }`
    )
      .pipe(
        catchError(error => this.handleObserverError(error))
      );
  }
}
