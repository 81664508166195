// Common
import { ComponentRef, Directive, Injector, Input, OnChanges, OnDestroy, OnInit, Self, SimpleChanges, TemplateRef, ViewContainerRef } from "@angular/core";

// Components
import { DnDNestedItemsComponent } from "../components/dnd-nested-items/dnd-nested-items.component";

// Types
import { NestedDataItem } from "../types/nested-item";
import { DragData } from "../types/drag-data";

// Services
import { DNDListService } from "../services/dnd-list.service";

// RX
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive({
  selector: '[dndNestedFor]', 
  providers: [DNDListService]
})
export class DnDNestedForDirective implements OnInit, OnChanges, OnDestroy {
  @Input() dndNestedForOf: { id: string, parentId: string }[];
  @Input() dndNestedForStateKey: string;
  @Input() dndNestedForDropDisabled = false;
  @Input() dndNestedForPositionKey = 'position';
  @Input() dndNestedForMaxDepth: number;
  @Input() dndNestedForDroppableZIndex = 0;
  @Input() dndNestedForChanges: Subject<NestedDataItem[]>; // https://github.com/angular/angular/issues/12121
  @Input() dndNestedForPredicate: (dragData: DragData) => boolean;

  private componentRef: ComponentRef<DnDNestedItemsComponent>;
  private alive = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<DnDNestedItemsComponent>, 
    private viewContainerRef: ViewContainerRef,
    @Self() private dndListService: DNDListService,
    private injector: Injector,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.componentRef = this.viewContainerRef.createComponent(DnDNestedItemsComponent, { injector: this.injector });
    this.componentRef.instance.template = this.templateRef;
    this.componentRef.instance.droppableZIndex = this.dndNestedForDroppableZIndex;
    this.componentRef.instance.disabled = this.dndNestedForDropDisabled;
    this.componentRef.instance.predicate = this.dndNestedForPredicate;

    this.dndListService.getItems()
      .pipe(takeUntil(this.alive))
      .subscribe((items) => {
        this.componentRef.instance.tree = items;
        this.componentRef.instance.treeChanged.next();
      })

    this.dndListService.setMaxDepth(this.dndNestedForMaxDepth);
    this.dndListService.setStateKey(this.dndNestedForStateKey);
    this.dndListService.setPositionKey(this.dndNestedForPositionKey);

    this.setItems();

    this.dndListService.getChanges()
      .pipe(takeUntil(this.alive))
      .subscribe(changes => {
        this.dndNestedForChanges?.next(changes);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('dndNestedForOf' in changes) {
      this.setItems();
    }

    if ('nestedForStateKey' in changes) {
      this.dndListService.setStateKey(this.dndNestedForStateKey);
    }

    if ('dndNestedForPositionKey' in changes) {
      this.dndListService.setPositionKey(this.dndNestedForPositionKey);
    }

    if ('dndNestedForMaxDepth' in changes) {
      this.dndListService.setMaxDepth(this.dndNestedForMaxDepth);
    }

    if ('dndNestedForDroppableZIndex' in changes && this.componentRef) {
      this.componentRef.instance.droppableZIndex = this.dndNestedForDroppableZIndex;
    }

    if ('dndNestedForDropDisabled' in changes && this.componentRef) {
      this.componentRef.instance.disabled = this.dndNestedForDropDisabled;
    }
  }

  ngOnDestroy() {
    this.dndListService.detach();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  setItems() {
    this.dndListService.setItems(this.dndNestedForOf.map(item => ({
      id: item.id,
      parentId: item.parentId || null,
      data: item,
      position: item[this.dndNestedForPositionKey]
    })));
  }
}
