export const countries = [
  { iso2: 'AD', iso3: 'AND', phoneCode: '+376', name: 'Andorra', native: 'Andorra' },
  { iso2: 'AE', iso3: 'ARE', phoneCode: '+971', name: 'United Arab Emirates', native: 'دولة الإمارات العربية المتحدة' },
  { iso2: 'AF', iso3: 'AFG', phoneCode: '+93', name: 'Afghanistan', native: 'افغانستان' },
  { iso2: 'AG', iso3: 'ATG', phoneCode: '+1268', name: 'Antigua and Barbuda', native: 'Antigua and Barbuda' },
  { iso2: 'AI', iso3: 'AIA', phoneCode: '+1264', name: 'Anguilla', native: 'Anguilla' },
  { iso2: 'AL', iso3: 'ALB', phoneCode: '+355', name: 'Albania', native: 'Shqipëria' },
  { iso2: 'AM', iso3: 'ARM', phoneCode: '+374', name: 'Armenia', native: 'Հայաստան' },
  { iso2: 'AO', iso3: 'AGO', phoneCode: '+244', name: 'Angola', native: 'Angola' },
  { iso2: 'AQ', iso3: 'ATA', phoneCode: '+672', name: 'Antarctica', native: 'Antarctica' },
  { iso2: 'AR', iso3: 'ARG', phoneCode: '+54', name: 'Argentina', native: 'Argentina' },
  { iso2: 'AS', iso3: 'ASM', phoneCode: '+1684', name: 'American Samoa', native: 'American Samoa' },
  { iso2: 'AT', iso3: 'AUT', phoneCode: '+43', name: 'Austria', native: 'Österreich' },
  { iso2: 'AU', iso3: 'AUS', phoneCode: '+61', name: 'Australia', native: 'Australia' },
  { iso2: 'AW', iso3: 'ABW', phoneCode: '+297', name: 'Aruba', native: 'Aruba' },
  { iso2: 'AX', iso3: 'ALA', phoneCode: '+358', name: 'Åland', native: 'Åland' },
  { iso2: 'AZ', iso3: 'AZE', phoneCode: '+994', name: 'Azerbaijan', native: 'Azərbaycan' },
  { iso2: 'BA', iso3: 'BIH', phoneCode: '+387', name: 'Bosnia and Herzegovina', native: 'Bosna i Hercegovina' },
  { iso2: 'BB', iso3: 'BRB', phoneCode: '+1246', name: 'Barbados', native: 'Barbados' },
  { iso2: 'BD', iso3: 'BGD', phoneCode: '+880', name: 'Bangladesh', native: 'Bangladesh' },
  { iso2: 'BE', iso3: 'BEL', phoneCode: '+32', name: 'Belgium', native: 'België' },
  { iso2: 'BF', iso3: 'BFA', phoneCode: '+226', name: 'Burkina Faso', native: 'Burkina Faso' },
  { iso2: 'BG', iso3: 'BGR', phoneCode: '+359', name: 'Bulgaria', native: 'България' },
  { iso2: 'BH', iso3: 'BHR', phoneCode: '+973', name: 'Bahrain', native: '‏البحرين' },
  { iso2: 'BI', iso3: 'BDI', phoneCode: '+257', name: 'Burundi', native: 'Burundi' },
  { iso2: 'BJ', iso3: 'BEN', phoneCode: '+229', name: 'Benin', native: 'Bénin' },
  { iso2: 'BL', iso3: 'BLM', phoneCode: '+590', name: 'Saint Barthélemy', native: 'Saint-Barthélemy' },
  { iso2: 'BM', iso3: 'BMU', phoneCode: '+1441', name: 'Bermuda', native: 'Bermuda' },
  { iso2: 'BN', iso3: 'BRN', phoneCode: '+673', name: 'Brunei', native: 'Negara Brunei Darussalam' },
  { iso2: 'BO', iso3: 'BOL', phoneCode: '+591', name: 'Bolivia', native: 'Bolivia' },
  { iso2: 'BQ', iso3: 'BES', phoneCode: '+5997', name: 'Bonaire', native: 'Bonaire' },
  { iso2: 'BR', iso3: 'BRA', phoneCode: '+55', name: 'Brazil', native: 'Brasil' },
  { iso2: 'BS', iso3: 'BHS', phoneCode: '+1242', name: 'Bahamas', native: 'Bahamas' },
  { iso2: 'BT', iso3: 'BTN', phoneCode: '+975', name: 'Bhutan', native: 'ʼbrug-yul' },
  { iso2: 'BV', iso3: 'BVT', phoneCode: '+47', name: 'Bouvet Island', native: 'Bouvetøya' },
  { iso2: 'BW', iso3: 'BWA', phoneCode: '+267', name: 'Botswana', native: 'Botswana' },
  { iso2: 'BY', iso3: 'BLR', phoneCode: '+375', name: 'Belarus', native: 'Белару́сь' },
  { iso2: 'BZ', iso3: 'BLZ', phoneCode: '+501', name: 'Belize', native: 'Belize' },
  { iso2: 'CA', iso3: 'CAN', phoneCode: '+1', name: 'Canada', native: 'Canada' },
  { iso2: 'CC', iso3: 'CCK', phoneCode: '+61', name: 'Cocos [Keeling] Islands', native: 'Cocos (Keeling) Islands' },
  { iso2: 'CD', iso3: 'COD', phoneCode: '+243', name: 'Democratic Republic of the Congo', native: 'République démocratique du Congo' },
  { iso2: 'CF', iso3: 'CAF', phoneCode: '+236', name: 'Central African Republic', native: 'Ködörösêse tî Bêafrîka' },
  { iso2: 'CG', iso3: 'COG', phoneCode: '+242', name: 'Republic of the Congo', native: 'République du Congo' },
  { iso2: 'CH', iso3: 'CHE', phoneCode: '+41', name: 'Switzerland', native: 'Schweiz' },
  { iso2: 'CI', iso3: 'CIV', phoneCode: '+225', name: 'Ivory Coast', native: 'Côte d\'Ivoire' },
  { iso2: 'CK', iso3: 'COK', phoneCode: '+682', name: 'Cook Islands', native: 'Cook Islands' },
  { iso2: 'CL', iso3: 'CHL', phoneCode: '+56', name: 'Chile', native: 'Chile' },
  { iso2: 'CM', iso3: 'CMR', phoneCode: '+237', name: 'Cameroon', native: 'Cameroon' },
  { iso2: 'CN', iso3: 'CHN', phoneCode: '+86', name: 'China', native: '中国' },
  { iso2: 'CO', iso3: 'COL', phoneCode: '+57', name: 'Colombia', native: 'Colombia' },
  { iso2: 'CR', iso3: 'CRI', phoneCode: '+506', name: 'Costa Rica', native: 'Costa Rica' },
  { iso2: 'CU', iso3: 'CUB', phoneCode: '+53', name: 'Cuba', native: 'Cuba' },
  { iso2: 'CV', iso3: 'CPV', phoneCode: '+238', name: 'Cape Verde', native: 'Cabo Verde' },
  { iso2: 'CW', iso3: 'CUW', phoneCode: '+5999', name: 'Curacao', native: 'Curaçao' },
  { iso2: 'CX', iso3: 'CXR', phoneCode: '+61', name: 'Christmas Island', native: 'Christmas Island' },
  { iso2: 'CY', iso3: 'CYP', phoneCode: '+357', name: 'Cyprus', native: 'Κύπρος' },
  { iso2: 'CZ', iso3: 'CZE', phoneCode: '+420', name: 'Czech Republic', native: 'Česká republika' },
  { iso2: 'DE', iso3: 'DEU', phoneCode: '+49', name: 'Germany', native: 'Deutschland' },
  { iso2: 'DJ', iso3: 'DJI', phoneCode: '+253', name: 'Djibouti', native: 'Djibouti' },
  { iso2: 'DK', iso3: 'DNK', phoneCode: '+45', name: 'Denmark', native: 'Danmark' },
  { iso2: 'DM', iso3: 'DMA', phoneCode: '+1767', name: 'Dominica', native: 'Dominica' },
  { iso2: 'DO', iso3: 'DOM', phoneCode: '+1809', name: 'Dominican Republic', native: 'República Dominicana' },
  { iso2: 'DZ', iso3: 'DZA', phoneCode: '+213', name: 'Algeria', native: 'الجزائر' },
  { iso2: 'EC', iso3: 'ECU', phoneCode: '+593', name: 'Ecuador', native: 'Ecuador' },
  { iso2: 'EE', iso3: 'EST', phoneCode: '+372', name: 'Estonia', native: 'Eesti' },
  { iso2: 'EG', iso3: 'EGY', phoneCode: '+20', name: 'Egypt', native: 'مصر‎' },
  { iso2: 'EH', iso3: 'ESH', phoneCode: '+212', name: 'Western Sahara', native: 'الصحراء الغربية' },
  { iso2: 'ER', iso3: 'ERI', phoneCode: '+291', name: 'Eritrea', native: 'ኤርትራ' },
  { iso2: 'ES', iso3: 'ESP', phoneCode: '+34', name: 'Spain', native: 'España' },
  { iso2: 'ET', iso3: 'ETH', phoneCode: '+251', name: 'Ethiopia', native: 'ኢትዮጵያ' },
  { iso2: 'FI', iso3: 'FIN', phoneCode: '+358', name: 'Finland', native: 'Suomi' },
  { iso2: 'FJ', iso3: 'FJI', phoneCode: '+679', name: 'Fiji', native: 'Fiji' },
  { iso2: 'FK', iso3: 'FLK', phoneCode: '+500', name: 'Falkland Islands', native: 'Falkland Islands' },
  { iso2: 'FM', iso3: 'FSM', phoneCode: '+691', name: 'Micronesia', native: 'Micronesia' },
  { iso2: 'FO', iso3: 'FRO', phoneCode: '+298', name: 'Faroe Islands', native: 'Føroyar' },
  { iso2: 'FR', iso3: 'FRA', phoneCode: '+33', name: 'France', native: 'France' },
  { iso2: 'GA', iso3: 'GAB', phoneCode: '+241', name: 'Gabon', native: 'Gabon' },
  { iso2: 'GB', iso3: 'GBR', phoneCode: '+44', name: 'United Kingdom', native: 'United Kingdom' },
  { iso2: 'GD', iso3: 'GRD', phoneCode: '+1473', name: 'Grenada', native: 'Grenada' },
  { iso2: 'GE', iso3: 'GEO', phoneCode: '+995', name: 'Georgia', native: 'საქართველო' },
  { iso2: 'GF', iso3: 'GUF', phoneCode: '+594', name: 'French Guiana', native: 'Guyane française' },
  { iso2: 'GG', iso3: 'GGY', phoneCode: '+44', name: 'Guernsey', native: 'Guernsey' },
  { iso2: 'GH', iso3: 'GHA', phoneCode: '+233', name: 'Ghana', native: 'Ghana' },
  { iso2: 'GI', iso3: 'GIB', phoneCode: '+350', name: 'Gibraltar', native: 'Gibraltar' },
  { iso2: 'GL', iso3: 'GRL', phoneCode: '+299', name: 'Greenland', native: 'Kalaallit Nunaat' },
  { iso2: 'GM', iso3: 'GMB', phoneCode: '+220', name: 'Gambia', native: 'Gambia' },
  { iso2: 'GN', iso3: 'GIN', phoneCode: '+224', name: 'Guinea', native: 'Guinée' },
  { iso2: 'GP', iso3: 'GLP', phoneCode: '+590', name: 'Guadeloupe', native: 'Guadeloupe' },
  { iso2: 'GQ', iso3: 'GNQ', phoneCode: '+240', name: 'Equatorial Guinea', native: 'Guinea Ecuatorial' },
  { iso2: 'GR', iso3: 'GRC', phoneCode: '+30', name: 'Greece', native: 'Ελλάδα' },
  { iso2: 'GS', iso3: 'SGS', phoneCode: '+500', name: 'South Georgia and the South Sandwich Islands', native: 'South Georgia' },
  { iso2: 'GT', iso3: 'GTM', phoneCode: '+502', name: 'Guatemala', native: 'Guatemala' },
  { iso2: 'GU', iso3: 'GUM', phoneCode: '+1671', name: 'Guam', native: 'Guam' },
  { iso2: 'GW', iso3: 'GNB', phoneCode: '+245', name: 'Guinea-Bissau', native: 'Guiné-Bissau' },
  { iso2: 'GY', iso3: 'GUY', phoneCode: '+592', name: 'Guyana', native: 'Guyana' },
  { iso2: 'HK', iso3: 'HKG', phoneCode: '+852', name: 'Hong Kong', native: '香港' },
  { iso2: 'HM', iso3: 'HMD', phoneCode: '+61', name: 'Heard Island and McDonald Islands', native: 'Heard Island and McDonald Islands' },
  { iso2: 'HN', iso3: 'HND', phoneCode: '+504', name: 'Honduras', native: 'Honduras' },
  { iso2: 'HR', iso3: 'HRV', phoneCode: '+385', name: 'Croatia', native: 'Hrvatska' },
  { iso2: 'HT', iso3: 'HTI', phoneCode: '+509', name: 'Haiti', native: 'Haïti' },
  { iso2: 'HU', iso3: 'HUN', phoneCode: '+36', name: 'Hungary', native: 'Magyarország' },
  { iso2: 'ID', iso3: 'IDN', phoneCode: '+62', name: 'Indonesia', native: 'Indonesia' },
  { iso2: 'IE', iso3: 'IRL', phoneCode: '+353', name: 'Ireland', native: 'Éire' },
  { iso2: 'IL', iso3: 'ISR', phoneCode: '+972', name: 'Israel', native: 'יִשְׂרָאֵל' },
  { iso2: 'IM', iso3: 'IMN', phoneCode: '+44', name: 'Isle of Man', native: 'Isle of Man' },
  { iso2: 'IN', iso3: 'IND', phoneCode: '+91', name: 'India', native: 'भारत' },
  { iso2: 'IO', iso3: 'IOT', phoneCode: '+246', name: 'British Indian Ocean Territory', native: 'British Indian Ocean Territory' },
  { iso2: 'IQ', iso3: 'IRQ', phoneCode: '+964', name: 'Iraq', native: 'العراق' },
  { iso2: 'IR', iso3: 'IRN', phoneCode: '+98', name: 'Iran', native: 'ایران' },
  { iso2: 'IS', iso3: 'ISL', phoneCode: '+354', name: 'Iceland', native: 'Ísland' },
  { iso2: 'IT', iso3: 'ITA', phoneCode: '+39', name: 'Italy', native: 'Italia' },
  { iso2: 'JE', iso3: 'JEY', phoneCode: '+44', name: 'Jersey', native: 'Jersey' },
  { iso2: 'JM', iso3: 'JAM', phoneCode: '+1876', name: 'Jamaica', native: 'Jamaica' },
  { iso2: 'JO', iso3: 'JOR', phoneCode: '+962', name: 'Jordan', native: 'الأردن' },
  { iso2: 'JP', iso3: 'JPN', phoneCode: '+81', name: 'Japan', native: '日本' },
  { iso2: 'KE', iso3: 'KEN', phoneCode: '+254', name: 'Kenya', native: 'Kenya' },
  { iso2: 'KG', iso3: 'KGZ', phoneCode: '+996', name: 'Kyrgyzstan', native: 'Кыргызстан' },
  { iso2: 'KH', iso3: 'KHM', phoneCode: '+855', name: 'Cambodia', native: 'Kâmpŭchéa' },
  { iso2: 'KI', iso3: 'KIR', phoneCode: '+686', name: 'Kiribati', native: 'Kiribati' },
  { iso2: 'KM', iso3: 'COM', phoneCode: '+269', name: 'Comoros', native: 'Komori' },
  { iso2: 'KN', iso3: 'KNA', phoneCode: '+1869', name: 'Saint Kitts and Nevis', native: 'Saint Kitts and Nevis' },
  { iso2: 'KP', iso3: 'PRK', phoneCode: '+850', name: 'North Korea', native: '북한' },
  { iso2: 'KR', iso3: 'KOR', phoneCode: '+82', name: 'South Korea', native: '대한민국' },
  { iso2: 'KW', iso3: 'KWT', phoneCode: '+965', name: 'Kuwait', native: 'الكويت' },
  { iso2: 'KY', iso3: 'CYM', phoneCode: '+1345', name: 'Cayman Islands', native: 'Cayman Islands' },
  { iso2: 'KZ', iso3: 'KAZ', phoneCode: '+76', name: 'Kazakhstan', native: 'Қазақстан' },
  { iso2: 'LA', iso3: 'LAO', phoneCode: '+856', name: 'Laos', native: 'ສປປລາວ' },
  { iso2: 'LB', iso3: 'LBN', phoneCode: '+961', name: 'Lebanon', native: 'لبنان' },
  { iso2: 'LC', iso3: 'LCA', phoneCode: '+1758', name: 'Saint Lucia', native: 'Saint Lucia' },
  { iso2: 'LI', iso3: 'LIE', phoneCode: '+423', name: 'Liechtenstein', native: 'Liechtenstein' },
  { iso2: 'LK', iso3: 'LKA', phoneCode: '+94', name: 'Sri Lanka', native: 'śrī laṃkāva' },
  { iso2: 'LR', iso3: 'LBR', phoneCode: '+231', name: 'Liberia', native: 'Liberia' },
  { iso2: 'LS', iso3: 'LSO', phoneCode: '+266', name: 'Lesotho', native: 'Lesotho' },
  { iso2: 'LT', iso3: 'LTU', phoneCode: '+370', name: 'Lithuania', native: 'Lietuva' },
  { iso2: 'LU', iso3: 'LUX', phoneCode: '+352', name: 'Luxembourg', native: 'Luxembourg' },
  { iso2: 'LV', iso3: 'LVA', phoneCode: '+371', name: 'Latvia', native: 'Latvija' },
  { iso2: 'LY', iso3: 'LBY', phoneCode: '+218', name: 'Libya', native: '‏ليبيا' },
  { iso2: 'MA', iso3: 'MAR', phoneCode: '+212', name: 'Morocco', native: 'المغرب' },
  { iso2: 'MC', iso3: 'MCO', phoneCode: '+377', name: 'Monaco', native: 'Monaco' },
  { iso2: 'MD', iso3: 'MDA', phoneCode: '+373', name: 'Moldova', native: 'Moldova' },
  { iso2: 'ME', iso3: 'MNE', phoneCode: '+382', name: 'Montenegro', native: 'Црна Гора' },
  { iso2: 'MF', iso3: 'MAF', phoneCode: '+590', name: 'Saint Martin', native: 'Saint-Martin' },
  { iso2: 'MG', iso3: 'MDG', phoneCode: '+261', name: 'Madagascar', native: 'Madagasikara' },
  { iso2: 'MH', iso3: 'MHL', phoneCode: '+692', name: 'Marshall Islands', native: 'M̧ajeļ' },
  { iso2: 'MK', iso3: 'MKD', phoneCode: '+389', name: 'North Macedonia', native: 'Северна Македонија' },
  { iso2: 'ML', iso3: 'MLI', phoneCode: '+223', name: 'Mali', native: 'Mali' },
  { iso2: 'MM', iso3: 'MMR', phoneCode: '+95', name: 'Myanmar [Burma]', native: 'မြန်မာ' },
  { iso2: 'MN', iso3: 'MNG', phoneCode: '+976', name: 'Mongolia', native: 'Монгол улс' },
  { iso2: 'MO', iso3: 'MAC', phoneCode: '+853', name: 'Macao', native: '澳門' },
  { iso2: 'MP', iso3: 'MNP', phoneCode: '+1670', name: 'Northern Mariana Islands', native: 'Northern Mariana Islands' },
  { iso2: 'MQ', iso3: 'MTQ', phoneCode: '+596', name: 'Martinique', native: 'Martinique' },
  { iso2: 'MR', iso3: 'MRT', phoneCode: '+222', name: 'Mauritania', native: 'موريتانيا' },
  { iso2: 'MS', iso3: 'MSR', phoneCode: '+1664', name: 'Montserrat', native: 'Montserrat' },
  { iso2: 'MT', iso3: 'MLT', phoneCode: '+356', name: 'Malta', native: 'Malta' },
  { iso2: 'MU', iso3: 'MUS', phoneCode: '+230', name: 'Mauritius', native: 'Maurice' },
  { iso2: 'MV', iso3: 'MDV', phoneCode: '+960', name: 'Maldives', native: 'Maldives' },
  { iso2: 'MW', iso3: 'MWI', phoneCode: '+265', name: 'Malawi', native: 'Malawi' },
  { iso2: 'MX', iso3: 'MEX', phoneCode: '+52', name: 'Mexico', native: 'México' },
  { iso2: 'MY', iso3: 'MYS', phoneCode: '+60', name: 'Malaysia', native: 'Malaysia' },
  { iso2: 'MZ', iso3: 'MOZ', phoneCode: '+258', name: 'Mozambique', native: 'Moçambique' },
  { iso2: 'NA', iso3: 'NAM', phoneCode: '+264', name: 'Namibia', native: 'Namibia' },
  { iso2: 'NC', iso3: 'NCL', phoneCode: '+687', name: 'New Caledonia', native: 'Nouvelle-Calédonie' },
  { iso2: 'NE', iso3: 'NER', phoneCode: '+227', name: 'Niger', native: 'Niger' },
  { iso2: 'NF', iso3: 'NFK', phoneCode: '+672', name: 'Norfolk Island', native: 'Norfolk Island' },
  { iso2: 'NG', iso3: 'NGA', phoneCode: '+234', name: 'Nigeria', native: 'Nigeria' },
  { iso2: 'NI', iso3: 'NIC', phoneCode: '+505', name: 'Nicaragua', native: 'Nicaragua' },
  { iso2: 'NL', iso3: 'NLD', phoneCode: '+31', name: 'Netherlands', native: 'Nederland' },
  { iso2: 'NO', iso3: 'NOR', phoneCode: '+47', name: 'Norway', native: 'Norge' },
  { iso2: 'NP', iso3: 'NPL', phoneCode: '+977', name: 'Nepal', native: 'नपल' },
  { iso2: 'NR', iso3: 'NRU', phoneCode: '+674', name: 'Nauru', native: 'Nauru' },
  { iso2: 'NU', iso3: 'NIU', phoneCode: '+683', name: 'Niue', native: 'Niuē' },
  { iso2: 'NZ', iso3: 'NZL', phoneCode: '+64', name: 'New Zealand', native: 'New Zealand' },
  { iso2: 'OM', iso3: 'OMN', phoneCode: '+968', name: 'Oman', native: 'عمان' },
  { iso2: 'PA', iso3: 'PAN', phoneCode: '+507', name: 'Panama', native: 'Panamá' },
  { iso2: 'PE', iso3: 'PER', phoneCode: '+51', name: 'Peru', native: 'Perú' },
  { iso2: 'PF', iso3: 'PYF', phoneCode: '+689', name: 'French Polynesia', native: 'Polynésie française' },
  { iso2: 'PG', iso3: 'PNG', phoneCode: '+675', name: 'Papua New Guinea', native: 'Papua Niugini' },
  { iso2: 'PH', iso3: 'PHL', phoneCode: '+63', name: 'Philippines', native: 'Pilipinas' },
  { iso2: 'PK', iso3: 'PAK', phoneCode: '+92', name: 'Pakistan', native: 'Pakistan' },
  { iso2: 'PL', iso3: 'POL', phoneCode: '+48', name: 'Poland', native: 'Polska' },
  { iso2: 'PM', iso3: 'SPM', phoneCode: '+508', name: 'Saint Pierre and Miquelon', native: 'Saint-Pierre-et-Miquelon' },
  { iso2: 'PN', iso3: 'PCN', phoneCode: '+64', name: 'Pitcairn Islands', native: 'Pitcairn Islands' },
  { iso2: 'PR', iso3: 'PRI', phoneCode: '+1787', name: 'Puerto Rico', native: 'Puerto Rico' },
  { iso2: 'PS', iso3: 'PSE', phoneCode: '+970', name: 'Palestine', native: 'فلسطين' },
  { iso2: 'PT', iso3: 'PRT', phoneCode: '+351', name: 'Portugal', native: 'Portugal' },
  { iso2: 'PW', iso3: 'PLW', phoneCode: '+680', name: 'Palau', native: 'Palau' },
  { iso2: 'PY', iso3: 'PRY', phoneCode: '+595', name: 'Paraguay', native: 'Paraguay' },
  { iso2: 'QA', iso3: 'QAT', phoneCode: '+974', name: 'Qatar', native: 'قطر' },
  { iso2: 'RE', iso3: 'REU', phoneCode: '+262', name: 'Réunion', native: 'La Réunion' },
  { iso2: 'RO', iso3: 'ROU', phoneCode: '+40', name: 'Romania', native: 'România' },
  { iso2: 'RS', iso3: 'SRB', phoneCode: '+381', name: 'Serbia', native: 'Србија' },
  { iso2: 'RU', iso3: 'RUS', phoneCode: '+7', name: 'Russia', native: 'Россия' },
  { iso2: 'RW', iso3: 'RWA', phoneCode: '+250', name: 'Rwanda', native: 'Rwanda' },
  { iso2: 'SA', iso3: 'SAU', phoneCode: '+966', name: 'Saudi Arabia', native: 'العربية السعودية' },
  { iso2: 'SB', iso3: 'SLB', phoneCode: '+677', name: 'Solomon Islands', native: 'Solomon Islands' },
  { iso2: 'SC', iso3: 'SYC', phoneCode: '+248', name: 'Seychelles', native: 'Seychelles' },
  { iso2: 'SD', iso3: 'SDN', phoneCode: '+249', name: 'Sudan', native: 'السودان' },
  { iso2: 'SE', iso3: 'SWE', phoneCode: '+46', name: 'Sweden', native: 'Sverige' },
  { iso2: 'SG', iso3: 'SGP', phoneCode: '+65', name: 'Singapore', native: 'Singapore' },
  { iso2: 'SH', iso3: 'SHN', phoneCode: '+290', name: 'Saint Helena', native: 'Saint Helena' },
  { iso2: 'SI', iso3: 'SVN', phoneCode: '+386', name: 'Slovenia', native: 'Slovenija' },
  { iso2: 'SJ', iso3: 'SJM', phoneCode: '+4779', name: 'Svalbard and Jan Mayen', native: 'Svalbard og Jan Mayen' },
  { iso2: 'SK', iso3: 'SVK', phoneCode: '+421', name: 'Slovakia', native: 'Slovensko' },
  { iso2: 'SL', iso3: 'SLE', phoneCode: '+232', name: 'Sierra Leone', native: 'Sierra Leone' },
  { iso2: 'SM', iso3: 'SMR', phoneCode: '+378', name: 'San Marino', native: 'San Marino' },
  { iso2: 'SN', iso3: 'SEN', phoneCode: '+221', name: 'Senegal', native: 'Sénégal' },
  { iso2: 'SO', iso3: 'SOM', phoneCode: '+252', name: 'Somalia', native: 'Soomaaliya' },
  { iso2: 'SR', iso3: 'SUR', phoneCode: '+597', name: 'Suriname', native: 'Suriname' },
  { iso2: 'SS', iso3: 'SSD', phoneCode: '+211', name: 'South Sudan', native: 'South Sudan' },
  { iso2: 'ST', iso3: 'STP', phoneCode: '+239', name: 'São Tomé and Príncipe', native: 'São Tomé e Príncipe' },
  { iso2: 'SV', iso3: 'SLV', phoneCode: '+503', name: 'El Salvador', native: 'El Salvador' },
  { iso2: 'SX', iso3: 'SXM', phoneCode: '+1721', name: 'Sint Maarten', native: 'Sint Maarten' },
  { iso2: 'SY', iso3: 'SYR', phoneCode: '+963', name: 'Syria', native: 'سوريا' },
  { iso2: 'SZ', iso3: 'SWZ', phoneCode: '+268', name: 'Swaziland', native: 'Swaziland' },
  { iso2: 'TC', iso3: 'TCA', phoneCode: '+1649', name: 'Turks and Caicos Islands', native: 'Turks and Caicos Islands' },
  { iso2: 'TD', iso3: 'TCD', phoneCode: '+235', name: 'Chad', native: 'Tchad' },
  { iso2: 'TF', iso3: 'ATF', phoneCode: '+262', name: 'French Southern Territories', native: 'Territoire des Terres australes et antarctiques fr' },
  { iso2: 'TG', iso3: 'TGO', phoneCode: '+228', name: 'Togo', native: 'Togo' },
  { iso2: 'TH', iso3: 'THA', phoneCode: '+66', name: 'Thailand', native: 'ประเทศไทย' },
  { iso2: 'TJ', iso3: 'TJK', phoneCode: '+992', name: 'Tajikistan', native: 'Тоҷикистон' },
  { iso2: 'TK', iso3: 'TKL', phoneCode: '+690', name: 'Tokelau', native: 'Tokelau' },
  { iso2: 'TL', iso3: 'TLS', phoneCode: '+670', name: 'East Timor', native: 'Timor-Leste' },
  { iso2: 'TM', iso3: 'TKM', phoneCode: '+993', name: 'Turkmenistan', native: 'Türkmenistan' },
  { iso2: 'TN', iso3: 'TUN', phoneCode: '+216', name: 'Tunisia', native: 'تونس' },
  { iso2: 'TO', iso3: 'TON', phoneCode: '+676', name: 'Tonga', native: 'Tonga' },
  { iso2: 'TR', iso3: 'TUR', phoneCode: '+90', name: 'Turkey', native: 'Türkiye' },
  { iso2: 'TT', iso3: 'TTO', phoneCode: '+1868', name: 'Trinidad and Tobago', native: 'Trinidad and Tobago' },
  { iso2: 'TV', iso3: 'TUV', phoneCode: '+688', name: 'Tuvalu', native: 'Tuvalu' },
  { iso2: 'TW', iso3: 'TWN', phoneCode: '+886', name: 'Taiwan', native: '臺灣' },
  { iso2: 'TZ', iso3: 'TZA', phoneCode: '+255', name: 'Tanzania', native: 'Tanzania' },
  { iso2: 'UA', iso3: 'UKR', phoneCode: '+380', name: 'Ukraine', native: 'Україна' },
  { iso2: 'UG', iso3: 'UGA', phoneCode: '+256', name: 'Uganda', native: 'Uganda' },
  { iso2: 'UM', iso3: 'UMI', phoneCode: '+1', name: 'U.S. Minor Outlying Islands', native: 'United States Minor Outlying Islands' },
  { iso2: 'US', iso3: 'USA', phoneCode: '+1', name: 'United States', native: 'United States' },
  { iso2: 'UY', iso3: 'URY', phoneCode: '+598', name: 'Uruguay', native: 'Uruguay' },
  { iso2: 'UZ', iso3: 'UZB', phoneCode: '+998', name: 'Uzbekistan', native: 'O‘zbekiston' },
  { iso2: 'VA', iso3: 'VAT', phoneCode: '+379', name: 'Vatican City', native: 'Vaticano' },
  { iso2: 'VC', iso3: 'VCT', phoneCode: '+1784', name: 'Saint Vincent and the Grenadines', native: 'Saint Vincent and the Grenadines' },
  { iso2: 'VE', iso3: 'VEN', phoneCode: '+58', name: 'Venezuela', native: 'Venezuela' },
  { iso2: 'VG', iso3: 'VGB', phoneCode: '+1284', name: 'British Virgin Islands', native: 'British Virgin Islands' },
  { iso2: 'VI', iso3: 'VIR', phoneCode: '+1340', name: 'U.S. Virgin Islands', native: 'United States Virgin Islands' },
  { iso2: 'VN', iso3: 'VNM', phoneCode: '+84', name: 'Vietnam', native: 'Việt Nam' },
  { iso2: 'VU', iso3: 'VUT', phoneCode: '+678', name: 'Vanuatu', native: 'Vanuatu' },
  { iso2: 'WF', iso3: 'WLF', phoneCode: '+681', name: 'Wallis and Futuna', native: 'Wallis et Futuna' },
  { iso2: 'WS', iso3: 'WSM', phoneCode: '+685', name: 'Samoa', native: 'Samoa' },
  { iso2: 'XK', iso3: 'XKX', phoneCode: '+377', name: 'Kosovo', native: 'Republika e Kosovës' },
  { iso2: 'YE', iso3: 'YEM', phoneCode: '+967', name: 'Yemen', native: 'اليَمَن' },
  { iso2: 'YT', iso3: 'MYT', phoneCode: '+262', name: 'Mayotte', native: 'Mayotte' },
  { iso2: 'ZA', iso3: 'ZAF', phoneCode: '+27', name: 'South Africa', native: 'South Africa' },
  { iso2: 'ZM', iso3: 'ZMB', phoneCode: '+260', name: 'Zambia', native: 'Zambia' },
  { iso2: 'ZW', iso3: 'ZWE', phoneCode: '+263', name: 'Zimbabwe', native: 'Zimbabwe' },
] as const;

export type CountryType = typeof countries[number];
