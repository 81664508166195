// Common
import { Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }]});

export class PaymentMethod {
  formBuilder = injector.get(UntypedFormBuilder);

  id: number;
  type: 'card' | 'paypal';
  isDefault: boolean;
  cardId: string;
  cardCity: string;
  cardCountry: string;
  cardLine1: string;
  cardLine2: string;
  cardPostalCode: string;
  cardState: string;
  cardName: string;
  cardBrand: string;
  cardExpMonth: number;
  cardExpYear: number;
  cardFunding: string;
  cardLast4: string;
  createdAt?: Date;

  constructor(raw: any = {}) {
    this.id = raw?.id;
    this.type = raw?.type;
    this.isDefault = raw?.isDefault;
    this.cardId = raw?.cardId;
    this.cardCity = raw?.cardCity;
    this.cardCountry = raw?.cardCountry;
    this.cardLine1 = raw?.cardLine1;
    this.cardLine2 = raw?.cardLine2;
    this.cardPostalCode = raw?.cardPostalCode;
    this.cardState = raw?.cardState;
    this.cardName = raw?.cardName;
    this.cardBrand = raw?.cardBrand;
    this.cardExpMonth = raw?.cardExpMonth;
    this.cardExpYear = raw?.cardExpYear;
    this.cardFunding = raw?.cardFunding;
    this.cardLast4 = raw?.cardLast4;
    this.createdAt = raw?.createdAt && new Date(raw.createdAt);
  }
}
