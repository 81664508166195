// Common
import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from '@modules/common/utils/base';

// Types
import { ReminderUnits } from '../types/reminder';

@Pipe({
  name: 'notificationText',
  pure: true
})
export class NotificationTextPipe implements PipeTransform {
  transform(value: number, units: ReminderUnits, dateType?: boolean): any {
    return `${value} ${value === 1 && units ? units.replace('s', '') : units} before ${!isNil(dateType) ? dateType ? 'start' : 'end' : ''}`;
  }
}
