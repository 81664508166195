// Common
import { Component, Input, Output, EventEmitter } from '@angular/core';

// Services
import { ConnectionsService } from '@modules/connections/services/connections.service';

// Types
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Connection } from '@modules/connections/types/connection';
import { Stitch } from '@modules/common/types/stitch';

@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.less']
})
export class ConnectionComponent {

  // Inputs
  @Input() connection: Connection;
  @Input() dragEnabled = true;
  @Input() withControls = true;
  @Input() selected = false;
  @Input() withRemove = false;
  @Input() withDetach = false;

  // Output
  @Output() delete = new EventEmitter();
  @Output() onRemove = new EventEmitter<void>();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    this.connection &&
    dragData.type !== DragDataTypes.connection &&
    dragDataTypeAllowed(dragData.type)

  constructor (
    private connectionsService: ConnectionsService
  ) { }

  /**
   * Actions
   */

  dndDrop(dragData: DragData) {
    this.connectionsService.upsert([this.connection], dragData.data as Stitch[]);
  }

  handleDelete() {
    this.delete.emit();
  }
}
