import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {
  transform(value: string, max?: number): string {
    return (value || '')
      .split(' ')
      .map(n => n[0])
      .slice(0, max)
      .join('');
  }
}
