// Common
import { Component, Input, OnDestroy, SimpleChanges, OnChanges, Injector } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { isEqual } from 'lodash';

// RX
import { combineLatest, of, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

// Types
import { Knot } from '@modules/knots/types/knot';
import { KnotFilters } from '@modules/knots/types/knot-filters';
import { ManageListState } from '@modules/knots/types/manage-list-state';
import { Stitch } from '@modules/common/types/stitch';
import { TemporalExpression } from '@modules/common/types/temporal-expression';
import { StateKey } from '@modules/settings/types/state-key';

// Services
import { KnotsService } from '@modules/knots/services/knots.service';
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';
import { TemporalExpressionsService } from '@modules/common/services/temporal-expressions.service';
import { FullFormService } from '@modules/messages/services/full-form.service';
import { ModalService } from '@modules/modal/services/modal.service';

@Component({
  selector: 'app-knots',
  templateUrl: './knots.component.html',
  styleUrls: ['./knots.component.less']
})
export class KnotsComponent implements OnChanges, OnDestroy {

  @Input() stitchItems: Stitch[];
  @Input() control = new UntypedFormControl();
  @Input() withControls = true;
  @Input() withPlaceholder = false;
  @Input() withTemporalExpressions = true;
  @Input() stateKey: StateKey;

  public hidePopover = new Subject();
  public knots: Knot[] = [];
  public temporalExpressions: TemporalExpression[] = [];
  public selectedKnots: Knot[];
  public selectedTemporalExpressions: TemporalExpression[];
  public pagesCount = 0;
  public page = 0;
  public perPage = 20;
  public state: ManageListState;
  public debug: 'score' | 'createdAt' | 'recency' | 'frequency' | 'entityType' | 'source' = null;

  private alive = new Subject<void>();
  private fetchTemporalExpressions = new Subject<void>();
  private loadPage = new Subject<void>();

  constructor (
    private knotsService: KnotsService,
    private kpService: KnowledgePanelService,
    private temporalExpressionsService: TemporalExpressionsService,
    private ffService: FullFormService,
    private modalService: ModalService,
    private injector: Injector,
  ) {
    this.loadPage
      .pipe(
        switchMap(() => this.knotsService.getRefreshRequired()),
        debounceTime(300),
        switchMap(() => this.stitchItems?.filter(({ id }) => !!id)?.length
          ? this.knotsService.search({
              ...KnotFilters.fromManageListState(this.state),
              items: this.stitchItems,
              offset: this.page * this.perPage,
              limit: this.perPage
            })
          : (this.control?.valueChanges || of([{ items: [] }]))
            .pipe(
              startWith(this.control?.value || [{ items: [] }]),
              map(items => ({ items, count: items.length }))
            )
        ),
        takeUntil(this.alive)
      )
      .subscribe(({ items: knots, count }) => {
        this.pagesCount = Math.ceil(count / this.perPage);
        this.knots.length = count;
        this.knots = [
          ...this.knots.slice(0, this.page * this.perPage),
          ...knots,
          ...this.knots.slice((this.page + 1) * this.perPage, count),
        ].filter(i => !!i);

        this.notifyVisibleKnotsChanged();
      });

    this.kpService.getSelectedKnots()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((knots: Knot[]) => {
        this.selectedKnots = knots;
      });

    this.ffService.getSelectedTemporalExpressions()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((expressions: TemporalExpression[]) => {
        this.selectedTemporalExpressions = expressions;
      });

    this.fetchTemporalExpressions
      .pipe(
        filter(() => this.stitchItems?.length > 0),
        switchMap(() => this.temporalExpressionsService.listAll(this.stitchItems[0])),
        takeUntil(this.alive)
      )
      .subscribe((temporalExpressions: TemporalExpression[]) => {
        const { meetingIntentRule, meetingIntentML } = this.state.filters;

        this.temporalExpressions = temporalExpressions.filter(
          expression => meetingIntentRule
            ? expression.meetingIntentByRule
            : meetingIntentML
              ? expression.meetingIntentByML
              : true
        );
      });
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if (
      'stitchItems' in changes &&
      !isEqual(
        changes.stitchItems.previousValue?.map(({ id }) => id ),
        changes.stitchItems.currentValue?.map(({ id }) => id )
      )
    ) {
      this.page = 0;
      this.knots = [];
      this.loadPage.next();
      this.fetchTemporalExpressions.next();
    }
  }

  ngOnDestroy() {
    this.loadPage.complete();
    this.hidePopover.next();
    this.hidePopover.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClick(knot: Knot, event: MouseEvent) {
    this.kpService.addKnotToSelection(knot, event.shiftKey, true, 'ff');
  }

  handleSelectTemporalExpression(expression: TemporalExpression, event: MouseEvent) {
    this.ffService.addTemporalExpressionToSelection(expression, event.shiftKey);
  }

  closePopovers() {
    this.hidePopover.next();
  }

  saveKnots(knots: Knot[]) {
    this.control?.setValue(
      knots.reduce(
        (acc: Knot[], item) => {
          if (acc.find(existingItem => existingItem.name === item.name)) {
            if (item.pinned) {
              return acc.map(existingItem => existingItem.name === item.name ? item : existingItem);
            } else {
              return acc;
            }
          } else {
            return [...acc, item];
          }
        },
        this.control?.value || []
      )
    );

    const existingStitchItems = this.stitchItems.filter(({ id }) => id);
    if (existingStitchItems.length === 0) { return; }

    const newKnots = knots.filter(knot => knot.added && knot.id === undefined || knot.id === null);
    const changedKnots = knots.filter(knot => knot.changed && knot.id !== undefined && knot.id !== null);
    const linkedKnots = knots.filter(knot => knot.added);
    const unlinkedKnots = knots.filter(knot => knot.deleted);

    combineLatest([
      newKnots.length ? this.knotsService.createBulk(newKnots, false, false) : of(true),
      changedKnots.length ? this.knotsService.updateBulk(changedKnots, false, false) : of(true)
    ])
      .pipe(
        switchMap(([created, updated]) => (
          created && updated
            ? combineLatest([
              linkedKnots.length ? this.knotsService.link(linkedKnots, existingStitchItems, false, false) : of(true),
              unlinkedKnots.length ? this.knotsService.unlink(unlinkedKnots, existingStitchItems, false, false) : of(true)
            ])
            : of(null)
        )),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.knotsService.forceRefresh();
        this.closePopovers();
      });
  }

  handleDelete(knot: Knot) {
    if (this.stitchItems?.length) {
      this.knotsService.unlink([knot], this.stitchItems)
        .pipe(
          takeUntil(this.alive)
        )
        .subscribe((success: boolean) => {
          if (success) {
            this.setRemovedValue(knot);
          }
        });
    } else {
      this.setRemovedValue(knot);
    }
  }

  handlePin(knot: Knot) {
    if (this.stitchItems?.length) {
      this.knotsService.pin([knot], !knot.pinned)
        .pipe(
          takeUntil(this.alive)
        )
        .subscribe((success: boolean) => {
          if (success) {
            this.setPinnedValue(knot);
          }
        });
    } else {
      this.setPinnedValue(knot);
    }
  }

  setPinnedValue(knot: Knot) {
    if (!this.stitchItems?.length) {
      this.control.setValue(this.control.value.map(item => item.name === knot.name ? { ...item, pinned: !knot.pinned} : item));
    }
  }

  setRemovedValue(knot: Knot) {
    if (!this.stitchItems?.length) {
      this.control.setValue(this.control.value.filter(item => item.name !== knot.name));
    }
  }

  showMore() {
    this.page = Math.min(this.page + 1, this.pagesCount - 1);

    if (this.knots[this.page * this.perPage]) {
      this.notifyVisibleKnotsChanged();
    } else {
      this.loadPage.next();
    }
  }

  showLess() {
    this.page = 0;
    this.notifyVisibleKnotsChanged();
  }

  notifyVisibleKnotsChanged() {
    this.kpService.setStitchItemKnots(this.knots.slice(0, (this.page + 1) * this.perPage));
  }

  setState(state: ManageListState) {
    this.page = 0;
    this.knots = [];
    this.state = state;
    this.loadPage.next();
    this.fetchTemporalExpressions.next();
  }

  doDebug() {
    switch (this.debug) {
      case 'score':
        this.debug = 'recency';
        break;
      case 'recency':
        this.debug = 'frequency';
        break;
      case 'frequency':
        this.debug = 'createdAt';
        break;
      case 'createdAt':
        this.debug = 'entityType';
        break;
      case 'entityType':
        this.debug = 'source';
        break;
      case 'source':
        this.debug = null;
        break;
      default:
        this.debug = 'score';
    }
  }
}
