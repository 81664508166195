<div class="container">
  <div
    class="container-item left"
    (click)="jumpTo(currentPage - 1)"
  >
    <mat-icon class="container-item-icon" svgIcon="arrow-up"></mat-icon>
  </div>

  <div
    *ngFor="let item of items"
    class="container-item"
    [class.filler]="item.type === 'filler'"
    [class.current]="item.value === currentPage"
    (click)="jumpTo(item.value)"
  >
    {{ item.type === 'filler' ? '...' : item.value + 1 }}
  </div>

  <div
    class="container-item right"
    (click)="jumpTo(currentPage + 1)"
  >
    <mat-icon class="container-item-icon" svgIcon="arrow-up"></mat-icon>
  </div>
</div>

<div class="manual">
  <input
    class="manual-input"
    type="number"
    min="0"
    [max]="pages - 1"
    [formControl]="manualInput"
  >
  <div class="manual-button" (click)="maunalJumpTo()">Go</div>
</div>
