<div class="modal-body">
  <app-split-view
    direction="vertical"
    side="right"
    key="modalStitch"
    [collapsedSize]="44"
    [magneticSize]="100"
  >
    <app-split-view
      split-top-pane
      side="right"
      key="dpModal"
      [collapsedSize]="15"
      [magneticSize]="200"
      [withIcon]="true"
      [iconTop]="46"
    >
      <app-message-compose
        class="message-form"
        split-left-pane
        [message]="message"
        [linkedInfo]="linkedInfo"
        [reply]="reply"
        [forward]="forward"
        [action]="action"
        (close)="close()"
      ></app-message-compose>

      <div
        split-right-pane
        class="dynamic-panel-container"
      >
        <app-dynamic-panel
          class="dynamic-panel"
          splitViewKey="dpModal"
          stateKey="modal"
        ></app-dynamic-panel>
      </div>
    </app-split-view>

    <div
      split-bottom-pane
      class="stitch-container"
    >
      <div class="stitch-container-title">
        Stitch
        <mat-icon class="stitch-container-title-link" svgIcon="link-bold"></mat-icon>
        <div class="stitch-container-title-separator"></div>
        <mat-icon
          class="stitch-container-title-collapse"
          [class.minimized]="stitchListMinimized"
          svgIcon="collapse-down"
          split-view-toggle="modalStitch"
        ></mat-icon>
      </div>

      <app-linked-info-list
        appearance="inline"
        [(linkedInfo)]="linkedInfo"
        [withDroppable]="true"
        [withActions]="false"
        [withPlaceholderImage]="false"
        [stitch]="message"
        (clickStitchItem)="handleClickItem($event)"
        (dblClickStitchItem)="handleDblClickItem($event)"
      ></app-linked-info-list>
    </div>
  </app-split-view>
</div>
