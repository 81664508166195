// Common
import { Component, Output, EventEmitter, Input } from '@angular/core';

// Types
import { KPListType } from '@modules/knowledge/types/kp-list-type';

@Component({
  selector: 'app-kp-stitch-type-context-menu',
  templateUrl: './stitch-type-context-menu.component.html',
  styleUrls: ['./stitch-type-context-menu.component.less']
})
export class StitchTypeContextMenuComponent {

  // Input
  @Input() value: KPListType;

  // Outputs
  @Output() change: EventEmitter<KPListType> = new EventEmitter();

  // Public
  public KPListType = KPListType;

  /**
   * Actions
   */

  select(type: KPListType) {
    this.change.emit(type);
  }
}
