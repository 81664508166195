// Common
import { Component, Injector, Input, OnInit } from '@angular/core';

// RX
import { BehaviorSubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

// Types
import { Notebook } from '@modules/notes/types/notebook';
import { DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Tab } from '@modules/common/types/tab';
import { NotesListState } from '@modules/notes/types/notes-list-state';
import { StateKey } from '@modules/settings/types/state-key';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { NotesFilters } from '@modules/notes/types/notes-filters';

// Services
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { NotesAppStateService } from '@modules/notes/services/state.service';

// Components
import { FullFormBaseComponent } from '@modules/common/components/full-form-base/full-form-base.component';

@Component({
  selector: 'app-notebook-form',
  templateUrl: './notebook-form.component.html',
  styleUrls: ['./notebook-form.component.less']
})
export class NotebookFormComponent extends FullFormBaseComponent<Notebook> implements OnInit {

  public changesKey = 'notebook';
  tabs: Tab[] = [
    { title: 'Notebook', value: 'notebook'},
    { title: 'Details', value: 'details'},
    { title: 'Attachments', value: 'attachments'},
    { title: 'Stitch', value: 'stitch'},
    { title: 'Lab', value: 'lab'},
    { title: 'Sections', value: 'sections' },
    { title: 'Comments', value: 'comments'},
  ];
  tabsStateKey = 'ffNotebook';
  public notesListStateKey: StateKey = StateKey.notesFFListState;
  public notesListState = new BehaviorSubject<NotesListState>(null);
  public parentSuggestions: AutocompleteFactory<Notebook>;
  public notesFilters: NotesFilters;

  protected dragDataType = DragDataTypes.notebook;

  @Input() notebook = new Notebook();

  constructor(
    private notebooksService: NotebooksService,
    notesAppStateService: NotesAppStateService,
    injector: Injector,
  ) {
    super(injector, notebooksService, notesAppStateService);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    this.stitchItemChanged
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.parentSuggestions = this.notebooksService.getAutocompleteSuggestions({ exceptIds: [this.stitchItem.id] });
      });

    this.stitchItemChanged
      .pipe(
        switchMap(() => this.notesListState),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.notesFilters = new NotesFilters({ containersIds: [this.stitchItem.id] });
        this.notesFilters.applyListState(this.notesListState.getValue());
      });
  }

  /**
   * Actions
   */

  handleNotesListStateChange(state: NotesListState) {
    this.notesListState.next(state);
  }

  /**
   * Helpers
   */

  get stitchItem(): Notebook {
    return this.notebook;
  }

  protected shouldRefreshList(prev, current) {
    return Notebook.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): Notebook {
    return Notebook.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return this.notebook.asFormGroup(this.notebooksService);
  }
}
