<app-split-view
  side="left"
  key="contactsSidebar"
  [collapsedSize]="76"
  [shadow]="true"
  [magneticSize]="120"
  [withIcon]="true"
>
  <app-sidebar split-left-pane splitViewKey="contactsSidebar">
    <app-contacts-side-bar *stchSpaceRerender></app-contacts-side-bar>
  </app-sidebar>

  <app-split-view
    split-right-pane
    side="left"
    key="contactsList"
    [collapsedSize]="25"
    [shadow]="true"
    [magneticSize]="300"
    [withIcon]="true"
  >
    <app-contacts-list-container
      split-left-pane
      *stchSpaceRerender
      class="list"
    ></app-contacts-list-container>

    <div class="full-form" split-right-pane>
      <app-contacts-main-view *stchSpaceRerender></app-contacts-main-view>
    </div>
  </app-split-view>
</app-split-view>
