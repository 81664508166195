// Common
import { Component, Input } from '@angular/core';

// Types
import { TemporalExpression } from '@modules/common/types/temporal-expression';

@Component({
  selector: 'app-temporal-expression',
  templateUrl: './temporal-expression.component.html',
  styleUrls: ['./temporal-expression.component.less']
})
export class TemporalExpressionComponent {

  // Inputs
  @Input() expression: TemporalExpression;
  @Input() debug;
  @Input() selected: boolean;
}
