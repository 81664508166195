// Types
import { LinkedInfo } from './linked-info';
import { Stitch } from '@modules/common/types/stitch';

export class LinkedInfoData extends LinkedInfo {
  data?: Stitch;

  constructor(stitchItem: Stitch) {
    super(stitchItem);
    this.data = stitchItem;
  }
}
