// Common
import {
  Directive, ElementRef, Input, OnDestroy, HostBinding, TemplateRef, OnInit, Output,
  EventEmitter, NgZone, Inject,
} from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { SPACE_ID } from '@modules/common/injection-tokens/space-id.injection-token';

// RX
import { BehaviorSubject, Subject, Subscription, fromEvent } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

// Components
import { DraggableElementComponent } from '../components/draggable-element/draggable-element.component';

// Services
import { DragNDropService } from '../services/drag-n-drop.service';

// Types
import { DragData } from '../types/drag-data';

@Directive({
  selector: '[appDraggable]',
})
export class DraggableDirective implements OnInit, OnDestroy {

  // Private
  private overlayRef: OverlayRef;
  private isDragging = false;
  private alive: Subject<void> = new Subject();
  private returnBackAnimationSubscription: Subscription;
  private uuid = Symbol();

  // Inputs
  @Input() appDraggableData: DragData;
  @Input() appDraggablePlaceholder: TemplateRef<any>;
  @Input() appDraggablePlaceholderContext: object;
  @Input() appDraggableDisabled = false;
  @Input() appDraggableContainerStyles: Object;
  @Input() appDraggableContainerAdjustFit = false;
  @Input() appDraggableNoShadow = false;

  // Outputs
  @Output() appDraggableDraggingChanged = new EventEmitter<boolean>();
  @Output() appDraggableDropped = new EventEmitter<void>();

  @HostBinding('draggable')
  public get isDraggable(): boolean {
    return !this.appDraggableDisabled;
  }

  constructor (
    private elementRef: ElementRef,
    private overlay: Overlay,
    private dragNDropService: DragNDropService,
    private ngZone: NgZone,
    @Inject(SPACE_ID) private spaceId: BehaviorSubject<string>,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.dragNDropService.getDraggingDataChanges()
      .pipe(
        takeUntil(this.alive),
      )
      .subscribe((data: DragData) => {
        this.isDragging = !!data;
        if (!this.isDragging) {
          this.stopDragging();
        }
        this.appDraggableDraggingChanged.emit(this.isDragging);
      });

    this.ngZone.runOutsideAngular(() => {
      // @TODO: Clarify why that subs is needed, as do not see a big reason for it
      fromEvent(this.elementRef.nativeElement, 'click')
        .pipe(
          filter(() => this.isDragging),
          takeUntil(this.alive)
        )
        .subscribe((event: MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
        });
    });

    fromEvent(this.elementRef.nativeElement, 'dragstart')
      .pipe(
        filter(() => !this.appDraggableDisabled),
        takeUntil(this.alive)
      )
      .subscribe((event: MouseEvent) => {
        event.stopImmediatePropagation();

        this.dragNDropService.setDragging(
          this.spaceId.getValue(),
          { ...this.appDraggableData, draggableId: this.uuid }
        );

        // @TODO: Check that there is no bug when placeholder is not set, what will be shown dragging then?
        if (this.appDraggablePlaceholder) {
          this.startDragging(event);
          event.preventDefault();
        }
      });

    this.dragNDropService.getDelivered()
      .pipe(takeUntil(this.alive))
      .subscribe((dragData: DragData) => {
        if (dragData.draggableId === this.uuid) { this.appDraggableDropped.emit(); }
      });
  }

  ngOnDestroy(): void {
    this.stopDragging();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  private stopDragging() {
    this.dragNDropService?.setDragIds([]);
    this.dragNDropService.setDraggableBounding(null);

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  private startDragging(event: MouseEvent): void {
    this.overlayRef = this.overlay.create();
    const componentPortal = new ComponentPortal(DraggableElementComponent);
    const componentRef = this.overlayRef.attach(componentPortal);

    componentRef.instance.dragPlaceholder = this.appDraggablePlaceholder;
    componentRef.instance.dragPlaceholderContext = this.appDraggablePlaceholderContext;
    componentRef.instance.multipleStyle = this.appDraggableData?.data?.length > 1;
    componentRef.instance.noShadow = this.appDraggableNoShadow;

    const boundingRect = this.elementRef.nativeElement.getBoundingClientRect();

    componentRef.instance.homeTop = boundingRect.top;
    componentRef.instance.homeLeft = boundingRect.left;

    if (this.appDraggableContainerAdjustFit) {
      componentRef.instance.mouseYAdjustment = 0;
      componentRef.instance.mouseXAdjustment = 0;
    } else {
      componentRef.instance.mouseXAdjustment = event.pageX - boundingRect.left;
      componentRef.instance.mouseYAdjustment = event.pageY - boundingRect.top;
      componentRef.instance.width = boundingRect.width;
      componentRef.instance.height = boundingRect.height;

      this.dragNDropService.setDraggableBounding({
        mouseXAdjustment: event.pageX - boundingRect.left,
        mouseYAdjustment: event.pageY - boundingRect.top,
        width: boundingRect.width,
        height: boundingRect.height
      });
    }

    if (this.appDraggableContainerStyles) {
      componentRef.instance.customStyles = this.appDraggableContainerStyles;
    }

    if (this.returnBackAnimationSubscription) {
      this.returnBackAnimationSubscription.unsubscribe();
    }

    this.returnBackAnimationSubscription = componentRef.instance.animationFinish
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.dragNDropService.setDragging(undefined, null);
      });

    this.dragNDropService?.setDragIds(this.appDraggableData?.data.map(({ id }) => id));
  }
}
