<div class="form">
  <div class="form-body">
    <app-input
      class="input"
      appearance="amethyst"
      label="Title"
      [inputFormControl]="form.controls.title"
      [withClear]="true"
      [invertedColor]="true"
      [withErrors]="submitted"
      [validationMessages]="{
        required: 'Can\'t be blank'
      }"
    ></app-input>

    <app-textarea
      class="input"
      appearance="amethyst"
      [inputFormControl]="form.controls.description"
      [rows]="8"
      [withClear]="true"
      [invertedColor]="true"
      label="Description"
    ></app-textarea>
  </div>

  <div class="form-buttons">
    <app-button
      class="form-buttons-item"
      label="CANCEL"
      appearance="purple-outline"
      (click)="handleCancel()"
    />

    <app-button
      class="form-buttons-item"
      label="SAVE"
      appearance="purple"
      (click)="handleSubmit()"
    />
  </div>
</div>
