<cdk-virtual-scroll-viewport
  [itemSize]="itemHeight"
  minBufferPx="880"
  maxBufferPx="1320"
  class="tasks-list-wrapper"
  (mouseover)="isHover = true"
  (mouseleave)="isHover = false"
  appSelectableList
  [appSelectableListReset]="resetSelected"
>
  <!-- Tasks -->
  <ng-container *cdkVirtualFor="let task of itemsStreamObservable; let index = index">

    <!-- Date header -->
    <div
      class="days-separator"
      *ngIf="!task?.pinned && isHover && task?.toTime | differentDay: items[index - 1]?.toTime"
    >
      <div class="days-separator-date">
        {{ task.toTime | date: 'MMM d, yyyy' }}
      </div>
    </div>

    <app-task
      [item]="task"
      [position]="index"
      [withTags]="withTags"
      [withKnots]="withKnots"
      [debug]="debug"
      (open)="openTask.emit(task)"
      (openSubtask)="openSubtask.emit($event)"
      (appClick)="handleClickItem(task)"
      (appDblClick)="handleDoubleClickItem(task)"

      [appSelectable]="task"
      [appSelectablePosition]="index"
    ></app-task>
  </ng-container>

  <!-- Placeholders -->
  <div class="loader" *ngIf="loading"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  <div *ngIf="loadingError" class="placeholder">Error occured while loading. <span (click)="items?.length ? refreshCurrentItems() : resetItems()" class="error">Click here to try again.</span></div>
</cdk-virtual-scroll-viewport>

<!-- Count -->
<div class="task-list__selected" *ngIf="items?.length > 0 && ((showCountView | async) === true)">
  <div class="total">Items: {{items.length}}</div>
</div>

<div class="tasks-stub" *ngIf="items?.length === 0 && !loading && !loadingError">
  <img class="tasks-stub-image" src="assets/placeholders/placeholder-tasks.svg"/>
  <p class="tasks-stub-text">{{ placeholderText }}</p>
</div>
