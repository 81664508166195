// Common
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';

// Types
import { MessageFolder } from '@modules/messages/types/message-folder';
import { Message } from '@modules/messages/types/message';
import { ListState } from '@modules/messages/types/list-state';
import { Tab } from '@modules/common/types/tab';
import { FoldersListState } from '@modules/messages/types/folders-list-state';
import { MessagesListState } from '@modules/messages/types/messages-list-state';
import { StateKey } from '@modules/settings/types/state-key';
import { VirtualFolder } from '@modules/messages/types/virtual-folder';
import { MessagesFilters } from '@modules/messages/types/messages-filters';
import { FoldersFilters } from '@modules/messages/types/folders-filters';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';
import { MailAppStateService } from '@modules/messages/services/state.service';
import { ModalService } from '@modules/modal/services/modal.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// RX
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-messages2-list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.less'],
})
export class MessagesListContainerComponent implements OnInit, OnDestroy {

  public minimized: boolean;
  public searchQuery: string;
  public listState: ListState;
  public sidebarFilter: VirtualFolder = 'all_messages';
  public quickFolder: MessageFolder;
  public quickMessage: Message;
  public loading: boolean;
  public tabs: Tab[] = [
    { title: 'Folders', value: 'folders'},
    { title: 'Messages', value: 'messages'}
  ];
  public selectedTab: Exclude<ListState, 'tabs'> = 'messages';
  public messagesListState = new BehaviorSubject<MessagesListState>(null);
  public foldersListState = new BehaviorSubject<FoldersListState>(null);
  public foldersListStateKey: StateKey = StateKey.messagesFoldersListState;
  public messagesListStateKey: StateKey = StateKey.messagesListState;
  public foldersFilters: FoldersFilters;
  public messagesFilters: MessagesFilters;
  public debug: 'score' = null;

  private alive: Subject<void> = new Subject();

  constructor (
    private splitViewService: SplitViewService,
    private stateService: MailAppStateService,
    private modalService: ModalService,
    private searchService: AdvancedSearchService,
    private injector: Injector,
  ) {
    combineLatest([
      this.foldersListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.foldersFilters = new FoldersFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });

    combineLatest([
      this.messagesListState,
      this.searchService.getState(),
      this.stateService.getVirtualFolder()
    ])
      .pipe(
        debounceTime(300),
        takeUntil(this.alive)
      )
      .subscribe(([list, search, folder]) => {
        this.messagesFilters = new MessagesFilters()
          .applyListState(list)
          .applyAdvancedFilters(search)
          .applyVirtualFolder(folder);
      });
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.splitViewService.getMinimized('mailsList')
      .pipe(takeUntil(this.alive))
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
      });

    this.stateService.getTabs()
      .pipe(takeUntil(this.alive))
      .subscribe((state: ListState) => {
        this.listState = state;
        if (this.listState !== 'tabs') {
          this.selectedTab = this.listState;
        }
      });

    combineLatest([
      this.stateService.getVirtualFolder(),
      this.searchService.getState()
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([folder, state]) => {
        this.sidebarFilter = folder;

        this.quickFolder = MessageFolder.fromAdvancedState(state, folder);
        this.quickMessage = Message.fromAdvancedState(state, folder);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleNewMessage(message) {
    this.stateService.composeMessage({ message, injector: this.injector });
  }

  handleNewFolder(folder = new MessageFolder()) {
    this.stateService.setMainView(folder);
  }

  handleDoubleClickMessage(message: Message) {
    this.modalService.openFullForm(message, this.injector);
  }

  handleDoubleClickFolder(folder: MessageFolder) {
    this.modalService.openFullForm(folder, this.injector);
  }

  handleClickMessage(message: Message) {
    this.stateService.setMainView(message);
  }

  handleClickFolder(folder: MessageFolder) {
    this.stateService.setMainView(folder);
  }

  handleMessagesListStateChange(event: MessagesListState) {
    this.messagesListState.next(event);
  }

  handleFoldersListStateChange(event: FoldersListState) {
    this.foldersListState.next(event);
  }

  doDebug() {
    switch (this.debug) {
      case 'score':
        this.debug = null;
        break;
      default:
        this.debug = 'score';
    }
  }
}
