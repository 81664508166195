<div class="block">
  <div
    *ngFor="let signature of signatures"
    class="block-item"
  >
    <span class="block-item-title">{{ signature.title }}</span>

    <app-button
      appearance="purple-outline"
      label="Edit Signature"
      (click)="handleOpenForm(signature)"
    />

    <app-button
      appearance="purple"
      label="Remove Signature"
      (click)="handleRemove(signature)"
    />
  </div>
</div>
<div class="block no-border">
  <app-button
    appearance="purple"
    label="Create Signature"
    (click)="handleOpenForm()"
  />
</div>


