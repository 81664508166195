// Common
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LocalStorageItem, LSBoolean } from 'src/app/decorators/local-storage.decorator';
import { heightAnimation } from '@modules/common/animations/height.animation';

// RX
import { combineLatest, merge, of, Subject, timer } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { KnotsService } from '@modules/knots/services/knots.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { ConnectionsService } from '@modules/connections/services/connections.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';

// Types
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { Knot } from '@modules/knots/types/knot';
import { Tag } from '@modules/tags/types/tag';
import { Connection } from '@modules/connections/types/connection';
import { Tab } from '@modules/common/types/tab';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { MailAppAdvancedFilters } from '@modules/search/types/mail-app-advanced-filters';
import { CalendarAppAdvancedFilters } from '@modules/search/types/calendar-app-advanced-filters';
import { TaskingAppAdvancedFilters } from '@modules/search/types/tasking-app-advanced-filters';
import { NotesAppAdvancedFilters } from '@modules/search/types/notes-app-advanced-filters';
import { ContactsAppAdvancedFilters } from '@modules/search/types/contacts-app-advanced-filters';
import { FilesAppAdvancedFilters } from '@modules/search/types/files-app-advanced-filters';

@Component({
  selector: 'app-search-popover',
  templateUrl: './search-popover.component.html',
  styleUrls: ['./search-popover.component.less'],
  animations: [heightAnimation]
})
export class SearchPopoverComponent implements OnInit, OnDestroy {

  @Input() width = 0;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSearch = new EventEmitter<void>();

  @ViewChild('advancedForms', { static: true }) advancedForms: ElementRef;

  public inputControl = new UntypedFormControl('');
  public suggestions: AutocompleteFactory<Knot[]|Tag[]|Connection[]|string>;
  public advanced = false;
  public advancedHeight = 0;
  public tabs: Tab[] = [
    { title: 'Mail App', value: 'mail', icon: 'search-small' },
    { title: 'Calendar App', value: 'calendar', icon: 'search-small' },
    { title: 'Tasking App', value: 'tasking', icon: 'search-small' },
    { title: 'Notes App', value: 'notes', icon: 'search-small' },
    { title: 'Contacts App', value: 'contacts', icon: 'search-small' },
    { title: 'Files App', value: 'files', icon: 'search-small' }
  ];
  public selectedTab = 'mail';
  public state: AdvancedSearchState;
  public mailForm: UntypedFormGroup;
  public calendarForm: UntypedFormGroup;
  public taskingForm: UntypedFormGroup;
  public notesForm: UntypedFormGroup;
  public contactsForm: UntypedFormGroup;
  public filesForm: UntypedFormGroup;
  public analyzerControl = new UntypedFormControl();
  public priorityControl = new UntypedFormControl();
  public multiMatchTypeControl = new UntypedFormControl();

  private alive = new Subject<void>();
  private formsChanged = new Subject<void>();
  @LSBoolean({ lsKey: 'as.expanded' }) private advancedExpanded: LocalStorageItem<boolean>;

  constructor(
    private knotsService: KnotsService,
    private tagsService: TagsService,
    private connectionsService: ConnectionsService,
    private searchService: AdvancedSearchService
  ) {
    this.suggestions = (title?: string, values?: string[], { limit }: { limit?: number } = { limit: 5 }) => {
      if (!title || title.trim() === '') {
        return of ([]);
      }

      return combineLatest([
        this.knotsService.search({ limit, query: title }),
        this.tagsService.search({ limit, query: title }),
        this.connectionsService.search({ limit, query: title }),
        this.searchService.getSuggestions(title)
      ])
        .pipe(
          map(([{items: knots}, {items: tags}, {items: connections}, suggestions]) => {
            const items: DropdownSelectItem<Knot[]|Tag[]|Connection[]|string>[] = suggestions;
            knots.length && items.push({ source: knots, type: 'knots', disabled: true });
            tags.length && items.push({ source: tags, type: 'tags', disabled: true });
            connections.length && items.push({ source: connections, type: 'connections', disabled: true });

            return items;
          })
        );
    };
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.formsChanged
      .pipe(
        switchMap(() => merge(
          this.mailForm.valueChanges,
          this.calendarForm.valueChanges,
          this.taskingForm.valueChanges,
          this.notesForm.valueChanges,
          this.contactsForm.valueChanges,
          this.filesForm.valueChanges,
        )),
        debounceTime(400),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.measureAdvancedHeight();
      });

    this.searchService.getState()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(state => {
        this.inputControl.setValue(state.query);
        this.state = state;
        this.mailForm = state.mail.asFormGroup();
        this.calendarForm = state.calendar.asFormGroup();
        this.taskingForm = state.tasking.asFormGroup();
        this.notesForm = state.notes.asFormGroup();
        this.contactsForm = state.contacts.asFormGroup();
        this.filesForm = state.files.asFormGroup();
        this.formsChanged.next();
        this.analyzerControl.setValue(state.analyzer);
        this.priorityControl.setValue(state.priority);
        this.multiMatchTypeControl.setValue(state.multiMatchType);
      });

    this.advancedExpanded.get()
      .pipe(takeUntil(this.alive))
      .subscribe(expanded => {
        this.advanced = expanded;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSearch() {
    this.searchService.setState(
      new AdvancedSearchState({
        query: this.inputControl.value,
        tags: this.state.tags, // TODO
        knots: this.state.knots, // TODO
        connections: this.state.connections, // TODO
        mail: MailAppAdvancedFilters.fromFormGroup(this.mailForm),
        calendar: CalendarAppAdvancedFilters.fromFormGroup(this.calendarForm),
        tasking: TaskingAppAdvancedFilters.fromFormGroup(this.taskingForm),
        notes: NotesAppAdvancedFilters.fromFormGroup(this.notesForm),
        contacts: ContactsAppAdvancedFilters.fromFormGroup(this.contactsForm),
        files: FilesAppAdvancedFilters.fromFormGroup(this.filesForm),
        analyzer: this.analyzerControl.value,
        priority: this.priorityControl.value,
        multiMatchType: this.multiMatchTypeControl.value
      })
    );

    this.onCancel.emit();
  }

  handleCancel() {
    this.onSearch.next();
  }

  addKnowledgeItem(item: Knot | Tag | Connection) {
    this.searchService.addKnowledgeItem(item);
  }

  removeKnowledgeItem(item: Knot | Tag | Connection)  {
    this.searchService.removeKnowledgeItem(item);
  }

  toggleAdvanced() {
    this.measureAdvancedHeight();
    this.advancedExpanded.set(!this.advanced);
  }

  handleChangeTab(tab: string) {
    this.selectedTab = tab;

    timer(200)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.measureAdvancedHeight();
      });
  }

  measureAdvancedHeight() {
    this.advancedHeight = this.advancedForms.nativeElement.offsetHeight + 2;
  }
}
