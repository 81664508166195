// Common
import { Component, OnDestroy, AfterViewInit, ViewChild, ElementRef, Input, SimpleChanges, OnChanges } from '@angular/core';
import ForceGraph from 'force-graph';
import { getStitchInstanceByStitchType } from '@modules/common/utils/stitch';

// RxJS
import { merge, Subject, timer } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { Tag } from '@modules/tags/types/tag';
import { Knot } from '@modules/knots/types/knot';
import { Connection } from '@modules/connections/types/connection';
import { ChartState } from '@modules/knowledge/types/chart-state';
import { ChartNode } from '@modules/knowledge/types/chart-node';
import { ChartLink } from '@modules/knowledge/types/chart-link';

// Service
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';
import { KnowledgeService } from '@modules/knowledge/services/knowledge.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { ConnectionsService } from '@modules/connections/services/connections.service';
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';

@Component({
  selector: 'app-knowledge-chart-2d',
  templateUrl: './knowledge-chart-2d.component.html',
  styleUrls: ['./knowledge-chart-2d.component.less'],
})
export class KnowledgeChart2DComponent implements AfterViewInit, OnChanges, OnDestroy {

  private chart;
  private alive = new Subject<void>();
  private calculateSize = new Subject<void>();
  private fetch = new Subject<void>();

  public limit = 100;

  @Input() item: Stitch;
  @Input() sizeChanges: any;
  @Input() state: ChartState;

  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('graph', { static: true }) graph: ElementRef;

  constructor (
    private knowledgeService: KnowledgeService,
    private kpService: KnowledgePanelService,
    private tagsService: TagsService,
    private knotsService: KnotsService,
    private connectionsService: ConnectionsService,
    private dpService: DynamicPanelService
  ) {

  }

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.calculateSize
      .pipe(
        debounceTime(400),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        const width = this.container.nativeElement.offsetWidth;
        const height = this.container.nativeElement.offsetHeight;

        this.chart.width(width).height(height);
      });

    this.draw();

    this.fetch
      .pipe(
        startWith(() => null),
        debounceTime(500),
        switchMap(() => (
          this.knowledgeService.getGraphForItem(this.item, {
            ...this.state,
            limit: this.limit,
          })
            .pipe(
              map(data => this.mergePages({ links: [], nodes: [] }, data))
            )
        )),
        filter(data => data.nodes.length > 0),
        takeUntil(this.alive)
      )
      .subscribe(data => {
        this.chart.graphData(data);

        timer(1000)
          .pipe(takeUntil(this.alive))
          .subscribe(() => this.chart.zoomToFit(500, 20));
      });

    merge(
      this.tagsService.getRefreshRequired(),
      this.knotsService.getRefreshRequired(),
      this.connectionsService.getRefreshRequired(),
    )
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.fetch.next();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('item' in changes && this.item) {
      this.limit = 100;
      this.fetch.next();
    }

    if ('sizeChanges' in changes) {
      this.calculateSize.next();
    }

    if ('state' in changes) {
      this.limit = 100;
      this.fetch.next();
    }
  }

  ngOnDestroy() {
    this.chart.graphData({ nodes: [], links: [] });
    this.chart._destructor();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  getNodeColor(node) {
    switch (node['type']) {
      case 'tag':
        return '#d5eaff';
      case 'knot':
        return '#decffb';
      case 'connection':
        return 'lightgray';
      default:
        return '#eff0f2';
    }
  }

  draw() {
    const width = this.container.nativeElement.offsetWidth;
    const height = this.container.nativeElement.offsetHeight;

    this.chart = ForceGraph()(this.graph.nativeElement)
      .graphData({ nodes: [], links: [] })
      .backgroundColor('white')
      .width(width)
      .height(height)
      .cooldownTime(5000)
      .nodeCanvasObject((node, ctx) => {
        const radius = 10;
        const fontSize = 10;
        const padding = 2;
        const text = node['name'];

        // Draw node background
        ctx.beginPath();
        ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = this.getNodeColor(node);
        ctx.fill();
        ctx.closePath();

        // Draw node icon
        const iconPadding = 0;
        const iconSize = 16;
        const iconX = node.x - radius + padding + iconPadding;
        const iconY = node.y - iconSize / 2;
        const iconSvg = this.drawNodeIcon(node);
        const img = new Image();
        img.src = 'data:image/svg+xml;base64,' + btoa(iconSvg);
        ctx.drawImage(img, iconX, iconY, iconSize, iconSize);

        // Draw node text
        ctx.fillStyle = '#212642';
        ctx.font = `${fontSize}px Arial`;
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        const textX = iconX + iconSize + padding;
        const textY = node.y;
        const textOverflow = ctx.measureText(text).width > 150;

        ctx.fillText(textOverflow ? this.truncateText(text, ctx, 150) : text, textX, textY);
      })
      .nodePointerAreaPaint((node, color, ctx) => {
        const radius = 10;
        ctx.beginPath();
        ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = color;
        ctx.fill();
        ctx.closePath();
      })
      .linkColor(() => '#409aff')
      .linkWidth(1)
      .linkCurvature(.2)
      .onNodeClick((node: ChartNode) => {
        switch (node.type) {
          case 'knot':
            this.kpService.addKnotToSelection(new Knot({ name: node['name'] }), false, true, null);
            break;
          case 'tag':
            this.kpService.addTagToSelection(new Tag({ name: node['name'] }), false, true, null);
            break;
          case 'connection':
            this.kpService.addConnectionToSelection(new Connection({ name: node['name'] }), false, true, null);
            break;
          default:
            const item = getStitchInstanceByStitchType(node.type, { id: node['itemId'] })
            this.dpService.setFormItem(item);
        }
      })

    // Spread nodes a little wider
    this.chart.d3Force('charge').strength(-200);
    this.chart.d3Force('link').distance(100);

    this.calculateSize.next();
  }

  drawNodeIcon(node) {
    let path = '';
    let color = '#73b5fe';

    switch (node['type']) {
      case 'tag':
        path = '<path d="M11.527 2c.553 0 .961.448.913 1l-.087 1h1c.552 0 .96.448.912 1-.048.552-.535 1-1.087 1h-1l-.35 4h1c.553 0 .962.448.913 1-.048.552-.535 1-1.087 1h-1l-.087 1c-.049.552-.535 1-1.088 1-.552 0-.96-.448-.912-1l.087-1h-4l-.087 1c-.049.552-.535 1-1.088 1-.552 0-.96-.448-.912-1l.087-1h-1c-.552 0-.96-.448-.913-1 .049-.552.535-1 1.088-1h1l.349-4h-1c-.552 0-.96-.448-.913-1 .049-.552.535-1 1.088-1h1l.087-1c.048-.552.535-1 1.087-1 .553 0 .961.448.913 1l-.087 1h4l.087-1c.048-.552.535-1 1.087-1zM9.83 10l.349-4h-4l-.35 4h4z" transform="translate(-337 -95) translate(329 87) translate(8 8)"/>';
        break;
      case 'connection':
      case 'knot':
        path = '<path d="M4.641 6.237c.52-.188 1.093.081 1.28.6.188.52-.081 1.093-.6 1.281C4.535 8.402 4 9.15 4 10c0 1.105.895 2 2 2 .85 0 1.597-.534 1.881-1.32l.046-.106c.224-.448.753-.667 1.235-.493.52.188.788.761.6 1.28C9.193 12.934 7.698 14 6 14c-.74 0-1.432-.2-2.026-.55l-1.27 1.26c-.392.389-1.025.386-1.414-.006-.359-.362-.384-.929-.078-1.32l.084-.094 1.261-1.252C2.203 11.441 2 10.744 2 10c0-1.699 1.069-3.195 2.641-3.763zM9.387 5.21c.392-.305.96-.278 1.32.083.39.39.39 1.023 0 1.414L6.536 10.88l-.094.083c-.393.306-.96.278-1.32-.082-.391-.39-.391-1.024-.001-1.414l4.172-4.175zM14.7 1.285c.364.357.398.924.097 1.32l-.082.095-1.259 1.285C13.802 4.577 14 5.265 14 6c0 1.708-1.08 3.21-2.665 3.771-.52.185-1.092-.088-1.276-.608-.184-.521.088-1.093.609-1.277l.145-.058C11.525 7.511 12 6.801 12 6c0-1.105-.895-2-2-2-.859 0-1.613.546-1.89 1.344l-.045.108c-.217.451-.743.677-1.228.51-.522-.182-.798-.752-.617-1.273C6.774 3.092 8.283 2 10 2c.749 0 1.449.206 2.048.563L13.285 1.3c.387-.394 1.02-.4 1.415-.015z" transform="translate(-202 -95) translate(194 87) translate(8 8)"/>';
        break;
      case 'project':
        path = '<path xmlns="http://www.w3.org/2000/svg" d="M8 1c3.866 0 7 3.134 7 7s-3.134 7-7 7-7-3.134-7-7 3.134-7 7-7zM6.974 8.181l-1.75 3.031c-.276.479-.112 1.09.366 1.366.479.277 1.09.113 1.366-.366l1.75-3.03-1.732-1zm.427-4.94c-.25-.09-.54-.001-.67.225l-.799 1.383-.04.088c-.081.24.026.525.264.662l.266.153.091.043c.25.09.533-.005.659-.223l.256.148-.13.225-.024.057c-.034.116.018.247.133.313.267.154.364.486.217.74l-.4.693 1.732 1 .4-.693.056-.078c.165-.191.457-.24.694-.103.134.077.301.036.375-.092l.125-.217.134.102c.35.28.631.625.684.862.025.111.114.197.228.217.114.021.223-.03.279-.126l.133-.23.064-.126c.301-.68-.003-1.544-.724-2.081l.141-.245.025-.057c.035-.116-.013-.25-.127-.316l-2.69-1.553-.06-.026c-.123-.038-.267.006-.33.115l-.13.224-.256-.148.039-.085c.08-.233-.02-.517-.258-.654l-.266-.154z" transform="translate(-439 -191) translate(431 183) translate(8 8)"/>';
        break;
      case 'message':
        path = '<path xmlns="http://www.w3.org/2000/svg" d="M14 5.338L8 9.02 2 5.338V4.8a.8.8 0 0 1 .8-.8h10.4a.8.8 0 0 1 .8.8v.538zm0 .98V12.2a.8.8 0 0 1-.8.8H2.8a.8.8 0 0 1-.8-.8V6.319L8 10l6-3.681z"></path>';
        break;
      case 'event':
        path = '<path d="M14 7v6.5c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5V7h12zm-2.2 2H9.2c-.11 0-.2.09-.2.2v2.6c0 .11.09.2.2.2h2.6c.11 0 .2-.09.2-.2V9.2c0-.11-.09-.2-.2-.2zm-6-7c.11 0 .2.09.2.2V3h4v-.8c0-.11.09-.2.2-.2h1.6c.11 0 .2.09.2.2V3h1c.552 0 1 .448 1 1v2H2V4c0-.552.448-1 1-1h1v-.8c0-.11.09-.2.2-.2h1.6z" transform="translate(-202 -143) translate(194 135) translate(8 8)"/>';
        break;
      case 'task':
        path = '<path xmlns="http://www.w3.org/2000/svg" d="M13.5 11c.276 0 .5.224.5.5v2c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5v-2c0-.276.224-.5.5-.5h11zm-6.7 1H4.2c-.11 0-.2.09-.2.2v.6c0 .11.09.2.2.2h2.6c.11 0 .2-.09.2-.2v-.6c0-.11-.09-.2-.2-.2zm6.7-5c.276 0 .5.224.5.5v2c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5v-2c0-.276.224-.5.5-.5h11zm-1.7 1H4.2c-.11 0-.2.09-.2.2v.6c0 .11.09.2.2.2h7.6c.11 0 .2-.09.2-.2v-.6c0-.11-.09-.2-.2-.2zm1.7-5c.276 0 .5.224.5.5v2c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5v-2c0-.276.224-.5.5-.5h11zM8.8 4H4.2c-.11 0-.2.09-.2.2v.6c0 .11.09.2.2.2h4.6c.11 0 .2-.09.2-.2v-.6c0-.11-.09-.2-.2-.2z" transform="translate(-202 -191) translate(194 183) translate(8 8)"/>';
        break;
      case 'note':
        path = '<path xmlns="http://www.w3.org/2000/svg" d="M11.5 2c.276 0 .5.224.5.5V3c.552 0 1 .448 1 1v9c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1v-.5c0-.276.224-.5.5-.5h7zm-1.7 9H5.2c-.11 0-.2.09-.2.2v.6c0 .11.09.2.2.2h4.6c.11 0 .2-.09.2-.2v-.6c0-.11-.09-.2-.2-.2zm1-2H5.2c-.11 0-.2.09-.2.2v.6c0 .11.09.2.2.2h5.6c.11 0 .2-.09.2-.2v-.6c0-.11-.09-.2-.2-.2zm-1-2H5.2c-.11 0-.2.09-.2.2v.6c0 .11.09.2.2.2h4.6c.11 0 .2-.09.2-.2v-.6c0-.11-.09-.2-.2-.2zm0-4H6.2c-.092 0-.17.062-.193.147L6 3.2v1.6c0 .092.062.17.147.193L6.2 5h3.6c.092 0 .17-.062.193-.147L10 4.8V3.2c0-.092-.062-.17-.147-.193L9.8 3z" transform="translate(-202 -239) translate(194 231) translate(8 8)"/>';
        break;
      case 'file':
        path = '<path xmlns="http://www.w3.org/2000/svg" d="M8.834 2c.106 0 .208.042.283.117l3.766 3.766c.075.075.117.177.117.283V13c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V3c0-.552.448-1 1-1h4.834zM8.03 3.17c-.018.02-.029.045-.029.071V6.9c0 .055.045.1.1.1h3.659c.055 0 .1-.045.1-.1 0-.027-.011-.052-.03-.07L8.171 3.17c-.04-.038-.103-.038-.142 0z" transform="translate(-202 -287) translate(194 279) translate(8 8)"/>';
        break;
      case 'contact':
      case 'group':
        path = '<path xmlns="http://www.w3.org/2000/svg" d="M10.54 9c1.43 0 2.662 1.01 2.942 2.412l.279 1.392c.108.541-.243 1.068-.785 1.177-.064.012-.13.019-.196.019H3.22c-.552 0-1-.448-1-1 0-.066.006-.132.02-.196l.278-1.392C2.798 10.009 4.029 9 5.459 9h5.082zM8 2c1.657 0 3 1.343 3 3S9.657 8 8 8 5 6.657 5 5s1.343-3 3-3z" transform="translate(-202 -335) translate(194 327) translate(8 8)"/>';
        break;
      case 'folder':
      case 'message-folder':
        path = '<path xmlns="http://www.w3.org/2000/svg" d="M2 3.6C2 2.716 2.716 2 3.6 2h2.15c.735 0 1.375.5 1.553 1.212L7.499 4H12.4c.884 0 1.6.716 1.6 1.6v6.8c0 .884-.716 1.6-1.6 1.6H3.6c-.884 0-1.6-.716-1.6-1.6V3.6z" transform="translate(-439 -143) translate(431 135) translate(8 8)"/>';
        break;
      case 'notebook':
        path = '<path xmlns="http://www.w3.org/2000/svg" d="M12.5 2c.276 0 .5.224.5.5v11c0 .276-.224.5-.5.5h-8c-.276 0-.5-.224-.5-.5V13h-.8c-.11 0-.2-.09-.2-.2v-.6c0-.11.09-.2.2-.2H4v-1h-.8c-.11 0-.2-.09-.2-.2v-.6c0-.11.09-.2.2-.2H4V9h-.8c-.11 0-.2-.09-.2-.2v-.6c0-.11.09-.2.2-.2H4V7h-.8c-.11 0-.2-.09-.2-.2v-.6c0-.11.09-.2.2-.2H4V5h-.8c-.11 0-.2-.09-.2-.2v-.6c0-.11.09-.2.2-.2H4V2.5c0-.276.224-.5.5-.5h8zm-1.7 2H6.2c-.11 0-.2.09-.2.2v1.6c0 .11.09.2.2.2h4.6c.11 0 .2-.09.2-.2V4.2c0-.11-.09-.2-.2-.2z" transform="translate(-439 -239) translate(431 231) translate(8 8)"/>';
        break;
    }

    switch (node['type']) {
      case 'tag':
        color = '#73b5fe';
        break;
      case 'knot':
        color = '#b38ff0';
        break;
      case 'connection':
        color = 'gray';
        break;
      default:
        color = node['color'] || '#73b5fe';
    }

    return `<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
        <g fill="none" fill-rule="evenodd">
          <g fill="${color}" fill-rule="nonzero">
            ${path}
          </g>
        </g>
      </svg>
    `;
  }

  showMore() {
    this.limit = 1000;
    this.fetch.next();
  }

  showLess() {
    this.limit = 100;
    this.fetch.next();
  }

  mergePages(
    data: { links: ChartLink[], nodes: ChartNode[] },
    nextPage: { links: ChartLink[], nodes: ChartNode[] }
  ) {
    const result = { nodes: [...data.nodes], links: [...data.links] };

    const nodesIds = result.nodes.map(i => i.id);

    nextPage.nodes.forEach(node => {
      if (!result.nodes.find(i => i.id === node.id)) {
        nodesIds.push(node.id);
        result.nodes.push(node);
      }
    });

    nextPage.links.forEach(link => {
      if (
        !result.links.find(i => i.source === link.source && i.target === link.target) &&
        nodesIds.includes(link.source) &&
        nodesIds.includes(link.target)
      ) {
        result.links.push(link);
      }
    });

    return result;
  }

  truncateText(text: string, ctx: CanvasRenderingContext2D, maxWidth: number) {
    let truncatedText = text;
    const ellipsis = '...';
    const ellipsisWidth = ctx.measureText(ellipsis).width;
  
    while (ctx.measureText(truncatedText).width + ellipsisWidth > maxWidth && truncatedText.length > 0) {
      truncatedText = truncatedText.slice(0, -1);
    }
  
    return truncatedText + ellipsis;
  }
}
