<div class="comment">
  <div class="avatar-container">
    <img class="avatar" [src]="comment ? participant?.avatar : account?.avatar">
  </div>
  <div class="content">
    <div
      *ngIf="comment"
      class="heading"
    >
      <div class="user">{{ participant?.fullName }}</div>
      <div class="date">{{ comment?.createdAt | timeAgo }}</div>
    </div>

    <stch-resizable
      *ngIf="editMode"
      [minHeight]="200"
    >
      <app-rich-text-editor
        [control]="form.controls['text']"
      ></app-rich-text-editor>

      <app-input-errors
        *ngIf="submitted"
        [inputFormControl]="form.controls['text']"
        [validationMessages]="{ required: 'Can\'t be blank' }"
      ></app-input-errors>
    </stch-resizable>

    <div
      *ngIf="!editMode"
      [innerHtml]="comment?.text | safeHtml"
    ></div>

    <div
      *ngIf="!editMode"
      class="links"
    >
      <div class="link" (click)="handleEdit()">Edit</div>
      <div class="link" (click)="handleDelete()">Delete</div>
    </div>

    <div
      *ngIf="editMode"
      class="buttons"
    >
      <app-button
        *ngIf="comment"
        class="form-buttons-item"
        label="CANCEL"
        appearance="purple-outline"
        (click)="handleCancel()"
      />

      <app-button
        class="buttons-item"
        label="SAVE"
        appearance="purple"
        (click)="handleSubmit()"
      />
    </div>
  </div>
</div>
