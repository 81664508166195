// Common
import { NgModule } from '@angular/core';

// Modules
import { CommonModule as AppCommonModule } from '../common/common.module';
import { CommonModule } from '@angular/common';
import { ContactsModule } from '@modules/contacts/contacts.module';
import { ElementsModule } from '@modules/elements/elements.module';
import { CalendarAppModule } from '@modules/calendar-app/calendar-app.module';
import { FilesModule } from '@modules/files/files.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@modules/full-calendar/full-calendar.module';
import { KnotsModule } from '@modules/knots/knots.module';
import { KnowledgeModule } from '@modules/knowledge/knowledge.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MessagesModule } from '@modules/messages/messages.module';
import { NotesModule } from '@modules/notes/notes.module';
import { PopoverModule } from '@modules/popover/popover.module';
import { SearchModule } from '@modules/search/search.module';
import { SplitViewModule } from '@modules/split-view/split-view.module';
import { TasksModule } from './../tasks/tasks.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { ContactsContextMenuComponent } from './components/contacts-context-menu/contacts-context-menu.component';
import { ContactsPanelComponent } from './components/contacts-panel/contacts-panel.component';
import { DynamicPanelComponent } from './components/dynamic-panel/dynamic-panel.component';
import { EventsContextMenuComponent } from './components/events-context-menu/events-context-menu.component';
import { EventsPanelComponent } from './components/events-panel/events-panel.component';
import { FilesContextMenuComponent } from './components/files-context-menu/files-context-menu.component';
import { FilesPanelComponent } from './components/files-panel/files-panel.component';
import { FormsContainerComponent } from './components/forms-container/forms-container.component';
import { MessagesContextMenuComponent } from './components/messages-context-menu/messages-context-menu.component';
import { MessagesPanelComponent } from './components/messages-panel/messages-panel.component';
import { NotesContextMenuComponent } from './components/notes-context-menu/notes-context-menu.component';
import { NotesPanelComponent } from './components/notes-panel/notes-panel.component';
import { TasksContextMenuComponent } from './components/tasks-context-menu/tasks-context-menu.component';
import { TasksPanelComponent } from './components/tasks-panel/tasks-panel.component';

// Directives
import { ContactsContextMenuDirective } from './directives/contacts-context-menu.directive';
import { EventsContextMenuDirective } from './directives/events-context-menu.directive';
import { FilesContextMenuDirective } from './directives/files-context-menu.directive';
import { MessagesContextMenuDirective } from './directives/messages-context-menu.directive';
import { NotesContextMenuDirective } from './directives/notes-context-menu.directive';
import { TasksContextMenuDirective } from './directives/tasks-context-menu.directive';

@NgModule({
  imports: [
    AppCommonModule,
    CalendarAppModule,
    CommonModule,
    ContactsModule,
    ElementsModule,
    FilesModule,
    FormControlsModule,
    FormsModule,
    FullCalendarModule,
    KnotsModule,
    KnowledgeModule,
    MatIconModule,
    MatTooltipModule,
    MessagesModule,
    NotesModule,
    PopoverModule,
    SearchModule,
    SplitViewModule,
    TasksModule,
    UserAvatarModule,
    UtilsModule,
  ],
  declarations: [
    // Components
    ContactsContextMenuComponent,
    ContactsPanelComponent,
    DynamicPanelComponent,
    EventsContextMenuComponent,
    EventsPanelComponent,
    FilesContextMenuComponent,
    FilesPanelComponent,
    FormsContainerComponent,
    MessagesContextMenuComponent,
    MessagesPanelComponent,
    NotesContextMenuComponent,
    NotesPanelComponent,
    TasksContextMenuComponent,
    TasksPanelComponent,

    // Directives
    ContactsContextMenuDirective,
    EventsContextMenuDirective,
    FilesContextMenuDirective,
    MessagesContextMenuDirective,
    NotesContextMenuDirective,
    TasksContextMenuDirective,
  ],
  exports: [
    DynamicPanelComponent,
  ],
  providers: []
})
export class DynamicPanelModule { }
