// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Modules
import { CommonModule as AppCommonModule } from '../common/common.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Services
import { AccountService } from './services/account.service';
import { SignaturesService } from '@modules/account/services/signatures.service';
import { TemplatesService } from '@modules/account/services/templates.service';
import { QuickReplyTemplatesService } from '@modules/account/services/quick-reply-templates.service';
import { AutoReplyTemplatesService } from '@modules/account/services/auto-reply-templates.service';
import { SessionsService } from '@modules/account/services/sessions.service';

@NgModule({
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    FormControlsModule,
    UtilsModule,
  ],
  exports: [],
  declarations: [],
  providers: [
    // TODO move SignaturesService, TemplatesService, QuickReplyTemplatesService, AutoReplyTemplatesService to Mail App 
    AccountService,
    SignaturesService,
    TemplatesService,
    QuickReplyTemplatesService,
    AutoReplyTemplatesService,
    SessionsService,
  ]
})
export class AccountModule { }
