<div class="heading1">Settings</div>
<div class="heading2-subtitle">File App</div>

<div class="block column">
  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.openInListView"
    title="Always open in list view"
    appearance="new"
  ></app-checkbox>

  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.browseInListView"
    title="Browse in list view"
    appearance="new"
  ></app-checkbox>

  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.useRelativeDates"
    title="Use relative dates"
    appearance="new"
  ></app-checkbox>

  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.calculateAllSizes"
    title="Calculate all sizes"
    appearance="new"
  ></app-checkbox>
</div>

<div class="block column">
  <div class="heading2">Group By</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.groupBy"
    [options]="sortOptions"
    appearance="amethyst"
  ></app-dropdown-input>
</div>

<div class="block column no-border">
  <div class="heading2">Sort By</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.sortBy"
    [options]="sortOptions"
    appearance="amethyst"
  ></app-dropdown-input>
</div>

<div class="block column">
  <div class="heading2">Show Date</div>

  <div class="body-primary area-code">Show the author in the page’s header for documents in Stitch App</div>

  <app-dropdown-multiple-input
    class="dropdown"
    [inputFormControl]="form.controls.showDate"
    [options]="showDateOptions"
    appearance="amethyst"
  ></app-dropdown-multiple-input>
</div>
