// Common
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';

// Types
import { TableColumn } from '@modules/common/types/table-column';
import { SortOrder } from '@modules/common/types/sort-order';
import { Charge } from '@modules/settings/types/charge';
import { ChargesFilters } from '@modules/settings/types/charges-filters';

// Services
import { ChargesService } from '@modules/settings/services/charges.service';

// RX
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-settings-general-charges',
  templateUrl: './charges.component.html',
  styleUrls: ['./charges.component.less']
})
export class ChargesComponent implements OnInit, OnDestroy {

  public charges: Charge[];
  public page = 0;
  public perPage = 10;
  public sortBy: ChargesFilters['sortBy'] = 'createdAt';
  public sortOrder: SortOrder = 'desc';
  public count = 0;
  public columns: TableColumn[] = [];

  private reload = new Subject<void>();
  private alive = new Subject<void>();

  @ViewChild('cardTemplate', { static: true }) cardTemplate: TemplateRef<void>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<void>;
  @ViewChild('currencyTemplate', { static: true }) currencyTemplate: TemplateRef<void>;

  constructor (
    private chargesService: ChargesService,
  ) {}

  ngOnInit() {
    this.columns = [
      { key: 'amount', title: 'Amount', template: this.currencyTemplate },
      { key: 'type', title: 'Type' },
      { key: 'paymentMethod.type', title: 'Payment Method' },
      { key: 'paymentMethod.cardName', title: 'Card Name' },
      { key: 'paymentMethod.cardBrand', title: 'Card Brand' },
      { key: 'paymentMethod.cardLast4', title: 'Card Number ', template: this.cardTemplate },
      { key: 'createdAt', title: 'Created At', sortable: true, template: this.dateTemplate },
      { key: 'error', title: 'Error' },
    ];

    this.reload
      .pipe(
        debounceTime(400),
        switchMap(() => this.chargesService.search({
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          limit: this.perPage,
          offset: this.page * this.perPage
        })),
        takeUntil(this.alive)
      )
      .subscribe(({ items: charges, count }) => {
        this.charges = charges;
        this.count = count;
      });

    this.reload.next();

    this.chargesService.getRefresh()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.reload.next();
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handlePageChange(page: number) {
    this.page = page;
    this.reload.next();
  }

  handleSortByChange(sortBy: ChargesFilters['sortBy']) {
    this.sortBy = sortBy;
    this.reload.next();
  }

  handleSortOrderChange(sortOrder: SortOrder) {
    this.sortOrder = sortOrder;
    this.reload.next();
  }
}
