// Common
import { Directive, ElementRef, OnDestroy, OnInit, NgZone, ViewContainerRef, ComponentRef, Injector, HostBinding } from '@angular/core';

// RX
import { Subject } from 'rxjs';

// Components
import { TextSelectionDirective } from './text-selection.directive'
import { SelectionTooltipComponent } from '../components/selection-tooltip/selection-tooltip.component';

// Types
import { Action } from '../types/action';

@Directive({
  selector: "[textSelectionTooltip]",
})
export abstract class TextSelectionTooltipDirective extends TextSelectionDirective implements OnInit, OnDestroy {

  @HostBinding('style.position') position: string = 'relative';

  private viewContainerRef: ViewContainerRef;
  private rectangleRef: ComponentRef<SelectionTooltipComponent>;

  protected alive = new Subject<void>();
  protected actions: Action[] = [];

  constructor(
    private injector: Injector,
  ) {
    super(injector.get(ElementRef), injector.get(NgZone));
    this.viewContainerRef = injector.get(ViewContainerRef);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    super.ngOnInit();

    this.rectangleRef = this.viewContainerRef.createComponent(SelectionTooltipComponent, { injector: this.injector });
    this.rectangleRef.instance.selectEvent = this.textSelect;
    this.rectangleRef.instance.actions = this.actions;

    this.viewContainerRef.element.nativeElement.appendChild(this.rectangleRef.location.nativeElement);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.alive.next();
    this.alive.complete();
  }
}
