// Common
import { Directive, Input, ComponentRef, Output, EventEmitter, OnInit } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { NotebookContextMenuComponent } from '../components/context-menus/notebook-context-menu/notebook-context-menu.component';

// Types
import { Notebook } from '@modules/notes/types/notebook';
import { Note } from '@modules/notes/types/note';

// RX
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appNotebookContextMenu]'
})
export class NotebookContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() appNotebookContextMenu: Notebook;
  @Input() appNotebookContextMenuDisabled = false;
  @Input() appNotebookContextMenuMode: 'contextmenu' | 'popover' = 'contextmenu';

  // Outputs
  @Output() appNotebookContextMenuOpened = this.contextMenuOpened;
  @Output() appNotebookContextMenuOpenNotebook = new EventEmitter;
  @Output() appNotebookContextMenuOpenNote = new EventEmitter<Note>();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.stchPopoverDisabled = this.appNotebookContextMenuDisabled;

    if (this.appNotebookContextMenuMode === 'popover') {
      this.stchPopoverTrigger = 'click';
      this.stchPopoverPlacement = 'bottomLeft';
      this.stchPopoverFallbackPlacements = ['bottomRight'];
    }
  }

  /**
   * Actions
   */

  registerComponent() {
    return NotebookContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<NotebookContextMenuComponent>) {
    componentRef.instance.notebook = this.appNotebookContextMenu;
    componentRef.instance.open
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.appNotebookContextMenuOpenNotebook.emit();
      });

    componentRef.instance.openNote
      .pipe(takeUntil(this.alive))
      .subscribe(note => {
        this.appNotebookContextMenuOpenNote.emit(note);
      });
  }
}
