// Common
import { Component } from '@angular/core';

@Component({
  selector: 'app-files-layout',
  templateUrl: './files-layout.component.html',
  styleUrls: ['./files-layout.component.less'],
})
export class FilesLayoutComponent {

}
