<app-context-menu-item
  [title]="'Add ' + appearance"
>
  <app-add-knots
    *ngIf="appearance === 'knots'"
    (close)="closePopover()"
  ></app-add-knots>

  <app-add-tags
    *ngIf="appearance === 'tags'"
    (close)="closePopover()"
  ></app-add-tags>
</app-context-menu-item>

<app-context-menu-item
  [title]="'Manage ' + appearance"
>
  <app-knots-form
    *ngIf="appearance === 'knots'"
    (close)="closePopover()"
  ></app-knots-form>

  <app-tags-form
    *ngIf="appearance === 'tags'"
    (close)="closePopover()"
  ></app-tags-form>
</app-context-menu-item>

