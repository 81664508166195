// Common
import { Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';

// Types
import { BaseAppAdvancedFilters } from './base-app-advanced-filters';

const injector = Injector.create({ providers: [{ provide: UntypedFormBuilder, deps: [] }]});

export class FilesAppAdvancedFilters extends BaseAppAdvancedFilters {
  formBuilder = injector.get(UntypedFormBuilder);

  description?: string;
  fileSize?: { from?: number, to?: number };
  fileType?: string[];
  openedDate?: Date;
  receivedDate?: Date;
  title?: string;
  uploadDate?: Date;

  constructor(filters?: any) {
    super(filters);
    this.description = filters?.description  || '';
    this.fileSize = { from: filters?.fileSize?.from || null, to: filters?.fileSize?.to || null };
    this.fileType = Array.isArray(filters?.fileType) ? filters?.fileType : [];
    this.openedDate = filters?.openedDate ? new Date(filters.openedDate) : null;
    this.receivedDate = filters?.receivedDate ? new Date(filters.receivedDate) : null;
    this.title = filters?.title || '';
    this.uploadDate = filters?.uploadDate ? new Date(filters.uploadDate) : null;
  }

  static fromFormGroup(form: UntypedFormGroup): FilesAppAdvancedFilters {
    return new FilesAppAdvancedFilters(this.getFormValues(form));
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...super.commonFormGroupFields(),
      description: [this.description],
      fileSize: [this.fileSize],
      fileType: [this.fileType],
      openedDate: [this.openedDate],
      receivedDate: [this.receivedDate],
      title: [this.title],
      uploadDate: [this.uploadDate],
    });
  }
}
