// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RX
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

// Types
import { Coordinates } from '@modules/common/types/geo-location';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { GeoLocationService } from '@modules/common/services/geo-location.service';

// Env
import { environment } from '@environment';

@Injectable()
export class AuthService {

  authenticated: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
    private geoLocationService: GeoLocationService
  ) {
    this.authenticated = new BehaviorSubject<boolean>(this.isAuthenticated());
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
  }

  getAuthenticated() {
    return this.authenticated.asObservable();
  }

  public authenticate(token: string) {
    if (token) {
      localStorage.setItem('token', token);
      this.authenticated.next(true);
    } else {
      // localStorage.removeItem('token');
      this.authenticated.next(false);
    }
  }

  private handleObserverError(error: Error) {
    this.toasterService.show({ text: error?.['error']?.message });
    return throwError(error);
  }

  signOut(): Observable<boolean> {
    return this.http.post(environment.baseUrl + '/api/account/sessions/logout', {}, { withCredentials: true })
      .pipe(
        map(() => true),
        catchError(() => of(false)),
        tap(() => {
          localStorage.removeItem('token');
          this.authenticated.next(false);
        })
      );
  }

  signIn(code: string, redirectUri: string): Observable<boolean> {
    return this.http.post(environment.baseUrl + '/api/auth/google', {
      code: code,
      redirect_uri: redirectUri
    }, { withCredentials: true })
      .pipe(
        // Unify errors from API and other sources
        catchError(error => throwError({
            message: error.error.error || error.message,
            code: error.error.error_code || 'internal_error'
        })),
        map((data: {token: string, account_ready: boolean}) => {
          if (!data['token']) {
            throw new Error('Error while generating access token. Please try again.');
          }
          this.authenticate(data['token']);
          return !!data['account_ready'];
        })
      );
  }

  isUsernameTaken(username: string) {
    return this.http.post<{ isTaken: boolean, alternative: string }>(
      environment.baseUrl + '/api/account/usercheck',
      { username }
    )
      .pipe(
        catchError(error => this.handleObserverError(error))
      );
  }

  signUp(formValues) {
    return this.geoLocationService.getCoordinates()
      .pipe(
        switchMap((coordinates: Coordinates) => this.http.post<{ success: boolean, token: string }>(
            environment.baseUrl + '/api/account/signup',
            { ...formValues, ...coordinates },
            { withCredentials: true, observe: 'response' }
          )),
        tap(response => {
          if (response['body']['success']) {
            this.authenticate(response['body']['token']);
          }
        }),
        map(response => ({ success: response['body']['success']})),
        catchError(error => this.handleObserverError(error))
      );
  }

  signIn2(formValues) {
    return this.geoLocationService.getCoordinates()
      .pipe(
        switchMap((coordinates: Coordinates) => this.http.post<{ success: boolean, token: string }>(
          environment.baseUrl + '/api/account/signin',
          { ...formValues, ...coordinates },
          { withCredentials: true, observe: 'response' }
        )),
        tap(response => {
          if (response['body']['success']) {
            this.authenticate(response['body']['token']);
          }
        }),
        map(response => ({ success: response['body']['success']})),
        catchError(error => this.handleObserverError(error))
      );
  }
}
