<app-simple-input
  *ngIf="appearance === 'default'"
  [inputFormControl]="control"
  [placeholder]="placeholder"
  [suggestions]="suggestions"
/>

<ng-container *ngIf="appearance === 'sapphire-primary-ghost-button'">
  <app-button
    appearance="sapphire-primary-ghost"
    size="xs"
    [label]="currentOption?.value || placeholder"
    [disabled]="disabled"

    stchPopover
    stchPopoverPlacement="bottomRight"
    [stchPopoverFallbackPlacements]="[]"
    [stchPopoverContent]="popoverTemplate"
    [stchPopoverShowUntil]="popoverHide"
    stchPopoverTrigger="click"
    [stchPopoverDisabled]="disabled"
    [stchPopoverNoBorder]="true"
  />

  <ng-template #popoverTemplate>
    <div
      class="popover"
      [ngClass]="appearance"
    >
      <app-input
        appearance="sapphire-filled"
        [inputFormControl]="searchControl"
        placeholder="Search Time Zone"
        size="m"
        leftIcon="sapphire-search"
      />

      <div class="options">
        <div class="header">Default Time Zone</div>

        <app-dropdown-item
          appearance="sapphire"
          [option]="defaultTimeZone"
          [active]="defaultTimeZone.value === control?.value"
          (click)="handleSelect(defaultTimeZone)"
        />

        <div class="separator"></div>

        <app-dropdown-item
          *ngFor="let option of options"
          appearance="sapphire"
          [option]="option"
          [active]="option.value === control?.value"
          (click)="handleSelect(option)"
        />
      </div>
    </div>
  </ng-template>
</ng-container>
