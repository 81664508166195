// Common
import { Component, Injector, OnInit } from '@angular/core';
import { heightAnimation } from '@modules/common/animations/height.animation';

// Types
import { Notebook } from '@modules/notes/types/notebook';
import { Note } from '@modules/notes/types/note';
import { VirtualFolder } from '@modules/notes/types/virtual-folder';
import { StateKey } from '@modules/settings/types/state-key';
import { NotebooksFilters } from '@modules/notes/types/notebooks-filters';
import { Application } from '@modules/common/types/application';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Stitch } from '@modules/common/types/stitch';
import { NotebooksListState } from '@modules/notes/types/notebooks-list-state';

// Services
import { NotesAppStateService } from '@modules/notes/services/state.service';

// RX
import { takeUntil } from 'rxjs/operators';

// Component
import { BaseSidebarContainersItemComponent } from '@modules/common/components/base-sidebar-containers-item/base-sidebar-containers-item.component';

@Component({
  selector: 'app-side-bar-notebooks',
  templateUrl: './side-bar-notebooks.component.html',
  styleUrls: ['./side-bar-notebooks.component.less'],
  animations: [heightAnimation],
})
export class SideBarNotebooksComponent extends BaseSidebarContainersItemComponent<Notebook, Note, NotebooksFilters, VirtualFolder, NotebooksListState> implements OnInit {

  application = Application.notes;
  sidebarSplitViewKey = SidebarSplitViewKey.notes;
  listStateKey = StateKey.sidebarNotebooksListState;

  constructor(
    injector: Injector,
    notesStateService: NotesAppStateService,
  ) {
    super(injector, notesStateService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.itemsListState
      .pipe(takeUntil(this.alive))
      .subscribe(state => {
        this.filters = new NotebooksFilters().applyListState(state);
      });
  }
}
