<div
  *ngIf="!item"
  class="empty-state"
>
  <img class="empty-state-image" src="assets/placeholders/placeholder-notes.svg"/>
</div>

<ng-container
  *ngIf="item"
  [ngSwitch]="item | stitchType"
>
  <app-note-form
    *ngSwitchCase="StitchType.note"
    [note]="item"
    (close)="handleFormClose()"
  />

  <app-notebook-form
    *ngSwitchCase="StitchType.notebook"
    [notebook]="item"
    (close)="handleFormClose()"
  />
</ng-container>
