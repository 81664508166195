// Common
import { Component, Input } from '@angular/core';

// Types
import { MonthViewDay } from 'calendar-utils';

@Component({
  selector: 'stch-calendar-month-small-cell',
  templateUrl: './calendar-month-small-cell.component.html',
  styleUrls: ['./calendar-month-small-cell.component.less'],
})
export class CalendarMonthSmallCellComponent {
  @Input() day: MonthViewDay;
  @Input() selectedDate: Date;
  @Input() size: 's' | 'l' = 's';
  @Input() disabled = false;
  @Input() inRange = false;
}
