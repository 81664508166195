<div class="heading1">Users</div> 
<div class="heading2-subtitle">Users</div>

<app-tabs
  class="tabs"
  [values]="tabs"
  [(value)]="selectedTab"
></app-tabs>

<ng-container [ngSwitch]="selectedTab">
  <app-admin-users-users *ngSwitchCase="'users'"></app-admin-users-users>
</ng-container>
