// Common
import { Component, Input } from '@angular/core';

// Injection Tokens
import { INPUTS_GROUP } from '@modules/form-controls/injection-tokens/inputs-group.injection-token';

@Component({
  selector: 'stch-inputs-group',
  templateUrl: './inputs-group.component.html',
  styleUrls: ['./inputs-group.component.less'],
  providers: [{ provide: INPUTS_GROUP, useValue: true }]
})
export class InputsGroupComponent {
  @Input() size: 'xs' | 's' | 'm' | 'l' = 's';
  @Input() active = false;
}
