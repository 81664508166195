// Common
import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';

// Services
import { AccountService } from '@modules/account/services/account.service';

// Types
import { Application } from '@modules/common/types/application';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-context-menu',
  templateUrl: './app-context-menu.component.html',
})
export class AppContextMenuComponent implements OnInit, OnDestroy {

  @Input() app: Application;
  @Input() dp: boolean;

  @Output() appChange: EventEmitter<Application> = new EventEmitter();

  public apps: Application[] = [];

  private alive = new Subject<void>();

  constructor(
    private accountService: AccountService
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.accountService.getAccount()
      .pipe(takeUntil(this.alive))
      .subscribe(account => {
        this.apps = Object.values(Application).filter(app => 
          this.dp 
            ? app !== Application.settings && app !== Application.admin
            : app !== Application.kp && (account.admin ? true : app !== Application.admin)
        );
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  selectApp(key: Application) {
    this.appChange.emit(key);
  }
}
