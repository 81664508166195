// Common
import { Component, Input, Output, EventEmitter } from '@angular/core';

// Types
import { Tag } from '@modules/tags/types/tag';

@Component({
  selector: 'app-manage-tag',
  templateUrl: './manage-tag.component.html',
  styleUrls: ['./manage-tag.component.less']
})
export class ManageTagComponent {

  // Inputs
  @Input() tag: Tag;

  // Output
  @Output() delete = new EventEmitter();
  @Output() pin = new EventEmitter<void>();
  @Output() change = new EventEmitter<void>();

  // Public
  public focused = false;
  private changed = false;

  /**
   * Actions
   */

  handlePin() {
    if (!this.tag) { return; }
    this.pin.emit();
  }

  handleDelete() {
    if (!this.tag) { return; }
    this.delete.emit();
  }

  handleFocus() {
    this.focused = true;
  }

  handleBlur() {
    this.focused = false;
  }

  handleChange() {
    if (!this.tag || this.changed) { return; }
    this.changed = true;
    this.change.emit();
  }
}
