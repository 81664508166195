// Common
import { Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// Rx
import { Observable, throwError, Subject, forkJoin, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Types
import { ColumnsFilters } from '../types/columns-filters';
import { Column } from '../types/column';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';

// Env
import { environment } from '@environment';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

@Injectable()
export class ColumnsService extends BaseRestService<Column, ColumnsFilters> {

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService
  ) {
    super();
  }

  /**
   * Methods
   */

  search(filters?: Partial<ColumnsFilters>): Observable<BaseSearchResponse<Column>> {

    if (!filters?.projectsIds?.length) { return of({ items: [], count: 0 }); }

    const requestParams = { params: new ColumnsFilters(filters || {}).format() };

    return this.http.get<{ count: number, items: Column[] }>(
      environment.baseUrl + '/api/tasking/columns',
      requestParams
    )
      .pipe(
        map(({ count, items }) => ({
          count,
          items: items.map(item => new Column(item))
        })),
        catchError(error => this.handleObserverError(error))
      );
  }

  create(
    columnInstance: Column,
    { emit, toast, message }: FeedbackConfig = { emit: true }
  ): Observable<Column> {
    return this.http.post<{ column: Column, success: boolean }>(`${environment.baseUrl}/api/tasking/columns`, columnInstance.asPayloadJSON())
      .pipe(
        tap(({ success }) => {
          success && emit && this.forceRefresh();
          success && toast && this.toasterService.show({ text: message || `Column added.` });
        }),
        map(({ column }) => new Column(column)),
        catchError((error: HttpErrorResponse) => {
          this.toasterService.show({ text: error.error.error });
          return throwError(error);
        })
      );
  }

  @warmUpObservable
  update(columnInstance: Column): Observable<Column> {
    return this.http.put<{ column: Column, success: boolean }>(
      environment.baseUrl + '/api/tasking/columns/' + columnInstance.id, columnInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (success) {
            this.forceRefresh();
            this.toasterService.show({ text: `Column updated` });
          }
        }),
        map(({ column }) => new Column(column))
      );
  }

  reorder(columns: { id: string, position: number }[], config?: FeedbackConfig): Observable<boolean> {
    return this.http.post<{ success: boolean }>(environment.baseUrl + '/api/tasking/columns/reorder', { columns })
      .pipe(
        tap(({ success }) => {
          success && config?.emit && this.forceRefresh();
        }),
        map(({ success }) => success)
      );
  }

  deletePermanently(columnIds: string[], emitChanges = true): Observable<boolean> {
    return forkJoin(
      columnIds.map(columnId => this.http.delete<{ success: boolean }>(environment.baseUrl + '/api/tasking/columns/' + columnId, {}))
    )
      .pipe(
        map((results: { success: boolean }[]) => results.some(result => result.success)),
        tap(success => {
          if (success) {
            emitChanges && this.forceRefresh();
            this.toasterService.show({ text: `Column(s) successfully deleted.` });
          }
        })
      );
  }

  getAutocompleteSuggestions(defaultFilters: Partial<ColumnsFilters>): AutocompleteFactory<Column> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      const filters = new ColumnsFilters({ limit: config?.limit || 5, ...defaultFilters });

      if (values?.length) {
        filters.ids = values;
      }

      if (title) {
        filters.title = title;
      }

      return this.search(filters)
        .pipe(
          map((response: BaseSearchResponse<Column>) => response.items.map(column => ({
            title: column.title,
            value: column.id
          }))),
        );
    };
  }
}
