<ng-container *ngIf="!multiple">
  <app-context-menu-item
    title="Open"
    [close]="true"
    (execute)="handleOpen()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="event?.pinned ? 'Unpin' : 'Pin'"
    [close]="true"
    (execute)="pin()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="event?.flagged ? 'Unflag' : 'Flag'"
    [close]="true"
    (execute)="flag()"
  ></app-context-menu-item>

  <app-context-menu-divider *ngIf="selectable"></app-context-menu-divider>

  <app-context-menu-item
    title="Snooze"
  >
    <app-postpone-context-menu
      [items]="[event]"
      (onSelect)="postpone($event, 'snooze')"
    ></app-postpone-context-menu>
  </app-context-menu-item>

  <app-context-menu-item
    title="Follow up"
  >
    <app-postpone-context-menu
      [items]="[event]"
      appearance="followUp"
      (onSelect)="postpone($event, 'followUp')"
    ></app-postpone-context-menu>
  </app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    *ngIf="selectable"
    title="Select All"
    [close]="true"
    (execute)="selectAll()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Open in Modal"
    [close]="true"
    (execute)="openInModal()"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Open in Dynamic Panel"
    [close]="true"
    (execute)="openInDP()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Copy To"
  >
    <app-move-to
      appearance="copy"
      [items]="[event]"
      (close)="close()"
    ></app-move-to>
  </app-context-menu-item>

  <app-context-menu-item
    title="Move To"
  >
    <app-move-to
      appearance="move"
      [items]="[event]"
      (close)="close()"
    ></app-move-to>
  </app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    *ngIf="!event?.readOnly"
    title="Cut"
    [close]="true"
    (execute)="cut()"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Copy"
    [close]="true"
    (execute)="copy()"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Duplicate"
    (execute)="duplicate()"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Stitch"
  >
    <app-stitch-to
      [stitchItems]="[event]"
      (close)="close()"
    ></app-stitch-to>
  </app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Add Knots"
  >
    <app-add-knots
      [stitchItems]="[event]"
      (close)="close()"
    ></app-add-knots>
  </app-context-menu-item>

  <app-context-menu-item
    title="Add Tags"
  >
    <app-add-tags
      [stitchItems]="[event]"
      (close)="close()"
    ></app-add-tags>
  </app-context-menu-item>

  <app-context-menu-item
    title="Reprocess Knots"
    (execute)="reprocessKnots()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Print Event"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="event?.archived ? 'Restore From Archive' : 'Archive'"
    [close]="true"
    (execute)="archive()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="event?.deleted ? 'Restore From Trash' : 'Trash'"
    [close]="true"
    (execute)="trash()"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngIf="event?.deleted"
    title="Delete Permanently"
    [close]="true"
    (execute)="deletePermanently()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Settings"
    [close]="true"
    (execute)="openSettings()"
  ></app-context-menu-item>
</ng-container>

<ng-container *ngIf="multiple">
  <app-context-menu-item
    [title]="event?.pinned ? 'Unpin Selected' : 'Pin Selected'"
    [close]="true"
    (execute)="pin()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="event?.flagged ? 'Unflag Selected' : 'Flag Selected'"
    [close]="true"
    (execute)="flag()"
  ></app-context-menu-item>

  <app-context-menu-divider *ngIf="selectable"></app-context-menu-divider>

  <app-context-menu-item
    title="Snooze"
  >
    <app-postpone-context-menu
      [items]="selectedItems"
      (onSelect)="postpone($event, 'snooze')"
    ></app-postpone-context-menu>
  </app-context-menu-item>

  <app-context-menu-item
    title="Follow up"
  >
    <app-postpone-context-menu
      [items]="selectedItems"
      appearance="followUp"
      (onSelect)="postpone($event, 'followUp')"
    ></app-postpone-context-menu>
  </app-context-menu-item>

  <app-context-menu-item
    *ngIf="selectable"
    title="Select All"
    [close]="true"
    (execute)="selectAll()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Duplicate Selected"
    (execute)="duplicate()"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Stitch"
  >
    <app-stitch-to
      [stitchItems]="selectedItems"
      (close)="close()"
    ></app-stitch-to>
  </app-context-menu-item>

  <app-context-menu-item
    title="Stitch All Together"
    [close]="true"
    (execute)="stitchAllTogether()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Add Knots"
  >
    <app-add-knots
      [stitchItems]="selectedItems"
      (close)="close()"
    ></app-add-knots>
  </app-context-menu-item>

  <app-context-menu-item
    title="Add Tags"
  >
    <app-add-tags
      [stitchItems]="selectedItems"
      (close)="close()"
    ></app-add-tags>
  </app-context-menu-item>

  <app-context-menu-item
    title="Reprocess Knots"
    (execute)="reprocessKnots()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    [title]="event?.archived ? 'Restore From Archive Selected' : 'Archive Selected'"
    [close]="true"
    (execute)="archive()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="event?.deleted ? 'Restore Selected From Trash' : 'Trash Selected'"
    [close]="true"
    (execute)="trash()"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngIf="event?.deleted"
    title="Delete Selected Permanently"
    [close]="true"
    (execute)="deletePermanently()"
  ></app-context-menu-item>
</ng-container>
