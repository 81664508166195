// Common
import { Component, ViewChild, OnInit, Input, OnDestroy, NgZone, ElementRef, Renderer2, HostBinding } from '@angular/core';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';

// Rx
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, fromEvent, of, Subject } from 'rxjs';

const MIN_SIZE = 10;

@Component({
  selector: 'app-split-view',
  templateUrl: './split-view.component.html',
  styleUrls: ['./split-view.component.less']
})
export class SplitViewComponent implements OnInit, OnDestroy {
  @Input() side: 'left' | 'right';
  @Input() key: string;
  @Input() collapsedSize: number;
  @Input() magneticSize: number;
  @Input() withIcon = false;
  @Input() shadow = false;
  @HostBinding('class') @Input() direction: 'horizontal' | 'vertical' = 'horizontal';
  @Input() gutterSize = 8;
  @Input() iconTop: number;

  @ViewChild('left', { static: true }) leftArea: ElementRef;
  @ViewChild('right', { static: true }) rightArea: ElementRef;
  @ViewChild('gutter', { static: true }) gutter: ElementRef;

  public collapsed: boolean;
  public dragging = false;

  private alive: Subject<void> = new Subject();
  private watchMouse = new BehaviorSubject(false);
  private mouseDownTime: number;

  constructor(
    private splitService: SplitViewService,
    private split: ElementRef,
    private ngZone: NgZone,
    private renderer: Renderer2
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.renderer.setStyle(this.gutter.nativeElement, this.direction === 'horizontal' ? 'width' : 'height', this.gutterSize + 'px');

    combineLatest([
      this.splitService.size.get(this.key),
      this.splitService.collapsed.get(this.key)
    ])
      .pipe(
        debounceTime(100),
        takeUntil(this.alive)
      )
      .subscribe(([size, collapsed]) => {
        this.collapsed = collapsed;
        this.draw(collapsed ? this.collapsedSize : this.fitSize(size));
    });

    fromEvent(this.gutter.nativeElement, 'mousedown')
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.mouseDownTime = Date.now();
        this.dragging = true
        this.watchMouse.next(true);
      });

    this.watchMouse
      .pipe(
        switchMap(watch => watch ? fromEvent(window, 'mouseup') : of(null)),
        filter(event => !!event),
        takeUntil(this.alive)
      )
      .subscribe((event: MouseEvent) => {
        this.dragging = false
        this.watchMouse.next(false);

        if (Date.now() - this.mouseDownTime < 300) {
          this.handleGutterClick()
        } else {
          this.handleMouseMove(event, true);
        }
      });

    this.ngZone.runOutsideAngular(() => {
      this.watchMouse
        .pipe(
          switchMap(watch => watch ? fromEvent(window, 'mousemove') : of(null)),
          filter(event => !!event),
          takeUntil(this.alive)
        )
        .subscribe(event => this.handleMouseMove(event));
    })

    this.splitService.getToggleIconClick(this.key)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.toggle());
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  getFullSize() {
    return this.split.nativeElement[this.direction === 'horizontal' ? 'offsetWidth' : 'offsetHeight'];
  }

  handleMouseMove(event: MouseEvent, last = false) {
    const fullSize = this.getFullSize();
    const bounding = this.split.nativeElement.getBoundingClientRect();

    const size = this.fitSize(
      this.direction === 'horizontal'
        ? this.side === 'left' ? event.pageX - bounding.left : fullSize - event.pageX + bounding.left
        : this.side === 'left' ? event.pageY - bounding.top : fullSize - event.pageY + bounding.top
    )

    this.draw(size);

    this.splitService.emitSizeChanges();

    if (last) {
      this.splitService.size.set(size, this.key);
      this.splitService.collapsed.set(size < this.magneticSize, this.key);
    }
  }

  fitSize(size: number) {
    const fullSize = this.getFullSize();
    return Math.max(Math.min(fullSize - MIN_SIZE, size), this.collapsedSize);
  }

  draw(size: number) {
    this.renderer.setStyle(
      (this.side === 'left' ? this.leftArea : this.rightArea).nativeElement,
      this.direction === 'horizontal' ? 'width' : 'height',
      size + 'px'
    );

    this.renderer.setStyle(
      this.gutter.nativeElement,
      this.direction === 'horizontal' ? (this.side === 'left' ? 'left' : 'right') : (this.side === 'left' ? 'top' : 'bottom'),
      size - this.gutterSize / 2 + 'px'
    );
  }

  handleDragStart($event: MouseEvent) {
    return false;
  }

  close() {
    this.splitService.collapsed.set(true, this.key);
    this.splitService.emitSizeChanges();
  }

  open() {
    this.splitService.collapsed.set(false, this.key);
    this.splitService.emitSizeChanges();
  }

  toggle() {
    if (this.collapsed) {
      this.open();
    } else {
      this.close();
    }
  }

  handleGutterClick() {
    this.toggle();
  }
}
