// Common
import { Component, Injector, Input } from '@angular/core';

// Types
import { Note } from '@modules/notes/types/note';
import { Notebook } from '@modules/notes/types/notebook';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { Upload } from '@modules/common/types/upload';
import { Tab } from '@modules/common/types/tab';
import { DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

// Services
import { NotesService } from '@modules/notes/services/notes.service';
import { NotebooksService } from '@modules/notes/services/notebooks.service';
import { NotesAppStateService } from '@modules/notes/services/state.service';

// Components
import { FullFormBaseComponent } from '@modules/common/components/full-form-base/full-form-base.component';

@Component({
  selector: 'app-note-form',
  templateUrl: './note-form.component.html',
  styleUrls: ['./note-form.component.less']
})
export class NoteFormComponent extends FullFormBaseComponent<Note> {

  public changesKey = 'note';
  public tabs: Tab[] = [
    { title: 'Note', value: 'note'},
    { title: 'Details', value: 'details'},
    { title: 'Attachments', value: 'attachments'},
    { title: 'Stitch', value: 'stitch'},
    { title: 'Lab', value: 'lab'},
    { title: 'Comments', value: 'comments'},
  ];
  public tabsStateKey = 'ffNote';
  public suggestions: AutocompleteFactory<Notebook>;

  protected dragDataType = DragDataTypes.note;

  @Input() note = new Note();

  constructor(
    notesService: NotesService,
    notebooksService: NotebooksService,
    notesAppStateService: NotesAppStateService,
    injector: Injector,
  ) {
    super(injector, notesService, notesAppStateService);
    this.suggestions = notebooksService.getAutocompleteSuggestions();
  }

  handleEditorUploadedAttachment(upload: Upload) {
    this.form.controls['inlineUploads'].setValue(this.form.controls['inlineUploads'].value.concat(upload));
  }

  /**
   * Helpers
   */

  get stitchItem(): Note {
    return this.note;
  }

  protected shouldRefreshList(prev, current) {
    return Note.shouldRefreshList(prev, current);
  }

  protected fromFormGroup(): Note {
    return Note.fromFormGroup(this.form);
  }

  protected asFormGroup() {
    return this.note.asFormGroup();
  }
}
