<div
  class="date-time-show"
  [class.two-rows]="twoRows"
>
  <div class="date-time-show-group" [class.void]="!fromDate && !fromTime">
    <div class="date-time-show-group-item" [class.void]="!fromDate">
      {{ fromDate | date: dateFormat }}
    </div>
    <div class="date-time-show-group-item" [class.void]="!fromTime">
      {{ fromTime | date: timeFormat | lowercase }}
    </div>
  </div>

  <div *ngIf="!twoRows" class="date-time-show-separator"></div>

  <div class="date-time-show-group" [class.void]="!toDate && !toTime">
    <div class="date-time-show-group-item" [class.void]="!toDate">
      {{ toDate | date: dateFormat }}
    </div>
    <div class="date-time-show-group-item" [class.void]="!toTime">
      {{ toTime | date: timeFormat | lowercase }}
    </div>
  </div>
</div>
