export enum StateKey {
  sidebarKnotsState = 'g-knots',
  sidebarKnotsPopoverState = 'gp-knots',
  sidebarTagsState = 'g-tags',
  sidebarTagsPopoverState = 'gp-tags',

  messagesSidebarFilters = 'messages-sf',
  eventsSidebarFilters = 'events-sf',
  tasksSidebarFilters = 'tasks-sf',
  notesSidebarFilters = 'notes-sf',
  contactsSidebarFilters = 'contacts-sf',
  filesSidebarFilters = 'files-sf',

  mailSidebarFoldersCollapsed = 'mail-sfc',
  mailSidebarFoldersTree = 'mail-sft',
  filesSidebarFoldersCollapsed = 'files-sfc',
  filesSidebarFoldersTree = 'files-sft',
  notesSidebarNotebooksCollapsed = 'notes-snc',
  notesSidebarNotebooksTree = 'notes-snt',
  contactsSidebarGroupsCollapsed = 'contacts-sgc',
  contactsSidebarGroupsTree = 'contacts-sgt',
  taskingSidebarProjectsCollapsed = 'tasking-spc',
  taskingSidebarProjectsTree = 'tasking-spt',
  calendarSidebarCalendarsCollapsed = 'calendar-scc',
  calendarSidebarCalendarsTree = 'calendar-sct',

  messagesDPSidebarFilters = 'messages-dp-sf',
  eventsDPSidebarFilters = 'events-dp-sf',
  tasksDPSidebarFilters = 'tasks-dp-sf',
  notesDPSidebarFilters = 'notes-dp-sf',
  contactsDPSidebarFilters = 'contacts-dp-sf',
  filesDPSidebarFilters = 'files-dp-sf',

  messagesListState = 'messages',
  messagesFoldersListState = 'messages-folders',
  sidebarMessagesFoldersListState = 's-messages-folders',
  calendarsListState = 'calendars',
  eventsListState = 'events',
  sidebarCalendarsListState = 's-calendars',
  projectsListState = 'projects',
  sidebarProjectsListState = 's-projects',
  tasksListState = 'tasks',
  notebooksListState = 'notebooks',
  sidebarNotebooksListState = 's-notebooks',
  notesListState = 'notes',
  groupsListState = 'groups',
  sidebarGroupsListState = 's-groups',
  contactsListState = 'contacts',
  foldersListState = 'folders',
  sidebarFoldersListState = 's-folders',
  filesListState = 'files',

  messagesFoldersDPListState = 'messages-folders-dp',
  messagesDPListState = 'messages-dp',
  eventsDPListState = 'events-dp',
  projectsDPListState = 'projects-dp',
  tasksDPListState = 'tasks-dp',
  notebooksDPListState = 'notebooks-dp',
  notesDPListState = 'notes-dp',
  groupsDPListState = 'groups-dp',
  contactsDPListState = 'contacts-dp',
  foldersDPListState = 'folders-dp',
  filesDPListState = 'files-dp',

  messagesFFListState = 'messages-ff',
  eventsFFListState = 'events-ff',
  tasksFFListState = 'tasks-ff',
  notesFFListState = 'notes-ff',
  contactsFFListState = 'contacts-ff',
  filesFFListState = 'files-ff',

  kpResultsStitchType = 'kp-results-type',
  kpKnotsState = 'kp-knots',
  kpTagsState = 'kp-tags',
  kpConnectionsState = 'kp-connections',
  kpConnectionsSectionState = 'kp-connections-section',

  fullFormKnotsState = 'ff-knots',
  fullFormTagsState = 'ff-tags',
  fullFormConnectionsState = 'ff-connections',

  manageFormKnotsState = 'mf-knots',
  manageFormTagsState = 'mf-tags',
  fullFormChart = 'ff-chart',

  notifications = 'notifications',
}
