// Common
import { Component, Input, OnInit, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';

// Services
import { contextMenuStateIndicator } from '@modules/popover/types/context-menu-state-indicator';

// RX
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-context-menu-state-indicator',
  templateUrl: './context-menu-state-indicator.component.html',
  styleUrls: ['./context-menu-state-indicator.component.less'],
  providers: [{ provide: contextMenuStateIndicator, useFactory: () => new BehaviorSubject<boolean>(null) }]
})

export class ContextMenuStateIndicatorComponent implements OnInit, OnDestroy {

  public isDefault: boolean;

  private alive = new Subject<void>();

  @Input() svgIcon: string;
  @Input() appearance: 'amethyst' | 'sapphire' = 'amethyst';

  @Output() defaultChanged = new EventEmitter<boolean>();

  constructor(
    @Inject(contextMenuStateIndicator) private isDefaultStateObservable
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    if (!this.isDefaultStateObservable) { return; }

    this.isDefaultStateObservable
      .pipe(takeUntil(this.alive))
      .subscribe(isDefaultState => {
        this.isDefault = isDefaultState;
        this.defaultChanged.emit(isDefaultState);
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  handleReset(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.isDefaultStateObservable.next(true);
  }
}
