// Common
import { Injectable, Injector } from '@angular/core';
import { LSStringArray, LocalStorageItem } from 'src/app/decorators/local-storage.decorator';

// RX
import { Observable, BehaviorSubject, Subject, zip } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

// Types
import { CalendarEvent } from '@modules/calendar-app/types/calendar-event';
import { Clipboard } from '../types/clipboard';
import { Calendar } from '../types/calendar';
import { ListState } from '@modules/calendar-app/types/list-state';
import { VirtualFolder } from '../types/virtual-folder';
import { Application } from '@modules/common/types/application';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';
import { BaseAppStateService } from '@modules/common/services/base-app-state.service';

@Injectable()
export class CalendarAppStateService extends BaseAppStateService<Calendar, CalendarEvent, VirtualFolder> {

  private selectedEvents = new BehaviorSubject<CalendarEvent[]>([]);
  private selectedCalendarDate = new Subject<Date>();
  private scrollToDay = new Subject<Date>();
  private clipboard = new BehaviorSubject<Clipboard>(null);
  @LSStringArray({ lsKey: 'full-calendar.calendars-ids' }) private fullCalendarViewCalendars: LocalStorageItem<string[]>;

  protected application = Application.calendar;
  protected defaultVirtualFolder: VirtualFolder = 'all_events';

  constructor(
    private toasterService: ToasterService,
    protected stitchServiceFactory: StitchServiceFactory,
    injector: Injector,
  ) {
    super(injector);
  }

  /**
   * Actions
   */

  setSelectedEvents(events: CalendarEvent[]) {
    // recreate events array for proper ngOnChange handling
    this.selectedEvents.next([...events]);
  }

  getSelectedEvents(): Observable<CalendarEvent[]> {
    return this.selectedEvents.asObservable();
  }

  setSelectedCalendarDate(date: Date) {
    this.selectedCalendarDate.next(date);
  }

  getSelectedCalendarDate(): Observable<Date> {
    return this.selectedCalendarDate
      .pipe(
        distinctUntilChanged((date1, date2) => (
          date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
        )),
      );
  }

  setScrollToDay(day: Date) {
    this.scrollToDay.next(day);
  }

  getScrollToDay(): Observable<Date> {
    return this.scrollToDay
      .pipe(
        filter(day => !!day)
      );
  }

  addToClipboard(events: CalendarEvent[], clipboardType: 'cut' | 'copy') {
    this.clipboard.next({
      data: events.filter(event => !event.readOnly),
      type: clipboardType
    });

    this.toasterService.show({
      text: `Event${this.clipboard.value.data.length > 1 ? 's' : ''} ${clipboardType === 'copy' ? 'copied' : 'cut'}.`
    });
  }

  getClipboard(): Observable<Clipboard> {
    return this.clipboard.asObservable();
  }

  performPaste(date: Date) {
    const events = this.clipboard.value.data;

    if (events && events.length > 0) {
      const observables: Observable<CalendarEvent>[] = [];

      events.forEach(item => {
        const event = this.clipboard.value.type === 'copy' ? new CalendarEvent({...item, id: null}) : item;

        const duration = event.startTime && event.endTime && event.endTime.getTime() - event.startTime.getTime();

        if (duration) {
          event.startTime = date;
          event.endTime = new Date(date.getTime() + duration);
        }

        const service = this.stitchServiceFactory.getServiceByStitchType(event.getStitchType());
        observables.push(
          (this.clipboard.value.type === 'cut' ? service.update(event) : service.create(event)) as Observable<CalendarEvent>
        );
      });

      zip(...observables).subscribe(() => {
        this.toasterService.show({ text: `Event${events.length > 1 ? 's' : ''} has been succesfully pasted.` });
      });

      this.clipboard.next(null);
    }
  }

  getTabs(): Observable<ListState> {
    return this.getVirtualFolder()
      .pipe(
        map(folder => {
          switch (folder) {
            case 'all_events':
              return 'events';
            case 'all_calendars':
              return 'calendars';
            case 'archived':
            case 'deleted':
            case 'followed':
            case 'flagged':
            case 'snoozed':
              return 'tabs';
            default:
              return 'events';
          }
        })
      );
  }

  getFullCalendarView(): Observable<string[]> {
    return this.fullCalendarViewCalendars.get();
  }

  setFullCalendarView(ids: string[]) {
    this.fullCalendarViewCalendars.set(ids);
  }
}
