// Common
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

// RX
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Types
import { PermissionParticipant } from '@modules/messages/types/permission-participant';

// Services
import { PermissionParticipantsService } from '@modules/messages/services/permission-participants.service';

@Component({
  selector: 'app-settings-messages-permissions-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class PermissionsListComponent implements OnInit, OnDestroy {

  public participants: PermissionParticipant[] = [];
  public pagesCount = 0;
  public page = 0;
  public perPage = 10;

  private alive = new Subject<void>();
  private loadMore = new Subject<void>();

  @Input() type: PermissionParticipant['type'];

  constructor (
    private participantsService: PermissionParticipantsService,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.loadMore
      .pipe(
        debounceTime(300),
        switchMap(() => (
          this.participantsService.search({
            type: this.type,
            offset: this.page * this.perPage,
            limit: this.perPage,
            sortBy: 'createdAt',
            sortOrder: 'desc'
          })
        )),
        takeUntil(this.alive)
      )
      .subscribe(({ items, count }) => {
        this.pagesCount = Math.ceil(count / this.perPage);
        this.participants.length = count;
        this.participants = [
          ...this.participants.slice(0, this.page * this.perPage),
          ...items,
          ...this.participants.slice((this.page + 1) * this.perPage, count),
        ].filter(i => !!i);
      });

    this.participantsService.getRefresh()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.page = 0;
        this.loadMore.next();
      });

    this.loadMore.next();
  }

  ngOnDestroy() {
    this.loadMore.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  showMore() {
    this.page = Math.min(this.page + 1, this.pagesCount - 1);

    if (!this.participants[this.page * this.perPage]) {
      this.loadMore.next();
    }
  }

  showLess() {
    this.page = 0;
  }

  handleDelete(participant: PermissionParticipant) {
    this.participantsService.deletePermanently(participant.id)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {

      });
  }
}
