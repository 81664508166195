// Types
import { NotificationsType } from '@modules/notification/types/notifications-type';
import { StitchType } from '@modules/common/types/stitch-type';
import { Filters } from '@modules/common/types/filters';
import { Application } from '@modules/common/types/application';

// Utils
import { isNil } from '@modules/common/utils/base';
import { appToEntityTypes } from '@modules/common/utils/stitch';

export class NotificationsFilters extends Filters {
  unread: boolean;
  notificationType: NotificationsType;
  applications: (Application | 'all')[];

  constructor(filters) {
    super(filters);
    this.unread = filters.unread;
    this.notificationType = filters.notificationType;
    this.applications = filters.applications;
  }

  format(): { [param: string]: string | string[] | boolean | number; } {
    const result = {};

    if (this.ids) { result['ids[]'] = this.ids; }
    if (this.exceptIds) { result['exceptIds'] = this.exceptIds; }
    if (!isNil(this.unread)) { result['unread'] = this.unread; }
    if (this.notificationType) { result['notificationType'] = this.notificationType; }

    if (this.applications && !this.applications.includes('all')) {
      result['entityType[]'] = this.applications.map(app => appToEntityTypes[app]).flat();
    }

    if (!isNil(this.limit)) { result['limit'] = this.limit; }
    if (!isNil(this.offset)) { result['offset'] = this.offset; }

    return result;
  }
}
