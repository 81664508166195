// Common
import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';

// Utils
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';

// RX
import { Subject } from 'rxjs';

// Types
import { CalendarCellClickEvent } from '@modules/full-calendar/types/calendar-cell-click-event';
import { CalendarDropEvent } from '@modules/full-calendar/types/calendar-drop-event';
import { DragData } from '@modules/drag-n-drop/types/drag-data';

@Component({
  selector: 'stch-calendar-year',
  templateUrl: './calendar-year.component.html',
  styleUrls: ['./calendar-year.component.less'],
})
export class FullCalendarYearComponent implements OnChanges {
  @Input() viewDate: Date;
  @Input() selectedDate: Date;
  @Input() events: CalendarEvent[];

  @Output() dateClicked = new EventEmitter<CalendarCellClickEvent>();
  @Output() dateDblClicked = new EventEmitter<Date>();
  @Output() loadDayEvents = new EventEmitter<Date>();
  @Output() eventDropped = new EventEmitter<CalendarDropEvent>();

  public refreshCalendar = new Subject<void>();
  public months: Date[];
  public contextMenuOpened = false;
  public popoverClose = new Subject<void>();

  private selectedYear: number;

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('viewDate' in changes) {
      const year = (this.viewDate || new Date()).getFullYear();

      if (year !== this.selectedYear) {
        this.selectedYear = year;
        this.months = Array.from({ length: 12 }, (_, index) => new Date(year, index, 1));
        this.refreshCalendar.next();
      }
    }
  }

  clickDay(event: MouseEvent, date: Date, origin: HTMLElement) {
    this.loadDayEvents.emit(date);
    this.dateClicked.emit({date, originRef: new ElementRef(origin), event});
  }

  dblClickDay(date: Date) {
    this.dateDblClicked.emit(date);
  }

  handleLoadDayEvents(date: Date) {
    this.loadDayEvents.emit(date);
  }

  handleDrop(dragData: DragData, day: Date, origin: HTMLElement) {
    this.eventDropped.emit({
      dragData,
      newStart: beginningOfDay(day),
      newEnd: endOfDay(day),
      originRef: new ElementRef(origin)
    });
  }
}
