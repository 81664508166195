// Common
import { ComponentRef, Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { ConnectionContextMenuComponent } from '../components/connection-context-menu/connection-context-menu.component';

// Types
import { Connection } from '@modules/connections/types/connection';

@Directive({
  selector: '[stchConnectionContextMenu]'
})
export class ConnectionContextMenuDirective extends ContextMenuDirective implements OnInit {

  // Inputs
  @Input() stchConnectionContextMenu: Connection;
  @Input() stchConnectionContextMenuWithDetach = false;
  @Input() stchConnectionContextMenuDisabled = false;

  // Outputs
  @Output() stchConnectionContextMenuOpened = this.contextMenuOpened;
  @Output() stchConnectionContextMenuDelete = new EventEmitter<void>();
  @Output() stchConnectionContextMenuPinned = new EventEmitter<void>();

  /**
   * Livecycle
   */

  ngOnInit() {
    this.stchPopoverDisabled = this.stchConnectionContextMenuDisabled;
  }

  /**
   * Actions
   */

  registerComponent() {
    return ConnectionContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<ConnectionContextMenuComponent>) {
    componentRef.instance.connection = this.stchConnectionContextMenu;
    componentRef.instance.delete = this.stchConnectionContextMenuDelete;
    componentRef.instance.withDetach = this.stchConnectionContextMenuWithDetach;
  }
}
