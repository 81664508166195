// Common
import { Component, OnDestroy, Input, TemplateRef, OnInit, ChangeDetectorRef } from '@angular/core';

// RxJS
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';

// Service
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';
import { TagsService } from '@modules/tags/services/tags.service';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';
import { Tag } from '@modules/tags/types/tag';
import { ManageListState } from '@modules/tags/types/manage-list-state';
import { TagFilters } from '@modules/tags/types/tag-filters';
import { Knot } from '@modules/knots/types/knot';
import { Connection } from '@modules/connections/types/connection';

// Components
import { TabsComponent } from '@modules/common/components/tabs/tabs.component';

@Component({
  selector: 'app-tags-knowledge-section',
  templateUrl: './tags-section.component.html',
  styleUrls: ['./tags-section.component.less'],
})
export class KnowledgeTagsSectionComponent implements OnInit, OnDestroy {

  @Input() tabs: TemplateRef<TabsComponent>;

  public StitchType = StitchType;
  public state: ManageListState;
  public itemTags: Tag[] = [];
  public itemKnots: Knot[] = [];
  public itemConnections: Connection[] = [];
  public selectedTags: Tag[] = [];
  public neighborsTags: Tag[] = [];
  public stitchItem: Stitch;
  public pagesCount = 0;
  public page = 0;
  public perPage = 20;
  public knotsHistory: Knot[] = [];
  public tagsHistory: Tag[] = [];
  public connectionsHistory: Connection[] = [];

  private alive = new Subject<void>();
  private loadNeighbors = new Subject<void>();

  constructor (
    private kpService: KnowledgePanelService,
    private tagsService: TagsService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    combineLatest([
      this.kpService.getKnotsHistory(),
      this.kpService.getTagsHistory(),
      this.kpService.getConnectionsHistory()
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([knots, tags, connections]) => {
        this.knotsHistory = knots.flat();
        this.tagsHistory = tags.flat();
        this.connectionsHistory = connections.flat();
        this.page = 0;
        this.changeDetector.detectChanges();
        this.loadNeighbors.next();
      });

    this.loadNeighbors
      .pipe(
        debounceTime(500),
        map(() => [
          this.knotsHistory.length ? this.knotsHistory : this.itemKnots,
          this.tagsHistory.length ? this.tagsHistory : this.itemTags,
          this.connectionsHistory.length ? this.connectionsHistory : this.itemConnections
        ]),
        switchMap(([knots, tags, connections]) => (
          knots.length || tags.length || connections.length
            ? this.tagsService.getNeighbors({
                ...TagFilters.fromManageListState(this.state),
                ids: tags.map(({ id }) => id),
                knots,
                connections,
                offset: this.page * this.perPage,
                limit: this.perPage
              })
            : Promise.resolve({ tags: [], count: 0 })
        )),
        takeUntil(this.alive)
      )
      .subscribe(({ tags, count }) => {
        this.pagesCount = Math.ceil(count / this.perPage);
        this.neighborsTags.length = count;
        this.neighborsTags = [
          ...this.neighborsTags.slice(0, this.page * this.perPage),
          ...tags,
          ...this.neighborsTags.slice((this.page + 1) * this.perPage, count),
        ].filter(i => !!i);
      });

    this.kpService.getSelectedStitchItem()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(stitchItem => {
        this.stitchItem = stitchItem;
      });

    combineLatest([
      this.kpService.getStitchItemTags(),
      this.kpService.getStitchItemKnots(),
      this.kpService.getStitchItemConnections()
    ])
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(([tags, knots, connections]) => {
        this.itemTags = tags;
        this.itemKnots = knots;
        this.itemConnections = connections;
        this.page = 0;
        this.loadNeighbors.next();
      });
  }

  ngOnDestroy() {
    this.loadNeighbors.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClickItem(tag: Tag) {
    this.kpService.addTagToSelection(tag, false, false, 'kp');
  }

  showMore() {
    this.page = Math.min(this.page + 1, this.pagesCount - 1);
    if (
      !this.neighborsTags[this.page * this.perPage]
    ) {
      this.loadNeighbors.next();
    }
  }

  showLess() {
    this.page = 0;
  }

  setState(state: ManageListState) {
    this.state = state;
    this.page = 0;
    this.loadNeighbors.next();
  }
}
