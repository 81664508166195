// Common
import { Injectable, NgZone } from '@angular/core';

// RX
import { Observable, Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { filter, distinctUntilChanged, map } from 'rxjs/operators';

// Types
import { DragData } from '../types/drag-data';
import { DroppableArea } from '../types/droppable-area';
import { Bounding } from '../types/bounding';
import { DroppableAreaTrackingStrategy } from '../types/droppable-area-tracking-strategy';
import { DraggableBounding } from '../types/draggable-bounding';

@Injectable()
export class DragNDropService {

  private currentSpaceId: string;
  private dataThread = new BehaviorSubject<DragData>(null);
  private droppableAreas: DroppableArea[] = [];
  private enteredThread = new BehaviorSubject<symbol>(null);
  private leavedThread = new Subject<symbol>();
  private droppedThread = new Subject<symbol>();
  private deliveredThread = new Subject<DragData>();
  private droppedOutsideThread = new Subject<void>();
  private currentPlaceholderElement = new BehaviorSubject<HTMLElement>(null);
  private repositionThread = new Subject<boolean>();
  private draggableBounding = new Subject<DraggableBounding>();
  private boundingCache: { [key: symbol]: { timestamp: number, bounding: Bounding } } = {};
  private dragIds = new Subject<string[]>();

  constructor(
    private ngZone: NgZone
  ) {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'mouseup')
        .pipe(
          filter(() => !!this.dataThread.value),
        )
        .subscribe((event: MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
          this.ngZone.run(() => this.emitDrop());
        });
    });
  }

  setDragging(spaceId: string, dragData: DragData) {
    this.currentSpaceId = spaceId;
    this.dataThread.next(dragData);
  }

  getCurrentSpaceId() {
    return this.currentSpaceId;
  }

  getDraggingDataChanges(): Observable<DragData> {
    return this.dataThread.pipe(distinctUntilChanged());
  }

  registerDroppableArea(
    key: symbol,
    element: HTMLElement,
    zIndex: number,
    noReposition: boolean,
    predicateSatisfied: boolean,
    trackingStrategy: DroppableAreaTrackingStrategy
  ) {
    if (this.droppableAreas.some(area => area.key === key)) { return; }

    this.droppableAreas.push({
      key,
      entered: false,
      zIndex,
      noReposition,
      element,
      predicateSatisfied,
      trackingStrategy
    });
  }

  unregisterDroppableArea(key: symbol) {
    const index = this.droppableAreas.findIndex(area => area.key === key);

    delete this.boundingCache[key];

    if (index >= 0) {
      this.droppableAreas.splice(index, 1);
    }
  }

  checkDroppableAreas(x: number, y: number, top: number, left: number): void {
    const enteredAreas = [];

    this.droppableAreas.forEach((area: DroppableArea) => {
      const bounding = this.getAreaBounding(area);

      if (
        bounding &&
        bounding.x < x && bounding.x + bounding.width > x &&
        bounding.y < y && bounding.y + bounding.height > y
      ) {
        enteredAreas.push(area);
      }
    });

    let enteredArea: DroppableArea;

    if (enteredAreas.some(area => area.trackingStrategy === 'closestLeft')) {
      enteredArea = this.getClosestLeftArea(enteredAreas, left);
    } else if (enteredAreas.some(area => area.trackingStrategy === 'mousePointer')) {
      enteredArea = this.getTopMostArea(enteredAreas);
    }

    if (enteredArea && !enteredArea.entered) {
      enteredArea.entered = true;
      this.enteredThread.next(enteredArea.key);
    }

    this.droppableAreas.forEach(area => {
      if (area.entered && (!enteredArea || area.key !== enteredArea.key)) {
        area.entered = false;
        this.leavedThread.next(area.key);
      }
    });

    this.checkReposition(enteredArea, x, y);
  }

  emitDrop() {
    const enteredArea = this.droppableAreas
      .find((area: DroppableArea) => area.entered);

    if (enteredArea) {
      enteredArea.entered = false;
      // leave-event is emitted after drop-event and drop-outside-event
      this.droppedThread.next(enteredArea.key);
      this.leavedThread.next(enteredArea.key);
      enteredArea.predicateSatisfied && this.deliveredThread.next(this.dataThread.value);
    }

    if (!enteredArea || !enteredArea.predicateSatisfied) {
      this.droppedOutsideThread.next();
      this.repositionThread.next(false);
    } else {
      this.dataThread.next(null);
    }
  }

  getDragEnter(key: symbol): Observable<void> {
    return this.enteredThread
      .asObservable()
      .pipe(
        filter((threadKey: symbol) => key === threadKey),
        map(() => null)
      );
  }

  getDragLeave(key: symbol): Observable<void> {
    return this.leavedThread
      .asObservable()
      .pipe(
        filter((threadKey: symbol) => key === threadKey),
        map(() => null)
      );
  }

  getDrop(key: symbol): Observable<void> {
    return this.droppedThread
      .pipe(
        filter((threadKey: symbol) => key === threadKey),
        map(() => null)
      );
  }

  getDelivered() {
    return this.deliveredThread.asObservable();
  }

  getDropOutside(): Observable<void> {
    return this.droppedOutsideThread
      .asObservable()
      .pipe(
        map(() => null)
      );
  }

  setCurrentPlaceholder(placeholder: HTMLElement) {
    this.currentPlaceholderElement.next(placeholder);
  }

  getCurrentPlaceholder(): Observable<HTMLElement> {
    return this.currentPlaceholderElement.asObservable();
  }

  getRepositionThread(): Observable<boolean> {
    return this.repositionThread.pipe(distinctUntilChanged());
  }

  checkReposition(area: DroppableArea, x: number, y: number) {
    if (!this.currentPlaceholderElement) { return; }

    const { width: placeholderWidth, height: placeholderHeight } = this.currentPlaceholderElement.value.getBoundingClientRect();
    const tolerance = 15;

    if (!area || area.noReposition || !area.predicateSatisfied) {
      this.repositionThread.next(false);
      return;
    }

    const bounding = this.getAreaBounding(area);

    this.repositionThread.next(
      bounding &&
        bounding.x + bounding.width + tolerance > x &&
        bounding.x - tolerance < x &&
        bounding.y - tolerance < y &&
        bounding.y + bounding.height + tolerance > y &&
        (bounding.width < placeholderWidth || bounding.height < placeholderHeight)
    );
  }

  setDraggableBounding(adjustment: DraggableBounding) {
    this.draggableBounding.next(adjustment);
  }

  getDraggableBounding() {
    return this.draggableBounding.pipe(distinctUntilChanged());
  }

  private getAreaBounding(area: DroppableArea) {
    if (
      this.boundingCache[area.key] &&
      Date.now() - this.boundingCache[area.key].timestamp < 1000
    ) { return this.boundingCache[area.key].bounding; }

    const bounding = this.getVisibleBoundingRect(area.element);

    this.boundingCache[area.key] = { bounding, timestamp: Date.now() }

    return bounding;
  }

  private getVisibleBoundingRect(element: HTMLElement, childBounding?: Bounding): Bounding {
    let bounding = childBounding;

    if (!bounding) {
      const { left: x, top: y, width, height } = element.getBoundingClientRect();
      bounding = { x, y, width, height } ;
    }

    if (!element.parentElement) { return bounding; }

    const { y, height } = bounding;

    if (
      element.parentElement.scrollHeight > element.parentElement.clientHeight &&
      ['scroll', 'scroll-y', 'hidden'].includes(window.getComputedStyle(element.parentElement).overflow)
    ) {
      const { height: parentHeight, top: parentTop } = element.parentElement.getBoundingClientRect();

      if (y < parentTop) {
        if (y + height > parentTop) { // partially hidden
          bounding.y = parentTop;
          bounding.height = y + height - parentTop;
        } else {
          return null;
        }
      } else if (y + height > parentTop + parentHeight) {
        if (y < parentTop + parentHeight) { // partially hidden
          bounding.height = parentTop + parentHeight - y;
        } else {
          return null;
        }
      }
    }

    return this.getVisibleBoundingRect(element.parentElement, bounding);
  }

  private getTopMostArea(areas: DroppableArea[]): DroppableArea {
    return areas.reduce(
      (topMostArea: DroppableArea, area: DroppableArea) => (
        !topMostArea || area.zIndex > topMostArea.zIndex ? area : topMostArea
      ),
      null
    );
  }

  private getClosestLeftArea(areas: DroppableArea[], left: number): DroppableArea {
    return areas.reduce((closestArea: DroppableArea, area: DroppableArea) => {
      const bounding = this.getAreaBounding(area);
      const closestBounding = this.getAreaBounding(closestArea);

      return (
        area.trackingStrategy === 'closestLeft' &&
        (!closestArea || Math.abs(bounding.x - left) < Math.abs(closestBounding.x - left))
          ? area : closestArea
      );
    }, null);
  }

  setDragIds(ids: string[]) {
    this.dragIds.next(ids);
  }

  getDragIds() {
    return this.dragIds.asObservable();
  }
}
