import { Component, Input } from '@angular/core';
import { WeekDay } from '@angular/common';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-work-week-picker',
  templateUrl: './work-week-picker.component.html',
  styleUrls: ['./work-week-picker.component.less']
})
export class WorkWeekPickerComponent {
  // Inputs
  @Input() inputFormControl: FormControl;

  weekDays = WeekDay;

  constructor() { }

  handleSelect(weekDay: number) {
    this.inputFormControl.value.some(day => day === weekDay)
      ? this.inputFormControl.setValue(this.inputFormControl.value.filter(val => val !== weekDay))
      : this.inputFormControl.setValue([...this.inputFormControl.value, weekDay]);
  }
}
