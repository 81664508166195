<div
  #left
  class="area"
  [class.animated]="!dragging"
  [ngClass]="{ controlled: side === 'left' }"
>
  <ng-content select="[split-left-pane]"></ng-content>
  <ng-content select="[split-top-pane]"></ng-content>
</div>

<div
  #right
  class="area"
  [class.animated]="!dragging"
  [ngClass]="{ controlled: side === 'right' }"
>
  <ng-content select="[split-right-pane]"></ng-content>
  <ng-content select="[split-bottom-pane]"></ng-content>
</div>

<div
  #gutter
  class="gutter"
  [class.animated]="!dragging"
  [ngClass]="direction"
  (dragstart)="handleDragStart($event)"
>
  <div
    *ngIf="withIcon"
    class="icon-container"
    [style.left.px]="gutterSize / 2 - 10"
    [style.top.px]="iconTop"
  >
    <mat-icon
      class="icon"
      [class.expanded]="side === 'left' ? !collapsed : collapsed"
      svgIcon="chevron-right"
    ></mat-icon>
  </div>  
</div>
