<div class="color-picker-palette" >
  <div *ngFor="let colorSample of colors" class="color-container">
    <div class="round-picker" (click)="setColor(colorSample)" [ngStyle]="{ 'background-color': colorSample }">
      <mat-icon
        *ngIf="inputFormControl && inputFormControl.value === colorSample"
        svgIcon="check"
        class="check">
      </mat-icon>
    </div>
  </div>
  <div class="color-container">
    <div
      class="round-picker round-picker-custom"
      matTooltip="Add custom color"
      matTooltipPosition="above"
      stchPopover
      stchPopoverPlacement="right"
      [stchPopoverContent]="colorPickerDialog"
      stchPopoverTrigger="click"
      [stchPopoverShowUntil]="popoverShowUntil"
    >
      <mat-icon svgIcon="plus-small" class="check plus-small"></mat-icon>
    </div>
  </div>
</div>

<ng-template #colorPickerDialog >
  <div class="color-picker-container" (click)="preventClose($event)">
    <div class="title"> Choose custom color </div>
    <div class="description"> Choose a background color for this calendar. The text color will be automatically adjusted. </div>
    <div class="color-picker-body">
      <div class="saturation-palette">
        <!-- <saturation-component [hue]="colorPickerControl.hue" [(color)]="colorPickerControl.value" class="saturation-component"></saturation-component>
        <hue-component [(hue)]="colorPickerControl.hue" [(color)]="colorPickerControl.value" class="hue-component"></hue-component> -->
      </div>
      <div class="color-description">
        <!-- <div class="sample" [ngStyle]="{'background-color': colorPickerControl.value.toHexString()}" > A </div> -->
        <div class="input-value">
          <app-input placeholder="Hex" [inputFormControl]="hexFormControl"></app-input>
        </div>
      </div>
    </div>
    <div class="form-columns-buttons">
      <app-button class="form-columns-buttons-item" appearance="blue" type="submit" (click)="save()" label="Save" />
      <app-button class="form-columns-buttons-item" (click)="cancel()" label="Cancel" />
    </div>
  </div>
</ng-template>
