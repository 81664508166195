// Common
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { QuickReplyTemplate } from '@modules/account/types/quick-reply-template';

// Services
import { QuickReplyTemplatesService } from '@modules/account/services/quick-reply-templates.service';

@Component({
  selector: 'app-settings-messages-quick-reply-form',
  templateUrl: './quick-reply-form.component.html',
  styleUrls: ['./quick-reply-form.component.less']
})
export class QuickReplyFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() quickReplyTemplate: QuickReplyTemplate;

  public form: FormGroup<{
    id: FormControl<string>,
    title: FormControl<string>,
    content: FormControl<string>
  }>;

  private quickReplyChanged = new Subject<void>();
  private alive = new Subject<void>();

  constructor(
    private quickReplyTemplatesService: QuickReplyTemplatesService,
    @Inject(CloseToken) private closeToken
  ) { }

  ngOnInit(): void {
    this.quickReplyChanged
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.form = (this.quickReplyTemplate || new QuickReplyTemplate()).asFormGroup();
      });

    this.quickReplyChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('quickReplyTemplate' in changes) {
      this.quickReplyChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  handleSubmit() {
    const template = QuickReplyTemplate.fromFormGroup(this.form);

    this.quickReplyTemplatesService.upsert(template)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.handleClose());
  }

  handleClose() {
    this.closeToken.next();
  }

}
