<div class="form">
  <div id="card-elements"></div>
  <div class="form-buttons">
    <app-button
      class="form-buttons-item"
      appearance="purple-outline"
      label="CANCEL"
      (click)="handleCancel()"
    />

    <app-button
      class="form-buttons-item"
      appearance="purple"
      label="SAVE"
      [disabled]="loading"
      (click)="handleSubmit()"
    />
  </div>
</div>
