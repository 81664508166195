// Common
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Connection } from '@modules/connections/types/connection';

// Services
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Types
import { Participant } from '../../types/participant';

@Component({
  selector: 'app-contacts-inline-list',
  templateUrl: './contacts-inline-list.component.html',
  styleUrls: ['./contacts-inline-list.component.less']
})
export class ContactsInlineListComponent implements OnInit, OnDestroy {

  // Inputs
  @Input() contacts: Participant[];
  @Input() minLength = 2;
  @Input() label: string;

  public selectedConnections: Connection[];
  public collapsed = true;
  public selectedContact: Participant;

  private alive = new Subject<void>();

  constructor(
    private kpService: KnowledgePanelService
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.kpService.getSelectedConnections()
      .pipe(takeUntil(this.alive))
      .subscribe((connections: Connection[]) => {
        this.selectedConnections = connections;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  selectContact(contact: Participant, event: MouseEvent) {
    this.kpService.addConnectionToSelection(
      new Connection({ name: contact.address }),
      event.ctrlKey || event.shiftKey || event.metaKey,
      true,
      'ff'
    );
  }

  toggleContacts() {
    this.collapsed = !this.collapsed;
  }
}
