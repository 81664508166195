// Common
import { Component, Input, SimpleChanges, OnDestroy, TemplateRef, OnChanges, Optional, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Injection Tokens
import { INPUTS_GROUP } from '@modules/form-controls/injection-tokens/inputs-group.injection-token';

// Types
import { Icon } from '@modules/icons/types/icons';
import { PopoverPlacement } from '@modules/popover/types/placement';

// RX
import { Subject, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-base',
  templateUrl: './dropdown-base.component.html',
  styleUrls: ['./dropdown-base.component.less'],
})
export class DropdownBaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() control: UntypedFormControl;
  @Input() placeholder: string;
  @Input() withClear = false;
  @Input() appearance: 'default' | 'amethyst' | 'amethyst-simple' | 'sapphire' | 'sapphire-outline' | 'sapphire-inline' | 'sapphire-dark' = 'default';
  /**
   * [invertedColor] ]Only for 'amethyst' | 'amethyst-simple'
   */
  @Input() invertedColor = false;
  /**
   * [size] Only for 'sapphire' | 'sapphire-outline' | 'sapphire-inline' | 'sapphire-dark'
   */
  @Input() size: 's' | 'm' | 'l' = 'l';
  @Input() disabled = false;
  @Input() icon: Icon;
  @Input() iconOnly = false;
  @Input() iconColor: string;
  @Input() placement: PopoverPlacement = 'bottomFullWidth';
  @Input() valueTemplate: TemplateRef<unknown>;
  @Input() popoverTemplate: TemplateRef<unknown>;
  @Input() popoverHide = new Subject();

  public opened = false;
  public chevronIcon: Icon; 
  public hasValue = false;

  private alive = new Subject<void>();
  private controlChanged = new Subject<void>();

  constructor(
    @Optional() @Inject(INPUTS_GROUP) public insideGroup: boolean,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.controlChanged
      .pipe(
        switchMap(() => this.control?.valueChanges || of(null)),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        this.hasValue =
          value !== null &&
          value !== undefined &&
          !(value instanceof Array && value.length === 0)
      });

    this.controlChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('control' in changes) {
      this.controlChanged.next();
    }

    if ('appearance' in changes) {
      switch (this.appearance) {
        case 'default':
        case 'amethyst':
        case 'amethyst-simple':
          this.chevronIcon = 'dropdown-new';
          break;
        case 'sapphire':
        case 'sapphire-outline':
        case 'sapphire-inline':
        case 'sapphire-dark':
          this.chevronIcon = 'sapphire-chevron-down';
          break;
      }
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClear(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.control?.setValue(null);
  }
}
