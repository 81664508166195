// Common
import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Rx
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

// Types
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { Settings } from '../types/settings';

// Env
import { environment } from '@environment';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseService } from '@modules/common/services/base.service';

@Injectable()
export class SettingsService extends BaseService {
  private url = environment.baseUrl + '/api/admin/settings/';

  constructor(
    private http: HttpClient,
    private toaster: ToasterService,
  ) {
    super();
  }

  getSettings(): Observable<Settings> {
    return this.http.get<{ settings: Settings }>(this.url)
      .pipe(
        map(({ settings }) => new Settings(settings)),
        catchError(error => this.handleObserverError(error))
      );
  }

  update(
    instance: Settings,
    { emit, toast, message }: FeedbackConfig = { emit: false, toast: true }
  ): Observable<Settings> {
    return this.http.put<{ settings: Settings, success: boolean }>(
      this.url,
      instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }

          emit && this.forceRefresh();
          toast && this.toaster.show({ text: message || `Settings Updated.` });
        }),
        map(({ settings }) => new Settings(settings)),
        catchError(error => this.handleObserverError(error))
      );
  }
}
