<ng-container *ngIf="!multiple && !task?.parentId">
  <app-context-menu-item
    title="Open"
    [close]="true"
    (execute)="handleOpen()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="task?.pinned ? 'Unpin' : 'Pin'"
    [close]="true"
    (execute)="pin()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="task?.flagged ? 'Unflag' : 'Flag'"
    [close]="true"
    (execute)="flag()"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Snooze"
  >
    <app-postpone-context-menu
      [items]="[task]"
      (onSelect)="postpone($event, 'snooze')"
    ></app-postpone-context-menu>
  </app-context-menu-item>

  <app-context-menu-item
    title="Follow up"
  >
    <app-postpone-context-menu
      [items]="[task]"
      appearance="followUp"
      (onSelect)="postpone($event, 'followUp')"
    ></app-postpone-context-menu>
  </app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Add New Subtask"
  >
    <app-task-quick-form
      class="subtask-quick-form"
      [task]="quickTask"
      (close)="close()"
      (more)="handleSubtaskFormMore($event)"
      (afterSave)="close()"
    ></app-task-quick-form>
  </app-context-menu-item>

  <app-context-menu-divider *ngIf="selectable"></app-context-menu-divider>

  <app-context-menu-item
    *ngIf="selectable"
    title="Select All"
    [close]="true"
    (execute)="selectAll()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Open in Modal"
    [close]="true"
    (execute)="openInModal()"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Open in Dynamic Panel"
    [close]="true"
    (execute)="openInDP()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Copy To"
  >
    <app-move-to
      appearance="copy"
      [items]="[task]"
      (close)="close()"
    ></app-move-to>
  </app-context-menu-item>

  <app-context-menu-item
    title="Move To"
  >
    <app-move-to
      appearance="move"
      [items]="[task]"
      (close)="close()"
    ></app-move-to>
  </app-context-menu-item>

  <app-context-menu-item
    title="Stitch"
  >
    <app-stitch-to
      [stitchItems]="[task]"
      (close)="close()"
    ></app-stitch-to>
  </app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Add Knots"
  >
    <app-add-knots
      [stitchItems]="[task]"
      (close)="close()"
    ></app-add-knots>
  </app-context-menu-item>

  <app-context-menu-item
    title="Add Tags"
  >
    <app-add-tags
      [stitchItems]="[task]"
      (close)="close()"
    ></app-add-tags>
  </app-context-menu-item>

  <app-context-menu-item
    title="Reprocess Knots"
    (execute)="reprocessKnots()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Print Task"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Rename Task"
  >
    <div class="rename">
      <div class="rename-title">Rename Task</div>
      <app-simple-input
        class="rename-input"
        [inputFormControl]="form.controls.title"
        placeholder="Type the Name"
      ></app-simple-input>
      <div class="rename-buttons">
        <app-button
          class="rename-buttons-item"
          label="Cancel"
          appearance="link"
          (click)="close()"
        />

        <app-button
          class="rename-buttons-item"
          label="Save"
          appearance="link-blue"
          (click)="handleSubmit()"
        />
      </div>
    </div>
  </app-context-menu-item>

  <app-context-menu-item
    title="Duplicate"
    (execute)="duplicate()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="task?.archived ? 'Restore From Archive' : 'Archive'"
    [close]="true"
    (execute)="archive()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="task?.deleted ? 'Restore From Trash' : 'Trash'"
    [close]="true"
    (execute)="trash()"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngIf="task?.deleted"
    title="Delete Permanently"
    [close]="true"
    (execute)="deletePermanently()"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Settings"
    [close]="true"
    (execute)="openSettings()"
  ></app-context-menu-item>
</ng-container>

<ng-container *ngIf="multiple">
  <app-context-menu-item
    [title]="task?.pinned ? 'Unpin Selected' : 'Pin Selected'"
    [close]="true"
    (execute)="pin()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="task?.flagged ? 'Unflag Selected' : 'Flag Selected'"
    [close]="true"
    (execute)="flag()"
  ></app-context-menu-item>

  <app-context-menu-divider *ngIf="selectable"></app-context-menu-divider>

  <app-context-menu-item
    *ngIf="selectable"
    title="Select All"
    [close]="true"
    (execute)="selectAll()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Snooze"
  >
    <app-postpone-context-menu
      [items]="selectedItems"
      (onSelect)="postpone($event, 'snooze')"
    ></app-postpone-context-menu>
  </app-context-menu-item>

  <app-context-menu-item
    title="Follow up"
  >
    <app-postpone-context-menu
      [items]="selectedItems"
      appearance="followUp"
      (onSelect)="postpone($event, 'followUp')"
    ></app-postpone-context-menu>
  </app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Copy To"
  >
    <app-move-to
      appearance="copy"
      [items]="selectedItems"
      (close)="close()"
    ></app-move-to>
  </app-context-menu-item>

  <app-context-menu-item
    title="Move To"
  >
    <app-move-to
      appearance="move"
      [items]="selectedItems"
      (close)="close()"
    ></app-move-to>
  </app-context-menu-item>

  <app-context-menu-item
    title="Stitch"
  >
    <app-stitch-to
      [stitchItems]="selectedItems"
      (close)="close()"
    ></app-stitch-to>
  </app-context-menu-item>

  <app-context-menu-item
    title="Stitch All Together"
    [close]="true"
    (execute)="stitchAllTogether()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Add Knots"
  >
    <app-add-knots
      [stitchItems]="selectedItems"
      (close)="close()"
    ></app-add-knots>
  </app-context-menu-item>

  <app-context-menu-item
    title="Add Tags"
  >
    <app-add-tags
      [stitchItems]="selectedItems"
      (close)="close()"
    ></app-add-tags>
  </app-context-menu-item>

  <app-context-menu-item
    title="Reprocess Knots"
    (execute)="reprocessKnots()"
  ></app-context-menu-item>

  <app-context-menu-divider></app-context-menu-divider>

  <app-context-menu-item
    title="Duplicate Selected"
    (execute)="duplicate()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="task?.archived ? 'Restore From Archive Selected' : 'Archive Selected'"
    [close]="true"
    (execute)="archive()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="task?.deleted ? 'Restore Selected From Trash' : 'Trash Selected'"
    [close]="true"
    (execute)="trash()"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngIf="task?.deleted"
    title="Delete Selected Permanently"
    [close]="true"
    (execute)="deletePermanently()"
  ></app-context-menu-item>
</ng-container>

<ng-container *ngIf="!multiple && task?.parentId">
  <app-context-menu-item
    [title]="task?.pinned ? 'Unpin' : 'Pin'"
    [close]="true"
    (execute)="pin()"
  ></app-context-menu-item>

  <app-context-menu-item
    title="Move To"
  >
    <app-move-to
      appearance="move"
      [items]="[task]"
      (close)="close()"
    ></app-move-to>
  </app-context-menu-item>

  <app-context-menu-item
    title="Duplicate"
    (execute)="duplicate()"
  ></app-context-menu-item>

  <app-context-menu-item
    [title]="task?.deleted ? 'Restore From Trash' : 'Trash'"
    [close]="true"
    (execute)="trash()"
  ></app-context-menu-item>

  <app-context-menu-item
    *ngIf="task?.deleted"
    title="Delete Permanently"
    [close]="true"
    (execute)="deletePermanently()"
  ></app-context-menu-item>
</ng-container>
