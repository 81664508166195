<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <input
    class="form-title"
    placeholder="Untitled Event"
    [formControl]="form.controls.title"
    #titleInput
  />

  <app-color-input
    class="form-marker"
    icon="event"
    [control]="form.controls.color"
    [size]="16"
  ></app-color-input>

  <div class="form-checkboxes">
    <app-radio-button
      class="form-checkboxes-item"
      appearance="checkbox"
      [booleanValue]="true"
      title="All day"
      [inputFormControl]="form.controls.allDay"
    ></app-radio-button>

    <app-radio-button
      class="form-checkboxes-item"
      appearance="checkbox"
      [booleanValue]="true"
      title="Reminder"
      [inputFormControl]="form.controls.reminder"
    ></app-radio-button>
  </div>

  <div class="form-date">
    <div
      class="form-date-item"
      [class.selected]="dateButtonSelected === 'from'"
      [class.blank]="!form.controls.startDate.value && !form.controls.startTime.value"
      (click)="dateButtonSelected = 'from'"
    >
      {{ form.controls.startDate.value | dateTimeDisplay: form.controls.startTime.value: 'No Start Date' }}
    </div>
    <div class="form-date-separator"></div>
    <div
      class="form-date-item"
      [class.selected]="dateButtonSelected === 'to'"
      [class.blank]="!form.controls.endDate.value && !form.controls.endTime.value"
      (click)="dateButtonSelected = 'to'"
    >
      {{ form.controls.endDate.value | dateTimeDisplay: form.controls.endTime.value: 'No Due Date' }}
    </div>
    <div *ngIf="calendar" class="form-date-separator"></div>
    <div class="form-date-calendar">
      {{ calendar?.title }}
    </div>
  </div>

  <div class="form-quick-actions">
    <mat-icon
      class="form-quick-actions-item"
      [class.active]="(dateButtonSelected === 'from' ? form.controls.startDate?.value : form.controls.endDate?.value) | compareToRelativeDate: 'today'"
      svgIcon="quick-today"
      (click)="setDate('today')"
      matTooltip="Today"
    ></mat-icon>

    <mat-icon
      class="form-quick-actions-item"
      [class.active]="(dateButtonSelected === 'from' ? form.controls.startDate?.value : form.controls.endDate?.value) | compareToRelativeDate: 'tomorrow'"
      svgIcon="quick-tomorrow"
      (click)="setDate('tomorrow')"
      matTooltip="Tomorrow"
    ></mat-icon>

    <mat-icon
      class="form-quick-actions-item"
      [class.active]="(dateButtonSelected === 'from' ? form.controls.startDate?.value : form.controls.endDate?.value) | compareToRelativeDate: 'nextMonday'"
      svgIcon="quick-week"
      (click)="setDate('nextMonday')"
      matTooltip="Next Monday"
    ></mat-icon>

    <mat-icon
      class="form-quick-actions-item"
      [class.active]="(dateButtonSelected === 'from' ? form.controls.startDate?.value : form.controls.endDate?.value) | compareToRelativeDate: 'nextMonth'"
      svgIcon="quick-month"
      (click)="setDate('nextMonth')"
      matTooltip="Next Month"
    ></mat-icon>

    <app-date-time-popover
      [fromDate]="form.controls.startDate"
      [toDate]="form.controls.endDate"
      [fromTime]="form.controls.startTime"
      [toTime]="form.controls.endTime"
      [reminders]="form.controls.reminders"
    >
      <mat-icon
        class="form-quick-actions-item"
        svgIcon="quick-calendar"
        matTooltip="Custom Date"
      ></mat-icon>
    </app-date-time-popover>

    <mat-icon
      class="form-quick-actions-item"
      [class.active]="form.controls.calendarId.value"
      svgIcon="quick-calendar-move"
      matTooltip="Calendar"

      stchPopover
      [stchPopoverArrow]="true"
      stchPopoverPlacement="right"
      stchPopoverTrigger="click"
      [stchPopoverContent]="calendarsPopoverTemplate"
      [stchPopoverShowUntil]="popoverClose"
    ></mat-icon>
  </div>

  <div class="form-buttons">
    <div
      *ngIf="withMore"
      class="form-buttons-item"
      (click)="clickMore()"
    >
      More
    </div>

    <div class="form-buttons-filler"></div>

    <div
      *ngIf="withCancel"
      class="form-buttons-item form-buttons-item-cancel"
      (click)="!saveInProgress && cancel()"
    >
      Cancel
    </div>

    <div
      class="form-buttons-item"
      (click)="!saveInProgress && submit()"
    >
      Add
    </div>
  </div>
</div>

<ng-template #calendarsPopoverTemplate>
  <div class="calendars-popover">
    <div class="calendars-popover-title">
      Add to Calendar
    </div>
    <app-simple-input
      class="form-details-row-input"
      [suggestions]="calendarsSuggestions"
      [inputFormControl]="form.controls.calendarId"
      placeholder="Select Calendar"
      (onSelect)="handleSelectCalendar($event)"
    ></app-simple-input>

    <div class="calendars-popover-buttons">
      <app-button appearance="square" size="medium" (click)="handleCancelCalendars()" label="Cancel" />
      <app-button appearance="square-blue" size="medium" (click)="handleSaveCalendars()" label="Save" />
    </div>
  </div>

</ng-template>
