<app-sidebar-item
  *nestedFor="
    let item of items;
    let index = index;
    let depth = depth;
    let expandable = expandable;
    let expanded = expanded;
    stateKey: StateKey.filesSidebarFoldersTree;
  "
  [title]="item.title"
  [icon]="item.icon"
  [depth]="depth"
  [expandable]="expandable"
  [expanded]="expanded"
  [active]="sidebarFilter === item.id"
  (appClick)="selectFilter(item.id)"
  [appSidebarContextMenu]="item.id"

  #elRef
  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event, item.id, index)"
></app-sidebar-item>

<app-sidebar-items-divider />

<app-side-bar-folders
  (selectFilter)="selectFilter($event)"
/>

<app-sidebar-items-divider />

<app-sidebar-knots sidebarSplitViewKey="filesSidebar"></app-sidebar-knots>

<app-sidebar-items-divider></app-sidebar-items-divider>

<app-sidebar-tags sidebarSplitViewKey="filesSidebar"></app-sidebar-tags>

<ng-template #afterDropTemplate let-item=item>
  <app-file-quick-form
    [autofocus]="true"
    [title]="item.title"
    [file]="item"
    (more)="handleMore($event)"
    (close)="handleClose()"
  ></app-file-quick-form>
</ng-template>
