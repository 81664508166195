<div
  #contentContainer
  class="quick-form"
  appDroppable
  appDroppableHoverClass="drag-over"
  appDroppableZIndex="10"
  (appDroppableDrop)="handleDrop($event)"
  [style.minHeight.px]="(inline || expanded) ? formsSizes[currentFormSelector] : 40"
  [@collapseMotion]="{
    value: inline || expanded ? 'expanded' : 'collapsed',
    params: { height: formsSizes[currentFormSelector], contentHeight: contentHeight }
  }"
  (keyup.enter)="enterKeyPressed.next(); $event.stopPropagation()"
>
  <div
    class="quick-form-container"
    [class.visible]="inline || expanded"
  >
    <ng-content *ngIf="currentFormSelector === 'message-folder'" select="app-message-folder-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'message'" select="app-message2-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'event'" select="app-event-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'task'" select="app-task-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'project'" select="app-project-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'note'" select="app-note-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'notebook'" select="app-notebook-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'group'" select="app-group-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'contact'" select="app-contact-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'folder'" select="app-folder-quick-form"></ng-content>
    <ng-content *ngIf="currentFormSelector === 'file'" select="app-file-quick-form"></ng-content>
  </div>

  <div
    class="quick-form-heading"
    [class.hidden]="inline || expanded"
    (click)="expand()"
  >
    <div class="quick-form-heading-placeholder">{{ placeholder }}</div>
    <mat-icon
      svgIcon="plus-medium"
      class="quick-form-heading-plus-icon"
    ></mat-icon>
  </div>
</div>
