// Common
import { Component, Input, TemplateRef, OnInit, OnDestroy, OnChanges, SimpleChanges, ElementRef, EventEmitter, Output } from '@angular/core';

// Services
import { StateService } from '@modules/settings/services/state.service';

// RX
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

// Types
import { Icon } from '@modules/icons/types/icons';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.less'],
})
export class SidebarItemComponent implements OnInit, OnChanges, OnDestroy {

  @Input() icon: string;
  @Input() title: string;
  @Input() active: boolean;
  @Input() hover: boolean;
  @Input() badge: { template: TemplateRef<any>, context: object };
  @Input() iconColor = '';
  @Input() depth = 0;
  @Input() expandable = false;
  @Input() expanded: Subject<boolean>;
  @Input() actions: { icon: Icon, template: TemplateRef<any>, context: object, until: Observable<void> }[] = [];
  @Input() minimized: boolean;
  @Input() withCheckbox: boolean;
  @Input() checked: boolean;
  @Input() iconPadding = true;

  @Output() onCheck = new EventEmitter();

  public collapsed = true;

  private expandedChanged = new Subject<void>();
  private alive = new Subject<void>();

  constructor (
    protected stateService: StateService,
    public elementRef: ElementRef, // Accessible from outside
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.expandedChanged
      .pipe(
        filter(() => !!this.expanded),
        switchMap(() => this.expanded),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        this.collapsed = !value;
      });

    this.expandedChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('expanded' in changes) {
      this.expandedChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  trigger(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.expanded?.next(this.collapsed);
  }
}
