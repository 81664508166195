<div class="card">
  <div class="card-info">
    <div class="heading1">
      {{ plan.title }}
      {{ account?.subscriptionPlanId === plan.id ? '(Current)' : '' }}
    </div>

    <div class="heading2">${{ chargeTypeControl.value === 'monthly' ? plan.monthlyPrice : plan.annuallyPrice }}/{{ chargeTypeControl.value === 'monthly' ? 'month' : 'year' }}</div>

    <app-dropdown-input
      class="forms-item-input"
      [inputFormControl]="chargeTypeControl"
      placeholder="Charge Type"
      [options]="chargeTypeSidebarOptions"
    ></app-dropdown-input>

    <div class="body-primary">
      {{ plan.description }}
    </div>

    <div class="card-info-actions">
      <app-button
        *ngIf="account?.subscriptionPlanId !== plan.id || account?.chargeType !== chargeTypeControl.value"
        [label]="buttonTitle"
        appearance="purple-outline"
        (appClick)="select()"
        [disabled]="isCurrentPlan"
      />
    </div>
  </div>

  <div class="items">
    <div class="item">Max Disk Space: {{ plan.maxDiskSpace }}Gb</div>
    <div class="item">Additional Disk Space Per Gb: ${{ plan.additionalDiskSpacePricePerGb }}</div>
    <div class="item">Max Tags Count: {{ plan.maxTagsCount }}</div>
    <div class="item">Max Knots Count: {{ plan.maxKnotsCount }}</div>
    <div class="item">Max Connections Count: {{ plan.maxConnectionsCount }}</div>
    <div class="item">Max Inboxes: {{ plan.maxInboxes }}</div>
    <div class="item">Max Spaces: {{ plan.maxSpaces }}</div>
  </div>

  <div class="card-features">
    <div
      *ngFor="let feature of features"
      class="card-features-item"
      [class.active]="plan[feature.key]"
    >
      <mat-icon
        class="card-features-item-icon"
        [svgIcon]="plan[feature.key] ? 'check-mark' : 'close-thin'"
      ></mat-icon>

      <span class="body-secondary">{{ feature.title }}</span>
    </div>
  </div>
</div>
