import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';

@Component({
  selector: 'app-settings-notes-additional',
  templateUrl: './additional.component.html',
  styleUrls: ['./additional.component.less']
})
export class AdditionalComponent {
  // Public
  public form = new FormGroup({
    openOnStart: new FormControl<string>('lastVisited'),
    openSliteLinks: new FormControl<string>('browser'),
    readableLineLength: new FormControl<boolean>(true),
    addItemsToBottom: new FormControl<boolean>(true),
    showLinksPreview: new FormControl<boolean>(false)
  });
  public openOnStartOptions: DropdownSelectItem<unknown>[] = [
    { title: 'Last visited page', value: 'lastVisited' },
    { title: 'Top page in sidebar', value: 'fromSidebar' },
  ];
  public openSliteLinksOptions: DropdownSelectItem<unknown>[] = [
    { title: 'In the browser', value: 'browser' },
    { title: 'In the desktop app', value: 'desktop' },
  ];

  constructor() { }

}
