// Common
import { Injectable, Optional} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// Rx
import { Observable } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

// Types
import { Like } from '@modules/common/types/like';
import { Folder } from '../types/folder';
import { FoldersFilters } from '../types/folders-filters';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { StitchType } from '@modules/common/types/stitch-type';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';
import { BunchUpdateOptions } from '@modules/common/types/bunch-update-options';

// Env
import { environment } from '@environment';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { LinkedInfoService } from '@modules/linked-info/services/linked-info.service';
import { KnotsService } from '@modules/knots/services/knots.service';
import { TagsService } from '@modules/tags/services/tags.service';
import { CalendarEventsService } from '@modules/form-controls/services/calendar-events.service';
import { BaseStitchService } from '@modules/common/services/base-stitch.service';
import { StitchService } from '@modules/common/services/stitch.service';
import { NestedService } from '@modules/common/types/nested-service.interface';

@Injectable()
export class FoldersService extends BaseStitchService<Folder, FoldersFilters> implements CalendarEventsService, NestedService {
  protected url = environment.baseUrl + '/api/files/folders/';
  protected stitchType = StitchType.folder;
  protected filtersConstructor = FoldersFilters;

  constructor(
    protected http: HttpClient,
    protected toasterService: ToasterService,
    @Optional() linkedInfoService: LinkedInfoService,
    @Optional() knotsService: KnotsService,
    @Optional() tagsService: TagsService,
    @Optional() stitchService: StitchService,
  ) {
    super(http, toasterService, stitchService, tagsService, knotsService, linkedInfoService);
  }

  /**
   * Methods
   */

  public bunchUpdate(
    filters: Partial<FoldersFilters>,
    changes: BunchUpdateOptions & Partial<Pick<Folder, 'parentId'>>,
    config: FeedbackConfig
  ): Observable<void> {
    return super.bunchUpdate(filters, changes, config);
  }

  create(
    folderInstance: Folder,
    { emit, toast, message }: FeedbackConfig = { emit: true },
  ): Observable<Folder> {
    return this.http.post<{ folder: Folder, success: boolean }>(
      `${environment.baseUrl}/api/files/folders`,
      folderInstance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }
          emit && this.forceRefresh();
          toast && this.toasterService.show({ text: message || `Folder created.` });
        }),
        map(({ folder }) => new Folder(folder)),
        switchMap(folder => this.processKnowledgeItems(folder)),
        switchMap(folder => this.processUploads(folderInstance.uploads, folder, { other: true, parentFolderName: 'File App' })),
        catchError(error => this.handleObserverError(error))
      );
  }

  @warmUpObservable
  update(
    folderInstance: Folder,
    { emitUpdate, displayToast, toastMessage }: FeedbackConfig = { emitUpdate: true }
  ): Observable<Folder> {
    return this.http.put<{ folder: Folder, success: boolean }>
    (environment.baseUrl + '/api/files/folders/' + folderInstance.id, folderInstance.asPayloadJSON())
      .pipe(
        tap(({ folder, success }) => {
          if (success) {
            emitUpdate && this.forceRefresh();
            displayToast && this.toasterService.show({ text: toastMessage || 'Folder updated' });
          }
        }),
        map(({ folder }) => folder),
        catchError(error => this.handleObserverError(error))
      );
  }

  getCalendarEvents(fromTime: Date, toTime: Date): Observable<AngularCalendarEvent[]> {
    return this.search({ fromTime, toTime })
      .pipe(
        map(({ items }) => items.map(folder => folder.asAngularCalendarEvent()))
      );
  }

  getAutocompleteSuggestions(inputFilters: Like<FoldersFilters> = {}): AutocompleteFactory<Folder> {
    return (title?: string, values?: string[], config?: { limit: number }) => {
      const filters: Like<FoldersFilters> = {
        limit: config?.limit || 5,
        archived: false,
        deleted: false,
        ...inputFilters
      };

      if (values?.length) {
        filters.ids = values;
      } else if (title?.trim()) {
        filters.query = title.trim();
        filters.esAnalyzer = 'ngrams_2_12';
        filters.esPriority = 'title';
        filters.esMultiMatchType = 'best_fields';
      }

      return this.search(filters)
        .pipe(
          map((response: BaseSearchResponse<Folder>) => response.items.map(folder => ({
            title: folder.title,
            value: folder.id,
            source: folder
          }))),
        );
    };
  }

  validateNested(id: string, parentId: string) {
    return this.http.get<{ deep: boolean, circular: boolean }>(
      `${ environment.baseUrl }/api/files/folders/validate-nesting/${ id }/${ parentId }`
    )
      .pipe(
        catchError(error => this.handleObserverError(error))
      );
  }

  createInstance(item): Folder {
    return new Folder(item);
  }
}
