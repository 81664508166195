<div class="form">
  <div class="buttons">
    <div class="title">Add Event</div>

    <div class="separator"></div>

    <app-button
      appearance="sapphire-primary-ghost"
      size="xs"
      icon="sapphire-close"
      (click)="handleClose()"
    />
  </div>

  <app-input
    appearance="sapphire-outline"
    [inputFormControl]="form.controls.title"
    placeholder="Add Event Title"
    [withErrors]="submitted"
    [withClear]="true"
    size="s"
  />

  <app-date-range-picker
    [fromDate]="form.controls.startDate"
    [toDate]="form.controls.endDate"
    [fromTime]="form.controls.startTime"
    [toTime]="form.controls.endTime"
    [reminders]="form.controls.reminders"
    [recurrence]="form.controls.recurrence"
  />

  <div class="row">
    <div class="label">
      <app-checkbox
        [inputFormControl]="form.controls.allDay"
        title="All Day"
        appearance="sapphire"
        size="s"
      />

      <app-checkbox
        [inputFormControl]="form.controls.reminder"
        title="Reminder"
        appearance="sapphire"
        size="s"
      />
    </div>

    <app-timezone-input
      class="input"
      [control]="form.controls.timeZone"
      placeholder="Select Time Zone"
      appearance="sapphire-primary-ghost-button"
    />
  </div>

  <div class="row">
    <div class="label">Repeat</div>
    <app-recurrence-picker
      class="input"
      [style.width.px]="252"
      appearance="sapphire-outline"
      [control]="form.controls.recurrence"
      [referenceDate]="form.controls.startDate"
      size="m"
    />
  </div>

  <div class="row">
    <div class="label">Calendar</div>
    <app-autocomplete-input
      class="input"
      [style.width.px]="252"
      appearance="sapphire-outline"
      [suggestions]="calendarSuggestions"
      [inputFormControl]="form.controls.calendarId"
      size="s"
    />
  </div>

  <div class="row">
    <div class="label">Meeting Link</div>
    <app-input
      class="input meeting-link"
      appearance="sapphire-outline"
      [inputFormControl]="form.controls.meetingLink"
      [withErrors]="submitted"
      [withClear]="true"
      size="s"
    />
  </div>

  <app-participants-input [control]="form.controls.participants" />

  <app-textarea
    appearance="sapphire-outline"
    [inputFormControl]="form.controls.description"
    [rows]="3"
    [withClear]="true"
    placeholder="Description"
    [validationMessages]="{ required: 'Please enter a value' }"
    [withErrors]="submitted"
  />

  <div class="buttons">
    <app-button
      appearance="sapphire-text-accent"
      size="m"
      label="More Options"
      (click)="handleMore()"
    />

    <div class="separator"></div>

    <app-button
      appearance="sapphire-text-accent"
      size="m"
      label="Cancel"
      (click)="handleClose()"
    />

    <app-button
      appearance="sapphire-text-accent"
      size="m"
      label="Create"
      (click)="handleSubmit()"
    />
  </div>
</div>
