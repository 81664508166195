import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class Settings {
  formBuilder = injector.get(FormBuilder);

  completedTaskAppearance: 'faded' | 'strikethrough';
  defaultAddTo: 'top' | 'bottom';
  defaultDate: 'today' | 'tomorrow' | 'afterTomorrow' | 'nextWeek';
  defaultPriority: 'low' | 'medium' | 'high';
  defaultReminder: number;
  defaultReminderWholeDay: 'none';
  dueDateReminder: number;
  dueDateTasks: 'all' | 'flagged';
  issueKeyEnabled: boolean;
  notifications?: boolean;
  overdueReminder: number;
  overdueTasks: 'all' | 'flagged';
  overdueTasksShowAt: 'top' | 'bottom';
  projectsPostponeNotificationsEnabled: boolean;
  reminders?: boolean;
  remindersTimeBeforeMinutes?: number;
  startDateReminder: number;
  startDateTasks: 'all' | 'flagged';
  tasksPostponeNotificationsEnabled: boolean;

  constructor(data: any = {}) {
    this.completedTaskAppearance = data.completedTaskAppearance || 'strikethrough';
    this.defaultAddTo = data.defaultAddTo || 'top';
    this.defaultDate = data.defaultDate;
    this.defaultPriority = data.defaultPriority;
    this.defaultReminder = data.defaultReminder || 5;
    this.defaultReminderWholeDay = data.defaultReminderWholeDay || 'none';
    this.dueDateReminder = data.dueDateReminder || 5;
    this.dueDateTasks = data.dueDateTasks || 'flagged';
    this.issueKeyEnabled = data.issueKeyEnabled;
    this.notifications = data.notifications ?? data.tasksNotificationsEnabled ?? false;
    this.overdueReminder = data.overdueReminder || 5;
    this.overdueTasks = data.overdueTasks || 'flagged';
    this.overdueTasksShowAt = data.overdueTasksShowAt || 'top';
    this.projectsPostponeNotificationsEnabled = data.projectsPostponeNotificationsEnabled ?? false;
    this.reminders = data.reminders ?? data.tasksRemindersEnabled ?? false;
    this.remindersTimeBeforeMinutes = data.remindersTimeBeforeMinutes || 0;
    this.startDateReminder = data.startDateReminder || 5;
    this.startDateTasks = data.startDateTasks || 'flagged';
    this.tasksPostponeNotificationsEnabled = data.tasksPostponeNotificationsEnabled ?? false;
  }

  static fromFormGroup(form: FormGroup): Settings {
    const { value: formValues } = form;

    return new Settings({
      completedTaskAppearance: formValues.completedTaskAppearance,
      defaultAddTo: formValues.defaultAddTo,
      defaultDate: formValues.defaultDate,
      defaultPriority: formValues.defaultPriority,
      defaultReminder: formValues.defaultReminder,
      defaultReminderWholeDay: formValues.defaultReminderWholeDay,
      dueDateReminder: formValues.dueDateReminder,
      dueDateTasks: formValues.dueDateTasks,
      issueKeyEnabled: formValues.issueKeyEnabled,
      notifications: formValues.notifications,
      overdueReminder: formValues.overdueReminder,
      overdueTasks: formValues.overdueTasks,
      overdueTasksShowAt: formValues.overdueTasksShowAt,
      projectsPostponeNotificationsEnabled: formValues.projectsPostponeNotificationsEnabled,
      reminders: formValues.reminders,
      remindersTimeBeforeMinutes: formValues.remindersTimeBeforeMinutes,
      startDateReminder: formValues.startDateReminder,
      startDateTasks: formValues.startDateTasks,
      tasksPostponeNotificationsEnabled: formValues.tasksPostponeNotificationsEnabled,
    });
  }

  public asFormGroup(): FormGroup {
    return this.formBuilder.group({
      completedTaskAppearance: this.completedTaskAppearance,
      defaultAddTo: this.defaultAddTo,
      defaultDate: this.defaultDate,
      defaultPriority: this.defaultPriority,
      defaultReminder: this.defaultReminder,
      defaultReminderWholeDay: this.defaultReminderWholeDay,
      dueDateReminder: this.dueDateReminder,
      dueDateTasks: this.dueDateTasks,
      issueKeyEnabled: this.issueKeyEnabled,
      notifications: [this.notifications],
      overdueReminder: this.overdueReminder,
      overdueTasks: this.overdueTasks,
      overdueTasksShowAt: this.overdueTasksShowAt,
      projectsPostponeNotificationsEnabled: this.projectsPostponeNotificationsEnabled,
      reminders: this.reminders,
      remindersTimeBeforeMinutes: this.remindersTimeBeforeMinutes,
      startDateReminder: this.startDateReminder,
      startDateTasks: this.startDateTasks,
      tasksPostponeNotificationsEnabled: this.tasksPostponeNotificationsEnabled,
    });
  }

  public asPayloadJSON() {
    return {
      completedTaskAppearance: this.completedTaskAppearance,
      defaultAddTo: this.defaultAddTo,
      defaultDate: this.defaultDate,
      defaultPriority: this.defaultPriority,
      defaultReminder: this.defaultReminder,
      defaultReminderWholeDay: this.defaultReminderWholeDay,
      dueDateReminder: this.dueDateReminder,
      dueDateTasks: this.dueDateTasks,
      issueKeyEnabled: this.issueKeyEnabled,
      notificationsEnabled: this.notifications,
      overdueReminder: this.overdueReminder,
      overdueTasks: this.overdueTasks,
      overdueTasksShowAt: this.overdueTasksShowAt,
      projectsPostponeNotificationsEnabled: this.projectsPostponeNotificationsEnabled,
      remindersEnabled: this.reminders,
      remindersTimeBeforeMinutes: this.remindersTimeBeforeMinutes,
      startDateReminder: this.startDateReminder,
      startDateTasks: this.startDateTasks,
      tasksPostponeNotificationsEnabled: this.tasksPostponeNotificationsEnabled,
    };
  }
}
