<cdk-virtual-scroll-viewport
  [itemSize]="itemHeight"
  minBufferPx="880"
  maxBufferPx="1320"
  class="events-list-wrapper"
  (mouseover)="isHover = true"
  (mouseleave)="isHover = false"

  appSelectableList
  [appSelectableListReset]="resetSelected"
>
  <!-- Events -->
  <ng-container *cdkVirtualFor="let event of itemsStreamObservable; let index = index">
    <div
      class="days-separator"
      *ngIf="!event?.pinned && isHover && event?.startTime | differentDay: items[index - 1]?.startTime"
    >
      <div class="days-separator-date">
        {{ (event?.startTime | relativeDate : true : false : true) || (event?.startTime | date: 'MMM d, yyyy') }}
      </div>
    </div>

    <app-event
      [item]="event"
      [class.event-cut]="event && event | isEventCut | async"
      [position]="index"
      [withTags]="withTags"
      [withKnots]="withKnots"
      [debug]="debug"
      (open)="openEvent.emit(event)"
      (appClick)="handleClickItem(event)"
      (appDblClick)="handleDoubleClickItem(event)"

      [appSelectable]="event"
      [appSelectablePosition]="index"
    ></app-event>
  </ng-container>

  <!-- Placeholders -->
  <div class="loader" *ngIf="loading && items?.length === 0"><i class="fa fa-refresh fa-spin fa-fw"></i></div>
  <div *ngIf="loadingError" class="placeholder">Error occured while loading. <span (click)="items?.length ? refreshCurrentItems() : resetItems()" class="error">Click here to try again.</span></div>
</cdk-virtual-scroll-viewport>

<!-- Count -->
<div class="event-list__selected" *ngIf="items?.length > 0 && ((showCountView | async) === true)">
  <div class="total">Items: {{items.length}}</div>
</div>

<div class="events-stub" *ngIf="items?.length === 0 && !loading && !loadingError">
  <img class="events-stub-image" src="assets/placeholders/placeholder-events.svg"/>
  <p class="events-stub-text">{{ placeholderText }}</p>
</div>
