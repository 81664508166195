// Common
import { ComponentRef, Directive, EventEmitter, Input, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { ContactContextMenuComponent } from '@modules/user-avatar/components/contact-context-menu/contact-context-menu.component';

// Types
import { Contact } from '@modules/mail/types/contact';

@Directive({
  selector: '[appContactContextMenu]'
})
export class ContactContextMenuDirective extends ContextMenuDirective {

  // Inputs
  @Input() appContactContextMenu: Contact;

  // Outputs
  @Output() loadInKnowledgePanel = new EventEmitter();

  registerComponent() {
    return ContactContextMenuComponent;
  }

  registerInstance(componentRef: ComponentRef<ContactContextMenuComponent>) {
    componentRef.instance.relatedContact = this.appContactContextMenu;
    componentRef.instance.loadInKnowledgePanel = this.loadInKnowledgePanel;
  }
}
