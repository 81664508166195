// Common
import { Component, Injector, Input } from '@angular/core';

// Types
import { LinkedInfo } from '@modules/linked-info/types/linked-info';
import { Application } from '@modules/common/types/application';
import { Stitch } from '@modules/common/types/stitch';

// Services
import { ModalService } from '@modules/modal/services/modal.service';

@Component({
  selector: 'app-link-info-bar',
  templateUrl: './link-info-bar.component.html',
  styleUrls: ['./link-info-bar.component.less']
})
export class LinkInfoBarComponent {

  // Inputs
  @Input() linkedInfo: LinkedInfo[];
  @Input() stitch: Stitch;

  public groupedStitchInfoByApp: Array<{ type: Application, items: LinkedInfo[] }>;

  constructor(
    private modalService: ModalService,
    private injector: Injector
  ) { }

  /**
   * Actions
   */

  handleDoubleClick(item: Stitch) {
    this.modalService.openFullForm(item, this.injector);
  }
}
