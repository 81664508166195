<div
  class="button"
  [ngClass]="[size]"
  [class.disabled]="disabled"
  [class.active]="active"
>
  <mat-icon
    class="icon"
    svgIcon="sapphire-knowledge"
  />
</div>
