<app-context-menu-item
  *ngIf="withDetach"
  title="Detach"
  [close]="true"
  (execute)="detachConnection()"
></app-context-menu-item>

<app-context-menu-item
  title="Delete Permanently"
  [close]="true"
  (execute)="deleteConnection()"
></app-context-menu-item>
