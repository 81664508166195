// Common
import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { heightAnimation } from '@modules/common/animations/height.animation';

// RX
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { BaseSectionsListService } from '@modules/common/services/base-sections-list.service';

// Types
import { Section } from '@modules/common/types/section';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { NestedDataItem } from '@modules/drag-n-drop/types/nested-item';
import { Stitch } from '@modules/common/types/stitch';
import { ChildStitch } from '@modules/common/types/child-stitch';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.less'],
  animations: [heightAnimation]
})
export class SectionComponent implements OnInit, OnChanges, OnDestroy {

  public sectionForm: UntypedFormGroup;
  public collapsed = false;
  public items: Stitch[] = [];
  public itemsChanges = new Subject<NestedDataItem<Stitch>[]>();
  public itemsCount = 0;

  private alive = new Subject<void>();
  private sectionChanged = new Subject<void>();

  @Input() section: Section;
  @Input() container: Stitch;
  @Input() itemTemplate: TemplateRef<any>;
  @Input() dragDataType: DragData['type'];
  @Input() maxDepth = 1;

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean => dragData.type === this.dragDataType;

  constructor (
    private listService: BaseSectionsListService<ChildStitch, Stitch>,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.sectionForm = this.section?.asFormGroup();

    this.sectionChanged
      .pipe(
        filter(() => !!this.section),
        switchMap(() => this.listService.getSectionCollapsed(this.section.id)),
        takeUntil(this.alive)
      )
      .subscribe((value: boolean) => this.collapsed = value);

    this.sectionChanged
      .pipe(
        switchMap(() => this.listService.getItems(this.section)),
        takeUntil(this.alive)
      )
      .subscribe(items => {
        this.items = items;
      });

    this.sectionChanged
      .pipe(
        switchMap(() => this.listService.getItemsCount(this.section)),
        takeUntil(this.alive)
      )
      .subscribe(count => {
        this.itemsCount = count;
      });

    this.itemsChanges
      .pipe(takeUntil(this.alive))
      .subscribe(changes => {
        this.listService.updateItems(changes, this.section?.id);
      });

    this.sectionChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('section' in changes) {
      this.sectionChanged.next();
      this.sectionForm = this.section?.asFormGroup();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleBlur() {
    if (
      this.sectionForm.get('title').dirty &&
      this.sectionForm.get('title').value.trim() !== ''
    ) {
      this.listService.updateSection(Section.fromFormGroup(this.sectionForm));
    }
  }

  delete() {
    this.listService.removeSection(this.section);
  }

  moveUp() {
    this.listService.moveSection(this.section, -1);
  }

  moveDown() {
    this.listService.moveSection(this.section, 1);
  }

  toggleCollapse() {
    this.listService.setSectionCollapsed(this.section.id, !this.collapsed);
  }
}
