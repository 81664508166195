// Common
import { UntypedFormGroup, Validators } from '@angular/forms';

// Utils
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { StitchType } from '@modules/common/types/stitch-type';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { CalendarContact } from '@modules/calendar-app/types/calendar-contact';
import { Attachment } from '@modules/form-controls/types/attachment';
import { Message } from '@modules/messages/types/message';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { Country } from '@modules/common/types/country';
import { VirtualFolder } from '@modules/contacts/types/virtual-folder';
import { Like } from '@modules/common/types/like';

// Validators
import { NestedValidators } from '@modules/form-controls/validators/nested.validators';

// Services
import { GroupsService } from '../services/groups.service';
import { FileValidators } from '@modules/form-controls/validators/file.validators';

export class Group extends Stitch {
  avatar?: string;
  email?: string;
  files?: Attachment[];
  participants?: CalendarContact[];
  phoneNumber?: string;
  country?: Country;
  parentId?: string;
  address?: string;
  website?: string;
  title?: string;
  vip?: boolean;

  constructor(data: any = {}) {
    super(data);

    if (data instanceof Stitch) {
      this.fillFromStitch(data);
    } else {
      this.avatar = data.avatar;
      this.email = data.email;
      this.participants = data.participants || [];
      this.phoneNumber = data.phoneNumber;
      this.country = Country.fromIso3(data.country || '');
      this.title = data.title;
      this.parentId = data.parentId || null;
      this.address = data.address;
      this.website = data.website;
      this.vip = data.vip;
    }
  }

  static fromFormGroup(form: UntypedFormGroup): Group {
    const formValues = form.value;

    return new Group({
      address: formValues.address,
      archived: formValues.archived,
      avatar: formValues.avatar,
      color: formValues.color,
      connections: formValues.connections,
      country: formValues.country,
      createdAt: formValues.createdAt,
      deleted: formValues.deleted,
      description: formValues.description,
      email: formValues.email,
      flagged: formValues.flagged,
      followed: formValues.followed,
      id: formValues.id,
      knots: formValues.knots,
      linkedInfo: formValues.linkedInfo,
      parentId: formValues.parentId,
      phoneNumber: formValues.phoneNumber,
      pinned: formValues.pinned,
      snoozed: formValues.snoozed,
      tags: formValues.tags,
      title: formValues.title,
      updatedAt: formValues.updatedAt,
      uploads: formValues.uploads,
      vip: formValues.vip,
      website: formValues.website,
    });
  }

  static fromDragData(dragItem: DragData): Group {
    return <Group>super.fromDragData(dragItem);
  }

  static fromAdvancedState(filters: AdvancedSearchState, folder: VirtualFolder): Group {
    return new Group({
      title: filters.query,
      tags: filters.tags,
      knots: filters.knots,
      country: filters.contacts.country,
      description: filters.contacts.description,
      email: filters.contacts.email,
      phoneNumber: filters.contacts.phone,
      parentId: filters.contacts.containersIds?.[0],
      flagged: folder === 'flagged',
      archived: folder === 'archived',
      deleted: folder === 'deleted',
      vip: folder === 'vip'
    });
  }

  static shouldRefreshList(prev, current) {
    return super.shouldRefreshList(prev, current, ['title', 'description', 'color', 'parentId']);
  }

  static getChangesForVirtualFolder(sidebar: VirtualFolder): {
    changes: Like<Group>;
    message: string
  } {
    let message = 'Successfully moved to ';
    switch (sidebar) {
      case 'all_contacts':
        break;
      case 'all_groups':
        return { changes: { parentId: null }, message: message += 'Orphan' };
      case 'upcoming':
      case 'recent':
      case 'frequent':
        break;
      case 'vip':
        return { changes: { vip: true }, message: message += 'VIP' };
        break;
      case 'archived':
      case 'deleted':
      case 'followed':
      case 'snoozed':
      case 'flagged':
        return super.getChangesForVirtualFolder(sidebar);
      default:
        checkExhaustiveness(sidebar);
    }
  }

  getStitchType(): StitchType {
    return StitchType.group;
  }

  asFormGroup(groupsService?: GroupsService): UntypedFormGroup {
    return this.formBuilder.group(
      {
        address: [this.address],
        archived: [this.archived],
        avatar: [this.avatar],
        color: [this.color],
        connections: [this.connections],
        country: [this.country.asIso3()],
        createdAt: [this.createdAt],
        deleted: [this.deleted],
        description: [this.description],
        email: [this.email],
        flagged: [this.flagged],
        followed: [this.followed],
        id: [this.id],
        knots: [this.knots],
        linkedInfo: [this.linkedInfo],
        parentId: [this.parentId],
        phoneNumber: [this.phoneNumber],
        pinned: [this.pinned],
        snoozed: [this.snoozed],
        social: [''],
        tags: [this.tags],
        title: [this.title, Validators.required],
        updatedAt: [this.updatedAt],
        uploads: [this.uploads || [], FileValidators.size(26214400)],
        vip: [this.vip],
        website: [this.website],
      },
      {
        asyncValidators: [
          NestedValidators.circular('id', 'parentId', groupsService)
        ]
      }
    );
  }

  asPayloadJSON() {
    return {
      archived: this.archived,
      avatar: this.avatar,
      color: this.color,
      createdAt: this.createdAt,
      deleted: this.deleted,
      description: this.description,
      email: this.email,
      flagged: this.flagged,
      followed: this.followed,
      phoneNumber: this.phoneNumber,
      pinned: this.pinned,
      title: this.title,
      country: this.country.asIso3(),
      updatedAt: this.updatedAt,
      parentId: this.parentId,
      address: this.address,
      website: this.website,
      snoozed: this.snoozed,
      vip: this.vip,
      noNotification: this.noNotification,
    };
  }

  asAngularCalendarEvent(): AngularCalendarEvent {
    return this.convertToAngularCalendarEvent(
      this.title, this.createdAt, this.createdAt, this.createdAt, this.createdAt
    );
  }

  protected fillFromStitch(data: Stitch) {
    super.fillFromStitch(data);

    this.country = Country.fromIso3('');

    if (data instanceof Message) {
      this.description = data.bodyText;
    } else if (data instanceof Group) {
      this.avatar = data.avatar;
      this.email = data.email;
      this.participants = data.participants;
      this.phoneNumber = data.phoneNumber;
      this.country = data.country;
      this.parentId = data.parentId;
      this.address = data.address;
      this.website = data.website;
      this.vip = data.vip;
    }
  }
}
