<div
  *ngIf="project"
  class="project"
  [class.selected]="selected && !isDragging"
  [class.pinned]="project.pinned"
  [class.hovered]="priorityOpened || contextMenuOpened"
  [class.snoozed]="project.snoozed"
  [class.followed]="project.followed"

  [appProjectContextMenu]="project"
  (appProjectContextMenuOpened)="contextMenuOpened = $event"
  (appProjectContextMenuOpenProject)="open.emit()"
  (appProjectContextMenuOpenTask)="openTask.emit($event)"

  appDraggable
  [appDraggableDisabled]="!dragEnabled"
  [appDraggableData]="dragData"
  [appDraggablePlaceholder]="dragPlaceholder"
  (appDraggableDraggingChanged)="isDragging = $event"

  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="project-selection"></div>
  <div class="project-body">
    <div class="project-body-title">
      <app-circular-progress
        [progress]="project.progress"
        size="16"
        [priority]="project.priority"
        [completed]="project.completed"
        (onClick)="handleComplete()"
      ></app-circular-progress>

      <div
        class="project-body-title-text"
        [class.blank]="!project.title"
      >
        {{ project.title || 'Untitled Project' }}
      </div>

      <div class="project-body-title-column">
        {{ project.columnTitle }}
      </div>
    </div>

    <div class="project-body-date">
      <app-date-time-popover
        [fromDate]="form.controls.fromDate"
        [toDate]="form.controls.toDate"
        [fromTime]="form.controls.fromTime"
        [toTime]="form.controls.toTime"
        [reminders]="form.controls.reminders"
        (click)="handleDatePicker('from', $event)"
        (onClose)="datePickerOpened = false"
      >
        <div
          class="project-body-date-item"
          [class.selected]="datePickerOpened && dateButtonSelected === 'from'"
          [class.blank]="!form.controls.fromDate.value && !form.controls.fromTime.value"
        >

          {{ form.controls.fromDate.value | dateTimeDisplay: form.controls.fromTime.value: 'No Start Date' }}
        </div>
      </app-date-time-popover>

      <div class="project-body-date-separator"></div>

      <app-date-time-popover
        [focusToDate]="true"
        [fromDate]="form.controls.fromDate"
        [toDate]="form.controls.toDate"
        [fromTime]="form.controls.fromTime"
        [toTime]="form.controls.toTime"
        [reminders]="form.controls.reminders"
        (click)="handleDatePicker('to', $event)"
        (onClose)="datePickerOpened = false"
      >
        <div
          class="project-body-date-item"
          [class.selected]="datePickerOpened && dateButtonSelected === 'to'"
          [class.blank]="!form.controls.toDate.value && !form.controls.toTime.value"
        >
          {{ form.controls.toDate.value | dateTimeDisplay: form.controls.toTime.value: 'No Due Date' }}
        </div>
      </app-date-time-popover>
    </div>

    <div
      *ngIf="!withTags && !withKnots"
      class="project-body-description"
    >
      {{ project.description || 'No description' }}
    </div>

    <app-list-item-tags
      *ngIf="withTags"
      class="project-body-tags"
      [tags]="project.tags"
    ></app-list-item-tags>

    <app-list-item-knots
      *ngIf="withKnots"
      class="project-body-tags"
      [knots]="project.knots"
    ></app-list-item-knots>

    <app-link-info-bar [stitch]="project"></app-link-info-bar>
  </div>

  <mat-icon
    class="project-pin-icon"
    [class.active]="project.pinned"
    svgIcon="pin-direct"
    (click)="pin($event)"
  ></mat-icon>

  <mat-icon
    class="project-flag-icon"
    [class.active]="project.flagged"
    svgIcon="starred"
    (click)="flag($event)"
  ></mat-icon>

  <mat-icon
    class="project-marker"
    [svgIcon]="project.archived ? 'folder-archive' : 'project'"
    [style.color]="project.color"
  ></mat-icon>

  <div
    class="project-hover-icons"
    *ngIf="dragEnabled && actionsButtonEnabled"
    (click)="$event.stopPropagation()"
  >
    <app-priority-input
      class="project-hover-icons-item project-hover-icons-priority"
      [control]="form.controls.priority"
      [size]="14"
    ></app-priority-input>
    <mat-icon
      class="project-hover-icons-item"
      svgIcon="snooze"
      [matTooltip]="project?.snoozed ? 'Unsnooze' : 'Snooze'"
      [class.active]="!!project?.snoozed"
      (click)="snooze()"
    ></mat-icon>
    <mat-icon
      class="project-hover-icons-item"
      svgIcon="follow-up"
      [matTooltip]="project?.followed ? 'Unfollow' : 'Follow up'"
      [class.active]="!!project?.followed"
      (click)="followUp()"
    ></mat-icon>
    <mat-icon
      class="project-hover-icons-item"
      svgIcon="mail"
      matTooltip="Coming Soon"
    ></mat-icon>
    <mat-icon
      class="project-hover-icons-item"
      svgIcon="stitch"
      matTooltip="Stitch To"
      [appStitchToPopover]="project"
      (appStitchToPopoverOpened)="contextMenuOpened = $event"
    ></mat-icon>
    <mat-icon
      class="project-hover-icons-item"
      svgIcon="archive"
      (click)="archive()"
      [matTooltip]="project?.archived ? 'Restore from Archive' : 'Archive'"
    ></mat-icon>
    <mat-icon
      class="project-hover-icons-item"
      svgIcon="trash"
      (click)="delete()"
      [matTooltip]="project?.deleted ? 'Restore from Trash' : 'Trash'"
    ></mat-icon>
  </div>

  <div class="drop-zone"></div>

  <div *ngIf="debug" class="project-debug">{{ project.score }}</div>
</div>

<div class="placeholder" *ngIf="!project">
  <div class="placeholder-1"></div>
  <div class="placeholder-2"></div>
  <div class="placeholder-3"></div>
  <div class="placeholder-4"></div>
  <div class="placeholder-5"></div>
  <div class="placeholder-6"></div>
  <div class="placeholder-7"></div>
  <div class="placeholder-8"></div>
  <div class="placeholder-9"></div>
  <div class="placeholder-10"></div>
  <div class="placeholder-11"></div>
  <div class="placeholder-12"></div>
  <div class="placeholder-13"></div>
</div>

<ng-template #dragPlaceholder>
  <app-project [item]="project" [dragEnabled]="false"></app-project>
</ng-template>

<ng-template #afterDropTemplate let-dragData=dragData>
  <app-button (click)="handleMove(dragData)" label="Move" />
  <app-button (click)="handleStitch(dragData)" label="Stitch" />
</ng-template>
