import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekend',
  pure: true
})
export class WeekendPipe implements PipeTransform {
  transform(date: Date): boolean {
    if (!date) { return false }

    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6;
  }
}
