// Common
import { Component, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnDestroy, NgZone } from '@angular/core';

// RX
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-scroll',
  templateUrl: './scroll.component.html',
  styleUrls: ['./scroll.component.less']
})
export class ScrollComponent implements AfterViewInit, OnDestroy {

  // Private
  private alive: Subject<void> = new Subject();
  private topReached = true;
  private bottomReached = true;

  // Outputs
  @Output() top: EventEmitter<boolean> = new EventEmitter();
  @Output() bottom: EventEmitter<boolean> = new EventEmitter();

  // View Children
  @ViewChild('scrollBody', { static: true }) scrollBody: ElementRef;

  /**
   * Constructor
   */

  constructor (
    private ngZone: NgZone
  ) {

  }

  /**
   * Component lifecycle
   */

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.scrollBody.nativeElement, 'scroll')
        .pipe(
          takeUntil(this.alive),
        )
        .subscribe(this.handleScroll.bind(this));
    });
  }


  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleScroll(event: Event) {
    const scrollPosition = event.target['scrollTop'];

    if (!this.topReached && scrollPosition === 0) {
      this.ngZone.run(() => {
        this.top.emit(true);
        this.topReached = true;
      });
    }

    if (this.topReached && scrollPosition > 0) {
      this.ngZone.run(() => {
        this.top.emit(false);
        this.topReached = false;
      });
    }

    if (!this.bottomReached && event.target['scrollHeight'] - 5 < event.target['clientHeight'] + scrollPosition) {
      this.ngZone.run(() => {
        this.bottom.emit(true);
        this.bottomReached = true;
      });
    }

    if (this.bottomReached && event.target['scrollHeight'] - 5 > event.target['clientHeight'] + scrollPosition) {
      this.ngZone.run(() => {
        this.bottom.emit(false);
        this.bottomReached = false;
      });
    }
  }
}
