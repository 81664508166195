// Common
import { ComponentRef, Directive, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

// Directives
import { ContextMenuDirective } from '@modules/popover/directives/context-menu.directive';

// Components
import { CalendarsListContextMenuComponent } from '../components/calendars-list-context-menu/calendars-list-context-menu.component';

// Types
import { StateKey } from '@modules/settings/types/state-key';
import { PopoverPlacement } from '@modules/popover/types/placement';
import { PopoverTrigger } from '@modules/popover/types/trigger';
import { CalendarsListState } from '../types/calendars-list-state';

// RX
import { takeUntil } from 'rxjs/operators';

// Services
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { StateService } from '@modules/settings/services/state.service';

@Directive({
  selector: '[appCalendarsListContextMenu]'
})
export class CalendarsListContextMenuDirective extends ContextMenuDirective implements OnInit, OnDestroy {

  // Inputs
  @Input() appCalendarsListContextMenuStateKey: StateKey;
  @Input() appCalendarsListContextMenuWithAdvancedSearch: boolean;
  @Input() appCalendarsListContextMenuWithScore: boolean;
  @Input() stchPopoverTrigger: PopoverTrigger = 'click';
  @Input() stchPopoverPlacement: PopoverPlacement = 'bottomLeft';
  @Input() stchPopoverFallbackPlacements: PopoverPlacement[] = ['right'];

  // Outputs
  @Output() appCalendarsListContextMenuChange = new EventEmitter<CalendarsListState>();

  // Private
  private state: CalendarsListState;

  constructor(
    injector: Injector,
    private searchService: AdvancedSearchService,
    private stateService: StateService
  ) {
    super(injector);
  }

  /**
   * Context Menu Interface
   */

  registerInstance(componentRef: ComponentRef<CalendarsListContextMenuComponent>) {
    componentRef.instance.state = this.state;
    componentRef.instance.withAdvancedSearch = this.appCalendarsListContextMenuWithAdvancedSearch;
    componentRef.instance.withScore = this.appCalendarsListContextMenuWithScore;
  }

  registerComponent() {
    return CalendarsListContextMenuComponent;
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.state = new CalendarsListState({
      searchService: this.searchService,
      stateService: this.stateService,
      stateKey: this.appCalendarsListContextMenuStateKey,
      withAdvanced: this.appCalendarsListContextMenuWithAdvancedSearch
    });

    this.state.changes()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.appCalendarsListContextMenuChange.emit(this.state);
        this.isDefaultStateObservable?.next(this.state.isDefault());
      });

    if (!this.isDefaultStateObservable) { return; }

    this.isDefaultStateObservable
      .pipe(takeUntil(this.alive))
      .subscribe(isDefault => {
        if (isDefault && !this.state.isDefault()) {
          this.state.resetToDefault();
        }
      });
  }

  ngOnDestroy() {
    this.state.detach();
    super.ngOnDestroy();
  }
}
