// Common
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';

// Types
import { Import } from '@modules/messages/types/import';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { AlertService } from '@modules/alert/services/alert.service';
import { ImportsService } from '@modules/messages/services/imports.service';

// RX
import { switchMap, takeUntil, filter } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';

// Components
import { ImportFormComponent } from './import-form/import-form.component';

@Component({
  selector: 'app-settings-messages-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.less']
})
export class ImportComponent implements OnInit, OnDestroy {
  // Public
  public imports: Import[];

  // Private
  private alive = new Subject<void>();

  constructor (
    private modalService: ModalService,
    private alertsService: AlertService,
    private importsService: ImportsService,
    private injector: Injector,
  ) {

  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.importsService.getRefreshRequired()
      .pipe(
        switchMap(() => timer(0, 6000)),
        filter(() => this.imports === undefined || this.imports.some(({ status }) => status === 'pending')),
        switchMap(() => this.importsService.listAll()),
        takeUntil(this.alive)
      )
      .subscribe(imports => {
        this.imports = imports;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  openForm() {
    this.modalService.showModal({
      component: ImportFormComponent,
      content: { },
      appearance: 'amethyst',
      title: 'Create Import',
      resize: true,
      collapsible: true,
      expandable: false,
      maxModals: 1,
      injector: this.injector,
    });
  }

  removeImport(item: Import) {
    this.importsService.delete(item);
  }

  removeImportPrompt(item: Import) {
    this.alertsService.show({
      title: 'Are you sure?',
      body: `Are you sure you want to delete import ${item.folderName}?`,
      rightButtons: [
        {
          title: 'CANCEL',
          close: true
        },
        {
          title: 'REMOVE',
          click: () => this.removeImport(item),
          close: true
        }
      ],
      showUntil: this.alive
    });
  }

  retry(item: Import) {
    item.status = 'pending';

    this.importsService.retry(item)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.importsService.forceRefresh();
      });
  }
}
