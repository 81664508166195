<app-split-view
  side="left"
  key="eventsSidebar"
  [collapsedSize]="76"
  [shadow]="true"
  [magneticSize]="120"
  [withIcon]="true"
>
  <app-sidebar split-left-pane splitViewKey="eventsSidebar">
    <app-events-side-bar *stchSpaceRerender></app-events-side-bar>
  </app-sidebar>

  <app-split-view
    split-right-pane
    side="left"
    key="eventsList"
    [collapsedSize]="25"
    [shadow]="true"
    [magneticSize]="260"
    [withIcon]="true"
  >
    <app-events-list-container
      split-left-pane
      *stchSpaceRerender
      class="list"
    />

    <div class="full-form" split-right-pane>
      <stch-calendar-app-main-view *stchSpaceRerender />
    </div>
  </app-split-view>
</app-split-view>
