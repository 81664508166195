<div class="form">
  <div class="form-date">{{ contact?.createdAt | date : 'MMM d' }}</div>

  <h2 class="form-title">
    <mat-icon
      class="form-heading-title-icon"
      svgIcon="contacts"
      [style.color]="contact?.color"
    />
    {{ contact?.title }}
  </h2>

  <div class="form-details">
    <div class="form-details-row">
      <div class="form-details-row-title">Job Title</div>
      <div class="form-details-row-input">{{ contact?.jobTitle }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Vip contact</div>
      <div class="form-details-row-input">{{ contact?.vip }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Country</div>
      <div class="form-details-row-input">{{ contact?.country?.name }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Phone Number</div>
      <div class="form-details-row-input">
        <p *ngFor="let phone of contact?.phones">
          {{ phone.category }}
          {{ phone.value }}
        </p>
      </div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Email</div>
      <div class="form-details-row-input">
        <p *ngFor="let email of contact?.emails">
          {{ email.category }}
          {{ email.value }}
        </p>
      </div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Birth Date</div>
      <div class="form-details-row-input">{{ contact?.birthDate }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Address</div>
      <div class="form-details-row-input">{{ contact?.address }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Group</div>
      <div class="form-details-row-input">{{ contact?.groupId }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Description</div>
      <div class="form-details-row-input">{{ contact?.description }}</div>
    </div>
  </div>
</div>
