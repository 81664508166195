// Common
import { Injectable } from '@angular/core';
import { isNil } from '@modules/common/utils/base';

// RX
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap, tap, takeUntil, take, filter, throttleTime } from 'rxjs/operators';

// Types
import { Stitch } from '../types/stitch';
import { LSBoolean, LocalStorageItem } from 'src/app/decorators/local-storage.decorator';
import { NestedDataItem } from '@modules/drag-n-drop/types/nested-item';
import { Section } from '../../common/types/section';
import { StitchChildFilters } from '../types/stitch-child-filters';
import { ChildStitch } from '../types/child-stitch';
import { Like } from '../types/like';
import { SignalEnum } from '@modules/common/types/signal';

// Services
import { BaseStitchChildService } from './base-stitch-child.service';
import { BaseSectionsService } from './base-sections.service';
import { SignalsService } from '@modules/common/services/signals.service';

@Injectable()
export abstract class BaseSectionsListService<T extends ChildStitch, TContainer extends Stitch> {

  private originalSections: Section[] = [];
  private sections = new BehaviorSubject<Section[]>([]);
  private originalItems: T[] = [];
  private items = new BehaviorSubject<T[]>([]);
  private container: TContainer;
  private alive = new Subject<void>();
  private reload = new Subject<void>();

  @LSBoolean({ lsKey: 'section.collapsed' }) sectionCollapsed: LocalStorageItem<boolean>;

  constructor (
    private sectionsService: BaseSectionsService,
    private itemsService: BaseStitchChildService<T, StitchChildFilters>,
    private signalsService: SignalsService
  ) {
    this.sections
      .pipe(
        filter(sections => !sections.some(({ id }) => id === 'temp')),
        takeUntil(this.alive)
      )
      .subscribe(sections => {
        this.syncOrder(sections, this.originalSections);
        this.originalSections = sections;
      });

    this.items
      .pipe(takeUntil(this.alive))
      .subscribe(items => {
        if (items.some(({ id }) => id === 'temp')) { return; }

        this.syncItems(items);
        this.originalItems = items;
      });

    this.reload
      .pipe(
        filter(() => !!this.container?.id),
        switchMap(() => combineLatest([
          this.sectionsService.findAll({ containersIds: [this.container.id] }),
        ])),
        tap(([{ items: sections }]) => {
          this.originalSections = sections;

          this.sections.next(
            sections
              .sort((a, b) => a.position - b.position)
              .map((section, index) => new Section({ ...section, position: index }))
          );
        }),
        switchMap(() => this.itemsService.findAll({ containersIds: [this.container.id] })),
        takeUntil(this.alive)
      )
      .subscribe(({ items }) => {
        const sectionsIds = this.originalSections.map(({ id }) => id);

        this.originalItems = items.map(item => this.createInstance({
          ...item,
          sectionId: sectionsIds.includes(item.sectionId) ? item.sectionId : null
        }));

        this.items.next(this.originalItems);
      });

    this.signalsService.getSignal(SignalEnum.RELOAD_PROJECT_BOARD)
      .pipe(
        filter(({ containerId }) => containerId === this.container?.id),
        throttleTime(300),
        takeUntil(this.alive),
      )
      .subscribe(signal => {
        // TODO - don't reload for signal initiator?
        this.reload.next();
      });
  }

  setContainer(container: TContainer) {
    this.container = container;
    this.reload.next();
  }

  getSections() {
    return this.sections.asObservable();
  }

  private getSubitems(parents: T[], allItems: T[]) {
    if (!parents.length) { return []; }

    const parentsIds = parents.map(({ id }) => id);
    const children = allItems.filter(({ parentId }) => parentsIds.includes(parentId));

    return [...children, ...this.getSubitems(children, allItems)];
  }

  getItems(section: Section) {
    return this.items.pipe(
      map(items => {
        const resultItems = items.filter(item =>
          item.parentId === null &&
          item.sectionId === (section?.id || null)
        );

        return [...resultItems, ...this.getSubitems(resultItems, items)];
      })
    );
  }

  getItemsCount(section: Section): Observable<number> {
    return this.getItems(section).pipe(map(items => items.length));
  }

  addSection(after = true, position?: number) {
    const sections = this.sections.getValue().filter(section => section.id !== 'temp');

    const finalPosition = isNil(position) ? sections.length - 1 : position;

    const newSections = [
      new Section({
        id: 'temp',
        containerId: this.container.id,
        position: after ? finalPosition + 0.5 : finalPosition - 0.5
      }),
      ...sections
    ]
      .sort((a, b) => a.position - b.position)
      .map((section, index) => new Section({ ...section, position: index }));

    this.sections.next(newSections);
  }

  addItem(item: T) {
    this.items.next([item, ...this.items.getValue()]);
  }

  updateSection(section: Section) {
    if (section.id === 'temp' && section.title.trim() === '') { return; }

    this.sections.next(this.sections.getValue().map(i => i.id === section.id ? section : i));

    this.sectionsService.upsert(section)
      .pipe(takeUntil(this.alive))
      .subscribe((upsertedSection) => {
        this.sections.next(this.sections.getValue().map(i => i.id === section.id || i.id === 'temp' ? upsertedSection : i));
      });
  }

  public moveSection(section: Section, direction: 1 | -1) {
    const sections = this.sections.getValue();

    const newSections = [...sections]
      .map(i => new Section({ ...i, position: i.id === section.id ? i.position + direction * 1.5 : i.position}))
      .sort((a, b) => a.position - b.position)
      .map((i, index) => new Section({ ...i, position: index }));

    this.sections.next(newSections);
  }

  public removeSection(section: Section) {
    this.sectionsService.deletePermanently([section.id])
      .pipe(
        take(1),
        takeUntil(this.alive)
      )
      .subscribe();

    const newSections = this.sections
      .getValue()
      .filter(({ id }) => id !== section.id)
      .sort((a, b) => a.position - b.position)
      .map((section, index) => new Section({ ...section, position: index }));

    this.sections.next(newSections);

    // items should be moved to previous section?
  }

  setSectionCollapsed(sectionId: string, value: boolean): void {
    this.sectionCollapsed.set(value, sectionId);
  }

  getSectionCollapsed(key: string): Observable<boolean> {
    return this.sectionCollapsed.get(key);
  }

  detach() {
    this.alive.next();
    this.alive.complete();
  }

  private syncOrder(
    items: Section[],
    originalItems: Section[],
  ) {
    const itemsMap = new Map<string, Section>();

    for (const item of originalItems) {
      itemsMap.set(item.id, item);
    }

    const changedItems = items.filter(item => {
      const mapItem = itemsMap.get(item.id);
      return mapItem && item.position !== mapItem.position;
    });

    if (!changedItems.length) { return; }

    this.sectionsService.reorder(changedItems.map(({ id, position }) => ({ id, position })))
      .pipe(
        take(1),
        takeUntil(this.alive)
      )
      .subscribe();
  }

  private syncItems(items: T[]) {
    const itemsMap = new Map<string, T>();

    for (const item of this.originalItems) {
      itemsMap.set(item.id, item);
    }

    const changedItems = items.filter(item => {
      const mapItem = itemsMap.get(item.id);
      return !mapItem ||
        (
          item.position !== mapItem.position ||
          item.containerId !== mapItem.containerId ||
          item.sectionId !== mapItem.sectionId ||
          item.parentId !== mapItem.parentId
        );
    });

    if (!changedItems.length) { return; }

    this.itemsService.reorder(changedItems)
      .pipe(
        take(1),
        takeUntil(this.alive)
      )
      .subscribe();
  }

  updateItems(changes: NestedDataItem<T>[], sectionId: string) {
    const changesIds = changes.map(({ id }) => id);

    const items = this.items.getValue();

    const newItems = changes
      .map(i => this.createInstance({
        ...i.data,
        position: i.position,
        parentId: i.parentId,
        sectionId: sectionId || null,
        containerId: this.container.id
      }));

    this.items.next([
      ...(items.filter(({ id }) => !changesIds.includes(id))),
      ...newItems
    ]);
  }

  protected abstract createInstance(data: Like<T>): T;
}
