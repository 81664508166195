<div class="block">
  <div
    *ngFor="let template of quickReplyTemplates"
    class="block-item"
  >
    <span class="block-item-title">{{ template.title }}</span>

    <app-button
      appearance="purple-outline"
      label="Edit template"
      (click)="handleOpenForm(template)"
    />

    <app-button
      appearance="purple"
      label="Remove template"
      (click)="handleRemove(template)"
    />
  </div>
</div>
<div class="block no-border">
  <app-button
    appearance="purple"
    label="Create template"
    (click)="handleOpenForm()"
  />
</div>


