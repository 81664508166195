// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { CommonModule as AppCommonModule } from '@modules/common/common.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopoverModule } from '@modules/popover/popover.module';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { AddTagsComponent } from './components/add-tags/add-tags.component';
import { ManageContextMenuComponent } from './components/manage-context-menu/manage-context-menu.component';
import { ManageTagComponent } from './components/manage-tag/manage-tag.component';
import { ManageTagsFormComponent } from './components/manage-tags-form/manage-tags-form.component';
import { TagComponent } from './components/tag/tag.component';
import { TagContextMenuComponent } from './components/tag-context-menu/tag-context-menu.component';
import { TagsComponent } from './components/tags/tags.component';
import { TagsFormComponent } from './components/tags-form/tags-form.component';

// Directives
import { ManageContextMenuDirective } from './directives/manage-context-menu.directive';
import { TagContextMenuDirective } from './directives/tag-context-menu.directive';

// Pipes
import { GroupAlphabeticallyPipe } from './pipes/group-alphabetically.pipe';
import { SortAndFilterPipe } from './pipes/sort-and-filter.pipe';

@NgModule({
  imports: [
    AppCommonModule,
    CommonModule,
    DragNDropModule,
    FormControlsModule,
    FormsModule,
    MatIconModule,
    MatTooltipModule,
    PopoverModule,
    ScrollModule,
    ScrollingModule,
    UtilsModule,
  ],
  declarations: [
    AddTagsComponent,
    ManageContextMenuComponent,
    ManageTagComponent,
    ManageTagsFormComponent,
    TagComponent,
    TagContextMenuComponent,
    TagsComponent,
    TagsFormComponent,
    // Directives
    ManageContextMenuDirective,
    TagContextMenuDirective,
    // Pipes
    GroupAlphabeticallyPipe,
    SortAndFilterPipe,
  ],
  exports: [
    AddTagsComponent,
    ManageTagsFormComponent,
    TagComponent,
    TagsComponent,
    TagsFormComponent,
    // Directives
    ManageContextMenuDirective,
  ]
})
export class TagsModule { }
