
// Common
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

// Env
import { environment } from '@environment';

// Types
import { File } from '@modules/files/types/file';

// RX
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.less']
})
export class FilePreviewComponent implements OnInit, OnChanges, OnDestroy {

  // Inputs
  @Input() file: File = new File();

  // Public
  public fileUrl: SafeUrl;
  public fileUrlString: string;
  public currentPage = 0;
  public pagesCount = 1;

  // Private
  private alive = new Subject<void>();
  private loadResource = new Subject<void>();

  /**
   * Constructor
   */

  constructor (
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.loadResource
      .pipe(
        switchMap(() => {
          return this.http.get(
            `${environment.baseUrl}/api/attachments/${this.file.id}/${this.file.id}/${this.file.attachmentName}`,
            { responseType: 'blob' }
          );
        }),
        takeUntil(this.alive)
      )
      .subscribe(event => {
        this.fileUrlString = URL.createObjectURL(event);
        this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(this.fileUrlString);
      });

    this.loadResource.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('file' in changes && this.file) {
      this.loadResource.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
