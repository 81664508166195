// Common
import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

// Services
import { SplitViewService } from '@modules/split-view/services/split-view.service';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})

export class SidebarComponent implements OnInit, OnDestroy {

  public minimized: boolean;

  private alive: Subject<void> = new Subject();

  @Input() splitViewKey: string;

  constructor(
    private splitViewService: SplitViewService,
    private changeDetector: ChangeDetectorRef
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.splitViewService
      .getMinimized(this.splitViewKey)
      .pipe(takeUntil(this.alive))
      .subscribe((minimized: boolean) => {
        this.minimized = minimized;
        this.changeDetector.detectChanges();
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
