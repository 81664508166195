// Common
import { Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { warmUpObservable } from '@decorators';

// Rx
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

// Types
import { PlansFilters } from '../types/subscription-plans-filters';
import { SubscriptionPlan } from '../types/subscription-plan';
import { BaseSearchResponse } from '@modules/common/types/base-search-response';
import { FeedbackConfig } from '@modules/common/types/base-service-types';

// Env
import { environment } from '@environment';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';
import { BaseRestService } from '@modules/common/services/base-rest.service';

@Injectable()
export class SubscriptionPlansService extends BaseRestService<SubscriptionPlan, PlansFilters> {
  private url = environment.baseUrl + '/api/admin/subscription-plans/';

  constructor(
    private http: HttpClient,
    private toaster: ToasterService,
  ) {
    super();
  }

  protected handleObserverError(error: HttpErrorResponse) {
    this.toaster.show({ text: error?.error?.message });
    return throwError(error);
  }

  search(
    filters?: Partial<PlansFilters>,
  ): Observable<BaseSearchResponse<SubscriptionPlan>> {
    const requestParams = { params: new PlansFilters(filters || {}).format() };

    return this.http.get<{ count: number, items: SubscriptionPlan[] }>(this.url, requestParams)
      .pipe(
        map(({ count, items }) => ({
          count,
          items: items.map(item => new SubscriptionPlan(item))
        })),
        catchError(error => this.handleObserverError(error))
      );
  }

  create(
    instance: SubscriptionPlan,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<SubscriptionPlan> {
    return this.http.post<{ success: boolean, item: SubscriptionPlan }>(
      this.url,
      instance.asPayloadJSON()
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }

          emit && this.forceRefresh();
          toast && this.toaster.show({ text: message || `Subscription Plan Created.` });
        }),
        map(({ item }) => new SubscriptionPlan(item)),
        catchError(error => this.handleObserverError(error))
      );
  }

  update(
    plan: SubscriptionPlan,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<SubscriptionPlan> {
    return this.http.put<{ success: boolean, item: SubscriptionPlan }>(
      this.url + plan.id,
      {
        suspended: plan.suspended
      }
    )
      .pipe(
        tap(({ success }) => {
          if (!success) { return; }

          emit && this.forceRefresh();
          toast && this.toaster.show({ text: message || `Subscription Plan ${ plan.suspended ? 'Suspended' : 'Activated' }.` });
        }),
        map(({ item }) => new SubscriptionPlan(item)),
        catchError(error => this.handleObserverError(error))
      );
  }

  getItem(id: string): Observable<SubscriptionPlan> {
    return this.http.get<{ item: SubscriptionPlan }>(this.url + id)
      .pipe(
        map(({ item }) => new SubscriptionPlan(item)),
      );
  }

  @warmUpObservable
  delete(
    instance: SubscriptionPlan,
    { emit, toast, message }: FeedbackConfig = { emit: true, toast: true }
  ): Observable<boolean> {
    return this.http.delete<{ success: boolean }>(
      this.url + instance.id
    )
      .pipe(
        map(({ success }) => success),
        tap(success => {
          if (!success) { return; }

          emit && this.forceRefresh();

          toast && this.toaster.show({ text: message || 'Subscription Plan successfully deleted' });
        }),
        catchError(error => this.handleObserverError(error))
      );
  }
}
