// Common
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Types
import { Project } from '@modules/tasks/types/project';
import { DragData, DragDataTypes, dragDataTypeAllowed } from '@modules/drag-n-drop/types/drag-data';
import { ViewType } from '@modules/linked-info/types/view-type';

// Services
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { StitchService } from '@modules/common/services/stitch.service';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-link-info-project',
  templateUrl: './link-info-project.component.html',
  styleUrls: ['./link-info-project.component.less']
})
export class LinkInfoProjectComponent {

  // Inputs
  @Input() project: Project;
  @Input() viewType: ViewType = 'full';
  @Input() withContextMenu = true;
  @Input() withDraggable = true;
  @Input() withDroppable = true;
  @Input() withActions = true;
  @Input() withUnlink = true;
  @Input() removeOnly = false;

  // Outputs
  @Output() unlink: EventEmitter<void> = new EventEmitter();
  @Output() remove: EventEmitter<void> = new EventEmitter();

  // Public
  public contextMenuOpened = false;
  public isDragging = false;

  // Private
  private alive: Subject<void> = new Subject();

  // Callable attributes
  public dndPredicate = (dragData: DragData): boolean =>
    this.project &&
    !(
      dragData.type === DragDataTypes.project &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.project.id
    ) &&
    dragDataTypeAllowed(dragData.type)

  /**
   * Constructor
   */

  constructor (
    private projectsService: ProjectsService,
    private stitchService: StitchService,
  ) {

  }

  /**
   * Actions
   */

  unlinkItem(event) {
    event.stopPropagation();
    event.preventDefault();
    this.unlink.emit();
  }

  flag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.projectsService.flag({ ids: [this.project.id] }, !this.project.flagged)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.project.flagged = !this.project.flagged);
  }

  pin(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.projectsService.pin({ ids: [this.project.id] }, !this.project.pinned)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.project.pinned = !this.project.pinned);
  }

  archive() {
    this.projectsService.archive({ ids: [this.project.id] }, !this.project.archived)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.project.archived = !this.project.archived);
  }

  delete() {
    if (this.remove.observers.length > 0) {
      this.remove.emit();
    } else {
      this.project.deleted
        ? this.projectsService.deletePermanently({ ids: [this.project.id] })
        : this.projectsService.delete({ ids: [this.project.id] }, true);
    }
  }

  dndDrop(dragData: DragData) {
    this.stitchService.linkDragData(this.project, dragData);
  }
}
