<app-split-view
  side="left"
  key="tasksSidebar"
  [collapsedSize]="76"
  [shadow]="true"
  [magneticSize]="170"
  [withIcon]="true"
>
  <app-sidebar split-left-pane splitViewKey="tasksSidebar">
    <app-tasks-side-bar *stchSpaceRerender></app-tasks-side-bar>
  </app-sidebar>

  <app-split-view
    split-right-pane
    side="left"
    key="tasksList"
    [collapsedSize]="25"
    [shadow]="true"
    [magneticSize]="300"
    [withIcon]="true"
  >
    <app-tasks-list-container
      split-left-pane
      *stchSpaceRerender
      class="list"
    ></app-tasks-list-container>

    <div
      class="full-form"
      split-right-pane
    >
      <app-tasks-main-view *stchSpaceRerender></app-tasks-main-view>
    </div>
  </app-split-view>
</app-split-view>
