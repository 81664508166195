// Common
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, QueryList, SimpleChanges, TemplateRef, ViewChildren } from '@angular/core';
import { collapse } from '@modules/common/animations/collapse.animation';

// Types
import { NestedItem } from '@modules/common/types/nested-item';

// RX
import { merge, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-nested-items',
  templateUrl: './nested-items.component.html',
  styleUrls: ['./nested-items.component.less'],
  animations: [collapse]
})
export class NestedItemsComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() template: TemplateRef<NestedItemsComponent>;
  @Input() tree: NestedItem[];
  @Input() depth = 0;

  @ViewChildren('content') contentElements: QueryList<ElementRef>;

  public expanded: boolean[] = [];
  public contentHeight: number[] = [];

  public treeChanged = new Subject<void>();
  private alive = new Subject<void>();

  constructor(private cd: ChangeDetectorRef) {

  }

  /**
   * Lifecycle
   */

  ngAfterViewInit() {
    this.treeChanged
      .pipe(
        filter(() => !!this.tree),
        switchMap(() => merge(
          ...this.tree.map((item, index) =>
            item.expanded.pipe(map(value => [index, value]))
          )
        )),
        filter(() => !!this.contentElements),
        takeUntil(this.alive)
      )
      .subscribe(([index, value]: [number, boolean]) => {
        this.contentHeight.length = this.expanded.length = this.contentElements.length;
        this.contentHeight[index] = this.contentElements.toArray()[index]?.nativeElement?.offsetHeight || 0;
        this.expanded[index] = value;
        this.cd.detectChanges();
      });

    this.treeChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('tree' in changes) {
      this.treeChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
