// Common
import { Component, ViewEncapsulation, HostListener } from '@angular/core';

// Types
import { MailMessage } from './../../../mail/types/mail-message';

@Component({
  selector: 'app-mail-editor-popup',
  templateUrl: './mail-editor-popup.component.html',
  styleUrls: ['./mail-editor-popup.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class MailEditorPopupComponent {
  // Public
  message: MailMessage;

  /**
   * Actions
   */

  close() {
    window.close();
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: MessageEvent) {
    const currentOrigin = `${ window.location.protocol }//${ window.location.host }`;
    if (event.origin !== currentOrigin || !event.data['mailMessage']) {
      return;
    }
    this.message = event.data['mailMessage'] as MailMessage;
  }
}
