// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// Types
import { Notebook } from '@modules/notes/types/notebook';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Services
import { NotebooksService } from '@modules/notes/services/notebooks.service';

// Components
import { BaseQuickFormComponent } from '../base-quick-form/base-quick-form.component';

@Component({
  selector: 'app-notebook-quick-form',
  templateUrl: './notebook-quick-form.component.html',
  styleUrls: ['./notebook-quick-form.component.less'],
})
export class NotebookQuickFormComponent extends BaseQuickFormComponent implements OnChanges {

  // Inputs
  @Input() notebook: Notebook = new Notebook();

  // Outputs
  @Output() more: EventEmitter<Notebook> = new EventEmitter<Notebook>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<Notebook> = new EventEmitter();

  // Public
  public popoverClose = new Subject<void>();

  constructor (
    private notebooksService: NotebooksService,
    public changeDetector: ChangeDetectorRef
  ) {
    super(changeDetector);
    this.reset();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('notebook' in changes) {
      this.reset();
    }
  }

  /**
   * Actions
   */

  submit() {
    if (!this.form.valid) {
      return;
    }

    const notebook = Notebook.fromFormGroup(this.form);

    if (this.save.observers.length > 0) {
      this.save.emit(notebook);
      return;
    }

    this.saveInProgress = true;

    this.notebooksService.create(notebook)
      .pipe(takeUntil(this.alive))
      .subscribe(
        notebookInstance => {
          this.afterSave.emit(notebookInstance);
          this.close.emit();
        },
        () => this.handleError()
      );
  }

  reset() {
    if (!this.notebook) { return; }
    this.form = this.notebook.asFormGroup();
    super.reset();
  }

  clickMore() {
    this.more.emit(Notebook.fromFormGroup(this.form));
    super.clickMore();
  }
}
