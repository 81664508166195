import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Injector } from '@angular/core';
import { TypedFormGroup } from '@modules/common/utils/form';

export type ReminderUnits = 'minutes' | 'hours' | 'days' | 'weeks';
export type ReminderType = 'beforeStart' | 'beforeEnd';
export type ReminderNotifyByType = 'email' | 'notification' | 'push';

export  type ReminderFormGroup = FormGroup<TypedFormGroup<{
  duration: number,
  notifyAt: Date,
  notifyBy: ReminderNotifyByType,
  units: ReminderUnits,
  dateType: boolean,
  id: string
}>>;

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

const unitsMilliseconds = {
  minutes: 60000,
  hours: 3600000,
  days: 86400000,
  weeks: 604800000
};

export class Reminder {
  formBuilder = injector.get(FormBuilder);

  duration: number;
  units: ReminderUnits;
  reminderType: ReminderType;
  notifyAt: Date;
  notifyBy: ReminderNotifyByType;
  entityType: string;
  entityId: string;
  id: string;

  constructor(data: any = {}) {
    this.duration = data.duration ?? 5;
    this.units = data.units || 'minutes';
    this.reminderType = data.reminderType || 'beforeStart';
    this.notifyAt = data.notifyAt ? new Date(data.notifyAt) : null;
    this.notifyBy = data.notifyBy || 'notification';
    this.id = data.id;
  }

  static fromFormGroup({ value: formValues }: ReminderFormGroup) {
    return new Reminder({
      duration: formValues.duration,
      units: formValues.units,
      notifyAt: formValues.notifyAt,
      notifyBy: formValues.notifyBy,
      reminderType: formValues.dateType ? 'beforeStart' : 'beforeEnd',
      id: formValues.id
    });
  }

  asFormGroup(): ReminderFormGroup {
    return this.formBuilder.group({
      duration: [this.duration, Validators.required],
      notifyAt: [this.notifyAt],
      notifyBy: [this.notifyBy],
      units: [this.units, Validators.required],
      dateType: [this.reminderType === 'beforeStart'],
      id: [this.id]
    });
  }

  asPayloadJson(startDate: Date, endDate: Date) {
      const notifyAt = new Date(this.reminderType === 'beforeStart' ? startDate : endDate);
      const timeDiff = this.duration * unitsMilliseconds[this.units];
      notifyAt.setTime(notifyAt.getTime() - timeDiff);

    return {
      duration: this.duration,
      notifyAt,
      notifyBy: this.notifyBy,
      units: this.units,
      reminderType: this.reminderType,
      entityType: this.entityType,
      entityId: this.entityId,
      id: this.id
    };
  }

  toText(): string {
    if (this.duration === 0) { return 'On time'; }
    return `${this.duration} ${this.duration > 1 ? this.units : this.units.slice(0, -1)} ${this.reminderType === 'beforeStart' ? 'before start' : 'before end'}`;
  }
}
