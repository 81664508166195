// Common
import { Injectable} from '@angular/core';
import { environment } from '@environment';

// Services
import { BaseSectionsService } from '@modules/common/services/base-sections.service';

@Injectable()
export class SectionsService extends BaseSectionsService {
  protected url = environment.baseUrl + '/api/contacts/sections';
}
