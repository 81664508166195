// Common
import { UntypedFormGroup, Validators } from '@angular/forms';

// Utils
import { checkExhaustiveness } from '@modules/common/utils/switch';
import { FileValidators } from '@modules/form-controls/validators/file.validators';

// Types
import { ChildStitch } from '@modules/common/types/child-stitch';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { AdvancedSearchState } from '@modules/search/types/advanced-search-state';
import { StitchType } from '@modules/common/types/stitch-type';
import { Message } from '@modules/messages/types/message';
import { CalendarEvent as AngularCalendarEvent } from 'calendar-utils';
import { Stitch } from '@modules/common/types/stitch';
import { VirtualFolder } from '@modules/notes/types/virtual-folder';
import { Like } from '@modules/common/types/like';
import { Upload } from '@modules/common/types/upload';

export class Note extends ChildStitch {
  id?: string;
  notebookId?: string;
  body?: string;
  snippet?: string;
  title?: string;
  inlineUploads?: Upload[];

  constructor(data: any = {}) {
    super(data);
    this.containerId = data?.containerId || data?.notebookId;

    if (data instanceof Stitch) {
      this.fillFromStitch(data);
    } else {
      this.body = data.body;
      this.notebookId = data.notebookId || data.containerId;
      this.snippet = data.snippet;
      this.title = data.title;
      this.inlineUploads = data.inlineUploads;
    }
  }

  static fromFormGroup(form: UntypedFormGroup): Note {
    const formValues = form.value;

    return new Note({
      archived: formValues.archived,
      body: formValues.body,
      color: formValues.color,
      connections: formValues.connections,
      createdAt: formValues.createdAt,
      deleted: formValues.deleted,
      description: formValues.description,
      dueDate: formValues.dueDate,
      files: formValues.files,
      flagged: formValues.flagged,
      followed: formValues.followed,
      id: formValues.id,
      knots: formValues.knots,
      linkedInfo: formValues.linkedInfo,
      notebookId: formValues.notebookId,
      pinned: formValues.pinned,
      position: formValues.position,
      shareUid: formValues.shareUid,
      snoozed: formValues.snoozed,
      tags: formValues.tags,
      temp: formValues.temp,
      title: formValues.title,
      updatedAt: formValues.updatedAt,
      uploads: formValues.uploads,
      inlineUploads: formValues.inlineUploads
    });
  }

  static fromDragData(dragItem: DragData): Note {
    return <Note>super.fromDragData(dragItem);
  }

  static fromAdvancedState(filters: AdvancedSearchState, folder: VirtualFolder): Note {
    return new Note({
      title: filters.query || filters.notes.title,
      tags: filters.tags,
      knots: filters.knots,
      description: filters.notes.body || filters.notes.description,
      notebookId: filters.notes.containersIds?.[0],
      flagged: folder === 'flagged',
      archived: folder === 'archived',
      deleted: folder === 'deleted'
    });
  }

  static shouldRefreshList(prev, current) {
    return super.shouldRefreshList(prev, current, ['title', 'description', 'color', 'notebookId']);
  }

  static getChangesForVirtualFolder(sidebar: VirtualFolder): { changes: Like<Note>, message: string } {
    let message = 'Successfully moved to ';

    switch (sidebar) {
      case 'all_notes':
        return { changes: { notebookId: null, parentId: null }, message: message += 'Orphan' };
      case 'all_notebooks':
      case 'upcoming':
      case 'recent':
        break;
      case 'archived':
      case 'deleted':
      case 'followed':
      case 'snoozed':
      case 'flagged':
        return super.getChangesForVirtualFolder(sidebar);
      default:
        checkExhaustiveness(sidebar);
    }
  }

  getStitchType(): StitchType {
    return StitchType.note;
  }

  asFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      archived: [this.archived],
      body: [this.body],
      color: [this.color],
      connections: [this.connections],
      createdAt: [this.createdAt],
      deleted: [this.deleted],
      description: [this.description],
      flagged: [this.flagged],
      followed: [this.followed],
      id: [this.id],
      knots: [this.knots],
      linkedInfo: [this.linkedInfo],
      notebookId: [this.notebookId],
      pinned: [this.pinned],
      position: [this.position],
      shareUid: [this.shareUid],
      snoozed: [this.snoozed],
      tags: [this.tags],
      temp: [this.temp],
      title: [this.title, Validators.required],
      updatedAt: [this.updatedAt],
      uploads: [this.uploads || [], FileValidators.size(26214400)],
      inlineUploads: [this.inlineUploads || [], FileValidators.size(26214400)]
    });
  }

  asPayloadJSON() {
    return {
      archived: this.archived,
      body: this.body || '',
      color: this.color,
      deleted: this.deleted,
      flagged: this.flagged,
      followed: this.followed,
      notebookId: this.notebookId,
      pinned: this.pinned,
      title: this.title,
      description: this.description,
      snoozed: this.snoozed,
      noNotification: this.noNotification,
      uploads: this.inlineUploads?.map(({ id, name, size, type, inline }) => ({ id, name, size, type, inline })),
    };
  }

  asAngularCalendarEvent(): AngularCalendarEvent {
    return this.convertToAngularCalendarEvent(
      this.title, this.createdAt, this.updatedAt, this.createdAt, this.updatedAt
    );
  }

  protected fillFromStitch(data: Stitch) {
    super.fillFromStitch(data);

    if (data instanceof Note) {
      this.body = data.body;
      this.snippet = data.snippet;
      this.notebookId = data.notebookId;
    } else if (data instanceof Message) {
      this.description = data.bodyText;
    }
  }
}
