// Common
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Services
import { BaseSettingsService } from '@modules/common/services/base-settings.service';

// Types
import { Settings } from '../types/settings';

// Env
import { environment } from '@environment';

@Injectable()
export class FilesAppSettingsService extends BaseSettingsService<Settings> {
  protected url = `${environment.baseUrl}/api/files/settings`;

  constructor (http: HttpClient) {
    super(http);
  }

  createInstance(data?: any) {
    return new Settings(data);
  }
}
