// Common
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-snippet',
  templateUrl: './message-snippet.component.html',
  styleUrls: ['./message-snippet.component.less']
})
export class MessageSnippetComponent {

  // Inputs
  @Input() snippet: any[];
}
