<div class="heading1">Settings</div>
<div class="heading2-subtitle">Contacts App</div>

<div class="block column">
  <div class="heading2">Sort Order</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.sortOrder"
    [options]="sortOrderOptions"
    appearance="amethyst"
  ></app-dropdown-input>
</div>

<div class="block column no-border">
  <div class="heading2">Display Order</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.sortDisplay"
    [options]="sortDisplayOptions"
    appearance="amethyst"
  ></app-dropdown-input>
</div>

<div class="block column">
  <div class="heading2">Address Layout</div>

  <app-dropdown-input
    class="dropdown"
    [inputFormControl]="form.controls.addressLayout"
    [options]="addressLayoutOptions"
    appearance="amethyst"
  ></app-dropdown-input>

  <app-checkbox
    class="check-box"
    [inputFormControl]="form.controls.isFormatPhoneNumbers"
    title="Automatically format phone numbers"
    appearance="new"
  ></app-checkbox>
</div>

<div class="block column">
  <div class="heading2">Default Area Code</div>

  <div class="body-primary area-code">Default Area Code – If you know that you’ll be using this phone to frequently make calls to a specific area code, you can specify the area code here as a shortcut. This way, you won’t need to dial the area code for each call. We’ll automatically do it for you, saving you a little bit of time.</div>

  <app-input
    class="input"
    [inputFormControl]="form.controls.defaultAreaCode"
  ></app-input>
</div>
