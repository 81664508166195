<div
  class="heading"
  (resize)="handleResize($event)"
>
  <div class="meta">
    <app-message-connections [message]="message" />
  </div>

  <div class="icons">
    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="bug"
      [active]="selectedTab === 'debug'"
      matTooltip="Debug"
      (onClick)="selectedTab = 'debug'"
    />

    <app-button
      *ngIf="!contracted"
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-stitch"
      [disabled]="!message?.id"
      matTooltip="Stitch To"
      [appStitchToPopover]="message"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-attachment"
      matTooltip="Reply"
      [disabled]="!message?.id"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-reply"
      matTooltip="Reply"
      [disabled]="!message?.id"
      (click)="forward('reply')"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-reply-all"
      matTooltip="Reply To All"
      [disabled]="!message?.id"
      (click)="forward('replyAll')"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-mail-forward"
      matTooltip="Reply To All"
      [disabled]="!message?.id"
      (click)="forward('forward')"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-share"
      matTooltip="Share"
      [disabled]="!message?.id"
      [stchShareContextMenu]="message"
      [stchShareContextMenuDisabled]="!message?.id"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-ellipsis"
      matTooltip="More Options"
      [appMessageContextMenu]="message"
      appMessageContextMenuMode="popover"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-close"
      (click)="handleClose()"
    />
  </div>
</div>

<div class="subject">
  <div class="subject-input">{{ message.subject }}</div>
  <div class="subject-date">{{ message.date | relativeDate }}</div>
</div>

<div class="tabs">
  <app-tabs
    appearance="sapphire"
    [values]="tabs"
    [(value)]="selectedTab"
    [selectedStateKey]="tabsStateKey"
    size="m"
  />

  <div class="filler"></div>

  <app-button appearance="sapphire-secondary" size="xs" label="Assign" />

  <stch-knowledge-button
    [active]="displayKnowledge"
    (click)="showKnowledge()"
  />
</div>

<div
  class="form"
  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="drop-area-border"></div>

  <div class="form-heading">
    <div class="form-heading-nav">
      <div class="form-heading-nav-separator"></div>

      <app-context-menu-state-indicator
        *ngIf="selectedTab === 'stitch'"
        class="form-heading-nav-state"
        svgIcon="filters"
        appStitchListContextMenu
        (appStitchListContextMenuChange)="stitchListState = $event"
      ></app-context-menu-state-indicator>

      <app-context-menu-state-indicator
        *ngIf="selectedTab === 'lab'"
        class="form-heading-nav-state"
        svgIcon="filters"
        appKnowledgeChartContextMenu
        (appKnowledgeChartContextMenuChange)="chartState = $event"
        matTooltip="Graph Filters"
      ></app-context-menu-state-indicator>
    </div>

    <div
      *ngIf="displayKnowledge"
      class="form-heading-nav-underline"
    ></div>

    <app-tags
      *ngIf="message"
      class="form-tags"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[message]"
      stateKey="ff-tags"
    ></app-tags>

    <app-knots
      *ngIf="message"
      class="form-knots"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[message]"
      stateKey="ff-knots"
    ></app-knots>

    <app-connections
      class="form-knots"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[message]"
      stateKey="ff-connections"
    ></app-connections>
  </div>

  <app-scroll
    *ngIf="selectedTab !== 'lab'"
    [ngSwitch]="selectedTab"
    class="form-scroll"
    (top)="displayScrollShadow = !$event"
  >
    <div
      *ngIf="displayScrollShadow"
      class="form-scroll-shadow"
    ></div>

    <div
      *ngSwitchCase="'message'"
      class="form-details"
      [selectionTooltip]="message"
    >
      <app-caja
        *ngIf="message?.bodyHtml && !displayRaw && !debug"
        [body]="message?.bodyHtml"
        [highlights]="
          selectedTemporalExressions?.length
            ? (selectedTemporalExressions | map : 'sentence')
            : (selectedKnots | map : 'name')
        "
      ></app-caja>
    </div>

    <div
      *ngSwitchCase="'raw'"
      class="form-details"
    >
      <pre [innerText]="message?.raw"></pre>
    </div>

    <div
      *ngSwitchCase="'debug'"
      class="form-details"
    >
      <stch-json-viewer [json]="message?.debug"></stch-json-viewer>
    </div>

    <div
      *ngSwitchCase="'attachments'"
      class="form-details"
    >
      <app-attachments
        [stitchItem]="message"
      ></app-attachments>
    </div>

    <app-linked-info-list
      *ngSwitchCase="'stitch'"
      class="form-linked-info"
      [stitch]="message"
      [state]="stitchListState"
      (clickStitchItem)="handleClickItem($event)"
      (dblClickStitchItem)="handleDblClickItem($event)"
    ></app-linked-info-list>

    <stch-comments
      *ngSwitchCase="'comments'"
      class="form-linked-info"
      [stitch]="message"
    ></stch-comments>
  </app-scroll>

  <app-knowledge-chart
    *ngIf="selectedTab === 'lab'"
    class="form-lab"
    [sizeChanges]="displayKnowledge"
    [item]="message"
    [state]="chartState"
  ></app-knowledge-chart>
</div>

<ng-template #dragPlaceholder>
  <app-message
    [message]="message"
    [dragEnabled]="false"
    [draggable]="true"
    [actionsButtonEnabled]="false"
    [threadEnabled]="false"
  ></app-message>
</ng-template>
