// Common
import { Pipe, PipeTransform } from '@angular/core';
import { checkExhaustiveness } from '@modules/common/utils/switch';

// Types
import { VirtualFolder } from '../types/virtual-folder';

@Pipe({
  name: 'titleByEventsFilter'
})
export class TitleByEventsFilterPipe implements PipeTransform {

  transform(value: VirtualFolder): any {
    if (!value) { return 'All'; }

    switch (value) {
      case 'all_events':
        return 'Events';
      case 'archived':
        return 'Archived';
      case 'deleted':
        return 'Trash';
      case 'all_calendars':
        return 'Calendars';
      case 'followed':
        return 'Follow Up';
      case 'snoozed':
        return 'Snoozed';
      case 'flagged':
        return 'Flagged';
      default:
        return checkExhaustiveness(value);
    }
  }
}
