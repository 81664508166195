<div class="block column">
  <div class="heading2">Before Start Date reminder</div>

  <div class="body-primary">Set default reminder to tasks that have Start Date</div>

  <div class="block-row">
    <app-dropdown-input
      class="dropdown"
      [inputFormControl]="form.controls.startDateTasks"
      [options]="tasksOptions"
      appearance="amethyst"
    ></app-dropdown-input>

    <app-dropdown-input
      class="dropdown"
      [inputFormControl]="form.controls.startDateReminder"
      [options]="reminderOptions"
      appearance="amethyst-simple"
    ></app-dropdown-input>
  </div>
</div>

<div class="block column">
  <div class="heading2">Before Due Date reminder</div>

  <div class="body-primary">Set default reminder to tasks that have Due Date</div>

  <div class="block-row">
    <app-dropdown-input
      class="dropdown"
      [inputFormControl]="form.controls.dueDateTasks"
      [options]="tasksOptions"
      appearance="amethyst"
    ></app-dropdown-input>

    <app-dropdown-input
      class="dropdown"
      [inputFormControl]="form.controls.dueDateReminder"
      [options]="reminderOptions"
      appearance="amethyst-simple"
    ></app-dropdown-input>
  </div>
</div>

<div class="block column">
  <div class="heading2">After overdue reminder</div>

  <div class="body-primary">Set default reminder to tasks that have Due Date</div>

  <div class="block-row">
    <app-dropdown-input
      class="dropdown"
      [inputFormControl]="form.controls.overdueTasks"
      [options]="tasksOptions"
      appearance="amethyst"
    ></app-dropdown-input>

    <app-dropdown-input
      class="dropdown"
      [inputFormControl]="form.controls.overdueReminder"
      [options]="reminderOptions"
      appearance="amethyst-simple"
    ></app-dropdown-input>
  </div>
</div>
