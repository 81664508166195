// Common
import { Component, Inject, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

// Types
import { Notification } from '@modules/notification/types/notification';
import { ButtonsActionTypes } from '@modules/notification/types/notifications-type';

// Services
import { ModalService } from '@modules/modal/services/modal.service';
import { NotificationsService } from '@modules/notification/services/notifications.service';
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';

// Injection Token
import CloseToken from '@modules/modal/types/modal-close.injection-token';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.less']
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() notification: Notification;
  @Input() showIssueKey: boolean;

  public checkboxControl: FormControl<boolean>;
  public popoverShowUntil = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private stitchServiceFactory: StitchServiceFactory,
    @Inject(CloseToken) private closeToken,
    private injector: Injector
  ) { }

  ngOnInit(): void {
    this.checkboxControl = new FormControl({ value: this.notification?.isRead, disabled: this.notification?.isRead });
  }

  ngOnDestroy() {
    this.popoverShowUntil.next();
    this.popoverShowUntil.complete();
  }

  handleCheckRead(isRead?: boolean) {
    const { value: isChecked } = this.checkboxControl;
    this.notificationsService.markAsRead(isRead ?? isChecked, [this.notification.id], { emitUpdate: false });
  }

  handleClickEntity() {
    // this.modalService.openFullFormByType({ type: this.notification.entity.entityType, id: this.notification.entity.id });
    this.stitchServiceFactory.getServiceByStitchType(this.notification.entity.entityType)
      .getItem(this.notification.entity.id)
      .pipe(
        take(1),
        takeUntil(this.popoverShowUntil)
      )
      .subscribe((item) => {
        this.modalService.openFullForm(item, this.injector);
      });

    this.handleCheckRead(true);
    this.closeToken.next();
  }

  handleClickAction(buttonActionType: ButtonsActionTypes, data?: unknown) {
    this.popoverShowUntil.next();
    this.notificationsService
      .handleAction({ notification: this.notification, buttonActionType, data })
      .pipe(
        take(1),
        takeUntil(this.popoverShowUntil)
      )
      .subscribe();
    this.checkboxControl.setValue(true);
    this.handleCheckRead(true);
  }
}
