// Common
import { Component, OnInit, Input, ElementRef } from '@angular/core';

// Services
import { PopupService } from '../../../popup/services/popup.service';
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { AccountService } from '@modules/account/services/account.service';

// Components
import { BaseModalComponent } from '../base-modal/base-modal.component';

// Types
import { ModalFrame } from '@modules/modal/types/modal-frame';
import { Stitch } from '@modules/common/types/stitch';
import { Account } from '@modules/account/types/account';

// RX
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-full-form-modal',
  templateUrl: './full-form-modal.component.html',
  styleUrls: ['./full-form-modal.component.less'],
  providers: [KnowledgePanelService, DynamicPanelService]
})
export class FullFormModalComponent extends BaseModalComponent implements OnInit {

  // Inputs
  @Input() stitchItem: Stitch;

  // Override
  public defaultSize: ModalFrame = {
    x: '15vw',
    y: '5vh',
    width: '70vw',
    height: '90vh'
  };

  // Public
  public account: Account;

  /**
   * Constructor
   */

  constructor(
    protected element: ElementRef,
    protected popupService: PopupService,
    private accountService: AccountService,
  ) {
    super();
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.accountService.getAccount()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(account => {
        this.account = account;
      });
  }

  /**
   * Actions
   */

  expand() {
    this.popupService.openFullForm(this.stitchItem, this.maximizedSize.popupSize());
    this.close();
  }
}
