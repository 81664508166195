// Common
import { Component, OnInit, OnDestroy } from '@angular/core';

// Services
import { NotesAppStateService } from '@modules/notes/services/state.service';

// Types
import { Note } from '@modules/notes/types/note';
import { Notebook } from '@modules/notes/types/notebook';
import { StitchType } from '@modules/common/types/stitch-type';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notes-main-view',
  templateUrl: './notes-main-view.component.html',
  styleUrls: ['./notes-main-view.component.less'],
})
export class NotesMainViewComponent implements OnInit, OnDestroy {

  public item: Note | Notebook;
  public StitchType = StitchType;

  private alive: Subject<void> = new Subject();

  constructor(
    private notesStateService: NotesAppStateService,
  ) { }

  /**
   * Component lifecycle
   */

  ngOnInit() {
    this.notesStateService.getMainView()
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe((item) => {
        this.item = item;
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleFormClose() {
    this.notesStateService.setMainView(null);
  }
}
