// Common
import { Component, Injector } from '@angular/core';

// Services
import { FilesAppStateService } from '@modules/files/services/state.service';
import { FilesService } from '@modules/files/services/files.service';
import { FoldersService } from '@modules/files/services/folders.service';

// Types
import { VirtualFolder } from '@modules/files/types/virtual-folder';
import { DragData, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';
import { Application } from '@modules/common/types/application';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { File } from '@modules/files/types/file';
import { Folder } from '@modules/files/types/folder';

// Components
import { BaseSidebarComponent } from '@modules/common/components/base-sidebar/base-sidebar.component';
import { SidebarItem } from '@modules/common/types/sidebar-item';

@Component({
  selector: 'app-files-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.less'],
})

export class FilesSideBarComponent extends BaseSidebarComponent<Folder, File, VirtualFolder> {
  application = Application.files;
  sidebarSplitViewKey = SidebarSplitViewKey.files;
  selfDragDataTypes = [DragDataTypes.file, DragDataTypes.folder];

  public items: SidebarItem[] = [
    { title: 'Files', id: 'all_files', icon: 'sapphire-file' },
    { title: 'Upcoming', id: 'upcoming', icon: 'sapphire-upcoming' },
    { title: 'Follow Up', id: 'followed', icon: 'sapphire-follow-up' },
    { title: 'Snoozed', id: 'snoozed', icon: 'sapphire-snooze' },
    { title: 'Recent', id: 'recent', icon: 'sapphire-recent' },
    { title: 'Archive', id: 'archived', icon: 'sapphire-archive' },
    { title: 'Trash', id: 'deleted', icon: 'sapphire-trash' }
  ];

  constructor(
    stateService: FilesAppStateService,
    injector: Injector,
    private filesService: FilesService,
    private foldersService: FoldersService,
  ) {
    super(injector, stateService);
  }

  /**
   * Actions
   */

  handleMove(dragData: DragData, sidebarFolder: VirtualFolder) {
    if (dragData.type === DragDataTypes.file) {
      const { changes, message } = File.getChangesForVirtualFolder(sidebarFolder);
      this.filesService.bunchUpdate({ ids: this.getIds(dragData.data) }, changes, { message });
    } else if (dragData.type === DragDataTypes.folder) {
      const { changes, message } = Folder.getChangesForVirtualFolder(sidebarFolder);
      this.foldersService.bunchUpdate({ ids: this.getIds(dragData.data) }, changes, { message });
    }
  }

  handleMore(item: File) {
    this.stateService.setMainView(item);
  }

  getStitchFromDragData(dragData: DragData, sidebarFolder: VirtualFolder) {
    const { changes } = File.getChangesForVirtualFolder(sidebarFolder);

    return new File({ ...changes, name: dragData.data[0]['title'] });
  }
}
