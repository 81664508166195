<div
  stchPopover
  stchPopoverPlacement="bottom"
  stchPopoverTrigger="click"
  [stchPopoverContent]="pickerTemplate"
  [stchPopoverShowUntil]="popoverClose"
  (stchPopoverVisibleChange)="handlePopoverVisibleChange($event)"
>
  <ng-content></ng-content>
</div>

<ng-template #pickerTemplate>
  <div class="notification-input">
    <div class="notification-input-value">
      <app-input
        class="notification-input-value-value notification-input-value-item"
        [inputFormControl]="innerFormGroup.controls.duration"
      >
      </app-input>
      <div class="notification-input-value-units notification-input-value-item">
        {{ innerFormGroup.controls.units.value }}
      </div>
    </div>

    <div class="notification-input-delivery-type">
      <app-radio-button
        appearance="checkbox"
        title="Email"
        [inputFormControl]="innerFormGroup.controls.notifyBy"
        value="email"
      ></app-radio-button>
      <app-radio-button
        appearance="checkbox"
        title="Notification"
        [inputFormControl]="innerFormGroup.controls.notifyBy"
        value="notification"
      ></app-radio-button>
    </div>

    <div class="notification-input-date-type">
      Apply reminder for date:
      <app-toggle-switch
        appearance="sapphire-selector"
        [inputFormControl]="innerFormGroup.controls.dateType"
        trueLabel="Start"
        falseLabel="End"
      />
    </div>

    <div class="notification-input-units">
      <app-radio-button
        value="minutes"
        title="M"
        [inputFormControl]="innerFormGroup.controls.units"
      ></app-radio-button>
      <app-radio-button
        value="hours"
        title="H"
        [inputFormControl]="innerFormGroup.controls.units"
      ></app-radio-button>
      <app-radio-button
        value="days"
        title="D"
        [inputFormControl]="innerFormGroup.controls.units"
      ></app-radio-button>
      <app-radio-button
        value="weeks"
        title="W"
        [inputFormControl]="innerFormGroup.controls.units"
      ></app-radio-button>
    </div>

    <div class="notification-input-options">
      <div
        class="notification-input-options-item"
        [class.active]="!innerFormGroup.controls.duration.value"
        (click)="setValue(null)"
      >
        None
      </div>

      <div
        class="notification-input-options-item"
        *ngFor="let option of options[innerFormGroup.controls.units.value]"
        [class.active]="option === innerFormGroup.controls.duration.value"
        (click)="setValue(option)"
      >
        {{ option | notificationText : innerFormGroup.controls.units.value }}
      </div>
    </div>

    <div class="notification-input-buttons">
      <app-button appearance="square" (click)="handleCancel()" label="Cancel" />

      <app-button
        appearance="square-blue"
        label="Save"
        (click)="handleSave()"
        [disabled]="innerFormGroup.invalid"
      />
    </div>
  </div>
</ng-template>
