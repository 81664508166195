<div
  class="heading"
  (resize)="handleResize($event)"
>
  <div class="logo">
    {{ form.controls.title.value | initials : 3 }}
  </div>

  <app-input
    class="input"
    appearance="sapphire-inline"
    [inputFormControl]="form.controls.title"
    placeholder="New Contact"
    [validationMessages]="{ required: 'Can\'t be blank' }"
    [withErrors]="submitted"
    size="m"
  />

  <div class="icons">
    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="bug"
      [active]="selectedTab === 'debug'"
      matTooltip="Debug"
      (onClick)="selectedTab = 'debug'"
    />

    <app-button
      *ngIf="!contracted"
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-stitch"
      [disabled]="!contact?.id"
      matTooltip="Stitch To"
      [appStitchToPopover]="contact"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-archive2"
      [active]="form.controls.archived.value"
      [matTooltip]="form.controls.archived.value ? 'Move To Archive' : 'Restore From Archive'"
      (onClick)="archive()"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-trash"
      [active]="form.controls.deleted.value"
      [matTooltip]="form.controls.deleted.value ? 'Move To Trash' : 'Restore From Trash'"
      (onClick)="delete()"
    />

    <stch-quick-action
      *ngIf="!contracted"
      appearance="sapphire-toggle"
      size="m"
      icon="sapphire-share"
      matTooltip="Share"
      [disabled]="!contact?.id"
      [stchShareContextMenu]="contact"
      [stchShareContextMenuDisabled]="!contact?.id"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-ellipsis"
      matTooltip="More Options"
      [appContactContextMenu2]="contact"
      appContactContextMenu2Mode="popover"
    />

    <app-button
      appearance="sapphire-secondary-ghost"
      size="xs"
      icon="sapphire-close"
      (click)="handleClose()"
    />
  </div>
</div>

<div class="tabs">
  <app-tabs
    appearance="sapphire"
    [values]="tabs"
    [(value)]="selectedTab"
    [selectedStateKey]="tabsStateKey"
    size="m"
  />

  <div class="filler"></div>

  <app-button appearance="sapphire-secondary" size="xs" label="Assign" />

  <stch-knowledge-button
    [active]="displayKnowledge"
    (click)="showKnowledge()"
  />
</div>

<div
  class="form"
  appDroppable
  appDroppableHoverClass="drag-over"
  [appDroppablePredicate]="dndPredicate"
  (appDroppableDrop)="dndDrop($event)"
>
  <div class="drop-area-border"></div>

  <div class="form-heading">
    <div class="form-heading-nav">
      <div class="form-heading-nav-separator"></div>

      <app-context-menu-state-indicator
        *ngIf="selectedTab === 'stitch'"
        class="form-heading-nav-state"
        svgIcon="filters"
        appStitchListContextMenu
        (appStitchListContextMenuChange)="stitchListState = $event"
      ></app-context-menu-state-indicator>

      <app-context-menu-state-indicator
        *ngIf="selectedTab === 'lab'"
        class="form-heading-nav-state"
        svgIcon="filters"
        appKnowledgeChartContextMenu
        (appKnowledgeChartContextMenuChange)="chartState = $event"
        matTooltip="Graph Filters"
      ></app-context-menu-state-indicator>
    </div>

    <div
      *ngIf="displayKnowledge"
      class="form-heading-nav-underline"
    ></div>

    <app-tags
      class="form-tags"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[contact]"
      [control]="form.controls['tags']"
      stateKey="ff-tags"
    ></app-tags>

    <app-knots
      class="form-knots"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[contact]"
      [control]="form.controls['knots']"
      stateKey="ff-knots"
    ></app-knots>

    <app-connections
      class="form-knots"
      [class.hidden]="!displayKnowledge"
      [stitchItems]="[contact]"
      [control]="form.controls['connections']"
      stateKey="ff-connections"
    ></app-connections>
  </div>

  <app-scroll
    *ngIf="selectedTab !== 'lab'"
    [ngSwitch]="selectedTab"
    class="form-scroll"
    (top)="displayScrollShadow = !$event"
  >
    <div
      *ngIf="displayScrollShadow"
      class="form-scroll-shadow"
    ></div>

    <div
      *ngSwitchCase="'contact'"
      class="details"
      [class.contracted]="contracted"
    >
      <div class="details-row">
        <div class="details-row-label">Job Title</div>
        <app-input
          class="details-row-input"
          [style.width]="contracted ? '100%' : '340px'"
          appearance="sapphire-outline"
          [inputFormControl]="form.controls.jobTitle"
          placeholder="Job Title"
          [withErrors]="submitted"
          [withClear]="true"
          size="s"
        />
      </div>

      <div class="details-row">
        <app-checkbox
          [inputFormControl]="form.controls.vip"
          title="VIP Contact"
          appearance="sapphire"
          size="s"
        />
      </div>

      <div class="details-row">
        <div class="details-row-label">Country</div>
        <app-country-picker
          class="form-details-row-input"
          [style.width]="contracted ? '100%' : '340px'"
          [inputFormControl]="form.controls.country"
          placeholder="Select country"
        ></app-country-picker>
      </div>

      <div class="details-row">
        <div class="details-row-label">Phone Number</div>
        <app-controls-list
          class="details-row-input"
          [style.width]="contracted ? '100%' : '340px'"
          [items]="form.controls.phones"
          [itemTemplateRef]="phoneNumberItemTemplate"
          (onAddItem)="handleAddItem('phones', $event)"
        >
          <ng-template #phoneNumberItemTemplate let-phoneNumberControl=item>
            <app-input-side-options
              [sideFormControl]="phoneNumberControl['controls'].category"
              [sideOptions]="contactInfoTypes"
            >
              <app-phone-number-input
                [inputFormControl]="phoneNumberControl['controls'].value"
              ></app-phone-number-input>
            </app-input-side-options>
          </ng-template>
        </app-controls-list>
      </div>

      <div class="details-row">
        <div class="details-row-label">Email</div>
        <app-controls-list
          class="details-row-input"
          [style.width]="contracted ? '100%' : '340px'"
          [items]="form.controls.emails"
          [itemTemplateRef]="emailItemTemplate"
          (onAddItem)="handleAddItem('emails', $event)"
        >
          <ng-template #emailItemTemplate let-emailControl=item>
            <app-input-side-options
              [sideFormControl]="emailControl['controls'].category"
              [sideOptions]="contactInfoTypes"
            >
              <app-input
                [inputFormControl]="emailControl['controls'].value"
                placeholder="Enter email"
              ></app-input>
            </app-input-side-options>
          </ng-template>
        </app-controls-list>
      </div>

      <div class="details-row">
        <div class="details-row-label">Birth Date</div>
        <app-dropdown-date-input
          [style.width]="contracted ? '100%' : '340px'"
          [control]="form.controls.birthDate"
          appearance="sapphire-outline"
          placeholder="Birth Date"
          size="m"
        />
      </div>

      <div class="details-row">
        <div class="details-row-label">Address</div>
        <app-input
          class="details-row-input"
          [style.width]="contracted ? '100%' : '340px'"
          appearance="sapphire-outline"
          [inputFormControl]="form.controls.address"
          placeholder="Address"
          [withErrors]="submitted"
          [withClear]="true"
          size="s"
        />
      </div>

      <div class="details-row">
        <div class="details-row-label">Group</div>

        <app-autocomplete-input
          class="details-row-input"
          [style.width]="contracted ? '100%' : '340px'"
          appearance="sapphire-outline"
          placeholder="Add To Group"
          [suggestions]="suggestions"
          [inputFormControl]="form.controls.groupId"
          size="s"
          [withErrors]="submitted"
          [withClear]="true"
        />
      </div>

      <div class="details-row">
        <div class="details-row-label">Description</div>
        <app-textarea
          class="details-row-input"
          [style.width]="contracted ? '100%' : '340px'"
          appearance="sapphire-outline"
          [inputFormControl]="form.controls.description"
          [rows]="3"
          [withClear]="true"
          placeholder="Description"
          [validationMessages]="{ required: 'Please enter a value' }"
          [withErrors]="submitted"
        />
      </div>
    </div>

    <app-attachments-list
      class="form-attachments"
      *ngSwitchCase="'attachments'"
      [stitchItem]="stitchItem"
      [inputControl]="form.controls.uploads"
      contextMenuStateVariant="contacts"
    />

    <app-linked-info-list
      *ngSwitchCase="'stitch'"
      class="form-linked-info"
      [stitch]="contact"
      [state]="stitchListState"
      (clickStitchItem)="handleClickItem($event)"
      (dblClickStitchItem)="handleDblClickItem($event)"
    ></app-linked-info-list>

    <stch-comments
      *ngSwitchCase="'comments'"
      class="form-linked-info"
      [stitch]="contact"
    ></stch-comments>

    <stch-json-viewer
      *ngSwitchCase="'debug'"
      [json]="contact?.debug"
    ></stch-json-viewer>
  </app-scroll>

  <app-knowledge-chart
    *ngIf="selectedTab === 'lab'"
    class="form-lab"
    [sizeChanges]="displayKnowledge"
    [item]="contact"
    [state]="chartState"
  ></app-knowledge-chart>
</div>
