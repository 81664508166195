// Common
import { Component, Input, OnChanges, SimpleChanges, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';

// Utils
import { relativeDateToDate } from '@modules/common/utils/date';

// RX
import { Subject, of } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

// Types
import { RRule } from 'rrule';
import { Reminder, ReminderFormGroup } from '@modules/form-controls/types/reminder';
import { RelativeDate } from '@modules/common/types/relative-date';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-time-range-picker.component.html',
  styleUrls: ['./date-time-range-picker.component.less']
})
export class DateTimeRangePickerComponent implements OnInit, OnChanges, OnDestroy {

  @Input() fromDate: FormControl<Date>;
  @Input() toDate: FormControl<Date>;
  @Input() fromTime: FormControl<Date>;
  @Input() toTime: FormControl<Date>;
  @Input() reminders: FormArray<ReminderFormGroup>;
  @Input() recurrence: FormControl<string>;

  public innerFromDate = new FormControl<Date>(null);
  public innerToDate = new FormControl<Date>(null);
  public innerFromTime = new FormControl<Date>(null);
  public innerToTime = new FormControl<Date>(null);
  public innerReminders = new FormArray<ReminderFormGroup>([]);
  public innerRecurrence = new FormControl<string>(null);

  public displayFromDate = new FormControl<string>('');
  public displayToDate = new FormControl<string>('');
  public displayFromTime = 'No Time';
  public displayToTime = 'No Time';
  public displayRecurrence = 'Repeat';
  public displayReminders = 'Reminder';

  public popoverClose = new Subject<void>();
  public state: 'main' | 'reminders' | 'recurrency' = 'main';
  public opened = false;
  public recurrencePopoverOpened = false;
  public remindersPopoverOpened = false;
  public recurrencePopoverHide = new Subject();
  public remindersPopoverHide = new Subject();
  public dateButtonSelected: 'from' | 'to' = 'from';
  public timeButtonSelected: 'from' | 'to';

  private alive = new Subject<void>();

  private fromDateControlChanged = new Subject<void>();
  private fromTimeControlChanged = new Subject<void>();
  private toDateControlChanged = new Subject<void>();
  private toTimeControlChanged = new Subject<void>();
  private remindersControlChanged = new Subject<void>();
  private recurrenceControlChanged = new Subject<void>();

  constructor(
    private datePipe: DatePipe
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.fromDateControlChanged
      .pipe(
        switchMap(() => this.fromDate?.valueChanges || of(null)),
        startWith(this.fromDate?.value),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        this.displayFromDate.setValue(this.datePipe.transform(value, 'MMM d, yy'));
        this.innerFromDate.setValue(value);
      });

    this.toDateControlChanged
      .pipe(
        switchMap(() => this.toDate?.valueChanges || of(null)),
        startWith(this.toDate?.value),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        this.displayToDate.setValue(this.datePipe.transform(value, 'MMM d, yy'));
        this.innerToDate.setValue(value);
      });

    this.fromTimeControlChanged
      .pipe(
        switchMap(() => this.fromTime?.valueChanges || of(null)),
        startWith(this.fromTime?.value),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        this.displayFromTime = this.datePipe.transform(value, 'h:mm a');
        this.innerFromTime.setValue(value);
      });

    this.toTimeControlChanged
      .pipe(
        switchMap(() => this.toTime?.valueChanges || of(null)),
        startWith(this.toTime?.value),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        this.displayToTime = this.datePipe.transform(value, 'h:mm a');
        this.innerToTime.setValue(value);
      });

    this.recurrenceControlChanged
      .pipe(
        switchMap(() => this.recurrence?.valueChanges || of(null)),
        startWith(this.recurrence?.value),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        this.innerRecurrence.setValue(value);
      });

    this.remindersControlChanged
      .pipe(
        switchMap(() => this.reminders?.valueChanges || of(null)),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.innerReminders.clear({ emitEvent: false });
        this.reminders.controls.forEach(control => this.innerReminders.push(control));
      });

    this.fromDateControlChanged.next();
    this.toDateControlChanged.next();
    this.fromTimeControlChanged.next();
    this.toTimeControlChanged.next();
    this.recurrenceControlChanged.next();

    this.innerRecurrence.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(value => {
        try {
          this.displayRecurrence = RRule.fromString(value).toText();
        } catch (e) {
          this.displayRecurrence = 'Repeat';
        }
      });

    this.innerReminders.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe(value => {
        if (!value.length) {
          this.displayReminders = 'Reminder';
          return;
        }

        this.displayReminders = this.innerReminders.controls
          .map(i => Reminder.fromFormGroup(i).toText()).join(', ');
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('fromDate' in changes) {
      this.fromDateControlChanged.next();
    }

    if ('fromTime' in changes) {
      this.fromTimeControlChanged.next();
    }

    if ('toDate' in changes) {
      this.toDateControlChanged.next();
    }

    if ('toTime' in changes) {
      this.toTimeControlChanged.next();
    }

    if ('reminders' in changes) {
      this.remindersControlChanged.next();
    }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleClose() {
    this.popoverClose.next();
  }

  setDate(range: RelativeDate): void {
    const date = relativeDateToDate(range);

    this.innerFromDate.setValue(date);
    this.innerToDate.setValue(date);
  }

  handleSave() {
    this.fromDate?.setValue(this.innerFromDate.value);
    this.fromDate?.markAsDirty();

    this.toDate?.setValue(this.innerToDate.value);
    this.toDate?.markAsDirty();

    this.fromTime?.setValue(this.innerFromTime.value);
    this.fromTime?.markAsDirty();

    this.toTime?.setValue(this.innerToTime.value);
    this.toTime?.markAsDirty();

    if (this.reminders) {
      this.reminders.clear({ emitEvent: false });
      this.innerReminders.controls.forEach(control => this.reminders.push(control, { emitEvent: false }));
    }

    this.recurrence?.setValue(this.innerRecurrence.value);
    this.recurrence?.markAsDirty();

    this.handleClose();
  }

  handlePopoverOpen(opened: boolean) {
    this.opened = opened;

    if (!opened) {
      this.fromDateControlChanged.next();
      this.fromTimeControlChanged.next();
      this.toDateControlChanged.next();
      this.toTimeControlChanged.next();
    }
  }

  handleTimePopoverOpen(opened, timeButtonType: 'from' | 'to') {
    if (!opened) {
      this.timeButtonSelected = null;
      return;
    }

    this.timeButtonSelected = timeButtonType;
  }
}
