// Common
import { Component, Input } from '@angular/core';

// Services
import { GoogleAnalyticsService } from '@modules/analytics/services/google-analytics.service';
import { BoardService } from '@modules/tasks/services/board.service';

// Types
import { Row } from '@modules/tasks/types/row';

@Component({
  selector: 'app-project-board-row-context-menu',
  templateUrl: './row-context-menu.component.html',
  styleUrls: ['./row-context-menu.component.less']
})
export class RowContextMenuComponent {

  @Input() row: Row;

  constructor (
    private ga: GoogleAnalyticsService,
    private boardService: BoardService
  ) {}

  /**
   * Actions
   */

  delete() {
    this.ga.trackEvent('row-context-menu', 'delete-permanently');
    this.boardService.removeRow(this.row);
  }

  addTop() {
    this.ga.trackEvent('row-context-menu', 'insert-top');
    this.boardService.addRow(false, this.row.position);
  }

  addBottom() {
    this.ga.trackEvent('row-context-menu', 'insert-bottom');
    this.boardService.addRow(true, this.row.position);
  }

  moveUp() {
    this.ga.trackEvent('row-context-menu', 'move-up');
    this.boardService.moveRow(this.row, -1);
  }

  moveDown() {
    this.ga.trackEvent('row-context-menu', 'move-down');
    this.boardService.moveRow(this.row, 1);
  }
}
