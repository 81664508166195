// Common
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

// Utils
import { objectsEqual } from '@modules/common/utils/object';

// Types
import { DropdownSelectItem } from '@modules/form-controls/types/dropdown-select-item';
import { Icon } from '@modules/icons/types/icons';
import { PopoverPlacement } from '@modules/popover/types/placement';

// RX
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.less'],
})
export class DropdownInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inputFormControl: UntypedFormControl;
  @Input() placeholder: string;
  @Input() options?: DropdownSelectItem<unknown>[];
  @Input() optionUniqKey?: string;
  @Input() itemTemplate?: TemplateRef<unknown>; 
  @Input() withClear = false;
  @Input() appearance: 'default' | 'amethyst' | 'amethyst-simple' | 'sapphire' | 'sapphire-outline' | 'sapphire-inline' | 'sapphire-dark' = 'default';
  /**
   * [invertedColor] Only for 'amethyst' | 'amethyst-simple'
   */
  @Input() invertedColor = false;
  /**
   * [size] Only for 'sapphire' | 'sapphire-outline' | 'sapphire-inline' | 'sapphire-dark'
   */
  @Input() size: 's' | 'm' | 'l' = 'l';
  @Input() disabled = false;
  @Input() icon: Icon;
  @Input() iconOnly = false;
  @Input() placement: PopoverPlacement = 'bottomFullWidth';
  @Input() popoverTemplate: TemplateRef<unknown>;
  @Input() inline = false;

  public popoverHide = new Subject();
  public currentOption: DropdownSelectItem<unknown>;

  private alive: Subject<void> = new Subject();
  private inputControlChanged = new Subject();

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.inputControlChanged
      .pipe(
        filter(() => !!this.inputFormControl),
        switchMap(() => this.inputFormControl.valueChanges.pipe(startWith(this.inputFormControl.value))),
        takeUntil(this.alive)
      )
      .subscribe(value => {
        if (!this.options) { return; }

        if (this.optionUniqKey) {
          const uniqKeys = this.optionUniqKey.split(',');

          this.currentOption = this.options.find(option => uniqKeys.every(key => {
            if (Array.isArray(option.value[key])) {
              return option.value[key].join('') === value?.[key].join('');
            } else {
              return option.value[key] === value?.[key];
            }
          }));
        } else {
          this.currentOption = this.options.find(option => objectsEqual(option.value, value)) || { title: value, value };
        }
      });

    this.inputControlChanged.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('inputFormControl' in changes) {
      this.inputControlChanged.next();
    }
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSelect(option: DropdownSelectItem<unknown>) {
    if (this.disabled || option.disabled) { return; }

    this.inputFormControl?.setValue(option.value);
    this.popoverHide.next();
  }
}
