import { FormGroup, FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';

const injector = Injector.create({ providers: [{ provide: FormBuilder, deps: [] }]});

export class Settings {
  formBuilder = injector.get(FormBuilder);

  filesPostponeNotificationsEnabled?: boolean;
  foldersPostponeNotificationsEnabled?: boolean;
  notifications?: boolean;

  constructor(data: any = {}) {
    this.filesPostponeNotificationsEnabled = data.filesPostponeNotificationsEnabled ?? false;
    this.foldersPostponeNotificationsEnabled = data.foldersPostponeNotificationsEnabled ?? false;
    this.notifications = data.notifications ?? data.filesNotificationsEnabled ?? false;
  }

  static fromFormGroup(form: FormGroup): Settings {
    const { value: formValues } = form;

    return new Settings({
      filesPostponeNotificationsEnabled: formValues.filesPostponeNotificationsEnabled,
      foldersPostponeNotificationsEnabled: formValues.foldersPostponeNotificationsEnabled,
      notifications: formValues.notifications,
    });
  }

  public asFormGroup(): FormGroup {
    return this.formBuilder.group({
      filesPostponeNotificationsEnabled: [this.filesPostponeNotificationsEnabled],
      foldersPostponeNotificationsEnabled: [this.foldersPostponeNotificationsEnabled],
      notifications: [this.notifications],
    });
  }

  public asPayloadJSON() {
    return {
      filesPostponeNotificationsEnabled: this.filesPostponeNotificationsEnabled,
      foldersPostponeNotificationsEnabled: this.foldersPostponeNotificationsEnabled,
      notificationsEnabled: this.notifications,
    };
  }
}
