// Common
import { Component, OnDestroy } from '@angular/core';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { StitchType } from '@modules/common/types/stitch-type';

// Services
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { StitchServiceFactory } from '@modules/common/factories/stitch-service.factory';

// RX
import { switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

@Component({
  selector: 'app-dynamic-panel-forms-container',
  templateUrl: './forms-container.component.html',
  styleUrls: ['./forms-container.component.less']
})
export class FormsContainerComponent implements OnDestroy {
  public formItem: Stitch;
  public formType: StitchType;
  public StitchType = StitchType;
  public preSelectedTab: string;
  private alive: Subject<void> = new Subject();

  constructor(
    private dpService: DynamicPanelService,
    private stitchServiceFactory: StitchServiceFactory
  ) {
    this.dpService.getFormItem()
      .pipe(
        switchMap(item => item?.id
          ? this.stitchServiceFactory.getServiceByStitchType(item.getStitchType()).getItem(item.id)
          : of(item)
        ),
        takeUntil(this.alive),
      )
      .subscribe((item) => {
        this.formItem = item;

        this.formType = item?.getStitchType?.();
      });

    this.dpService.getFormTab()
      .pipe(takeUntil(this.alive))
      .subscribe(tab => this.preSelectedTab = tab);
  }

  /**
   * Lifecycle
   */

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleCancel() {
    this.dpService.setFormItem(null);
    this.dpService.setFormTab(null);
  }
}
