// Common
import { Injectable } from '@angular/core';

// RxJs
import { Observable, ReplaySubject } from 'rxjs';

// Services
import { ToasterService } from '@modules/toaster/services/toaster.service';

// Types
import { Coordinates } from '@modules/common/types/geo-location';

@Injectable({
  providedIn: 'root'
})

export class GeoLocationService {
  constructor(
    private toasterService: ToasterService
  ) {
    this.lookUpLocation();
  }

  private coordinates = new ReplaySubject<Coordinates>();

  private lookUpLocation(): void {
    if (!('geolocation' in navigator)) {
      this.toasterService.show({ text: 'Location is not supported by this browser' });
      this.coordinates.next(null);
    }

    navigator.geolocation.getCurrentPosition(
      ({ coords }: GeolocationPosition) => this.coordinates.next({ latitude: coords.latitude, longitude: coords.longitude }),
      (error) => {
        this.toasterService.show({ text: error.message });
        this.coordinates.next(null);
      }
    );
  }

  public getCoordinates(): Observable<Coordinates> {
    return this.coordinates.asObservable();
  }
}
