// Common
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

// Services
import { DynamicPanelService as DPService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { KnowledgePanelService } from '@modules/knowledge/services/knowledge-panel.service';
import { SplitViewComponent } from '@modules/split-view/components/split-view/split-view.component';
import { DynamicPanelService } from '@modules/dynamic-panel/services/dynamic-panel.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less'],
  providers: [DPService, KnowledgePanelService]
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('splitView', {static: true}) splitView: SplitViewComponent;

  private alive = new Subject();

  constructor(
    private dynamicPanelService: DynamicPanelService,
  ) {}

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.dynamicPanelService.getFormItem()
      .pipe(
        filter((item) => !!item),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.splitView.open();
      });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }
}
