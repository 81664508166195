// Common
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// Types
import { Message } from '@modules/messages/types/message';
import { AutocompleteFactory } from '@modules/form-controls/types/autocomplete-factory';
import { Participant } from '@modules/messages/types/participant';

// RX
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Services
import { MessagesService } from '@modules/messages/services/messages.service';
import { ContactsService } from '@modules/contacts/services/contacts.service';

// Components
import { BaseQuickFormComponent } from '../base-quick-form/base-quick-form.component';

@Component({
  selector: 'app-message2-quick-form',
  templateUrl: './message-quick-form.component.html',
  styleUrls: ['./message-quick-form.component.less'],
})
export class Message2QuickFormComponent extends BaseQuickFormComponent implements OnChanges {

  // Inputs
  @Input() message: Message = new Message();

  // Outputs
  @Output() more: EventEmitter<Message> = new EventEmitter<Message>();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<Message> = new EventEmitter();

  // Public
  public contactsSuggestions: AutocompleteFactory<Participant>;
  public popoverClose = new Subject<void>();

  /**
   * Constructor
   */
  constructor (
    private contactsService: ContactsService,
    public changeDetector: ChangeDetectorRef,
    private messagesService: MessagesService,
  ) {
    super(changeDetector);
    this.reset();
    this.contactsSuggestions = this.contactsService.getEmailAutocompleteSuggestions();
  }

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if ('message' in changes) {
      this.reset();
    }
  }

  /**
   * Actions
   */

  submit() {
    if (!this.form.valid) {
      return;
    }

    const message = Message.fromFormGroup(this.form);

    if (this.save.observers.length > 0) {
      this.save.emit(message);
      return;
    }

    this.saveInProgress = true;

    this.messagesService.create(message)
      .pipe(takeUntil(this.alive))
      .subscribe(
        () => {
          this.close.emit();
        },
        () => this.handleError()
      );
  }

  reset() {
    if (this.message) {
      this.form = this.message.asFormGroup();
    }

    super.reset();
  }

  clickMore() {
    const message = Message.fromFormGroup(this.form);
    this.more.emit(message);
    super.clickMore();
  }
}
