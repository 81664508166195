// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { CommonModule as AppCommonModule } from '@modules/common/common.module';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopoverModule } from '@modules/popover/popover.module';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { AddKnotsComponent } from './components/add-knots/add-knots.component';
import { KnotComponent } from './components/knot/knot.component';
import { KnotContextMenuComponent } from './components/knot-context-menu/knot-context-menu.component';
import { KnotsComponent } from './components/knots/knots.component';
import { KnotsFormComponent } from './components/knots-form/knots-form.component';
import { ManageContextMenuComponent } from './components/manage-context-menu/manage-context-menu.component';
import { ManageKnotComponent } from './components/manage-knot/manage-knot.component';
import { ManageKnotsFormComponent } from './components/manage-knots-form/manage-knots-form.component';
import { TemporalExpressionComponent } from './components/temporal-expression/temporal-expression.component';
import { TemporalExpressionContextMenuComponent } from './components/temporal-expression-context-menu/temporal-expression-context-menu.component';

// Directives
import { ManageContextMenuDirective } from './directives/manage-context-menu.directive';
import { KnotContextMenuDirective } from './directives/knot-context-menu.directive';
import { TemporalExpressionContextMenuDirective } from './directives/temporal-expression-context-menu.directive';

// Pipes
import { GroupAlphabeticallyPipe } from './pipes/group-alphabetically.pipe';
import { SortAndFilterPipe } from './pipes/sort-and-filter.pipe';

@NgModule({
  imports: [
    AppCommonModule,
    CommonModule,
    DragNDropModule,
    FormControlsModule,
    FormsModule,
    MatIconModule,
    MatTooltipModule,
    PopoverModule,
    ScrollModule,
    ScrollingModule,
    UtilsModule,
  ],
  declarations: [
    AddKnotsComponent,
    KnotComponent,
    KnotContextMenuComponent,
    KnotsComponent,
    KnotsFormComponent,
    ManageContextMenuComponent,
    ManageKnotComponent,
    ManageKnotsFormComponent,
    TemporalExpressionComponent,
    TemporalExpressionContextMenuComponent,
    // Directives
    KnotContextMenuDirective,
    ManageContextMenuDirective,
    TemporalExpressionContextMenuDirective,
    // Pipes
    GroupAlphabeticallyPipe,
    SortAndFilterPipe,
  ],
  exports: [
    AddKnotsComponent,
    KnotComponent,
    KnotsComponent,
    KnotsFormComponent,
    ManageKnotsFormComponent,
    // Directives
    ManageContextMenuDirective,
  ]
})
export class KnotsModule { }
