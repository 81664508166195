import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'past',
  pure: true
})
export class PastPipe implements PipeTransform {
  transform(value: Date): boolean {
    if (!value) { return false }

    return value.getTime() < Date.now();
  }
}
