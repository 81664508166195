<div class="panel">
  <div class="panel-heading">
    <div class="panel-heading-top">
      <app-dropdown-label
        appDPContactsContextMenu
        (appDPContactsContextMenuOpened)="contextMenuOpened = $event"
        (appDPContactsContextMenuFilterChanged)="selectFilter($event)"
        [collapsed]="!contextMenuOpened"
      >
        {{ selectedFilter | titleByContactsFilter }}
      </app-dropdown-label>
      <div class="panel-heading-top-filters">
        <app-context-menu-state-indicator
          *ngIf="selectedFilter !== 'all_groups'"
          svgIcon="filters"
          appContactsListContextMenu
          [appContactsListContextMenuStateKey]="contactsListStateKey"
          (appContactsListContextMenuChange)="handleContactsListStateChange($event)"
        ></app-context-menu-state-indicator>
        <app-context-menu-state-indicator
          *ngIf="selectedFilter === 'all_groups'"
          svgIcon="filters"
          appGroupsListContextMenu
          [appGroupsListContextMenuStateKey]="groupsListStateKey"
          (appGroupsListContextMenuChange)="handleGroupsListStateChange($event)"
        ></app-context-menu-state-indicator>

        <ng-container [ngTemplateOutlet]="searchTemplate"></ng-container>
      </div>
    </div>
  </div>

  <app-quick-form
    *ngIf="selectedFilter === 'all_groups'"
    class="panel-quick-form"
    placeholder="Add New Group"
  >
    <app-group-quick-form
      (more)="handleNewGroup($event)"
    ></app-group-quick-form>
  </app-quick-form>

  <app-quick-form
    *ngIf="
      selectedFilter !== 'all_groups' &&
      selectedFilter !== 'deleted' &&
      selectedFilter !== 'archived'
    "
    class="panel-quick-form"
    placeholder="Add New Contact"
  >
    <app-contact-quick-form
      (more)="handleNewContact($event)"
    ></app-contact-quick-form>
  </app-quick-form>

  <div class="panel-list">
    <app-groups-list
      *ngIf="selectedFilter === 'all_groups'"
      [filters]="groupsFilters"
      (openGroup)="openGroup($event)"
      (openContact)="openContactInModal($event)"
      (doubleClickGroup)="openGroup($event)"
    ></app-groups-list>

    <app-contacts-list
      *ngIf="selectedFilter === 'all_contacts'"
      [filters]="contactsFilters"
      (openContact)="openContact($event)"
      (doubleClickContact)="openContact($event)"
    ></app-contacts-list>
  </div>
</div>
