<div class="form">
  <div class="form-date">{{ event?.createdAt | date : 'MMM d' }}</div>

  <h2 class="form-title">
    <mat-icon
      class="form-heading-title-icon"
      svgIcon="event"
      [style.color]="event?.color"
    />
    {{ event?.title }}
  </h2>

  <div class="form-details">
    <div class="form-details-row">
      <div class="form-details-row-title">Calendar</div>
      <div class="form-details-row-input">{{ event?.calendarId }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Description</div>
      <div class="form-details-row-input">{{ event?.description }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Start Date</div>
      <div class="form-details-row-input">{{ event?.startTime }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">End Date</div>
      <div class="form-details-row-input">{{ event?.endTime }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">All Day</div>
      <div class="form-details-row-input">{{ event?.allDay }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Reminder</div>
      <div class="form-details-row-input">{{ event?.reminder }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Recurrence</div>
      <div class="form-details-row-input">{{ event?.recurrence }}</div>
    </div>
  </div>
</div>
