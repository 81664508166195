<div class="form">
  <div class="form-date">{{ file?.createdAt | date : 'MMM d' }}</div>

  <h2 class="form-title">
    <mat-icon
      class="form-heading-title-icon"
      svgIcon="file"
      [style.color]="file?.color"
    />
    {{ file?.title }}
  </h2>

  <app-file-preview
    *ngIf="file?.id"
    class="form-preview"
    [style]="{ height: '800px', display: 'block' }"
    [file]="file"
  />

  <div
    class="form-actions"
    [class.disabled]="!file?.id"
  >
    <mat-icon
      class="form-actions-button"
      svgIcon="download-large"
      matTooltip="Download"
      (click)="download()"
    ></mat-icon>

    <mat-icon
      svgIcon="print"
      class="form-actions-button"
      matTooltip="Print"
      (click)="print()"
    ></mat-icon>
  </div>

  <div class="form-details">
    <div class="form-details-row">
      <div class="form-details-row-title">Folder</div>
      <div class="form-details-row-input">{{ file?.folderId }}</div>
    </div>

    <div class="form-details-row">
      <div class="form-details-row-title">Description</div>
      <div class="form-details-row-input">{{ file?.description }}</div>
    </div>
  </div>
</div>
