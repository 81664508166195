<div
  class="form"
  (keyup.enter)="handleEnterKeyPressed($event)"
>
  <input
    class="form-title"
    placeholder="Untitled Contact"
    [formControl]="form.controls.title"
    #titleInput
  />

  <app-color-input
    class="form-marker"
    icon="contacts"
    [control]="form.controls.color"
    [size]="16"
  ></app-color-input>

  <div class="form-input-label">
    Phone
  </div>

  <app-controls-list
    class="form-details-row-input"
    [items]="form.controls.phones"
    [itemTemplateRef]="phoneNumberItemTemplate"
    (onAddItem)="handleAddItem('phones', $event)"
  >
    <ng-template #phoneNumberItemTemplate let-phoneNumberControl=item>
      <app-input-side-options
        [sideFormControl]="phoneNumberControl['controls'].category"
        [sideOptions]="contactInfoTypes"
      >
        <app-phone-number-input
          [inputFormControl]="phoneNumberControl['controls'].value"
        ></app-phone-number-input>
      </app-input-side-options>
    </ng-template>
  </app-controls-list>

  <div class="form-input-label">
    Email
  </div>

  <app-controls-list
    class="form-details-row-input"
    [items]="form.controls.emails"
    [itemTemplateRef]="emailItemTemplate"
    (onAddItem)="handleAddItem('emails', $event)"
  >
    <ng-template #emailItemTemplate let-emailControl=item>
      <app-input-side-options
        [sideFormControl]="emailControl['controls'].category"
        [sideOptions]="contactInfoTypes"
      >
        <app-input
          [inputFormControl]="emailControl['controls'].value"
          placeholder="Enter email"
        ></app-input>
      </app-input-side-options>
    </ng-template>
  </app-controls-list>

  <div class="form-buttons">
    <div
      *ngIf="!inline"
      class="form-buttons-item"
      (click)="clickMore()"
    >
      More
    </div>

    <div class="form-buttons-filler"></div>

    <div
      *ngIf="!inline"
      class="form-buttons-item form-buttons-item-cancel"
      (click)="!saveInProgress && cancel()"
    >
      Cancel
    </div>

    <div
      class="form-buttons-item"
      (click)="!saveInProgress && submit()"
    >
      Add
    </div>
  </div>
</div>
