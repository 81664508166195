import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ElementRef, ViewChildren, QueryList, Injectable } from '@angular/core';
import { beginningOfDay, endOfDay } from '@modules/common/utils/date';

// RX
import { Subject } from 'rxjs';

// Types
import { CalendarEvent as AngularCalendarEvent, CalendarDateFormatter, CalendarNativeDateFormatter, DateFormatterParams } from 'angular-calendar';
import { DragData } from '@modules/drag-n-drop/types/drag-data';
import { CalendarDropEvent } from '@modules/full-calendar/types/calendar-drop-event';
import { CalendarCellClickEvent } from '@modules/full-calendar/types/calendar-cell-click-event';

// Animations
import { slideAnimation } from '@modules/calendar-app/animations/slide-animation';

@Injectable()
class CustomDateFormatter extends CalendarNativeDateFormatter {
  public monthViewColumnHeader({date, locale}: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, {weekday: 'short'}).format(date);
  }
}

@Component({
  selector: 'stch-calendar-month',
  templateUrl: './calendar-month.component.html',
  styleUrls: [ './calendar-month.component.less' ],
  animations: [slideAnimation],
  providers: [
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter }
  ]
})
export class CalendarMonthComponent implements OnChanges {

  @Input() selectedDate: Date;
  @Input() viewDate: Date;
  @Input() events: AngularCalendarEvent[];
  @Input() eventsAppearance: 'list' | 'dots' = 'dots';

  @Output() dateClicked: EventEmitter<CalendarCellClickEvent> = new EventEmitter<CalendarCellClickEvent>();
  @Output() dateDblClicked: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() eventDropped: EventEmitter<CalendarDropEvent> = new EventEmitter<CalendarDropEvent>();
  @Output() loadDayEvents: EventEmitter<Date> = new EventEmitter<Date>();

  public displayDate: Date;
  public maxEventsPerCell = 0;
  public popoverClose = new Subject<void>();

  @ViewChildren('dayRef') days: QueryList<ElementRef>;

  /**
   * Lifecycle
   */

  ngOnChanges(changes: SimpleChanges) {
    if (
      'viewDate' in changes &&
      (
        changes.viewDate.firstChange ||
        changes.viewDate.previousValue?.getMonth() !== changes.viewDate.currentValue?.getMonth() ||
        changes.viewDate.previousValue?.getFullYear() !== changes.viewDate.currentValue?.getFullYear()
      )
    ) {
      this.displayDate = this.viewDate;
    }

    if ('events' in changes) {
      // Number of weeks in other month may vary, leading to different height of calendar cell
      this.handleResize();
    }
  }

  /**
   *  Actions
   */

  selectCalendarDate(event: MouseEvent, date: Date, origin: HTMLElement) {
    this.dateClicked.emit({ date, originRef: new ElementRef(origin), event });
  }

  dblClickCalendarDate(date: Date) {
    this.dateDblClicked.emit(date);
  }

  handleDrop(dragData: DragData, day: Date, origin: HTMLElement) {
    this.eventDropped.emit({
      dragData,
      newStart: beginningOfDay(day),
      newEnd: endOfDay(day),
      originRef: new ElementRef(origin)
    });
  }

  /**
   * Actions
   */

  handleLoadDayEvents(date: Date) {
    this.loadDayEvents.emit(date);
  }

  handleResize() {
    if (!this.days) { return; }

    const maxEvents = Math.floor((this.days.first.nativeElement.offsetHeight - 38) / 20);

    if (this.maxEventsPerCell !== maxEvents) {
      this.maxEventsPerCell = maxEvents;
    }
  }
}
