<div
  *ngIf="!item"
  class="empty-state"
>
  <img class="empty-state-image" src="assets/placeholders/placeholder-files.svg"/>
</div>

<ng-container
  *ngIf="item"
  [ngSwitch]="item | stitchType"
>
  <app-file-form
    *ngSwitchCase="StitchType.file"
    [file]="item"
    (close)="handleFormClose()"
  />

  <app-folder-form
    *ngSwitchCase="StitchType.folder"
    [folder]="item"
    (close)="handleFormClose()"
  />
</ng-container>
