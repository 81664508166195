<label
  class="switch"
  [ngClass]="appearance"
  [class.disabled]="disabled"
  [style.width.px]="width"
  [for]="uuid"
>
  <input
    type="checkbox"
    [id]="uuid"
    [name]="uuid"
    [formControl]="inputFormControl"
    [checked]="value"
    (change)="onChange.emit($event.target.checked)"
  >
  <div class="control-bg">
    <div class="label" [style.width.px]="buttonWidth">{{ falseLabel }}</div>
    <div class="label" [style.width.px]="buttonWidth">{{ trueLabel }}</div>
    <div class="label-control" [style.width.px]="buttonWidth">{{ inputFormControl.value ? trueLabel : falseLabel }}</div>
  </div>
  <div class="control"></div>
</label>
