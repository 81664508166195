<div
  class="container"
  (resize)="handleContainerResize($event)"
>
  <div
    class="tabs-container"
    [ngClass]="[appearance, size]"
    [class.hidden]="!tabsWidth || tabsWidth > containerWidth"
    (resize)="handleTabsResize($event)"
  >
    <div
      *ngFor="let tab of values; let index = index"
      class="item"
      [class.active]="tab.value === value"
      [class.disabled]="tab.disabled"
      (click)="handleSelect(index, tab)"
    >
      {{ tab.title }}

      <mat-icon
        *ngIf="tab.icon"
        class="item-icon"
        [svgIcon]="tab.icon"
      />
    </div>
  </div>

  <app-dropdown-input
    *ngIf="tabsWidth > containerWidth"
    [inputFormControl]="control"
    [options]="options"
    [inline]="true"
    appearance="sapphire-inline"
    size="l"
    placement="bottomLeft"
  />
</div>
