import { FormControl, UntypedFormGroup } from '@angular/forms';

export const getFirstError = (form: UntypedFormGroup) => {
  if (form.errors) {
    const key = Object.keys(form.errors)[0];
    return typeof form.errors[key] === 'boolean' ? key : form.errors[key];
  }

  for (const controlKey in form.controls) {
    const errors = form.controls[controlKey].errors;

    if (errors) {
      const key = Object.keys(errors)[0];
      return typeof errors[key] === 'boolean' ? `${ controlKey } ${ key }` : errors[key];
    }
  }
}

export type TypedFormGroup<T> = {
  [K in keyof T]: FormControl<T[K]>;
};
