<div
  class="day"
  [class.weekend]="day.isWeekend"
  [class.not-in-month]="!day.inMonth"
  [class.today]="day.isToday"
  [class.past]="day.isPast"
  [class.selected]="(day.date | sameDay : selectedDate)"
  [class.disabled]="disabled"
  [class.in-range]="inRange"
  [ngClass]="size"
>
  <div class="number">
    {{ day.date | date : 'd' }}
  </div>

  <div class="dots">
    <div
      class="dots-item"
      *ngFor="let event of day.events | slice : 0 : 3"
      [style.background-color]="event.color.primary"
    ></div>
  </div>
</div>
