// Common
import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

// Services
import { AuthService } from '@modules/auth/services/auth.service';

// RX
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.less']
})
export class SigninComponent implements OnDestroy {

  // Public
  public form = new UntypedFormGroup({
    email: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
    staySigned: new UntypedFormControl(false)
  });

  // Private
  private alive = new Subject<void>();

  /**
   * Constructor
   */

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  /**
   * Lifecycle
   */

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSubmit() {
    this.authService.signIn2(this.form.value)
      .pipe(
        takeUntil(this.alive)
      )
      .subscribe(({ success }) => {
        if (success) {
          this.router.navigate(['']);
        }
      });
  }
}
